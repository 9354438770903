<template>
    <modal name="item-filter-utility-filter" class="filter-modal item-filter-premium-my-filter"
           :width="`calc(100vw - 28px)`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_status">
                    <div class="modal_title">{{ $t('category') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="categoryStatus"
                                               @change="setCategoryFilter(item.uc_idx)">
                                <el-checkbox :label="item.uc_idx" v-for="(item, index) in utilityCategory" :key="`utilityCategory${index}`">{{ item.uc_name }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('condition') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="usestStatus">
                                <el-checkbox :label="1" @click="setUsestFilter(1)">{{ $t('use_await') }}</el-checkbox>
                                <el-checkbox :label="0" @click="setUsestFilter(0)">{{ $t('use_possible') }}</el-checkbox>
                                <el-checkbox :label="3" @click="setUsestFilter(3)">{{ $t('use_fin') }}</el-checkbox>
                                <el-checkbox :label="2" @click="setUsestFilter(2)">{{ $t('use_end_already') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('sell_condition') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="sellstStatus">
                                <el-checkbox :label="1" @click="setDealStatusFilter(1)">{{ $t('sell_stay') }}</el-checkbox>
                                <el-checkbox :label="0" @click="setDealStatusFilter(0)">{{ $t('sell_ing') }}</el-checkbox>
                                <el-checkbox :label="2" @click="setDealStatusFilter(2)">{{ $t('sell_end') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="search()">{{ $t('apply2') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import itemUtil from "@/components/item/itemUtil";
import {Settings} from "luxon";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import filterMixins from "@/mixins/filterMixins";

const {filterConfig} = itemUtil;
export default {
    name: "ItemFilterUtilityFilter",
    mixins: [filterMixins],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            filterData: {},
            paginationData: {default: {page: 1, size: 10, total: 0}},
            categoryStatus: [],
            usestStatus: [],
            sellstStatus: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.setParserType('url', this.$route.fullPath);
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {...mapState({
            utilityCategory: state => state.utilityCategory.utilityCategoryList
        })},
    methods: {
        handlerBeforeOpen(event) {
            if (!util.isEmpty(event.params) && !util.isEmpty(event.param.filterData)) {
                this.filterData = event.params.filterData
            }
            if (!util.isEmpty(event.params) && !util.isEmpty(event.params.paginationData)) {
                this.paginationData = event.params.paginationData
            }
            this.setterFilterDataInData()
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('item-filter-utility-filter');
        },
        search() {
            let data = {};
            data.uc_idx = this.categoryStatus;
            data.usest = this.usestStatus;
            data.sellst = this.sellstStatus;
            EventBus.$emit('setFilterMyModal', data);
            EventBus.$emit('setUtilityFilterMemberModal', data);
            EventBus.$emit('setFilterModal', data);
            this.modalClose()
        },
        resetFilter() {
            this.categoryStatus = [];
            this.usestStatus = [];
            this.sellstStatus = [];
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.$data.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this.$data[classname] = obj.query;
                        if (this.$data.hasOwnProperty(classname + 'Option')) {
                            this.$data[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this.$data[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        let arr = obj.value.split(',');
                        if (arr.length > 1) {
                            let numArr = []
                            for (let i of arr) {
                                numArr.push(Number(i))
                            }
                            ;
                            this.$data[classname] = numArr
                        } else if (isNaN(obj.value)) {
                            this.$data[classname] = obj.value;
                        } else {
                            this.$data[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        if (isNaN(obj.terms[0])) {
                            this.$data[classname] = obj.terms[0];
                        } else {
                            this.$data[classname] = Number(obj.terms[0]);
                        }

                    }

                }
            })
            this.size = this.paginationData.size;
        },
        setCategoryFilter(value) {
            if (!this.categoryStatus.includes(value)) {
                this.categoryStatus.push(value);
            } else {
                this.categoryStatus = this.categoryStatus.filter((el) => el !== value);
            }
        },
        setUsestFilter(value) {
            if (!this.usestStatus.includes(value)) {
                this.usestStatus.push(value);
            } else {
                this.usestStatus = this.usestStatus.filter((el) => el !== value);
            }
        },
        setDealStatusFilter(value) {
            if (!this.sellstStatus.includes(value)) {
                this.sellstStatus.push(value);
            } else {
                this.sellstStatus = this.sellstStatus.filter((el) => el !== value);
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
