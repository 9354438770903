<template>
    <component :is="loader" v-if="loader" :paginationData="paginationData" :listLength="listLength"
               :finishGetList="finishGetList"></component>
  </template>

  <script>
  import {defineAsyncComponent} from "vue";

  export default {
    name: "PaginationLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
      return {}
    },
    props: {
      paginationLayout : '',
      paginationData: {
        type: Object,
        default: {
          page: 1,
          size: 0,
          total: 0}
      },
      listLength: {
        type: Number,
        default: 0
      },
      finishGetList: {default: true,}
    },
    data() {
      return {
        component: null,
      }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
      loader() {
        if (!this.paginationLayout) {
          return defineAsyncComponent(() => import('@/components/utility/layout/utilityList/pagination/LazyloadDefaultLayout.vue'));
        }
        return defineAsyncComponent(() => import(`@/components/utility/layout/utilityList/pagination/${this.paginationLayout}.vue`))
      },
    },
    methods: {},
    watch: {},
  }
  </script>

  <style scoped>

  </style>
