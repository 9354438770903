<template>
    <div>
        ItemListCardLayout
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListCardLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
      returnCommas(x = 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
        moveDetail(data) {
            this.$router.push(`/premiummarket/${data}`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
        },
        returnMemberImg() {
            return `${this.memberData.profile}?${this.setImageOptions(100, 100, 'jpg')}`
        },

        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },

        getMemberData(data) {
            this.memberData = {}
            this.memberData.profile = this.itemData[0].profile.provider[0].org_url;
            this.memberCheck = true;
            this.$forceUpdate()
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            return diffTime;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
