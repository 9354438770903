<template>
    <div class="gallery_list_wrap g_collection_wrap">
        <div class="gallery_item" v-for="(gallery, index) in galleryData" :key="`gallery${gallery.c_idx}`" @click="movePage(`/gallery/${gallery.c_idx}`)">
            <div class="img_box">
                <img :src="returnImage(gallery)" alt="">
            </div>
            <div class="item_name_box">
                <div class="item_name">{{ gallery.c_title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import GallerySettingModal from "@/components/modal/GallerySettingModal";

export default {
    name: "GalleryListCreatorLayout",
    mixins: [imageOption],
    components: {GallerySettingModal},
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        galleryData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}}
    },
    data() {
        return{
            collectionLists: 3,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        movePage(url) {
            this.$router.push(url);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        returnImage(gallery) {
            if(util.isEmpty(gallery) || util.isEmpty(gallery.Files) || util.isEmpty(gallery.Files.Background)) {
                return "";
            }
            return `${gallery.Files.Background[0].org_url}?${this.setImageOptions(308, 202, 'jpg')}`;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
