<template>
    <div class="item_status_component">
        <item-write-link v-for="writeLayout in writeLayouts" :key="`item${writeLayout}`"
                         :writeLayout="writeLayout" :itemData="itemData"></item-write-link>
    </div>
</template>

<script>
import ItemWriteLink from "@/components/item/ItemWriteLink";
import itemUtil from "@/components/item/itemUtil";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import itemValidator from "@/mixins/validators/itemValidator";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import elFileMixins from "@/mixins/elFileMixins";
import {mapState} from "vuex";

export default {
    name: "ItemWriteComponent",
    mixins: [itemUtil, alertMixins, itemValidator, awsS3FileUpload, elFileMixins],
    components: {
        ItemWriteLink,
    },
    inject: [],
    provide() {
        return {
            itemWriteSetData: this.setData,
        }
    },
    props: {
        itemType: '',
        itemKey: '',
    },
    data() {
        return {
            isActive: false,
            writeLayouts: '',
            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            fileInfo: [],
            directUrl: '',
            directType: '',
            keyName: '',
            getKeyName: '',
            writeSuccessType: '',
            writeFailTypes: {},
            itemData: {},
            isRunning: false,
            afterType: '',
            writeValidatorArr: '',
            successMsg: '',
            deleteFunc: 'deleteItem',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            itemIdx: [],
            nftFileInfo: []
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        if (!util.isEmpty(this.itemKey)) {
            this.getItem();
        } else {
            this.initTemplateConfig();
        }
    },
    mounted() {
        EventBus.$on('writeItem', this.writeTemporary);
        EventBus.$on('updateItem', this.checkUpdateValidator);
        EventBus.$on('writeItemTemporary', this.writeTemporary);
        EventBus.$on('deleteItem', this.deleteConfirm);

    },
    beforeDestroy() {
        EventBus.$off('writeItem');
        EventBus.$off('updateItem');
        EventBus.$off('writeItemTemporary');
        EventBus.$off('deleteItem');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name) {
            this.$modal.show(name);
        },
        initConfig() {
            let itemTemplateConfig = this.returnItemTemplateConfig(this.itemType);
            this.writeFunc = itemTemplateConfig.writeFunc;
            this.writeApiParamSet = itemTemplateConfig.writeApiParamSet;
            this.parsingData = itemTemplateConfig.parsingData;
            this.fileInfo = itemTemplateConfig.fileInfo;
            this.directUrl = itemTemplateConfig.directUrl;
            this.directType = itemTemplateConfig.directType;
            this.writeSuccessType = itemTemplateConfig.writeSuccessType;
            this.keyName = itemTemplateConfig.keyName;
            this.getKeyName = itemTemplateConfig.getKeyName;
            this.writeFailTypes = itemTemplateConfig.writeFailTypes;
            this.writeValidatorArr = itemTemplateConfig.writeValidatorArr;
            this.afterType = itemTemplateConfig.afterType;
            this.successMsg = itemTemplateConfig.successMsg;
            this.deleteFunc = itemTemplateConfig.deleteFunc;
            this.deleteApiParamSet = itemTemplateConfig.deleteApiParamSet;
            this.deleteSuccessType = itemTemplateConfig.deleteSuccessType;
            this.deleteErrorTypes = itemTemplateConfig.deleteErrorTypes;
            this.nftFileInfo = itemTemplateConfig.nftFileInfo;
        },
        initTemplateConfig() {
            let config = this.returnItemTemplateConfig(this.itemType);
            this.writeLayouts = config.writeLayouts;
        },
        setData(key, value) {
            this[key] = value;
            EventBus.$emit('setRequiredMsg', false);
        },
        getItem() {
            let params = {
                // id: this.itemKey,
            }
            if(util.isEmpty(this.getKeyName)) {
                params.id = this.itemKey;
            }else{
                params[this.getKeyName] = this.itemKey;
            }
            this.$api.$item.getItemDetail(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.createAlert({
                        title: '',
                        content: this.$t('no_data_loading'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    });
                    return;
                }
                this.itemData = res.List[0];
                this.initTemplateConfig();
            })

        },
        writeTemporary(data) {
            this.enroll = Number(data);
            this.temp = Number(data);
            this.checkValidator();
        },
        checkValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = this.writeValidatorArr;

            validatorArr.push('original');
            validatorArr.push('thumnail');
            if (this.guaranteeType === 1) {
                validatorArr.push('guarantee');
            }
            if (this.edition === 1 && this.type === 'digital') {
                validatorArr.push('total');
            }
            if (this.edition === 1 && this.type === 'real') {
                validatorArr.push('count');
                validatorArr.push('total');
            }
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeItem();
                } else {
                    this.isRunning = false;
                    EventBus.$emit('apiLoading', false);
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                    });
                    EventBus.$emit('setRequiredMsg', true);
                }
            })
        },
        writeItem() {
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet))
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                params[this.parsingData[dataKey]] = this[dataKey];
            })
            if (this.itemKey !== '' && this.i_idx != '') {
                params.i_idx = this.i_idx;
            }else if(this.itemKey !== '') {
                params.i_idx = this.itemKey;
            }
            if (this.status === 1 || this.status === 2) {
                params.ia_is_minimum = this.minimumSetting;
                params.ia_minimum = this.minimum;
                params.ia_is_immediately = this.immediatelySetting;
                params.ia_immediately = this.immediately;
                params.ia_start_date = this.startDate;
                params.ia_end_date = this.endData;
            } else if (this.status === 3) {
                params.il_small_price = this.smallPrice
                params.il_medium_price = this.mediumPrice
                params.il_large_price = this.largePrice
                params.il_original_price = this.originalPrice
                params.il_caution = this.caution
            } else if (this.status === 4 || this.status === 5) {
                params.i_price = this.i_price;
            }
            try {
                this.$api.$item[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    if (!res.List.Data.Status) {
                        EventBus.$emit('apiLoading', false);
                        this.isRunning = false;
                        this.errorAlert(`${this.$t('createItemMsg3')}`);
                        return false;
                    }

                    //s3파일 등록 및 api 등록
                    if (!util.isEmpty(this.deleteFiles)) {
                        this.runS3ApiDelete(this.deleteFiles)
                    }
                    this.itemIdx = res.List.Data.Data.Basic.Data.Item.Data.i_idx;

                    if (!util.isEmpty(this.nftFileInfo)) {
                        this.setMultiNftFileUpload(this.itemIdx, this.nftFileInfo);
                    } else if (!util.isEmpty(this.fileInfo)) {
                        this.setMultiFileUpload(this.itemIdx, this.fileInfo)
                    } else {
                        this.writeAfterFunc()
                    }
                });

            } catch (e) {
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;
                console.log(e);
            }
        },
        setMultiNftFileUpload(key, fileInfo) {
            if (Array.isArray(key)) {
                util.Array.each(key, (data, index, arr) => {
                    this.runUploadFileInfo(fileInfo, 0, data).then(res => {
                        if (this.temp === 1) {
                            if (key.length === index + 1) {
                                this.itemHashAdd(key);
                            }
                        } else {
                            if (key.length === index + 1) {
                                this.setMultiFileUpload(key, this.fileInfo);
                            }
                        }
                    })
                });
            } else {
                this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                    if (this.temp === 1) {
                        this.itemHashAdd(key);
                    } else {
                        this.setMultiFileUpload(key, this.fileInfo);
                    }
                })
            }
        },

        setMultiFileUpload(key, fileInfo) {
            if (Array.isArray(key)) {
                util.Array.each(key, (data, index, arr) => {
                    this.runUploadFileInfo(fileInfo, 0, data).then(res => {
                        if (key.length === index + 1) {
                            this.writeAfterFunc(key);
                        }
                    })
                });
            } else {
                this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                    this.writeAfterFunc(key);
                })
            }
        },

        //아이템 생성 성공 함수
        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('apiLoading', false);
            let detailKey = key;
            if(!util.isEmpty(this.itemKey)) {
                detailKey = this.itemKey;
            }
            if (this.afterType === 'write') {
                let alertContent = this.$t('createItemMsg1')
                if (this.enroll === 2) {
                    alertContent = this.$t('createItemMsg2')
                }
                this.fileInfo = [];
                this.createConfirm({
                    content: alertContent,
                    confirmText: this.$t('check_now'),
                    cancelText: this.$t('check_later'),
                    confirm: () => {
                        if (this.enroll === 2) {
                            this.$router.push(`/mypage/item?tab=myItemTempSave`)
                            return
                        }
                        this.$router.replace(`/mypage/item?tab=myItemHold`);
                    },
                    cancel: () => {
                        this.$router.push('/');
                    },
                    hide: () => {
                        this.initTemplateConfig();
                        this.restData();
                    },
                })
            } else if (this.afterType === 'update') {
                this.createAlert({
                    title: '',
                    content: this.$t(this.successMsg),
                    btnText: this.$t('yes'),
                    hide: () => {
                        if (this.directType === 'back') {
                            this.$router.back();
                            return
                        } else if (this.directType === 'replace') {
                            this.$router.replace(this.directUrl);
                            return
                        } else if (this.directType === 'list') {
                            this.$router.push(this.directUrl);
                            return
                        } else if (this.directType === 'detail') {
                            if (this.UserInfo.g_idx === 2) {
                                this.$router.push(`/premiummarket/${detailKey}`);
                                return
                            }
                            this.$router.push(`${this.directUrl}/${detailKey}`);
                            return
                        }
                        if (this.directType === 'modal') {
                            this.$modal.hide(this.directUrl);
                            EventBus.$emit('getItemList');
                        }
                    }
                });
            }
        },
        //아이템 해시 추가함수
        itemHashAdd(key, index = 0) {
            EventBus.$emit('apiLoading', true);
            let params = {
                i_idx: key
            }
            if (Array.isArray(key)) {
                params.i_idx = key[index];
            }
            this.$api.$item.addHash(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.isRunning = false;
                    EventBus.$emit('apiLoading', false);
                    this.errorAlert(this.$t('nft_error'));
                    return
                }
                console.log(res.List);
                let itemKeyArr = res.List.filter(data => data != params.i_idx);
                if(util.isEmpty(itemKeyArr)) {
                    this.writeAfterFunc(params.i_idx);
                }else{
                    this.setMultiFileUpload(itemKeyArr, this.nftFileInfo);
                }
            })
        },

        itemHashFail(key) {
            if (key.length > 1) {
                let params = {
                    i_idx: key
                }
                this.$api.$item.failHash(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        EventBus.$emit('apiLoading', false);
                        this.isRunning = false;
                        this.errorAlert(this.$t('nft_error'));
                        return
                    } else {
                        return this.$router.back();
                    }
                })
            } else if (key.length === 1) {
                this.isRunning = false;
                EventBus.$emit('apiLoading', false);
                this.errorAlert(this.$t('nft_error'));
                return
            }
        },

        checkUpdateValidator() {
            if (this.isRunning) {
                return false
            }
            EventBus.$emit('apiLoading', true);
            this.isRunning = true;
            let validatorArr = this.$_.cloneDeep(this.writeValidatorArr);
            ;
            if (this.status === 1 && this.itemData.ia_auction !== 2 || this.status === 2) {
                validatorArr.push('minimumSetting')
                validatorArr.push('minimum')
                validatorArr.push('immediatelySetting')
                validatorArr.push('immediately')
                validatorArr.push('startDate')
                validatorArr.push('endData')
            } else if (this.status === 3) {
                validatorArr.push('smallPrice')
                validatorArr.push('mediumPrice')
                validatorArr.push('largePrice')
                validatorArr.push('originalPrice')
                validatorArr.push('caution')
            } else if (this.status === 4 || this.status === 5) {
                validatorArr.push('i_price');
            } else if (this.status == 9) {
            }
            console.log(validatorArr);
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.writeItem();
                } else {
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                    util.Array.each(this.writeLayouts, (layout, index, arr) => {
                        EventBus.$emit(`${layout}Validator`);
                    });
                }
            })
        },
        deleteConfirm() {
            this.createConfirm({
                content: `${this.$t('caution_del_item')}<br>${this.$t('caution_del')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.deleteItem(this.itemKey);
                },
                cancel: () => {
                }
            })
        },
        deleteItem() {
            let params = {
                i_idx: this.itemKey
            }
            if(!util.isEmpty(this.i_idx)) {
                params.i_idx = this.i_idx;
            }
            this.$api.$item[this.deleteFunc](params).then(res => res.Data.Result.List).then(res => {
                if (!res.Data.Status) {
                    this.errorAlert(this.$t('deleteErrorMsg'));
                    return
                }
                this.createAlert({
                    title: '',
                    content: this.$t('deleteSuccessMsg'),
                    btnText: this.$t('yes'),
                    hide: () => {
                        if (this.directType === 'back') {
                            this.$router.back();
                        }
                        this.$router.replace(this.directUrl);
                    },
                })

            })
        },


    },
    watch: {},
}
</script>

<style scoped>

</style>
