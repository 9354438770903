<template>
  <div>
      <page-header :header_back="true"  :header_setting="true"></page-header>
    <gallery-write-component :gallery-type="'galleryAdd'"></gallery-write-component>
  </div>
</template>

<script>
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";

export default {
  name: "GalleryAdd",
  mixins: [],
  components: {
    GalleryWriteComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>