<template>
    <modal name="tel-enroll-modal"
           class="tel-enroll-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :width="'90%'"
           :height="'290px'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>

            <div class="update-change-modal_wrap">
                <div class="nickname_title">
                    {{$t('phoneNumber')}}
                </div>
                <div class="rollback_btn" @click="closeModal()"></div>
                <div class="clear"></div>
                <div class="modal_text_content mt22">
                    <div class="text_content_text">
                        <span v-html="$t('popup_phone')"></span>
                    </div>
                    <img class="mt19 mb45 enroll_icon" src="@/assets/image/telenrollimg.png">
                </div>
                <button class="two_btn back_gray" @click="closeModal()">{{$t('do_later')}}</button>
                <button class="two_btn back_navy" @click="runAuthentication">{{$t('do_enroll')}}</button>
                <div class="clear"></div>
            </div>
        </div>
    </modal>

</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
    name: "telEnrollModal",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('tel-enroll-modal');
        },
        handlerBeforeOpen(event) {
            this.scroll = event.params.scroll
            document.body.style.top = `-${this.scroll}px`
        },

        handlerOpened() {
        },

        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },
        movePage(url) {
            this.$router.push(url);
        },
        runAuthentication() {
            EventBus.$emit('runAuthenticatio')
            this.closeModal();
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
