<template>
    <div>
        <gallery-list-component :memberKey ="memberKey" :gallery-type="'creatorGallery'" :defaultFilter="defaultFilter"></gallery-list-component>
    </div>
</template>

<script>
import GalleryListComponent from "@/components/gallery/GalleryListComponent";

export default {
  name: "CreatorDetailCollection",
  mixins: [],
  components: {
      GalleryListComponent
  },
  inject: [],
  provide() {
    return {
    }
  },
  props: {
  },
  data() {
    return {
        memberKey: this.$route.params.idx,
        defaultFilter: {},
    }
  },
  created() {
      this.defaultFilter = {uid: this.memberKey};
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {

  },
  watch: {},
}
</script>

<style scoped>

</style>
