<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="padding_side dgm_wrap">
            <div class="mypage_item_wrap">
                <div class="notice_tabs">
                    <button class="notice_item_3_tab" :class="{'active':tab === 'myItemHistory'}"
                            @click="changeTab('myItemHistory')">
                        {{ $t('history') }}
                    </button>
                    <button class="notice_item_3_tab" :class="{'active':tab === 'myItemHold'}"
                            @click="changeTab('myItemHold')">
                        {{ $t('holding') }}
                    </button>
                    <button class="notice_item_3_tab border_r_l" :class="{'active':tab === 'myItemTempSave'}"
                            @click="changeTab('myItemTempSave')">
                        {{ $t('tempSave') }}
                    </button>
                </div>
                <item-list-component :item-type="tab" :member-key="UserInfo.mb_no" v-if="tab"></item-list-component>
            </div>
        </div>

    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import ItemListComponent from "@/components/item/ItemListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import util from "@/mixins/util";

export default {
    name: "MyPageItem",
    mixins: [AuthMixins, alertMixins],
    components: {
        MyPageHeaderComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'myItemHistory',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        // if (this.getMemberType() === 'curator') {
        //     this.$router.replace('/mypage/curation');
        //     return false;
        // }
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('myItemHistory');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        // ...mapState({
        //     UserInfo: state => state.userInfo.UserInfo,
        // })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
