<template>
    <div class="padding_side">
        <item-list-component :item-type="'openUser'" :member-key="memberKey"></item-list-component>
    </div>
</template>
<script>
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "CreatorDetailMarket",
    mixins: [],
    components: {
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
