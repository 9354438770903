<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <history-detail-component :history-type="'itemHistory'" :historyKey="historyKey"></history-detail-component>
    </div>
</template>

<script>
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";

export default {
    name: "ItemHistory",
    mixins: [],
    components: {
        HistoryDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            historyKey: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.historyKey = this.$route.params.idx
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
