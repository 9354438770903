<template>
    <div>
        <entry-filter-link :filterLayout="filterLayout"></entry-filter-link>
        <entry-list-link :listLayout="listLayout" v-if="true"></entry-list-link>
        <entry-empty-link :emptyLayout="emptyLayout" v-else></entry-empty-link>
        <pagination-link :paginationLayout="paginationLayout"></pagination-link>
    </div>
</template>

<script>
import EntryFilterLink from "@/components/entry/EntryFilterLink";
import EntryListLink from "@/components/entry/EntryListLink";
import EntryEmptyLink from "@/components/entry/EntryEmptyLink";
import PaginationLink from "@/components/entry/PaginationLink";
import entryUtil from "@/components/entry/entryUtil";

export default {
    name: "EntryListComponent",
    mixins: [entryUtil],
    components: {
        EntryFilterLink,
        EntryListLink,
        EntryEmptyLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        entryType: '',
    },
    data() {
        return{
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initConfig(){
            let entryConfig = this.returnEntryConfig(this.entryType);
            this.listLayout = entryConfig.listLayout;
            this.filterLayout = entryConfig.filterLayout;
            this.emptyLayout = entryConfig.emptyLayout;
            this.paginationLayout = entryConfig.paginationLayout;
        },
        movePage(url){
            this.$router.push(url);
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>