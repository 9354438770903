<template>
    <div>
        <div class="competition_create_wrap">
            <div class="poster_wrap">
                <div class="poster_text_top">{{ $t('comp_poster') }}</div>
                <div class="poster_text_bottom"><span>*</span>{{ $t('big_upload_msg') }}</div>
            </div>

            <ul class="radio_wrap">

                <!--              썸네일-->
                <li class="detail_thumb">
                    <div class="w_content_box" @click="changeFileType('poster')">
                        <el-upload
                                class="n_el_up"
                                action=""
                                list-type="picture-card"
                                :show-file-list="false"
                                :file-list="poster"
                                :auto-upload="false"
                                :on-change="onlyOnePhotoChange"
                        >
                            <img v-if="hasFile('poster')" :src="poster[0].org_url" class="upload_img">
                            <button v-if="hasFile('poster')" class=""
                                    @click="removeList('poster',0)"></button>
                            <img v-else src="@/assets/image/change_item.png" alt="" class="default_img">
                        </el-upload>
                        <div class="val_cap" v-if="validation.hasError('poster') && poster.length === 0"
                        >{{ validation.firstError('poster') }}
                        </div>
                    </div>
                </li>

                <!--        카테고리-->
                <li class="category_select">
                    <h3 class="sub_title_m regular warning_icon">{{ $t('category') }}</h3>
                    <div class="w_content_box">
                        <el-radio-group class="el_radio_box" v-model="category" @change="setCategory(Number(category))">
                            <el-radio class="el_radio_btn" v-for="data in categories"
                                      :key="`${data.cc_idx}`" :label="Number(data.cc_idx)">
                                {{ returnCategoryName(data.cc_idx) }}
                            </el-radio>

                        </el-radio-group>
                    </div>
                </li>

                <!--        공모전명-->
                <li class="item_name">
                    <h3 class="sub_title_m regular warning_icon">{{ $t('n_comp') }}</h3>
                    <div class="w_content_box">
                        <h3 class="sub_title_s regular">{{ $t('subject_comp') }}</h3>
                        <!--           <span class="input_max_length"><span>{{ title.length }}</span>/50</span>-->

                        <el-input class="input_txt" type="text" :placeholder="$t('i_sub_comp')"
                                  v-model="title"
                                  @input="parentChange('title',title)"
                                  maxLength="50"
                                  :disabled="hasData()"
                        ></el-input>
                        <div class="val_cap" v-if="validation.hasError('title')">{{
                                validation.firstError('title')
                            }}
                        </div>
                    </div>
                </li>


                <!--              해시태그-->
                <li class="c_hash_wrap">
                    <h3 class="sub_title_m regular">{{ $t('hashtag') }}</h3>
                    <div class="w_content_box">
                        <div class="sub_title_s">
                            {{ $t('hashtag') }}
                        </div>

                        <div class="w_content w_self">
                            <el-input type="text" :placeholder="$t('w_hash')" v-model="hashInput"
                                      @input="setData('hashInput',hashInput)"
                                      @keyup.enter.native="changeHash(2,hashInput)"/>
                            <div class="plus_btn" for="addition" @click="changeHash(2,hashInput)"></div>
                            <div class="d_hash_tag_wrap" v-for="(data,index) in hashtag" :key="`hash${index}`"
                                 v-if="data.type === 2">
                                <div class="hash_wrap">
                                    <span class="hash_name ">{{ data.tag }}</span>
                                    <button class="delete_btn" @click="deleteHash(data)"></button>
                                </div>
                            </div>
                        </div>
                        <div class="val_cap" v-if="validation.hasError('hashtag')">{{
                                validation.firstError('hashtag')
                            }}
                        </div>

                    </div>
                </li>

                <!--              주관 입력-->
                <li class="inform c_ju">
                    <h3 class="sub_title_m regular">{{ $t('c_ju') }}</h3>
                    <div class="edit_textarea">
                        <h3 class="sub_title_s regular">{{ $t('i_ju') }}</h3>
                        <el-input type="textarea" maxlength="50" v-model="organize"
                                  @input="parentChange('organize',organize)"
                                  :placeholder="$t('i_ju')"></el-input>
                        <div class="val_cap" v-if="validation.hasError('content')">
                            {{ validation.firstError('content') }}
                        </div>
                    </div>
                </li>

                <!--              대상 입력-->
                <li class="inform c_target">
                    <h3 class="sub_title_m regular">{{ $t('target') }}</h3>
                    <div class="edit_textarea">
                        <h3 class="sub_title_s regular">{{ $t('i_target') }}</h3>
                        <el-input type="textarea" :placeholder="$t('i_target')" maxlength="50" v-model="target"
                                  @input="parentChange('target',target)"
                        ></el-input>
                        <div class="val_cap" v-if="validation.hasError('target')">
                            {{ validation.firstError('target') }}
                        </div>
                    </div>
                </li>

                <!--              공모전 내용-->
                <li class="inform c_comp">
                    <h3 class="sub_title_m regular">{{ $t('comp_con') }}</h3>
                    <div class=" item_editor">
<!--                        <h3 class="sub_title_s regular">{{ $t('i_comp_con') }}</h3>-->
<!--                        <textarea type="text" id="comp_content" :placeholder="$t('i_comp_con')" v-model="content"
                                  @input="parentChange('content', content)" :disabled="hasData()"
                        ></textarea>-->
                        <quill-editor
                            ref="comp_contentEditor"
                            v-model="content"
                            :options="editorOption"
                        />
                        <div class="val_cap" v-if="validation.hasError('content')">
                            {{ validation.firstError('content') }}
                        </div>
                    </div>
                </li>

                <!--              당선작 라이선스 이용안내-->
                <li class="inform c_license">
                    <h3 class="sub_title_m regular">{{ $t('inform_license') }}</h3>
                    <div class=" item_editor">
<!--                        <h3 class="sub_title_s regular">{{ $t('information') }}</h3>-->
<!--                        <textarea type="text" id="license" :placeholder="$t('i_information')" v-model="license"
                                  @input="parentChange('license',license)"
                        ></textarea>-->
                        <quill-editor
                            ref="licenseEditor"
                            v-model="license"
                            :options="editorOption"
                        />
                        <div class="val_cap" v-if="validation.hasError('license')">
                            {{ validation.firstError('license') }}
                        </div>
                    </div>
                </li>

                <!--평가기준-->
                <li class="standard_wrap">
                    <h3 class="sub_title_m regular">{{ $t('standard') }}</h3>
                    <div class="w_content_box">
                        <ul class="sub_content">
                            <li>
                                <div class="sub_title_s regular">
                                    {{ $t('standard') }}
                                </div>
                                <div class="w_content">
                                    <el-input type="text" :placeholder="$t('i_standard')"
                                              v-model="evaluateInput"
                                              @input="setData('evaluateInput',evaluateInput)"
                                              @keyup.enter.native="setEvaluate()"/>
                                    <div class="plus_btn" for="addition" @click="setEvaluate()"></div>
                                </div>
                            </li>
                            <li class="standard_item" v-for="(eva,index) in evaluate" :key="`evaluate-${index}`">
                                <div class="content_name "></div>
                                <input type="text" :disabled="true" :value="eva">
                                <div class="delete_btn" @click="deleteEvaluate(eva,index)"></div>
                            </li>
                        </ul>
                        <div class="val_cap" v-if="validation.hasError('evaluate')">{{
                                validation.firstError('evaluate')
                            }}
                        </div>
                    </div>
                </li>

                <!--              안내 첨부파일-->
                <li class="real_item" @click="changeFileType('intro')">
                    <h3 class="sub_title_m regular warning_icon">{{ $t('i_upload') }}</h3>
                    <div class="w_content_box">
                        <div class="item_file" v-show="!hasFile('intro')">
                            <el-upload
                                    class=""
                                    action=""
                                    list-type="picture-card"
                                    :show-file-list="false"
                                    :file-list="intro"
                                    :auto-upload="false"
                                    :on-change="onlyOneFileUpload"
                            >
                                <button class="upload-name"><span>{{ $t('file_upload') }}</span></button>
                            </el-upload>
                        </div>
                        <div class="filebox" v-if="hasFile('intro')">
                            <div class="file_name">
                                <div> {{ intro[0].org_name ? intro[0].org_name : intro[0].name }}</div>
                                <button class="drop_file" v-if="hasFile('intro')"
                                        @click="removeList('intro',0,$event)"></button>
                            </div>
                        </div>
                        <div class="val_cap" v-if="validation.hasError('intro') && intro.length ===0">
                            {{ validation.firstError('intro') }}
                        </div>
                    </div>
                </li>


                <!--       심사위원-->
                <li class="judge_wrap">
                    <h3 class="sub_title_m regular">{{ $t('judges') }}</h3>
                    <div class="w_content_box">
                        <el-radio-group class="el_radio_box" v-model="is_judge"
                                        @change="changeIsJudge()">
                            <el-radio class="el_radio_btn" :label="1">{{ $t('yes3') }}</el-radio>
                            <el-radio class="el_radio_btn" :label="0">{{ $t('no3') }}</el-radio>
                        </el-radio-group>
                        <div class="find" v-show="is_judge === 1">
                            <button @click="openModal('judge-search-modal')" :disabled="is_judge === 0">{{
                                    $t('f_judge')
                                }}
                            </button>
                        </div>
                        <div class="sub_content" v-if="this.judgeData.length > 0">
                            <ul>
                                <li v-for="(data, index) in judgeData" :key="`judge${index}`">
                                    <div class="mini_thumb_wrap">
                                        <div class="thumb_img"
                                             :style="`background-image: url(${returnImage(data.profile,45,45)});`"></div>
                                        <span class="thumb_nickname">{{ data.mb_nick }}</span>
                                        <div class="delete_btn" @click="deleteJudge(index)"></div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="val_cap"
                             v-if="validation.hasError('judge') && is_judge === 1 && u_judge.delete.length === 0">
                            {{ validation.firstError('judge') }}
                        </div>
                    </div>
                </li>

                <!--총 상금-->
                <li class="total_prize_wrap">
                    <h3 class="sub_title_m regular">{{ $t('t_prize') }}</h3>
                    <div class="w_content_box">
                        <ul class="sub_content">
                            <li>
                                <div class="sub_title_s regular">
                                    {{ $t('t_prize') }}
                                </div>
                                <div class="w_content">
                                    <el-input type="tel" v-model="prize"
                                              @input="returnNumber('prize',prize)"
                                              :disabled="hasData()"
                                              :placeholder="0"
                                    />
                                    <div class="mg">Mg</div>
                                </div>
                            </li>
                        </ul>
                        <div class="val_cap" v-if="validation.hasError('prize')">{{
                                validation.firstError('prize')
                            }}
                        </div>
                        <div class="val_cap" v-if="!validation.hasError('prize') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                </li>

                <li class="time_wrap">
                    <h3 class="sub_title_m regular">{{ $t('start_competition') }}</h3>
                    <div class="w_content_box">
                        <ul class="sub_content">
                            <li>
                                <div class="sub_title_s">
                                    {{ $t('start_competition') }}
                                </div>
                                <el-radio-group class="el_radio_box" v-model="startStatus" :disabled="hasData()"
                                                @change="parentChange('startStatus', startStatus)">
                                    <el-radio class="el_radio_btn" :label="1">{{ $t('setting') }}</el-radio>
                                    <el-radio class="el_radio_btn" :label="0">{{ $t('start') }}</el-radio>
                                </el-radio-group>
                                <div class="mt20" v-if="startStatus === 1">
                                    <el-date-picker
                                            class="range_time"
                                            v-model="startApplyDate"
                                            type="datetime"
                                            :disabled="hasData()"
                                            :picker-options="startPickerOptions"
                                            format="yyyy-MM-dd HH:mm"
                                            value-format="yyyy-MM-dd HH:mm"
                                            placeholder="YYYY-MM-DD HH:MM"
                                            popper-class="not_now"
                                            @change="dateChange('startApplyDate', startApplyDate)"
                                    >
                                    </el-date-picker>
                                </div>
                            </li>
                        </ul>
                        <div class="val_cap" v-if="validation.hasError('startApplyDate')">
                            {{ validation.firstError('startApplyDate') }}
                        </div>
                    </div>
                </li>

                <li class="time_wrap">
                    <h3 class="sub_title_m regular">{{ $t('end_competition') }}</h3>
                    <div class="w_content_box">
                        <ul class="sub_content">
                            <li>
                                <div class="sub_title_s">
                                    {{ $t('end_competition') }}
                                </div>
                                <el-date-picker
                                        class="range_time"
                                        v-model="endApplyDate"
                                        type="datetime"
                                        :disabled="hasData()"
                                        :picker-options="endPickerOptions"
                                        value-format="yyyy-MM-dd HH:mm"
                                        format="yyyy-MM-dd HH:mm"
                                        placeholder="YYYY-MM-DD HH:MM"
                                        popper-class="not_now"
                                        @change="dateChange('endApplyDate', endApplyDate)"
                                >
                                </el-date-picker>
                            </li>
                        </ul>
                        <div class="val_cap" v-if="validation.hasError('endApplyDate')">
                            {{ validation.firstError('endApplyDate') }}
                        </div>
                    </div>
                </li>

                <li class="time_wrap">
                    <h3 class="sub_title_m regular">{{ $t('p_d_day') }}</h3>
                    <div class="w_content_box">
                        <ul class="sub_content">
                            <li>
                                <div class="sub_title_s regular">
                                    {{ $t('d_day') }}
                                </div>
                                <el-date-picker
                                        class="range_time"
                                        v-model="releaseDate"
                                        type="datetime"
                                        :disabled="hasData()"
                                        placeholder="YYYY-MM-DD HH:MM"
                                        value-format="yyyy-MM-dd HH:mm"
                                        format="yyyy-MM-dd HH:mm"
                                        popper-class="not_now"
                                        :picker-options="releasePickerOptions"
                                        @change="dateChange('releaseDate', releaseDate)"

                                >
                                </el-date-picker>
                            </li>
                        </ul>
                        <div class="val_cap" v-if="validation.hasError('releaseDate')">
                            {{ validation.firstError('releaseDate') }}
                        </div>
                    </div>
                </li>

                <li class="amount_enroll_wrap">
                    <h3 class="sub_title_m regular">{{ $t('n_self_enroll') }}</h3>
                    <div class="w_content_box">
                        <el-select class="select a_enroll_select"
                                   v-model="chance"
                                   popper-class="category_select "
                                   :popper-append-to-body="false"
                                   @change="parentChange('chance',Number(chance))">
                            <el-option value="1" :label="1"></el-option>
                            <el-option value="2" :label="2"></el-option>
                            <el-option value="3" :label="3"></el-option>
                            <el-option value="4" :label="4"></el-option>
                            <el-option value="5" :label="5"></el-option>
                        </el-select>
                    </div>
                </li>

            </ul>

            <div class="i_create_footer">
                <div class="create_footer_check">
                    <div class="check_wrap">
                        <el-checkbox v-model="termsCheck" @click="changeCheck"></el-checkbox>
                        <!--                        <span class="check_span" v-html="$t('competition_terms')"></span>-->
                        <span class="item_text_span bold"
                              @click="openTermModal('terms-modal', 'contest')">{{ $t('terms_e_enroll') }}</span>
                        <span class="item_text_span" v-html="$t('agree_terms2')"></span>
                    </div>
                </div>
                <div class="btn_wrap">
                    <div class="save_btn one_btn" @click="submit">{{ $t('enroll_comp') }}</div>
                </div>
                <div class="caption_check mt10" v-if="termsMsg"><p>{{ $t('caption_check') }}</p></div>
                <div class="caption_check mt10" v-if="hasAllError"><p>{{ $t('caption_check2') }}</p></div>
            </div>
        </div>
        <judge-search-modal></judge-search-modal>
    </div>
</template>

<script>

import competitionValidator from "@/mixins/validators/competitionValidator";
import elFileMixins from "@/mixins/elFileMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import priceMixins from "@/mixins/priceMixins";
import imageOption from "@/mixins/imageOption";
import {mapState} from "vuex";
import JudgeSearchModal from "@/components/modal/JudgeSearchModal";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import quillEditorMixins from "@/mixins/quillEditorMixins";

export default {
    name: "CompetitionWriteDefaultLayout",
    mixins: [quillEditorMixins, competitionValidator, elFileMixins, CompetitionCategoryMixin, alertMixins, imageOption, priceMixins],
    components: {JudgeSearchModal},
    inject: ['competitionWriteSetData'],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            guaranteeType: 1,
            status: 1,
            detailCategorys: {},
            hashData: '',
            hashInput: '',
            hasAllError: false,
            termsCheck: false,
            termsMsg: false,
            evaluateInput: '',
            startPickerOptions: {
                disabledDate: this.returnStartDate,
            },
            endPickerOptions: {
                disabledDate: this.returnEndDate,
            },
            releasePickerOptions: {
                disabledDate: this.returnReleaseDate,
            },
            judgeData: [],
            dataReadOnly: false,

        };
    },
    beforeRouterEnter() {
    },
    created() {
        if (this.hasData()) {
            this.initSetData();
        }
    },
    mounted() {
        EventBus.$on('CompetitionWriteDefaultLayoutValidator', this.checkValidator);
        EventBus.$on('judgeSelect', this.judgeSelect);
    },
    beforeDestroy() {
        EventBus.$off('CompetitionWriteDefaultLayoutValidator');
        EventBus.$off('judgeSelect');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initSetData() {

            this.c_idx = this.competitionData.c_idx;
            this.parentChange('c_idx', this.c_idx);
            this.title = this.competitionData.c_title;
            this.parentChange('title', this.title);
            this.content = this.competitionData.c_context;
            this.parentChange('content', this.content);
            this.category = this.returnCategory(this.competitionData.cc_idx);
            this.parentChange('category', this.category);
            this.hashtag = this.returnHashtag(this.competitionData.Hashtag);
            this.parentChange('hashtag', this.hashtag);
            this.poster = this.competitionData.Poster
            this.parentChange('poster', this.poster)
            this.organize = this.competitionData.c_organize
            this.parentChange('organize', this.organize)
            this.target = this.competitionData.c_target
            this.parentChange('target', this.target)
            this.license = this.competitionData.c_license
            this.parentChange('license', this.license)
            this.evaluate = this.returnEvaluates(this.competitionData.Evaluates)
            this.parentChange('evaluate', this.evaluate)
            this.intro = this.competitionData.Intro
            this.parentChange('intro', this.intro)
            this.prize = this.competitionData.c_prize
            this.parentChange('prize', this.prize)
            this.startApplyDate = this.competitionData.c_start_time
            this.parentChange('startApplyDate', this.startApplyDate)
            this.endApplyDate = this.competitionData.c_end_time
            this.parentChange('endApplyDate', this.endApplyDate)
            this.releaseDate = this.competitionData.c_release_time
            this.parentChange('releaseDate', this.releaseDate)
            this.chance = this.competitionData.c_apply_cnt
            this.parentChange('chance', this.chance)
            this.judgeData = this.competitionData.Judges
            this.returnJudge(this.judgeData)
            this.parentChange('u_judge', this.u_judge)

            this.is_judge = this.competitionData.c_is_judge
            this.parentChange('is_judge', this.is_judge)

        },
        openModal(name) {
            this.$modal.show(name);
        },
        openTermModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        removeList(type, index, e) {
            e.stopPropagation()
            this[type].splice(index, 1);
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
        setData(type, value) {
            this[type] = value
        },
        parentChange(type, value) {
            this[type] = value
            if (!util.isEmpty(this.competitionWriteSetData) && util.isFunction(this.competitionWriteSetData)) {
                this.competitionWriteSetData(type, value);
            }
            if (!util.isEmpty(this.competitionVueSetData) && util.isFunction(this.competitionVueSetData)) {
                this.competitionVueSetData('hasAllError', false)
            }

        },
        checkValidator(dataArr) {
            this.$validate(dataArr)
        },
        changeFileType(type) {
            this.fileType = type;
        },
        changeHash(key, value) {
            if (this.hashtag.length > 49) {
                this.errorAlert(this.$t('l_hash'));
                return;
            }
            if (util.isEmpty(value)) {
                return;
            }
            let hash = {type: key, tag: value};
            if (key === 1) {
                this.hashData = value;
            }
            let hashIndex = this.hashtag.findIndex(category => category.tag === value);
            if (hashIndex > -1) {
                return
            }
            this.hashtag.push(hash);
            this.hashInput = ''
            this.parentChange('hashtag', this.hashtag);
        },
        returnHashTag(data) {
            let detailTag = Number(data.tag);
            let hashIndex = this.detailCategories.findIndex(category => category.node_id === detailTag);
            if (hashIndex > -1) {
                return this.detailCategories[hashIndex].NAME;
            }
        },
        setEvaluate() {
            if (this.evaluate.length > 9) {
                this.errorAlert(this.$t('l_evaluate'));
                return;
            }
            if (util.isEmpty(this.evaluateInput)) {
                return;
            }

            this.evaluate.push(this.evaluateInput);
            this.evaluateInput = ''
            this.parentChange('evaluate', this.evaluate);
        },
        returnStartDate(time) {
            if (!util.isEmpty(this.endApplyDate)) {
                return time.getTime() > this.$moment(this.endApplyDate) || time.getTime() < this.$moment();
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }
        },
        returnEndDate(time) {
            if (!util.isEmpty(this.startApplyDate)) {
                return time.getTime() < this.$moment(this.startApplyDate);
            } else {

                return time.getTime() < this.$moment().add(-1, 'day');
            }

        },
        returnReleaseDate(time) {
            if (!util.isEmpty(this.endApplyDate)) {
                console.log(time.getTime() < this.$moment(this.endApplyDate))
                return time.getTime() < this.$moment(this.endApplyDate).add(-23, 'hours');
            } else {
                return time.getTime() < this.$moment().add(-1, 'day');
            }

        },
        dateChange(key, value) {
            let date = this.$moment(value).format('YYYY-MM-DD HH:mm:ss')
            if (key === 'endApplyDate' && !util.isEmpty(this.releaseDate)) {
                let releaseDate = this.$moment(this.endApplyDate) > this.$moment(this.releaseDate) ? '' : this.$moment(this.releaseDate)
                this.parentChange('releaseDate', releaseDate)
            }
            console.log(key, value)
            this.parentChange(key, date)
            this.$forceUpdate();
        },
        returnEvaluates(arr) {
            let list = []
            util.each(arr, (eva, index, arr) => {
                list.push(eva.cs_evaluate)
            })
            return list
        },
        returnHashtag(hashList) {
            let list = []
            util.each(hashList, (tag) => {
                let hash = ''
                if (tag.ch_type === 1) {
                    hash = {type: 1, tag: tag.cc_idx}
                } else {
                    hash = {type: 2, tag: tag.ch_hashtag}
                }

                list.push(hash)
            })
            return list
        },
        returnCategory(value) {
            // let categoryIndex = this.categories.findIndex(category => category.node_id === value);
            // if (!util.isEmpty(this.categories[categoryIndex].child)) {
            //     this.detailCategories = this.categories[categoryIndex].child;
            // } else {
            //     this.detailCategories = [];
            // }
            return value
        },
        deleteHash(hash) {
            let idx = this.hashtag.findIndex(value =>
                    (value.value === hash.value) && (value.tag === hash.tag)
            )
            this.hashtag.splice(idx, 1)
        },
        deleteEvaluate(eva, idx) {
            this.evaluate.splice(idx, 1)
        },
        hasData() {
            return !util.isEmpty(this.competitionData)
        },
        judgeSelect(data) {
            // this.judgeData = [...this.judgeData,...data];
            for (let i = 0; i < this.judgeData.length; i++) {
                if (this.judgeData[i].mb_nick === data.mb_nick) {
                    this.errorAlert(`${this.$t('dup_more_judge')}`);
                    return false;
                }
            }
            if (this.judgeData.length > 4) {
                this.errorAlert(`${this.$t('no_more_judge')}`);
                return false;
            } else {
                this.judgeData.push(data);
                this.u_judge.create.push(data.mb_no)

                let list = []
                this.judgeData.forEach(v => {
                    list.push(v.mb_no)
                })
                this.judge = list
                this.parentChange('judge', this.judge)
                this.parentChange('u_judge', this.u_judge)
            }
        },
        returnProfile(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.Profile)) {
                return "";
            }
            return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, 'jpg')}`;
        },
        deleteJudge(index) {
            let mbno = this.judgeData[index].mb_no
            let u_idx = this.u_judge.update.indexOf(mbno)
            let c_idx = this.u_judge.create.indexOf(mbno)
            if (u_idx > -1) {
                this.u_judge.update.splice(u_idx, 1)
            }
            if (c_idx > -1) {
                this.u_judge.create.splice(c_idx, 1)
            }

            if (this.judgeData[index].hasOwnProperty('cbj_idx') && !util.isEmpty(this.judgeData[index].cbj_idx)) {
                this.u_judge.delete.push(mbno)
            }
            this.judgeData.splice(index, 1);
            let list = []

            this.judgeData.forEach(v => {
                list.push(v.mb_no)
            })
            this.judge = list
            this.parentChange('judge', this.judge)
            this.parentChange('u_judge', this.u_judge)
        },
        checkPrize() {
            //이고 왜???
            // this.prize = this.prize < 100 ? 100 : this.prize
            let re_prize = Math.floor(this.prize / 100) * 100
            this.parentChange('prize', re_prize)
        },
        returnImage(image, width = 45, height = 45) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnJudge(judge) {
            judge.forEach(v => {
                if (v.hasOwnProperty('cbj_idx') && !util.isEmpty(v.cbj_idx)) {
                    this.u_judge.update.push(v.mb_no)
                }
            })
        },
        changeIsJudge() {
            this.judgeData.forEach(v => {
                if (this.u_judge.update.indexOf(v.mb_no) > -1) {
                    this.u_judge.delete.push(v.mb_no)
                }
            })
            this.u_judge.create = []
            this.u_judge.update = []
            this.judge = []
            this.judgeData = []
            this.parentChange('u_judge', this.u_judge)
            this.parentChange('judge', this.judge)
            this.parentChange('is_judge', this.is_judge)
        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);
            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
        },
        submit() {
          this.createConfirm({
            content: this.$t('popup_phone'),
            cancelText : this.$t('do_later'),
            confirmText : this.$t('do_enroll'),
          })
            if (!this.termsCheck) {
                this.termsMsg = true
            } else {
                this.termsMsg = false
                EventBus.$emit('writeCompetition')
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
