<template>
  <div>
    <div class="notice">
      <div class="notice_top">
        <div class="notice_title">
          {{ boardData.wr_subject }}
        </div>
        <div class="notice_date mt6">
          {{ boardData.created_at }}
        </div>
      </div>
      <div class="notice_detail_content">
        <div class="nd_content ql-editor" v-html="replaceBr(boardData.wr_content)"></div>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "BoardDetailDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {
      type: Object,
      default: () => {
        return []
      }
    },
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    replaceBr(content){
      if(util.isEmpty(content)){
        return '';
      }
      return content.replace(/(\n|\r\n)/g, '<br>');
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
