import 'babel-polyfill'
import 'es6-promise/auto'
import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store'
import _ from "lodash";
import API from '@/api/index'
import moment from 'moment';
import VueMomentJs from 'vue-momentjs';
import ElementUI from 'element-ui'
import locale from 'element-ui/lib/locale/lang/ko'
import 'element-ui/lib/theme-chalk/index.css'
import VModal from 'vue-js-modal';
import 'expose-loader?$!expose-loader?jQuery!jquery'
import Storage from 'vue-web-storage';
import PageHeader from "@/components/PageHeader"
import i18n from './i18n'
import momentTimezone from "moment-timezone";
import Paginate from 'vuejs-paginate';
import device from "vue-device-detector"

Vue.component('paginate', Paginate);

import GAuth from 'vue-google-oauth2'
import Meta from 'vue-meta';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
Vue.use(device)
Vue.use(Meta);
const gauthOption = {
    clientId: '16176604297-kjgbfb5dsv3sc9bndrgc24b3koe4acpu.apps.googleusercontent.com',
    scope: 'profile email',
    prompt: 'select_account'
}
Vue.use(GAuth, gauthOption)
window.$ = require('jquery')
Vue.use(VueMomentJs, momentTimezone);
Vue.use(VModal);
Vue.use(API)
// Vue.use(ElementUI, {locale});
Vue.use(ElementUI, {
    i18n: (key, value) => i18n.t(key, value)
})
Object.defineProperty(Vue.prototype, "$_", { value: _ });
Vue.config.productionTip = false;
Vue.use(Storage, {
  prefix: 'com.dgmonglab.blockchain_mobilewep', // default `app_`
  drivers: ['local'], // default 'local'
});
Vue.component("page-header", PageHeader);


new Vue({
    router,
    store,
    i18n,
    render: h => h(App)
}).$mount('#app')
