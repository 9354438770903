<template>
  <div>
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <div slot="headerBtns">
              <button class="h_search_btn" @click="$router.push('/search')"></button>
          </div>
      </page-header>
      <page-header class="sub_header"></page-header>
    <div class="dgm_wrap padding_side">
      <div class="mypage_auction_wrap">
        <div class="notice_tabs">
          <button class="notice_item_3_tab two_tab" :class="{'active':tab==='auctionBid'}" @click="changeTab('auctionBid')">
            {{ $t('bid') }}
          </button>
          <div class="notice_item_3_tab two_tab" :class="{'active':tab==='auctionExhibit'}" @click="changeTab('auctionExhibit')">
            {{ $t('exhibit') }}
          </div>
        </div>
        <item-list-component :item-type="'myAuction'" v-if="tab === 'auctionBid'"></item-list-component>
        <item-list-component :item-type="'myAuctionExhibit'"
                             v-else-if="tab === 'auctionExhibit'"></item-list-component>
      </div>
    </div>
  </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";

export default {
  name: "MyPageAuction",
  mixins: [AuthMixins],
  components: {
    ItemListComponent,
    MyPageHeaderComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      tab: 'auctionBid',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    if (this.getMemberType() === 'curator') {
      this.$router.replace('/mypage/curation');
      return false;
    }
    this.tab = this.$route.query.tab;
    if (util.isEmpty(this.tab)) {
      this.changeTab('auctionBid');
    }
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
    changeTab(tab) {
      this.tab = '';
      this.$nextTick(() => {
        this.tab = tab
      })
      this.$router.replace(`${this.$route.path}?tab=${tab}`);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
