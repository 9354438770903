<template>
    <div class="point_withdraw_wrap">
        <div class="withdraw_wrap my_account">
            <h3 class="sub_title_m regular">{{$t('my_bank')}}</h3>

            <ul class="withdraw_box">
                <li>
                    <h3 class="sub_title_s regular">{{$t('bank_name')}}</h3>
                    <div class="input_wrap">
                        <div class="input_box">{{bkName}}</div>
                        <div class="write_btn"  @click="movePage('/mypage/update')"></div>
                    </div>
                </li>

                <li>
                    <h3 class="sub_title_s regular">{{$t('account_number')}}</h3>
                    <div class="input_wrap">
                        <div class="input_box">{{acNumber}}</div>
                    </div>
                </li>

                <li>
                    <h3 class="sub_title_s regular">{{$t('account_holder')}}</h3>
                    <div class="input_wrap">
                        <div class="input_box">{{acName}}</div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="withdraw_wrap">
            <h3 class="sub_title_m regular">{{$t('withdrawal_re')}}</h3>
            <ul class="withdraw_box">
                <li>
                    <h3 class="sub_title_s regular">{{$t('withdraw_possible_mingle')}}</h3>
                    <div class="input_wrap">
                        <div class="input_box">{{ returnCommas(haveMg) }}</div>
                        <div class="mg">Mg</div>

                    </div>
                </li>

                <li>
                    <h3 class="sub_title_s regular">{{$t('withdraw__mingle')}}</h3>
                    <div class="input_wrap">
                        <el-input class="input_box" :placeholder="$t('withdraw_err1')" type="tel" v-model="point" @input="returnNumber('point',point)"></el-input>
                        <div class="mg">Mg</div>
                    </div>
                </li>

                <li>
                    <h3 class="sub_title_s regular">{{$t('w_after_mingle')}}</h3>
                    <div class="input_wrap">
                        <div class="input_box">{{ returnAfterMg() }}</div>
                        <div class="mg">Mg</div>
                    </div>
                </li>

                <div class="validation" v-if="validation.hasError('point')">
                    {{ validation.firstError('point') }}
                </div>
                <div class="validation" v-if="!validation.hasError('point') && maxPriceErrorMsg">
                    {{maxPriceErrorMsg}}
                </div>
            </ul>
        </div>

        <div class="i_create_footer">
            <div class="create_footer_check">
                <div class="check_wrap">
                    <el-checkbox v-model="noticeCheck" @click="changeTerms"></el-checkbox>
                    <span v-html="$t('point_withdraw_terms')"></span>
                </div>
            </div>
            <div class="btn_wrap" >
                <div class="save_btn one_btn" @click="Withdraw()">{{$t('withdrawal_re')}}</div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import priceMixins from "@/mixins/priceMixins";
import pointValidator from "@/mixins/validators/pointValidator";
export default {
    name: "PointWriteWithdrawLayout",
    mixins: [alertMixins,priceMixins,pointValidator],
    components: {},
    inject: ['pointSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            bkName: '',
            acNumber: '',
            acName: '',
            haveMg: '',
            point: '',
            afterMg: '',
            msgTemp: [],
            minimumSetting: 0,
            noticeCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms;
    },
    mounted() {
        this.initData();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initData(){
            if(!util.isEmpty(this.UserInfo.Account)){
                this.bkName = this.UserInfo.Account[0].ma_bank;
                this.acNumber = this.UserInfo.Account[0].ma_account;
                this.acName = this.UserInfo.Account[0].ma_name;
            }
            this.haveMg = this.UserInfo.point;
        },
        movePage(url) {
            this.$router.push(url);
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },

        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);
            if(number > Number(this.haveMg)){
                number = Number(this.haveMg);
            }
            this.pointSetData('point', number)
            this[type] = this.returnCommas(number);
        },
        returnAfterMg(){
            let total = 0;
            let point = this.returnMaxPrice(this.point);
            total = Number(this.haveMg) - Number(point);
            return this.returnCommas(total);
        },
        Withdraw(){
            if (util.isEmpty(this.UserInfo.Account)) {
                this.errorAlert(this.$t('popup_bank'))
                return
            }
            if(this.noticeCheck){
                EventBus.$emit('withdraw');
            }else{
                this.errorAlert(this.$t('withdraw_exp'))
            }

        },
        changeTerms() {
            this.noticeCheck = !this.noticeCheck;
        },
        openTerms(name, type){
            this.$modal.show(name, {termsType: type})
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
