<template>
    <div class="mypage_point_history_wrap">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
      <div class="mypage_point_tab_wrap">
        <div class="mypage_point_tab col2" @click="changeTab()">
          {{ $t('mingle_status') }}
        </div>
        <div class="mypage_point_tab col2 on">
          {{ $t('usage_history') }}
        </div>
      </div>
        <point-list-component :point-type="'pointHistory'"></point-list-component>
    </div>
</template>

<script>
import PointListComponent from "../../components/point/PointListComponent";
import PointChargeComponent from "../../components/point/PointChargeComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";

export default {
    name: "MyPagePointHistory",
    mixins: [],
    components: {MyPageHeaderComponent, PointChargeComponent, PointListComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointHistory',
            backUrl: 'pointStatus'
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.backUrl = this.$route.query.backUrl;
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            if (tab !== 'pointHistory') {
                tab = this.backUrl;
            }

            this.tab = '';
            if (tab !== 'point') {
                this.$router.replace(`/mypage/point/${tab}`);
            } else {
                this.$router.replace(`/mypage/point`);
            }

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
