<template>
    <modal name="popup-modal"
           class="popup-modal"
           :pivotY="0.3"
           :width="`100%`"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="modal_wrap">
            <div class="modal_header">
                <div class="modal_title">{{ $t('item_member_info') }}</div>
                <div class="modal_close" @click="closeModal()"></div>
            </div>
            <div class="modal_content">
                <!--                창작자-->
                <div class="item_detail_popup_wrap">
                    <div class="creator_popup board_none" v-if="checkOriginal(itemData)">
                        <div class="creator_text">{{ $t('k_creator') }}</div>
                        <member-detail-component :member-key="itemData.im_originator"
                                                 :member-type="'itemMemberInfo'"></member-detail-component>
                    </div>
                    <div class="creator_popup no_profile bor board_none" v-else>
                        <div class="creator_text">{{ $t('k_creator') }}</div>

                        <div class="profile_info_wrap">
                            <div class="profile_img"></div>
                            <div class="nickname">{{ $t('notInfo') }}</div>
                            <div class="profile_description">
                                {{ $t('notProvider') }}
                            </div>
                        </div>

                    </div>

                    <!--                    소유자-->
                    <div class="creator_popup">
                        <div class="creator_text">{{ $t('owner') }}</div>
                        <member-detail-component :member-key="itemData.provider"
                                                 :member-type="'itemMemberInfo'"></member-detail-component>
                    </div>

                    <!--                    큐레이터-->
                    <div class="creator_popup " v-if="checkCurator(itemData)">
                        <div class="creator_text">{{ $t('curator') }}</div>
                        <member-detail-component :member-key="itemData.ibc_curator"
                                                 :member-type="'itemMemberInfo'">
                        </member-detail-component>
                    </div>
                    <!--                    컬렉션-->
                    <div class="creator_popup ">
                        <div class="creator_text">{{ $t('collection') }}</div>
                        <collect-detail-component :collect-key="itemData.Collection.c_idx" v-if="returnCollect(itemData)"
                                                  :collect-type="'itemCollectInfo'"></collect-detail-component>
                    </div>
                </div>

            </div>
        </div>
    </modal>
</template>

<script>

import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import util from "@/mixins/util";
import CollectDetailComponent from "@/components/collect/CollectDetailComponent";

export default {
    name: "PopupModal",
    mixins: [],
    components: {CollectDetailComponent, MemberDetailComponent},
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            itemData: {},
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.itemData = event.params.itemData
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        closeModal() {
            this.$modal.hide('popup-modal');
        },
        movePage(url) {
            this.$router.push(url);
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        checkOriginal(data) {
            if (!util.isEmpty(data.im_originator)) {
                return true
            }
            return false;
        },
        returnCollect(data) {
            return !util.isEmpty(data.Collection)

        }

    },

    watch: {},
};
</script>

<style scoped>

</style>
