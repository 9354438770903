<template>
    <div class="competition_wrap pb17">
        <div class="competition_item thumbnail_item mypage_filter_item">
            <div class="competition_input">
                <el-input :placeholder="$t('st')" v-model="searchInput" @keypress.enter.native="setFilterFunc('u_name', searchInput)"/>
                <button class="competition_btn" @click="setFilterFunc('u_name', searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_filter_btn" @click="$modal.show('item-filter-utility-filter')"></button>
        </div>
        <item-filter-utility-filter></item-filter-utility-filter>
    </div>
</template>


<script>
import utilityUtil from "@/components/utility/UtilityUtil";
import EventBus from "@/utils/event-bus";
import ItemFilterUtilityFilter from "@/components/filter/ItemFilterUtilityFilter";

const {filterConfig} = utilityUtil;
export default {
    name: "UtilityFilterMemberLayout",
    mixins: [],
    inject : ['setFilter'],
    components: { ItemFilterUtilityFilter},
    props: {

    },
    data() {
        return {
          searchInput : '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
      EventBus.$on('setUtilityFilterMemberModal', this.setUtilityFilterMemberModal);
      EventBus.$on('setFilterModal', this.setFilterModal)
    },
    beforeUnmount() {
      EventBus.$off('setUtilityFilterMemberModal')
      EventBus.$off('setFilterModal');
    },
    unmounted() {
    },
    computed: {

    },
    methods: {
      setUtilityFilterMemberModal(key){
        this.setFilterFunc('sellst', key.sellst);
        this.setFilterFunc('uc_idx', key.uc_idx);
        this.setFilterFunc('usest', key.usest);
      },
      setFilterFunc(key, value) {
        this.setFilter(key, value);
      },
      setFilterModal(key){
        this.setFilterFunc('uc_idx', key.uc_idx);
      }
    },
    watch: {
    },
}
</script>
