<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap padding_side">
            <board-detail-component :board-type="'faqDetail'" :board-key="boardKey"></board-detail-component>
            <!--    <board-detail-faq-layout></board-detail-faq-layout>-->
        </div>
    </div>
</template>

<script>
import BoardDetailComponent from "@/components/board/BoardDetailComponent";

export default {
  name: "FaqDetail",
  mixins: [],
  components: {
    BoardDetailComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      boardKey: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.boardKey = this.$route.params.idx;
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
