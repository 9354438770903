<template>
    <modal name="curation-request-modal"
           class="curation-request-modal"
           :pivotX="0.5"
           :pivotY="0.36"
           :adaptive="true"
           :width="'90%'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>

            <div class="update-change-modal_wrap">
                <div class="nickname_title mb25">
                    {{ $t('terms_curation') }}
                </div>
                <div class="rollback_btn" @click="closeModal()"></div>
                <div class="clear"></div>
                <div class="login_signup_input nickname_change mb25">
<!--                    <el-input type="textarea" class="curation_request_textarea" placeholder="규정노출"/>-->
                    <div class="curation_request_textarea">
                        <div class="el-textarea__inner has_scroll">
                            <terms-component :terms-type="'curation'"></terms-component>
                        </div>
                    </div>
                </div>
                <div class="check_wrap mb10" >
                    <el-checkbox v-model="termsCheck" @change="changeTerms">
                        <span class="check_span register_terms"  v-html="$t('apply_curation_text1')"></span>
                    </el-checkbox>
                </div>
                <button class="two_btn back_gray" @click="closeModal">{{ $t('cancel') }}</button>
                <button class="two_btn back_navy" @click="checkTerms()">{{ $t('request') }}</button>
                <div class="clear"></div>
                <div class="val_cap" v-if="isNotCheckTerms">{{ $t('curation_create_err2') }}</div>
            </div>
        </div>
    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";
import TermsComponent from "@/components/terms/TermsComponent";

export default {
    name: "CurationRequestModal",
    mixins: [],
    components: {
        TermsComponent
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            termsCheck: false,
            scroll: 0,
            isNotCheckTerms: false,
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('curation-request-modal');
        },
        handlerBeforeOpen(event) {
            this.scroll = event.params.scroll
            document.body.style.top = `-${this.scroll}px`
        },

        handlerOpened() {
        },
        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },
        movePage(url) {
            this.$router.push(url);
        },
        changeTerms() {
            this.isNotCheckTerms = false;
        },
        checkTerms() {
            if(!this.termsCheck) {
                this.isNotCheckTerms = true;
            }else{
                EventBus.$emit('curationWrite');
                this.closeModal();
            }
        },
    },

    watch: {},
};
</script>

<style scoped>

</style>
