<template>
    <div class="item_detail_popup_wrap" @click="movePage(`/collect/${collectData.c_idx}`)">
        <div>
            <div class="profile_info_wrap">
                <div class="profile_img" :style="`background-image: url(${returnMainImage()});`"></div>

                <div class="nickname">{{ collectData.c_title }}</div>

                <div class="p_r_middle_content">
                    <div class="heart_box">
                        <img src="@/assets/image/s_heart.png">
                        <span>{{ collectData.Likes.cnt }}</span>
                    </div>

                    <div class="item_box">
                        <div class="item_text">Item</div>
                        <span>{{ collectData.item_cnt }}</span>
                    </div>
                </div>

            </div>
            <div class="profile_description">
                {{ collectData.c_intro }}
            </div>
        </div>


    </div>
</template>

<script>
import util from "@/mixins/util";
import LikeCollectionComponent from "@/components/like/LikeCollectionComponent";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import priceMixins from "@/mixins/priceMixins";
import ShareCollectComponent from "@/components/share/ShareCollectComponent";

export default {
    name: "CollectDetailInfoLayout",
    mixins: [imageResizeMixins, priceMixins],
    components: {ShareCollectComponent, LikeCollectionComponent,},
    inject: [],
    provide() {
        return {}
    },
    props: {
        collectData: {
            default: () => {
            }
        },

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {

        returnImage() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.BgFile)) {
                return `${this.collectData.CollectionFiles.BgFile[0].org_url}?${this.setImageOptions(710, 456, 'webp')}`;

            } else {
                return '';
            }
        },
        returnMainImage() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.MainFile)) {
                return `${this.collectData.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(180, 180, 'webp')}`;
            }
        },
        returnProfile() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.OwnerFile) && !util.isEmpty(this.collectData.OwnerFile.Profile)) {
                return `${this.collectData.OwnerFile.Profile[0].org_url}?${this.setImageOptions(50, 50, 'webp')}`;
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnMinMingle() {
            if (!util.isEmpty(this.collectData.min_price)) {
                return this.collectData.min_price
            } else {
                return 0
            }
        },
        movePage(url) {
            this.$router.push(url)
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>