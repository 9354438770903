import { render, staticRenderFns } from "./LikeCompetitionDetailLayout.vue?vue&type=template&id=74101402&scoped=true"
import script from "./LikeCompetitionDetailLayout.vue?vue&type=script&lang=js"
export * from "./LikeCompetitionDetailLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74101402",
  null
  
)

export default component.exports