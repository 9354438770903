<template>
    <component :is="loader" v-if="loader" :utilityData="utilityData" :utilityKey="utilityKey" :userKey="userKey"></component>
</template>

<script>
import {defineAsyncComponent} from "vue";

export default {
    name: "UtilityListLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        listLayout: '',
        utilityData: {
            default: () => []
        },
        utilityKey: {
            default: 0
        },
        userKey: {
            default: 0
        },
    },
    data() {
        return {
            component: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
        loader() {
            if (!this.listLayout) {
                return defineAsyncComponent(() => import('@/components/utility/layout/utilityList/list/UtilityListDefaultLayout.vue'));
            }
            return defineAsyncComponent(() => import(`@/components/utility/layout/utilityList/list/${this.listLayout}.vue`))
        },
    },
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
