<template>
    <div class="curation_submit_wrap">
        <el-input type="textarea" v-model="content" :placeholder="$t('board_write_con')" maxlength="1000" @input="resetErrMsg" :readonly="hasBeforeCurator()" class="curation_textarea"/>
        <p class="item_text_p">{{ $t('apply_curator_text1') }}</p>
        <div class="radio_wrap curation_radio_wrap">
            <div class="real_item">
                <div class="w_content_box">
                    <div class="item_file">
                        <div class="item_file_box has_radius">
                            <el-upload
                                class=""
                                action=""
                                :limit="5"
                                :file-list="files"
                                :auto-upload="false"
                                :show-file-list="false"
                                :on-change="handleEveryFileChange"
                                :on-remove="fileRemove"
                                :disabled="files.length >= 5"
                            >
                                <button class="upload-name ">
                                    <span>{{ $t('file_upload_s') }}</span>
                                </button>
                                <!--                <img v-else src="@/assets/image/main/change_item.png" alt=""
                                                     style="width: 93px; height: 74px;">-->
                            </el-upload>
                            <div class="el-upload el-upload--picture-card el-upload-curation no_width mt13">
                                <div class="filebox filebox_curation no_width mt0" v-for="(file, index) in files" :key="`file${index}`">
                                    <div class="file_name file_name_curation ">
                                        <p class="one_line" :class="{'no_remove' : hasBeforeCurator()}">{{ file.org_name }}</p>
                                        <button class="drop_file drop_file_curation" @click="removeFile(file, index)" v-if="!hasBeforeCurator()"></button>
                                    </div>
                                </div>
                            </div>
                        </div>
<!--                        <div class="item_file_box">
                            <div tabindex="0" class="el-upload el-upload&#45;&#45;picture-card el-upload-curation">
                                <button class="upload-name">
                                    <span>파일 업로드</span>
                                </button>
                                <div class="filebox filebox_curation">
                                    <div class="file_name file_name_curation">
                                        <p>파일명_2021-10-24.pdf</p>
                                        <button class="drop_file drop_file_curation"></button>
                                    </div>
                                </div>
                            </div>
                        </div>-->
                    </div>
                    <!---->
                    <!---->
                </div>
            </div>
        </div>
        <div class="i_create_footer">
            <div class="create_footer_check"  v-if="!hasBeforeCurator()">
                <div class="check_wrap">
                    <el-checkbox v-model="isActive" @change="changeActive"></el-checkbox>
                    <span class="item_text_span cur_text_span" v-html="$t('apply_curator_text2')"></span>
<!--                    <span class="item_text_span cur_text_span cur_text_span1 bold">큐레이터 신청 규정</span>
                    <span class="item_text_span cur_text_span">을 확인하였으며 동의합니다.</span>-->
                </div>
            </div>
            <div class="cur_btn_position">
<!--                //active 됐을 때 버튼 보이게 처리-->
                <div class="curation_btn_wrap active" v-if="hasBeforeCurator()">
                    <div class="cur_btn_wrap ">
                        <button class="temp_btn one_btn" @click="openCancelConfirm()">{{ $t('apply_cancel') }}</button>
                    </div>
                </div>
                <div class="curation_btn_wrap active" v-else>
                    <div class="btn_wrap cur_btn_wrap ">
                        <div class="temp_btn two_btn" @click="$router.back()">{{ $t('cancel') }}</div>
                        <div class="save_btn two_btn" @click="checkValidator">{{ $t('apply1') }}</div>
                    </div>
                </div>
                <div class="val_cap center" v-if="errorMsg != ''">
                    {{errorMsg}}
                </div>
            </div>

            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import alertMixins from "@/mixins/alertMixins";
import awsS3FileUpload from "@/mixins/awsS3FileUpload";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationApplicationComponent",
    mixins: [elFileMixins, alertMixins, awsS3FileUpload],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            content: '',
            isActive: false,

            myCurator: {},
            fileInfo: [
                {
                    fileName: 'files',
                    fileType: 'curator',
                },
            ],
            errorMsg: "",

            isRunning: false,
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms;
        this.getMyCurator();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initData() {
            this.content = "";
            this.files = [];
            this.deleteFiles = [];
            this.isActive = false;
            this.errorMsg = "";
        },
        changeActive() {
            this.isActive = !this.isActive;
            this.resetErrMsg();
        },
        getMyCurator() {
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$curation.getMyCurator().then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if (!util.isEmpty(res.List)) {
                        this.myCurator = res.List[0];
                        this.content = res.List[0].c_context;
                        if (!util.isEmpty(res.List[0].Files)) {
                            let files = res.List[0].Files;
                            this.files = files.filter(file => file.a_idx != null);
                        }
                    } else {
                        this.myCurator = {};
                    }
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
        hasBeforeCurator() {
            return !util.isEmpty(this.myCurator) && !util.isEmpty(this.myCurator.c_idx);
        },
        removeFile(file, index) {
            if (file.hasOwnProperty('a_idx') && !util.isEmpty(file.a_idx)) {
                this.deleteFiles.push(file);
            }
            this.files.splice(index, 1);
        },
        resetErrMsg() {
            this.errorMsg = "";
        },
        checkValidator() {
            if (this.isRunning) {
                return false;
            }
            this.isRunning = false;
            if (!this.isActive) {
                this.errorMsg = this.$t('caption_check');
                this.isRunning = false;
            } else if (util.isEmpty(this.content) || util.isEmpty(this.files)) {
                this.errorMsg = this.$t('caption_check2')
                this.isRunning = false;
            } else {
                this.createCurator();
            }
        },
        createCurator() {
            EventBus.$emit('changeLoading', true);
            let params = {
                c_context: this.content,
            };

            try {
                this.$api.$curation.applicationCurator(params).then(res => res.Data.Result).then(res => {
                    let type = this.returnCreateCurator(res.Info.type);
                    if (type === true) {
                        let key = res.List.c_idx;
                        this.setMultiFileUpload(key, this.fileInfo)
                    } else {
                        EventBus.$emit('changeLoading', false);
                        this.errorAlert(type);
                        this.isRunning = false;
                    }
                })
            } catch (e) {
                console.log(e);
                this.isRunning = false;
            }
        },
        setMultiFileUpload(key, fileInfo) {
            this.runUploadFileInfo(fileInfo, 0, key).then(res => {
                if (res) {
                    this.writeAfterFunc(key);
                }
            })
        },
        returnCreateCurator(type) {
            switch (type) {
                case 1:
                    return true;
                case -211:
                    return this.$t('curator_application_err1');
                case -212:
                    return this.$t('curator_application_err2');
                case -251:
                    return this.$t('curator_application_err5');
                case -252:
                    return this.$t('curator_application_err6');
                case -253:
                    return this.$t('curator_application_err7');
                default:
                    return this.$t('curator_application_err3');
            }
        },

        writeAfterFunc(key) {
            this.isRunning = false;
            EventBus.$emit('changeLoading', false)
            this.initData();
            this.createConfirm({
                content: `${this.$t('curator_application_success1')}<br>${this.$t('curator_application_success2')}`,
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.push('/myprofile')
                },
                cancel: () => {
                    this.getMyCurator();
                },
            })
        },

        openCancelConfirm() {
            this.createConfirm({
                content: this.$t('curator_cancel_confirm'),
                confirm: () => {
                    this.runCuratorCancel();
                },
            })
        },
        runCuratorCancel() {
            this.deleteFiles = this.files.slice();

            let params = {
                c_idx: this.myCurator.c_idx,
            };
            try {
                this.$api.$curation.cancelCurator(params).then(res => res.Data.Result).then(res => {
                    let type = this.returnCancelCurator(res.Info.type);
                    if (type === true) {
                        if (util.isEmpty(this.deleteFiles)) {
                            this.runS3ApiDelete(this.deleteFiles).then(res => {
                                this.cancelAfter();
                            })
                        } else {
                            this.cancelAfter();
                        }
                    } else {
                        this.errorAlert(type);
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        returnCancelCurator(type) {
            switch (type) {
                case 1:
                    return true;
                case -213:
                    return this.$t('curator_cancel_err1');
                case -212:
                    return this.$t('curator_application_err2');
                case -202:
                    return this.$t('curator_cancel_err2');
                default:
                    return this.$t('curator_cancel_err3');
            }
        },
        cancelAfter() {
            this.initData();
            this.getMyCurator();
            this.createAlert({
                content: this.$t('curator_cancel_success'),
            })
        },
        openTerms(name) {
            this.$modal.show(name, {termsType: 'curatorForm'});
        },
    },
    watch: {},
}
</script>


<style scoped>

</style>
