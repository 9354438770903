<template>
  <div class="creator_detail_top_wrap">
    <div class="member_background"></div>
    <div class="profile_wrap">

      <div class="profile_img_box" @click="openModal(`profile-setting-modal`)">
        <img src="@/assets/image/mypage_profile.png">
      </div>

      <div class="profile_right_wrap">

        <div class="p_r_middle_content">
          <div class="heart_box">
            <img src="@/assets/image/s_heart.png">
            <span>251</span>
          </div>

          <div class="item_box">
            <div class="item_text">Item</div>
            <span>1,251</span>
          </div>
        </div>


        <div class="share_box">
          <img src="@/assets/image/share.png">
        </div>
      </div>
    </div>

    <div class="nickname_wrap">
      <div class="nickname_text">NickName</div>
      <div class="card_wrap">
        <div class="curator_card">Curator</div>
        <div class="special_card">Special</div>
      </div>
    </div>

    <div class="curation_btn_wrap">
      <div class="c_history" @click="openModal('curation-history-modal')">{{$t('curation_history')}}</div>
      <div class="c_request" @click="movePage('/curation/:mb_no/request')">{{$t('c_request')}}</div>
    </div>

    <div class="url_wrap">
      <a href="#">http://www.staccatovivace.com</a>
      <a href="#">http://www.facebook.com/ad1dkfjsdiogkg</a>
    </div>

    <div class="intro_wrap">
      <p>저는 회화 작가 입니다. 요즘 경매에 너무 재미를 느끼고 있어요.
        제 창작품의 값어치가 정해진다는 것이 너무나 신기합니다.</p>
    </div>

  </div>
</template>

<script>
import ProfileSettingModal from "@/components/modal/ProfileSettingModal";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import CurationWriteItemsLayout from "@/template/curation/write/CurationWriteItemsLayout";
export default {
  name: "MemberDetailDefaultLayout",
  mixins: [],
  components: {
    CurationWriteItemsLayout,
    CurationHistoryModal,
    ProfileSettingModal
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
      memberData: {default: () => {}},
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    movePage(url) {
      this.$router.push(url);
    },
    openModal(name) {
      this.$modal.show(name);
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
