<template>
    <modal name="faq-filter-modal" class="filter-modal faq-filter-modal not_bottom"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_type">
                    <div class="modal_title">{{ $t('category') }}</div>
                    <div class="radio_wrap">
                        <div class=" inline_block">
                            <el-radio-group v-model="filterCategory"
                                               @change="setFilterFunc('ca', filterCategory)">
                                <el-radio :label="''">{{$t('all')}}</el-radio>
                                <el-radio v-for="data in categoryList"
                                             :key="`catrgory${data.bc_name}`" :label="data.bc_idx">{{ data.bc_name }}</el-radio>
                            </el-radio-group>
                        </div>

                    </div>
                </div>
<!--                <div class="wrapper_bottom">
                    <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
                </div>-->
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import boardFilterUtil from "@/components/board/boardFilterUtil";

const {filterConfig} = boardFilterUtil;
export default {
    name: "FaqFilterModal",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            filterData: {},
            filterCategory: '',
            categoryList: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCategory();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (event.params.hasOwnProperty('filterData')) {
                this.filterData = event.params.filterData;
            }
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('faq-filter-modal');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
            this.modalClose();
        },

        getCategory() {
            let params = {
                type: 'post'
            }
            this.$api.$board.getCategory(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || res.List.length === 0) {
                    this.createAlert({
                        title: '',
                        content: this.$t('no_data_loading'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    });
                    return;
                }
                this.categoryList = res.List;
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
