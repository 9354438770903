<template>
    <modal name="openmarket-filter-modal" class="filter-modal openmarket-filter-modal"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_status" v-if="tab == 0">
                    <div class="modal_title">{{ $t('status') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterCategory"
                                               @change="setFilterFunc('ic', filterCategory)">
                                <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                                <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                                <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                                <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                                <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                                <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <!--        <div class="mt20 filter_modal_status">-->
                <!--          <div class="modal_title">{{ $t('exposure') }}</div>-->
                <!--          <div class="check_wrap">-->
                <!--            <div class="checkbox inline_block">-->
                <!--              <el-checkbox-group v-model="filterCategory"-->
                <!--                                 @change="setFilterFunc('ic', filterCategory)">-->
                <!--                <el-checkbox :label="2">{{ $t('b_update') }}</el-checkbox>-->
                <!--                <el-checkbox :label="9">{{ $t('b_enter_m') }}</el-checkbox>-->
                <!--                <el-checkbox :label="3">{{ $t('b_like') }}</el-checkbox>-->
                <!--                <el-checkbox :label="5">{{ $t('priceDesc') }}</el-checkbox>-->
                <!--                <el-checkbox :label="4">{{ $t('priceAsc') }}</el-checkbox>-->
                <!--              </el-checkbox-group>-->
                <!--            </div>-->
                <!--          </div>-->
                <!--        </div>-->

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('status') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterStatus"
                                               @change="setFilterFunc('imst',filterStatus)">
                                <el-checkbox :label="1">
                                    {{ $t('auction') }}
                                </el-checkbox>
                                <el-checkbox :label="3">
                                    {{ $t('license_sell') }}
                                </el-checkbox>
                                <el-checkbox :label="4">
                                    {{ $t('fix_selling') }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('edition') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterEdition"
                                               @change="setFilterFunc('ed', filterEdition)">
                                <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                                <el-checkbox :label="0">{{ $t('single') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_type">
                    <div class="modal_title">{{ $t('type') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterType"
                                               @change="setFilterFunc('ty', filterType)">
                                <el-checkbox :label="'digital'">{{ $t('digital') }}</el-checkbox>
                                <el-checkbox :label="'real'">{{ $t('real') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>

                    </div>
                </div>

                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('price2') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">

                            <el-input class="el_price_input el_input_num" type="text"
                                      v-model="priceInput[0]" @keypress.native.enter="changeFilter()"
                            ></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input el_input_num" type="text"
                                      v-model="priceInput[1]" @keypress.native.enter="changeFilter()"
                            ></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
                </div>
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import itemUtil from "@/components/item/itemUtil";

const {filterConfig} = itemUtil;
export default {
    name: "OpenmarketFilterModal",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            tab: 0,
            filterData: {},
            sortData: {},
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if(!util.isEmpty(event.params.filterData)) {
                this.filterData = event.params.filterData;
            }else{
                this.filterData = {};
            }
            // this.sortData = event.params.sortData;
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('openmarket-filter-modal');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
            this.$forceUpdate();
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
            this.modalClose();
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
