<template>
    <div class="s_p_img" @click="runShare()">
        <img src="@/assets/image/share_kakao.png">
        <div class="s_p_img_text">
            {{ $t('kakao_talk') }}
        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import shareUtil from "@/components/share/shareUtil";
import {kakaoService} from "@/plugins/kakaoService";
import EventBus from "@/utils/event-bus";

const {getShareLink} = shareUtil;
export default {
    name: "ShareKaKaoComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        shareType: {default: '', },
        shareData: {
            default: {
                path: '/',
                img: 'https://testimage.minglemint.com/profileBack/no_image.jpg',
                title: `밍글민트(Minglemint NFT Marketplace)`,
                description: `밍글민트(Minglemint NFT Marketplace)`
            }
        },
        runAfter:{type: Function, default:  () => {}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        runShare() {
            let link = getShareLink(this.shareData.path);
            let shareLink = encodeURIComponent(link);
            this.kakaoShare(link, this.shareData.img)
        },
        kakaoShare(link, img) {
            kakaoService().initService();
            if(util.isEmpty(window.Kakao) || util.isEmpty(window.Kakao.Link)) {
                EventBus.$emit('apiLoading', true);
                setTimeout(() => {
                    EventBus.$emit('apiLoading', false);
                    this.kakaoShare(link, img);
                }, 500)
                return false;
            }
            let msg = this.$t('share_msg')
            let readMore = this.$t('read_more')
            Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: msg,
                    imageUrl: img,
                    link: {
                        mobileWebUrl: link,
                        webUrl: link,
                    },
                },
                buttons: [
                    {
                        title: readMore,
                        link: {
                            mobileWebUrl: link,
                            webUrl: link,
                        },
                    },
                ],
                installTalk: true,
            })
            kakaoService().disconnect();
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
