<template>
  <modal name="account-registration-modal"
         class="account-registration-modal"
         :pivotX="0.5"
         :pivotY="0.36"
         :adaptive="true"
         :width="'90%'"
         :height="'290px'"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div>

      <div class="update-change-modal_wrap">
        <div class="nickname_title">
          {{$t('bank_add')}}
        </div>
        <div class="rollback_btn" @click="closeModal()"></div>
        <div class="clear"></div>
        <div class="modal_text_content mt22">
          <div class="text_content_text">
            <span v-html="$t('popup_bank')"></span>
          </div>
          <img class="mt19 mb45 enroll_icon accountregist_icon" src="@/assets/image/accountregist_icon.png">
        </div>
        <button class="two_btn back_gray">{{$t('do_later')}}</button>
        <button class="two_btn back_navy">{{$t('do_enroll')}}</button>
        <div class="clear"></div>
      </div>
    </div>
  </modal>

</template>

<script>
import {logout} from "@/mixins/Auth";
import EventBus from "@/utils/event-bus";

export default {
  name: "AccountRegistrationModal",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {};
  },
  props: {},
  data() {
    return {
    };
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {

  },
  methods: {
    closeModal() {
      this.$modal.hide('account-registration-modal');
    },
    handlerBeforeOpen(event) {
      this.scroll = event.params.scroll
      document.body.style.top = `-${this.scroll}px`
    },

    handlerOpened() {
    },

    handlerBeforeClose() {
      window.scrollTo(0, this.scroll);
    },
    movePage(url) {
      this.$router.push(url);
    },
    logout() {
      logout();
    },

  },

  watch: {},
};
</script>

<style scoped>

</style>