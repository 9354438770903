<template>
    <div class="auction_history">
        <ul>
            <li v-for="(data,index) in historyData" :key="`ah${index}`">
                <div v-if="checkLength(index)">
                    <h3 class="history_content"
                        v-html="`${ $t('bid_history_Msg', {name: data.ab_nick, price: returnCommas(data.ab_bids)}) }`"></h3>
                    <div class="auction_date">{{ returnDateFormat(data.created_at) }}</div>
                </div>
                <div v-else>
                    <h3 class="history_content"
                        v-html="returnContent(data)"></h3>
                    <div class="auction_date">{{ returnDateFormat(data.created_at) }}</div>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";

export default {
    name: "ItemListAuctionHistoryLayout",
    mixins: [dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        historyData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        checkLength(index) {
            if (index + 1 === this.historyData.length) {
                return false
            }
            return true
        },
        returnContent(data) {
            let name = data.m_nick;
            let bid = data.m_minimum;
            let price = data.m_immediately;

            if (util.isEmpty(data.m_minimum)) {
                bid = 0
            }
            if (util.isEmpty(data.m_immediately)) {
                price = 0
            }
            return `${this.$t('bid_history_Msg2', {name: name, bid: bid, price: price})}`
        },
        returnCommas(x = 0) {
            return util.Number.numFormat(x);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>