<template>
    <div class="mypage_list_wrap">
        <div class="mypage_list border-top" v-for="(data,index) in itemData" :key="`item${index}`" @click="movePage(data)">
            <div class="item_information">
                <div class="list_time">{{ data.created_at }}</div>
            </div>
            <div class="item_img_wrap mt11">
                <div class="item_img">
                    <img :src="returnThumbnail(data)">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold">[{{ checkBidStatus(data) }}]</div>
                    <div class="item_text">{{ data.i_name }}</div>
                    <img src="@/assets/image/mg_icon.png">
                    <div class="item_text bold">{{ returnCommas(data.ab_bids) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListAuctionLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(data) {
            this.$router.push(`/item/${data.i_sku}`);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
            }
        },
        checkBidStatus(data) {
            if (data.ab_status === 0) {
                return this.$t('bidFail')
            } else if (data.ab_status === 1) {
                return this.$t('bid')
            } else if (data.ab_status === 2) {
                return this.$t('s_bid')
            }
        },
        returnCommas(x = 0) {
            return util.Number.numFormat(x);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
