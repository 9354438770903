<template>
    <div>
<!--        <page-header :header_main_back="true" :header-title="`공모전`" :header_setting="true" :header_side="true"></page-header>-->

        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <div class="competition_list_text">
            <span>{{$t('competition')}}</span>
            <span class="competition_sub_text ml10">{{$t('competition_introduction_msg')}}</span>
        </div>
        <div class="pl14 pr14 mb15">
            <button class="competition_introduction_btn" @click="openUrl()">{{$t('competition_introduction')}}</button>
            <button class="competition_introduction_btn ml10" @click="movePage('/competitioncreate')">{{$t('enroll_comp')}}</button>
        </div>
        <div class="dgm_wrap">
            <div class="mypage_item_wrap">
                <div class="notice_tabs padding_side ">
                    <button class="notice_item_3_tab" :class="{ active : tab === 'all'}"
                            @click="changeTab('all')">{{ $t('all') }}
                    </button>
                    <button class="notice_item_3_tab " :class="{ active : tab === 'art'}"
                            @click="changeTab('art')">
                        {{ $t('competition_art') }}
                    </button>
                    <button class="notice_item_3_tab border_r_l" :class="{ active : tab === 'idea'}"
                            @click="changeTab('idea')">
                        {{ $t('competition_idea') }}
                    </button>
                    <button class="notice_item_3_tab border_r_l" :class="{ active : tab === 'photo'}"
                            @click="changeTab('photo')">
                        {{ $t('picture') }}
                    </button>
                    <button class="notice_item_3_tab" :class="{ active : tab === 'broad'}"
                            @click="changeTab('broad')">
                        {{ $t('competition_broad') }}
                    </button>
                    <button class="notice_item_3_tab border_r_l" :class="{ active : tab === 'etc'}"
                            @click="changeTab('etc')">
                        {{ $t('etc') }}
                    </button>
                </div>
                <div class="padding_side" v-if="tab !== ''">
                    <competition-list-component v-if="tab === 'all'" :competition-type="'competitionAll'"></competition-list-component>
                    <competition-list-component v-else-if="tab === 'art'" :competition-type="'competitionArt'"></competition-list-component>
                    <competition-list-component v-else-if="tab === 'idea'" :competition-type="'competitionCollect'"></competition-list-component>
                    <competition-list-component v-else-if="tab === 'photo'" :competition-type="'competitionPhoto'"></competition-list-component>
                    <competition-list-component v-else-if="tab === 'broad'" :competition-type="'competitionMusic'"></competition-list-component>
                    <competition-list-component v-else-if="tab === 'etc'" :competition-type="'competitionVideo'"></competition-list-component>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import util from "@/mixins/util";

export default {
    name: "competition",
    mixins: [],
    components: {
        CompetitionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'all',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if(!util.isEmpty(this.$route.query.tab)) {
            this.tab = this.$route.query.tab;
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeTab(tab) {
            if(this.tab === tab) {
                return false;
            }
            this.tab = '';
            this.$nextTick(() => {
                this.$router.replace(this.$route.path+'?tab='+tab)
                this.tab = tab
            })
        },
        movePage(url) {
            this.$router.push(url);
        },
        openUrl() {
            window.open('https://www.youtube.com/watch?v=pvivoJwJ2uA', '_blank');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
