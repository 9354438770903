<template>
    <div class="item_create_wrap">
        <div class="warning_wrap">
            <div class="warning_img"></div>
            <div class="warning_text">{{ $t('cant_change_msg') }}</div>
        </div>

        <ul class="radio_wrap">

            <!--      타입선택-->
            <li class="type_select">
                <h3 class="sub_title_m regular warning_icon">{{ $t('s_type') }}</h3>
                <div class="w_content_box">
                    <el-radio-group class="el_radio_box" v-model="type" @change="changeType('type', type)"
                                    :disabled="checkItemData()">
                        <el-radio class="el_radio_btn" :label="'digital'">{{ $t('digital') }}</el-radio>
                        <el-radio class="el_radio_btn" :label="`real`">{{ $t('real') }}</el-radio>
                    </el-radio-group>
                </div>
            </li>

            <!--        카테고리-->
            <li class="category_select">
                <h3 class="sub_title_m regular warning_icon">{{ $t('category') }}</h3>
                <div class="w_content_box">
                    <el-radio-group class="el_radio_box" v-model="category" @change="setCategory(Number(category))">
                        <el-radio class="el_radio_btn" v-for="data in itemCategorys"
                                  :key="`${data.node_id}`" :label="Number(data.node_id)">{{ returnCategoryName(data) }}
                        </el-radio>
                    </el-radio-group>
                </div>
            </li>

            <!--        아이템명-->
            <li class="item_name">
                <h3 class="sub_title_m regular warning_icon">{{ $t('i_name') }}</h3>
                <div class="w_content_box">
                    <h3 class="sub_title_s regular">{{ $t('sub_item') }}</h3>
                    <span class="input_max_length"><span>{{ title.length }}</span>/50</span>

                    <el-input class="input_txt" type="text" v-model="title"
                              @input="parentChange('title',title)" :placeholder="$t('write_sub_item')"
                              maxlength="50"/>
                </div>
            </li>

            <!--        에디션-->
            <li class="edition_setting">
                <h3 class="sub_title_m regular warning_icon">{{ $t('edition') }}</h3>
                <div class="w_content_box edition_wrap">
                    <el-radio-group class="el_radio_box" v-model="edition"
                                    @change="parentChange('edition', edition)"
                                    :disabled="checkItemData()">
                        <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                        <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                    </el-radio-group>
                    <ul class="sub_content" v-if="edition === 1">

                        <li class="real_edition">
                            <h3 class="sub_title_s regular">{{ $t('b_edi') }}</h3>
                            <el-input class="input_txt" type="number" v-model="count" v-if="type !== 'digital'"
                                      @change="checkNum('count', count)"
                                      :disabled="checkItemData()"/>
                            <span class="mr7" v-if="type !== 'digital'">/</span>
                            <el-input class="input_txt" type="number" v-model="total"
                                      @change="checkNum('total', total)"
                                      :disabled="checkItemData()"/>
                            <div class="val_cap" v-if="validation.hasError('count')">
                                {{ validation.firstError('count') }}
                            </div>
                            <div class="val_cap" v-if="validation.hasError('total') ">
                                {{ validation.firstError('total') }}
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <!--              보증서/감정서-->
            <li class="certification">
                <h3 class="sub_title_m regular warning_icon">{{ $t('certi') }}</h3>
                <div class="w_content_box">
                    <el-radio-group class="el_radio_box" v-model="guaranteeType"
                                    @change="parentChange('guaranteeType', guaranteeType)">
                        <el-radio class="el_radio_btn" :label="1">{{ $t('own') }}</el-radio>
                        <el-radio class="el_radio_btn" :label="0">{{ $t('n_own') }}</el-radio>
                    </el-radio-group>

                    <div class="sub_content" v-if="guaranteeType === 1">
                        <div class="filebox">
                            <div class="file_name" v-for="(data,index) in guarantee"
                                 :key="`guarantee${index}`">
                                <div>{{ data.name }}</div>
                                <button class="drop_file"
                                        @click="removeList('guarantee',index,e)"></button>
                            </div>
                        </div>
                        <div @click="changeFileType('guarantee')">
                            <el-upload
                                    class=""
                                    :auto-upload="false"
                                    action=""
                                    :show-file-list="false"
                                    :on-change="setGuarantee"
                            >
                                <button class="upload-name"><span>{{ $t('file_upload') }}</span>
                                </button>
                            </el-upload>
                        </div>

                    </div>
                </div>
            </li>

            <!--              썸네일-->
            <li class="detail_thumb">
                <h3 class="sub_title_m regular warning_icon">{{ $t('thumbnail') }}</h3>
                <div class="w_content_box" @click="changeFileType('thumnail')">
                    <el-upload
                            class="n_el_up"
                            action=""
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="thumnail"
                            :auto-upload="false"
                            :on-change="onlyOnePhotoChange"
                            :disabled="im_type === 'photo'"
                    >
                        <img v-if="hasFile('thumnail')" :src="thumnail[0].org_url" class="upload_img">
                        <button v-if="hasFile('thumnail')" class=""
                                @click="removeList('thumnail',0)"></button>
                        <img v-else src="@/assets/image/change_item.png" alt="" class="default_img">
                    </el-upload>
                </div>
            </li>

            <!--              아이템 원본-->
            <li class="real_item" v-if=" type === 'digital' " @click="changeFileType('original')">
                <h3 class="sub_title_m regular warning_icon">{{ $t('real_item') }}</h3>
                <div class="w_content_box">
                    <div class="item_file" v-show="!hasFile('original')">
                        <el-upload
                                class=""
                                action=""
                                list-type="picture-card"
                                :show-file-list="false"
                                :file-list="original"
                                :auto-upload="false"
                                :on-change="uploadOriginalFile"
                        >
                            <button class="upload-name"><span>{{ $t('file_upload') }}</span></button>
                        </el-upload>
                    </div>
                    <div class="filebox" v-if="hasFile('original')">
                        <div class="file_name">
                            <div>{{ original[0].org_name }}</div>
                            <button class="drop_file" v-if="hasFile('original')"
                                    @click="removeList('original',0)"></button>
                        </div>
                    </div>

                    <div class="val_cap" v-if="validation.hasError('original') && this.original.length ===0">
                        {{ validation.firstError('original') }}
                    </div>
                </div>
            </li>

            <!--              본인창작여부-->
            <li class="self_made">
                <h3 class="sub_title_m regular warning_icon">{{ $t('self_made') }}</h3>
                <div class="w_content_box self_made">
                    <el-radio-group class="el_radio_box" v-model="originator"
                                    @change="parentChange('originator', originator)">
                        <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                        <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                    </el-radio-group>
                </div>
            </li>

            <!--              세부정보-->
            <li class="detail_info_wrap">
                <h3 class="sub_title_m regular warning_icon">{{ $t('detail_info') }}</h3>
                <div class="w_content_box">
                    <div class="inform_txt">
                          <span class="">
                            {{ $t('d_info_msg1') }}<br/>
                            {{ $t('d_info_msg2') }}
                          </span>
                    </div>
                    <ul class="sub_content">
                        <li>
                            <div class="sub_title_s " v-if="im_type === 'audio'">
                                {{ $t('t_record') }}
                            </div>
                            <div class="sub_title_s" v-else-if="im_type === 'etc'">
                                {{ $t('t_create') }}
                            </div>
                            <div class="sub_title_s" v-else>
                                {{ $t('t_video') }}
                            </div>
                            <el-date-picker
                                    placeholder="04/02/2021 14:52:37 +0000"
                                    id="itemDate"
                                    type="datetime"
                                    class="date-picker"
                                    format="dd/MM/yyyy HH:mm:ss"
                                    v-model="itemDate"
                                    :readonly="exifDate"
                                    @change="dateChange('itemDate',itemDate)">
                            </el-date-picker>
                            <div class="val_cap" v-if="validation.hasError('itemDate')">
                                {{ validation.firstError('itemDate') }}
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"
                                 v-if="im_type === 'video' || im_type === 'photo'">
                                {{ $t('v_country') }}
                            </div>
                            <div class="sub_title_s" v-else>
                                {{ $t('c_country') }}
                            </div>
                            <el-input type="text" placeholder="Republic of Korea" id="country" v-model="country"
                                      :readonly="exifCountry"
                                      @input="parentChange('country',country)"/>
                            <div class="val_cap" v-if="validation.hasError('country')">
                                {{ validation.firstError('country') }}
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"
                                 v-if="im_type === 'video' || im_type === 'photo'">
                                {{ $t('v_si') }}
                            </div>
                            <div class="sub_title_s" v-else>
                                {{ $t('c_si') }}
                            </div>
                            <el-input type="text" placeholder="Gyeongsangbuk-do" id="si" v-model="si"
                                      :readonly="exifSi"
                                      @input="parentChange('si',si)"/>
                            <div class="val_cap" v-if="validation.hasError('si')">
                                {{ validation.firstError('si') }}
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"
                                 v-if="im_type === 'video' || im_type === 'photo'">
                                {{ $t('v_city') }}
                            </div>
                            <div class="sub_title_s" v-else>
                                {{ $t('c_city') }}
                            </div>
                            <el-input type="text" placeholder="Gyeongju-si" id="city" v-model="city"
                                      :readonly="exifCity"
                                      @input="parentChange('city',city)"/>
                            <div class="val_cap" v-if="validation.hasError('city')">
                                {{ validation.firstError('city') }}
                            </div>
                        </li>
                        <li v-if="im_type === 'photo'">
                            <div class="sub_title_s ">
                                {{ $t('size') }}
                            </div>
                            <el-input type="text" placeholder="4131*6196" id="photoSize" v-model="size"
                                      :readonly="exifSize"
                                      @input="parentChange('size',size)"/>
                            <div class="val_cap" v-if="validation.hasError('size')">
                                {{ validation.firstError('size') }}
                            </div>
                        </li>

                        <li v-if="im_type === 'audio' || im_type==='video'">
                            <div class="sub_title_s ">
                                {{ $t('v_long') }}
                            </div>
                            <el-input type="text" placeholder="39:12:03" id="videoSize" v-model="size"
                                      :readonly="exifSize"
                                      @input="parentChange('size',size)"/>
                            <div class="val_cap" v-if="validation.hasError('size')">
                                {{ validation.firstError('size') }}
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s ">
                                {{ $t('copyright_info') }}
                            </div>
                            <el-input type="text" placeholder="Copyright ..." id="copyright" v-model="copyright"
                                      :readonly="exifCopyright"
                                      @input="parentChange('copyright',copyright)"/>
                            <div class="val_cap" v-if="validation.hasError('copyright')">
                                {{ validation.firstError('copyright') }}
                            </div>
                        </li>
                    </ul>
                </div>
            </li>

            <!--              상세페이지 썸네일-->
            <li class="detail_thumb_wrap">
                <h3 class="sub_title_m regular">{{ $t('d_thumb') }}</h3>
                <div class="w_content_box" @click="changeFileType('detailThumnail')">
                    <el-upload
                            v-for="(data,index) in detailThumnail"
                            :key="`detailThum${index}`"
                            class="c_el_up"
                            action=""
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="thumnail"
                            :auto-upload="false"
                            :on-change="photoListChange"
                    >
                        <img v-if="hasFile('detailThumnail')"
                             :src="data.org_url" class="upload_img">
                        <button v-if="hasFile('detailThumnail')" class="upload_del_btn"
                                @click="removeList('detailThumnail',0)"></button>
                    </el-upload>
                    <el-upload
                            class="n_el_up"
                            action=""
                            list-type="picture-card"
                            :show-file-list="false"
                            :file-list="detailThumnail"
                            :auto-upload="false"
                            :on-change="photoListChange"
                    >
                        <img src="@/assets/image/change_item.png" alt="" class="default_img">
                    </el-upload>
                </div>
                <div class="clear"></div>
            </li>

            <!--              성인인증 필요-->
            <li class="adult_certi_need">
                <h3 class="sub_title_m regular">{{ $t('adult_in') }}</h3>
                <div class="w_content_box">
                    <el-radio-group class="el_radio_box" v-model="adult" @change="parentChange('adult', adult)">
                        <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                        <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                    </el-radio-group>
                </div>
            </li>

            <!--              해시태그-->
            <li class="c_hash_wrap">
                <h3 class="sub_title_m regular">{{ $t('hashtag') }}</h3>
                <div class="w_content_box">
                    <div class="sub_title_s">
                        {{ $t('d_category') }}
                    </div>
                    <div class="sub_content" v-if="!isEmptyData(detailCategorys.child)">
                        <el-select id="hash" v-model="hashData" class="w_content my_filter select"
                                   :placeholder="$t('s_category')"
                                   popper-class="select_style"
                                   :popper-append-to-body="false"
                                   @change="changeHash(1,hashData)">
                            <el-option :value="data.node_id" v-for="data in detailCategorys.child"
                                       :key="`item${data.node_id}`" :label="data.NAME">
                            </el-option>
                        </el-select>
                        <ul class="hash_tag_wrap">
                            <li v-for="(data,index) in hashtag" :key="`hash${index}`" v-if="data.type === 1">
                                <div class="hash_wrap">
                                    <span class="hash_name">{{ returnHashTag(data) }}</span>
                                    <button class="delete_btn" @click="removeHash(index)"></button>
                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="w_content w_self">
                        <el-input type="text" :placeholder="$t('w_hash')" v-model="hashInput"
                                  @input="setData('hashInput',hashInput)"
                                  @keyup.enter.native="changeHash(2,hashInput)"/>
                        <div class="plus_btn" for="addition" @click="changeHash(2,hashInput)"></div>
                        <div class="d_hash_tag_wrap" v-for="(data,index) in hashtag" :key="`hash${index}`"
                             v-if="data.type === 2">
                            <div class="hash_wrap">
                                <span class="hash_name ">{{ data.tag }}</span>
                                <button class="delete_btn" @click="removeHash(index)"></button>
                            </div>
                        </div>
                    </div>
                    <div class="val_cap" v-if="validation.hasError('hashtag')">{{
                            validation.firstError('hashtag')
                        }}
                    </div>

                </div>
            </li>

            <!--              아이템 설명-->
            <li class="inform ">
                <h3 class="sub_title_m regular">{{ $t('i_inform') }}</h3>
                <div class="edit_textarea">
<!--                    <el-input type="textarea" id="content" v-model="content"
                              @input="parentChange('content',content)"
                              :placeholder="$t('input_inform')"></el-input>-->
                    <quill-editor
                        ref="contentEditor"
                        v-model="content"
                        :options="editorOption"
                    />
                    <div class="val_cap" v-if="validation.hasError('content')">
                        {{ validation.firstError('content') }}
                    </div>
                </div>
            </li>

        </ul>

        <div class="i_create_footer">
            <div class="create_footer_check">
                <div class="check_wrap">
                    <el-checkbox v-model="termsCheck"></el-checkbox>
                  <span class="check_span" v-html="$t('item_terms')"></span>
<!--                    <span class="item_text_span bold">{{ $t('t_e_item') }}</span>-->
<!--                    <span class="item_text_span ">{{ $t('agree_terms2') }}</span>-->
                </div>
            </div>
            <div class="btn_wrap">
                <div class="temp_btn two_btn" @click="save('writeItemTemporary',2)">{{ $t('temporary_save') }}</div>
                <div class="save_btn two_btn" @click="save('writeItemTemporary',1)">{{ $t('do_enroll') }}</div>
            </div>
        </div>

        <img v-if="hasFile('original') && im_type === 'photo' " :src="original[0].org_url" id="originalImg"
             style="display:none">
        <video controlslist="nodownload"
               class="video-js h100p w100p"
               controls playsinline
               ref="videoPlayer"
               style="display:none"
               id="originalVideo" v-if="hasFile('original') && im_type === 'video' ">
            <source :src="original[0].org_url">
        </video>
    </div>
</template>


<script>
import quillEditorMixins from "@/mixins/quillEditorMixins";
import {mapState} from "vuex";
import itemValidator from "@/mixins/validators/itemValidator";
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import EXIF from "exif-js"
import dateMixins from "@/mixins/dateMixins";


export default {
    name: "ItemWriteDefaultLayout",
    mixins: [quillEditorMixins, itemValidator, elFileMixins, alertMixins, dateMixins],
    components: {},
    inject: ['itemWriteSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemCategorys: {},
            detailCategorys: {},
            hashData: '',
            hashInput: '',
            fileCheck: '',
            checkOriginalType: false,
            exifData: '',
            exifSize: true,
            exifDate: false,
            exifCopyright: false,
            exifCountry: false,
            exifSi: false,
            exifCity: false,
            termsCheck: false,

        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (!util.isEmpty(this.itemData)) {
            this.initSetData();
        }
    },
    mounted() {
        this.setDefaultData();
        EventBus.$on('ItemWriteDefaultLayoutValidator', this.checkValidator);
        $(document).scrollTop(0);
    },
    beforeDestroy() {
        EventBus.$off('ItemWriteDefaultLayoutValidator');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        setDefaultData() {
            this.itemCategorys = this.itemCategoryList;
            if (util.isEmpty(this.itemData) && !util.isEmpty(this.itemCategoryList)) {
                this.detailCategorys = this.itemCategoryList[0];
                this.parentChange('category', this.itemCategorys[0].node_id)
            }
        },
        initSetData() {
            this.i_idx = this.itemData.i_idx;
            this.parentChange('i_idx', this.i_idx);
            this.title = this.itemData.i_name;
            this.parentChange('title', this.title);
            this.content = this.itemData.i_memo;
            this.content = util.htmlDecode(this.content);
            this.parentChange('content', this.content);
            this.type = this.itemData.i_type;
            this.parentChange('type', this.type);
            this.category = this.itemData.ic_idx;
            this.parentChange('category', this.category);
            let categoryIndex = this.itemCategoryList.findIndex(category => category.node_id === this.category);
            this.detailCategorys = this.itemCategoryList[categoryIndex];
            this.edition = this.itemData.im_edition;
            this.parentChange('edition', this.edition);
            this.count = this.itemData.im_count;
            this.parentChange('count', this.count);
            if (!util.isEmpty(this.itemData.im_total)) {
                this.total = this.itemData.im_total;
                this.parentChange('total', this.total);
            }
            this.i_status = this.itemData.i_status;
            this.parentChange('i_status', this.i_status);
            if (util.isEmpty(this.itemData.im_originator)) {
                this.originator = 0
                this.parentChange('originator', this.originator);
            } else {
                this.originator = 1
                this.parentChange('originator', this.originator);
            }

            this.im_type = this.itemData.im_type;
            this.parentChange('im_type', this.im_type);
            this.enroll = this.itemData.im_enroll;
            this.parentChange('enroll', this.enroll);
            this.adult = this.itemData.im_adult_chk;
            this.parentChange('adult', this.adult);
            this.itemDate = this.itemData.imo_date;
            this.parentChange('itemDate', this.itemDate);
            this.country = this.itemData.imo_country;
            this.parentChange('country', this.country);
            this.si = this.itemData.imo_si;
            this.parentChange('si', this.si);
            this.city = this.itemData.imo_city;
            this.parentChange('city', this.city);
            this.size = this.itemData.imo_size;
            this.parentChange('size', this.size);
            if (this.itemData.im_type === 'photo') {
                let afterSize = this.size.split('*');
                this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
            }
            this.copyright = this.itemData.imo_copyright;
            this.parentChange('copyright', this.copyright);
            this.itemData.Hash.forEach(data => {
                let hash = {type: data.ih_type, tag: data.ih_hashtag}
                if (data.ih_type === 1) {
                    hash.tag = data.ic_idx;
                }
                this.hashtag.push(hash);
            })
            this.parentChange('hashtag', this.hashtag);

            if (!util.isEmpty(this.itemData.ItemFile.Detail)) {
                if (!util.isEmpty(this.itemData.ItemFile.Detail[0].a_idx)) {
                    this.detailThumnail = this.itemData.ItemFile.Detail;
                }
                this.parentChange('detailThumnail', this.detailThumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Guaranty)) {
                if (!util.isEmpty(this.itemData.ItemFile.Guaranty[0].a_idx)) {
                    this.guarantee = this.itemData.ItemFile.Guaranty;
                    this.guaranteeType = 1;
                }
                this.parentChange('guarantee', this.guarantee);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Item)) {
                if (!util.isEmpty(this.itemData.ItemFile.Item[0].a_idx)) {
                    this.thumnail = this.itemData.ItemFile.Item;
                }
                this.parentChange('thumnail', this.thumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Item)) {
                if (!util.isEmpty(this.itemData.ItemFile.Item[0].a_idx)) {
                    this.original = this.itemData.ItemFile.Item;
                }
                this.parentChange('original', this.original);
            }
        },
        movePage(url) {
            this.$router.push(url);
        },
        parentChange(type, value) {
            if (type === 'edition' || type === 'type') {
                this.count = 0;
                this.total = 0;
            }
            this[type] = value;
            this.itemWriteSetData(type, value);
        },
        changeType(type, value) {
            this.parentChange(type, value);
            this.original = [];
            if (value === 'real') {
                this.parentChange('im_type', 'etc')
                if (!util.isEmpty(this.thumnail)) {
                    let orgFile = Object.assign({}, this.thumnail[0]);
                    this.original[0] = orgFile;
                    this.parentChange('original', this.original);
                }
            } else {
                this.afterFileType = '';
                this.checkOriginal();
            }
        },
        setData(key, value) {
            this[key] = value;
        },
        changeHash(key, value) {
            if (util.isEmpty(value)) {
                return false;
            }
            if (this.hashtag.length > 50) {
                this.errorAlert(this.$t('l_hash'));
                return;
            }
            let hash = {type: key, tag: value};
            if (key === 1) {
                this.hashData = value;
            }
            let hashIndex = this.hashtag.findIndex(category => category.tag === value);
            if (hashIndex > -1) {
                return
            }
            this.hashtag.push(hash);
            this.parentChange('hashtag', this.hashtag);
            this.hashInput = "";
        },
        removeHash(index) {
            this.hashtag.splice(index, 1);
        },
        setCategory(value) {
            this.parentChange('category', value);
            this.hashtag = [];
            this.hashData = '';
            this.hashInput = '';
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === value);
            if (!util.isEmpty(this.itemCategorys[categoryIndex].child)) {
                this.detailCategorys = this.itemCategorys[categoryIndex];
            } else {
                this.detailCategorys = [];
            }

        },
        returnHashTag(data) {
            if (util.isEmpty(data) || util.isEmpty(this.detailCategorys)) {
                return
            }
            let lang = this.$localStorage.get('lang');
            let detailTag = Number(data.tag);
            let hashIndex = this.detailCategorys.child.findIndex(category => category.node_id === detailTag);
            if (hashIndex > -1) {
                if (lang === 'ko') {
                    return this.detailCategorys.child[hashIndex].NAME;
                } else {
                    return this.detailCategorys.child[hashIndex].NAME;
                }

            }
        },

        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this.creatorCheck) {
                this.creatorCheck = true;
            } else {
                this.creatorCheck = false;
            }
        },
        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        changeFileType(type) {
            this.fileType = type;
        },
        checkValidator(dataArr) {
            this.$validate(dataArr)
        },
        checkOriginal() {
            if (this.afterFileType === '' || this.afterFileType === 'none') {
                this.parentChange('im_type', "")
                return '';
            } else if (this.afterFileType === 'video') {
                this.parentChange('im_type', 'video')
                return 'video'
            } else if (this.afterFileType === 'audio') {
                this.parentChange('im_type', 'audio')
                return 'audio'
            } else if (this.afterFileType === 'image') {
                if (this.fileCheck) {
                    this.fileCheck = false;
                    this.thumnail = this.$_.cloneDeep(this.original);
                    this.parentChange('thumnail', this.thumnail);
                }
                this.parentChange('im_type', 'photo')
                return 'image'
            } else {
                this.parentChange('im_type', 'etc')
                return 'etc'
            }
        },

        handleEveryOneFileChange(file, fileList) {
            let type = file.raw.type.split('/');
            file.name = this.regExp(file.name)
            this.afterFileType = type[0];
            file.org_url = file.url;
            file.org_name = file.name;
            let maxSize = this.fileMaxSize;
            if (file.size > maxSize) {
                this.errorAlert(this.$t('uploadFile_error'));
                return;
            }
            if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                this.files[0] = fileList;
                if (this.parentChange) {
                    this.parentChange('files', this.files);
                }
                this.$forceUpdate();
                return
            } else {
                if (this.fileType === 'original') {
                    this.afterFileType = type[0];
                }
                if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                    file.a_idx = this[this.fileType][0].a_idx;
                }
                this[this.fileType][0] = file;
                this.parentChange(this.fileType, this[this.fileType]);
                this.$forceUpdate();
                this.checkOriginal();
                return
            }
        },
        removeList(type, index, e) {
            if (type === 'original') {
                this.afterFileType = 'none';
                this.checkOriginalType = false;
                this.resetExifData();
                this.checkOriginal();
            }
            this[type].splice(index, 1);
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
        photoListChange(file, fileList) {
            if (this.detailThumnail.length > 9) {
                this.errorAlert(this.$t('l_img1'))
            } else {
                this.handleFileChange(file, fileList);
            }
        },

        setGuarantee(file, fileList) {
            if (this.guarantee.length > 4) {
                this.errorAlert(this.$t('l_img2'))
            } else {
                this.handleEveryFileChange(file, fileList);
            }
        },

        uploadOriginalFile(file, fileList) {
            let fileData = file.raw;
            this.fileCheck = true;
            let _this = this
            EXIF.getData(fileData, function () {
                _this.exifData = this.exifdata;
            });
            this.handleEveryOneFileChange(file, fileList)
        },
        changeExifTime(key, value) {
            this[key] = value;

            let time = this.returnDateFormat(value, 'YYYY-MM-DD HH:mm:ss');
            this.itemWriteSetData(key, time);
        },
        setExifData() {
            if (util.isEmpty(this.exifData)) {
                if (this.im_type === 'photo') {
                    let img = document.getElementById('originalImg')
                    let size = `${img.width}*${img.height}`;
                    this.parentChange('size', size);
                    this.exifSize = true;
                } else if (this.im_type === 'video') {
                    let play = document.getElementById('originalVideo');
                    // play.onloadedmetadata = function() {
                    //     let playtime = play.duration;
                    //     console.log(playtime);
                    //     this.parentChange('size', playtime);
                    //     this.exifSize = true;
                    // };
                    let playtime = play.duration;
                    console.log(playtime);
                    this.parentChange('size', playtime);
                    this.exifSize = true;
                }
                return
            }

            if (this.exifData.hasOwnProperty('ImageHeight') && this.exifData.hasOwnProperty('ImageWidth')) {
                this.exifSize = true;
                this.parentChange('size', `${this.exifData.ImageWidth}*${this.exifData.ImageHeight}`);
            } else if (this.exifData.hasOwnProperty('PixelXDimension') && this.exifData.hasOwnProperty('PixelYDimension')) {
                this.exifSize = true;
                this.parentChange('size', `${this.exifData.PixelXDimension}*${this.exifData.PixelYDimension}`);
            } else {
                this.exifSize = true;
                let img = document.getElementById('originalImg')
                let size = `${img.width}*${img.height}`;
                this.parentChange('size', size);
            }
            if (this.exifData.hasOwnProperty('DateTime')) {
                this.exifDate = true;
                let date = Date(this.exifData.DateTime)
                let time = this.returnDateFormat(date, 'MM/DD/YYYY HH:mm:ss')

                this.changeExifTime('itemDate', time)
            }
            if (this.exifData.hasOwnProperty('Copyright')) {
                this.exifCopyright = true;
                this.parentChange('copyright', this.exifData.Copyright);
            }
            if (this.exifData.hasOwnProperty('ImageDescription')) {
                this.parentChange('title', this.exifData.ImageDescription);
            }
            if (this.exifData.hasOwnProperty('GPSLatitude') && this.exifData.hasOwnProperty('GPSLongitude')) {
                let latDegree = this.exifData.GPSLatitude[0].numerator / this.exifData.GPSLatitude[0].denominator;
                let latMinute = this.exifData.GPSLatitude[1].numerator / this.exifData.GPSLatitude[1].denominator;
                let latSecond = this.exifData.GPSLatitude[2].numerator / this.exifData.GPSLatitude[2].denominator;
                let latDirection = this.exifData.GPSLatitudeRef;
                let lat = this.setGps(latDegree, latMinute, latSecond, latDirection)
                let lonDegree = this.exifData.GPSLongitude[0].numerator / this.exifData.GPSLongitude[0].denominator;
                let lonMinute = this.exifData.GPSLongitude[1].numerator / this.exifData.GPSLongitude[1].denominator;
                let lonSecond = this.exifData.GPSLongitude[2].numerator / this.exifData.GPSLongitude[2].denominator;
                let lonDirection = this.exifData.GPSLongitudeRef;
                let long = this.setGps(lonDegree, lonMinute, lonSecond, lonDirection);
                this.getGeocode(lat, long)
            }

        },
        setGps(degrees, minutes, seconds, direction) {
            let gps = degrees + (minutes / 60) + (seconds / 360000);
            if (direction == "S" || direction == "W") {
                gps = gps * -1;
            }
            return gps;
        },
        getGeocode(lat, long) {
            if (util.isEmpty(google)) {
                return false;
            }
            const geocoder = new google.maps.Geocoder();
            let latlng = {
                lat: parseFloat(lat),
                lng: parseFloat(long),
            }
            let _this = this
            geocoder.geocode({'location': latlng}, function (results, status) {
                let address = results[0].address_components;
                let countryIndex = address.findIndex(add => add.types[0] === 'country');
                if (countryIndex > -1) {
                    _this.exifCountry = true;
                    _this.parentChange('country', address[countryIndex].long_name);
                }
                let doIndex = address.findIndex(add => add.types[0] === 'administrative_area_level_1');
                if (doIndex > -1) {
                    _this.exifSi = true;
                    _this.parentChange('si', address[doIndex].long_name);
                }
                let siIndex = address.findIndex(add => add.types[0] === 'locality');
                if (siIndex > -1) {
                    _this.exifCity = true;
                    _this.parentChange('city', address[siIndex].long_name);
                }
            })
        },
        returnCategoryName(data) {
            if (data.node_id === 2) {
                return this.$t('art');
            } else if (data.node_id === 3) {
                return this.$t('picture');
            } else if (data.node_id === 4) {
                return this.$t('video');
            } else if (data.node_id === 5) {
                return this.$t('music');
            } else if (data.node_id === 9) {
                return this.$t('collections');
            } else if(data.node_id === 178){
                return this.$t('prompt')
            }
        },
        checkNum(type, value) {
            this.parentChange(type, value);
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        checkItemData() {
            return !util.isEmpty(this.itemData);
        },
        resetExifData() {
            this.exifSize = false;
            this.exifDate = false;
            this.exifCopyright = false;
            this.exifCountry = false;
            this.exifSi = false;
            this.exifCity = false;
            this.exifData = '';
            this.parentChange('country', '')
            this.parentChange('city', '')
            this.parentChange('size', '')
            this.parentChange('si', '')
            this.parentChange('copyright', '')
            this.parentChange('itemDate', '')
        },
        dateChange(key, value) {
            let date = this.$moment(value).format('MM/DD/YYYY HH:mm:ss')
            this.itemDate = date;
            this.changeExifTime(key, date)
        },
        onlyOnePhotoChange(file, fileList) {
            let maxSize = this.imageMaxSize;
            let type = file.raw.type.split('/');

            file.name = this.regExp(file.name)
            if (file.size > maxSize) {
                this.errorAlert('1회 업로드 가능 ' + this.imageSizeError + '를 초과했습니다!');
                fileList.pop();
            } else if (!(type[1] === 'jpeg' || type[1] === 'png' || type[1] === 'gif')) {
                this.errorAlert('이미지 파일만 업로드 가능합니다.')
                fileList.pop();
            } else {
                file.org_url = file.url;
                if (this.fileType === '' || !this.hasOwnProperty(this.fileType)) {
                    if (this.files.length > 0 && this.files[0].hasOwnProperty('a_idx') && this.files[0].a_idx != null) {
                        file.a_idx = this.files[0].a_idx;
                    }
                    this.files[0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange('files', this.files);
                    }
                } else {
                    if (this[this.fileType].length > 0 && this[this.fileType][0].hasOwnProperty('a_idx') && this[this.fileType][0].a_idx != null) {
                        file.a_idx = this[this.fileType][0].a_idx;
                    }
                    this[this.fileType][0] = file;
                    this.$forceUpdate();
                    if (this.parentChange) {
                        this.parentChange(this.fileType, this[this.fileType]);
                    }
                    if (this.fileType == 'thumnail' && this.type === 'real') {
                        let orgFile = Object.assign({}, file);
                        this.original[0] = orgFile;
                        if (this.parentChange) {
                            this.parentChange('original', this.original);
                        }
                    }
                }
            }
        },
        save(type,data) {
            if (!this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit(type, data);
        },

    },
    watch: {
        'content': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('content', this.content)
            },
        },
        'exifData': {
            deep: true,
            handler: function (val, oldVal) {
                this.setExifData();
            },
        },
        'original': {
            deep: true,
            handler: function (val, oldVal) {
                this.setExifData();
            },
        },
        'count': {
            deep: true,
            handler: function (val, oldVal) {
                if (val * 1 > this.total * 1 && this.total) {
                    this.count = this.total;
                } else if (val * 1 > 100) {
                    this.count = 100;
                }
            }
        },
        'total': {
            deep: true,
            handler: function (val, oldVal) {
                if (val * 1 > 100) {
                    this.total = 100;
                }
            }
        },
        'itemCategoryList': {
            deep: true,
            handler: function (val, oldVal) {
                this.setDefaultData();
            }
        },
    },
}
</script>

<style scoped>

</style>
