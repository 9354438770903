<template>
    <div class="search_title padding_side mb15"> {{ searchData }} {{ $t('search_result') }}
        <span class="title_bold">{{ paginationData.total }}</span> {{ $t('amount') }}
        <div class="competition_list mt10">
            <div class="competition_top mb30" @click="movePage(`/competition/${data.c_uuid}`)"
                 v-for="(data,index) in competitionData" :key="`competition${index}`">
                <div class="competition_result">
                    <div class="profile_img"
                         :style="` background-image: url(${returnThumbnail(data)})`"></div>
                    <div class="competition_sort mt10">{{ returnCategoryName(data.cc_idx) }}</div>
                    <div class="competition_title mt7">{{ data.c_title }}</div>
                    <div class="competition_time mt7">
                        {{ data.c_context }}
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListSearchDetailLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: [],
        paginationData: {},
    },
    data() {
        return {
            itemCategorys: [],
            searchData: "",
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemCategorys = this.itemCategoryList
        this.searchData = this.$route.query.ti;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Poster)) {
                return `${data.Poster[0].org_url}?${this.setImageOptions(278, 387, 'png')}`;
                // return this.setFitWidthImg(data.Poster, 278);
            }
        },
        returnCategoryName(data) {
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === data);
            if (categoryIndex > -1) {
                return this.itemCategorys[categoryIndex].NAME_ENG
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
