<template>
  <div>
      CurationListSendLayout
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "CurationListSendLayout",
    mixins: [alertMixins, imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationStatus: curationStatus,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        cancelRequest(curation) {
            this.createConfirm({
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" + this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListCancel', curation)
                },
            })
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_idx}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_idx}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_idx}`
            }
            this.$router.push(url);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
