<template>
    <div>
        <li class="sell_price_wrap">
            <h3 class="sub_title_m regular">{{ $t('selling_price') }}</h3>
            <div class="w_content_box">
                <div class="input_wrap" v-if="exifType === 'photo'">
                    <div v-if="smallType">
                        <h3>Small</h3>
                        <p>{{ Math.floor(smallSize[0]) }}*{{ Math.floor(smallSize[1]) }} px </p>
                        <div class="input_box">
                            <input type="tel" v-model="smallPrice" placeholder="0"
                                   @input="returnNumber('smallPrice',smallPrice)"
                                   class="el_input_num"></input>
                            <div class="mg">Mg</div>
                        </div>
                        <div class="validation mt10" v-if="validation.hasError('smallPrice')">
                            {{ validation.firstError('smallPrice') }}
                        </div>
                        <div class="validation mt10" v-if="!validation.hasError('smallPrice') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                    <div v-if="mediumType">
                        <h3>Medium</h3>
                        <p>{{ Math.floor(mediumSize[0]) }}*{{ Math.floor(mediumSize[1]) }}
                            px</p>
                        <div class="input_box">
                            <input type="tel" v-model="mediumPrice" placeholder="0"
                                   @input="returnNumber('mediumPrice',mediumPrice)"
                                   class="el_input_num"></input>
                            <div class="mg">Mg</div>
                        </div>
                        <div class="validation mt10" v-if="validation.hasError('mediumPrice')">
                            {{ validation.firstError('mediumPrice') }}
                        </div>
                        <div class="validation mt10" v-if="!validation.hasError('mediumPrice') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                    <div v-if="largeType">
                        <h3>Large</h3>
                        <p>{{ Math.floor(largeSize[0]) }}*{{ Math.floor(largeSize[1]) }} px </p>
                        <div class="input_box">
                            <input type="tel" v-model="largePrice" placeholder="0"
                                   @input="returnNumber('largePrice',largePrice)"
                                   class="el_input_num"></input>
                            <div class="mg">Mg</div>
                        </div>
                        <div class="validation mt10" v-if="validation.hasError('largePrice')">
                            {{ validation.firstError('largePrice') }}
                        </div>
                        <div class="validation mt10" v-if="!validation.hasError('largePrice') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                    <div>
                        <h3>Original</h3>
                        <p>{{ Math.floor(originalSize[0]) }}*{{ Math.floor(originalSize[1]) }} px </p>
                        <div class="input_box">
                            <input type="tel" v-model="originalPrice" placeholder="0"
                                   @input="returnNumber('originalPrice',originalPrice)"
                                   class="el_input_num"></input>
                            <div class="mg">Mg</div>
                        </div>
                        <div class="validation mt10" v-if="validation.hasError('originalPrice')">
                            {{ validation.firstError('originalPrice') }}
                        </div>
                        <div class="validation mt10" v-if="!validation.hasError('originalPrice') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                </div>
                <div class="input_wrap" v-else>
                    <div>
                        <h3>{{ $t('license_sell_amount') }}</h3>

                        <div class="input_box">
                            <input type="tel" v-model="originalPrice" placeholder="0"
                                   @input="returnNumber('originalPrice',originalPrice)"
                                   class="el_input_num"></input>
                            <div class="mg">Mg</div>
                        </div>
                        <div class="validation mt10" v-if="validation.hasError('originalPrice')">
                            {{ validation.firstError('originalPrice') }}
                        </div>
                        <div class="validation mt10" v-if="!validation.hasError('originalPrice') && maxPriceErrorMsg">
                            {{ maxPriceErrorMsg }}
                        </div>
                    </div>
                </div>
            </div>
        </li>

        <li class="license_caution_wrap">
            <h3 class="sub_title_m regular">{{ $t('caution_license') }}</h3>
            <div class="w_content_box">
                <div class="input_wrap">
                    <h3>{{ $t('cautions') }}</h3>
                    <div class="input_box">
                        <el-input type="textarea" v-model="caution" @input="parentChange('caution', caution)"
                                  :placeholder="$t('caution_license_input')"/>
                    </div>
                </div>
            </div>
        </li>
    </div>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import {mapState} from "vuex";
import util from "@/mixins/util";
import licenseResizeMixins from "@/components/item/lib/licenseResizeMixins";
import priceMixins from "@/mixins/priceMixins";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemLicenseLayout",
    mixins: [licenseResizeMixins, priceMixins],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        statusExifData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            validatorArr: ['smallPrice', 'mediumPrice', 'largePrice', 'originalPrice'],
            smallType: false,
            mediumType: false,
            largeType: false,
            smallPrice: '',
            mediumPrice: '',
            largePrice: '',
            extraLargePrice: '',
            originalPrice: '',
            caution: '',
            exifType: '',
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        } else if (this.isExifData()) {
            this.itemData = this.statusExifData
            this.settingItemData();
        }
    },
    mounted() {
        this.setValidatorArr(this.validatorArr)
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        isExifData() {
            return !util.isEmpty(this.statusExifData);
        },
        settingItemData() {
            this.exifType = this.itemData.exifType;
            if (this.exifType === 'photo' && !util.isEmpty(this.itemData.exif.size)) {
                let afterSize = this.itemData.exif.size.value.split('*');
                this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
                this.parentChange('smallType', this.smallType)
                this.parentChange('mediumType', this.mediumType)
                this.parentChange('largeType', this.largeType)
                if (this.smallType) {
                    this.smallPrice = this.itemData.smallPrice;
                    this.returnNumber('smallPrice', this.smallPrice)
                }
                if (this.mediumType) {
                    this.mediumPrice = this.itemData.mediumPrice;
                    this.returnNumber('mediumPrice', this.mediumPrice)
                }
                if (this.largeType) {
                    this.largePrice = this.itemData.largePrice;
                    this.returnNumber('largePrice', this.largePrice)
                }
                this.originalPrice = this.itemData.originalPrice;
                this.returnNumber('originalPrice', this.originalPrice)
                this.caution = this.itemData.caution;
            }

        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);

            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {
            this.$validate(this.validatorArr)
        },

    },
    watch: {},
    validators: {
        smallPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.smallType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        mediumPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.mediumType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        largePrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.largeType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        originalPrice: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg19'))
                    .custom(() => {
                        if (value === '' || Number(value) === 0) {
                            return this.$t('itemErrorMsg19')
                        }

                        let str = String(value);
                        let last = String(str.substring(str.length - 2, str.length))
                        if (last !== '00') {
                            return this.$t('Mg_msg')
                        }
                    })
        },
        caution: function (value) {
            return Validator.value(value)
        },
    },
}
</script>

<style scoped>

</style>
