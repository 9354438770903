<template>
    <modal name="competition-sort" class="filter-modal competition-sort"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img sort_img"></div>
                    <div class="filter_name">{{ $t('sort') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20  filter_modal_status">
                    <div class="radio_wrap block_wrap">
                        <div class="inline_block">
                            <el-radio-group v-model="sortOption"
                                            @change="setSortFunc(sortOption)">
                                <el-radio :label="'created_at'">{{ $t('b_update') }}</el-radio>
                                <el-radio :label="'c_hit'">{{ $t('b_enter_m') }}</el-radio>
                                <el-radio :label="'likeCnt'">{{ $t('b_like') }}</el-radio>
                                <el-radio :label="'c_prize'">{{ $t('priceDesc1') }}</el-radio>
                                <el-radio :label="'c_prize_asc'">{{ $t('priceAsc1') }}</el-radio>

                            </el-radio-group>
                        </div>
                    </div>
                </div>
                <div class="wrapper_bottom">
                    <button class="" @click="modalClose">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "CompetitionSort",
    mixins: [],
    components: {},
    inject: [ 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            sortData: {},
            sort: '',
            sortOption: 'created_at',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if(!util.isEmpty(event.params.sortData)) {
                this.sortData = event.params.sortData;
            }else{
                this.sortData = {};
            }
            this.setterSortDataInData()
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('competition-sort');
        },
        setterSortDataInData() {
            if(util.isEmpty(this.sortData)) {
                return false;
            }
            if (this.sortData.order === 'asc') {
                this.sortOption = `${this.sortData.key}_asc`
            } else {
                this.sortOption = this.sortData.key
            }
        },
        setSortFunc(key) {
            let order = 'desc'
            if (key.includes('_asc')) {
                let idx = key.indexOf('_asc')
                key = key.substring(0, idx)
                order = 'asc'
            }
            this.setSort(key, order)
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
