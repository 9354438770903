<template>
    <div class="competition_detail_wrap">

        <div class="cd_detail_top">
            <div class="cd_main_slide_wrap">
                <div class="slide_wrap" @click="openImageFile(competitionData.Poster)">
                    <img :src="returnImage(competitionData.Poster,654,null)">
                </div>
            </div>
            <div class="detail_top_btn_wrap">
                <div class="share_wrap">
                    <div class="share_img" @click="openShareModal(competitionData)"></div>
                </div>
                <div class="heart_wrap">
                    <like-competition-component :like-type="`competitionDetail`"
                                                :like-data="competitionData"></like-competition-component>
                    <div class="eye_count">
                        <div class="eye"></div>
                        <span>{{ returnCommas(competitionData.c_hit) }}</span></div>
                </div>
            </div>
        </div>

        <div class="cd_detail_middle">
            <div class="detail_middle_header">

                <div class="thumbnail_wrap cd_thumbnail_wrap">
                    <div class="time_wrap">
                        <competition-date-time
                                :competition-data="competitionData"></competition-date-time>
                        <div class="uploaded_file"
                             @click="awsS3Download('contestIntro',competitionData.Intro[0],competitionData.c_idx)">
                            <i></i>
                            {{ $t('download_file') }}
                        </div>
                    </div>
                    <ul @click="openModal('competition-popup-modal',competitionData)">
                        <li class="thumb_box">
                            <img :src="returnImage(competitionData.Profile,45,45, 'png')" alt="">
                            <div class="nick">{{ competitionData.mb_nick }}</div>
                        </li>
                    </ul>
                </div>

                <div class="subject_wrap">
                    <h3>{{ returnCategoryName(competitionData.cc_idx) }}</h3>
                    <p>{{ competitionData.c_title }}</p>
                </div>

                <div class="hashtag_wrap">
                    <ul>
                        <li v-for="(hash,index) in returnHashtag(competitionData.Hashtag)"
                            :key="`hash-${index}`">
                            <span>#{{ hash }}</span>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="cd_inform_wrap">
                <h3 class="sub_title_m regular">{{ $t('info') }}</h3>
                <ul class="cd_inform_list">
                    <li>
                        <h3 class="regular">{{ $t('c_ju') }}</h3>
                        <div class="inform_content">{{ competitionData.c_organize }}</div>
                        <div class="clear"></div>
                    </li>
                    <li>
                        <h3 class="regular">{{ $t('target') }}</h3>
                        <div class="inform_content">{{ competitionData.c_target }}</div>
                        <div class="clear"></div>
                    </li>
                    <li>
                        <h3 class="regular">{{ $t('entry') }}</h3>
                        <div class="inform_content">{{ returnApplyDate(competitionData) }}</div>
                        <div class="clear"></div>
                    </li>
                    <li>
                        <h3 class="regular">{{ $t('presentation') }}</h3>
                        <div class="inform_content">{{ returnReleaseDate(competitionData.c_release_time) }}</div>
                        <div class="clear"></div>
                    </li>
                </ul>
            </div>
            <!--      총 상금-->
            <div class="total_prize_wrap">
                <h3>{{ $t('t_prize') }}</h3>
                <div class="amount_wrap">
                    <div class="mg_icon"></div>
                    <div class="amount">{{ returnCommas(competitionData.c_prize) }}</div>
                    <div class="mg">Mg</div><br/>
                    <span class="sub_span_w">({{
                            returnMingle(competitionData.c_prize)
                        }})</span>
                </div>
            </div>

            <div class="c_detail_middle_footer" v-if="competitionData.c_status === 3">
                <div class="one_btn bid_btn" @click="movePage(`/award/${competitionData.c_uuid}/list`)">
                    {{ $t('go_award') }}
                </div>
                <div class="clear"></div>
            </div>

            <div class="c_detail_middle_footer" v-else>
                <div class="two_btn history_btn"
                     @click="movePage(`/entry/${competitionData.c_uuid}/list/${competitionData.mb_uuid}`)"
                     v-if="competitionData.c_status !== 0">{{ $t('s_apply') }}
                </div>
                <div class="two_btn bid_btn" @click="checkApply()"
                     v-show="UserInfo.mb_no !== competitionData.mb_no"
                     v-if="checkJudges(competitionData)">{{ $t('apply') }}
                </div>
                <div class="clear"></div>
            </div>

        </div>


        <div class="comp_detail_footer">
            <ul class="detail_footer_tab">
                <li @click="changeTab('item')" class="col3 footer_tab_item" :class="{active : tab === 'item'}">
                    {{ $t('comp_content') }}
                </li>
                <li @click="changeTab('block')" class="col3 footer_tab_block" :class="{active : tab === 'block'}">
                    {{ $t('information') }}
                </li>
                <li @click="changeTab('nft')" class="col3 footer_tab_nft" :class="{active : tab === 'nft'}">
                    {{ $t('standard') }}
                </li>
            </ul>

            <div class="footer_item_wrap" v-if="tab === 'item'">
                <div class="info_detail">
                    <h3 class="sub_title_m regular">{{ $t('comp_content') }}</h3>
                    <div class="competition_info ql-editor" v-html="replaceBr(competitionData.c_context)"></div>
                </div>
            </div>

            <div class="footer_block_wrap" v-if="tab === 'block'">
                <div class="info_detail">
                    <h3 class="sub_title_m regular">{{ $t('inform_license') }}</h3>
                    <div class="competition_info ql-editor" v-html="replaceBr(competitionData.c_license)"></div>
                </div>
            </div>
            <div class="footer_nft_wrap" v-if="tab === 'nft'">
                <div class="info_detail">
                    <div class="nft_header_wrap">
                        <h3 class="sub_title_m regular">{{ $t('standard') }}</h3>
                        <ul>
                            <li v-for=" (evaluate,index) in returnEvaluates(competitionData.Evaluates)"
                                :key="`evaluate-${index}`">
                                {{ evaluate }}
                            </li>
                        </ul>
                    </div>
                </div>

            </div>
        </div>
        <div v-if="hasData(competitionData)">
            <competition-comment-component :competitionType="`comment`"
                                           :competitionKey="competitionData.c_idx" :competitionData="competitionData">
            </competition-comment-component>
        </div>
        <judge-input-modal></judge-input-modal>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import LikeComponent from "@/components/like/LikeComponent";
import LikeCompetitionComponent from "@/components/like/LikeCompetitionComponent";
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import fileMixins from "@/mixins/fileMixins";
import AuthMixins from "@/mixins/AuthMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import JudgeInputModal from "@/components/modal/JudgeInputModal";
import priceMixins from "@/mixins/priceMixins";
import CompetitionCommentComponent from "@/components/competition/comment/CompetitionCommentComponent";

export default {
    name: "CompetitionDetailDefaultLayout",
    mixins: [imageOption, CompetitionCategoryMixin, priceMixins,alertMixins, fileMixins, AuthMixins, awsS3FileDownload],
    components: {
        CompetitionCommentComponent,
        JudgeInputModal, LikeCompetitionComponent, LikeComponent, CompetitionDateTime},
    inject: ['deleteCompetition'],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            tab: 'item',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name) {
            this.$modal.show(name, {competitionData: this.competitionData});
        },
        movePage(url) {
            this.$router.push(url);
        },
        moveUpdate(url) {
            this.$router.push(`${this.$route.path}/${url}`);
        },
        hasData(data) {
            return !util.isEmpty(data);
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnImage(image, width = 32, height = 32, f = 'jpg') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
            }
            return ''
        },
        returnOrgImage(image) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}`
            }
            return ''
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnHashtag(hashtag) {
            let list = []
            util.each(hashtag, (hash, index, arr) => {
                if (!util.isEmpty(hash.ic_name) && hash.ch_type === 1) {
                    list.push(hash.ic_name)
                }
                if (!util.isEmpty(hash.ch_hashtag) && hash.ch_type === 2) {
                    list.push(hash.ch_hashtag)
                }
            })
            return list
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY.MM.DD HH:mm')
            let e_time = this.$moment(data.c_end_time).format('YYYY.MM.DD HH:mm')
            return `${s_time} ~ ${e_time}`
        },
        returnReleaseDate(time) {
            let r_time = this.$moment(time).format('YYYY.MM.DD HH:mm')
            return r_time;
        },
        returnEvaluates(evaluates = []) {
            let list = []
            util.each(evaluates, (evaluate, index, arr) => {
                list.push(evaluate.cs_evaluate)
            })
            return list
        },
        returnPoster(poster) {
            return !util.isEmpty(poster) ? poster[0].org_url : ''
        },
        returnIntroName(intro) {
            return !util.isEmpty(intro) ? intro[0].org_name : ''
        },
        deleteConfirm() {
            let key = this.competitionData.c_idx
            this.createConfirm({
                content: this.$t('competition_cancel_msg'),
                confirm: () => {
                    this.deleteCompetition(key)
                },
                cancel: () => {

                }
            })
        },
        checkApply() {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('checkApply')
            }
        },
        getMemberData(mb_no) {
            let params = {
                no: mb_no
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        openImageFile(poster) {
            let url = ''
            if (!util.isEmpty(poster)) {
                url = poster[0].org_url
            }
            window.open(url, '_blank');
        },
        checkJudges(data) {
            if (!util.isEmpty(data)) {
                if(data.c_idx===32){
                    return false
                }
                let judgesIndex = data.Judges.findIndex(judges => judges.mb_no === this.UserInfo.mb_no);
                if (judgesIndex > -1) {
                    return false;
                }
                if (data.c_status === 1) {
                    return true;
                }
                return false;
            }
        },
        openShareModal(data) {
            this.$modal.show('share-modal', {competitionData: data});
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
    },
    watch: {
        "$route.fullPath": {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$route.query.tab) {
                    this.changeTab('item');
                } else if (this.tab != this.$route.query.tab) {
                    this.changeTab(this.$route.query.tab);
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
