const itemConfig = {
    itemCreate: {
        writeLayouts: ['ItemWriteDefaultLayout'],
        writeFunc: 'createItem',
        writeApiParamSet: {},
        parsingData: {
            title: 'i_name',
            content: 'i_memo',
            type: 'i_type',
            category: 'ic_idx',
            edition: 'im_edition',
            count: 'im_count',
            total: 'im_total',
            i_status: 'i_status',
            originator: 'im_originator',
            im_type: 'im_type',
            enroll: 'im_enroll',
            adult: 'im_adult_chk',
            itemDate: 'imo_date',
            country: 'imo_country',
            si: 'imo_si',
            city: 'imo_city',
            size: 'imo_size',
            copyright: 'imo_copyright',
            hashtag: 'hashtag',
            temp: 'temp',
            aiCreate:'im_is_ai'
        },
        fileInfo: [],
        nftFileInfo: [
            {
                fileName: 'thumnail',
                fileType: 'item',
            },
            {
                fileName: 'original',
                fileType: 'original',
            },
            {
                fileName: 'detailThumnail',
                fileType: 'item_detail',
            },
            {
                fileName: 'guarantee',
                fileType: 'guaranty',
            },
        ],

        afterType: 'write',
        directUrl: '',
        successMsg: '',
        directType: 'back',
        keyName: '',
        writeSuccessType: 1,
        writeValidatorArr: [
            'title',
            'type',
            'content',
            'category',
            'edition',
            'status',
            'originator',
            'im_type',
            'enroll',
            'adult',
            'itemDate',
            'country',
            'si',
            'city',
            'size',
            'copyright',
            'hashtag',],
        writeFailTypes: {},
    },
    itemUpdate: {
        writeLayouts: ['ItemWriteDefaultLayout'],
        writeFunc: 'updateItem',
        writeApiParamSet: {},
        parsingData: {
            title: 'i_name',
            content: 'i_memo',
            type: 'i_type',
            category: 'ic_idx',
            edition: 'im_edition',
            count: 'im_count',
            total: 'im_total',
            i_status: 'i_status',
            originator: 'im_originator',
            im_type: 'im_type',
            enroll: 'im_enroll',
            adult: 'im_adult_chk',
            itemDate: 'imo_date',
            country: 'imo_country',
            si: 'imo_si',
            city: 'imo_city',
            size: 'imo_size',
            copyright: 'imo_copyright',
            hashtag: 'hashtag',
            temp: 'temp',
        },
        fileInfo: [],
        nftFileInfo: [
            {
                fileName: 'thumnail',
                fileType: 'item',
            },
            {
                fileName: 'original',
                fileType: 'original',
            },
            {
                fileName: 'detailThumnail',
                fileType: 'item_detail',
            },
            {
                fileName: 'guarantee',
                fileType: 'guaranty',
            },
        ],
        directUrl: '',
        directType: 'back',
        keyName: '',
        getKeyName: 'sku',
        writeSuccessType: 1,
        writeValidatorArr: [
            'title',
            'type',
            'content',
            'category',
            'edition',
            'status',
            'originator',
            'im_type',
            'enroll',
            'adult',
            'itemDate',
            'country',
            'si',
            'city',
            'size',
            'copyright',
            'hashtag',
        ],
        writeFailTypes: {},
        afterType: 'write',
        deleteFunc: 'deleteItem',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},

    },
    premium: {
        filterLayout: 'ItemFilterDefaultLayout',
        listLayout: 'ItemListPremiumMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },

    open: {
        filterLayout: 'ItemFilterDefaultLayout',
        listLayout: 'ItemListOpenMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    openMarketDetail: {
        detailLayout: 'ItemDetailOpenMarketLayout',
        getFunc: 'getItemDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
    },
    premiumMarketDetail: {
        detailLayout: 'ItemDetailPremiumMarketLayout',
        getFunc: 'getItemDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
    },
    creationItem: {
        filterLayout: 'ItemFilterModalFilterLayout',
        listLayout: 'ItemListCreationLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 20,
            obt: 'last_update',
            obs: 'desc',
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'or'
    },
    creationUser: {
        filterLayout: 'ItemFilterCreationUserLayout',
        listLayout: 'ItemListCreationLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 20,
            obt: 'last_update',
            obs: 'desc',
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        //keyName: 'or'
        keyName: 'oruid'
    },
    premiumUser: {
        filterLayout: 'ItemFilterPremiumMyLayout',
        listLayout: 'ItemListPremiumMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [2, 5, 8, 9],
            en: 1,
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        // keyName: 'cu',
        keyName: 'cuuid',
    },
    openUser: {
        filterLayout: 'ItemFilterOpenMyLayout',
        listLayout: 'ItemListProfileMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 15,
            iscu:2,
            obt: 'last_update',
            obs: 'desc',
            imst: [1, 3, 4, 6, 7, 9],
            en: 1,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        //keyName: 'pr',
        keyName: 'pruid',
        pageRow: 20,
    },
    premiumMy: {
        filterLayout: 'ItemFilterPremiumMyLayout',
        listLayout: 'ItemListProfileMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [2, 5, 8, 9],
            en: 1,
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'cu',
    },
    openMy: {
        filterLayout: 'ItemFilterOpenMyLayout',
        listLayout: 'ItemListProfileMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            iscu:2,
            sp: 15,
            obt: 'last_update',
            obs: 'desc',
            imst: [1, 3, 4, 6, 7, 9],
            en: 1,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'pr',
        pageRow: 20,
    },
    curationRequest: {
        filterLayout: 'ItemFilterItemAddLayout',
        // listLayout: 'ItemListCurationRequestLayout',
        listLayout: 'ItemListItemAddLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getMyItem',
        listApiParamSet: {
            ip: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [9],
            en: 1,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'pr',
        pageRow: 20,
    },
    curationApply: {
        writeLayouts: ['ItemWriteCurationLayout']
    },

    myItemHistory: {
        filterLayout: 'ItemFilterMyPageLayout',
        listLayout: 'ItemListMyItemHistoryLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemHistory',
        listApiParamSet: {
            ip: 1,
        },
        keyName: '',
        pageRow: 10,
    },
    myItemTempSave: {
        filterLayout: 'ItemFilterMyTempSaveLayout',
        listLayout: 'ItemListMyTempSaveLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            en: 2,
            page: 1,
            sp: 10,
            obt: 'last_update',
            obs: 'desc',
        },
        pageRow: 10,
        keyName: 'pr'
    },
    myItemHold: {
        filterLayout: 'ItemFilterHoldingLayout',
        listLayout: 'ItemListHoldingLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemStore',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'im_possession_date',
            obs: 'desc',
        },
        pageRow: 10,
        keyName: 'pr'
    },


    interestItem: {
        filterLayout: 'ItemFilterInterestLayout',
        // listLayout: 'ItemListInterestLayout',
        listLayout: 'ItemListLikeLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemLike',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
    },
    /* interestCreator: {
         filterLayout: 'ItemFilterInterestCreatorLayout',
         listLayout: 'ItemListInterestCreatorLayout',
         emptyLayout: 'ItemEmptyDefaultLayout',
         paginationLayout: 'PaginationDefaultLayout',
         getFunc: 'getCreatorLike',
         listApiParamSet: {
             ip: 1,
             en: 1,
             page: 1,
             sp: 10,
             ty:'me',

             gid: [], //2 큐레이션 / 1 = 일반
             pr: [], // 0 일반 // 1 = 스페셜
         },
         pageRow: 10,

     },*/
    /* interestCompetition: {
         filterLayout: 'ItemFilterInterestCompetitionLayout',
         listLayout: 'ItemListInterestCompetitionLayout',
         emptyLayout: 'ItemListCompetitionEmptyDefaultLayout',
         paginationLayout: 'PaginationDefaultLayout',
         getFunc: 'getcompetitionLike',
         listApiParamSet: {
             ip:1,
             sp:1,
             page:1,
             obt: 'created_at',
             obs: 'desc',
             prgt: [],
         },
         pageRow: 10,
     },*/
    homeItem: {
        listLayout: 'ItemListHomeArtLayout',
        // getFunc: 'getItem',
        getFunc: 'getItemCombine',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 8,
            imst: [1, 2, 3, 4, 5],
            obt: 'last_update',
            obs: 'desc',
        },


    },

    searchItem: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListSearchlistLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        // paginationLayout: 'PaginationDefaultLayout'
    },
    itemAdd: {
        filterLayout: 'ItemFilterGalleryLayout',
        listLayout: 'ItemListItemAddLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [1, 3, 4, 6, 7, 9],
            en: 1,
            iscu: 2,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'pr',
        pageRow: 20,
    },
    itemCuratorAdd: {
        filterLayout: 'ItemFilterGalleryLayout',
        listLayout: 'ItemListItemAddLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [2, 5, 8, 9],
            en: 1,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'cu',
        pageRow: 20,
    },

    openMarket: {
        filterLayout: 'ItemFilterModalOpenMarketLayout',
        listLayout: 'ItemListOpenMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemCombine',
        // getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 15,
            obt: 'last_update',
            obs: 'desc',
            au: [1, 2],
            st: ['auction', 'license', 'pricetag',],
            imst: [1, 3, 4],
            en: 1,
            use: 1
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 20,
    },

    premiumMarket: {
        filterLayout: 'ItemFilterModalPremiumMarketLayout',
        listLayout: 'ItemListPremiumMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemCombine',
        // getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            obt: 'last_update',
            obs: 'desc',
            imst: [2, 5],
            en: 1,
            use: 1
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },

    premiumMarketTopBanner: {
        getFunc: 'getItemBanner',
        listApiParamSet: {
            day: 15,
            count: 20,
            ispr: 1
        }
    },

    openMarketTopBanner: {
        getFunc: 'getItemBanner',
        listApiParamSet: {
            day: 15,
            count: 20,
            ispr: 0,
        }
    },

    premiumMarketUpload: {
        filterLayout: 'ItemFilterModalPremiumMarketLayout',
        listLayout: 'ItemListPremiumMarketCreate',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            obt: 'last_update',
            obs: 'desc',
            st: ['nosale'],
            en: 1,
        },
        keyName: 'pr',
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },


    galleryUser: {
        listLayout: 'ItemListGalleryUserLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
    },
    galleryMy: {
        // filterLayout: 'ItemFilterGalleryMyLayout',
        listLayout: 'ItemListGalleryMyLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: '',
        listApiParamSet: {}
    },
    userItemList: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListUserItemLayout',
        emptyLayout: 'ItemEmptyListLayout',
        paginationLayout: 'PaginationNoneLayout',
        getFunc: 'getItem',
        // getFunc: 'getItemCombine',
        keyName: 'pr',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 20,
            obt: 'last_update',
            obs: 'desc',
            imst: [1, 3, 4],
            en: 1,
            iscu: 2,
        },
        pageRow: 20,

    },
    premiumUserItemList: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListUserItemLayout',
        emptyLayout: 'ItemEmptyListLayout',
        paginationLayout: 'PaginationNoneLayout',
        getFunc: 'getItem',
        // getFunc: 'getItemCombine',
        keyName: 'cu',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 20,
            obt: 'last_update',
            obs: 'desc',
            imst: [2, 5],
            en: 1,
        },
        pageRow: 20,

    },
    itemStatusUpdate: {
        writeLayouts: ['ItemWriteStatusLayout'],
        writeFunc: 'updateItem',
        writeApiParamSet: {},
        parsingData: {
            content: 'i_memo',
            edition: 'im_edition',
            count: 'im_count',
            total: 'im_total',
            type: 'i_type',
            category: 'ic_idx',
            i_status: 'i_status',
            im_type: 'im_type',
            status: 'im_status',
            enroll: 'im_enroll',
            adult: 'im_adult_chk',
            hashtag: 'hashtag',
        },
        fileInfo: [
            {
                fileName: 'detailThumnail',
                fileType: 'item_detail',
            },
        ],
        directUrl: '/openmarket',
        afterType: 'update',
        directType: 'detail',
        successMsg: 'updateItemMsg1',

        writeSuccessType: 1,
        writeValidatorArr: [
            'content',
            'adult',
            'hashtag',
        ],
        writeFailTypes: {},
        keyName: 'i_idx',
        getKeyName: 'sku',
        deleteFunc: 'deleteItem',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
    },
    entryList: {
        filterLayout: 'ItemFilterEntryLayout',
        listLayout: 'ItemListEntryLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getMyItem',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 8,
            ty: ['digital'],
            imst: [9],
            obt: 'im_possession_date',
            obs: 'desc',
        },
        pageRow: 8,
        keyName: 'or',
    },

    myItemList: {
        filterLayout: 'ItemFilterEntryLayout',
        listLayout: 'ItemListMyItemLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    detection: {
        filterLayout: 'ItemFilterDetectionLayout',
        listLayout: 'ItemListDetectionItemLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItemDetect',
        listApiParamSet: {
            ip: 1,
            obt: 'i.created_at',
        },
        pageRow: 20,
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
    },
    entryDetail: {
        detailLayout: 'ItemDetailEntryLayout',
        getFunc: 'getEntryDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},

    },
    premiumMarketAdd: {
        writeLayouts: ['ItemWritePremiumMarketAddLayout'],
        writeFunc: 'updateItem',
        writeApiParamSet: {},
        parsingData: {
            type: 'i_type',
            category: 'ic_idx',
            // i_status: 'i_status',
            // status: 'status',
            enroll: 'im_enroll',
            hashtag: 'hashtag',
        },
        fileInfo: [],
        directUrl: 'premium-market-add-modal',
        directType: 'modal',
        keyName: '',
        successMsg: 'd_ignore_url_success',
        writeSuccessType: 1,
        writeValidatorArr: [],
        writeFailTypes: {},
        afterType: 'update',
        deleteFunc: 'deleteItem',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
    },
    searchItemDetail: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListSearchDetailLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            en: 1,
            page: 1,
            sp: 20,
            obt: 'i_name',
            obs: 'desc',
        },
        pageRow: 20,
        keyName: '',
    },

    itemBid: {
        detailLayout: 'ItemDetailBidLayout',
        getFunc: 'getItemDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
    },
    itemPrice: {
        detailLayout: 'ItemDetailPriceLayout',
        getFunc: 'getItemDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
    },
    itemLicense: {
        detailLayout: 'ItemDetailLicenseLayout',
        getFunc: 'getItemDetail',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
    },
    myAuction: {
        filterLayout: 'ItemFilterAuctionLayout',
        listLayout: 'ItemListAuctionLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getBid',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        keyName: '',
    },
    myAuctionExhibit: {
        filterLayout: 'ItemFilterAuctionExhibitLayout',
        listLayout: 'ItemListAuctionExhibitLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getAuction',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        keyName: '',
    },
    auctionHistory: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListAuctionHistoryLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getBidHistory',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        keyName: 'm_idx',
    },
    realBuy: {
        filterLayout: 'ItemFilterRealSaleLayout',
        listLayout: 'ItemListRealBuyLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getRealBuy',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        keyName: '',
    },
    realSale: {
        filterLayout: 'ItemFilterRealSaleLayout',
        listLayout: 'ItemListRealSaleLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getRealSale',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        keyName: '',
    },
    edition: {
        filterLayout: 'ItemFilterNoneLayout',
        listLayout: 'ItemListEditionItemLayout',
        emptyLayout: 'ItemEmptyNoneLayout',
        paginationLayout: 'PaginationNoneLayout',
        getFunc: 'getEdition',
        listApiParamSet: {
            ip: 1,
        },
        pageRow: 10,
        keyName: 'sku',
        paginationType: 'lazyload',
    },
    collectItem: {
        filterLayout: 'ItemFilterOpenMyLayout',
        listLayout: 'ItemListProfileMarketLayout',
        emptyLayout: 'ItemEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 15,
            obt: 'last_update',
            obs: 'desc',
            en: 1,
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        keyName: 'cid',
        pageRow: 20,
    },

}
export default {
    methods: {
        returnItemTemplateConfig(type) {
            return itemConfig[type];
        }
    },
    filterConfig: {
        ed: {
            type: 'terms',
            classname: 'filterEdition',
        },
        tab: {
            type: 'match',
            classname: 'tab',
        },
        ty: {
            type: 'terms',
            classname: 'filterType',
        },
        imst: {
            type: 'terms',
            classname: 'filterStatus',
            option: {
                'auction': [1, 2],
                'license': [3],
                'fix': [4, 5],
                'no': [7, 8, 9],
                'competition': [6],
            }
        },
        st: {
            type: 'terms',
            classname: 'filterStatus',
        },
        ic: {
            type: 'terms',
            classname: 'filterCategory',
        },
        pr: {
            type: 'between',
            classname: 'priceInput',
            option: {
                start: 'spr',
                end: 'epr'
            }
        },
        obt: {
            type: 'query',
            classname: 'sort',
        },
        obs: {
            type: 'match',
            classname: 'sort',
        },
        ti: {
            type: 'query',
            classname: 'searchInput',
        },
        ina: {
            type: 'query',
            classname: 'searchInput',
        },
        hash: {
            type: 'query',
            classname: 'searchInput',
        },
        orni: {
            type: 'query',
            classname: 'searchInput',
        },
        prni: {
            type: 'query',
            classname: 'searchInput',
        },
        cuni: {
            type: 'query',
            classname: 'searchInput',
        },
        ihna: {
            type: 'query',
            classname: 'searchInput',
        },
        ihcr: {
            type: 'query',
            classname: 'searchInput',
        },
        ihcu: {
            type: 'query',
            classname: 'searchInput',
        },
        ihow: {
            type: 'query',
            classname: 'searchInput',
        },
        iscu: {
            type: 'terms',
            classname: 'filterCuration',
        },
        po: {
            type: 'between',
            classname: 'filterPossessDate',
        },
        ma: {
            type: 'between',
            classname: 'filterMatch',
            option: {
                start: 'masc',
                end: 'maec'
            },
        },
        cr: {
            type: 'between',
            classname: 'filterDate'
        },
        adst: {
            type: 'terms',
            classname: 'filterBidStatus'
        },
        mst: {
            type: 'terms',
            classname: 'filterBidStatus'
        },
        hst: {
            type: 'terms',
            classname: 'filterStatus'
        },
        ni: {
            type: 'query',
            classname: 'searchInput'
        },
        gid: { //큐레이터
            type: 'terms',
            classname: 'filterCurator',
        },
        prt: { //타입
            type: 'terms',
            classname: 'filterType',
            option: {
                pr: [0, 1],
            },
        },
        lic: { //좋아요
            type: 'between',
            classname: 'filterLike',
            option: {
                start: 'licgt',
                end: 'licle'
            }
        },
        itc: {
            type: 'between',
            classname: 'filterItem',
            option: {
                start: 'itcgt',
                end: 'itcle'
            }
        },
        //카테고리
        icid: {
            type: 'terms',
            classname: 'filterCategory'
        },
        ccid: {
            type: 'terms',
            classname: 'filterCategory'
        },

    }
}
