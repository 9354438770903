<template>
    <div>
        <point-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                           :sortData="filterSet.sort"></point-filter-link>
        <point-empty-link :emptyLayout="emptyLayout" v-if="List.length === 0"></point-empty-link>
        <point-list-link :listLayout="listLayout" :pointData="List" :paginationData="filterSet.page"
                         v-else></point-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import PointFilterLink from "@/components/point/PointFilterLink";
import PointListLink from "@/components/point/PointListLink";
import PointEmptyLink from "@/components/point/PointEmptyLink";
import PaginationLink from "@/components/point/PaginationLink";
import pointUtil from "@/components/point/pointUtil";
import alertMixins from "@/mixins/alertMixins";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

const {filterConfig} = pointUtil;

export default {
    name: "PointListComponent",
    mixins: [pointUtil, alertMixins, filterMixins, listMixins],
    components: {
        PointFilterLink,
        PointListLink,
        PointEmptyLink,
        PaginationLink
    },
    inject: [],
    provide() {
        return{
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setPointListData: this.setData,
        }
    },
    props: {
        pointType: '',
    },
    data() {
        return{
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig);
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initConfig(){
            let config = this.returnPointConfig(this.pointType);
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }
            let params = util.isEmpty(this.listApiParamSet) ? {} : JSON.parse(JSON.stringify(this.listApiParamSet));
            let listOptions = {
                url: this.$api.$point[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },
        setData(key, value) {
            this[key] = value
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
