const termsConfig = {
    service: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    privacy: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    leave: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    product: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    auctionSell: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    auctionBuy: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    productSell: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    licenseSell: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    licenseBuy: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    contest: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    contestItem: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    contestWin: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    curator: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    //큐레이터 신청 규정
    curatorForm: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    curation: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    lawsuit: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    lawsuitRequest: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    mingle: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    charge: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    withdraw: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    fees: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },
    itembuy: {
        detailLayout: 'TermsDetailDefaultLayout',
        getFunc: 'getTerms',
        detailApiParamsSet: {
            c_idx: '',
            ty: '',
            la : 1
        },
        getSuccessType: 1,
        getErrorTypes: {},
    },


}
export default {
    methods: {
        returnTermsConfig(type) {
            return termsConfig[type];
        },

    },
    filterConfig: {
        cr: {
            type: 'between',
            classname: 'filterDate'
        },
        al: {
            type: 'between',
            classname: 'filterDate'
        },
        out: {
            type: 'between',
            classname: 'filterOutDate'
        },
        ni: {
            type: 'query',
            classname: 'searchInput',
        },
        mid: {
            type: 'query',
            classname: 'searchInput',
        },
        bid: {
            type: 'query',
            classname: 'filterStatus',
        },
        bl: {
            type: 'match',
            classname: 'filterStatus',
        },
        cmty:{
            type: 'terms',
            classname: 'filterType',
        }

    }
}

