<template>
  <div>
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <div slot="headerBtns">
              <button class="h_search_btn" @click="$router.push('/search')"></button>
          </div>
      </page-header>
      <page-header class="sub_header"></page-header>
    <div class=" dgm_wrap padding_side">
<!--추후 삭제-->
<!--      <NoticeDetailLayout></NoticeDetailLayout>-->
      <board-detail-component :boardType="'noticeDetail'" :boardKey="boardKey"></board-detail-component>
    </div>
  </div>
</template>

<script>
import BoardDetailComponent from "@/components/board/BoardDetailComponent";

export default {
  name: "NoticeDetail",
  mixins: [],
  components: {
    BoardDetailComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      boardKey: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.boardKey = this.$route.params.idx;
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
