<template>
    <modal name="profile-setting-modal"
           class="profile-setting-modal"
           :width="`100%`"
           :height="`100%`"
           :adaptive="true"
           :scrollable="true"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
<!--            <page-header :header_modal_back="true" :header-title="$t('profile_setting')"></page-header>-->
            <page-header :header-title="$t('profile_setting')">
                <button slot="headerBtns" class="h_x_btn" @click="modalClose"></button>
            </page-header>
            <member-write-component :member-type="'profileSetting'" :memberKey="UserInfo.mb_no"></member-write-component>
        </div>
    </modal>
</template>

<script>
import {mapState} from "vuex";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "ProfileSettingModal",
    mixins: [],
    components: {
        MemberWriteComponent,
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            intro_textarea: '',
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        handlerBeforeOpen(event) {
            this.scroll = event.params.scroll
            document.body.style.top = `-${this.scroll}px`
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },
        modalClose() {
            this.$modal.hide('profile-setting-modal')
        },
    },

    watch: {},
};
</script>

<style scoped>

</style>
