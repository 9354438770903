<template>
    <modal name="mypage-deposit-modal"
           class="password-change-modal mypage-deposit-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :scrollable="true"
           :clickToClose="false"
           :width="'90%'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="update-change-modal_wrap">
            <div class="nickname_title mb25">
                {{$t('pwd_change')}}
            </div>
            <div class="rollback_btn" @click="closeModal()"></div>
            <div class="clear"></div>
            <point-deposit-component :afterMethod="closeModal"></point-deposit-component>
        </div>
<!--        <member-write-component :member-type="'updatePwd'"></member-write-component>-->
    </modal>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import PointDepositComponent from "@/components/point/PointDepositComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "MypageDepositModal",
    mixins: [memberValidator],
    components: {
        PointDepositComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pwd: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        closeModal() {
            this.$modal.hide('mypage-deposit-modal');
        },
        returnUrl(){
            return this.url
        },
        setData(key, value) {
            this[key] = value
        },
        checkSave() {

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
