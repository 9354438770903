<template>
    <div>
        <entry-write-link :writeLayout="writeLayout"></entry-write-link>
    </div>
</template>

<script>
import EntryWriteLink from "@/components/entry/EntryWriteLink";
import entryUtil from "@/components/entry/entryUtil";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";

export default {
    name: "EntryWriteComponent",
    mixins: [entryUtil, alertMixins],
    components: {
        EntryWriteLink,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        entryType: '',
        competitionKey: '',
    },
    data() {
        return {
            writeLayout: '',
            writeFunc: '',
            writeSuccessType: '',
            writeFailTypes: [],
            termCheck: false,

            competitionData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('selectEntryItem', this.selectEntryItem)
        EventBus.$on('entryWriteSetData', this.setData)
    },
    beforeDestroy() {
        EventBus.$off('selectEntryItem')
        EventBus.$off('entryWriteSetData')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let entryConfig = this.returnEntryConfig(this.entryType);
            this.writeLayout = entryConfig.writeLayout;
            this.writeFunc = entryConfig.writeFunc
            this.writeSuccessType = entryConfig.writeSuccessType
            this.writeFailTypes = entryConfig.writeFailTypes;

            if (!util.isEmpty(this.competitionKey)) {
                this.getCompetitionData();
            }
        },
        setData(key, value) {
            this[key] = value
        },
        selectEntryItem(i_idx, i_name) {
            if (!this.termCheck) {
                this.errorAlert(this.$t('entry_agree_terms_err'))
            } else {
                this.createConfirm({
                    content: `<span>${i_name}</span> <br/>
                                ${this.$t('submit_entry_item')} `,
                    confirm: () => {
                        this.writeEntry(i_idx)
                    },
                })
            }
        },
        getCompetitionData() {
            let params = {
                cuid: this.competitionKey
            };
            try {
                this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                    if (!util.isEmpty(res.List)) {
                        this.competitionData = res.List[0];
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        writeEntry(i_idx) {
            let params = {
                c_idx: this.competitionKey,
                i_idx: i_idx
            }
            if (!util.isEmpty(this.competitionData)) {
                params.c_idx = this.competitionData.c_idx;
            }
            EventBus.$emit('changeLoading', true)

            try {
                this.$api.$competition[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    let result = this.returnWriteType(res.Info.type)
                    if (result !== true) {
                        EventBus.$emit('changeLoading', false)
                        this.errorAlert(this.$t(result));
                        return false;
                    } else {
                        this.writeAfterFunc()
                    }
                });
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false)

            }
        },
        returnWriteType(type) {
            if (type == this.writeSuccessType) {
                return true;
            } else {
                return util.isEmpty(this.writeFailTypes[type]) ? 'competition_fail' : this.writeFailTypes[type]
            }
        },
        writeAfterFunc() {
            EventBus.$emit('changeLoading', false)

            let alertContent = this.$t('create_entry_msg')
            this.createConfirm({
                content: alertContent,
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.push(`/mypage/competition?tab=entryItem`);
                },
                cancel: () => {
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
