<template>
  <div>
<!--    <page-header :header_back="true"></page-header>-->
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <button slot="headerBtns" class="h_search_btn" @click="movePage('/search')"></button>
      </page-header>
    <google-register-component></google-register-component>
  </div>
</template>

<script>
import GoogleRegisterComponent from "@/components/auth/GoogleRegisterComponent";
export default {
  name: "SnsLogin",
  components: {GoogleRegisterComponent}
}
</script>

<style scoped>

</style>
