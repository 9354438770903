<template>
    <div class="notice_list_wrap">
      <div class="notice_list border_none">
        <button v-for="(data, index) in boardData" :key="`qna${index}`" @click="moveDetail(`${data.wr_id}`)">
          <div class="wait_answer" :class="{'finish_color' : data.Comment.cnt == 1}">{{ returnComment(data.Comment.cnt) }}</div>
          <div class="notice_content question_notice">{{ data.wr_subject }}</div>
          <div class="notice_content font_size">{{ data.created_at }}</div>
        </button>
      </div>
    </div>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "BoardListQnaLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}},
    paginationData: {default: {page: 1, size: 10, total: 0}},
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(idx){
      this.$router.push(`/qna/${idx}`)
    },
    returnComment(comment){
      switch (comment){
        case 1:
          return '답변완료'
        case 0:
          return '답변대기'
      }
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
