<template>
  <div class="wrap-launch">
    <div class="btn-more-launch" v-if="listLength >= 5" @click="showFiveMoreContent">{{ $t('view_more') }}</div>
  </div>
</template>

<script>
export default {
  name: "LazyloadDefaultLayout",
  inject: ['setPage'],
  props: {
    paginationData: {
      default: () => {
        return {total: 0, page: 0, size: 0,}
      }
    },
    listLength: {
      default: () => {
        return 0
      }
    },
    finishGetList: {default: true,},
  },
  mixins: [],
  data() {
    return {
      loadingMore: false,
    }
  },
  computed: {},
  mounted() {

  },
  methods: {
    loadMore($event) {
      if (this.loadingMore) {
        return;
      }
      this.loadingMore = true;
      let page = this.paginationData.page;
      page = page + 1;
      this.setPage('page', page);
      this.lazyLoadComplete($event);
    },
    lazyLoadComplete($event) {
      setTimeout(() => {
        if (!this.finishGetList) {
          this.lazyLoadComplete($event);
          return false;
        }

        $event.target.complete();
        this.loadingMore = false;
      }, 500)

    },
  }
}
</script>

<style scoped>

</style>
