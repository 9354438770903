<template>
    <div class="creator_detail_wrap mt0">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
<!--        <page-header :header_white_back="true" class="creator_detail_header"></page-header>-->
        <member-detail-component :member-type="'myProfile'"></member-detail-component>
        <my-profile-tabs ></my-profile-tabs>
        <share-modal></share-modal>
        <router-view></router-view>
    </div>
</template>

<script>
import MyProfileTabs from "@/components/mypage/MyProfileTabs";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import {logout} from "@/mixins/Auth";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import ShareModal from "@/components/modal/ShareModal";

export default {
    name: "MyProfile",
    mixins: [AuthMixins, alertMixins],
    components: {
        MyProfileTabs,
        MemberDetailComponent,
        GalleryListComponent,
        ShareModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'galleryMy',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
      // $('.creator_detail_wrap').parent().css('padding-top', '0');
    },
    beforeDestroy() {
      // $('.creator_detail_wrap').parent().css('padding-top', '70px');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
        },
        logout() {
            const param = {
                content: this.$t('logout_confirm'),
                confirm: () => {
                    logout();
                }
            };
            this.createConfirm(param);
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
