<template>
    <div class="competition_wrap pb17 padding_side">
        <div class="competition_item application_item">
            <div class="competition_input">
                <el-input :placeholder="`${$t('st')}`" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_sort_btn ma0"></button>
            <button class="c_filter_btn ma0 ml13" @click="openFilter('item-filter-modal-filter')"></button>
        </div>
        <div class="clear"></div>
        <item-filter-modal-filter></item-filter-modal-filter>
    </div>
</template>


<script>
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
const {filterConfig} = competitionUtil
export default {
    name: "CompetitionFilterEntryModalLayout",
    mixins: [],
    components: {
    },
    inject: ['setFilter','setSort'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {default: () => {}},
    },
    data() {
        return{
            searchInputOption: 'na',
            searchInput: '',
            sortOption: 'created_at',
            filterStatus:[],
            filterCategory:[],
            filterPrice:[],
            minPrice:'',
            maxPrice:''
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {
        this.setterFilterDataInData();
        if(!util.isEmpty(this.sortData)){
            this.setterSortDataInData()
        }
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }
                }
            })
        },
        setterSortDataInData(){
            if(this.sortData.order === 'asc'){
                this.sortOption = `${this.sortData.key}_asc`
            }else {
                this.sortOption = this.sortData.key
            }
        },
        setSortFunc(key) {
            let order = 'desc'
            if(key.includes('_asc')){
                let idx = key.indexOf('_asc')
                key = key.substring(0,idx)
                order = 'asc'
            }
            this.setSort(key,order)
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openModal(name) {
            this.$modal.show(name);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
