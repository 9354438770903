<template>
    <div>
        <ul class="detection_filter_result_wrap">
            <!--         <el-collapse accordion class="detection_item" @change="collapseChange($event)">
                         <el-collapse-item :name="detect.dr_idx" v-for="(detect, index) in detectionData" :key="`detect${detect.dr_idx}`"
                                           :disabled="clickIgnore">
                             <template slot="title">
                                 <li class="detection_filter_result_list" :class="{'bt0': index === 0}">
                                     <ul class="detection_detail_list_wrap">
                                         <li>
                                             <p class="detection_detail_tit">{{$t('detection_domain1')}}</p>
                                             <p class="detection_detail_content">wallpapercave.com</p>
                                         </li>
                                         <li>
                                             <p class="detection_detail_tit">URL</p>
                                             <p class="detection_detail_content detection-ellipsis">http://wallpapercave.com/mobile-screen</p>
                                         </li>
                                         <li>
                                             <p class="detection_detail_tit">{{$t('status')}}</p>
                                             <el-select class="select_status"
                                                        popper-class="category_select"
                                                        :popper-append-to-body="false"
                                                        v-if="selectCollapse === detect.dr_idx && !returnDomainStatus(detect.dr_status).noChange"
                                                        v-model="detect.dr_status_select" :placeholder="$t('select')" @change="changeStatus(detect)">
                                                 <el-option v-for="(status) in detectionDomainStatus" :key="`setStatus${status.value}`"
                                                            v-if="!status.noChange" :value="status.value" :label="`${$t(status.name)}`"></el-option>
                                                 &lt;!&ndash;                        <el-option :value="'noImg'" :label="`${$t('no_myImg')}`"></el-option>
                                                                         <el-option :value="'ignore'" :label="`${$t('ignore')}`"></el-option>
                                                                         <el-option :value="'hold'" :label="`${$t('hold')}`"></el-option>&ndash;&gt;
                                                 <el-option :value="'request'" :label="`${$t('request1')}`"></el-option>
                                             </el-select>
                                             <p class="detection_detail_content detail-color " :class="returnDomainStatus(detect.dr_status).class" v-else>
                                                 {{ returnDomainStatusName(detect.dr_status) }}
                                             </p>
                                         </li>
                                         <li>
                                             <p class="detection_detail_tit">{{$t('detection_date')}}</p>
                                             <p class="detection_detail_content">2021.10.31</p>
                                         </li>
                                     </ul>
                                     <div class="btn-domain-wrap btn-domain-list-wrap">
                                         <button class="btn-domain" v-if="detect.dr_except != 1"
                                                 @click="ignoreDomain(detect)" :disabled="detect.dr_except === 1" >{{ $t('except_domain') }}</button>
                                     </div>
                                 </li>
                             </template>
                             <div class="detection_img my">
                                 <img :src="returnItemImage()" alt="">
                                 <div class="detection_img_bottom"></div>
                                 <div class="detection_img_text">{{ $t('myImg') }}</div>
                             </div>
                             <div class="detection_img">
                                 <img :src="returnDetectImage(detect)" alt="">
                                 <div class="detection_img_bottom"></div>
                                 <div class="detection_img_text">{{ $t('detection_img') }}</div>
                             </div>
                             <div class="detection_text_box">
                                 <div class="detection_text_title">{{ $t('d_img_title') }}</div>
                                 <div class="detection_text">{{ detect.dr_title }}</div>
                                 <div class="detection_text_title">{{ $t('d_page_url') }}</div>
                                 <div class="detection_url cursor" @click="openUrl(detect.dr_page)">{{ detect.dr_page }}</div>
                                 <div class="detection_text_title">{{ $t('d_img_url') }}</div>
                                 <div class="detection_url cursor" @click="openUrl(detect.dr_image)">{{ detect.dr_image }}</div>
                             </div>
                         </el-collapse-item>
                     </el-collapse>-->
            <li class="detection_filter_result_list"  v-for="(detect, index) in detectionData" :key="`detect${detect.dr_idx}`" @click="moveDetail(detect)">
                <ul class="detection_detail_list_wrap">
                    <li>
                        <p class="detection_detail_tit">{{$t('detection_domain1')}}</p>
                        <p class="detection_detail_content">{{ detect.dr_domain }}</p>
                    </li>
                    <li>
                        <p class="detection_detail_tit">URL</p>
                        <p class="detection_detail_content detection-ellipsis">{{ detect.dr_page }}</p>
                    </li>
                    <li>
                        <p class="detection_detail_tit">{{$t('status')}}</p>
                        <p class="detection_detail_content detail-color " :class="returnDomainStatus(detect.dr_status).class">
                            {{ returnDomainStatusName(detect.dr_status) }}
                        </p>
                    </li>
                    <li>
                        <p class="detection_detail_tit">{{$t('detection_date')}}</p>
                        <p class="detection_detail_content">{{ returnDateFormat(detect.created_at, 'YYYY.MM.DD') }}</p>
                    </li>
                </ul>
                <div class="btn-domain-wrap btn-domain-list-wrap">
                    <button class="btn-domain" v-if="detect.dr_except != 1"
                            @click="ignoreDomain(detect)" :disabled="detect.dr_except === 1" >{{ $t('except_domain') }}</button>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
import {detectionDomainStatus} from "@/service/detectionService";
import dateMixins from "@/mixins/dateMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionListDefaultLayout",
    mixins: [dateMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => []},
        itemData: {
            default: () => {
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            selectCollapse: '',
            searchOption: 'permission',
            detectionDomainStatus: detectionDomainStatus,

            clickIgnore: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        collapseChange(e) {
            this.selectCollapse = e;
            let detectionIndex = this.detectionData.findIndex(d => d.dr_idx === e);
            if (detectionIndex > -1) {
                this.detectionData[detectionIndex].dr_status_select = this.detectionData[detectionIndex].dr_status;
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnDomainStatus(value) {
            let index = detectionDomainStatus.findIndex(dds => dds.value == value);
            let returnStatus = {
                value: null,
                name: '-',
                noChange: false,
                class: '',
                description: '기본값',
            }
            if (index > -1) {
                returnStatus = detectionDomainStatus[index];
            }
            return returnStatus;
        },
        returnDomainStatusName(value) {
            let status = this.returnDomainStatus(value);
            if (status.name === '-') {
                return status.name;
            }
            return this.$t(status.name);
        },
        openUrl(url) {
            window.open(url, '_blank');
            //location.href= url;
        },
        returnDetectImage(detect) {
            if (util.isEmpty(detect.dr_image)) {
                return '';
            }
            return `${detect.dr_image}?${this.setImageOptions(271, 176, 'jpg')}`;
        },
        returnItemImage() {
            if (util.isEmpty(this.itemData) || util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.Item)) {
                return "";
            }
            return `${this.itemData.ItemFile.Item[0].org_url}?${this.setImageOptions(271, 176, 'png')}`;
        },
        changeStatus(detect) {
            if (detect.dr_status_select === 'request') {
                this.$router.push(`/detection/${detect.dr_idx}/request`);
                return false;
            }
            EventBus.$emit('detectListChangeStatus', detect);
        },
        ignoreDomain(detect) {
            this.clickIgnore = true;
            setTimeout(() => {
                this.clickIgnore = false;
            }, 500)

            EventBus.$emit('detectListIgnoreDomain', detect)
        },
        moveDetail(detect) {
            this.$router.push(`/detection/${detect.d_idx}/history/${detect.dr_idx}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
