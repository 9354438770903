<template>
    <utility-detail-link ref="utilityDetailLink" :detailLayout="detailLayout" :utilityData="utilityData" :utilityKey="utilityKey"></utility-detail-link>
</template>

<script>
import UtilityUtil from '@/components/utility/UtilityUtil'
import UtilityDetailLink from '@/components/utility/link/UtilityDetailLink.vue'
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";

export default {
    name: "UtilityDetailComponent",
    mixins: [UtilityUtil, alertMixins],
    components: {
        UtilityDetailLink
    },
    inject: [],
    provide() {
        return {
        }
    },
    props: {
        UtilityType: {
            default: () => '',
        },
        utilityKey: {
            default: () => '',
        },
        userUtilityKey: {
            default: () => '',
        },
        defaultApiParamsSet: {
            default: () => {},
        }
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamSet: '',
            keyName: '',
            utilityData: {},
            collectionImgUrl: '',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('updateState', this.updateState);
    },
    beforeUnmount() {
        EventBus.$off('updateState');
    },
    unmounted() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let config = this.returnUtilityConfig(this.UtilityType);
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.keyName = config.keyName;
            this.getUtilityDetail();
        },
        getUtilityDetail() {
            let params = Object.assign({}, this.detailApiParamSet, this.defaultApiParamsSet);

            if (!util.isEmpty(this.keyName)) {
                    params[this.keyName] = this.utilityKey;
            }

            try {
                this.$api.$utility[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (!util.isEmpty(res.List)) {
                        this.utilityData = res.List[0];
                        EventBus.$emit('setUtilityData', res.List[0]);

                        let config = this.returnUtilityConfig(this.UtilityType);
                        this.detailLayout = config.detailLayout;

                        if (this.UserInfo.mb_no == this.utilityData.mb_no) {
                            EventBus.$emit('showSettingBtn', true)
                        }
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
