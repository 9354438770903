const pointConfig = {
    pointStatus: {
        filterLayout: 'PointFilterDefaultLayout',
        listLayout: 'PointListDefaultLayout',
        emptyLayout: 'PointEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    pointCharge: {
        writeLayouts: ['PointWriteDefaultLayout'],
    },
    pointWithdraw: {
        writeLayouts: ['PointWriteWithdrawLayout'],
        getFunc: 'getPointDefine',
        listApiParamSet: {
            co:'WITHDRAWAL_REQUEST'
        },
        parsingData: {
            point: "p_point",
        },
        writeFunc: 'PointWithdraw',
        writeApiParamSet: {},
        writeSuccessType: 1,
        writeFailTypes: {},

    },
    pointHistory: {
        filterLayout: 'PointFilterDefaultLayout',
        listLayout: 'PointListHistoryLayout',
        emptyLayout: 'PointEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getPointHistory',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'created_at',
            obs: 'desc',
            post: 'all',
            //    post : plus //밍글 가감 증가-plus, 감소-minus, 전체 -all
            // stg[0] : auction //상태 auction-경매, deal-거래, contest-공모전, mingle-충전/출금, etc-기타
            // crsd : 2021-10-01 //일자 시작
            // cred : 2021-10-01 //일자 종료
        },
        pageRow: 10,

    },

}
export default {
    methods: {
        returnPointConfig(type) {
            return pointConfig[type];
        }
    },
    filterConfig: {
        cr: {
            type: 'between',
            classname: 'filterDate'
        },
        stg: {
            type: 'terms',
            classname: 'filterStatus',
            option: {
                [0]: 'auction',
                [1]: 'deal',
                [2]: 'competition',
                [3]: 'mingle',
                [4]: 'etc',
            },
        },
        post: {
            type: 'terms',
            classname: 'mingleArr',
            option: {
                'plus': 'plus',
                'minus': 'minus',
            }
        },

    },
}
