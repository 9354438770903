<template>
    <like-link :likeLayout="likeLayout" :likeData="likeData"></like-link>
</template>

<script>
import likeUtil from "@/components/like/likeUtil";
import LikeLink from "@/components/like/LikeLink";
import EventBus from "@/utils/event-bus";

export default {
    name: "LikeComponent",
    mixins: [likeUtil],
    components: {LikeLink},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeType: '',
        likeData: {}
    },
    data() {
        return {
            likeLayout: '',
            writeFunc: '',
            keyName: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('like', this.like);
    },
    beforeDestroy() {
        EventBus.$off('like');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnLikeConfig(this.likeType)
            this.likeLayout = config.likeLayout;
            this.writeFunc = config.writeFunc;
            this.keyName = config.keyName;
        },
        like() {
            let params = {}
            params[this.keyName] = this.likeData.i_idx
            this.$api.$item[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.likeAfterFunc('add');
                } else if (res.Info.type === 2) {
                    this.likeAfterFunc('sub');
                }
            })
        },
        likeAfterFunc(key) {
            if (key === 'add') {
                this.likeData.im_real_like = Number(this.likeData.im_real_like) + 1;
            } else if (key === 'sub') {
                this.likeData.im_real_like = Number(this.likeData.im_real_like) - 1;
            }
            this.likeData.Like.myLike = !this.likeData.Like.myLike;
            this.$forceUpdate();
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
