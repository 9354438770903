<template>
    <div>
        <!--    <div class="search_item mb19">
              <div class="filter_category_box mr5">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="select_style"
                           :popper-append-to-body="false"
                           @change="setFilterFunc('ca', searchInputOption)">
                  <el-option :value="''" :label="$t('all')"></el-option>
                  <el-option :value="`${data.bc_idx}`" :label="data.bc_name"
                             v-for="data in categoryList"
                             :key="`catrgory${data.bc_name}`"></el-option>
                </el-select>
              </div>
              <div class="search_input">
                <el-input :placeholder="$('t')" v-model="searchInput"
                          @keypress.enter.native="setFilterFunc('su', searchInput)">
                </el-input>
                <button class="search_btn" @click="setFilterFunc('su', searchInput)">
                </button>
              </div>
            </div>-->
        <div class="search_item mb19">
            <div class="search_input detection_search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput" @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
            <button class="search_filter_btn" @click="openFilter('faq-filter-modal')"></button>
        </div>
        <faq-filter-modal></faq-filter-modal>
    </div>
</template>

<script>
import boardFilterUtil from "@/components/board/boardFilterUtil";
import FaqFilterModal from "@/components/filter/FaqFilterModal";

const {filterConfig} = boardFilterUtil

export default {
    name: "BoardFilterFaqLayout",
    mixins: [],
    components: {
        FaqFilterModal,
        boardFilterUtil
    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {
            default: () => {
                return {}
            }
        },
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
    },
    data() {
        return{
            searchInput: '',
            searchInputOption: 'su',
            categoryList: [],
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setFilterFunc(key, value){
            this.setFilter(key, value);
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
