<template>
    <div class="footer_wrap" v-if="!$route.meta.noFooter">
        <div class="footer_top_back">
            <div class="footer_top">
                <div class="footer_top_text_wrap">
                    <div class="footer_top_text">HELP CENTER</div>
                </div>
                <div class="footer_top_menu">
                    <div class="inner_left_wrap">
                        <div class="f_text_line" @click="movePage('/notice')">Notice <span class="sub_text">{{ $t('notice') }}</span></div>
                        <div class="f_text_line" @click="movePage('/faq')">FAQ <span class="sub_text">{{ $t('how_to_use') }}</span></div>
                        <div class="f_text_line" @click="movePage('/qna')">Q&A <span class="sub_text">{{ $t('footer_qna') }}</span></div>
                    </div>
                    <div class="inner_right_wrap">
                        <div class="f_text_line" @click="newPage('https://docs.google.com/forms/d/e/1FAIpQLSeIgce-MnXbPK1BLDIW-UxsP4771_hk8sXy85Oj6I7zHkPY2A/viewform')">Partners <span class="sub_text">{{ $t('affiliate_offer') }}</span></div>
                        <div class="f_text_line" @click="openModal(`terms-modal`, 'service')">Terms of Service <span class="sub_text">{{ $t('terms') }}</span></div>
                        <div class="f_text_line" @click="openModal(`terms-modal`, 'privacy')">Privacy Policy <span class="sub_text">{{ $t('footer_personal_terms') }}</span></div>
                    </div>
                </div>
                <div class="footer_top_text_wrap pt35">
                    <div class="footer_top_text">COMMUNITY</div>
                </div>
                <div class="footer_sec_menu">
                    <div class="inner_sns_line">
                        <div class="f_sns_wrap" @click="newPage('https://www.instagram.com/minglemint/')">
                            <img class="sns_icon" src="@/assets/image/sns_1.png" />
                            <div class="sns_name">Instagram</div>
                        </div>
                        <div class="f_sns_wrap" @click="newPage('https://www.facebook.com/minglemint.nft')">
                            <img class="sns_icon" src="@/assets/image/sns_2.png" />
                            <div class="sns_name">Facebook</div>
                        </div>
                        <div class="f_sns_wrap" @click="newPage('https://blog.naver.com/minglemint')">
                            <img class="sns_icon" src="@/assets/image/sns_3.png" />
                            <div class="sns_name">Naverblog</div>
                        </div>
                        <div class="f_sns_wrap" @click="newPage('https://open.kakao.com/o/grunX6Kd')">
                            <img class="sns_icon" src="@/assets/image/sns_4.png" />
                            <div class="sns_name">Kakao channel</div>
                        </div>
                        <div class="f_sns_wrap" @click="newPage('https://www.youtube.com/channel/UC0tQw7DDfq1Zuy6WKee6AdA')">
                            <img class="sns_icon" src="@/assets/image/sns_5.png" />
                            <div class="sns_name">Youtube</div>
                        </div>
                        <div class="f_sns_wrap" @click="newPage('https://twitter.com/minglemint')">
                            <img class="sns_icon" src="@/assets/image/sns_6.png" />
                            <div class="sns_name">Twitter</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer_bottom_back">
            <div class="footer_bottom">
              <div class="fb_logo"></div>
              <div class="fb_content">
                    <div class="fb_copyright">(주)빅스터</div>
                    <span class="fb_copyright_line">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <div class="fb_copyright">대표이사 : 이현종</div>
                    <span class="fb_copyright_line">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <div class="fb_copyright">사업자 등록번호 : 113-86-66578</div>
                    <div class="fb_copyright">통신판매업 신고 : 2021-서울구로-2877</div>
                    <div class="fb_copyright">주소 : 서울 구로구 디지털로 33길 28 우림이비즈센터 1차 406호 08377</div>
                    <div class="fb_copyright">개인정보책임자 : 정성용</div>
                    <span class="fb_copyright_line">&nbsp;&nbsp;|&nbsp;&nbsp;</span>
                    <div class="fb_copyright">TEL : 02-6274-6662</div>
                    <!--                    <div class="fb_copyright">대표전화 : 02-6274-6661</div>-->
                    <!--                    <div class="fb_copyright">팩스 : 02-6274-6663</div>-->
                    <div class="fb_copyright mt15">밍글민트의 모든 컨텐츠를 무단도용시에는 법적인 조치를 받게 됩니다.</div>
                    <div class="fb_copyright">Copyright © 2021 Minglemint all rights reserved.</div>
                </div>
            </div>
        </div>
        <terms-modal></terms-modal>
    </div>
</template>

<script>

import TermsModal from "@/components/modal/TermsModal";

export default {
    name: "PageFooter",
    mixins: [],
    components: {TermsModal},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        movePage(url) {
            this.$router.push(url);
        },
        newPage(url) {
            window.open(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
