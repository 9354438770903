<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap padding_side">
            <div class="mypage_interest_wrap">
                <div class="notice_tabs">
                    <button class="notice_item_4_tab " :class="{'active':tab === 'item'}"
                            @click="changeTab('item')">
                        {{ $t('item') }}
                    </button>
                    <button class="notice_item_4_tab border_left_none" :class="{'active':tab === 'creator'}"
                            @click="changeTab('creator')">
                        {{ $t('creator') }}
                    </button>
                    <button class="notice_item_4_tab border_left_none" :class="{'active':tab === 'contest'}"
                            @click="changeTab('contest')">
                        {{ $t('competition') }}
                    </button>
                    <button class="notice_item_4_tab border_left_none" :class="{'active':tab === 'collect'}"
                            @click="changeTab('collect')">
                        {{ $t('collection') }}
                    </button>
                </div>
                <member-list-component :member-type="'interestCreator'"
                                       v-if="tab === 'creator'"></member-list-component>
                <item-list-component :item-type="'interestItem'" v-if="tab === 'item'"></item-list-component>
                <competition-list-component :competition-type="'interestCompetition'"
                                            v-if="tab === 'contest'"></competition-list-component>
                <collect-list-component :collect-type="'mypageCollect'" :defaultFilter="{no:UserInfo.mb_no}"
                                        v-if="tab === 'collect'"></collect-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import ItemListComponent from "@/components/item/ItemListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import MemberListComponent from "@/components/member/MemberListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import util from "@/mixins/util";
import CollectListComponent from "@/components/collect/CollectListComponent";

export default {
    name: "MyPageInterest",
    mixins: [AuthMixins, alertMixins],
    components: {
        CollectListComponent,
        CompetitionListComponent,
        MemberListComponent,
        MyPageHeaderComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'item',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (!util.isEmpty(this.$route.query.tab)) {
            this.tab = this.$route.query.tab;
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            if (tab === this.tab) {
                return false;
            }
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
