<template>
    <modal name="bank-add-modal"
           class="password-change-modal bank-add-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :scrollable="true"
           :clickToClose="false"
           :width="'90%'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="update-change-modal_wrap ">
                <div class="nickname_title mb25">
                    {{$t('bank_add')}}
                </div>
                <div class="rollback_btn" @click="closeModal()"></div>
                <div class="clear"></div>
                <member-write-component :member-type="'addAccount'"></member-write-component>
<!--                <div class="login_signup_input nickname_change mb18 ">
                    <input type="email" class="register_email" :placeholder="$t('write_pwd')" />
                </div>
                <div class="login_signup_input nickname_change mb10 ">
                    <input type="email" class="register_email" :placeholder="$t('pwd')" />
                </div>
                <div class="login_signup_input nickname_change mb30 ">
                    <input type="email" class="register_email" :placeholder="$t('confirm_pwd')" />
                </div>-->
                <button class="two_btn back_gray" @click="closeModal">{{$t('cancel')}}</button>
                <button class="two_btn back_navy" @click="save">{{$t('save')}}</button>
                <div class="clear"></div>
            </div>
        </div>
    </modal>

</template>

<script>
import EventBus from "@/utils/event-bus";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "BankAddModal",
    mixins: [],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('bank-add-modal');
        },
        handlerBeforeOpen(event) {
        },

        handlerOpened() {
        },

        handlerBeforeClose() {
            // window.scrollTo(0, this.scroll);
        },
        movePage(url) {
            this.$router.push(url);
        },
        save() {
            //EventBus.$emit('updateMember')
            EventBus.$emit('runSaveBank')
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
