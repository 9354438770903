<template>
  <div class="dgm_wrap padding_side mt105">
  <div class="register">
    <div class="login_title">
      <span v-html="$t('loginText1')"></span>
    </div>
    <div class="login_top_img">
      <img src="@/assets/image/main_logo.png" alt="">
    </div>
    <div class="login_nickname">
      <div class="login_signup_input">
        <input :placeholder="$t('nickPlaceholder')" type="text"></input>
      </div>
      <div class="validation validation_margin">
        {{ $t('val_nick') }}
      </div>
      <div class="login_signup">
        <button class="one_btn font_white btn_background ">
          {{ $t('register') }}
        </button>
        <div class="validation validation_margin">
          {{ $t('reject_register') }}
        </div>
      </div>
      <div class="check_wrap mt50" >
        <el-checkbox v-model="termsCheck"></el-checkbox>
        <span class="check_span register_terms" v-html="$t('snslogin_terms')"></span>
      </div>
    </div>

  </div>
  </div>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import alertMixins from "@/mixins/alertMixins";
export default {
  name: "GoogleRegisterComponent",
  mixins: [memberValidator, alertMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      checkTerms: false,
      checkSendMail: true,
      checkGetMail: false,
      errorMsg: false,
      successMsg: false,
      isRunning: true,
      btnCheck: false,
      checkList: ['selected and disabled', 'Option A']
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    openModal(name, type) {
      this.$modal.show(name, {termsType: type})
    },
    checkNum() {
      this.emailChk = this.emailChk.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    },
    sendSms() {
      this.checkGetMail = false;
      this.$validate(['email']).then(success => {
        if (!success) {
          return false;
        }
        let params = {
          mb_email: this.email
        }
        try {
          this.$api.$member.sendMail(params).then(res => res.Data.Result).then(res => {
            if (res.Info.type === 1) {
              this.createAlert({
                title: '',
                content: this.$t('r_send_num'),
                btnText: this.$t('yes'),
                hide: () => {
                  this.checkSendMail = false
                }
              });
            } else {
              this.errorAlert(this.$t('r_send_fail'));
            }
          })
        } catch (e) {
          console.log(e);
        }
      })
    },
    checkMail() {
      if (this.checkSendMail) {
        return false;
      }
      if (this.checkGetMail) {
        return false
      }
      let validatorArr = [`email`, 'emailChk'];
      this.$validate(validatorArr).then(success => {
        if (!success) {
          return false;
        }
        let params = {
          num: this.emailChk,
          mb_id: this.email
        }
        try {
          this.$api.$member.getMail(params).then(res => res.Data.Result).then(res => {
            if (res.type !== 1) {
              this.errorMsg = true;
              this.isRunning = true;
              this.successMsg = false;
              this.checkGetMail = false;
            } else {
              this.isRunning = false;
              this.checkGetMail = true;
              this.successMsg = true;
              this.errorMsg = false;
            }
          })
        } catch (e) {
          console.log(e);
        }
      });
    },
    checkValidator() {
      if (!this.checkTerms) {
        this.btnCheck = true;
        return false;
      } else {
        this.btnCheck = false;
      }
      if (this.isRunning) {
        return false;
      }
      this.isRunning = true;

      this.emailCheckDuplicate = true;
      this.nickCheckDuplicate = true;

      let validatorArr = [`email`, 'emailChk', 'emailCheckDuplicate', 'pwd', 'pwdChk', 'nick', 'nickCheckDuplicate',
      ];
      this.$validate(validatorArr).then(success => {
        if (success) {
          this.writeMember();
        } else {
          this.errorAlert(this.$t('r_register'));
          this.isRunning = false;
        }
      });
    },
    writeMember() {
      let params = {
        mb_nick: this.nick,
        mb_id: this.email,
        mb_password: this.pwd,
        mb_password_confirmation: this.pwdChk,
        num: this.emailChk,
      }
      try {
        this.$api.$member.createMember(params).then(res => res.Data.Result).then(res => {
          if (res.Info.type === 1) {
            this.createAlert({
              title: '',
              content: this.$t('c_register'),
              btnText: this.$t('yes'),
              hide: () => {
                this.$router.push('/');
              }
            });
          } else {
            this.errorAlert(this.$t('r_register'));
          }
        })
      } catch (e) {
        console.log(e);
      }
    },
    changeCheck(dataName) {
      this[dataName] = !this[dataName];
      if (this[dataName]) {
        this[dataName] = true;
      } else {
        this[dataName] = false;
      }
      if (this[dataName]) {
        this.btnCheck = false;
      }
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>