<template>
    <div>
<!--        <iframe name="frAttachFiles" ></iframe>-->
        <form name="form_chk" method="post" action="https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb">
            <input type="hidden" id="m" name="m" value="service" />
            <input type="hidden" id="token_version_id" name="token_version_id" value="" />
            <input type="hidden" id="enc_data" name="enc_data" value="" />
            <input type="hidden" id="integrity_value" name="integrity_value" value="" />
        </form>
    </div>
</template>

<script>
import {apiURL} from "@/api/config";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "MemberAuthenticationComponent",
    mixins: [AuthMixins, alertMixins],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            encodeData: {},
        }
    },
    beforeRouterEnter() {},
    created() {
        let enc_data = this.$localStorage.get('enc_data');
        if(!util.isEmpty(enc_data)) {
            this.decryptData()
        }
        /*if(!util.isEmpty(this.$route.query) && !util.isEmpty(this.$route.query.enc_data)) {
            this.decryptData(this.$route.query);
        }
        if(!util.isEmpty(this.$route.query)) {
            this.$router.replace(this.$route.path);
        }*/
    },
    mounted() {
        EventBus.$on('runAuthenticatio', this.runAuthenticatio)
        EventBus.$on('decryptData', this.decryptData)
    },
    beforeDestroy() {
        EventBus.$off('runAuthenticatio')
        EventBus.$off('decryptData')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        decryptData() {
            let encodeObj = this.$localStorage.get('AuthenticationObj');
            let objData = JSON.parse(atob(encodeObj));
            let enc_data = this.$localStorage.get('enc_data')
            // let objData = encodeObj;
            if(util.isEmpty(encodeObj) || util.isEmpty(objData.key) || util.isEmpty(objData.iv) || util.isEmpty(enc_data)) {
                this.failNiceAuth();
            }
            EventBus.$emit('apiLoading', true);
            let params = {
                data: enc_data,
                key: objData.key,
                iv: objData.iv,
            }
            this.$api.$member.getDecryptData(params).then(res => res.Data.Result).then(res => {
                EventBus.$emit('apiLoading', false);
                if(res.Info.type == 1 ) {
                    this.$localStorage.remove('AuthenticationObj')
                    this.$localStorage.remove('enc_data')
                    let token = this.$localStorage.get('token');
                    this.$store.dispatch('userInfo/setUserInfo', {token: token});
                    this.createAlert({
                        content: this.$t('authentication_success'),
                    });
                }else{
                    this.failNiceAuth();
                }

                // this.memberUpdate({})

            })
        },
        failNiceAuth() {
            this.errorAlert(this.$t('authentication_fail'));
            if(!util.isEmpty(this.$localStorage.get('AuthenticationObj'))) {
                this.$localStorage.remove('AuthenticationObj')
            }
            if(!util.isEmpty(this.$localStorage.get('enc_data'))) {
                this.$localStorage.remove('enc_data')
            }
        },
        runAuthenticatio() {
            /*let data = {
                "token_version_id": "20211214170059B8-NC3DBW123-AH386-B6679D5AA1",
                "enc_data": "1yE9SdkMn0Z+bO/YW2fXQnU6+nXjxFAtiCZa1YFLT+bMBDA6rCYSC4bULPR6VPqGmOUFiVo7ARQkEiUUDonIDOMRkGa5q++3Z7Y/eeA+loLiswycy+Kse1fitMNRjnoOzGhqQtU7P72cQJ9TE8ogixkAzQi72b1S1trvuMWPXEUXxPVMitbjuvltCu6032ecnK5dJImHolTBeH8KQqk7yBDj63Ra2CXb8fkrtp16NZE4qpAkC5XBp8+ODVyNuRYn",
                "integrity_value": "NCc+SkKukjWKo8GQVkmeHPVNnRukcKcwL0RCzYKZeKk=",
                "reqArr": {
                    "requestno": "krhj86eb6ko4o0kekppnrhl8tbtx24",
                    "returnurl": "http://192.168.0.74:8080/auth/certification/result",
                    "sitecode": "QlcxMjM=",
                    "methodtype": "get",
                    "popupyn": "Y",
                    "authtype": "M"
                },
                "req_dtim": "20211214170059",
                "key": "qd4RSMMh9D7+XZGh",
                "iv": "1CoEGBaXuNgZYbk="
            }
            this.checkIdentity(data)
            return false;*/

            let domain = window.location.origin;
            let params = {
                mb_no: this.UserInfo.mb_no,
                url : domain + '/auth/certification/result'
                //url : 'https://www.minglemint.com/auth/certification/result'
            };
            EventBus.$emit('apiLoading', true);
            this.$api.$member.getEncryptData(params).then(res => res.Data.Result).then(res => {
                console.log('res.List', res.List)
                if(util.isEmpty(res.List)) {
                    this.errorAlert(this.$t('authentication_fail'));
                    return false;
                }
                this.encodeData = res.List;
                //this.checkIdentity(res.List)
                setTimeout(() => {
                    this.checkIdentity()
                   EventBus.$emit('apiLoading', false);
                }, 500)
            })
        },
        checkIdentity() {
            try {
                let token_version_id = this.encodeData.token_version_id;
                let enc_data = this.encodeData.enc_data;
                let integrity_value = this.encodeData.integrity_value;

                document.form_chk.action = "https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb";
                document.form_chk.token_version_id.value = token_version_id;
                document.form_chk.enc_data.value = enc_data;
                document.form_chk.integrity_value.value = integrity_value;

                let obj = {
                    key: this.encodeData.key,
                    iv: this.encodeData.iv,
                    mb_no: this.UserInfo.mb_no,
                }
                let decodeObj = btoa(JSON.stringify(obj));
                this.$localStorage.set('AuthenticationObj', decodeObj)
                 window.open('', 'popupChk', 'menubar=no, status=no, toolbar=no, location=no, scrollbar=no');
                 document.form_chk.target = "popupChk";
                document.form_chk.submit();

            }catch (e) {
                console.log(e);
            }
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
