<template>

    <div class="mypage_list_wrap mb24 border-top">
        <div class="mypage_list detection_list" v-for="(detection, index) in detectionData"
             :key="`detection${detection.l_idx}`"
             @click="moveDetail(`/detection/request/${detection.l_idx}`)">
            <div class="item_information">
                <div class="list_time">{{ returnDateFormat(detection.created_at, 'YYYY.MM.DD') }}</div>
                <div class="closing">[{{ returnStatusName(detection.l_status) }}]</div>
                <div class="clear"></div>
            </div>
            <div class="item_img_wrap">
                <div class="item_img pt3">
                    <img :src="returnImageOption(detection.Item)">
                </div>
                <div class="item_text_list">
                    <div class="item_text">{{ detection.Item.i_name }}</div>
                    <div class="item_text"><span class="mr6">{{ $t('request_number') }}</span>{{ detection.l_uuid }}
                    </div>
                    <div class="item_text"><span class="mr6">{{
                            $t('compensation')
                        }}</span>{{ numFormat(detection.l_price) }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {detectionStatus} from "@/service/detectionService";

export default {
    name: "DetectionListRequestHistoryLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            detectionLayouts: 10,
            tempSubject: '아이템 제목이 길어지면 말줄임표를 합니다',
            tempDate: '20210123',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        reverseKeys(n) {
            return [...Array(n).keys()].slice().reverse()
        },
        cancelRequest() {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>" + this.tempSubject + "</span>'<br>" + this.$t('mypage_cancel_confirm'),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {

                },
                cancel: () => {

                }
            })
        },
        moveDetail(url) {
            this.$router.push(`${url}`);
        },
        returnImageOption(item) {
            if (util.isEmpty(item) || util.isEmpty(item.ItemFile) || util.isEmpty(item.ItemFile.Item)) {
                return '';
            }
            return `${item.ItemFile.Item[0].org_url}?${this.setImageOptions(142, 90, 'jpg')}`;
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? '-' : util.Number.numFormat(num);
        },
        returnStatusName(status) {
            let index = detectionStatus.findIndex(ds => ds.value == status);
            return index > -1 ? this.$t(detectionStatus[index].name) : '';
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
