<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="movePage('/search')"></button>
        </page-header>
        <popup-component></popup-component>
        <banner-component></banner-component>
        <competition-no-page-list-component :competitionType="'homeCompetition'"></competition-no-page-list-component>
        <item-home-list-component :item-type="'homeItem'"></item-home-list-component>
        <member-home-list-component :member-type="'topCurator'"></member-home-list-component>

        <curation-history-modal></curation-history-modal>
        <entry-search-modal></entry-search-modal>
        <popup-modal></popup-modal>
    </div>
</template>

<script>

import CompetitionNoPageListComponent from "@/components/competition/CompetitionNoPageListComponent";
import BannerComponent from "@/components/banner/BannerComponent";
import ItemHomeListComponent from "@/components/item/ItemHomeListComponent";
import MemberHomeListComponent from "@/components/member/MemberHomeListComponent";
import HeaderMenuModal from "@/components/modal/HeaderMenuModal";
import {mapState, mapGetters} from "vuex";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import MsocialpopupModal from "@/components/modal/ShareModal";
import EntrySearchModal from "@/components/modal/EntrySearchModal";
import PopupModal from "@/components/modal/PopupModal";
import PopupComponent from "@/components/banner/PopupComponent";

export default {
    name: 'Home',
    mixins: [],
    components: {
        PopupComponent,
        PopupModal,
        EntrySearchModal,
        MsocialpopupModal,
        CurationHistoryModal,
        HeaderMenuModal,
        BannerComponent,
        MemberHomeListComponent,
        ItemHomeListComponent,
        CompetitionNoPageListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        openModal(name) {
            this.$modal.show(name);
        }
    },
    watch: {},
}
</script>
