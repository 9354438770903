<template>
    <div>

        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header" :headerTitle="$t('item_comp')" :header_back="true"></page-header>
        <div class="enroll_wrap">
            <div class="e_btn_wrap">
                <div class="item_btn" @click="movePage(`/itemcreate`)">
                    <div class="img_wrap">
                        <img src="@/assets/image/enroll_item_img.png" alt="">
                    </div>
                    <h3>{{$t('item')}}</h3>
                    <div class="text_box" v-html="$t('e_item_msg1')"></div>
                </div>
                <div class="clear"></div>
                <div class="comp_btn" @click="movePage('/competitioncreate')">
                    <div class="img_wrap">
                        <img src="@/assets/image/enroll_comp_img.png" alt="">
                    </div>
                    <h3>{{$t('competition')}}</h3>
                    <div class="text_box" v-html="$t('e_item_msg3')"></div>
                </div>
                <div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Enroll",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return {
        }
    },
    props: {
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url){
            this.$router.push(url)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
