<template>
    <div class="creator_detail_tab_wrap">
        <div class="creator_detail_tab_scroll">
            <div class="creator_detail_tab" :class="{ active : nowTab === 'collection'}" @click="changeTab('collection')">
                <span class="tab_text">{{ $t('gallery') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'premium'}" @click="changeTab('premium')"
                 v-if="memberData.g_idx === 2">
                <span class="tab_text">{{ $t('premiumMarket') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'collect'}" @click="changeTab('collect')" >
                <span class="tab_text">{{ $t('collection') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'market'}" @click="changeTab('market')"
                 v-if="memberData.g_idx != 2">
                <span class="tab_text">{{ $t('collection2') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'competition'}" @click="changeTab('competition')"
                 v-if="memberData.g_idx != 2">
                <span class="tab_text">{{ $t('competition') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'creation'}" @click="changeTab('creation')"
                 v-if="memberData.g_idx != 2">
                <span class="tab_text">{{ $t('creationItem') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'portfolio'}" @click="changeTab('portfolio')">
                <span class="tab_text">{{ $t('introduction') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
    name: "CreatorDetailTabs",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberKey: {default: 0},
    },
    data() {
        return {
            memberData: {},
            nowTab: 'collection',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.settingFirstTab();
    },
    mounted() {
        EventBus.$on('setTabsMemberData', this.setMemberData);
    },
    beforeDestroy() {
        EventBus.$off('setTabsMemberData');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        settingFirstTab() {
            let url = this.$route.path;
            if (url === `/creator/${this.memberKey}`) {
                this.nowTab = 'collection';
            } else if (url === `/creator/${this.memberKey}/premium`) {
                this.nowTab = 'premium';
            } else if (url === `/creator/${this.memberKey}/market`) {
                this.nowTab = 'market';
            } else if (url === `/creator/${this.memberKey}/competition`) {
                this.nowTab = 'competition';
            } else if (url === `/creator/${this.memberKey}/creation`) {
                this.nowTab = 'creation';
            } else if (url === `/creator/${this.memberKey}/portfolio`) {
                this.nowTab = 'portfolio';
            } else if (url === `/creator/${this.memberKey}/collect`) {
                this.nowTab = 'collect';
            } else {
                this.nowTab = 'collection';
            }
        },
        changeTab(type) {
            if (this.nowTab === type) {
                return false;
            }
            this.nowTab = type;
            if (type === 'collection') {
                this.$router.replace('/creator/' + this.memberKey);
                return false;
            }
            this.$router.replace('/creator/' + this.memberKey + '/' + type);
        },
        setMemberData(value) {
            this.memberData = value;
        },
    },
    watch: {},
}
</script>
