<template>
  <modal name="item-filter-entry-filter" class="filter-modal item-filter-entry-filter"
         :width="`calc(100vw - 28px)`" :height="`auto`" :pivotY="0.5"
         @before-close="handlerBeforeClose"
         @before-open="handlerBeforeOpen"
         @opened="handlerOpened"
  >
    <div>
      <div class="filter_modal_wrap">
        <div class="filter_modal_top">
          <div class="filter_img"></div>
          <div class="filter_name">{{ $t('filter') }}</div>
          <div class="close_btn" @click="modalClose()"></div>
        </div>

        <div class="mt20 filter_modal_status">
          <div class="modal_title">{{ $t('exposure') }}</div>
          <div class="check_wrap">
            <div class="checkbox inline_block">
              <el-checkbox-group v-model="filterCategory"
                                 @change="setFilterFunc('ic', filterCategory)">
                <el-checkbox :label="2">{{ $t('b_update') }}</el-checkbox>
                <el-checkbox :label="9">{{ $t('b_enter_m') }}</el-checkbox>
                <el-checkbox :label="3">{{ $t('b_like') }}</el-checkbox>
                <el-checkbox :label="5">{{ $t('priceDesc') }}</el-checkbox>
                <el-checkbox :label="4">{{ $t('priceAsc') }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>

        <div class="mt20 filter_modal_edition">
          <div class="modal_title">{{ $t('edition') }}</div>
          <div class="check_wrap">
            <div class="checkbox inline_block">
              <el-checkbox-group v-model="filterEdition"
                                 @change="setFilterFunc('ed', filterEdition)">
                <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                <el-checkbox :label="0">{{ $t('single') }}</el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>

        <div class="wrapper_bottom">
          <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
  name: "ItemFilterEntryFilter",
  mixins: [],
  components: {},
  inject: ['setFilter', 'setSort'],
  provide() {
    return {}
  },
  props: {
    modalLayout: '',
  },
  data() {
    return {
      component: null,
      sortData: {},
      searchInputOption: 'ina',
      sort: '',
      sortOption: 'last_update',
      searchInput: '',
      filterCategory: [],
      filterStatus: [],
      filterEdition: [],
      filterType: [],
      priceInput: ['', ''],
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    handlerBeforeOpen(event) {
      this.sortData = event.params.sortData;
      this.setterFilterDataInData();
    },
    handlerOpened() {
    },
    handlerBeforeClose() {
    },
    modalClose() {
      this.$modal.hide('item-filter-entry-filter');
    },
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },

    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    changeFilter() {
      if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
        return
      }
      this.setFilterFunc('pr', this.priceInput);
      this.modalClose();
    },

  },
  watch: {},
}
</script>

<style scoped>

</style>
