import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            point: 0,
            haveMg:''
        }
    },
    computed: {},
    validators: {
        point: function (value) {
            return Validator.value(value)
                .required(this.$t('withdraw_err1'))
                .custom(
                    () => {
                        if (value === '' || Number(value) === 0) {
                            return this.$t('empty_price')
                        }
                        let str = value.toString();
                        if (str.toString().indexOf(',') > 0) {
                            str = str.replace(',', '');
                        }
                        let int = Number(str);
                        if (int < 5000) {
                            return this.$t('Mg_500_msg')
                        }
                        if(value > Number(this.haveMg)){
                            value = Number(this.haveMg);
                        }
                    }
                )

        },
    },
    methods: {


    },
}
