<template>
    <div class="competition_wrap pb17 padding_side">
        <div class="competition_item application_item">
            <div class="competition_input">
                <el-input :placeholder="$t('st')" v-model="searchInput" @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
                </el-input>
                <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_sort_btn ma0" @click="openFilter('open-market-sort-filter')"></button>
            <button class="c_filter_btn ma0 ml13" @click="openFilter('openmarket-filter-modal')"></button>
        </div>
        <div class="clear"></div>
        <!--        <item-filter-creation-user-filter></item-filter-creation-user-filter>-->
        <div class="clear"></div>
        <openmarket-filter-modal></openmarket-filter-modal>
        <open-market-sort-filter></open-market-sort-filter>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";
import ItemFilterCreationUserFilter from "@/components/filter/ItemFilterCreationUserFilter";
import OpenmarketFilterModal from "@/components/modal/OpenmarketFilterModal";
import OpenMarketSortFilter from "@/components/sort/OpenMarketSortFilter";

const {filterConfig} = itemUtil
export default {
    name: "ItemFilterCreationUserLayout",
    mixins: [],
    components: {
        ItemFilterCreationUserFilter,
        OpenmarketFilterModal,
        OpenMarketSortFilter
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
        sortData: {type: Object, default: () => {}},
    },
    data() {
        return{
            searchMsg:'',
            searchInputOption: 'ina',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.setterFilterDataInData();
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openModal(name){
            this.$modal.show(name, {modalLayout: 'ModalFilterDefaultLayout'});
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/curationrequest');
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'asc');
            } else if (data === 'priceDesc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'desc');
            } else {
                this.setFilterFunc('obt', data);
                this.setFilterFunc('obs', 'desc');
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if(this[dataName] ) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if(this[dataName]) {
                this.btnCheck = false;
            }
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        openFilter(name) {
            this.$modal.show(name, {sortData: this.sortData, filterData: this.filterData});
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
