<template>
    <modal name="detection-list-url-modal"
           class="detection-list-url-modal"
           :pivotX="0.5"
           :pivotY="0.36"
           :adaptive="true"
           :width="'90%'"
           :height="'222px'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>

            <div class="update-change-modal_wrap">
                <div class="nickname_title mb25">
                    {{ $t('ignore_url_enroll') }}
                </div>
                <div class="rollback_btn" @click="modalClose()"></div>
                <div class="clear"></div>
                <div class="detection_list_info">
                    {{ $t('ignore_url_text') }}
<!--                    등록된 단어를 포함하는 도메인은 검출 결과에 반영하지 않습니다.-->
                </div>

                <detection-write-component :detection-type="'urlWrite'"></detection-write-component>
<!--                <div class="login_signup_input nickname_change mb25">
                    <input type="email" class="register_email" placeholder="도메인 단어입력" />
                </div>-->
                <button class="two_btn back_gray" @click="modalClose()">{{$t('cancel')}}</button>
                <button class="two_btn back_navy" @click="runWrite">{{$t('save')}}</button>
                <div class="clear"></div>
                <div class="val_cap" v-if="validatorErr != ''">{{validatorErr}}</div>
            </div>
        </div>
    </modal>
</template>

<script>
import DetectionWriteComponent from "@/components/detection/DetectionWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionListUrlModal",
    mixins: [],
    components: {
        DetectionWriteComponent
    },
    inject: [],
    provide() {
        return {
            setValidatorErr: this.setValidatorErr,
        };
    },
    props: {},
    data() {
        return {
            validatorErr: '',
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        handlerBeforeOpen(event) {
            this.scroll = event.params.scroll
            document.body.style.top = `-${this.scroll}px`
        },

        handlerOpened() {
        },

        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },

        modalClose() {
            this.$modal.hide('detection-list-url-modal');
        },
        returnUrl() {
            return this.url
        },
        setValidatorErr(value) {
            this.validatorErr = value;
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
