<template>
<!--    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-select id="category" v-model="searchInputOption" class="my_filter select"
                       popper-class="select_style"
                       :popper-append-to-body="false"
            >
                <el-option :value="'ina'" :label="$t('subject')"></el-option>
                <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
            </el-select>
        </div>
        <div class="search_input mypage_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
            <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
            </button>
        </div>
        <button class="search_filter_btn" @click="openFilter('item-filter-detection-filter')"></button>
        <item-filter-detection-filter></item-filter-detection-filter>
    </div>-->
    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
            <button class="search_btn mypage_search_btn"  @click="setFilterFunc(searchInputOption,searchInput)"></button>
        </div>
        <button class="search_filter_btn" @click="openFilter('item-filter-detection-filter')"></button>
        <item-filter-detection-filter></item-filter-detection-filter>
    </div>

</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";
import ItemFilterDetectionFilter from "@/components/filter/ItemFilterDetectionFilter";

const {filterConfig} = itemUtil;

export default {
    name: "ItemFilterDetectionLayout",
    mixins: [],
    components: {ItemFilterDetectionFilter},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            searchInput: '',
            filterPossessDate: [],
            filterMatch: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        openModal(name) {
            this.$modal.show(name, {modalLayout: 'ModalFilterDefaultLayout'});
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        changeFilter() {
            this.setFilterFunc('ma', this.filterMatch);
            this.closeModal()
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
