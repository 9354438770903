import util from "@/mixins/util";

const boardFilterConfig = {
    notice: {
        getFunc: 'getNotice',
        paginationType: 'pagination',
        listApiParamSet: {
            ip: 1,
            type: 'post'
        }
    },
    noticeDetail: {
        getFunc: 'getNotice',
        detailApiParamSet: {
            ip: 1,
            type: 'post'
        },
        getKeyName: 'id',
        keyName: 'id'
    },
    qna: {
        getFunc: 'getQna',
        paginationType: 'pagination',
        listApiParamSet: {
            type: 'post',
            cmt: 1,
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'updated_at',
            obs: 'desc',
            la: 1,
        }
    },
    qnaDetail: {
        getFunc: 'getQna',
        detailApiParamSet: {
            ip: 1,
            type: 'post',
            cmt: 1,
            cmtd: 1,
            f: 1,
            wr: 1,
        },
        getKeyName: 'id',
        keyName: 'wr_id',

        deleteFunc: 'deleteQna',
        deleteApiParamsSet: {
            type: 'post'
        },
        deleteSuccessType: 1,
        deleteFailTypes: {},
    },
    qnaCreate: {
        writeFunc: 'createQna',
        writeApiParamSet: {
            type: 'post',
            wr_type: 'post',
        },
        parsingData: {
            title: 'wr_subject',
            content: 'wr_content',
        },
        directUrl: '/qna',
        directType: '',
        writeSuccessType: 1,
        writeFailTypes: {},
        fileInfo: [
            {
                fileName: 'files',
                fileType: 'qna',
            },
        ],
    },
    qnaUpdate: {
        getFunc: 'getQna',
        detailApiParamsSet: {
            type: 'post',
            cmt: 1,
            cmtd: 1,
            wr: 1,
            f: 1,
        },
        keyName: 'wr_id',
        getKeyName: 'id',
        parsingData: {
            title: 'wr_subject',
            content: 'wr_content',
        },
        writeApiParamSet: {
            type: 'post',
            wr_type: 'post',
        },
        writeSuccessType: 1,
        writeFailTypes: {},
        writeFunc: 'updateQna',
        directType: 'back',
        directUrl: '',
        fileInfo: [
            {
                fileName: 'files',
                fileType: 'qna',
            },
        ],
    },
    faqCategory: {
        getFunc: 'getCategory',
        categoryApiParamSet: {}
    },
    faq: {
        getFunc: 'getFaq',
        listApiParamSet: {
            type: 'post',
            c: 1,
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'updated_at',
            obs: 'desc',
            la: 1,
        },
        pageRow: 10,
        isLanguage: true,
    },
    faqDetail: {
        getFunc: 'getFaq',
        detailApiParamSet: {
            type: 'post',
            c: 1,
        },
        getKeyName: 'id',
        keyName: 'id',

        getCategoryFunc: 'getCategory',
        categoryApiParamsSet: {
            na: '', // 카테고리명
            // ip:0,
        },
        isLanguage: true,
    },
}

export default {
    methods: {
        returnBoardFilterConfig(type){
          return boardFilterConfig[type];
        },
    },
    data(){
        return {}
    },
    filterConfig: {
        su: {
            type: 'match',
            classname: 'searchInput'
        },
        ca: { // faq
            type: 'match',
            classname: 'filterCategory'
        },
        isa: { //  qna
            type: 'match',
            classname: 'filterStatus'
        },
    }
}
