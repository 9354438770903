<template>
    <div>
        <!--        1등-->
        <div class="award_list_wrap" v-if="checkData('firstData')"
             @click="moveItemDetail(firstData[0])">
            <div class="award_list">
                <div class="award_img_wrap">
                    <div class="award_img"
                         :style="` background-image: url(${returnImage(firstData[0].Item.ItemFile.Item,1200,697)});`">
                    </div>
                    <div class="award_first">
                        {{ $t('1th') }}
                    </div>
                </div>
                <div class="award_content_wrap">
                    <div class="a_c_subject_wrap">
                        {{ firstData[0].Item.i_name }}
                    </div>
                    <div class="a_c_middle">
                        <div class="a_c_middle_thumb">
                            <img :src="returnImage(firstData[0].Item.profile.originator)">
                        </div>
                        <div class="a_c_nickname ml4">{{ firstData[0].Item.originator_nick }}</div>
                    </div>
                    <div class="a_c_bottom pb17 pt17">
                        <img src="@/assets/image/icon_vote.png">
                        <span class="ml5">
                            {{ returnCommas(firstData[0].ci_vote_cnt) }}
                            ({{ returnPercentage(firstData[0].ci_vote_cnt, firstData[0].totalVote) }}%)</span>
                      <div class="result_btn">
                        <button @click="openResultModal(firstData[0].ci_result,$event)">{{ $t('r_judge') }}</button>
                      </div>
                    </div>

                    <div class="clear"></div>
                </div>
            </div>
        </div>
        <div class="item_premium_list_wrap pa0">
            <!--            2등-->
            <div class="item_premium_list border-radius_ten" @click="moveItemDetail(secondData[0])"
                 v-if="checkData('secondData')">
                <div class="img_wrap">
                    <div class="img_bg"
                         :style="` background-image: url(${returnImage(secondData[0].Item.ItemFile.Item,570,331)});`">
                    </div>
                    <div class="award_first award_second">
                        {{ $t('2th') }}
                    </div>
                </div>

                <div class="content_wrap pb10">
                    <div class="subject_wrap competition_subject"> {{ secondData[0].Item.i_name }}
                    </div>
                    <div class="content_middle mb12">
                        <ul class="middle_thumb">
                            <li class="thumb_wrap competition_thumb">
                                <img :src="returnImage(secondData[0].Item.profile.originator)" alt="">
                            </li>
                        </ul>
                        <div class="c_t_nickname">
                            {{ secondData[0].Item.originator_nick }}
                        </div>
                    </div>
                    <div class="content_bottom pb10 pt9">
                        <img src="@/assets/image/icon_vote.png">
                        {{
                            returnCommas(secondData[0].ci_vote_cnt)
                        }}({{ returnPercentage(secondData[0].ci_vote_cnt, secondData[0].totalVote) }}%)
                    </div>
                    <div class="result_btn award_result">
                        <button @click="openResultModal(secondData[0].ci_result,$event)">{{
                                $t('awardResult')
                            }}
                        </button>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <!--3등-->
            <div class="item_premium_list border-radius_ten" @click="moveItemDetail(thirdData[0])"
                 v-if="checkData('thirdData')">
                <div class="img_wrap">
                    <div class="img_bg"
                         :style="` background-image: url(${returnImage(thirdData[0].Item.ItemFile.Item,570,331)});`"></div>
                    <div class="award_first award_third">
                        {{ $t('3th') }}
                    </div>
                </div>

                <div class="content_wrap pb10">
                    <div class="subject_wrap competition_subject">{{ thirdData[0].Item.i_name }}
                    </div>
                    <div class="content_middle mb12">
                        <ul class="middle_thumb">
                            <li class="thumb_wrap competition_thumb">
                                <img :src="returnImage(thirdData[0].Item.profile.originator)" alt="">
                            </li>
                        </ul>
                        <div class="c_t_nickname">
                            {{ thirdData[0].Item.originator_nick }}
                        </div>
                    </div>
                    <div class="content_bottom pb10 pt9">
                        <img src="@/assets/image/icon_vote.png">
                        {{
                            returnCommas(thirdData[0].ci_vote_cnt)
                        }}({{ returnPercentage(thirdData[0].ci_vote_cnt, secondData[0].totalVote) }}%)
                    </div>
                    <div class="result_btn award_result">
                        <button @click="openResultModal(thirdData[0].ci_result,$event)">{{
                                $t('awardResult')
                            }}
                        </button>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
            <!--            4등이후-->
            <div class="item_premium_list border-radius_ten" v-if="checkData('otherData')" v-for="award in otherData"
                 :key="`award${listLayouts}`"
                 @click="moveItemDetail(award)">
                <div class="img_wrap">
                    <div class="img_bg"
                         :style="` background-image: url(${returnImage(award.Item.ItemFile.Item,570,331)});`"></div>
                    <div class="award_first award_fourth" :id="`${award.ci_rank}`">{{ award.ci_rank }}{{ $t('th') }}
                    </div>
                </div>
                <div class="content_wrap pb10">
                    <div class="subject_wrap competition_subject"> {{ award.Item.i_name }}
                    </div>
                    <div class="content_middle mb12">
                        <ul class="middle_thumb">
                            <li class="thumb_wrap competition_thumb">
                                <img :src="returnImage(award.Item.profile.originator)" alt="">
                            </li>
                        </ul>
                        <div class="c_t_nickname">
                            {{ award.Item.originator_nick }}
                        </div>
                    </div>
                    <div class="content_bottom pb10 pt9">
                        <img src="@/assets/image/icon_vote.png">
                        {{ returnCommas(award.ci_vote_cnt) }}({{
                            returnPercentage(award.ci_vote_cnt, award.totalVote)
                        }}%)
                    </div>
                    <div class="result_btn award_result">
                        <button @click="openResultModal(award.ci_result,$event)">{{ $t('awardResult') }}</button>
                    </div>
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "AwradListDefaultLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        awardList: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            idx: 1,
            stepLayout: '',
            listLayouts: 7,
            firstData: [],
            secondData: [],
            thirdData: [],
            otherData: []
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.initData()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openResultModal(reason, e) {
            e.stopPropagation()
            this.$modal.show('judgement-result-modal', {reason: reason});
        },
        initData() {
            this.firstData = this.awardList.filter(v => v.ci_rank === 1)
            this.secondData = this.awardList.filter(v => v.ci_rank === 2)
            this.thirdData = this.awardList.filter(v => v.ci_rank === 3)
            this.otherData = this.awardList.filter(v => {
                return v.ci_rank > 3
            })

            if (this.awardList.length >= 3) {
                this.stepLayout = 3
            } else {
                this.stepLayout = this.awardList.length
            }
        },
        returnImage(image, width = 30, height = 30, f = 'jpg') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
            }
            return ''
        },
        returnCommas(x = 0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        returnDetail(type) {
            return this[type][0].Item.ItemFile.Item
        },
        movePage(url) {
            this.$router.push(url)
        },
        moveItemDetail(data) {
            if (util.isEmpty(data) || util.isEmpty(data.Item)) {
                return false;
            }
            this.$router.push('/item/' + data.Item.i_sku);
        },
        checkData(data) {
            return !util.isEmpty(this[data]);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
