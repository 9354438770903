<template>
    <div class="">
        <gallery-list-link :listLayout="listLayout" :galleryData="List" :paginationData="filterSet.page" v-if="List.length !== 0"></gallery-list-link>
        <gallery-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" v-else></gallery-empty-link>
<!--      <gallery-list-link :listLayout="listLayout" :galleryData="List" :paginationData="filterSet.page" ></gallery-list-link>-->
      <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
    </div>
</template>

<script>
import GalleryEmptyLink from "@/components/gallery/GalleryEmptyLink";
import GalleryListLink from "@/components/gallery/GalleryListLink";
import PaginationLink from "@/components/gallery/PaginationLink";
import galleryUtil from "@/components/gallery/galleryUtil";
import GalleryFilterLink from "./GalleryFilterLink";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import DeleteClass from "@/mixins/deleteClass";

const {filterConfig} = galleryUtil;
export default {
    name: "GalleryListComponent",
    mixins: [galleryUtil, filterMixins, listMixins, alertMixins],
    components: {
        GalleryFilterLink,
        GalleryEmptyLink,
        GalleryListLink,
        PaginationLink
    },
    inject: [],
    provide() {
        return{
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setDetectionListData: this.setData,
        }
    },
    props: {
        galleryType: '',
        defaultFilter: {default: () => {}},
    },
    data() {
        return{
            listLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: 10,
            sortOptions: {},
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            keyName: '',

            deleteClass: null,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('getGalleryList', this.getList)
        EventBus.$on('galleryListDelete', this.runDelete)
    },
    beforeDestroy() {
        EventBus.$off('getGalleryList')
        EventBus.$off('galleryListDelete')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setData(type, value) {
            this[type] = value;
        },
        initConfig(){
            let config = this.returnGalleryConfig(this.galleryType);
            this.listLayout = config.listLayout;
            this.emptyLayout = config.emptyLayout;
            this.paginationLayout = config.paginationLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.keyName = config.keyName;

            if (config.pageRow && config.pageRow != this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = util.isEmpty(this.listApiParamSet) ? {} : JSON.parse(JSON.stringify(this.listApiParamSet));

            if(!util.isEmpty(this.defaultFilter)) {
                params = Object.assign({}, params, this.defaultFilter);
            }

            let listOptions = {
                url: this.$api.$gallery[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = config.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
            this.initDeleteClass();
        },
        initDeleteClass() {
            if(util.isEmpty(this.deleteFunc)) {
                return false;
            }
            let options = {
                func: this.$api.$gallery[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.keyName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        deleteAfterFunc() {
            this.getList();
        },
        runDelete(key) {
            this.deleteClass.runDelete(key);
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
