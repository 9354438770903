<template>
    <modal name="collect-default-filter" class="filter-modal item-filter-default-filter"
           :width="`calc(100% - 28px)`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>
                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('all_sell_price') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">
                            <el-input class="el_price_input" type="number"oninput="value = value.replace(/[^0-9.]/g,'')"
                                      v-model="filterPrice[0]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input" type="number"oninput="value = value.replace(/[^0-9.]/g,'')"
                                      v-model="filterPrice[1]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="setFilterFunc('tot', filterPrice)">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "CollectDefaultFilter",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            filterPrice: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('collect-default-filter');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
