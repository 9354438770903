<template>
  <div class="dgm_wrap mt105 mb90">

    <div class="register">
      <div class="register_after_wrap">

        <div class="login_top_img">
          <img src="@/assets/image/main_logo.png" alt="">
        </div>
        <div class="login_title">
          <span v-html="$t('register_after')"></span>
          <!--          {{$t('loginText1')}}-->
        </div>
        <div class="register_after">
          <button class="one_btn back_navy" @click="movePage('/auth')">{{
              $t('yes')
            }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>


export default {
    name: "RegisterAfter",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
