import moment from "moment-timezone";
import util from "@/mixins/util";
import API from '@/api/index'

class ItemRegister { //ItemRegister
    getCreateClassConstants() {
        let CreateClassConstants = {
            ApiDataDefine: {
                i_type: 'digital',
                i_name: '',
                i_status: 'nosale',
                i_memo: '',
                ic_idx: '',
                im_originator: 1,
                im_type: 'etc',
                im_enroll: 1,
                im_adult_chk: 0,
                im_edition: 0,
                im_status: 9,
                c_idx: '',
            },
        }
        return CreateClassConstants
    }

    makeParams(params) {
        let resultParams = this.getCreateClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {

            resultData.type = res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod(itemKey) {
        console.log(itemKey)
        if (itemKey != null) {
            return API.$item.updateItem
        }
        return API.$item.createItem
    }
}

class ItemTemporary {
    getTemporaryClassConstants() {
        let TemporaryClassConstants = {
            ApiDataDefine: {
                i_type: 'digital',
                i_name: '',
                i_status: 'nosale',
                i_memo: '',
                ic_idx: '',
                im_originator: 1,
                im_type: 'etc',
                im_enroll: 2,
                im_adult_chk: 0,
                im_edition: 0,
                im_status: 9,
            },
        }
        return TemporaryClassConstants
    }

    makeParams(params) {
        let resultParams = this.getTemporaryClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            resultData.type = res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod(itemKey) {
        if (itemKey != null) {
            return API.$item.updateItem
        }
        return API.$item.createItem
    }
}

class ItemAuction {
    getAuctionClassConstants() {
        let AuctionClassConstants = {
            ApiDataDefine: {
                im_enroll: 1,
                im_status: 1,
                i_status: "auction",
                ia_is_immediately: 0,
            },
        }
        return AuctionClassConstants
    }

    makeParams(params) {
        let resultParams = this.getAuctionClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            resultData.type = res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod() {
        return API.$item.updateItem
    }
}

class ItemLicense {
    getLicenseClassConstants() {
        let LicenseClassConstants = {
            ApiDataDefine: {
                im_enroll: 1,
                im_status: 3,
                i_status: "license",
            },
        }
        return LicenseClassConstants
    }

    makeParams(params) {
        let resultParams = this.getLicenseClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            resultData.type =  res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod() {
        return API.$item.updateItem
    }
}

class ItemLimits {
    getLimitsClassConstants() {
        let LimitsClassConstants = {
            ApiDataDefine: {
                im_enroll: 1,
                im_status: 4,
                i_status: "pricetag",
            },
        }
        return LimitsClassConstants
    }

    makeParams(params) {
        let resultParams = this.getLimitsClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            resultData.type =  res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod() {
        return API.$item.updateItem
    }
}

class ItemNoSale {
    getNoSaleClassConstants() {
        let NoSaleClassConstants = {
            ApiDataDefine: {
                im_enroll: 1,
                im_status: 9,
                i_status: "nosale",
            },
        }
        return NoSaleClassConstants
    }

    makeParams(params) {
        let resultParams = this.getNoSaleClassConstants().ApiDataDefine;
        resultParams = Object.assign({}, resultParams, params);
        return resultParams;
    }

    getResultData(res, resultData) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            resultData.type =  res.List.Data.Status;
            if (resultData.type) {
                let key = res.List.Data.Data.Basic.Data.Item.Data.i_idx
                if (util.isArray(key)) {
                    key = key[0]
                }
                resultData.key = key;
            }
        }
        return resultData
    }

    getApiMethod() {
        return API.$item.updateItem
    }
}

class ItemClass {
    User = null;
    UserKey = 'mb_no';

    constructor(ItemUser) {
        let User = ItemUser || null,
                UserKey = ItemUser[this.getItemClassConstants().UserKey] || null

        this.User = User;
        this.UserKey = UserKey;

        this._settingExifOptions();
    }

    getItemClassConstants() {
        let ItemClassConstants = {
            ItemStatus: {
                create: {
                    addHash: true,
                },
                temporary: {
                    addHash: false,
                },
                auction: {
                    addHash: false,
                },
                license: {
                    addHash: false,
                },
                limits: {
                    addHash: false,
                },
            },
            ExifDefine: {
                photo: {
                    create: {value: '', options: {readonly: false,},},
                    country: {value: '', options: {readonly: false,},},
                    si: {value: '', options: {readonly: false,},},
                    city: {value: '', options: {readonly: false,},},
                    size: {value: '', options: {readonly: false,},},
                    copyright: {value: '', options: {readonly: false,},},
                },
                video: {
                    create: {value: '', options: {readonly: false,},},
                    country: {value: '', options: {readonly: false,},},
                    si: {value: '', options: {readonly: false,},},
                    city: {value: '', options: {readonly: false,},},
                    size: {value: '', options: {readonly: false,},},
                    copyright: {value: '', options: {readonly: false,},}
                },
                audio: {
                    create: {value: '', options: {readonly: false,},},
                    country: {value: '', options: {readonly: false,},},
                    si: {value: '', options: {readonly: false,},},
                    city: {value: '', options: {readonly: false,},},
                    size: {value: '', options: {readonly: false,},},
                    copyright: {value: '', options: {readonly: false,},}
                },
                etc: {
                    create: {value: '', options: {readonly: false,},},
                    country: {value: '', options: {readonly: false,},},
                    si: {value: '', options: {readonly: false,},},
                    city: {value: '', options: {readonly: false,},},
                    copyright: {value: '', options: {readonly: false,},}
                },
            },
            ResultData: {key: null, type: -1},
            UserKey: 'mb_no',
            ItemKey: 'i_idx',
            defaultDataValue: "",
        }
        return ItemClassConstants;
    }

    _settingExifOptions() {
    }

    getExifDataObj(type = 'etc') {
        let ExifDefine = this.getItemClassConstants().ExifDefine
        let obj = ExifDefine[type] || ExifDefine.etc
        return obj
    }

    _getOrderType(type) {
        let writeClass = null;
        if (type === 'create') {
            writeClass = new ItemRegister()
        } else if (type === 'temporary') {
            writeClass = new ItemTemporary()
        } else if (type === 'auction') {
            writeClass = new ItemAuction()
        } else if (type === 'license') {
            writeClass = new ItemLicense()
        } else if (type === 'limits') {
            writeClass = new ItemLimits()
        } else if (type === 'nosale') {
            writeClass = new ItemNoSale()
        }
        return writeClass
    }

    _getItemKey(params) {
        let itemKey = this.getItemClassConstants().ItemKey;
        if (params.hasOwnProperty(itemKey)) {
            return params[itemKey]
        }
        return null;
    }

    _checkAbleWriteUser() {
        if (util.isEmpty(this.User)) {
            return false;
        }
        return true;
    }

    runWrite(type, data) {
        let writeClass = this._getOrderType(type);
        let resultData = this.getItemClassConstants().ResultData
        return new Promise(async resolve => {
            if (!this._checkAbleWriteUser()) {
                resolve(resultData)
                return false;
            }
            if (writeClass == null) {
                resolve(resultData)
                return false;
            }
            let params = writeClass.makeParams(data)
            let itemKey = this._getItemKey(params)
            let func = writeClass.getApiMethod(itemKey)
            func(params).then(res => res.Data.Result).then(res => {
                let resData = writeClass.getResultData(res, resultData);
                resolve(resData)
            })
        })
    }

    _getItemDataBasic(itemData, resultData) {
        resultData = {
            i_idx: itemData.i_idx,
            title: itemData.i_name,
            category: itemData.ic_idx,
            type: itemData.i_type,
            status: itemData.im_status,
            //isEdition: itemData.im_edition,
            isEdition: 0,
            editionCount: '',
            editionTotal: '',
            originator: 1,
            isCheckAdult: itemData.im_adult_chk,
            information: itemData.i_memo,
            exifType: itemData.im_type,
            hashtag: [],
            exif: {},
            isGuarantee: 0,
            guaranteeFile: [],
            thumbnailFile: [],
            originalFile: [],
            detailFile: [],
            ntfFile: [],

            minimumSetting: 0,
            minimum: "",
            immediatelySetting: 0,
            immediately: '',
            startDate: '',
            startDateSetting: 0,

            smallType: false,
            mediumType: false,
            largeType: false,
            smallPrice: '',
            mediumPrice: '',
            largePrice: '',
            originalPrice: '',
            caution: '',
            priceOrg: itemData.i_price,
            updateMember: itemData.provider,
        }
        if (!util.isEmpty(itemData.im_originator)) {
            resultData.originator = 1;
            resultData.originatorId = itemData.im_originator;
            resultData.originatorNick = itemData.originator_nick;
        }
        if (!util.isEmpty(itemData.ibc_curator)) {
            resultData.updateMember = itemData.ibc_curator;
        }
        return resultData
    }

    _getItemDataEdition(itemData, resultData) {
        if (itemData.im_edition === 1) {
            resultData.isEdition = itemData.im_edition;
            resultData.editionTotal = itemData.im_total;
            if (itemData.i_type === 'real') {
                resultData.editionCount = itemData.im_count;
            }
        }
        return resultData
    }

    _getItemDataHashtag(itemData, resultData) {
        let hashtag = [];
        util.Array.each(itemData.Hash, (hash, index, arr) => {
            let data = {type: hash.ih_type, tag: '',}
            if (data.type === 1) {
                data.tag = hash.ic_idx;
            } else if (data.type === 2) {
                data.tag = hash.ih_hashtag;
            }
            hashtag.push(data)
        })
        resultData.hashtag = hashtag;
        return resultData
    }

    _getItemDataExif(itemData, resultData) {
        let exif = this.getExifDataObj(itemData.im_type);
        resultData.exifType = itemData.im_type;
        if (!util.isEmpty(itemData.imo_date) && exif.hasOwnProperty('create') && itemData.imo_date != '0000-00-00 00:00:00') {
            exif.create.value = itemData.imo_date;
            exif.create.options.readonly = true;
        }
        if (!util.isEmpty(itemData.imo_country) && exif.hasOwnProperty('country')) {
            exif.country.value = itemData.imo_country;
            exif.country.options.readonly = true;
        }
        if (!util.isEmpty(itemData.imo_si) && exif.hasOwnProperty('si')) {
            exif.si.value = itemData.imo_si;
            exif.si.options.readonly = true;
        }
        if (!util.isEmpty(itemData.imo_city) && exif.hasOwnProperty('city')) {
            exif.city.value = itemData.imo_city;
            exif.city.options.readonly = true;
        }
        if (!util.isEmpty(itemData.imo_size) && exif.hasOwnProperty('size')) {
            exif.size.value = itemData.imo_size;
            exif.size.options.readonly = true;
        }
        if (!util.isEmpty(itemData.imo_copyright) && exif.hasOwnProperty('copyright')) {
            exif.copyright.value = itemData.imo_copyright;
        }
        resultData.exif = exif;
        return resultData
    }

    _getItemDataFile(itemData, resultData) {
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.Guaranty)) {
            resultData.guaranteeFile = itemData.ItemFile.Guaranty.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(resultData.guaranteeFile)) {
            resultData.isGuarantee = 1;
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.Item)) {
            resultData.thumbnailFile = itemData.ItemFile.Item.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.Original)) {
            resultData.originalFile = itemData.ItemFile.Original.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.Detail)) {
            resultData.detailFile = itemData.ItemFile.Detail.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.NFT)) {
            resultData.ntfFile = itemData.ItemFile.NFT.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.Video)) {
            resultData.videoFile = itemData.ItemFile.Video.filter(file => file.a_idx != null);
        }
        if (!util.isEmpty(itemData.ItemFile) && !util.isEmpty(itemData.ItemFile.VideoStream)) {
            resultData.videoStreamFile = itemData.ItemFile.VideoStream.filter(file => file.a_idx != null);
        }
        return resultData
    }

    _getItemOriginator(itemData, resultData) {
        if (!util.isEmpty(itemData.im_originator)) {
            resultData.originator = 1;
            resultData.originatorId = itemData.im_originator;
            resultData.originatorNick = itemData.originator_nick;
            resultData.originatorProfile = itemData.profile.originator
        } else {
            resultData.originatorId = null;
            resultData.originatorNick = null;
            resultData.originatorProfile = [];
        }
        return resultData
    }

    _getDeleteItem(itemData, resultData) {
        resultData.isCanDelete = false;
        if (itemData.provider === this.UserKey) {
            resultData.isCanDelete = true;
        }
        if (itemData.im_status === 1) {
            resultData.isCanDelete = false;
        }
        return resultData;
    }

    _getItemIsAuctioning(itemData, resultData) {
        resultData.isAuctioning = false;
        if (itemData.ia_auction === 2) {
            resultData.isAuctioning = true;
        }
        return resultData
    }

    _getItemAuctionData(itemData, resultData) {
        if (itemData.im_status === 1 || itemData.im_status === 2) {
            resultData.minimumSetting = itemData.ia_is_minimum;
            resultData.minimum = itemData.ia_minimum;
            resultData.immediatelySetting = itemData.ia_is_immediately;
            resultData.immediately = itemData.ia_immediately;
            resultData.startDate = moment(itemData.ia_start_date).format('YYYY-MM-DD HH:mm');
            let nowDate = moment().format('YYYY-MM-DD HH:mm');
            if (resultData.startDate >= nowDate) {
                resultData.startDateStatus = 1;
            } else {
                resultData.startDateStatus = 0;
            }
            resultData.endDate = moment(itemData.ia_end_date).format('YYYY-MM-DD HH:mm');
        }
        return resultData
    }

    _getItemLicenceData(itemData, resultData) {
        if (itemData.im_status === 3) {
            if (!util.isEmpty(itemData.il_small_price)) {
                resultData.smallType = true;
                resultData.smallPrice = itemData.il_small_price;
            }
            if (!util.isEmpty(itemData.il_medium_price)) {
                resultData.mediumType = true;
                resultData.mediumPrice = itemData.il_medium_price;
            }
            if (!util.isEmpty(itemData.il_large_price)) {
                resultData.largeType = true;
                resultData.largePrice = itemData.il_large_price;
            }
            if (!util.isEmpty(itemData.il_original_price)) {
                resultData.largeType = true;
                resultData.originalPrice = itemData.il_original_price;
            }
            resultData.caution = itemData.il_caution
        }
        return resultData
    }

    _getItemLimitsData(itemData, resultData) {
        if (itemData.im_status === 4 || itemData.im_status === 5) {
            resultData.originalPrice = itemData.i_price;
        }
        return resultData
    }

    _getItemCollectionData(itemData, resultData) {
        if (!util.isEmpty(itemData.Collection)) {
            resultData.collectKey = itemData.Collection.c_idx;
            resultData.collectOwner = itemData.Collection.c_owner;
            resultData.collectTitle = itemData.Collection.c_title;
        }
        return resultData
    }

    _getItemDataEditionView(itemData, resultData) {
        if (itemData.im_edition === 1) {
            resultData.isEdition = itemData.im_edition;
            resultData.editionTotal = itemData.im_total;
            resultData.editionCount = itemData.im_count;
        }
        return resultData
    }

    getItemData(itemData) {
        let resultData = {};
        resultData = this._getItemDataBasic(itemData, resultData)
        resultData = this._getItemDataEdition(itemData, resultData)
        resultData = this._getItemDataHashtag(itemData, resultData)
        resultData = this._getItemDataExif(itemData, resultData)
        resultData = this._getItemDataFile(itemData, resultData);

        resultData = this._getItemIsAuctioning(itemData, resultData);
        resultData = this._getItemAuctionData(itemData, resultData);
        resultData = this._getItemLicenceData(itemData, resultData);
        resultData = this._getItemLimitsData(itemData, resultData);
        return resultData;
    }

    getItemStatusData(itemData) {
        let resultData = {};
        resultData = this._getItemDataBasic(itemData, resultData)
        resultData = this._getItemDataEditionView(itemData, resultData)
        resultData = this._getItemDataHashtag(itemData, resultData)
        resultData = this._getItemDataExif(itemData, resultData)
        resultData = this._getItemDataFile(itemData, resultData);
        resultData = this._getItemOriginator(itemData, resultData);
        resultData = this._getDeleteItem(itemData, resultData);
        resultData = this._getItemIsAuctioning(itemData, resultData);
        resultData = this._getItemAuctionData(itemData, resultData);
        resultData = this._getItemLicenceData(itemData, resultData);
        resultData = this._getItemLimitsData(itemData, resultData);
        resultData = this._getItemCollectionData(itemData, resultData)
        return resultData;

    }

    _chkDeleteItemStatus(res) {
        if (res.List.hasOwnProperty('Data') && !util.isEmpty(res.List.Data) &&
                res.List.Data.hasOwnProperty('Status') && !util.isEmpty(res.List.Data.Status)) {
            return res.List.Data.Status;
        }
        return false
    }

    deleteItem(key) {
        let params = {};
        let itemKey = this.getItemClassConstants().ItemKey;
        params[itemKey] = key;
        return API.$item.deleteItem(params).then(res => res.Data.Result).then(res => {
            let status = this._chkDeleteItemStatus(res);
            if (!status) {
                return false;
            }
            return true;
        })
    }
}

export default {
    ItemClass: ItemClass,
    methods: {}
}
