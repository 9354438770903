<template>
  <div>
    <div class="notice_list_wrap">
      <div class="notice_list">
        <button v-for="(data, index) in boardData" :key="`notice${index}`" @click="moveDetail(`${data.wr_id}`)">
          <div class="notice_content">{{ data.wr_subject }}</div>
          <div class="notice_content font_size">{{ data.created_at }}</div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BoardListDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}},
    paginationData: {default: {page: 1, size: 10, total: 0}},
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    moveDetail(idx){
      this.$router.push(`/notice/${idx}`)
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>