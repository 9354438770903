<template>
  <div>
      CompetitionFilterDefaultLayout
  </div>
</template>

<script>
export default {
    name: "CompetitionFilterDefaultLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            searchOption: 'ina',
            sortOption: 'i.updated_at',
            searchInput: '',
            filterEndCheck: false,

            auctionCheck: false,
            licenseCheck: false,
            sellCheck: false,

            edtitionCheck: false,
            singleCheck: false,

            digitalCheck: false,
            realCheck: false,

            inputPrice1: '',
            inputPrice2: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'asc');
            } else if (data === 'priceDesc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'desc');
            } else {
                this.setFilterFunc('obt', data);
                this.setFilterFunc('obs', 'desc');
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openModal(name) {
            this.$modal.show(name);
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if(this[dataName] ) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if(this[dataName]) {
                this.btnCheck = false;
            }
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
