<template>
    <component :is="loader" v-if="loader" :utilityKey="utilityKey"></component>
  </template>

  <script>
  import {defineAsyncComponent} from "vue";

  export default {
    name: "UtilityEmptyLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
      return {}
    },
    props: {
      emptyLayout: '',
        utilityKey: {default: 0}
    },
    data() {
      return {
        component: null,
      }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
      loader() {
        if (!this.emptyLayout) {
          return defineAsyncComponent(() => import('@/components/utility/layout/utilityList/empty/UtilityEmptyDefaultLayout.vue'));
        }
        return defineAsyncComponent(() => import(`@/components/utility/layout/utilityList/empty/${this.emptyLayout}.vue`))
      },
    },
    methods: {},
    watch: {},
  }
  </script>

  <style scoped>

  </style>
