<template>
  <div>
<!--    <page-header :header_back="true" :header-title="` `"></page-header>-->
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
      </page-header>
    <div class="dgm_wrap padding_side">
      <unsubscribe-result-component></unsubscribe-result-component>
    </div>
  </div>
</template>

<script>

import UnsubscribeResultComponent from "@/components/auth/UnsubscribeResultComponent";

export default {
  name: "UnsubscribeResult",
  mixins: [],
  components: {
    UnsubscribeResultComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
