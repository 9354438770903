<template>
  <div>
    <div class="search_item mb19">
      <div class="filter_category_box mr5">
<!--        <el-select id="category" class="my_filter select"
                   popper-class="select_style" v-model="searchInputOption">
          <el-option value="su" :label="'제목'"></el-option>
        </el-select>-->
<!--        <el-select class="el-select" v-model="searchInputOption">-->
<!--          <el-option value="mid" :label="'아이디'"></el-option>-->
<!--          <el-option value="ni" :label="'닉네임'"></el-option>-->
<!--          <el-option value="na" :label="'이름'"></el-option>-->
<!--        </el-select>-->
      </div>
      <div class="search_input w100p">
        <el-input :placeholder="$t('st')" v-model="searchInput"
          @keypress.enter.native="setFilterFunc(searchInputOption, searchInput)">
        </el-input>
        <button class="search_btn" @click="setFilterFunc(searchInputOption, searchInput)">
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"
import boardUtil from "@/components/board/boardFilterUtil";

const {filterConfig} = boardUtil
export default {
    name: "BoardFilterDefaultLayout",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort', 'setBoardListData'],
    provide() {
        return {}
    },
    props: {
        filterData: {default: () => {}},
        sortData: {default: () => {}},
        filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
    },
    data() {
        return {
            searchOption: '',
            sortOption: 'updated_at',
            searchInput: '',
            searchInputOption: 'su',
            filterClass: new Filter(),
            showFilter: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
            this.filterClass.filter = Object.assign({}, this.filterSet.filter);
        },
        setData(key, value) {
            this[key] = value;
        },
        runRangeFilter() {
            let filterSet = this.filterSet;
            filterSet.filter = this.filterClass.setterFilter('isa', this.searchOption);
            this.setBoardListData('filterSet', filterSet);
            this.setBoardListData('filterClass', this.filterClass);
            EventBus.$emit('boardListChangeSaveFilter')
            // this.showFilter = false;

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
