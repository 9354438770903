<template>
    <div>
        <div class="creator_detail_top_wrap">
            <div class="member_background" :style="`background-image: url(${returnBackground()})`"></div>
            <div class="profile_wrap">
                <div class="profile_img_box">
                    <img :src="returnProfile()">
                </div>

                <div class="profile_right_wrap">

                    <div class="p_r_middle_content">
                        <like-member-component v-if="!isEmptyData(memberData)" class="in_block" :likeType="'memberDetail'" :likeData="memberData"></like-member-component>
<!--                        <div class="heart_box">
                            <img src="@/assets/image/s_heart.png">
                            <span>251</span>
                        </div>-->

                        <div class="item_box">
                            <div class="item_text">{{ $t('en_item') }}</div>
                            <span>{{ numFormat(memberData.itemCnt) }}</span>
                        </div>
                    </div>


                    <div class="share_box" @click="openShareModal">
                        <img src="@/assets/image/share.png">
                    </div>
                </div>
            </div>

            <div class="nickname_wrap">
                <div class="nickname_text">{{ memberData.mb_nick }}</div>
                <div class="card_wrap">
                    <div class="curator_card mt3" v-if="memberData.g_idx === 2">{{ $t('en_curator') }}</div>
                    <div class="special_card mt3" v-if="memberData.mb_premium === 1">{{ $t('en_special') }}</div>
                </div>
            </div>

            <div class="curation_btn_wrap">
                <div class="c_history"  v-if="memberData.g_idx === 2" @click="openModal('curation-history-modal',memberData.mb_no)">{{$t('curation_history')}}</div>
                <div class="c_request" v-if="getMemberType() === 'normal' && memberData.g_idx === 2" @click="movePage(`/curation/${memberData.mb_uuid}/request`)">{{$t('c_request')}}</div>
            </div>

            <div class="url_wrap">
                <a :href="returnUrl(memberData.mb_sns_1)" target="_blank" v-if="!isEmptyData(memberData.mb_sns_1)">{{ returnUrl(memberData.mb_sns_1) }}</a>
                <a :href="returnUrl(memberData.mb_sns_2)" target="_blank" v-if="!isEmptyData(memberData.mb_sns_2)">{{ returnUrl(memberData.mb_sns_2) }}</a>
                <a :href="returnUrl(memberData.mb_sns_3)" target="_blank" v-if="!isEmptyData(memberData.mb_sns_3)">{{ returnUrl(memberData.mb_sns_3) }}</a>
            </div>

            <div class="intro_wrap">
                <input type="checkbox" id="expanded" v-model="onceMore">
                <p v-html="replaceBr(memberData.mb_introduce)"></p>
            </div>

        </div>
        <curation-history-modal></curation-history-modal>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import LikeMemberComponent from "@/components/like/LikeMemberComponent";
import AuthMixins from "@/mixins/AuthMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import GalleryDetailBannerLayout from "@/template/gallery/detail/GalleryDetailBannerLayout";
import ProfileSettingModal from "@/components/modal/ProfileSettingModal";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import MemberDetailDefaultLayout from "@/template/member/detail/MemberDetailDefaultLayout";

export default {
    name: "MemberDetailUserLayout",
    mixins: [imageOption, AuthMixins],
    components: {
      MemberDetailDefaultLayout,
      CurationHistoryModal,
      ProfileSettingModal,
      GalleryDetailBannerLayout,
        LikeMemberComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
            onceMore: false,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.setMoreContent();

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        movePage(url){
            this.$router.push(url);
        },

        openModal(name,key){
            this.$modal.show(name,{memberKey:key});
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        setCuratorBtnText() {
            if(this.memberData.g_idx === 2 ) {
                return this.$t('curation_history')
            }else if(!this.isEmptyData(this.memberData.c_idx)) {
                return this.$t('curator_ready')
            }
            return this.$t('curator_apply')
        },
        returnBackground() {
            if(util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.ProfileBack)) {
                return "";
            }
            return `${this.memberData.Files.ProfileBack[0].org_url}?${this.setImageOptions(710, 440, 'jpg')}`;
        },
        returnProfile() {
            if(util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.Profile)) {
                return "";
            }
            return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(180, 180, 'png')}`;
        },
        returnUrl(url) {
            if(util.isEmpty(url)) {
                return "";
            }
            let expUrl = /^http[s]?\:\/\//i;
            if(!expUrl.test(url)) {
                return `http://${url}`;
            }
            return url;
        },
        setMoreContent() {
            if(util.isEmpty(this.memberData.mb_introduce)) {
                return false;
            }
            const ps = document.querySelectorAll('.creator_detail_intro');
            if(ps.length === 0) {
                setTimeout(() => {
                    this.setMoreContent();
                }, 200)
                return false;
            }
            let $this = this;
            ps.forEach(p => {
                p.style.lineHeight = '22.4px';
            })
            this.onceObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    let divHeight = entry.target.scrollHeight
                    let lineHeight = parseInt(entry.target.style.lineHeight);
                    let lines = Math.floor(divHeight / lineHeight);
                    if(!$this.onceMore && lines > 5){
                        entry.target.classList['add']('off');
                    }
                }
            });

            ps.forEach(p => {
                this.onceObserver.observe(p);
            });
            return true;
        },
        openShareModal() {
            this.$modal.show('share-modal', {memberData: this.memberData})
        }

    },
    watch: {
        'memberData': {
            deep: true,
            immediate: true,
            handler: function (val, oldVal) {
                this.setMoreContent();
            }
        }
    },
}
</script>

<style scoped>

</style>
