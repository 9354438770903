<template>
    <div>
<!--        <page-header :header_back="true" :header-title="`공모전`">
            <button slot="headerBtns" class="h_trash_btn" @click="deleteConfirm()"
                    v-show="(UserInfo.mb_no  === competitionData.mb_no) && (competitionData.c_status === 0)"></button>
        </page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header has_btn two">
            <div class="h_btn_wrap" slot="headerBtns">
                <button class="h_trash_btn" @click="deleteConfirm()"
                        v-show="(UserInfo.mb_no  === competitionData.mb_no) && (competitionData.c_status === 0)"></button>
                <button class="h_setting_btn" @click="moveUpdate('update')"
                        v-show="(UserInfo.mb_no  === competitionData.mb_no) && (competitionData.c_status === 0)"></button>
            </div>
        </page-header>
        <competition-detail-component :competition-type="'competitionDetail'"
                                      :competition-key="competitionKey"></competition-detail-component>
        <judge-input-modal></judge-input-modal>
        <share-modal></share-modal>
        <competition-popup-modal></competition-popup-modal>
    </div>
</template>

<script>
import CompetitionDetailComponent from "@/components/competition/CompetitionDetailComponent";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import JudgeInputModal from "@/components/modal/JudgeInputModal";
import CompetitionPopupModal from "@/components/modal/CompetitionPopupModal";
import ShareModal from "@/components/modal/ShareModal";

export default {
    name: "CompetitionDetail",
    mixins: [],
    components: {
        ShareModal,
        CompetitionPopupModal,
        JudgeInputModal,
        CompetitionDetailComponent,
    },
    inject: [],
    provide() {
        return {
            CompetitionVueSetData: this.setData
        }
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.idx,
            competitionData: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        deleteConfirm() {
            EventBus.$emit('deleteCompetiton');
        },
        setData(key, value) {
            this[key] = value;
        },
        moveUpdate(url) {
            this.$router.push(`${this.$route.path}/${url}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
