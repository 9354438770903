import { render, staticRenderFns } from "./BoardListComponent.vue?vue&type=template&id=15d1f058&scoped=true"
import script from "./BoardListComponent.vue?vue&type=script&lang=js"
export * from "./BoardListComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15d1f058",
  null
  
)

export default component.exports