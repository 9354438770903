import {storeSet, authPageCheck} from "@/mixins/Auth.js"
import Vue from 'vue'
import VueRouter from 'vue-router'

function setViewResolver(component) {
    return require('../views/' + component).default;
}
const layout = {
    render(h) { return h('router-view') }
}

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(() => {
        return window.location.reload()
    })
};

export default [
    {
        path: '/',
        component: setViewResolver('Home'),
        name : 'Home',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta : { name : 'Main', title: "메인", storeRequired:true},
    },
    {
        path: '*',
        redirect: '/',
    },
    {
        path: '/auth',
        component: setViewResolver('auth/Login'),
        name: 'Login',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "Login", title: "login", transitionName: 'none'},
    },
    {
        path: '/snsAuth',
        component: setViewResolver('auth/SnsLogin'),
        name: 'SnsLogin',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "SnsLogin", title: "소셜로그인", transitionName: 'none'},
    },
    {
        path: '/auth/register',
        component: setViewResolver('auth/Register'),
        name: 'Register',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/email',
        component: setViewResolver('auth/RegisterEmail'),
        name: 'Register',
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/sns',
        component: setViewResolver('auth/RegisterSocial'),
        name: 'Register',
        meta: {name: "Register", title: "회원가입", transitionName: 'none'},
    },
    {
        path: '/auth/register/after',
        component: setViewResolver('auth/RegisterAfter'),
        name: 'RegisterAfter',
        meta: {name: "Register", title: "회원가입완료", transitionName: 'none'},
    },
    {
        path: '/auth/naver/callback',
        component: setViewResolver('auth/NaverCallback'),
        name: 'SnsLogin',
        beforeEnter: (to, from, next) => {
            authPageCheck(to, from, next);
        },
        meta: {name: "SnsLogin", title: "소셜로그인", transitionName: 'none'},
    },
    {
        path: '/auth/certification/result',
        component: setViewResolver('auth/AuthenticationResult'),
        name: 'AuthenticationResult',
        meta: {name: "AuthenticationResult", title: "실명인증 결과", transitionName: 'none'},
    },
    {
        path: '/auth/unsubscribe',
        component: setViewResolver('auth/Unsubscribe'),
        name: 'Unsubscribe',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Unsubscribe", title: "회원탈퇴", storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/auth/unsubscribe/result',
        component: setViewResolver('auth/UnsubscribeResult'),
        name: 'UnsubscribeResult',
        meta: {name: "UnsubscribeResult", title: "회원탈퇴 완료", transitionName: 'none'},
    },
    {
        path: '/find/password',
        component: setViewResolver('auth/FindPassword'),
        name: 'FindPassWord',
        meta: {name: "FindPassWord", title: "비밀번호 찾기", transitionName: 'none'},
    },
    {
        path: '/alarm',
        component: setViewResolver('alarm/Alarm'),
        name: 'Alarm',
        meta: {name: "Alarm", title: "alarm", transitionName: 'none'},
    },
    {
        path: '/search',
        component: setViewResolver('search/Result'),
        name: 'Result',
        meta: {name: "Result", title: "search_result", transitionName: 'none'},
    },
    {
        path: '/search/member',
        component: setViewResolver('member/SearchResultCreator'),
        name: 'SearchResultCreator',
        meta: {name: "Result", title: "c_search", transitionName: 'none'},
    },
    {
        path: '/search/item',
        component: setViewResolver('item/SearchResultItem'),
        name: 'SearchResultItem',
        meta: {name: "Result", title: "i_search", transitionName: 'none'},
    }, {
        path: '/search/competition',
        component: setViewResolver('competition/SearchResultCompetition'),
        name: 'SearchResultCompetition',
        meta: {name: "Result", title: "competition_search", transitionName: 'none'},
    },{
        path: '/search/collect',
        component: setViewResolver('collect/SearchResultCollect'),
        name: 'SearchResultCompetition',
        meta: {name: "Result", title: "competition_search", transitionName: 'none'},
    },

    {
        path: '/enroll',
        component: setViewResolver('enroll/Enroll'),
        name: 'Enroll',
        meta: {name: "Enroll", title: "enroll", transitionName: 'none'},
    },

    {
        path: '/itemcreate',
        component: setViewResolver('item/ItemCreate'),
        name: 'ItemCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemCreate", title: "e_item", storeRequired:true, authRequired: true,storeConfig: {userInfo: true},},
    },
    {
        path: '/item/update/:idx',
        component: setViewResolver('item/ItemUpdate'),
        name: 'ItemUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemUpdate", title: "e_item", storeRequired:true, authRequired: true,storeConfig: {userInfo: true},},
    },
    {
        path: '/item/:idx/status',
        component: setViewResolver('item/ItemStatusUpdate'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'ItemStatusUpdate',
        meta: {name: "ItemStatusUpdate", title: "i_status", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true},},
    },


    {
        path: '/openmarket',
        component: setViewResolver('market/OpenMarket'),
        name: 'OpenMarket',
        meta: {name: "OpenMarket", title: "오픈마켓", transitionName: 'none'},
    },
    {
        path: '/openmarket/:idx',
        component: setViewResolver("market/OpenMarketDetail"),
        name: "OpenMarketDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "OpenMarketDetail", title: "item", transitionName: 'none'},
    },
    {
        path: '/openmarket/:idx/history',
        component: setViewResolver("history/HistoryDetail"),
        name: "openHistoryDetail",
        meta: {name: "openHistoryDetail", title: "history_title", transitionName: 'none'},
    },
    {
        path: '/openmarket/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        name: 'openItemUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "openItemUpdate", title: "i_status", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
    },
    {
        path: '/openmarket/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'openBid',
        meta: {name: "openBid", title: "bid", transitionName: 'none'},
    },
    {
        path: '/premiummarket/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'premiumBid',
        meta: {name: "itemBid", title: "bid", transitionName: 'none' },
    },
    {
        path: '/item/:idx/bid',
        component: setViewResolver('market/Bid'),
        name: 'itemBid',
        meta: {name: "premiumBid", title: "bid", transitionName: 'none'},
    },
    {
        path: '/openmarket/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'marketPrice',
        meta: {name: "marketPrice", title: "sale_limit", transitionName: 'none' },
          },
    {
        path: '/premiummarket/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'premiumPrice',
        meta: {name: "marketPrice", title: "sale_limit", transitionName: 'none' },
    },
    {
        path: '/item/:idx/price',
        component: setViewResolver('market/Price'),
        name: 'itemPrice',
        meta: {name: "marketPrice", title: "sale_limit", transitionName: 'none' },
    },
    {
        path: '/openmarket/:idx/license/:type',
        component: setViewResolver('market/License'),
        name: 'openLicense',
        meta: {name: "openLicense", title: "licenseBuy", transitionName: 'none'},
    },
    {
        path: '/item/:idx/license/:type',
        component: setViewResolver('market/License'),
        name: 'itemLicense',
        meta: {name: "openLicense", title: "licenseBuy", transitionName: 'none'},
    },
    /*{
        path: '/premiummarket/upload',
        component: setViewResolver('market/PremiumMarketUpload'),
        name: 'PremiumMarketUpload',
        meta: {name: "PremiumMarketUpload", title: "내 아이템 프리미엄 마켓 등록", },
    },*/

    {
        path: '/item/:idx',
        component: setViewResolver("item/ItemDetail"),
        name: "ItemDetail",
        meta: {name: "ItemDetail", title: "item", transitionName: 'none'},
    },

    {
        path: '/premiummarket',
        component: setViewResolver('market/PremiumMarket'),
        name: 'PremiumMarket',
        meta: {name: "PremiumMarket", title: "프리미엄마켓", transitionName: 'none'},
    },
    {
        path: '/premiummarket/:idx',
        component: setViewResolver("market/PremiumMarketDetail"),
        name: "PremiumMarketDetail",
        meta: {name: "PremiumMarketDetail", title: "item", transitionName: 'none'},
    },
    {
        path: '/premiummarket/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        name: 'premiumItemStatusUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "premiumItemStatusUpdate", title: "i_status", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
    },
    {
        path: '/creator',
        component: setViewResolver('member/Creator'),
        name: 'Creator',
        meta: {name: "Creator", title: "creator"},
    },
    {
        path: '/competitioncreate',
        component: setViewResolver('competition/CompetitionCreate'),
        name: 'CompetitionCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionCreate", title: "e_comp",  authRequired: true, storeRequired: true, storeConfig: {userInfo: true},transitionName: 'none'},
    },
    {
        path: '/competition',
        component: setViewResolver('competition/Competition'),
        name: 'Competition',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Competition", title: "competition",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/competition/:idx',
        component: setViewResolver("competition/CompetitionDetail"),
        name: "CompetitionDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionDetail", title: "competition",storeRequired:true,}
    },
    {
        path: '/competition/:idx/update',
        component: setViewResolver("competition/CompetitionUpdate"),
        name: "CompetitionUpdate",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CompetitionUpdate", title: "e_update",storeRequired:true,authRequired: true}
    },
    {
        path: '/portfolio',
        component: setViewResolver('portfolio/Portfolio'),
        name: 'portfolio',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationApply", title: "portfolio", storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/portfolio/edit',
        component: setViewResolver('portfolio/PortfolioEdit'),
        name: 'PortfolioEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PortfolioEdit", title: "portfolio",authRequired: true, storeRequired: true, storeConfig: {userInfo: true}},

    },
    {
        path: '/creator/:idx',
        component: setViewResolver('member/CreatorDetail'),
        name: 'CreatorDetail',
        meta: {name: 'CreatorDetail', title: '프로필 상세'},
        children: [
            {
                path: '',
                component: setViewResolver("member/CreatorDetailCollection"),
                name: "CreatorDetailCollection",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'premium',
                component: setViewResolver("member/CreatorDetailPremium"),
                name: "CreatorDetailPremium",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'market',
                component: setViewResolver("member/CreatorDetailMarket"),
                name: "CreatorDetailMarket",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'collect',
                component: setViewResolver("member/CreatorDetailCollect"),
                name: "CreatorDetailCollect",
                meta: {name: "CreatorDetailCollect", title: "유저 프로필",storeRequired: true,}
            },
            {
                path: 'competition',
                component: setViewResolver("member/CreatorDetailCompetition"),
                name: "CreatorDetailCompetition",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'creation',
                component: setViewResolver("member/CreatorDetailCreation"),
                name: "CreatorDetailCreation",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
            {
                path: 'portfolio',
                component: setViewResolver("member/CreatorDetailPortfolio"),
                name: "CreatorDetailPortfolio",
                meta: {name: "CreatorDetail", title: "유저 프로필",storeRequired:true,}
            },
        ],
    },
    {
        path: '/gallery',
        component: setViewResolver('gallery/Gallery'),
        name: 'Gallery',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Gallery", title: "gallery" ,storeRequired:true, transitionName: 'none', storeConfig: {userInfo: true}},

    },
    {
        path: '/gallery/:idx/edit',
        component: setViewResolver('gallery/GalleryEdit'),
        name: 'GalleryEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "GalleryEdit", title: "gallery_setting",storeRequired:true, transitionName: 'none'},

    },
    {
        path: '/gallery/:idx',
        component: setViewResolver("gallery/GalleryDetail"),
        name: "GalleryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "GalleryDetail", title: "gallery",storeRequired:true,},
    },
    {
        path: '/gallery/:idx/itemadd',
        component: setViewResolver('item/ItemAdd'),
        name: 'ItemAdd',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemAdd", title: "add_item",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/collect',
        component: setViewResolver('collect/Collect'),
        name: 'Collect',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Collect", title: "컬렉션",storeRequired:true, transitionName: 'none', storeConfig: {userInfo: true}},

    },
    {
        path: '/collect/add',
        component: setViewResolver('collect/CollectEdit'),
        name: 'CollectEdit',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CollectEdit", title: "컬렉션 편집", authRequired: true, storeRequired: true, storeConfig: {userInfo: true} , transitionName: 'none'},

    },
    {
        path: '/collect/:idx',
        component: setViewResolver("collect/CollectDetail"),
        name: "CollectDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CollectDetail", title: "컬렉션 상세",storeRequired:true,},
    },
    {
        path: '/curation/:mb_no/request',
        component: setViewResolver('curation/CurationRequest'),
        name: 'CurationRequest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationRequest", title: "c_request", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}},

    },
    {
        path: '/curation/:mb_no/request/:i_idx',
        component: setViewResolver("curation/CurationRequestDetail"),
        name: "CurationRequestDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationRequestDetail", title: "c_request",storeRequired:true,}

    },
    {
        path: '/curationapply',
        component: setViewResolver('curation/CurationApply'),
        name: 'CurationApply',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "CurationApply", title: "curator_apply",storeRequired:true,authRequired: true, transitionName: 'none'},
    },

    {
        path: '/myprofile',
        component: setViewResolver('mypage/MyProfile'),
        name: 'MyProfile',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyProfile", title: "my_profile", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
        children: [
            {
                path: '',
                component: setViewResolver("mypage/MyProfileCollection"),
                name: "MyProfileCollection",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'premium',
                component: setViewResolver("mypage/MyProfilePremium"),
                name: "MyProfilePremium",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'market',
                component: setViewResolver("mypage/MyProfileMarket"),
                name: "MyProfileMarket",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'collect',
                component: setViewResolver("mypage/MyProfileCollect"),
                name: "MyProfileCollect",
                meta: {name: "MyProfileCollect", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'competition',
                component: setViewResolver("mypage/MyProfileCompetition"),
                name: "MyProfileCompetition",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'creation',
                component: setViewResolver("mypage/MyProfileCreationItem"),
                name: "MyProfileCreation",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
            {
                path: 'portfolio',
                component: setViewResolver("mypage/MyProfilePortfolio"),
                name: "MyProfilePortfolio",
                meta: {name: "MyProfile", title: "my_profile",authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
            },
        ],
    },

    {
        path: '/mypage',
        component: setViewResolver('mypage/MyPage'),
        name: 'MyPage',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPage", title: "mypage", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/item',
        component: setViewResolver('mypage/MyPageItem'),
        name: 'MyPageItem',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageItem", title: "item", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/item/:idx/history',
        component: setViewResolver('history/ItemHistory'),
        name: 'ItemHistory',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemHistory", title: "history_title", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/update',
        component: setViewResolver('mypage/MyPageUpdate'),
        name: 'MyPageUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageUpdate", title: "infoModify", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/deposit',
        component: setViewResolver('mypage/MyPageDeposit'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPagePoint',
        meta: {name: "MyPagePoint", title: "마이페이지 입금하기", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point',
        component: setViewResolver('mypage/MyPagePoint'),
        name: 'MyPagePoint',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPagePoint", title: "my_mingle", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point/history',
        component: setViewResolver('mypage/MyPagePointHistory'),
        name: 'MyPagePointHistory',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPagePointHistory", title: "my_mingle", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point/charge',
        component: setViewResolver('mypage/PointCharge'),
        name: 'PointCharge',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PointCharge", title: "charge", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/point/withdraw',
        component: setViewResolver('mypage/PointWithdraw'),
        name: 'PointWithdraw',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "PointWithdraw", title: "withdraw", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/auction',
        component: setViewResolver('mypage/MyPageAuction'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageAuction',
        meta: {name: "MyPageAuction", title: "auction", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/curation',
        component: setViewResolver('mypage/MyPageCuration'),
        name: 'MyPageCuration',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageCuration", title: "curation", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/real',
        component: setViewResolver('mypage/MyPageReal'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageReal',
        meta: {name: "MyPageReal", title: "real", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/competition',
        component: setViewResolver('mypage/MyPageCompetition'),
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        name: 'MyPageCompetition',
        meta: {name: "MyPageCompetition", title: "competition", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/interest',
        component: setViewResolver('mypage/MyPageInterest'),
        name: 'MyPageInterest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageInterest", title: "interest", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/mypage/detection',
        component: setViewResolver('mypage/MyPageDetection'),
        name: 'MyPageDetection',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "MyPageDetection", title: "detection", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},

    },
    {
        path: '/mypage/detection/:idx',
        component: setViewResolver('detection/DetectionDetail'),
        name: 'DetectionDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionDetail", title: "Item_search", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },

    {
        path: '/detection/:d_idx/history',
        component: setViewResolver('detection/DetectionHistory'),
        name: 'DetectionHistory',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionHistory", title: "Item_search", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/detection/:d_idx/history/:dr_idx',
        component: setViewResolver('detection/DetectionHistoryDetail'),
        name: 'DetectionHistoryDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionHistoryDetail", title: "detection_img", authRequired: true, storeRequired: true, storeConfig: {userInfo: true}, transitionName: 'none'},
    },
    {
        path: '/detection/:idx/request',
        component: setViewResolver('detection/DetectionRequest'),
        name: 'DetectionRequest',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionRequest", title: "request1", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/detection/request/:idx',
        component: setViewResolver('detection/DetectionRequestDetail'),
        name: 'DetectionRequestDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionRequestDetail", title: "request_detail", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/detection/:d_idx/url',
        component: setViewResolver('detection/DetectionUrl'),
        name: 'DetectionUrl',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "DetectionUrl", title: "ignore_url1", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/faq',
        component: setViewResolver('faq/Faq'),
        name: 'Faq',
        meta: {name: "Faq", title: "FAQ", transitionName: 'none'},
    },
    {
        path: '/faq/:idx',
        component: setViewResolver("faq/FaqDetail"),
        name: "FaqDetail",
        meta: {name: "FaqDetail", title: "FAQ", transitionName: 'none'}
    },
    {
        path: '/notice',
        component: setViewResolver('notice/Notice'),
        name: 'Notice',
        meta: {name: "Notice", title: "notice", transitionName: 'none'},
    },
    {
        path: '/notice/:idx',
        component: setViewResolver("notice/NoticeDetail"),
        name: "NoticeDetail",
        meta: {name: "NoticeDetail", title: "notice", transitionName: 'none'}
    },
    {
        path: '/qna',
        component: setViewResolver('qna/Question'),
        name: 'Question',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "Question", title: "qna", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},

    },
    {
        path: '/qna/create',
        component: setViewResolver('qna/QnaCreate'),
        name: 'QnaCreate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaCreate", title: "write_qna", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},},
    },
    {
        path: '/qna/:idx',
        component: setViewResolver("qna/QnaDetail"),
        name: "QnaDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaDetail", title: "detail_qna", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
    },
    {
        path: '/qna/:idx/update',
        component: setViewResolver("qna/QnaUpdate"),
        name: "QnaDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "QnaDetail", title: "write_qna", transitionName: 'none', authRequired: true, storeRequired: true, storeConfig: {userInfo: true},}
    },
    {
        path: '/item/:idx',
        component: setViewResolver("item/ItemDetail"),
        name: "ItemDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemDetail", title: "아이템 상세", transitionName: 'none', storeRequired: true, },
    },
    {
        path: '/item/:idx/update',
        component: setViewResolver('item/ItemStatusUpdate'),
        name: 'ItemStatusUpdate',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "ItemStatusUpdate", title: "아이템 상태변경", transitionName: 'none',authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true},},
    },
    {
        path: '/item/:idx/history',
        component: setViewResolver("history/HistoryDetail"),
        name: "ItemHistoryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "openHistoryDetail", title: "거래 내역",storeRequired:true, transitionName: 'none'},
    },

    {
        path: '/entry/:cid/list/:mb_no',
        component: setViewResolver('entry/EntryList'),
        name: 'EntryList',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryList", title: "s_apply",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/entry/:cid/write',
        component: setViewResolver('entry/EntryWrite'),
        name: 'EntryWrite',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryWrite", title: "apply",authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,}, transitionName: 'none'},
    },
    {
        path: '/entry/:cid/list/:mb_no/:idx',
        component: setViewResolver("entry/EntryDetail"),
        name: "EntryDetail",
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "EntryDetail", title: "s_apply",storeRequired:true, transitionName: 'none'}
    },
    {
        path: '/award/:c_idx/write',
        component: setViewResolver('award/AwardWrite'),
        name: 'AwardWrite',
        meta: {name: "AwardWrite", title: "s_award", authRequired: true, storeRequired: true, storeConfig: {userInfo: true, nftUser: true,},},
    },
    {
        path: '/award/:c_idx/list',
        component: setViewResolver('award/AwardList'),
        name: 'AwardList',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "AwardList", title: "award",storeRequired:true, transitionName: 'none'},
    },
    {
        path: '/utility/:idx',
        component: setViewResolver('utility/UtilityDetail'),
        name: 'UtilityDetail',
        beforeEnter: (to, from, next) => {
            storeSet(to, from, next);
        },
        meta: {name: "UtilityDetail", title: "유틸리티",storeRequired:true, transitionName: 'none', noFooter:true },
    },
]
