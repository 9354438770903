<template>
    <div class="">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
<!--      <page-header :header_white_main_back="true" :header-white-title="true" class="mypage_header"></page-header>-->
      <div class="mypage">
            <div class="mypage_profile pt30">
                <div class="mypage_profile_list">
                    <img class="profile mr15" :src="returnThumbnail()">
                    <div class="nickname">
                        <div>
                            <div class="mypage_name font_white">{{ UserInfo.mb_nick }}</div>
                            <button class="mypage_button" @click="movePage('/mypage/update')">
                                <img class="modify" src="@/assets/image/mypage_modify.png">
                            </button>
                        </div>
                        <div class="mypage_mingle" @click="movePage('/mypage/point')">
                            <img src="@/assets/image/mypage_mingle.png">
                            <div class="mingle_mg">{{ returnCommas(UserInfo.point) }}</div>
                            <div class="mg">{{ $t('en_mg') }}</div>
                        </div>
                    </div>
                </div>
                <div class="myprofile_look mt20" @click="movePage('/myprofile')">
                    <button>
                        <span class="font_white">{{ $t('show_mypage') }}</span>
                    </button>
                </div>
            </div>


            <div class="mypage_content_lists pt18 ">
                <div class="content_list" @click="movePage('/mypage/item')">
                    <div class="content_img my_item_img"></div>
                    <div class="content_text icon_new">
                        {{ $t('item') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/auction')">
                    <div class="content_img my_auction_img"></div>
                    <div class="content_text">
                        {{ $t('auction') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/curation')">
                    <div class="content_img my_curation_img"></div>
                    <div class="content_text">
                        {{ $t('curation') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/real')">
                    <div class="content_img my_real_img"></div>
                    <div class="content_text">
                        {{ $t('real') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/competition')">
                    <div class="content_img my_competition_img"></div>
                    <div class="content_text">
                        {{ $t('competition') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/interest')">
                    <div class="content_img my_attention_img"></div>
                    <div class="content_text">
                        {{ $t('interest') }}
                    </div>
                </div>
                <div class="content_list" @click="movePage('/mypage/detection')">
                    <div class="content_img my_detection_img"></div>
                    <div class="content_text">
                        {{ $t('detection') }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import AuthMixins from "@/mixins/AuthMixins";
import {mapState, mapGetters} from "vuex";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";

export default {
    name: "MyPageHeaderComponent",
    mixins: [imageOption, AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            newType: true,
            activeCheck: 'item',
            tagCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            console.log(url)
            this.$router.push(url);
        },
        checkActiveButton(path) {
            if (path === '/mypage/item') {
                this.activeCheck = 'item';
            } else if (path === '/mypage/auction') {
                this.activeCheck = 'auction';
            } else if (path === '/mypage/curation') {
                this.activeCheck = 'curation';
            } else if (path === '/mypage/real') {
                this.activeCheck = 'real';
            } else if (path === '/mypage/competition') {
                this.activeCheck = 'competition';
            } else if (path === '/mypage/interest') {
                this.activeCheck = 'interest';
            } else if (path === '/mypage/detection') {
                this.activeCheck = 'detection';
            } else {
                this.activeCheck = '';
            }
        },
        returnThumbnail() {
            if (!util.isEmpty(this.UserInfo.Files.Profile) && !util.isEmpty(this.UserInfo.Files.Profile[0])) {
                return `${this.UserInfo.Files.Profile[0].org_url}?${this.setImageOptions(97, 98.5, 'png')}`;

            }
        },
        returnCommas(x = 0) {
            return util.Number.numFormat(x);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

