import EventBus from "@/utils/event-bus";

const alarmConfig = {
    alarm: {
        listLayout: 'AlarmListDefaultLayout',
        emptyLayout: 'AlarmEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getAlarm',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 20,
            ty: 'mint'
        },
        deleteFunc: 'deleteAlarm',
        deleteApiParamsSet: {
            type:'single'
        },
        deleteKeyName: 'ah_idx',
        deleteSuccessType: 1,
        deleteErrorTypes: {
          //  '-202': "delete_error_allow"
        },
    },
}
export default {
    methods: {
        returnAlarmConfig(type) {
            return alarmConfig[type];
        },
    }
}
