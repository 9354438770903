<template>
    <div class="om_list_wrap">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header" :header-title="`<span class='font-Lobster'>Premium</span>`"></page-header>
        <!--            <button  v-for="data in itemCategoryList"-->
        <!--                    :key="`category${data.node_id}`"-->
        <!--                    @click="changeTab(`${data.node_id}`)">{{ returnCategoryName(data) }}-->
        <!--            </button>-->
        <primium-market-banner-component></primium-market-banner-component>
        <div class="notice_tabs padding_side mt25">

            <!--                <button class="notice_item_3_tab" :class="{ active : tab === `${data.node_id}` , }"-->
            <!--                        v-for="data in itemCategoryList"-->
            <!--                        :key="`category${data.node_id}`"-->
            <!--                        @click="changeTab(`${data.node_id}`)">{{ returnCategoryName(data) }}-->
            <!--                </button>-->
            <button class="notice_item_3_tab border_r_l " :class="{ active : tab === '0'}" @click="changeTab('0')">
                {{ $t('all') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '2'}" @click="changeTab(`${2}`)">
                {{ $t('art') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '9'}" @click="changeTab(`${9}`)">
                {{ $t('collections') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '3'}" @click="changeTab(`${3}`)">
                {{ $t('picture') }}
            </button>
            <button class="notice_item_3_tab" :class="{ active : tab === '5'}" @click="changeTab(`${5}`)">
                {{ $t('music') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '4'}" @click="changeTab(`${4}`)">
                {{ $t('video') }}
            </button>
        </div>
        <div v-if="tab === `0`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `2`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `3`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `4`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `5`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === `9`">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'premiumMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'premiumMarket'" :category-key="tab"></item-list-component>
        </div>
        <popup-modal></popup-modal>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import ItemListMarketTopComponent from "@/components/item/itemListMarketTopComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import PopupModal from "@/components/modal/PopupModal";
import PrimiumMarketBannerComponent from "@/components/banner/PrimiumMarketBannerComponent";

export default {
    name: "PremiumMarket",
    mixins: [],
    components: {
        PrimiumMarketBannerComponent,
        PopupModal,
        ItemListMarketTopComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('0');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnCategoryName(data) {

            if (data.node_id === 2) {
                return this.$t('art');
            } else if (data.node_id === 3) {
                return this.$t('picture');
            } else if (data.node_id === 4) {
                return this.$t('video');
            } else if (data.node_id === 5) {
                return this.$t('music');
            } else if (data.node_id === 9) {
                return this.$t('collections');
            } else if (data.node_id === 178) {
                return this.$t('prompt')
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
