<template>
  <div>
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <div slot="headerBtns">
              <button class="h_search_btn" @click="$router.push('/search')"></button>
          </div>
      </page-header>
      <page-header class="sub_header"></page-header>
    <div class="padding_side dgm_wrap mb130">
<!--추후삭제-->
<!--      <QnaDetailLayout></QnaDetailLayout>-->
<!--      <QnaDetailAnswerLayout></QnaDetailAnswerLayout>-->
      <board-detail-component :boardType="'qnaDetail'" :boardKey="boardKey"></board-detail-component>
    </div>
  </div>

</template>

<script>
import BoardDetailComponent from "@/components/board/BoardDetailComponent";
export default {
  name: "QnaDetail",
  mixins: [],
  components: {
    BoardDetailComponent,
  },
  inject: [],
  provide() {
    return {
      'setQnaData': this.setQnaData,
    }
  },
  props: {
  },
  data() {
    return {
      boardKey: '',
      qnaData: '',
    }
  },
  beforeRouterEnter() {
  },
  created() {
    this.boardKey = this.$route.params.idx;
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    setQnaData(value) {
      this.qnaData = value;
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
