<template>
    <div>
        <board-filter-link :filterLayout="filterLayout" :sortData="filterSet.sort"  :filterData="filterSet.filter" :paginationData="filterSet.page" :filterSet="filterSet"></board-filter-link>
        <board-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" :isGetFirst="isGetFirst" v-if="List.length ===0"></board-empty-link>
        <board-list-link :listLayout="listLayout" :total="filterSet.page.total" :boardType="boardType" :boardData="List" :paginationData="filterSet.page" v-else></board-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"  ></pagination-link>
    </div>
</template>

<script>
import BoardListLink from "@/components/board/BoardListLink";
import BoardFilterLink from "@/components/board/BoardFilterLink";
import PaginationLink from "@/components/board/PaginationLink";
import BoardEmptyLink from "@/components/board/BoardEmptyLink";
import boardFilterUtil from "@/components/board/boardFilterUtil";
import boardTemplateUtil from "@/components/board/boardTemplateUtil";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";

const {filterConfig} = boardFilterUtil;
export default {
    name: "BoardListComponent",
    mixins: [boardFilterUtil, boardTemplateUtil, alertMixins, filterMixins, AuthMixins, listMixins],
    components: {
        BoardEmptyLink,
        PaginationLink,
        BoardFilterLink,
        BoardListLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setBoardListData: this.setData,
        }
    },
    props: {
        boardType: '',
        boardKey: '',
    },
    data() {
        return {
            boardId: '',
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            paginationType: '',
            getFunc: '',
            listApiParamSet: {},
            lang: '',
            isLanguage: true,
            cid: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig);
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        if(this.isGetFirst) {
            this.getList(true);
            if(this.isLanguage && this.boardType !== 'qna') {
                this.cid = this.langCheckLine(this.Country);
                EventBus.$emit('langIdx', this.cid);
            }
        }else{
            this.finishGetList = false;
        }
        EventBus.$on('getBoardList',this.getList);
        EventBus.$on('boardListChangeSaveFilter', () => {this.changeSaveFilter(this.parserType)});
    },
    beforeDestroy() {
        EventBus.$off('getBoardList');
        EventBus.$off('boardListChangeSaveFilter');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
            Country: state => state.country.Country,
        }),
    },
    methods: {
        initConfig() {
            let boardTemplateConfig = this.returnBoardTemplateConfig(this.boardType);
            let boardConfig = this.returnBoardFilterConfig(this.boardType)
            this.listLayout = boardTemplateConfig.listLayout;
            this.filterLayout = boardTemplateConfig.filterLayout;
            this.emptyLayout = boardTemplateConfig.emptyLayout;
            this.paginationLayout = boardTemplateConfig.paginationLayout;
            this.getFunc = boardConfig.getFunc;
            this.listApiParamSet = boardConfig.listApiParamSet;
            this.isLanguage = util.isEmpty(boardConfig.isLanguage) ? true : boardConfig.isLanguage;
            if (boardConfig.pageRow && boardConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = boardConfig.pageRow;
                this.filterSet.page.size = boardConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if (Number(this.categoryKey) !== 0) {
                params.ic = [this.categoryKey];
            }
            if (this.boardKey !== '' || !util.isEmpty(this.boardKey)) {
                params[this.keyName] = this.boardKey
            }
            this.boardId = this.UserInfo.mb_nick;
            if (this.boardType === 'qna' && this.UserInfo.mb_no !== null) {
                params.mbno = this.UserInfo.mb_no;
            }

            if (this.boardType !== 'qna' ) {
                params.cid = this.langCheckLine(this.Country);
            }

            let listOptions = {
                url: this.$api.$board[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = boardConfig.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },
        setData(key, value) {
            this[key] = value;
        },
    },
    watch: {
        'Country': {
            deep: true,
            handler: function (val, oldVal) {
                this.cid = this.langCheckLine(this.Country);
                this.$localStorage.set('lang', this.Country);
                this.initConfig();
                this.getList(true);
            }
        },
    },
}
</script>

<style scoped>

</style>
