<template>
  <div>
<!--    <div class="faq_list_wrap">
      <div class="faq_list">
        <button v-for="(data, index) in boardData" :key="`faq${index}`"
                @click="moveDetail(`${data.wr_id}`)"
                class="faq_button_wrap"
        >
          <div style="width: 100%;">
            <div class="faq_number">{{index+1}}</div>
            <div class="faq_category">{{ data.Category.bc_name }}</div>
            <div class="faq_title">{{ data.wr_subject }}</div>
          </div>
        </button>
      </div>
    </div>-->
    <div class="board_faq_list border-top ">
      <div class="board_faq_item one_line" v-for="(data, index) in boardData" :key="`faq${index}`" @click="moveDetail(`${data.wr_id}`)">
        <div class="faq_category">[{{ data.Category.bc_name }}]</div>
<!--        <div class="faq_title">{{ data.wr_subject }}</div>-->
          {{ data.wr_subject }}
      </div>
    </div>
  </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
  name: "BoardListFaqLayout",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    boardData: {default: () => {}},
  },
  data() {
    return{
      idx: 1,
    }
  },
  beforeRouterEnter() {},
  created() {

  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
      moveDetail(data){
          this.$router.push(`/faq/${data}`)
      },
      tableIdx(index) {
          return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
      },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
