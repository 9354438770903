import { render, staticRenderFns } from "./GalleryWriteComponent.vue?vue&type=template&id=70e8411e&scoped=true"
import script from "./GalleryWriteComponent.vue?vue&type=script&lang=js"
export * from "./GalleryWriteComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70e8411e",
  null
  
)

export default component.exports