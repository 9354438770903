<template>
    <modal name="header-menu-modal"
           class="header-menu-modal"
           :pivotX="1"
           :adaptive="true"
           :scrollable="true"
           :width="'73.52%'"
           :height="'100%'"
           :transition="`modal-right`"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>

            <div class="header-menu-modal_wrap">
                <div class="header-menu-modal">

                    <!-- 사이드 바 상단 -->
                    <div class="menu_top">
                        <div class="menu_top_header">
                            <el-select class="mt_lang_select" id="flag" v-model="selectCountry"
                                       @change="changeCountry(selectCountry)"
                                       popper-class="select_country" :popper-append-to-body="false">
                                <el-option :value="'ko'" :label="$t('Korean_kr')"></el-option>
                                <el-option :value="'en'" :label="$t('English_us')"></el-option>
                            </el-select>
<!--                            <div class="alarm_btn" @click="movePage('/alarm')">
                                <el-badge :value="'N'" class="alarm_btn_badge" v-if="UserInfo.IsAlarm"></el-badge>
                            </div>-->
                            <div class="close_btn" @click="closeModal()"></div>
                            <div class="clear"></div>
                        </div>
                        <div class="menu_top_header no_login pt10" v-if="!isLogin()">
                            <div class="login_btn" @click="movePage('/auth')">{{$t('login')}}</div>
                            <div class="register_btn" @click="movePage('/auth/register')">{{$t('register')}}</div>
<!--                            <div class="close_btn" @click="closeModal()"></div>-->
                        </div>
                        <div class="menu_top_profile" v-if="UserInfo.mb_no">
                            <div class="profile" @click="movePage('/myprofile')">
                                <div class="img_wrap">
                                    <img :src="returnThumbnail()" alt="">
<!--                                    <div class="img_setting"></div>-->
                                </div>
                            </div>
                            <div class="mypage_wrap" @click="movePage('/mypage')">
                                <div class="mypage_btn">
                                    <span>My page</span>
                                </div>
                            </div>
                            <div class="mg_amount_wrap"  @click="movePage('/mypage')">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(UserInfo.point) }}</div>
                                <div class="mg">Mg</div>
                            </div>
                        </div>
                    </div>

                    <!-- 네비게이션 부분 -->
                    <div class="menu_nav_wrap">
                        <div class="menu_nav">
                            <ul>
<!--                                <li @click="movePage('/premiummarket')">-->
<!--                                    <div class="m_nav_list">Premium</div>-->
<!--                                    <div class="arrow-right"></div>-->
<!--                                    <div class="clear"></div>-->
<!--                                </li>-->
                                <li @click="movePage('/openmarket?tab=178')">
                                    <div class="m_nav_list mt0">{{ $t('prompt') }}</div>
                                    <div class="arrow-right"></div>
                                    <div class="clear"></div>
                                </li>
                                <li @click="movePage('/openmarket')">
                                    <div class="m_nav_list">{{ $t('openMarket') }}</div>
                                    <div class="arrow-right"></div>
                                    <div class="clear"></div>
                                </li>
                                <li @click="movePage('/competition')">
                                    <div class="m_nav_list">{{ $t('competition') }}</div>
                                    <div class="arrow-right"></div>
                                    <div class="clear"></div>
                                </li>
                                <li @click="movePage('/creator')">
                                    <div class="m_nav_list">{{ $t('creator') }}</div>
                                    <div class="arrow-right"></div>
                                    <div class="clear"></div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <!-- 공지사항 부분 -->
                    <div class="menu_info_wrap">
                        <div class="menu_info">
                            <ul>
                                <li @click="movePage('/notice')">
                                    <div class="m_info_list">{{ $t('notice') }}</div>
                                </li>
                                <li @click="movePage('/faq')">
                                    <div class="m_info_list">FAQ</div>
                                </li>
                                <li @click="movePage('/qna')">
                                    <div class="m_info_list">{{ $t('qna') }}</div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="logout" v-if="UserInfo.mb_no" @click="logout()">
                        <div class="logout_btn">{{ $t('logout') }}</div>
                    </div>
                    <div class="enroll_wrap pa0" v-if="UserInfo.mb_no">
                        <div class="one_btn" v-if="UserInfo.g_idx===1" @click="movePage('/enroll')">{{ $t('enroll') }}</div>
                        <div class="one_btn" @click="autoMintingLogin" v-if="checkLaunch()">{{ $t('enroll') }}</div>
                    </div>
                </div>
            </div>
        </div>
    </modal>

</template>

<script>
import {logout} from "@/mixins/Auth";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import AuthMixins from "@/mixins/AuthMixins";
import priceMixins from "@/mixins/priceMixins";
import {isDev} from "@/api/config";

export default {
    name: "HeaderMenuModal",
    mixins: [imageOption, imageResizeMixins, priceMixins, AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            selectCountry: 'ko',
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        closeModal() {
            this.$modal.hide('header-menu-modal');
        },
        initData() {
            let lang = this.$localStorage.get('lang');
            this.selectCountry = lang;
            if (util.isEmpty(lang)) {
                this.selectCountry = this.Country;
            }
        },
        handlerBeforeOpen(event) {
            this.initData();
            // this.scroll = event.params.scroll
            // document.body.style.top = `-${this.scroll}px`
        },

        handlerOpened() {
        },

        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },
        movePage(url) {
            if (this.$route.path.includes('/openmarket') && url.includes('/openmarket')) {
                this.$router.replace(url);
                this.$router.go();
            } else {
                this.$router.push(url);
            }
        },
        logout() {
            logout();
        },
        moveMyPage() {
            if (this.getMemberType() === 'curator') {
                this.movePage('/mypage/curation')
            } else {
                this.movePage('/mypage/item')
            }
        },
        returnThumbnail() {
            if (!util.isEmpty(this.UserInfo.Files) && !util.isEmpty(this.UserInfo.Files.Profile)) {
                return `${this.UserInfo.Files.Profile[0].org_url}?${this.setImageOptions(114, 114, this.returnExtension(this.UserInfo.Files.Profile[0].org_url))}`;
            }
        },
        changeCountry(country) {
            this.$store.dispatch('country/changeCountry', {data: country});
            this.changeLang(country);
        },
        changeLang(lang) {
            this.$localStorage.set('lang', lang);
            this.$i18n.locale = lang;
            this.$forceUpdate();
        },
      autoMintingLogin() {
        let blockchain_weptoken = this.$localStorage.get('token');
        let url = 'http://115.68.199.206/automintLogin.do';
        if (isDev === true) {
          url = 'http://115.68.199.206/login.do'
        }
        let loginForm = $('<form></form>');
        loginForm.attr("name", "loginForm");
        loginForm.attr("method", "post");
        loginForm.attr("action", url);
        loginForm.attr("target", "_blank");

        loginForm.append($('<input/>', {
          type: 'hidden',
          name: 'com.dgmonglab.blockchain_weptoken',
          value: blockchain_weptoken
        }));

        loginForm.appendTo('body');
        loginForm.submit();
      },
      checkLaunch() {
        return !util.isEmpty(this.UserInfo) && this.UserInfo.mb_premium === 2;
      }
    },

    watch: {},
};
</script>

<style scoped>

</style>
