<template>
    <video id="audio_player" class="video-js vjs-default-skin post-detail-video"
           :poster="thumbnail"
           crossOrigin="anonymous"
           playsInline controls>
    </video>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import util from "@/mixins/util";
import videojs from 'video.js';
import 'video.js/dist/video-js.css'

export default {
    name: "AudioComponent",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        url: {
            default: () => ''
        },
        width: {default: 0},
        height: {default: 0},
        type: {default: 'audio/mp3'},
        autoplay: {default: false},
        controls: {default: true},
        thumbnail: {default: ''},
    },
    data() {
        return {
            player: null,
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {
        this.$nextTick(() => {
            this.setVideo();
        })
    },
    beforeDestroy() {
        this.initPlayer()
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setVideo() {
            let $this = this
            this.player = videojs(`audio_player`,
                {
                    fullscreen: {
                        navigationUI: "hide"
                    },
                    html5: {
                        vhs: {
                            //withCredentials: true,
                            overrideNative: true
                        }
                    },
                    controlBar: {
                        fullscreenToggle: false,
                        pictureInPictureToggle: false,
                        subsCapsButton: false,
                    },
                    autoplay: false,
                    controls: true,
                    width: $this.width,
                    height: $this.height,
                    // disablePictureInPicture:true
                    disableTogglePlay: true
                },
                function onPlayerReady() {
                    // $this.videoVueSetData(`player`, $this.player)
                })
            this.player.ready(function () {
                console.log(' player ready')
                this.src({
                    src: $this.url,
                    // type: $this.type,
                });

            });
        },
        returnMedia(image) {
            if (util.isEmpty(image)) {
                return '';
            }
            let url = image
            url = url.replace(/%/g,'%25')
            let ex = this.returnExtension(url);
            // return `${url}?${this.setImageOptions(this.width, null, ex)}`;
            // return `${url}?${this.setFitWidthImg(url, this.width)}`
            return url;
        },
        initPlayer() {
            this.player.dispose();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
