<template>
    <div class="point_history_list_wrap">
        <ul>
            <li class="p_history_box" v-for="(data,index) in pointData" :key="`point${index}`">
                <div class="ph_left">
                    <div class="date_wrap">{{ returnDateFormat(data.created_at, 'YYYY-MM-DD HH:mm') }} <span>{{ $t(returnPointType(data.pd_code)) }}</span></div>
                    <div class="subject_wrap" v-html="$t(data.pd_code,data.ExtraData[0])"></div>
                    <div class="ph_num_wrap">
                        <span class="ph_num">{{$t('payment_number')}}</span>
                        <span class="num">{{ data.p_uuid ? data.p_uuid : '-'}}</span>
                    </div>
                </div>

                <div class="ph_right">
                    <div class="amount_wrap">
                        <div class="mg_icon">{{returnIcon(data.p_point)}}</div>
                        <!--              <div class="mg_icon">-</div>-->
                        <div class="amount">{{ returnPrice(returnOnlyNumber(data.p_point)) }}</div>
                        <div class="mg">mg</div>
                    </div>

                    <div class="own_mg_wrap">
                        <span class="own_mg">{{$t('now')}}{{$t('mingle')}}</span>
                        <span class="own_mg_num">{{ returnPrice(data.p_point_history + data.p_point)}}</span>
                    </div>
                </div>
            </li>

        </ul>
    </div>
</template>

<script>
import {pointStatus} from "@/service/pointService";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";
export default {
    name: "PointListHistoryLayout",
    mixins: [alertMixins, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        pointData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnPointType(status){
            let index = pointStatus.findIndex(ds => ds.value === status);
            if(index > -1) {
                return pointStatus[index].name;
            }
            return '-';
        },
        returnPrice(price){
            return util.Number.numFormat(price);
        },
        returnIcon(point) {
            if(isNaN(point)) {
                return ''
            }
            return point >= 0 ? '+' : '-';
        },
        returnOnlyNumber(point) {
            if(isNaN(point)) {
                return ''
            }
            return point >= 0 ? point : point*-1;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
