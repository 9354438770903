<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap">
            <entry-write-component :entry-type="'entry'" :competitionKey="competitionKey"></entry-write-component>
        </div>
    </div>
</template>

<script>
import EntryWriteComponent from "@/components/entry/EntryWriteComponent";

export default {
    name: "EnterWrite",
    mixins: [],
    components: {
        EntryWriteComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.cid
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
