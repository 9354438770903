import { render, staticRenderFns } from "./DetectionUrl.vue?vue&type=template&id=5b3dd4fe&scoped=true"
import script from "./DetectionUrl.vue?vue&type=script&lang=js"
export * from "./DetectionUrl.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b3dd4fe",
  null
  
)

export default component.exports