<template>
    <div class="member_wrap mb100">
        <div class="main_title mb25">{{ $t('en_hotcreator') }}</div>

        <div class="swiper_wrap">
            <swiper class="member_swiper" :options="swiperOption" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="member_swiper_slide" v-for="(data,index) in creator" :key="`creator${index}`">
                    <div class="creator_list member_list">
                        <div class="creator_top mb6">
                            <div class="creator_img">
                                <div class="profile_background"
                                     :style="` background-image: url(${returnBackground(data)});`"
                                     :id="`img_${data.mb_uuid}`"></div>
                                <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator
                                </div>
                            </div>
                            <div class="profile_info">
                                <div class="profile_img" :id="`profile_${data.mb_uuid}`"
                                     :style="` background-image: url(${returnProfile(data)});`"></div>
                                <div class="profile_s_mark" v-if="data.mb_premium===1"
                                     :id="`special_${data.mb_uuid}`"></div>
                                <div class="profile_text" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>

                                <div class="creator_like">
                                    <div class="like_img" :class="{'active': isMyLike(data)}"
                                         :id="`like_creator_${data.mb_no}`"></div>
                                    <div class="like_cnt" :id="`like_creator_${data.mb_no}`">{{ data.likeCnt }}</div>

                                    <div class="sub_point" :id="`point_${data.mb_uuid}`">·</div>
                                    <div class="item_subject" :id="`item_${data.mb_uuid}`">Item
                                        <span class="bold" :id="`itemCnt_${data.mb_uuid}`">
                                            {{ data.itemCnt }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination member_swiper_pagination " slot="pagination"></div>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_creator_prev1" slot="button-prev"></div>
            <div class="swiper_btn swiper-button-next hot_creator_next1" slot="button-next"></div>
        </div>

        <div class="swiper_wrap">
            <div class="main_title mb25">{{ $t('en_topcollector') }}</div>
            <swiper class="member_swiper" :options="swiperOption2" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="member_swiper_slide" v-for="(data,index) in collector" :key="`creator${index}`">
                    <div class="creator_list member_list">
                        <div class="creator_top mb6">
                            <div class="creator_img">
                                <div class="profile_background member_background">
                                    <img class="m_b_img" :id="`img_${data.mb_uuid}`" :src="returnBackground(data)">
                                </div>
                                <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator
                                </div>
                            </div>
                            <div class="profile_info">
                                <div class="profile_img member_img" :id="`profile_${data.mb_uuid}`"
                                     :style="` background-image: url(${returnProfile(data)});`"></div>
                                <div class="profile_s_mark" v-if="data.mb_premium===1"
                                     :id="`special_${data.mb_uuid}`"></div>
                                <div class="profile_text" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>
                                <div class="creator_like">

                                    <div class="like_img" :class="{'active': isMyLike(data)}"
                                         :id="`like_collector_${data.mb_no}`"></div>
                                    <div class="like_cnt" :id="`like_collector_${data.mb_no}`">{{ data.likeCnt }}</div>

                                    <div class="sub_point" :id="`point_${data.mb_uuid}`">·</div>
                                    <div class="item_subject" :id="`item_${data.mb_uuid}`">Item
                                        <span class="bold" :id="`itemCnt_${data.mb_uuid}`">
                                            {{ data.itemCnt }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination member_swiper_pagination " slot="pagination"></div>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_creator_prev2" slot="button-prev"></div>
            <div class="swiper_btn swiper-button-next hot_creator_next2" slot="button-next"></div>
        </div>

        <div class="swiper_wrap">
            <div class="main_title mb25">{{ $t('en_topcurator') }}</div>
            <swiper class="member_swiper" :options="swiperOption3" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="member_swiper_slide" v-for="(data,index) in curator" :key="`creator${index}`">
                    <div class="creator_list member_list">
                        <div class="creator_top mb6">
                            <div class="creator_img">
                                <div class="profile_background"
                                     :style="` background-image: url(${returnBackground(data)});`"
                                     :id="`img_${data.mb_uuid}`"></div>
                                <div class="curator_icon" :id="`icon_${data.mb_uuid}`" v-if="data.g_idx === 2">Curator
                                </div>
                            </div>
                            <div class="profile_info">
                                <div class="profile_img member_img" :id="`profile_${data.mb_uuid}`"
                                     :style="` background-image: url(${returnProfile(data)});`"></div>
                                <div class="profile_s_mark" v-if="data.mb_premium===1"
                                     :id="`special_${data.mb_uuid}`"></div>
                                <div class="profile_text" :id="`nick_${data.mb_uuid}`">{{ data.mb_nick }}</div>
                                <div class="creator_like">
                                    <div class="like_img" :class="{'active': isMyLike(data)}"
                                         :id="`like_curator_${data.mb_no}`"></div>
                                    <div class="like_cnt" :id="`like_curator_${data.mb_no}`">{{ data.likeCnt }}</div>
                                    <div class="sub_point" :id="`point_${data.mb_uuid}`">·</div>
                                    <div class="item_subject" :id="`item_${data.mb_uuid}`">Item
                                        <span class="bold" :id="`itemCnt_${data.mb_uuid}`">
                                            {{ data.itemCnt }}</span></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </swiper-slide>
                <div class="swiper-pagination member_swiper_pagination " slot="pagination"></div>
            </swiper>
            <div class="swiper_btn swiper-button-prev hot_creator_prev3" slot="button-prev"></div>
            <div class="swiper_btn swiper-button-next hot_creator_next3" slot="button-next"></div>
        </div>
    </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import {mapState} from "vuex";

export default {
    name: "MemberHomeListComponent",
    mixins: [memberUtil, imageOption],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberType: '',
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                navigation: {
                    nextEl: '.hot_creator_next1',
                    prevEl: '.hot_creator_prev1',
                },
            },
            swiperOption2: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                navigation: {
                    nextEl: '.hot_creator_next2',
                    prevEl: '.hot_creator_prev2',
                },
            },
            swiperOption3: {
                slidesPerView: 2,
                spaceBetween: 20,
                centeredSlides: false,
                loop: true,
                navigation: {
                    nextEl: '.hot_creator_next3',
                    prevEl: '.hot_creator_prev3',
                },
            },
            collector: [],
            creator: [],
            curator: []
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getMember();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {

        getMember() {
            this.$api.$member.getMainList().then(res => res.Data.Result).then(res => {
                if (res.List.length !== 0) {
                    this.creator = res.List.Creator
                    if (this.creator.length < 2) {
                        this.swiperOption.loop = false;
                    }
                    this.collector = res.List.Collector
                    if (this.collector.length < 2) {
                        this.swiperOption2.loop = false;
                    }
                    this.curator = res.List.Curator
                    if (this.curator.length < 2) {
                        this.swiperOption3.loop = false;
                    }
                }
            })
        },
        movePage(url) {
            this.$router.push(`/creator/${url}`);
        },
        returnBackground(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.ProfileBack)) {
                return "";
            }
            return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, 'jpg')}`;
        },
        returnProfile(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.Profile)) {
                return "";
            }
            return `${data.Files.Profile[0].org_url}?${this.setImageOptions(68, 68, 'png')}`;
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (event.type !== "touchend") {
                return false
            }
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId)) {
                return false;
            }

            let elementIdx = elementId.split('_');
            if (elementIdx[0] === 'like') {
                this.likeMember(elementIdx[1], elementIdx[2])
            } else {
                this.movePage(elementIdx[1]);
            }
        },
        isMyLike(data) {
            if (util.isEmpty(data)) {
                return
            }
            return data.likeStatus
        },
        likeMember(type, key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.follow_id = key
                this.$api.$member.toggleLike(params).then(res => res.Data.Result).then(res => {
                    if (res.List.type === 'create') {
                        this.likeAfterFunc('add', key, type);
                    } else if (res.List.type === 'delete') {
                        this.likeAfterFunc('sub', key, type);
                    }
                })
            }
        },
        likeAfterFunc(type, key, member) {
            let likeIndex = this[member].findIndex(data => data.mb_no === Number(key));
            if (likeIndex > -1) {
                if (type === 'add') {
                    this[member][likeIndex].likeCnt = this[member][likeIndex].likeCnt + 1;
                } else if (type === 'sub') {
                    this[member][likeIndex].likeCnt = this[member][likeIndex].likeCnt - 1;
                }
                this[member][likeIndex].likeStatus = !this[member][likeIndex].likeStatus;
                this.$forceUpdate();
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
