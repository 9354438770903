<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header has_btn">
            <button slot="headerBtns" class="h_write_btn" @click="movePage('/qna/create')"></button>
        </page-header>
<!--        <page-header :header_main_back="true" :header_side="true">
            <button slot="headerBtns" class="h_write_btn" @click="movePage('/qna/create')"></button>
        </page-header>-->

        <div class="padding_side dgm_wrap">
<!--            <div class="notice_tabs">-->
<!--                <div class="notice_item_3_tab" @click="movePage('/notice')">-->
<!--                    {{ $t('notice') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab" @click="movePage('/faq')">-->
<!--                    {{ $t('detail_faq') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab active  font_white">-->
<!--                    {{ $t('qna') }}-->
<!--                </div>-->
<!--            </div>-->
            <board-list-component :boardType="'qna'"></board-list-component>
        </div>
    </div>


</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";

export default {
    name: "Question",
    mixins: [],
    components: {
        BoardListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            search_input: ""
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
