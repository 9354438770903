<template>
    <modal name="play-audio-modal" class="play-audio-modal play-video-modal"
           :width="'90%'"
           :height="'90%'"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
           @before-close="handlerBeforeClose"
    >
        <div class="pa10 pt5  bc_white">
            <div class="modal_title mb0">
                <span>{{$t('music')}}</span>
                <div class="close_btn_wrap request_modal">
                    <button class="close_modal" @click="backEvent()"></button>
                </div>
            </div>
<!--            <iframe :src="returnUrl()"
                    frameborder="0"
                    allowfullscreen
                    playsinline
                    allow="autoplay; gyroscope; accelerometer; encrypted-media"
                    style="width: 100%;"
                    height="600"
                    v-if="type === 2"></iframe>-->
<!--            <video :src="src" controls controlslist="nodownload"  v-else></video>-->
            <div class="play-video-modal-wrap">
                <audio-component v-if="showAudio" :url="src" :type="audioType" :width="width" :height="height" :thumbnail="thumbnail"
                                 style="display: block; width: 100%; height: 100%;"></audio-component>
            </div>

        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";
import AudioComponent from "@/components/player/AudioComponent";

export default {
    name: "PlayAudioModal",
    mixins: [],
    components: {
        AudioComponent
    },
    props: {},
    inject: ['changeLoading'],
    provide(){
        return {

        }
    },
    data() {
        return {
            src: '',
            name: '',
            type:1,
            youtubeId:'',
            audioType:'application/x-mpegURL',
            thumbnail: '',
            showAudio: false,
            width: 0,
            height: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        backEvent() {
            this.$modal.hide('play-audio-modal');
        },
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.src)) {
                event.stop();
            }
            this.type = 1;
            this.src = event.params.src;
            this.name = event.params.name;
            this.thumbnail = event.params.thumbnail;
            this.audioType = this.returnAudioType(this.src)
        },
        handlerOpened() {
            this.widith = $('.play-video-modal .v--modal').innerWidth() - 20;
            this.height = $('.play-video-modal .v--modal').innerHeight() - 54;
            this.showAudio = true;
        },
        handlerBeforeClose() {
            this.src = '';
            this.thumbnail = '';
            this.width = 0;
            this.height = 0;
            this.showAudio = false;
        },
        returnAudioType(src){
            let typeIndex = src.lastIndexOf('.')
            let type = src.substring(typeIndex+1)
            if(type === 'm3u8'){
                return 'application/x-mpegURL'
            }
            return 'audio/'+type;
        },
        setData(key,value){
            this[key] = value
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
