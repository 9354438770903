<template>
    <modal name="open-market-sort-filter" class="filter-modal open-market-sort-filter"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img sort_img"></div>
                    <div class="filter_name">{{ $t('sort') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20  filter_modal_status">
                    <div class="radio_wrap block_wrap">
                        <div class="inline_block">
                            <el-radio-group v-model="sortOption"
                                            @change="sortChange(sortOption)">
                                <el-radio :label="'last_update'">{{ $t('b_update') }}</el-radio>
                                <el-radio :label="'im_real_hit'">{{ $t('b_enter_m') }}</el-radio>
                                <el-radio :label="'im_real_like'">{{ $t('b_like') }}</el-radio>
                                <el-radio :label="'priceDesc'">{{ $t('priceDesc') }}</el-radio>
                                <el-radio :label="'priceAsc'">{{ $t('priceAsc') }}</el-radio>

                            </el-radio-group>
                        </div>
                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="modalClose">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "OpenMarketSortFilter",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            sortData: {},
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {

            this.sortData = event.params.sortData;
            //this.setterFilterDataInData();

            this.setterSortDataInData()
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('open-market-sort-filter');
        },
        setterSortDataInData() {
            if(util.isEmpty(this.sortData)) {
                return false;
            }
            this.sort = this.sortData.order;
            this.sortOption = this.sortData.key
            if (this.sortData.key === 'i_price') {
                if (this.sortData.order === 'desc') {
                    this.sortOption = `priceDesc`
                } else if (this.sortData.order === 'asc') {
                    this.sortOption = `priceAsc`
                }
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('i_price', 'asc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('i_price', 'desc');
            } else {
                this.sort = 'desc'
                this.setSort(data, 'desc');
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
