<template>
    <div id="header" class="header">
        <div class="header_wrap main" v-if="header_logo">
            <button class="h_symbol" @click="moveHome()"></button>
            <div class="main_right_btn_wrap">
                <button class="h_search_btn_new" @click="movePage('/search')"></button>
                <button class="h_add_btn_new" v-if="isLogin()" @click="movePage('/enroll')"></button>
                <button class="h_alarm_btn_new" v-if="isLogin()" @click="movePage('/alarm')"></button>
                <button class="h_menu_btn_new" @click="openModal('header-menu-modal')"></button>
            </div>
            <div class="clear"></div>
        </div>
        <div class="header_wrap" v-else>
            <button class="h_back_btn" v-show="header_back" slot="start" @click="BackEvt();">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_main_back" slot="start" @click="movePage('/');">
                <img src="@/assets/image/header_back.png">
            </button>

            <button class="h_back_btn" v-show="header_modal_back" slot="start"
                    @click="modalClose(`profile-setting-modal`)">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_h_modal_back" slot="start"
                    @click="modalClose(`curation-history-modal`)">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_g_modal_back" slot="start"
                    @click="modalClose(`gallery-setting-modal`)">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_j_modal_back" slot="start"
                    @click="modalClose(`judge-search-modal`)">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_e_modal_back" slot="start"
                    @click="modalClose(`entry-search-modal`)">
                <img src="@/assets/image/header_back.png">
            </button>
            <button class="h_back_btn" v-show="header_white_back" slot="start" @click="BackEvt();">
                <img src="@/assets/image/header_white_back.png">
            </button>
            <button class="h_back_btn" v-show="header_white_main_back" slot="start" @click="movePage('/');">
                <img src="@/assets/image/header_white_back.png">
            </button>
            <button class="h_logo" v-show="header_logo" @click="moveHome()">
                <img src="@/assets/image/header_logo.png">
            </button>
            <div class="header_title"
                 v-show="headerTitle || $route.meta.title " @click="scrollMoveTop" v-html="headerTitle ? headerTitle : $t($route.meta.title)">
                {{ headerTitle ? headerTitle : $t($route.meta.title) }}
            </div>
            <button slot="end" class="h_menu_btn" v-show="header_side" @click="openModal('header-menu-modal')">
                <div class="menu_bar"></div>
            </button>
            <button slot="end" class="h_search_btn" v-show="haeader_search"
                    @click="movePage('/search')"></button>
            <div slot="end">
                <slot name="headerBtns"></slot>
            </div>
<!--            <div slot="end" class="h_add_btn_wrap"  v-show="header_side">
                <button class="h_add_btn"><i class="el-icon-plus"></i></button>
            </div>-->

        </div>
        <header-menu-modal v-if="header_side"></header-menu-modal>
    </div>
</template>

<script>
import HeaderMenuModal from "@/components/modal/HeaderMenuModal";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "PageHeader",
    mixins: [AuthMixins],
    components: {HeaderMenuModal},
    inject: [],
    provide() {
        return {}
    },
    props: {
        msg: String,
        BackEvent: {type: null, default: false},
        headerTitle: {
            default: null,
        },
        header_type: {
            type: String,
            default: '',
        },
        header_back: {
            type: Boolean,
            default: false,
        },
        header_main_back: {
            type: Boolean,
            default: false,
        },
        header_white_back: {
            type: Boolean,
            default: false,
        },
        header_white_main_back: {
            type: Boolean,
            default: false,
        },
        header_modal_back: {
            type: Boolean,
            default: false,
        },
        header_delete: {
            type: Boolean,
            default: false,
        },
        header_logo: {
            type: Boolean,
            default: false,
        },
        header_setting: {
            type: Boolean,
            default: false,
        },
        header_search: {
            type: Boolean,
            default: false,
        },
        header_menu: {
            type: Boolean,
            default: false,
        },
        header_h_modal_back: {
            type: Boolean,
            default: false,
        },
        header_g_modal_back: {
            type: Boolean,
            default: false,
        },
        header_e_modal_back: {
            type: Boolean,
            default: false,
        },
        header_side: {
            type: Boolean,
            default: false,
        },
        header_j_modal_back: {
            type: Boolean,
            default: false,
        },
        haeader_search: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        modalClose(name) {
            this.$modal.hide(name);
        },
        movePage(path) {
            this.$router.push(path);
        },
        BackEvt() {
            this.$router.back();
        },
        scrollMoveTop() {
            $('.scroll-move-top').animate({scrollTop: 0}, 300);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        moveHome() {
            if(this.$route.path != '/') {
                this.$router.push('/')
            }
        },
    },
    watch: {},

}
</script>

<style scoped>

</style>
