<template>
    <div class="mypage_list_wrap">
        <div class="mypage_list border-top" v-for="(data,index) in itemData"
             :key="`item${index}`">
            <div class="item_information">
                <div class="list_time" @click="movePage(data)">{{ data.created_at }}</div>
            </div>
            <div class="item_img_wrap mt11" @click="movePage(data)">
                <div class="item_img">
                    <img :src="returnThumbnail(data)">
                </div>
                <div class="item_text_list">

                    <div class="item_text bold">[{{ checkItemType(data) }}]</div>
                    <div class="item_text">{{ data.i_name }}</div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListHoldingLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}

    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(data) {
            if (data.im_enroll === 2) {
                this.$router.push(`/item/update/${data.i_sku}`);
                return
            }
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
                return
            } else {
                this.$router.push(`/item/${data.i_sku}`);
                return
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(142, 90, 'png')}`;
            }
        },
        isCuration(data) {
            return !util.isEmpty(data.ibc_idx)
        },
        checkItemType(data) {
            if (data.im_enroll === 2) {
                return this.$t('temporary_save');
            }
            /*if (!util.isEmpty(data.ibc_idx)) {
                return this.$t('c_request');
            }*/
            if (data.im_status === 6) {
                return this.$t('competition_apply');
            }
            if (data.i_status === 'nosale') {
                return this.$t('no_sell');
            } else if (data.i_status === 'auction') {
                return this.$t('auction');
            } else if (data.i_status === 'license') {
                return this.$t('licenseSell');
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling');
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
