<template>
   <div>
       CurationWriteDefaultLayout
   </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import curationValidator from "@/mixins/validators/curationValidator";
import AuthMixins from "@/mixins/AuthMixins";
export default {
    name: "CurationWriteDefaultLayout",
    mixins: [alertMixins, curationValidator,AuthMixins],
    components: {
    },
    inject: ['curationWriteSetData'],
    provide() {
        return{

        }
    },
    props: {
        curationData: {default: () => {}},
        itemData: {default: () => {}},
    },
    data() {
        return{
            termsCheck: false,
            errMsg: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setInitData();
        openTerms = this.openModal;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setInitData() {
            this.curatorKey = Number(this.$route.params.mb_no);
            this.curationWriteSetData('curatorKey', this.curatorKey);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        changeCheck() {
            this.errMsg = "";
        },
        requestConfirm() {
            this.createConfirm({
                content : this.$t('curation_c_msg1') +'<br/>'+ this.$t('curation_c_msg2') ,
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    this.requestResult()
                },
                cancel: () => {

                }
            });
        },
        requestResult() {
            this.createConfirm({
                content : this.$t('curation_c_msg3') +'<br/>'+ this.$t('check_my') ,
                confirmText : this.$t('check_now'),
                cancelText : this.$t('check_later'),
                confirm: () => {
                },
                cancel: () => {

                }
            });
        },
        checkValidator() {
            if(!this.termsCheck) {
                this.errMsg = this.$t('curation_create_err2')
                return false;
            }
            if(!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            }else{
                this.requestConfirm();
            }


        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
