<template>
    <div class="detect_detail_wrap">
        <div>
            <div class="detect_detail_wrap_top">
                <figure class="detect_detail_img_box">
                    <img :src="returnItemImage()" alt="detect_img">
                </figure>
                <p class="sub_title_m sub_title_m_detect">{{ $t('detect_img_title') }}</p>
                <h3>{{returnItem().i_name}}</h3>
            </div>
            <div class="detect_detail_wrap_bottom">
                <ul class="detect_inform_box">
                    <li>
                        <p class="detect_detail_tit sub_title_m">{{ $t('detect_img_filming_date') }}</p>
                        <p class="detect_detail_detail sub_title_m">{{ returnDateFormat(returnItem().imo_date, 'YYYY.MM.DD') }}</p>
                    </li>
                    <li>
                        <p class="detect_detail_tit sub_title_m">{{ $t('detect_img_create_date') }}</p>
                        <p class="detect_detail_detail sub_title_m">{{ returnDateFormat(returnItem().created, 'YYYY.MM.DD') }}</p>
                    </li>
                    <li>
                        <p class="detect_detail_tit sub_title_m">{{ $t('detect_last_date') }}</p>
                        <p class="detect_detail_detail sub_title_m">{{ returnLastDetectDate() }}</p>
                    </li>
                    <li>
                        <p class="detect_detail_tit sub_title_m">{{ $t('detect_img_number') }}</p>
                        <p class="detect_detail_detail sub_title_m">{{returnItem().i_sku}}</p>
                    </li>
                </ul>
                <div class="detect_btn_border">
                    <button class="detect_btn one_btn" @click="runDetection">{{ returnDetectNumber() == 0 ? $t('detection') : $t('re_detection') }}</button>
                </div>
                <button class="detection_result_btn one_btn"
                        @click="moveHistory()"
                        v-html="$t('detection_detail_cnt', {cnt: `<strong class='detection_result_number'>${returnDetectNumber()}</strong>`})">
                </button>
            </div>
        </div>
    </div>
<!--    <div class="detect_detail_wrap">-->
<!--        &lt;!&ndash;        <div>-->
<!--                    <img :src="returnItemImage()" alt="">-->
<!--                </div>&ndash;&gt;-->
<!--        <div class="detect_main_wrap">-->
<!--            <div class="detect_header_wrap">-->
<!--                <span>{{ $t('Item_search') }}</span>-->
<!--            </div>-->
<!--            <div class="detect_content_wrap">-->
<!--                <div class="image_wrap">-->
<!--                    <div class="d_img">-->
<!--                        <img :src="returnItemImage()">-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="d_inform_wrap">-->
<!--                    <div class="d_inform_header">-->
<!--                        <div class="d_header_text">-->
<!--                            {{ $t('detect_img_title') }}-->
<!--                        </div>-->
<!--                        <div class="d_header_text2">-->
<!--                            {{returnItem().i_name}}-->
<!--                        </div>-->

<!--                    </div>-->
<!--                    <div class="d_inform_bottom">-->
<!--                        <div class="d_img_date">-->
<!--                            <div class="img_date">-->
<!--                                {{ $t('detect_img_filming_date') }}-->
<!--                            </div>-->
<!--                            <div class="d_date">-->
<!--                                {{ returnDateFormat(returnItem().imo_date, 'YYYY.MM.DD') }}-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="d_img_date">-->
<!--                            <div class="img_date">-->
<!--                                {{ $t('detect_img_create_date') }}-->
<!--                            </div>-->
<!--                            <div class="d_date">-->
<!--                                {{ returnDateFormat(returnItem().created, 'YYYY.MM.DD') }}-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="d_img_date">-->
<!--                            <div class="img_date">-->
<!--                                {{ $t('detect_last_date') }}-->
<!--                            </div>-->
<!--                            <div class="d_date">-->
<!--                                {{ returnLastDetectDate() }}-->
<!--                            </div>-->
<!--                        </div>-->

<!--                        <div class="d_img_date">-->
<!--                            <div class="img_date">-->
<!--                                {{ $t('detect_img_number') }}-->
<!--                            </div>-->
<!--                            <div class="d_date">-->
<!--                                {{returnItem().i_sku}}-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="d_img_btn">-->
<!--                            <button @click="runDetection()">{{ returnDetectNumber() == 0 ? $t('detection') : $t('re_detection') }}</button>-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--                <div class="d_search">-->
<!--                    <button class="ds_text" @click="moveHistory()" v-html="$t('detection_detail_cnt', {cnt: `<span>${returnDetectNumber()}</span>`})">-->
<!--&lt;!&ndash;                        검출결과 총 <span>N</span>건&ndash;&gt;-->
<!--                    </button>-->
<!--                </div>-->
<!--            </div>-->
<!--        </div>-->

<!--        &lt;!&ndash;-->

<!--                    <div>{{$t('detect_img_filming_date')}}</div>-->
<!--                    <div>{{ returnDateFormat(returnItem().imo_date, 'YYYY.MM.DD') }}</div>-->
<!--                    <div>{{$t('detect_img_create_date')}}</div>-->
<!--                    <div>{{ returnDateFormat(returnItem().created, 'YYYY.MM.DD') }}</div>-->
<!--                    <div>{{$t('detect_last_date')}}</div>-->
<!--                    <div>{{ returnLastDetectDate() }}</div>-->
<!--                    <div>{{$t('detect_img_number')}}</div>-->
<!--                    <div>NFT관련내용은 추후</div>-->
<!--                    <button @click="runDetection()">{{ returnDetectNumber() == 0 ? $t('detection') : $t('re_detection') }}</button>-->
<!--                    <button @click="moveHistory()">{{ $t('detection_detail_cnt', {cnt: returnDetectNumber()}) }}</button>&ndash;&gt;-->

<!--    </div>-->
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import alertMixins from "@/mixins/alertMixins";
export default {
    name: "DetectionDetailDefaultLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins,alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {
            default: () => {
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnDetect() {
            if (util.isEmpty(this.detectionData.Detect)) {
                return {};
            }
            return this.detectionData.Detect;
        },
        returnItem() {
            if (util.isEmpty(this.detectionData.Item)) {
                return {};
            }
            return this.detectionData.Item[0]
        },
        returnItemImage() {
            if (util.isEmpty(this.returnItem().ItemFile) || util.isEmpty(this.returnItem().ItemFile.Item)) {
                return '';
            }
            //return `${this.returnItem().ItemFile.Item[0].org_url}?${this.setImageOptions(580, null, null)}`
            return this.setFitWidthImg(this.returnItem().ItemFile.Item, 580);
        },
        returnLastDetectDate() {
            if (util.isEmpty(this.returnDetect().last_update)) {
                return '-'
            }
            return this.returnDateFormat(this.returnDetect().last_update, 'YYYY.MM.DD')
        },
        returnDetectNumber() {
            return util.isEmpty(this.returnDetect().cnt) ? 0 : util.Number.numFormat(this.returnDetect().cnt);
        },
        runDetection() {
            this.createConfirm({
                content : this.$t('check_detection',{'mingle':100}),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm:()=>{
                    EventBus.$emit('runItemDetection');
                }
            })
        },
        moveHistory() {
            if (this.returnDetectNumber() == 0) {
                return false;
            }
            this.$router.push(`/detection/${this.returnDetect().d_idx}/history`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
