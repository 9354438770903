<template>
    <div></div>
</template>

<script>
import util from "@/mixins/util";
import pointUtil from "@/components/point/pointUtil";

const {filterConfig} = pointUtil;
export default {
    name: "PointFilterDefaultLayout",
    mixins: [pointUtil],
    components: {
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return{}
    },
    props: {
        filterData:{default: () => {}},
    },
    data() {
        return{
            mingleArr: [],
            filterStatus: [],
            filterDate: [],
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        setterFilterDataInData() {
            let classnames = Object.keys(this.pointData);
            classnames.forEach(classname => {
                let obj = this.pointData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeDateFilter() {
            if(util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            }else{
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>