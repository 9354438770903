<template>
    <div class="contest_wrap competition con_height">
        <div class="main_title mt35 mb20">{{ $t('en_contest') }}</div>
        <swiper v-if="showSlide" class="contest_swiper" ref="swiper" :options="swiperOption" @click="onClick"
                @slideChange="slideChange">
            <swiper-slide class="contest_swiper_slide" v-for="(competition, index) in competitionData"
                          :key="`competition-home-list-${competition.c_idx}`">
                <!--        <img :id="`mainCompetition${competition.c_uuid}`"-->
                <!--                :src="returnImage(competition.Poster,650,930)" alt="">-->
                <div class="content_card"
                     :id="`mainCompetition${competition.c_uuid}`"
                     :style="`background-image : url(${returnImage(competition.Poster,283,390)})`"
                >
                    <div class="c_category">
                        {{ returnCategoryName(competition.cc_idx) }}
                    </div>
                    <div class="contest_info_box">
                        <div class="contest_content_area">{{ competition.c_title }}</div>
                        <div class="content_box">
                            <div class="contest_category_area">
                                <div class="c_day">
                                    <competition-date-time
                                            :competition-data="competitionData[index]"></competition-date-time>
                                </div>
                            </div>
                            <div class="content_mingle_area">
                                <div class="c_mingle_img"></div>
                                <div class="c_mingle_price">{{ returnCommas(competition.c_prize) }} Mg</div>
                            </div>
                            <div class="c_won_price">(￦{{ returnCommas(returnMingle(competition.c_prize)) }})</div>
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <!--      <div class="swiper-pagination contest_swiper_pagination " slot="pagination"></div>-->
            <div class="swiper-pagination" slot="pagination"></div>
        </swiper>
        <!--    <div class="art_wrap">
              <div class="art_title">
                  {{returnCategoryName(competitionData[slideIndex].cc_idx)}}
              </div>
              <div class="art_text mt3">{{ competitionData[slideIndex].c_title }}</div>
              <div class="art_text2 mt10">
                  <competition-date-time :competition-data="competitionData[this.slideIndex]"></competition-date-time>
              </div>
            </div>-->
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import imageOption from "@/mixins/imageOption";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListHomeLayout",
    mixins: [imageOption, CompetitionCategoryMixin, imageResizeMixins],
    components: {
        Swiper,
        SwiperSlide,
        CompetitionDateTime
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            swiperOption: {
                initialSlide: 0,
                slidesPerView: 1.5,
                spaceBetween: 20,
                centeredSlides: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    // type: 'bubble',
                    clickable: true
                },
            },
            slideIndex: 0,
            showSlide: false,
            checkList: []

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.setIndex()
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setIndex() {
            if (this.competitionData.length < 2) {
                this.swiperOption.loop = false;
            }
            this.showSlide = true;
            if (!util.isEmpty(this.competitionData)) {
                this.$nextTick(() => {
                    this.slideIndex = this.$refs.swiper.$swiper.realIndex;
                })
            }
        },
        onSwiper(v) {
            console.log(v)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
                // return `${image[0].org_url}`
            }
            return ''
        },
        movePage(url) {
            this.$router.push(url)
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId) || elementId.indexOf('mainCompetition')) {
                return false;
            }
            let elementIdx = elementId.replace('mainCompetition', "");
            this.movePage('/competition/' + elementIdx);
        },
        returnCommas(x = 0) {
            if (util.isEmpty(x)) {
                return 0;
            }
            return util.Number.numFormat(x);
        },
        returnMingle(data) {
            return `${(Number(data) * 10)}`
        }
    },
    watch: {
        'competitionData': {
            deep: true,
            handler: function (val) {
                this.setIndex();
            }
        }
    },
}
</script>

<style scoped>

</style>
