<template>
    <div class="dgm_wrap mt105 mb90">

        <div class="register" v-show="step === 1">
            <div class="email_wrap">
                <div class="login_title">
                    <span v-html="$t('loginText1')"></span>
                    <!--          {{$t('loginText1')}}-->
                </div>
                <div class="login_top_img">
                    <img src="@/assets/image/main_logo.png" alt="">
                </div>
                <div class="login_nickname padding_side">
                    <div class="login_signup_input find_password_input mb9">
                        <input type="email" class="register_email" :placeholder="$t('email')" @input="setData('email', email)"
                               v-model="email" @keypress.enter="checkValidator()"/>
                    </div>
                </div>
                <div class="email_register find_password_btn padding_side mt20">
                    <button class="one_btn back_navy" @click="checkValidator()">
                        {{ $t('temp_pwd') }}
                    </button>
                    <div class="validation" v-if="validation.hasError('email')">
                        {{ validation.firstError('email') }}
                    </div>
                </div>

            </div>
        </div>
        <div class="register" v-show="step === 2" v-if="step >= 2">
            <div class="email_wrap">
                <div class="login_title">
                    <span v-html="$t('loginText1')"></span>

                    <div class="login_top_img">
                        <img src="@/assets/image/main_logo.png" alt="">
                    </div>
                </div>
                <div class="findpassword_send_middle_wrap">
                    <div class="findpassword_send_middle">
                        {{ $t('send_sms_pw', {usermail: email}) }}
                    </div>
                </div>
                <!--      register_middle_wrap-->
                <div class="findpassword_send_bottom_wrap mt20">
                    <div class="findpassword_send_bottom padding_side">
                        <button class="one_btn back_navy" @click="movePage('/auth')">{{ $t('login') }}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import memberValidator from "@/mixins/validators/memberValidator";
import EventBus from "@/utils/event-bus";

export default {
    name: "FindComponent",
    mixins: [alertMixins, memberValidator],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            checkTerms: false,
            step: 1,
            email: '',
            errorChk: false,
            errorMsg: '',

            isRunning: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        checkValidator() {
            if (this.isRunning) {
                return false;
            }
            this.isRunning = true;
            if (this.errorMsg != '') {
                this.errorMsg = '';
                this.errorChk = false;
                return false;
            }
            this.$validate('email').then(success => {
                if (success) {
                    this.errorChk = false;
                    this.sendSms();
                } else {
                    this.errorChk = true;
                    this.errorMsg = this.$t('n_email_msg');
                    this.isRunning = false;
                }
            })
        },
        sendSms() {
            let lang = this.$localStorage.get('lang');
            let params = {
                mb_email: this.email
            };
            if (lang === 'en') {
                params.lan = 'en'
            }
            EventBus.$emit('apiLoading', true);
            this.$api.$auth.findPw(params).then(res => res.Data.Result).then(res => {
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;
                let type = this.returnSendSmsType(res.Info.type);
                if (type === true) {
                    this.sendAfter();
                } else {
                    this.errorAlert(type);
                }
            })
        },
        returnSendSmsType(type) {
            switch (type) {
                case 1:
                    return true;
                case -2:
                    return this.$t('find_pw_err1')
                default:
                    return this.$t('find_pw_err2')
            }
        },
        sendAfter() {
            this.checkGetMail = false;
            this.step = 2;

        },
        setData(key, value) {
            this[key] = value;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
