import { render, staticRenderFns } from "./KakaoLoginComponent.vue?vue&type=template&id=f1a178f6&scoped=true"
import script from "./KakaoLoginComponent.vue?vue&type=script&lang=js"
export * from "./KakaoLoginComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f1a178f6",
  null
  
)

export default component.exports