<template>
    <div>
        <div class="competition_list text_left mt10">
            <div class="competition_top mb30" @click="movePage(`/competition/${data.c_uuid}`)"
                 v-for="(data,index) in competitionData" :key="`competition${index}`" v-if="index<8">
                <div class="competition_result">
                    <div class="profile_img"
                         :style="` background-image: url(${returnImage(data.Poster,278,387, 'png')})`"></div>
                    <div class="competition_sort mt10">{{ returnCategoryName(data.cc_idx) }}</div>
                    <div class="competition_title mt7">{{ data.c_title }}</div>
                    <div class="competition_time mt7">
                        <competition-date-time :competition-data="data"></competition-date-time>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListSearchLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {CompetitionDateTime},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: [],
        paginationData: ''
    },
    data() {
        return {
            itemCategorys: []
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemCategorys = this.itemCategoryList
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Poster)) {
                return `${data.Poster.org_url}?${this.setImageOptions(278, null, this.returnExtension(data.Poster.org_url))}`;
            }
        },
        returnCategoryName(data) {
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === data);
            if (categoryIndex > -1) {
                return this.itemCategorys[categoryIndex].NAME_ENG
            }
        },
        returnImage(image, width = 32, height = 32, f = 'png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
