<template>

  <div>
    <div class="empty_wrap" v-if="finishGetList">
      <div class="img_box"></div>
      <div class="text_box">{{$t('no_result')}}</div>
    </div>
      <div class="empty_wrap" v-else v-loading="!finishGetList" :element-loading-text="''" element-loading-custom-class="dgmong_loading"></div>
  </div>
</template>

<script>
export default {
    name: "ItemEmptyDefaultLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        finishGetList: {default: true},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
