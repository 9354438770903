<template>
    <div>
        ItemListDefaultLayout
    </div>
</template>


<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import imageResizeMixins from "@/mixins/imageResizeMixins";

let timeInterval = null;
export default {
    name: "ItemListDefaultLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {

    },
    destroyed() {
    },
    computed: {

    },
    methods: {
    },
    watch: {},
}
</script>

<style scoped>

</style>
