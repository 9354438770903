const boardTemplateConfig = {
    notice: {
        listLayout: 'BoardListDefaultLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        filterLayout: 'BoardFilterDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    noticeDetail: {
        detailLayout: 'BoardDetailDefaultLayout',
    },
    qna: {
        listLayout: 'BoardListQnaLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        filterLayout: 'BoardFilterQnaLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    qnaDetail: {
        detailLayout: 'BoardDetailQnaLayout',
    },
    qnaCreate: {
        writeLayouts: ['BoardWriteQnaLayout'],
    },
    qnaUpdate: {
        writeLayouts: ['BoardWriteQnaLayout'],
    },
    faq: {
        listLayout: 'BoardListFaqLayout',
        emptyLayout: 'BoardEmptyDefaultLayout',
        filterLayout: 'BoardFilterFaqLayout',
        paginationLayout: 'PaginationDefaultLayout',
    },
    faqDetail: {
        detailLayout: 'BoardDetailFaqLayout',
    },

}

export default {
    methods: {
        returnBoardTemplateConfig(type) {
            return boardTemplateConfig[type];
        }
    }
}
