<template>
    <div>
        <ul class="detection_filter_result_wrap">
            <li class="detection_filter_result_list"  v-for="(detect, index) in detectionData" :key="`url${index}`">
                <div class="detection_filter_result_list_wrap" >
                    <ul class="detection_filter_result_list_tit">
                        <li>
                            {{ $t('ignore_url2') }}
<!--                            무시 도메인-->
                        </li>
                        <li>{{ $t('result_detection') }}</li>
                        <li>{{ $t('reg_date') }}</li>
                    </ul>
                    <ul class="detection_filter_result_list_detail">
                        <li>{{ detect.di_text }}</li>
                        <li>{{ numFormat(detect.di_cnt) }}</li>
                        <li>{{ returnDateFormat(detect.created_at, 'YYYY.MM.DD') }}</li>
                    </ul>
                </div>
                <div class="btn-domain-wrap btn-domain-list-wrap">
                    <button class="btn-domain" @click="openDeleteConfirm(detect.di_idx)">{{ $t('except_cancel') }}</button>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import {detectionDomainStatus} from "@/service/detectionService";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "DetectionListUrlLayout",
    mixins: [dateMixins, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionData: {default: () => []},
        itemData: {default: () => {}},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            selectCollapse:'',
            searchOption: 'permission',
            detectionDomainStatus: detectionDomainStatus,

            clickIgnore: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      openModal(name) {
        this.$modal.show(name);
      },
        movePage(url) {
            this.$router.push(url);
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        openUrl(url) {
            window.open(url, '_blank');
            //location.href= url;
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? 0 : util.Number.numFormat(num);
        },
        openDeleteConfirm(key) {
            this.createConfirm({
                content: this.$t('d_ignore_url_delete_confirm'),
                confirm: () => {
                    EventBus.$emit('getDetectionDelete', key);
                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
