<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap padding_side">
            <div class="mypage_competition_wrap">
                <div class="notice_tabs  ">
                    <button class="notice_item_3_tab two_tab " :class="{ active : tab === 'myCompetition'}"
                            @click="changeTab('myCompetition')" v-if="getMemberType() === 'normal'">
                        {{ $t('my_competition') }}
                    </button>
                    <button class="notice_item_3_tab two_tab border_r_l one_line pl10 pr10" :class="{ active : tab  === 'competitionMy'}"
                            @click="changeTab('competitionMy')" v-if="getMemberType() === 'normal'">
                        {{ $t('my_competition') }} {{ $t('award') }}
                    </button>
                    <button class="notice_item_3_tab two_tab " :class="{ active : tab  === 'entryItem'}"
                            @click="changeTab('entryItem')" v-if="getMemberType() === 'normal'">
                        {{ $t('my_entry_item') }}
                    </button>
                    <button class="notice_item_3_tab two_tab border_r_l "
                            :class="{ active : tab  === 'judge' || getMemberType() !== 'normal'}"
                            @click="changeTab('judge')">
                        {{ $t('judges') }}
                    </button>
                </div>
                <competition-list-component v-if="tab" :competition-type="tab"
                                            :memberKey="returnMemberKey()"></competition-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "MyPageCompetition",
    mixins: [AuthMixins],
    components: {
        ItemListComponent,
        CompetitionListComponent,
        MyPageHeaderComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        if (!util.isEmpty(this.$route.query.tab)) {
            this.tab = this.$route.query.tab;
        } else {
            this.tab = this.getMemberType() !== 'normal' ? 'judge' : 'myCompetition';
        }
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$forceUpdate()
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnMemberKey() {
            return this.tab === "myCompetition" ? this.UserInfo.mb_no : ''
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
