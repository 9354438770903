<template>
    <component :is="component" :paginationData="paginationData"  :listLength="listLength" v-if="component" />
</template>

<script>
export default {
    name: "PaginationLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        paginationLayout: {},
        paginationData: {
            type: Object,
            default: {
                total: 0,
                page: 0,
                size: 0,
            }
        },
        listLength: {
            type: Number,
            default: 0
        },
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
            .then(() => {
                this.component = () => this.loader()
            })
            .catch(() => {
                this.component = () => import('@/template/history/pagination/PaginationDefaultLayout')
            })
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.paginationLayout) {
                return null
            }
            return () => import(`@/template/history/pagination/${this.paginationLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
