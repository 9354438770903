import { render, staticRenderFns } from "./ItemFilterHoldingFilter.vue?vue&type=template&id=93edc2fa&scoped=true"
import script from "./ItemFilterHoldingFilter.vue?vue&type=script&lang=js"
export * from "./ItemFilterHoldingFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93edc2fa",
  null
  
)

export default component.exports