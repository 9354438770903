<template>
    <div class="item_detail_wrap">
        <div class="item_detail_footer">
            <div class="footer_nft_wrap">
                <div class="info_detail">
                    <div class="nft_header_wrap">
                        <h3 class="sub_title_m">{{ $t('nft_history') }}</h3>
                    </div>

                    <div class="empty_wrap" v-if="isEmpty(historyData)">
                        <div class="img_box"></div>
                        <div class="text_box">{{ $t('no_default_list') }}</div>
                    </div>

                    <table class="table_wrap" v-else width="100%" border="0" cellspacing="0" cellpadding="0" v-else>
                        <colgroup>

                            <col style="max-width: 25%; width: 25%;">
                            <col style="max-width: 16.66%; width: 16.66%;">
                            <col style="max-width: 16.66%; width: 16.66%;">
                            <col style="max-width: 16.66%; width: 16.66%;">
                            <col style="max-width: 25%; width: 25%;">
                        </colgroup>
                        <tr height="52" class="t_top">

                            <th scope="col" class="h_t_th">Event</th>
                            <th scope="col" class="h_t_th">Price</th>
                            <th scope="col" class="h_t_th">From</th>
                            <th scope="col" class="h_t_th">To</th>
                            <th scope="col" class="h_t_th">Date</th>
                        </tr>
                        <tr height="55" class="table_content" v-for="history in historyData.slice(0, 5)"
                            :key="`itemNtfHistory${history.inh_idx}`">
                            <td> {{getItemNftStatus(history.inh_status)}} </td>
                            <td>{{ getPrice(history.inh_price) }}</td>
                            <td>{{ isEmpty(history.inh_from) ? '-' : history.inh_from }}</td>
                            <td>{{ isEmpty(history.inh_to) ? '-' : history.inh_to }}</td>
                            <td>{{ returnDateFormat(history.created_at, 'YYYY-MM-DD HH:mm') }}</td>
                        </tr>
                    </table>
                    <div class="number_pagination_wrap">
                        <div class="page_btn_wrap el-page" v-if="maxPage > 1 && total > 0">
                            <button class="first_end" @click="changePage('page',1)" v-if="page !== 1"></button>
                            <button class="previous" @click="changePage('page',page-1)" :disabled="page === 1"
                                    v-if="page !== 1"></button>
                            <el-pagination
                                    :hide-on-single-page=true
                                    :page-size="size"
                                    :current-page.sync="page"
                                    :pager-count="11"
                                    :total="total"
                                    @current-change="changePage('page', page)"
                                    class="page_btn_wrap"
                                    layout="pager"
                            >
                            </el-pagination>
                            <button class="next" @click="changePage('page',page+1)" :disabled="maxPage === page"
                                    v-if="maxPage !== page"></button>
                            <button class="last_end" @click="changePage('page',maxPage)" :disabled="maxPage === page"
                                    v-if="maxPage !== page"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import util from "@/mixins/util";
import priceMixins from "@/mixins/priceMixins";
import dateMixins from "@/mixins/dateMixins";
import historyUtil from "@/components/history/historyUtil";

export default {
    name: "HistoryDetailHistoryLayout",
    mixins: [priceMixins, dateMixins, historyUtil],
    components: {},
    inject: ['setPage'],
    provide() {
        return {}
    },
    props: {
        historyData: {default: () => []},
        paginationData: {default: {page: 1, current_page: 1, last_page: 2, per_page: 10}}
    },
    data() {
        return {
            idx: 1,

            page: 1,
            size: 10,
            maxPage: 0,
            total: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.setInitPageData();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(path) {
            this.$router.push(`${path}/history`);
        },
        isEmpty(data) {
            return util.isEmpty(data);
        },
        returnRecord(history) {
            return util.isEmpty(history) || util.isEmpty(history.Record) ? {} : history.Record;
        },
        getPrice(price) {
            if (util.isEmpty(price)) {
                return '-'
            }
            return this.returnCommas(price);
        },
        setInitPageData() {
            if (this.isEmpty(this.historyData)) {
                return false;
            }
            this.page = this.paginationData.current_page;
            this.size = this.paginationData.per_page;
            //this.maxPage = Math.ceil(this.historyData.length / this.size);
            this.maxPage = this.paginationData.last_page;
            this.total = this.paginationData.last_page * this.paginationData.per_page;
        },
        changePage(type, value) {
            this[type] = value;
            this.setPage(type, value);
        },
        returnNFTTo(data) {
            let history = this.returnRecord(data)
            if (util.isEmpty(history)) {
                return
            }
            if (history.user !== 0) {
                return history.ownerNick
            } else {
                return history.nftOwner
            }

        }
    },
    watch: {
        'historyData': {
            deep: true,
            handler: function (val, oldVal) {
                this.setInitPageData();
            }
        },
    },
}
</script>

<style scoped>

</style>
