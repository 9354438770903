<template>
    <div class="list_wrap">
        <div class="creator_list">
            <div class="creator_top mb6" v-for="(data,index) in memberData" :key="`judge${index}`"
                 @click="judgeConfirm(data)">
                <div class="creator_img">
                    <div class="profile_background" :style="` background-image: url(${returnThumbnail(data)});`"></div>
                    <div class="curator_icon" v-if="data.g_idx === 2">{{ $t('en_curator') }}</div>
                </div>
                <div class="profile_info">
                    <div class="profile_img" :style="` background-image: url(${returnProfile(data)});`"></div>
                    <div class="profile_s_mark" v-if="data.mb_premium === 1"></div>
                    <div class="profile_text">{{ data.mb_nick }}</div>
                    <div class="creator_like">
                        <img src="@/assets/image/heart_img.png">
                        <div class="like_cnt">{{ numFormat(data.likeCnt) }}</div>
                        <div class="sub_point">.</div>
                        <div class="item_subject">Item <span class="bold">{{ numFormat(data.itemCnt) }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberListJudgeLayout",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            // memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        judgeConfirm(data) {
            let dataArr = data;
            if (data.mb_nick === this.UserInfo.mb_nick) {
                this.errorAlert(`${this.$t('do_not_myself')}`);
                return false;
            }
            this.createConfirm({
                content: `${this.$t('judgeConfirm', {name: dataArr.mb_nick})}`,
                cancelText: this.$t('cancel'),
                confirmText: this.$t('yes'),
                confirm: () => {
                    let judgeData =
                            {profile: dataArr.Files.Profile, mb_nick: dataArr.mb_nick, mb_no: dataArr.mb_no};

                    EventBus.$emit('judgeSelect', judgeData);
                    this.modalClose();
                },
                cancel: () => {

                }
            });
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        numFormat(num) {
            if (util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnThumbnail(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.ProfileBack)) {
                return "";
            }
            return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, 'jpg')}`;
        },
        returnProfile(data) {
            if (util.isEmpty(data.Files) || util.isEmpty(data.Files.Profile)) {
                return "";
            }
            return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, 'png')}`;
        },
        modalClose() {
            this.$modal.hide('judge-search-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
