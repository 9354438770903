<template>
<div>
    EntryListSearchLayout
</div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
export default {
  name: "EntryListSearchLayout",
  mixins: [alertMixins],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    confirmCharge(){
      this.createConfirm({
        content : this.$t('confirm_charge'),
        confirmText : this.$t('yes'),
        cancelText : this.$t('cancel'),
        confirm: () => {
          this.resultCharge();
        },
        cancel: () => {

        }
      });
    },
    confirm() {
      this.createConfirm(
          {
            content: this.$t('entry_confirm_text'),
            confirmText: this.$t('yes'),
            cancelText: this.$t('cancel'),
            confirm: () => {

            },
            cancel: () => {

            }})
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>