<template>
    <div class="dgm_wrap padding_side mb30">
        <div class="transaction_history">
            <div class="t_h_table">
                <table height="190" width="100%">
                    <!--              <colgroup>
                                    <col style="max-width: 25%; width: 25%;">
                                    <col style="max-width: 75%; width: 75%;">
                                  </colgroup>-->
                    <tr>
                        <td width="30%" class="table_text">{{ $t('n_history') }}</td>
                        <td width="70%" class="table_information">{{ historyData.Buyer.pm_order }}</td>
                    </tr>
                    <tr width="100%">
                        <td width="30%" class="table_text">{{ $t('d_history') }}</td>
                        <td width="70%" class="table_information">{{ historyData.Buyer.created_at }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('t_history') }}</td>
                        <td width="70%" class="table_information">{{ checkSellType(historyData.Buyer.pm_type) }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('market') }}</td>
                        <td width="70%" class="table_information">{{ checkMarket(historyData.Buyer.pm_status) }}</td>
                    </tr>
                </table>
            </div>

            <div class="t_h_title mt33 mb16">
                {{ historyData.m_bid_nick }}{{ $t('sir') }}
            </div>
            <div class="table_title mb18">
                {{ $t('r_history') }}
            </div>
            <div class="t_h_table">
                <table height="143" width="100%">
                    <tr>
                        <td width="30%" class="table_text">{{ $t('seller') }}</td>
                        <td width="70%" class="table_information">{{ historyData.m_nick }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('buyer') }}</td>
                        <td width="70%" class="table_information">{{ historyData.m_bid_nick }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('curator') }}</td>
                        <td width="70%" class="table_information" v-if="checkCurator(historyData)">
                            {{ historyData.m_curator_nick }}
                        </td>
                        <td width="30%" class="table_information" v-else>-</td>
                    </tr>
                </table>
            </div>
            <div class="table_title mb18 mt32">
                아이템
            </div>
            <div class="t_h_table">
                <table height="190" width="100%">
                    <tr>
                        <td width="30%" class="table_text">{{ $t('i_name') }}</td>
                        <td width="70%" class="table_information">{{ historyData.i_name }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('i_num') }}</td>
                        <td width="70%" class="table_information">{{ historyData.i_sku }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('type') }}</td>
                        <td width="70%" class="table_information">{{ checkType(historyData) }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('category') }}</td>
                        <td width="70%" class="table_information">{{ returnCategoryName(historyData) }}</td>
                    </tr>
                </table>
            </div>
            <div class="table_title mb18 mt32">
                거래금액
            </div>
            <div class="t_h_table">
                <table height="143" width="100%">
                    <tr>
                        <td width="30%" class="table_text">{{ $t('a_history') }}</td>
                        <td width="70%" class="table_information">{{ historyData.i_price }}Mg</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('s_tax') }}</td>
                        <td width="70%" class="table_information">{{ returnFees(historyData) }}</td>
                    </tr>
                    <tr>
                        <td width="30%" class="table_text">{{ $t('f_income') }}</td>
                        <td width="70%" class="table_information">{{ returnPrice(historyData) }}Mg</td>
                    </tr>
                </table>
            </div>
            <div class="table_title mb18 mt32">
                비고
            </div>
            <div class="t_h_table">
                <table height="66" width="100%">
                    <tr>
                        <td width="70%" class="table_information">
                            {{ returnBigo(historyData) }}
                        </td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";
import util from "@/mixins/util";

export default {
    name: "HistoryDetailBuyerLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        historyData: {}
    },
    data() {
        return {
            memberType: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        checkSellType(data) {
            if (data === 1) {
                return this.$t('ownership_buy')
            } else if (data === 2) {
                return this.$t('ownership_sell')
            } else if (data === 3) {
                return this.$t('license_sell')
            } else if (data === 4) {
                return this.$t('buy_license')
            }
        },
        checkMarket(data) {
            if (data === 0) {
                return this.$t('openMarket')
            } else if (data === 1) {
                return this.$t('premiumMarket')
            }
        },
        checkType(data) {
            if (data.i_type === 'real') {
                return this.$t('real')
            } else {
                return this.$t('digital')
            }
        },
        returnCategoryName(data) {
            if (data.node_id === 2) {
                return 'Art'
            } else if (data.node_id === 3) {
                return 'Photo'
            } else if (data.node_id === 4) {
                return 'Video'
            } else if (data.node_id === 5) {
                return 'Music'
            } else if (data.node_id === 9) {
                return 'Collectibles'
            } else if(data.node_id === 178){
                return 'Prompt'
            }

        },
        checkCurator(data) {
            return !util.isEmpty(data.m_curator)
        },
        returnPrice(data) {
            return Number(data.Buyer.p_point) * -1;
        },
        returnFees(data) {
            if (util.isEmpty(data.Buyer.pm_fees)) {
                return '-'
            } else {
                let fees = (data.Buyer.pm_fees * 100) / data.i_price;
                if (data.Buyer.pm_fees == 0) {
                    fees = 0;
                }
                return `+${data.Buyer.pm_fees}Mg (${fees}%)`
            }
        },
        returnBigo(data) {
            if (data.m_first_order === 1) {
                if (data.Seller.pm_status === 1) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg4')
                    } else if (data.i_type == 'digital') {
                        return this.$t('feesMsg1')
                    }
                } else if (data.Seller.pm_status === 0) {
                    if (data.i_type === 'real') {
                        return this.$t('no3')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                }
            } else if (data.m_first_order === 0) {
                if (data.Seller.pm_status === 1) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg4')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                } else if (data.Seller.pm_status === 0) {
                    if (data.i_type === 'real') {
                        return this.$t('feesMsg10')
                    } else if (data.i_type == 'digital') {
                        return this.$t('no3')
                    }
                }
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
