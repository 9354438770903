<template>
    <modal name="nickname-change-modal"
           class="nickname-change-modal"
           :pivotX="0.5"
           :pivotY="0.36"
           :adaptive="true"
           :scrollable="true"
           :clickToClose="false"
           :width="'90%'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="update-change-modal_wrap">
                <div class="nickname_title mb25">
                    {{$t('nick_change')}}
                </div>
                <div class="rollback_btn" @click="closeModal()"></div>
                <div class="clear"></div>

                <member-write-component :member-type="'updateNick'"></member-write-component>

                <button class="two_btn back_gray" @click="closeModal()">{{$t('cancel')}}</button>
                <button class="two_btn back_navy" @click="save()">{{$t('save')}}</button>
                <div class="clear"></div>
            </div>
        </div>
    </modal>

</template>

<script>
import EventBus from "@/utils/event-bus";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "NicknameChangeModal",
    mixins: [],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('nickname-change-modal');
        },
        handlerBeforeOpen(event) {
        },

        handlerOpened() {
        },

        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },
        movePage(url) {
            this.$router.push(url);
        },
        save(){
            EventBus.$emit('updateMember')
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
