<template>
    <div>
        <div class="detail_img_wrap">
            <div class="b_img_wrap" :style="`background-image: url(${returnThumbnail(mainImg)})`">
                <div class="real_card" v-if="entryData.i_type === 'real'">{{ $t('real') }}</div>
            </div>
            <swiper class="s_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                    :slidesPerView="'auto'" @click="onClick"
                    @slideChange="slideChange">
                <swiper-slide class="img_wrap">
                    <div class="img_box">
                        <img :src="returnSmallThumbnail(orgItemImg)">
                    </div>
                </swiper-slide>
                <swiper-slide class="img_wrap"
                              v-for="(data,index) in thumnailImgs"
                              :key="`detail${index}`"
                              v-if="data.org_name != 'no_image.png'">
                    <div class="img_box">
                        <img :src="returnSmallThumbnail(data.org_url)">
                    </div>
                </swiper-slide>
            </swiper>
        </div>

    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import util from "@/mixins/util";

export default {
    name: "ItemDetailEntrySlideLayout",
    mixins: [imageResizeMixins],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 3,
                grabCursor: true,
            },
            entryData: {},
            swiperOption2: {
                slidesPerView: 1.77,
                spaceBetween: 3,
                grabCursor: true,
            }
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initItemData();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initItemData() {
            console.log(123);
            this.entryData = !util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('Item') && !util.isEmpty(this.itemData.Item) ? this.itemData.Item[0] : {}
            if (!util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile.Detail)) {
                this.thumnailImgs = this.entryData.ItemFile.Detail;
                this.orgItemImg = this.entryData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            console.log(elementSrc);
            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>