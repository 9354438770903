<template>
    <div>
        <div class="i_status_img_box">
            <img :src="returnItemImg(itemData)"/>
        </div>
        <div class="i_status_inform_box">
            <ul>
                <li>
                    <h3>{{ $t('type') }}</h3>
                    <div class="left_text">{{ returnItemType(itemData.type) }}</div>
                </li>
                <li>
                    <h3>{{ $t('category') }}</h3>
                    <div class="left_text">{{ returnCategoryName(itemData.category) }}</div>
                </li>
                <li>
                    <h3>{{ $t('i_name') }}</h3>
                    <div class="left_text">{{ itemData.title }}</div>
                </li>
                <li v-if="itemData.isEdition === 1">
                    <h3>{{ $t('edition') }}</h3>
                    <div class="left_text" v-if="itemData.type === 'real'">{{ itemData.editionCount }} of
                        {{ itemData.editionTotal }}
                    </div>
                    <div class="left_text" v-else> {{ itemData.editionCount }} of {{ itemData.editionTotal }}</div>
                </li>
                <li>
                    <h3>{{ $t('certi') }}</h3>
                    <div class="left_text file_name" v-for="(data,index) in guaranteeFile"
                         :key="`guranty${index}`"
                         @click="awsS3Download('guaranty', data, itemData.i_idx)"> {{ data.org_name }}
                    </div>
                </li>
                <li v-if="originalFile.length > 0">
                    <h3>{{ $t('f_real') }}</h3>
                    <div class="left_text file_name"
                         @click="awsS3Download('original', originalFile[0],itemData.i_idx,true)">
                        {{ originalFile[0].a_filename }}
                    </div>
                </li>

                <li v-if="checkOriginal()">
                    <h3>{{ $t('k_creator') }}</h3>
                    <img :src="returnProfile(originatorProfile)"/>
                    <div class="left_text" style="line-height: 25px;   vertical-align: top; ">
                        {{ itemData.originatorNick }}
                    </div>
                </li>

                <li class="detail_info_box">
                    <h3>{{ $t('detail_info') }}</h3>
                    <ul v-if="exifType==='photo'">
                        <li>
                            <h4>{{ $t('t_video2') }}</h4>
                            <div v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_country2') }}</h4>
                            <div v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_si2') }}</h4>
                            <div v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_city2') }}</h4>
                            <div v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('size2') }}</h4>
                            <div v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('copyright_info2') }}</h4>
                            <div v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</div>
                        </li>
                    </ul>
                    <ul v-if="exifType==='video'">
                        <li>
                            <h4>{{ $t('t_video2') }}</h4>
                            <div v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_country2') }}</h4>
                            <div v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_si2') }}</h4>
                            <div v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_city2') }}</h4>
                            <div v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_long') }}</h4>
                            <div v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('copyright_info2') }}</h4>
                            <div v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</div>
                        </li>
                    </ul>
                    <ul v-if="exifType==='audio'">
                        <li>
                            <h4>{{ $t('t_record') }}</h4>
                            <div v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_country') }}</h4>
                            <div v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_si') }}</h4>
                            <div v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_city') }}</h4>
                            <div v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('v_long') }}</h4>
                            <div v-if="!checkExifEmpty('size')"> {{ exif.size.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('copyright_info2') }}</h4>
                            <div v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</div>
                        </li>
                    </ul>
                    <ul v-if="exifType==='etc'">
                        <li>
                            <h4>{{ $t('t_video2') }}</h4>
                            <div v-if="!checkExifEmpty('create')"> {{ exif.create.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_country') }}</h4>
                            <div v-if="!checkExifEmpty('country')"> {{ exif.country.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_si') }}</h4>
                            <div v-if="!checkExifEmpty('si')"> {{ exif.si.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('c_city') }}</h4>
                            <div v-if="!checkExifEmpty('city')"> {{ exif.city.value }}</div>
                        </li>
                        <li>
                            <h4>{{ $t('copyright_info2') }}</h4>
                            <div v-if="!checkExifEmpty('copyright')"> {{ exif.copyright.value }}</div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {mapState} from "vuex";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemInfoViewLayout",
    mixins: [imageOption, awsS3FileDownload, imageResizeMixins],
    components: {},
    inject: ['setVueItemData'],
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setCategoryData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            validatorArr: [],
            guaranteeFile: [],
            thumbnailFile: [],
            originalFile: [],
            originatorProfile: [],
            category: '',
            exif: {},
            exifType: '',

            categoryObj: {},
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();

        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.guaranteeFile = this.$_.cloneDeep(this.itemData.guaranteeFile);
            this.thumbnailFile = this.$_.cloneDeep(this.itemData.thumbnailFile)
            this.originalFile = this.$_.cloneDeep(this.itemData.ntfFile)
            this.originatorProfile = this.$_.cloneDeep(this.itemData.originatorProfile)
            this.category = this.itemData.category;
            this.exifType = this.itemData.exifType;
            this.exif = this.$_.cloneDeep(this.itemData.exif)
            this.setVueItemData('itemClass', this.itemClass);
            this.setVueItemData('itemData', this.itemData);
            this.setCategory(this.category)
        },
        setCategory(value) {
            this.parentChange('category', value);
            let index = this.itemCategoryList.findIndex(cate => cate.PKEY == value);
            if (index > -1) {
                let categoryData = this.itemCategoryList[index];
                this.categoryObj = categoryData;
                this.setCategoryData(categoryData)
            }
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        returnItemImg() {
            if (!util.isEmpty(this.thumbnailFile)) {
                return this.setFitWidthImg(this.thumbnailFile, 730)
                // return this.thumbnailFile[0].org_url;
            }
        },
        checkIsDelete() {
            if (util.isEmpty(this.itemClass)) {
                return false;
            }
            return this.itemClass.isDeleteItem(this.itemData);
        },
        returnItemType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        returnCategoryName() {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return this.categoryObj.NAME
            }
            return this.categoryObj.NAME_ENG
        },
        checkOriginal() {
            return !util.isEmpty(this.itemData.originatorNick)
        },
        returnProfile(data) {
            if (!util.isEmpty(this.originatorProfile)) {
                return `${this.originatorProfile[0].org_url}?${this.setImageOptions(25, 25, 'png')}`
            }
        },
        checkExifEmpty(type) {
            return !this.itemData.exif.hasOwnProperty(type) && util.isEmpty(this.itemData.exif[type].value);
        },

        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('caution_del_item')}<br>${this.$t('caution_del')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.deleteItem();
                },
                cancel: () => {
                }
            })
        },
        async deleteItem() {
            EventBus.$emit('apiLoading', true);
            let result = await this.itemClass.deleteItem(this.itemData.i_idx);
            if (result) {
                this.deleteItemFinish();
            } else {
                this.errorAlert(this.$t('deleteErrorMsg'));
            }
            EventBus.$emit('apiLoading', false);
        },
        deleteItemFinish() {
            this.createAlert({
                title: '',
                content: this.$t('deleteSuccessMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    this.$router.back();
                },
            })
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
