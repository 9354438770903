<template>
    <div>
        <div v-if="isEmptyPortfolio()" class="empty_wrap">
            <div class="img_box"></div>
            <div class="text_box">{{$t('empty_portfolio')}}</div>
        </div>
        <div class="content_box editor mr14 ml14 ql-editor" v-else v-html="memberData.mb_portfolio"></div>
    </div>
</template>

<script>

import util from "@/mixins/util";

export default {
    name: "MemberDetailPortfolioLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
      movePage(url) {
        this.$router.push(url);
      },
        isEmptyPortfolio() {
            return util.isEmpty(this.memberData.mb_portfolio)
        },
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
