import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import util from "@/mixins/util";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);

export default {
    data() {
        return {
            title: '',
            category: '',
            type: 'digital',
            isEdition: 0,
            editionCount: 0,
            editionTotal: 0,
            isGuarantee: 0,
            guaranteeFile: [],
            thumbnailFile: [],
            originalFile: [],
            videoStreamFile: [],
            originator: 1,
            detailFile: [],
            isCheckAdult: 0,
            hashtag: [],
            hashtahInput: [],
            information: '',
            exifType: '',
            exif: {},
            collect: '',

            status: 9,

            //경매 관련
            minimumSetting: 0,
            minimum: '',
            immediatelySetting: 0,
            immediately: '',
            startDate: '',
            startDateStatus: 0,
            endDate: '',
            endDateStatus: 0,
            //라이센스 관련
            smallType: false,
            mediumType: false,
            largeType: false,
            smallPrice: '',
            mediumPrice: '',
            largePrice: '',
            originalPrice: '',
            caution: '',
            collectOwner: '',
            aiCreate:0
        }
    },
    validators: {
        title: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg1'))
        },
        category: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg3'))
        },
        type: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg1'))
        },
        exifType: function (value) {
            return Validator.value(value)
        },
        editionCount: function (value) {
            return Validator.value(value)
                    // .required(this.$t('itemErrorMsg4'))
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg4'))
                    .custom(() => {
                        if (this.isEdition === 1 && this.type === 'real' && (value * 1 > this.editionTotal * 1 && this.editionTotal)) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        editionTotal: function (value) {
            return Validator.value(value)
                    // .required(this.$t('itemErrorMsg5'))
                    .regex('^[0-9|]+$', this.$t('itemErrorMsg5'))
                    .custom(() => {
                        if (this.isEdition === 1 && value < 1) {
                            return this.$t('itemErrorMsg5')
                        }
                        if (this.isEdition === 1 && (value * 1 < this.editionCount * 1 && this.editionCount)) {
                            return this.$t('itemErrorMsg6')
                        }
                    })
        },
        guaranteeFile: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.isGuarantee === 1 && value.length === 0) {
                            return this.$t('itemErrorMsg17')
                        }
                    })
        },
        thumbnailFile: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg15'))
        },

        originalFile: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.type === 'digital' && value.length === 0) {
                            return this.$t('itemErrorMsg16')
                        }
                    })
        },
        hashtag: function (value) {
            return Validator.value(value)
        },

        minimum: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.minimumSetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }

                            }
                    )

        },
        immediately: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.immediatelySetting === 1) {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg9')
                                    }

                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }

                                    if (this.minimumSetting === 1 && value <= this.minimum) {
                                        return this.$t('auctionErrorMsg');
                                    }

                                }
                            }
                    )
        },
        startDate: function (value) {
            return Validator.value(value)
                    .custom(() => {
                        if (this.startDateStatus === 1 && util.isEmpty(this.startDate)) {
                            return this.$t('itemErrorMsg12')
                        }
                    })
        },
        endDate: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg13'))
                    .custom(
                            () => {
                                if (value < this.startDate) {
                                    return this.$t('itemErrorMsg14')
                                }
                            }
                    )
        },

        smallPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.smallType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        mediumPrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.mediumType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        largePrice: function (value) {
            return Validator.value(value)
                    .custom(
                            () => {
                                if (this.largeType && this.exifType === 'photo') {
                                    if (value === '' || Number(value) === 0) {
                                        return this.$t('itemErrorMsg19')
                                    }
                                    let str = String(value);
                                    let last = String(str.substring(str.length - 2, str.length))
                                    if (last !== '00') {
                                        return this.$t('Mg_msg')
                                    }
                                }
                            }
                    )
        },
        originalPrice: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg19'))
                    .custom(() => {
                        if (value === '' || Number(value) === 0) {
                            return this.$t('itemErrorMsg19')
                        }

                        let str = String(value);
                        let last = String(str.substring(str.length - 2, str.length))
                        if (last !== '00') {
                            return this.$t('Mg_msg')
                        }
                    })
        },
        collect: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
        },
        collectOwner: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
                    .custom(() => {
                        if (value === 0 && this.status !== 9) {
                            return this.$t('collect_select_error2')
                        }
                    })
        },
        information: function (value) {
            return Validator.value(value)
                    .custom(()=>{
                        if(this.category == 178 && util.isEmpty(value)){
                            return this.$t('itemInfoEmptyMsg')
                        }
                    })
        },
        aiCreate: function (value) {
            return Validator.value(value)
        },
    }
}
