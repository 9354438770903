<template>
    <div class="award_write_wrap">
        <div class="ac_content_wrap">
            <ul>
                <li class="prize_wrap">
                    <h3 class="sub_title_m regular">{{ $t('t_prize') }}</h3>
                    <div class="prize_box">
                        <div class="amount_wrap">
                            <div class="mg_icon"></div>
                            <div class="amount">{{ competitionPrize }}</div>
                            <div class="mg">Mg</div>
                        </div>
                    </div>
                    <div class="clear"></div>
                </li>

                <li class="share_prize_wrap">
                    <h3>{{ $t('share_prize') }}</h3>
                    <div class="content_wrap">
                        <h3>{{ $t('range_prize') }}</h3>
                        <div class="w_content_box box_padding">
                            <el-select id="" class=" select"
                                       popper-class="select_style"
                                       :popper-append-to-body="false"
                                       v-model="sharePrize"
                                       @change="initPrizeArray()"
                            >
                                <el-option :value="data" v-for="data in 10" :key="data">
                                </el-option>
                            </el-select>
                        </div>
                    </div>
                </li>

                <li class="rank_wrap" v-for="(item,index) in sharePrize"
                    :key="`award-item-${item}`">
                    <h3>{{ $t('award') }}</h3>
                    <div class="content_wrap">
                        <div class="content_box">
                            <!--                            <h3 v-html="$('num_place', {item : '{{ item }}'})"></h3>-->
                            <h3>{{ item }}{{ $t('th') }}</h3>
                            <div class="item_name_content" v-if="prizeArray[index].name !== ''">
                                {{ prizeArray[index].name }}
                                <button class="close_btn" @click="deleteEl(index)"></button>
                            </div>
                            <button @click="openSearchModal(index)" v-else>{{ $t('matching_entry') }}</button>
                        </div>
                        <div class="content_box">
                            <h3>{{ $t('prize') }}</h3>
                            <el-input v-model="prizeArray[index].prize" :placeholder="$t('write_prize')" type="tel"
                                      @input="setPrice(index,$event)"></el-input>
                        </div>
                        <div class="content_box">
                            <h3>{{ $t('awardResult') }}</h3>
                            <el-input v-model="prizeArray[index].result" type="textarea"
                                      :placeholder="$t('write_award_result')"
                                      @input="parentChange(`prizeArray[${index}].result`,prizeArray[index].result)"></el-input>
                        </div>
                    </div>
                </li>
                <div class="val_cap" v-if="validation.hasError('prizeArray') && this.hasError">
                    {{ validation.firstError('prizeArray') }}
                </div>
            </ul>

            <div class="i_create_footer">
                <div class="create_footer_check">
                    <div class="check_wrap">
                        <el-checkbox v-model="termsCheck" @click="changeCheck"></el-checkbox>
                        <!--                        <span v-html="$t('award_terms')"></span>-->
                        <span class="item_text_span bold"
                              @click="openModal('terms-modal', 'contestWin')">{{ $t('s_award_terms') }}</span>
                        <span class="item_text_span" v-html="$t('agree_terms2')"></span>
                    </div>
                </div>
                <div class="btn_wrap">
                    <div class="save_btn one_btn" @click="save()">{{ $t('save') }}</div>
                </div>
                <div class="val_cap" v-if="termsMsg"><p>{{ $t('caption_check') }}</p></div>
            </div>

        </div>
    </div>
</template>

<script>

import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import awardValidator from "@/mixins/validators/awardValidator";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "AwardWriteDefaultLayout",
    mixins: [awardValidator, awardValidator, priceMixins],
    components: {},
    inject: ['awardWriteSetData'],
    provide() {
        return {}
    },
    props: {
        competitionKey: 0,
        competitionPrize: 0
    },
    data() {
        return {
            prize: '',
            termsCheck: false,
            termsMsg: false,
            hasError: false,
            price: '',
            beforePrize: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getBeforePrize();
    },
    mounted() {
// this.inputAutoWidth();
        this.initPrizeArray()
        EventBus.$on('awardSetData', this.setModalData)
        EventBus.$on('AwardWriteDefaultLayoutValidator', this.checkValidator)
    },
    beforeDestroy() {
        EventBus.$off('awardSetData')
        EventBus.$on('AwardWriteDefaultLayoutValidator')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getBeforePrize() {
            let params = {
                cuid: this.competitionKey,
            }
            try {
                this.$api.$competition.getAward(params).then(res => res.Data.Result).then(res => {
                    console.log(res);
                    if (!util.isEmpty(res.List)) {
                        this.beforePrize = res.List;
                        this.sharePrize = this.beforePrize.length;
                        this.initPrizeArray();
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        openSearchModal(idx) {
            let selectArray = []
            this.prizeArray.filter((v) => {
                if (v.idx !== 0) {
                    selectArray.push(v.idx)
                }
            })
            this.$modal.show('entry-search-modal', {
                competitionKey: this.competitionKey,
                idx: idx,
                selectArray: selectArray
            });
        },
        openModal(name, type) {
            this.$modal.show(name, {termsType: type})
        },

        changeCheck() {
            this.termsCheck = !this.termsCheck;
        },
        save(type) {
            if (!this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit('writeAward');
        },
        initPrizeArray() {
            let num = this.sharePrize
            // if(!util.isEmpty(this.beforePrize)) {
            //     num = this.beforePrize.length;
            //     this.sharePrize = num;
            // }
            let list = []
            for (let i = 0; i < num; i++) {
                list[i] = {
                    idx: 0,
                    prize: 0,
                    result: '',
                    rank: i + 1,
                    name: ''
                }
                if (!util.isEmpty(this.beforePrize[i])) {
                    list[i].idx = this.beforePrize[i].ci_idx;
                    list[i].prize = this.beforePrize[i].ci_prize;
                    list[i].result = this.beforePrize[i].ci_result;
                    list[i].rank = this.beforePrize[i].ci_rank;
                    list[i].name = this.beforePrize[i].Item.i_name;
                }
            }
            this.prizeArray = [...list]
            this.parentChange('prizeArray', this.prizeArray)
            this.parentChange('sharePrize', this.sharePrize)
        },
        setModalData(arrIdx, idx, name) {
            this.prizeArray[arrIdx].idx = idx
            this.prizeArray[arrIdx].name = name
            this.hasError = false
        },
        deleteEl(index) {
            this.prizeArray[index].name = ''
            this.prizeArray[index].idx = 0
        },
        parentChange(key, value) {
            this.hasError = false
            this.awardWriteSetData(key, value)
        },

        setPrice(index, value) {
            let number = util.Number.returnNumber(value);
            this.prizeArray[index].prize = number;
            if (number > this.competitionPrize) {
                this.prizeArray[index].prize = util.Number.numFormat(this.competitionPrize)
                return;
            }
            if (number > this.maxPrice) {
                this.prizeArray[index].prize = util.Number.numFormat(this.maxPrice)
                return
            }
            this.prizeArray[index].prize = util.Number.numFormat(number)
        },
        checkValidator(dataArr) {
            this.hasError = true

            this.$validate(dataArr)
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
