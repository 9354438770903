<template>
    <div class="item_detail_popup_wrap" @click="moveMember(`/creator/${memberData.mb_uuid}`)">
        <div>
            <div class="profile_info_wrap">
                <div class="profile_img" :style="`background-image: url(${returnMemberImg()});`"></div>

                <div class="profile_card">
                    <div class="curator" v-if="memberData.g_idx=== 2">Curator</div>
                    <div class="special" v-if="memberData.mb_premium===1">Special</div>
                </div>

                <div class="nickname">{{ memberData.mb_nick }}</div>

                <div class="p_r_middle_content">
                    <div class="heart_box">
                        <img src="@/assets/image/s_heart.png">
                        <span>{{ memberData.likeCnt }}</span>
                    </div>

                    <div class="item_box">
                        <div class="item_text">Item</div>
                        <span>{{ memberData.itemCnt }}</span>
                    </div>
                </div>

            </div>
            <div class="profile_description">
                {{ memberData.mb_introduce }}
            </div>
        </div>


    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";


export default {
    name: "MemberDetailItemPopupLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {
            default: () => {
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        moveMember(url) {
            this.$router.push(url);
        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(144, 144, 'png')}`
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
