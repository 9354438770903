<template>
  <div class="unsubscribe_result_wrap">

      <div class="u_r_img mb33">
        <img src="@/assets/image/main_logo.png">
      </div>
      <div class="u_r_content">
        {{ $t('unsub_done') }}
      </div>
    <div class="u_r_content">
      {{ $t('thanks_ming') }}
    </div>
      <div class="u_r_btn mt90">
        <button class="one_btn" @click="$router.push('/')">{{ $t('to_home') }}</button>
      </div>
    </div>


</template>

<script>
import alertMixins from "@/mixins/alertMixins";

export default {
  name: "UnsubscribeResultComponent",
  mixins: [alertMixins],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>
