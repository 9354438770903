<template>
    <div class="padding_side">
        <div class="winner_select border-top pt12 pb12" v-if="isOrganizer">
            <button class="w_s_text" @click="movePage(`/award/${contestKey}/write`)">
                <img src="@/assets/image/check_icon.png">
                <span>{{ $t('s_award') }}</span>
            </button>
            <div class="clear"></div>
        </div>
        <div class="item_premium_list_wrap pa0">
            <div class="item_premium_list" v-for="entry in competitionData" :key="`${entry.ci_idx}`"
                 @click.stop="moveDetail(entry.ci_idx)">
                <div class="img_wrap">
                    <div class="img_bg"  style="height: 100%; background-position: 50%; max-height: unset; background-size: contain"
                         :style="`background-image : url(${setFitWidthImg(entry.Item.ItemFile.Item, 478)})`"></div>
                </div>

                <div class="content_wrap">
                    <div class="subject_wrap competition_subject">{{ entry.Item.i_name }}
                    </div>
                    <div class="content_middle mb12">
                        <ul class="middle_thumb">
                            <li class="thumb_wrap competition_thumb">
                                <img :src="returnImage(entry.Profile.Profile,30,30)" alt="">
                            </li>
                        </ul>
                        <div class="c_t_nickname">
                            {{ entry.Item.originator_nick }}
                        </div>
                    </div>
                    <div class="content_bottom pb10 pt10">
                        <img src="@/assets/image/icon_vote.png" @click="voteItem(entry.ci_idx,$event)">
                        {{ returnCommas(entry.ci_vote_cnt) }}({{
                            returnPercentage(entry.ci_vote_cnt, entry.totalVote)
                        }}%)
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {mapState} from "vuex";

export default {
    name: "CompetitionListEntryLayout",
    mixins: [imageOption, alertMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => {
                return []
            }
        }
    },
    data() {
        return {
            idx: 1,
            entryItemLists: 12,
            contestKey: '',
            organizer: this.$route.params.mb_no,
            contestData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getContest();
        this.contestKey = this.$route.params.cid
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
        isOrganizer() {
            return this.UserInfo.mb_uuid === this.organizer
        },
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        getContest() {
            if (util.isEmpty(this.$route.params.cid)) {
                return false;
            }
            let params = {
                //id: this.$route.params.cid,
                cuid: this.$route.params.cid,
            }
            try {
                this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                    if (util.isEmpty(res.List)) {
                        return false;
                    }
                    this.contestData = res.List[0];
                })
            } catch (e) {
                console.log(e);
            }
        },
        moveDetail(data) {
            let path = this.$route.path
            this.$router.push(`${path}/${data}`);
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        returnImage(image, width = 32, height = 32, f = 'png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
            }
            return ''
        },
        returnOrgImage(image) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}`
            }
            return "";
        },
        voteItem(ci_idx, e) {
            e.stopPropagation()
            if (util.isEmpty(this.contestData) || this.contestData.c_status != 1) {
                this.errorAlert(this.$t('vote_err_date'))
                return false;
            }
            e.stopPropagation()
            EventBus.$emit('voteItem', ci_idx)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
