<template>
    <modal name="gallery-setting-modal"
           class="gallery-setting-modal"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
           :pivotX="0.5"
           :pivotY="0.5"
           :adaptive="true"
           :scrollable="true"
           :width="'100%'"
           :height="'100%'"
    >
        <div>
            <page-header :header-title="$t('gallery_setting')">
                <button slot="headerBtns" class="h_x_btn" @click="modalClose"></button>
            </page-header>
            <gallery-write-component v-if="showComponent" :gallery-type="galleryType" :galleryKey="galleryKey"></gallery-write-component>
        </div>
    </modal>

</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";
import util from "@/mixins/util";

export default {
    name: "GallerySettingModal",
    mixins: [alertMixins],
    components: {
        GalleryWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {

    },
    data() {
        return {
            showComponent: false,
            galleryKey: 0,
            galleryType: 'galleryAdd',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.galleryKey)) {
                this.galleryType = 'galleryAdd';

            }else{
                this.galleryKey = event.params.galleryKey;
                this.galleryType = 'galleryUpdate';
            }
            this.showComponent = true;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.galleryKey = 0;
        },
        modalClose() {
            this.$modal.hide('gallery-setting-modal');
        },
        returnUrl(){
            return this.url
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
