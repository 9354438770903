<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header">
<!--            <button class="h_add_deposit" @click="$router.push('/mypage/deposit')" slot="headerBtns">{{$t('deposit_btn')}}</button>-->
        </page-header>
        <div class="padding_side dgm_wrap">
            <div class="mypage_point_wrap">
                <div class="notice_tabs">
                    <button class="notice_item_3_tab active two_tab">
                        {{ $t('mingle_status') }}
                    </button>
                    <button class="notice_item_3_tab two_tab border_r_l" @click="movePage('history?backUrl=point')">
                        {{ $t('usage_history') }}
                    </button>
                </div>
                <point-status-component :point-type="'pointStatus'"></point-status-component>

                <div class="">
<!--                    <button class="two_btn btn_border" @click="checkAuthCertification()">{{ $t('deposit_btn') }}</button>-->
<!--                    <button class="two_btn btn_border" @click="movePage('charge')">{{ $t('charge_mingle') }}</button>-->
<!--                    <button class="two_btn back_navy" @click="movePage('withdraw')">{{ $t('request_withdraw') }}</button>-->
                    <div class="clear"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import PointStatusComponent from "@/components/point/PointStatusComponent";
import PointChargeComponent from "../../components/point/PointChargeComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "MyPagePoint",
    mixins: [AuthMixins, alertMixins],
    components: {MyPageHeaderComponent, PointChargeComponent, PointStatusComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointStatus',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.replace(`/mypage/point/${url}`);
        },
        checkAuthCertification() {
            if(this.isAuthenticated()) {
                let movePath = '/mypage/deposit';
                if(this.$route.path != movePath) {
                    this.$router.push('/mypage/deposit');
                }
            }else{
                this.createConfirm({
                    content: `${this.$t('check_deposit_auth1')}<br>${this.$t('check_deposit_auth2')}`,
                    confirmText: this.$t('check_now'),
                    confirm : () => {
                        this.$router.push('/mypage/update')
                    },
                })
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
