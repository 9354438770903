<template>
    <div class="mypage_list_wrap border-top">
        <div class="mypage_list " v-for="(data,index) in itemData" :key="`real${index}`">
            <div class="item_information">
                <div class="list_time">{{ data.m_bid_date }}</div>

                <div class="term_end acquire_end" v-if="data.m_status===4">{{ $t('a_completed') }}</div>
                <div class="term_end acquire_end" v-else>{{ $t('buyer') }} {{ $t('a_wait') }}</div>
                <div class="clear"></div>
            </div>
            <div class="item_img_wrap mt11">
                <div class="item_img">
                    <img :src="returnThumbnail(data)">
                </div>

                <div class="item_text_list">
                    <div class="item_text" v-if="checkData(m_curator)">
                        <span>{{ $t('seller') }}</span>
                        {{ data.m_curator_nick }}
                    </div>
                    <div class="item_text" v-else><span>{{ $t('seller') }}</span>{{ data.m_nick }}</div>

                    <div class="item_text">{{ data.i_name }}</div>
                    <img src="@/assets/image/mg_icon.png">
                    <div class="item_text bold">{{ returnPrice(data.i_price) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListRealSaleLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(142, 90, 'png')}`;
            }
        },
        checkData(data) {
            return !util.isEmpty(data);
        },
        returnPrice(price) {
            return util.Number.numFormat(price);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
