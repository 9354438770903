<template>
    <div class="competition_wrap pb17 ">
        <div class="competition_item application_item not_sort">
            <div class="competition_input">
                <el-input :placeholder="`${$t('st')}`" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap ">
            <button class="c_filter_btn ma0 " @click="openFilter('item-filter-auction-exhibit-filter')"></button>
        </div>
        <div class="clear"></div>
        <item-filter-auction-exhibit-filter></item-filter-auction-exhibit-filter>
    </div>
<!--    <div class="search_item mb19">
        <div class="filter_category_box mr5">
            <el-select id="category" v-model="searchInputOption" class="my_filter select"
                       popper-class="select_style"
                       :popper-append-to-body="false">
                <el-option :value="'ina'" :label="$t('subject')"></el-option>
                <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                <el-option :value="'orni'" :label="$t('k_creator')"></el-option>
            </el-select>

        </div>
        <div class="search_input mypage_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @input="setData('searchInput',searchInput)"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
            </el-input>
            <button class="search_btn mypage_search_btn"  @click="setFilterFunc(searchInputOption,searchInput)">
            </button>
        </div>
        <button class="search_filter_btn" @click="openFilter('item-filter-auction-exhibit-filter')"></button>
        <item-filter-auction-exhibit-filter></item-filter-auction-exhibit-filter>
    </div>-->
</template>

<script>
import util from "@/mixins/util";
import itemUtil from "@/components/item/itemUtil";
import ItemFilterAuctionExhibitFilter from "@/components/filter/ItemFilterAuctionExhibitFilter";

const {filterConfig} = itemUtil
export default {
    name: "ItemFilterAuctionExhibitLayout",
    mixins: [itemUtil],
    components: {ItemFilterAuctionExhibitFilter},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterBidStatus: [],
            filterStatus: [],
            filterDate: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
        if (!util.isEmpty(this.sortData)) {
            this.setterSortDataInData()
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setterSortDataInData() {
            this.sort = this.sortData.order;
            this.sortOption = this.sortData.key
            if (this.sortData.key === 'i_price') {
                if (this.sortData.order === 'desc') {
                    this.sortOption = `priceDesc`
                } else if (this.sortData.order === 'asc') {
                    this.sortOption = `priceAsc`
                }
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('i_price', 'asc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('i_price', 'desc');
            } else {
                this.sort = 'desc'
                this.setSort(data, 'desc');
            }
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
        },
        changeDateFilter() {
            if (util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            } else {
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

