import { render, staticRenderFns } from "./DetectionFilterHistoryLayout.vue?vue&type=template&id=a00ce008&scoped=true"
import script from "./DetectionFilterHistoryLayout.vue?vue&type=script&lang=js"
export * from "./DetectionFilterHistoryLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a00ce008",
  null
  
)

export default component.exports