<template>
    <div id="app" class="dg_mobile_wrap" v-if="showLayout"
         v-loading.fullscreen.lock="loading"
         :element-loading-text="loadingText"
         element-loading-custom-class="dgmong_loading">
        <div class="dgm_app_warp">
            <router-view></router-view>
        </div>

        <page-footer></page-footer>
    </div>
</template>

<script>
import PageHeader from "./components/PageHeader";
import PageFooter from "./components/PageFooter";
import EventBus from "@/utils/event-bus";
import {setAuthInHeader} from "@/api/request";
import util from "@/mixins/util";
import {mapState} from "vuex";
import MobileDetect from "mobile-detect";
import {isDev} from "@/api/config";

export default {
    name: "App",
    mixins: [],
    components: {
        PageFooter,
        PageHeader
    },
    props: {},
    provide() {
        return {
            changeLoading: this.changeLoading,
        }
    },
    data() {
        return {
            loading: false,
            loadingText: '',
            showLayout: false,
        }
    },
    created() {
        let md = new MobileDetect(window.navigator.userAgent);
        if(md.mobile() == null){
            if (isDev === true) {
                location.href="https://test.minglemint.com" + this.$route.fullPath;
            } else {
                location.href="http://www.minglemint.com" + this.$route.fullPath;
            }
            return false;
        }
        this.showLayout = true;
        this.$store.dispatch('utilityCategory/setUtilityCategory', {});
        this.$store.dispatch('itemCategory/setItemCategory', {});
        this.$store.dispatch('competitionCategory/setCompetitionCategory', {});
        let token = this.$localStorage.get('token');
        if (!util.isEmpty(token)) {
            this.$store.dispatch('userInfo/setUserInfo', {token: token});
            this.$store.dispatch('collection/setCollectionList',{token:token})
        }
        let lang = this.$localStorage.get('lang');
        if (util.isEmpty(lang)) {
            this.$localStorage.set('lang', 'ko');
            this.$i18n.locale = 'ko';
            this.$store.dispatch('country/changeCountry', {data: 'ko'});
        } else {
            this.$localStorage.set('lang', lang);
            this.$i18n.locale = lang;
            this.$store.dispatch('country/changeCountry', {data: lang});
        }

    },

    mounted() {
        EventBus.$on('changeLoading', this.changeLoading)
        EventBus.$on('apiLoading', this.changeLoading)
        EventBus.$on('setProgress', this.setProgress);

    },
    beforeDestroy() {
        EventBus.$off('changeLoading')
        EventBus.$off('apiLoading')
        EventBus.$off('setProgress');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeLoading(value, text = '') {
            this.loading = value;
            if (!value) {
                this.loadingText = '';
            } else {
                this.loadingText = text;
            }
            return new Promise((resolve, reject) => {
                setTimeout(() => {
                    resolve(true);
                }, 50)
            })
        },
        setProgress(value) {
            if (value === false) {
                this.uploading = false;
                this.progress = 0;
            } else if (value > 0) {
                this.uploading = true;
                this.progress = value;
            }
        },

    },
    watch: {},
}
</script>
<style lang="scss">
@import "assets/scss/index.scss";
</style>
