<template>
    <div class="mypage_list_wrap mb24 border-top">
        <div class="mypage_list " v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
            <div class="item_information">
                <div class="list_time" v-html="returnDateFormat(curation.created_at, 'YYYY-MM-DD HH:mm')"  @click="moveItemDetail(curation)">

                    <div v-if="returnStatusData(curation.ibc_status).hasCancel">
                        <button class="mypage_list_two_btn" @click="cancelRequest(curation)" v-if="hasCancel(curation)">
                            {{ $t('accept') }}
                        </button>
                        <button class="mypage_list_two_btn right"
                                v-else-if="returnStatusData(curation.ibc_status).showMarketHistory"
                                @click="$router.push(`/mypage/item/${curation.m_idx}/history`)">{{ $t('refuse') }}
                        </button>
                    </div>
                    <span v-else>{{ $t(returnStatusData(curation.ibc_status).showName) }}</span>
                </div>
            </div>
            <div class="item_img_wrap mt11"  @click="moveItemDetail(curation)">
                <div class="item_img pt3">
                    <img :src="returnItemImage(curation)">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold">[{{ $t(returnStatusData(curation.ibc_status).showName) }}]</div>
                    <div class="item_text"><span>{{ $t('curator') }}</span>{{ curation.curator_nick }}</div>
                    <div class="item_text">{{ curation.i_name }}</div>
                    <img src="@/assets/image/mg_icon.png">
                    <div class="item_text bold">{{ numFormat(curation.ibc_price) }}</div>
                </div>
            </div>
        </div>

    </div>
</template>


<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
// import MypageRequestModal from "@/components/modal/MypageRequestModal";
// import CurationTermsModal from "@/components/modal/CurationTermsModal";
// import TermsModal from "@/components/modal/TermsModal";
export default {
    name: "CurationListReceiveLayout",
    mixins: [alertMixins, imageOption, dateMixins],
    components: {
        // MypageRequestModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationLayouts: 10,
            tempSubject: '아이템 제목이 길어지면 말줄임표를 합니다.'
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_idx}`
            if (curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_idx}`
            } else if (curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_idx}`
            }
            this.$router.push(url);
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if (util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'jpg')}`;
        },
        numFormat(num) {
            if (util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if (index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        openAllowModal(curation) {
            this.$modal.show('mypage-request-modal', {curationData: curation});
        },
        cancelRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>" + curation.i_name + "</span>'<br>" +
                        this.$t('mypage_refuse_confirm'),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListReject', curation);
                },
            })
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
