<template>
  <div>
<!--    <page-header :header_back="true"></page-header>-->
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <button slot="headerBtns" class="h_search_btn" @click="movePage('/search')"></button>
      </page-header>
    <div class="dgm_wrap mt105 ">

      <div class="register">
        <div class="email_wrap no_absolute">
          <div class="login_title">
            <span v-html="$t('loginText1')"></span>
            <!--          {{$t('loginText1')}}-->
          </div>
          <div class="login_top_img">
            <img src="@/assets/image/main_logo.png" alt="">
          </div>
          <div class="email_register register">
            <button class="one_btn back_navy" @click="movePage('/auth/register/email')">{{
                $t('email_register')
              }}
            </button>
          </div>
          <div class="email_sns_start">
            <div class="login_sns_start mt19 border_none">
              <div class="login_sns_wrap mb15">
                {{ $t('start_sns') }}
              </div>
            </div>
            <div class="login_sns mb15 pb0">
                <!--                <div class="sns_login_wrap mr22"></div>-->
                <google-login-component></google-login-component>
                <!--                <div class="sns_login_wrap mr22 naver"></div>-->
                <naver-login-component></naver-login-component>
                <!--                <div class="sns_login_wrap mr22 kakao"></div>-->
                <kakao-login-component></kakao-login-component>
                <!--                <div class="sns_login_wrap facebook"></div>-->
                <facebook-login-component></facebook-login-component>

                <apple-login-component></apple-login-component>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GoogleLoginComponent from "@/components/auth/GoogleLoginComponent";
import NaverLoginComponent from "@/components/auth/NaverLoginComponent";
import KakaoLoginComponent from "@/components/auth/KakaoLoginComponent";
import FacebookLoginComponent from "@/components/auth/FacebookLoginComponent";
import AppleLoginComponent from "@/components/auth/AppleLoginComponent";
export default {
  name: "Register",
  mixins: [],
  components: {
      AppleLoginComponent,
      GoogleLoginComponent,
      NaverLoginComponent,
      KakaoLoginComponent,
      FacebookLoginComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    movePage(url) {
      this.$router.push(url);
    }
  },
  watch: {},
}
</script>

<style scoped>

</style>
