const collectConfig = {
    profileCollect: {
        filterLayout: 'CollectFilterDefaultLayout',
        listLayout: 'CollectListDefaultLayout',
        emptyLayout: 'CollectEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            opf: 1,
            ip: 1
        },
        pageRow: 15,
        sortOptions: {},
    },
    searchCollect: {
        filterLayout: 'CollectFilterDefaultLayout',
        listLayout: 'CollectListSearchResultLayout',
        emptyLayout: 'CollectEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            opf: 1,
            ip: 1
        },
        pageRow: 15,
        sortOptions: {},
    },
    mypageCollect: {
        filterLayout: 'CollectFilterMyPageLayout',
        listLayout: 'CollectListDefaultLayout',
        emptyLayout: 'CollectEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            opf: 1,
            ip: 1
        },
        pageRow: 15,
        sortOptions: {},
    },

    userCollect: {
        filterLayout: 'CollectFilterMyPageLayout',
        listLayout: 'CollectListDefaultLayout',
        emptyLayout: 'CollectEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            opf: 1,
            ip: 1
        },
        pageRow: 15,
        sortOptions: {},
    },

    collect: {
        filterLayout: 'CollectFilterDefaultLayout',
        listLayout: 'CollectListUpdateLayout',
        emptyLayout: 'CollectEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCollection',
        listApiParamSet: {
            opf: 1,
            ip: 1
        },
        pageRow: 15,
        sortOptions: {},
    },
    collectDetail: {
        detailLayout: 'CollectDetailDefaultLayout',
        getFunc: 'getCollection',
        detailApiParamSet: {
            opf: 1,
        },
        getKeyName: 'id',
    },
    itemCollectInfo: {
        detailLayout: 'CollectDetailInfoLayout',
        getFunc: 'getCollection',
        detailApiParamSet: {
            opf: 1,
        },
        getKeyName: 'id',
    },
    collectAdd: {
        writeLayouts: ['CollectWriteModalLayout'],
        writeFunc: 'createCollect',
        writeApiParamSet: {},
        parsingData: {
            title: 'c_title',
            info: 'c_intro',
            owner: 'c_owner'
        },
        fileInfo: [
            {fileName: 'backgroundFile', fileType: 'c_bg'},
            {fileName: 'mainFile', fileType: 'c_main'},
        ],
        directUrl: 'collection-setting-modal',
        directType: 'modal',
        writeSuccessType: 1,
        writeFailTypes: {},
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        getFunc: 'getCollection',
        detailApiParamsSet: {
            opf: 1
        },
        keyName: 'c_idx',
    },

    collectUpdate: {
        writeLayouts: ['CollectWriteModalLayout'],
        writeFunc: 'updateCollect',
        writeApiParamSet: {},
        parsingData: {
            title: 'c_title',
            info: 'c_intro',
            owner: 'c_owner'
        },
        fileInfo: [
            {fileName: 'backgroundFile', fileType: 'c_bg'},
            {fileName: 'mainFile', fileType: 'c_main'},
        ],
        directUrl: 'collection-setting-modal',
        directType: 'modal',
        writeSuccessType: 1,
        writeFailTypes: {},
        writeFailDefault: 'd_ignore_domain_err3',
        writeSuccessAlert: 'd_ignore_url_success',
        getFunc: 'getCollection',
        detailApiParamsSet: {
            opf: 1
        },
        keyName: 'c_idx',
    },
}
export default {
    filterConfig: {
        ti: {
            type: 'query',
            classname: 'searchInput'
        },
        ni: {
            type: 'query',
            classname: 'searchInput'
        },

        tot: {
            type: 'between',
            classname: 'filterPrice',
            option: {
                start: 'totle',
                end: 'totge'
            }
        },
        tab:{
            type: 'match',
            classname: 'tab',
        }
    },
    methods: {
        returnCollectConfig(type) {
            return collectConfig[type];
        }
    },
}
