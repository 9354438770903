<template>
    <div class="wrap-empty-nticket">
        <div class="image-empty"></div>
        <div class="text-empty-nticket">{{$t('no_default_list')}}</div>
    </div>
</template>

<script>
export default {
    name: "UtilityEmptyDefaultLayout",
    mixins: [],
    components: { },
    props: {

    },
    data() {
        return {

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {

    },
    methods: {
    },
    watch: {
    },
}
</script>
