<template>
    <div>
<!--        <page-header :header-title=" $t('i_search') " :header_side="true" :header_back="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header" :header-title=" $t('i_search') "></page-header>
        <item-list-component :itemType="'searchItemDetail'"></item-list-component>
        <popup-modal></popup-modal>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import PopupModal from "@/components/modal/PopupModal";

export default {
  name: "SearchResultItem",
  mixins: [],
  components: {
      PopupModal,
      ItemListComponent
  },
  inject: [],
  provide() {
    return {
    }
  },
  props: {
  },
  data() {
    return {

    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {

  },
  watch: {},
}
</script>

<style scoped>

</style>
