import { render, staticRenderFns } from "./CreatorSort.vue?vue&type=template&id=1dbaf1a2&scoped=true"
import script from "./CreatorSort.vue?vue&type=script&lang=js"
export * from "./CreatorSort.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1dbaf1a2",
  null
  
)

export default component.exports