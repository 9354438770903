<template>
    <div class="pt14 border-top">
      <div class="detection_box">
        <button class="detection_button" :class="{active: selectedItems.length > 0}"
                @click="checkSelectedItem()">{{ $t('select_detection') }}
        </button>
        <button class="detection_button d_b_background" @click="checkAllItem()">
          {{ $t('all_detection') }}
        </button>
      </div>
        <div class="detection_list">
            <div class="item_premium_list" v-for="item in itemData" :key="`item${item.i_idx}`">
                <div class="img_wrap">
                    <div class="img_bg" @click="moveDetail(item.i_sku)">
                        <img :src="returnThumbnail(item)" alt="">
                    </div>
                    <div class="art_card">{{ item.ic_name_eng }}</div>
                    <div class="check_wrap white_box">
                        <el-checkbox class="checkbox" @change="selectItem(item.i_idx, $event)"
                                     :checked="selectedItems.indexOf(item.i_idx) > -1"/>
                    </div>
                </div>
                <div class="content_wrap" @click="moveDetail(item.i_sku)">
                    <div class="subject_wrap detection_subject_wrap">
                        <div>{{ item.i_name }}</div>
                        <div class="match_text mt4">{{ numFormat(item.drCnt) }}Match</div>
                    </div>

                </div>
            </div>
        </div>
    </div>

</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListDetectionItemLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: ['setSelectItems', 'checkSelectedItem', 'checkAllItem'],
    provide() {
        return {}
    },
    props: {
        itemData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            selectedItems: [],
            showList: true,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                //return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(279, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 279);
            }
        },
        numFormat(num) {
            return util.isEmpty(num) || isNaN(num) ? '-' : util.Number.numFormat(num);
        },
        moveDetail(url) {
            this.$router.push(`${this.$route.path}/${url}`);
        },
        selectItem(value, checked) {
            if (checked && this.selectedItems.indexOf(value) < 0) {
                this.selectedItems.push(value);
            } else if (!checked && this.selectedItems.indexOf(value) > -1) {
                this.selectedItems.splice(this.selectedItems.indexOf(value), 1);
            }
            this.setSelectItems(this.selectedItems);


        },
    },
    watch: {
        'itemData': {
            deep: true,
            handler: function (val, oldVal) {
                this.selectedItems = [];
                this.setSelectItems(this.selectedItems);
                this.showList = false;
                this.$nextTick(() => {
                    this.showList = true;
                })
            }
        }
    },
}
</script>

<style scoped>

</style>
