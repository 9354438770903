<template>
    <div class="creator_detail_wrap overflow_hidden mt0">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
<!--        <page-header :header_white_back="true" class="creator_detail_header"
                     :header-title="$t('creator')"></page-header>-->
        <member-detail-component :memberType="'userProfile'" :memberKey="memberKey"></member-detail-component>
        <creator-detail-tabs :nowTab="'collection'" :memberKey="memberKey"></creator-detail-tabs>
        <share-modal></share-modal>
        <router-view></router-view>
    </div>
</template>

<script>
import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import CreatorDetailTabs from "@/components/tabs/CreatorDetailTabs";
import ShareModal from "@/components/modal/ShareModal";

export default {
    name: "CreatorDetail",
    mixins: [],
    components: {
        ShareModal,
        MemberDetailComponent,
        CreatorDetailTabs
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
