<template>
    <div>
<!--        <page-header :header_back="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <div class="dgm_wrap padding_side mt105 mb90">
            <div class="register">
                <div class="login_title">
                    <span v-html="$t('loginText1')"></span>
                    <!--          {{$t('loginText1')}}-->
                </div>
                <div class="login_top_img">
                    <img src="@/assets/image/main_logo.png" alt="">
                </div>
                <register-component></register-component>
            </div>
        </div>
    </div>
</template>

<script>
import RegisterComponent from "@/components/auth/RegisterComponent";

export default {
    name: "RegisterEmail",
    mixins: [],
    components: {
        RegisterComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
