<template>
    <div>
        <div class="notice" v-if="boardData.hasOwnProperty('wr_id')">
            <div class="notice_top">
                <div class="notice_title">
                    {{ boardData.wr_subject }}
                </div>
                <div class="notice_date mt6">
                    {{ boardData.created_at }}
                </div>
            </div>
            <div class="notice_middle" v-if="returnNoEmptyFiles(boardData.File).length > 0">
                <button class="file_btn readonly" v-for="file in returnNoEmptyFiles(boardData.File)"
                        :key="`qnaFile${file.a_idx}`"
                        @click="awsS3Download('qna',file,boardData.wr_id)">{{ file.org_name }}
                </button>
            </div>
            <div class="notice_detail_content">
                <div class="nd_content qna_content ql-editor" v-html="replaceBr(boardData.wr_content)"></div>
            </div>
        </div>
        <div class="notice">
            <div class="notice_top" v-if="boardData.hasOwnProperty('Comment') && boardData.Comment.cnt === 1">
                <div class="answer">
                    <div class="subject notice_title icon_re"><span class="answer">└&nbsp;&nbsp;Re</span> <span
                            class="qna_subject"
                            v-html="$t('answer_subject', {subject: boardData.wr_subject})"></span>
                    </div>
                    <div class="notice_date mt3">{{ boardData.Comment.List[0].created_at }} / <span>{{
                            $t('admin_write')
                        }}</span></div>
                </div>
                <div class="qna_middle" v-if="returnNoEmptyFiles(boardData.Comment.List[0].File).length > 0">
                    <button class="file_btn readonly" v-for="file in returnNoEmptyFiles(boardData.Comment.List[0].File)"
                            :key="`qnaCommentFile${file.a_idx}`" @click="download(file)">{{ file.org_name }}
                    </button>
                </div>

            </div>
            <div class="qna_content notice_detail_content"
                 v-if="boardData.hasOwnProperty('Comment') && boardData.Comment.cnt === 1">
                <div class="qna_msg nd_content qna_content"
                     v-html="replaceBr( boardData.Comment.List[0].wr_content )"></div>
            </div>
        </div>

        <div class="qna_bottom">
            <button class="black-btn" @click="moveModify(boardData.wr_id)"
                    v-if="!boardData.hasOwnProperty('Comment') || boardData.Comment.cnt < 1">{{ $t('modify') }}
            </button>
            <button class="black-btn ml7" @click="deleteQna"
                    v-if="!boardData.hasOwnProperty('Comment') || boardData.Comment.cnt < 1">{{ $t('confirm_del') }}
            </button>
        </div>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";

export default {
    name: "BoardDetailQnaLayout",
    mixins: [awsS3FileDownload],
    components: {},
    inject: ['setQnaData'],
    provide() {
        return {}
    },
    props: {
        boardData: {
            type: Object,
            default: () => {
                return []
            }
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setQna() {
            if (!util.isEmpty(this.boardData.Comment)) {
                this.setQnaData(this.boardData.Comment.cnt);
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        hasFile(file) {
            return !util.isEmpty(file);
        },
        returnNoEmptyFiles(files = []) {
            if (util.isEmpty(files)) {
                return []
            }
            return files.filter(file => file.a_idx != null);
        },
        awsS3Download(type, name, key) {
            this.runDownloadFileInfo(type, name, key).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        },
        backList() {
            this.$router.back();
        },
        moveModify(path) {
            this.$router.push(`${path}/update`);
        },
        deleteQna() {
            EventBus.$emit('openDeleteConfirm')
        },
    },
    watch: {
        'boardData.wr_id': {
            deep: true,
            handler: function (val, oldVal) {
                this.setQna();
            }
        }
    },
}
</script>

<style scoped>

</style>
