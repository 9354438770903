<template>
    <div>
      <div class="search_title mb15">{{$t('i_search')}} <span class="title_bold">5</span> 건</div>
      <div class="item_premium_list_wrap pa0">
        <div class="item_premium_list" v-for="data in 10">
          <div class="img_wrap">
            <div class="img_bg"></div>
            <div class="art_card">Art</div>
            <div class="real_card">실물</div>
          </div>

          <div class="content_wrap">
            <div class="subject_wrap">병원로고 디자인 의뢰</div>


            <div class="content_middle">
              <ul class="middle_thumb">
                <li class="thumb_wrap">
                  <img src="@/assets/image/bid_thumb1.png" alt="">
                </li>
                <li class="thumb_wrap">
                  <img src="@/assets/image/bid_thumb2.png" alt="">
                </li>
                <li class="thumb_wrap">
                  <img src="@/assets/image/bid_thumb3.png" alt="">
                </li>
              </ul>


              <div class="heart_wrap">
                <div class="heart_box">
                  <img src="@/assets/image/s_heart.png" alt="">
                  <span class="count">251</span>
                </div>

                <div class="eye_box">
                  <img src="@/assets/image/s_eye.png" alt="">
                  <span class="count">1,251</span>
                </div>
              </div>
            </div>


            <div class="content_status">
              <div class="premium_card">premium</div>
              <div class="status_text">라이선스판매</div>
              <div class="clear"></div>
            </div>


            <div class="amount_wrap">
              <div class="mg_icon"></div>
              <div class="amount_box">1,000,000</div>
              <div class="mg">Mg</div>
            </div>
          </div>

        </div>
      </div>
      <div class="search_result_btn mt15 mb60">
        <button class="more_btn font_white">+ More View</button>
      </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListSearchLayout",
    mixins: [imageOption, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
      returnCommas(x = 0) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      },
        moveDetail(data) {
            this.$router.push(`/premiummarket/${data}`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
        },
        returnMemberImg() {
            return `${this.memberData.profile}?${this.setImageOptions(100, 100, 'jpg')}`
        },

        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },

        getMemberData(data) {
            this.memberData = {}
            this.memberData.profile = this.itemData[0].profile.provider[0].org_url;
            this.memberCheck = true;
            this.$forceUpdate()
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            return diffTime;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
