import { render, staticRenderFns } from "./JudgeFilterModalFilter.vue?vue&type=template&id=580d1496&scoped=true"
import script from "./JudgeFilterModalFilter.vue?vue&type=script&lang=js"
export * from "./JudgeFilterModalFilter.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "580d1496",
  null
  
)

export default component.exports