<template>

    <div class="curation_submit_wrap pointcharge_wrap">
        <div class="point_charge_wrap">
            <div class="point_charge_wrap_list "
                 v-for="point in pointItems" :key="`cp${point.ibp_idx}`"
                 :class="{'point_charge_wrap_list_color': selectedItem.ibp_idx === point.ibp_idx}"
                 @click="selectItem(point)">
                <div class="point_wrap" :class="{'point_wrap_active' : hasBonusPoint(point)}">
                    <div class="point_wrap_top " v-show="hasBonusPoint(point)">{{ numFormat(point.ibp_point) }} +
                        {{ numFormat(point.ibp_point_extra) }}
                    </div>
                    <div class="point_wrap_bottom">
                        <img src="@/assets/image/mg_icon.png">
                        <div class="point_mg">{{ numFormat(point.ibp_point + point.ibp_point_extra) }}Mg</div>
                    </div>
                </div>
                <div class="point_price">￦ {{ numFormat(point.i_price) }}</div>
            </div>
        </div>
        <div class="i_create_footer point_charge_footer">
            <div class="create_footer_check">
                <div class="check_wrap">
                    <label class="el-checkbox ">
                        <span class="el-checkbox__input el-point-checkbox">
                            <el-checkbox @change="changeTerms()"></el-checkbox>
                        </span>
                    </label>
                    <span class="item_text_span" v-html="$t('charge_notification_text')"></span>
                </div>
            </div>
            <div class="btn_wrap point_btn_wrap">
                <div class="save_btn one_btn" @click="writePoint('card')">{{ $t('charge') }}</div>
                <!--              <button class="save_btn one_btn" @click="directOrder('dgm')">{{ $t('charge_force_card') }}</button>-->
            </div>
            <div class="val_cap" v-if="showError">
                {{ $t('charge_validator_text') }}
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import Payment from "@/components/point/Payment";
import {mapState} from "vuex";
import Order from "@/components/point/Order";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {getStorageToken} from "@/mixins/Auth";
import {apiDomain} from "@/api/config";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "PointChargeComponent",
    mixins: [alertMixins, AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pointItems: [],
            selectedItem: {},
            checkTerms: false,
            showError: false,
            PaymentClass: null,
            OrderClass: null,
            itemId: null,
            qty: null,
            c_type: 'item',
            pg: 'dgm',
            pw: 'dgmong',
            oid: '',
            isRunning: false,
            pgPopup: '',
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms

        this.getItemPoint();
        this.PaymentClass = new Payment.PaymentClass(this.UserInfo);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        getItemPoint() {
            try {
                this.$api.$point.getPointItem().then(res => res.Data.Result).then(res => {
                    if (!util.isEmpty(res)) {
                        this.pointItems = res.List;
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        selectItem(item) {
            this.selectedItem = item;
        },
        hasBonusPoint(item) {
            return !util.isEmpty(item.ibp_point_extra) && item.ibp_point_extra > 0;
        },
        numFormat(num) {
            if (isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        openTerms(name, type) {
            this.$modal.show(name, {termsType: type})
        },
        changeTerms() {
            this.checkTerms = !this.checkTerms
            this.showError = false;
        },
        async writePoint(type) {
            if (!this.isAuthenticated()) {
                this.createConfirm({
                    content: `${this.$t('check_deposit_auth1')}<br>${this.$t('check_deposit_auth2')}`,
                    confirmText: this.$t('check_now'),
                    confirm: () => {
                        this.$router.push('/mypage/update')
                    },
                })
            } else {
                if (!this.checkTerms) {
                    this.showError = true;
                    return false;
                }
                if (util.isEmpty(this.selectedItem.i_idx)) {
                    this.errorAlert(this.$t('required_point_item'));
                    return false;
                }
                if (this.isRunning) {
                    return false;
                }
                this.isRunning = true;
                EventBus.$emit('apiLoading', true);

                this.oid = await this.PaymentClass.directPay(this.selectedItem.i_idx, 1, 'item');
                if (this.oid) {
                    this.writePayment(this.oid, type);
                } else {
                    //카트 생성 에러
                    console.log('system error')
                    this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
                    EventBus.$emit('apiLoading', false);
                    this.isRunning = false;
                }
            }
        },
        async writePayment(oid, type) {
            this.OrderClass = new Order.OrderClass(this.UserInfo);
            let isSafari = await this.PaymentClass.getBrowserType();
            let pgUrl = await this.OrderClass.OrderPay(this.oid, type);
            if (util.isEmpty(pgUrl)) {
                console.log('system error')
                this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
                EventBus.$emit('apiLoading', false);
                this.isRunning = false;
            }
            if (isSafari === 'notSafari') {
                this.pgPopup = window.open(pgUrl, 'popupChk', 'width=1000,height=800, top=100, left=100, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=no, scrollbar=no');
                this.checkPopupClose();
            } else {
                if (!util.isEmpty(pgUrl)) {
                    window.location.replace(pgUrl);
                }
            }

        },
        checkPopupClose() {
            let $this = this;
            EventBus.$emit('apiLoading', true);
            let checkPgPopupInterval = setInterval(() => {
                if ($this.pgPopup.closed) {
                    clearInterval(checkPgPopupInterval);
                    $this.checkOrderId(this.oid).then(result => {
                        if (result) {
                            $this.writeOrder($this.o_id);
                        } else {
                            EventBus.$emit('apiLoading', false);
                            this.isRunning = false;
                        }
                    });
                }
            }, 1000)
        },
        async writeOrder() {
            let updateUserInfo = this.UserInfo;
            updateUserInfo.point = Number(this.UserInfo.point) + Number(this.selectedItem.ibp_point);

            // let status = this.OrderClass.writePoint(this.oid);
            // if (status) {
            EventBus.$emit('apiLoading', false);
            this.$store.dispatch('userInfo/changeUserInfo', {data: updateUserInfo});
            this.createAlert({
                content: this.$t('pay_point_success'),
                btnText: '확인',
                hide: () => {

                    this.$router.push('/mypage/point');
                }
            })
        },
        // } else {
        //     this.errorAlert(this.$t('point_charge_err2') + '<br>' + this.$t('point_charge_err3'));
        //     EventBus.$emit('apiLoading', false);
        //     this.isRunning = false;
        // }
        async checkOrderId(o_id) {
            let result = await this.OrderClass.checkOrder(o_id);
            if (!result) {
                let errorMsg = this.$t('payment_fail');
                this.errorAlert(errorMsg)
                return false;
            }
            return true;
        },

    },
    watch: {},
}
</script>
<style scoped>

</style>
