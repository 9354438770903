<template>
  <div>
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <div slot="headerBtns">
              <button class="h_search_btn" @click="$router.push('/search')"></button>
          </div>
      </page-header>
      <page-header class="sub_header"></page-header>
<!--    <page-header :header_back="true"></page-header>-->
    <div class="padding_side dgm_wrap">
      <div class="mypage_item_wrap">
        <div class="notice_tabs">
          <button class="notice_item_3_tab  two_tab" :class="{'active':tab === 'image'}" @click="changeTab('image')">
            {{ $t('myImg') }}
          </button>
          <button class="notice_item_3_tab two_tab border_r_l" :class="{'active':tab === 'request'}" @click="changeTab('request')">
            {{ $t('request_history') }}
          </button>
        </div>
        <detection-write-component :detection-type="'detectionWrite'"
                                   v-if="tab === 'image'"></detection-write-component>
        <detection-list-component :detection-type="'request'" v-else-if="tab === 'request'"></detection-list-component>
      </div>
    </div>
      <transition :name="`detail-${$root.$children[0].transitionName}`">
          <router-view></router-view>
      </transition>
  </div>
</template>

<script>
import DetectionListComponent from "@/components/detection/DetectionListComponent";
import DetectionWriteComponent from "@/components/detection/DetectionWriteComponent";
import util from "@/mixins/util";

export default {
  name: "MyPageDetection",
  mixins: [],
  components: {
    DetectionWriteComponent,
    DetectionListComponent,
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      tab: 'image',
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    if (!util.isEmpty(this.$route.query.tab)) {
      this.tab = this.$route.query.tab;
    }
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
      movePage(url) {
          this.$router.push(url);
      },

      changeTab(tab) {
          if(this.tab === tab) {
              return false;
          }
          this.tab = '';
          this.$nextTick(() => {
              this.tab = tab
              let route = `${this.$route.path}?tab=${tab}`
              if(tab != this.$route.fullPath) {
                  this.$router.replace(route);
              }
          })
          this.$router.replace(`${this.$route.path}?tab=${tab}`);
      },
  },
  watch: {},
}
</script>

<style scoped>

</style>
