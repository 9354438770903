<template>
    <div>
<!--        <page-header :header_back="true" :header-title="$t('unsubscribe')"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <div class="dgm_wrap padding_side relative">
            <unsubscribe-component></unsubscribe-component>
        </div>
    </div>
</template>

<script>
import UnsubscribeComponent from "@/components/auth/UnsubscribeComponent";

export default {
    name: "Unsubscribe",
    mixins: [],
    components: {
        UnsubscribeComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
