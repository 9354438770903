<template>
    <div class="gallery-setting-modal_wrap btn_wrap_absol">
        <div class="gallery_bg">
            <h3 class="sub_title_m regular">{{$t('gallery_background')}}</h3>
            <div class="bg_wrap ba0" @click="[changeType('backgroundFile'), initErrorMsg()]">
                <el-upload
                    class="ba0"
                    list-type="picture-card"
                    :file-list="backgroundFile"
                    :auto-upload="false"
                    :show-file-list="false"
                    accept="image/png, image/jpeg"
                    :on-change="onlyOnePhotoChange">
                    <div class="bg_wrap" v-if="backgroundFile.length === 0">
                        <img class="default_img" src="@/assets/image/change_item.png" alt="">
                    </div>
                    <div class="bg_wrap" v-else>
                        <img class="select_img" :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                    </div>
                </el-upload>
            </div>
            <div class="validation" v-if="validation.hasError('backgroundFile')">
                {{ validation.firstError('backgroundFile') }}
            </div>
        </div>
        <div class="gallery_name">
            <h3 class="sub_title_m regular">{{$t('gallery_name')}}</h3>
            <div class="input_wrap">
                <el-input type="text" :placeholder="$t('collection_input')" v-model="title" @input="parentChange('title', title)" maxLength="20"></el-input>
                <div class="validation" v-if="validation.hasError('title')">
                    {{ validation.firstError('title') }}
                </div>
            </div>
        </div>
        <div class="button_wrap">
            <div class="two_btn temp_btn" v-if="isGalleryData()" @click="openDeleteConfirm()">{{$t('collection_delete')}}</div>
            <div class="save_btn" :class="{'one_btn': !isGalleryData(), 'two_btn' : isGalleryData()}" @click="runSave">{{$t('save')}}</div>
            <div class="clear"></div>
            <div class="validation" v-if="errorMsg != ''">{{errorMsg}}</div>
        </div>
    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import galleryValidator from "@/mixins/validators/galleryValidator"
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "GalleryWriteModalLayout",
    mixins: [elFileMixins, galleryValidator],
    components: {
    },
    inject: ['galleryWriteSetData'],
    provide() {
        return{

        }
    },
    props: {
        galleryData: {default: () => {}},
    },
    data() {
        return{
            collectionName: '',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.settingBeforeData();
    },
    mounted() {
        EventBus.$on('GalleryWriteModalLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('GalleryWriteModalLayoutValidator')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        settingBeforeData() {
            if(!this.isGalleryData()) {
                return false;
            }
            this.title = this.galleryData.c_title;
            this.galleryWriteSetData('title', this.title);
            if(!util.isEmpty(this.galleryData.Files) && !util.isEmpty(this.galleryData.Files.Thumb)) {
                this.thumbnailFile = this.galleryData.Files.Thumb.slice();
                this.galleryWriteSetData('thumbnailFile', this.thumbnailFile);
            }
            if(!util.isEmpty(this.galleryData.Files) && !util.isEmpty(this.galleryData.Files.Background)) {
                this.backgroundFile = this.galleryData.Files.Background.slice();
                this.galleryWriteSetData('backgroundFile', this.backgroundFile);
            }
        },
        runValidator(validatorArr=['title', 'thumbnailFile', 'backgroundFile']) {
            this.$validate(validatorArr).then(success => {
                if(success) {
                    this.errorMsg = "";
                }else{
                    this.errorMsg = this.$t('all_input');
                }
            })
        },
        parentChange(type, value) {
            this.galleryWriteSetData(type, value);
            this.initErrorMsg();
            this.$validate([type]).then(success => {
            });
        },
        initErrorMsg() {
            this.errorMsg = "";
        },
        runSave() {
            EventBus.$emit('writeGallery');
        },
        isGalleryData() {
            return !util.isEmpty(this.galleryData);
        },
        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('collection_delete_confirm1')}<br>${this.$t('collection_delete_confirm2')}`,
                confirm: () => {
                    EventBus.$emit('galleryWriteRunDelete')
                }
            })
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
