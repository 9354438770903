<template>
    <div class="competition_wrap pb17 padding_side">
        <div class="competition_item application_item">
            <div class="competition_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @keypress.enter.native="setFilterFunc('ni',searchInput)"/>
                <button class="competition_btn" @click="setFilterFunc('ni',searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_sort_btn ma0"></button>
            <button class="c_filter_btn ma0 ml13" @click="openFilter('judge-filter-modal-filter')"></button>
        </div>
        <div class="clear"></div>
        <judge-filter-modal-filter></judge-filter-modal-filter>
    </div>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"
import JudgeFilterModalFilter from "@/components/filter/JudgeFilterModalFilter";

const {filterConfig} = memberUtil

export default {
    name: "MemberFilterJudgeLayout",
    mixins: [],
    components: {JudgeFilterModalFilter},
    inject: ['setFilter', 'setSort', 'setMemberListData'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            default: () => {
            }
        },
        filterSet: {default: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}}}
    },
    data() {
        return {
            searchOption: 'ina',
            sortOption: 'mb_item_update',
            searchInput: '',
            filterType: [],
            filterCurator: [],
            filterLike: ["", ""],
            filterItem: ["", ""],
            filterClass: new Filter(),
            showFilter: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.filterClass.setterFilterConfigs(filterConfig);
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
            this.filterClass.filter = Object.assign({}, this.filterSet.filter);
            if (!util.isEmpty(this.sortData.key)) {
                this.sortOption = this.sortData.key;
            }
        },
        sortChange(data) {

            this.setSort(data, 'desc')
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
            this.filterClass.filter = Object.assign({}, this.filterSet.filter);
        },
        runRangeFilter() {
            let filterSet = this.filterSet;
            filterSet.filter = this.filterClass.setterFilter('lic', this.filterLike);
            filterSet.filter = this.filterClass.setterFilter('itc', this.filterItem);
            this.setMemberListData('filterSet', filterSet);
            this.setMemberListData('filterClass', this.filterClass);
            EventBus.$emit('memberListChangeSaveFilter')

        },
        openFilter(name) {
            this.$modal.show(name, {filterSet: this.filterSet, filterData: this.filterData, sortData: this.sortData,});
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
