<template>
    <modal name="item-filter-auction-filter" class="filter-modal item-filter-auction-filter"
           :width="`calc(100% - 28px)`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_status">
                    <div class="modal_title">{{ $t('category') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterCategory"
                                               @change="setFilterFunc('ic', filterCategory)">
                                <el-checkbox :label="2">{{ $t('art') }}</el-checkbox>
                                <el-checkbox :label="178">{{ $t('prompt') }}</el-checkbox>
                                <el-checkbox :label="9">{{ $t('collections') }}</el-checkbox>
                                <el-checkbox :label="3">{{ $t('picture') }}</el-checkbox>
                                <el-checkbox :label="5">{{ $t('music') }}</el-checkbox>
<!--                                <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>


                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('edition') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterEdition"
                                               @change="setFilterFunc('ed', filterEdition)">
                                <el-checkbox :label="1">{{ $t('edition') }}</el-checkbox>
                                <el-checkbox :label="0"> {{ $t('single') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_type">
                    <div class="modal_title">{{ $t('type') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterType"
                                               @change="setFilterFunc('ty', filterType)">
                                <el-checkbox :label="'digital'">{{ $t('digital') }}</el-checkbox>
                                <el-checkbox :label="'real'">{{ $t('real') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('status') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterBidStatus"
                                               @change="setFilterFunc('adst', filterBidStatus)">
                                <el-checkbox :label="1">
                                    {{ $t('bid') }}
                                </el-checkbox>
                                <el-checkbox :label="2">
                                    {{ $t('s_bid') }}
                                </el-checkbox>
                                <el-checkbox :label="0">
                                    {{ $t('bidFail') }}
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_price filter_modal_date">
                    <div class="modal_title">{{ $t('bidDate') }}</div>
                    <div class="date-range-wrap">
                        <datetime class=" date-picker" v-model="filterDateView[0]"
                                  placeholder="YYYY-MM-DD"
                                  :min-datetime="returnLimitStartDate(0)"
                                  :max-datetime="returnLimitEndDate(0)" :week-start="7" format="yyyy-MM-dd"
                                  @input="changeDatePicker(0, $event)"></datetime>

                        <span class="first_input_step">~</span>

                        <datetime class=" date-picker" v-model="filterDateView[1]"
                                  placeholder="YYYY-MM-DD"
                                  :min-datetime="returnLimitStartDate(1)"
                                  :max-datetime="returnLimitEndDate(1)" :week-start="7" format="yyyy-MM-dd"
                                  @input="changeDatePicker(1, $event)"></datetime>

                        <button class="date-range-remove-btn" v-show="checkDate('filterDate')"
                                @click="resetDate('filterDate', 'cr')">
                            <i class="el-icon-circle-close"></i>
                        </button>
                    </div>
                </div>

                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('bid_amount') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">
                            <el-input class="el_price_input" type="number"
                                      oninput="value = value.replace(/[^0-9.]/g,'')"
                                      v-model="priceInput[0]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input" type="number"
                                      oninput="value = value.replace(/[^0-9.]/g,'')"
                                      v-model="priceInput[1]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>


                <div class="wrapper_bottom">
                    <button class="" @click="runPriceFilter">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";
import itemUtil from "@/components/item/itemUtil";
import {Datetime} from 'vue-datetime';
import 'vue-datetime/dist/vue-datetime.css'
import {Settings} from "luxon";

const {filterConfig} = itemUtil;

export default {
    name: "ItemFilterAuctionFilter",
    mixins: [],
    components: {
        datetime: Datetime
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            sortData: {},
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            filterBidStatus: [],
            filterDate: ['', ''],
            filterDateView: ['', ''],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (event.params.hasOwnProperty('sortData')) {
                this.sortData = event.params.sortData;
            }
            if (event.params.hasOwnProperty('filterData')) {
                this.filterData = event.params.filterData;
            }
            let lang = this.$i18n.locale;
            Settings.defaultLocale = lang;
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('item-filter-auction-filter');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                        if (classname === 'filterDate') {
                            if (obj.start != '') {
                                this[classname + 'View'][0] = obj.start.split(' ')[0] + 'T00:00:00.000Z'
                            }
                            if (obj.end != '') {
                                this[classname + 'View'][1] = obj.end.split(' ')[0] + 'T00:00:00.000Z'
                            }
                        }
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        checkDate(type) {
            if (this[type].findIndex(data => !util.isEmpty(data)) > -1) {
                return true;
            }
            return false;
        },
        resetDate(classname, type) {
            this[classname] = ['', ''];
            this[classname + 'View'] = ['', ''];
            this.setFilterFunc(type, this[classname])
        },

        returnLimitStartDate(index = 0) {
            let startDate = null
            if (index === 1 && this.filterDate[0] != '') {
                startDate = this.$moment(this.filterDate[0]).toISOString();
            }
            return startDate;
        },
        returnLimitEndDate(index = 0) {
            let endDate = null;
            if (index === 0 && this.filterDate[0] != '') {
                endDate = this.$moment(this.filterDate[1]).toISOString();
            }
            return endDate;
        },
        changeDatePicker(index, value) {
            if (value === '') {
                return false;
            }
            let time = '00:00:00'
            if (index === 1) {
                time = '23:59:59'
            }
            let reformDate = this.$moment(value).format('YYYY-MM-DD ' + time);
            this.filterDate[index] = reformDate;
            if (index === 0 && this.filterDate[1] != '' && this.filterDate[1] < reformDate) {
                this.filterDate[1] = this.$moment(value).format('YYYY-MM-DD 23:59:59');
                this.filterDateView[1] = reformDate + 'T00:00:00.000Z';
            } else if (index === 1 && this.filterDate[0] != '' && this.filterDate[0] > reformDate) {
                this.filterDate[0] = this.$moment(value).format('YYYY-MM-DD 00:00:00');
                this.filterDateView[0] = reformDate + 'T00:00:00.000Z';
            }
            this.setFilterFunc('cr', this.filterDate)
        },
        runPriceFilter() {
            this.setFilterFunc('pr', this.priceInput);
            this.modalClose();
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
