<template>
  <div>
    <div class="item_detail_footer">

      <div class="footer_nft_wrap" v-if="tab === 'nft'">
        <div class="info_detail">
          <div class="nft_header_wrap">
            <h3 class="sub_title_m">{{$t('nft_history')}}</h3>
            <div class="more_view_btn2">More+</div>
          </div>
          <table class="table_wrap" width="100%"  border="0" cellspacing="0" cellpadding="0">
            <colgroup>
              <col style="max-width: 25%; width: 25%;">
              <col style="max-width: 16.66%; width: 16.66%;">
              <col style="max-width: 16.66%; width: 16.66%;">
              <col style="max-width: 16.66%; width: 16.66%;">
              <col style="max-width: 25%; width: 25%;">
            </colgroup>
            <tr height="52"  class="t_top">
              <th scope="col" class="h_t_th">Event</th>
              <th scope="col" class="h_t_th">Price</th>
              <th scope="col" class="h_t_th">From</th>
              <th scope="col" class="h_t_th">To</th>
              <th scope="col" class="h_t_th">Date</th>
            </tr>
            <tr height="55" class="table_content">
              <td width="25%">{{$t('s_bid')}}</td>
              <td width="16.66%">-</td>
              <td width="16.66%">nickname</td>
              <td width="16.66%">nickname</td>
              <td width="25%">2021-07-22 10:33</td>
            </tr>
            <tr height="55" class="table_content">
              <td width="25%">{{$t('bid_enroll')}}</td>
              <td width="16.66%">-</td>
              <td width="16.66%">nickname</td>
              <td width="16.66%">nickname</td>
              <td width="25%">2021-07-22 10:33</td>
            </tr>
            <tr height="55" class="table_content">
              <td width="25%">{{$t('buy_license')}}</td>
              <td width="16.66%">10,000</td>
              <td width="16.66%">nickname</td>
              <td width="16.66%">nickname</td>
              <td width="25%">2021-07-22 10:33</td>
            </tr>
            <tr height="55" class="table_content">
              <td width="25%">{{$t('create_nft')}}</td>
              <td width="16.66%">-</td>
              <td width="16.66%">nickname</td>
              <td width="16.66%">nickname</td>
              <td width="25%">2021-07-22 10:33</td>
            </tr>
          </table>

        </div>

      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper' ;
import 'swiper/css/swiper.css';
export default {
  name: "ItemDetailInfoLayout",
  mixins: [],
  components: {
    Swiper,
    SwiperSlide
  },
  inject: [],
  provide() {
    return {
    }
  },
  props: {
  },
  data() {
    return {
      detail_s_img: 4,
      tab: 'item',
      swiperOption2: {
        slidesPerView: 1.77,
        spaceBetween: 3,
        grabCursor:true,
      }
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    this.slideIndex = this.$refs.swiper.$swiper.realIndex;

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {
    changeTab(tab) {
      this.tab = ''
      this.$nextTick(() => {
        this.tab = tab;
      })
      this.$router.replace(`${this.$route.path}?tab=${tab}`);
    },
  },
  watch: {},
}
</script>

<style scoped>

</style>