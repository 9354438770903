<template>
    <div class="">
        <div class="reg_middle_wrap">
            <div class="reg_middle">
                <input class="register_nickname" v-model="nick" :placeholder="$t('nickPlaceholder')" maxlength="20" @blur="checkNickDuplicate()" />
                <div class="validation" v-if="validation.hasError('nick') && nick.length > 0">{{ validation.firstError('nick') }}</div>
                <div class="validation" v-else-if="validation.hasError('nickCheckDuplicate')">
                    {{ validation.firstError('nickCheckDuplicate') }}
                </div>
                <div class="possible" v-else-if="!validation.hasError('nickCheckDuplicate') && nick !== ''">
                    {{$t('usableNick')}}
                </div>
            </div>
            <div class="reg_middle">
                <input class="user_id" type="text" :placeholder="`${$t('r_nick')} (${$t('options')})`" v-model="referrerNick" @blur="referrerNickCheck()"/>
                <div class="validation" v-if="validation.hasError('referrerNick') && referrerNick.length > 0">
                    {{ validation.firstError('referrerNick') }}
                </div>
                <div class="validation" v-else-if="validation.hasError('referrerNickChk')">
                    {{ validation.firstError('referrerNickChk') }}
                </div>
            </div>
        </div>
        <!--      register_middle_wrap-->
        <div class="reg_bottom_wrap">
            <div class="reg_bottom">
                <button class="submit_btn" @click="checkValidator()">{{$t('register')}}</button>
            </div>
            <div class="reg_bottom check_wrap">
                <div class="search_item">
                    <div class="checkbox" :class="{active: checkTerms}" @click="changeCheck('checkTerms')"
                         id="agreeTerm" :checked="checkTerms"></div>
                    <div class="register_terms" v-html="$t('register_terms')"></div>
                </div>
                <div class="search_item mt10" @click="changeCheck('checkAge')">
                    <div class="checkbox" :class="{active: checkAge}"
                         id="agreeAge" :checked="checkAge"></div>
                    <div class="register_terms">
                        <span class="ml24">{{$t('age14_over')}}</span>
                    </div>
                </div>
                <div class="validation  last_val" v-if="btnCheck">{{$t('reject_register')}}</div>
                <div class="validation  last_val" v-else-if="btnCheck2">{{$t('no_age14_over_err')}}</div>
            </div>
        </div>
    </div>
</template>

<script>
import memberValidator from "@/mixins/validators/memberValidator";
import alertMixins from "@/mixins/alertMixins";
import {loginTokenSet} from "@/mixins/Auth";
import {mapState} from "vuex";
import Vue from "vue";
import {setAuthInHeader} from "@/api/request";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
export default {
    name: "RegisterSocialComponent",
    mixins: [memberValidator, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            errorMsg: false,
            successMsg: false,
            checkTerms: false,
            checkAge: false,
            checkGetMail: false,
            checkSendMail: true, //
            btnCheck: false,
            btnCheck2: false,
            params: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },

    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type})
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
              this[dataName] = true;
            } else {
              this[dataName] = false;
            }
            if (this[dataName]) {
              this.btnCheck = false;
              this.btnCheck2 = false;
            }
        },


        checkValidator() {
          if (!this.checkTerms) {
            this.btnCheck = true;
            return false;
          }
          if (!this.checkAge) {
            this.btnCheck2 = true;
            return false;
          }

          let validatorArr = ['nick', 'referrerNickChk'];
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.updateMember();
                } else {
                    this.errorAlert(this.$t('r_register'));
                }
            })
        },
        updateMember() {
            try {
                let params = {
                    mb_nick: this.nick,
                    mb_is_verify: 1
                }
                this.params = params;

                this.$api.$member.updateSocialMember(this.params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        if (util.isEmpty(this.referrerNick)) {
                            this.createAlert({
                                title: '',
                                content: this.$t('c_register'),
                                btnText: this.$t('yes'),
                                hide: () => {
                                    this.$router.push('/auth/register/after')
                                }
                            });
                        } else {
                            this.setReferrer();
                        }
                    } else if (res.Info.type === -241) {
                        this.referrerIdChk = false;
                    } else {
                        this.errorAlert(this.$t('r_register'));
                    }
                });
            } catch (e) {
                console.log(e);
            }
        },
        setReferrer() {
            let params = {
                recommender: this.referrerNick
            }
            this.$api.$member.updateRecommend(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.createAlert({
                        title: '',
                        content: this.$t('c_register'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.push('/auth/register/after')
                        }
                    });
                }
            })
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
