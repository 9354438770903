const UtilityConfig = {
  utilityDetail: {
    detailLayout: 'UtilityDetailLayout',
    getFunc: 'getUtility',
    detailApiParamSet: {
      // uu:1,
      // ul: 1,
      uucnt: 1,
    },
    keyName: 'u_idx',
  },
  utilityCollect: {
    filterLayout: 'UtilityFilterMemberLayout',
    listLayout: 'UtilityListDefaultLayout',
    emptyLayout: 'UtilityEmptyDefaultLayout',
    paginationType: 'pagination',
    paginationLayout: 'PaginationDefaultLayout',
    getFunc : 'getUtility',
    listApiParamSet : {
      ip: 1,
      sp: 10,
    },
    keyName: 'c_idx'
  },
  utilityDetailNticket:{
    listLayout: 'UtilityListDefaultLayout',
    emptyLayout: 'UtilityEmptyDefaultLayout',
    getFunc: 'getUtility',
    listApiParamSet: {
      ip: 1,
      sp: 5,
      uu:1,
      uu_is_deal : [0,1]
    },
    keyName: 'i_idx',
    pageRow : 5,
    paginationType: 'pagination',
    paginationLayout: 'PaginationDefaultLayout',
    parserType:'none',
  },
}


export default {
  methods: {
    returnUtilityConfig(type) {
      return UtilityConfig[type];
    },
  },
  filterConfig: {
    usest: { //사용상태
      type: 'terms',
      classname: 'useSelect',
    },
    owner_nick: { // 소유자 닉네임
      type: 'query',
      classname: 'searchInput',
    },
    i_name: { // 연결된 아이템
      type: 'query',
      classname: 'searchInput',
    },
    obs: {
      type: 'match',
      classname: 'tabObs'
    },
    u_name: {
      type: 'query',
      classname: 'searchInput'
    },
    obt: {
      type: 'match',
      classname: 'tabObt'
    },
    ni: { // 닉네임
      type: 'query',
      classname: 'searchInput',
    },
    minprice : {
      type : 'match',
      classname: 'minprice',
    },
    maxprice : {
      type : 'match',
      classname: 'maxprice',
    },
    uc_idx : {
      type : 'match',
      classname: 'categoryStatus',
    },
    sellst : {
      type: 'terms',
      classname: 'dealSelect',
    },
    mb_nick: { // 선물 가능한 회원 목록 닉네임
      type: 'query',
      classname: 'searchInput',
    },
    type: { // 회원 유형
      type : 'match',
      classname: 'memberType',
    },
    is_curator: { // 큐레이터 유형
      type : 'match',
      classname: 'curatorType',
    },
    min_like: {
      type : 'match',
      classname: 'minLike',
    },
    max_like: {
      type : 'match',
      classname: 'maxLike',
    },
    min_icnt: {
      type : 'match',
      classname: 'minIcnt',
    },
    max_icnt : {
      type : 'match',
      classname: 'maxIcnt',
    },
  }
}
