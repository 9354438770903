<template>
    <div>
        <utility-filter-link v-if="utilityType != 'utilityDetailNticket'" :filterLayout="filterLayout" :utilityCategoryList="utilityCategoryList"></utility-filter-link>
        <utility-list-link :listLayout="listLayout" :utilityData="List" :utilityKey="utilityKey"
                           v-if="List.length !== 0"></utility-list-link>
        <utility-empty-link :empty-layout="emptyLayout" :utilityKey="utilityKey" v-else></utility-empty-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"></pagination-link>
    </div>
</template>

<script>
import UtilityUtil from '@/components/utility/UtilityUtil'
import UtilityFilterLink from '@/components/utility/link/UtilityFilterLink.vue'
import UtilityListLink from '@/components/utility/link/UtilityListLink.vue'
import PaginationLink from '@/components/utility/link/PaginationLink.vue'
import UtilityEmptyLink from "@/components/utility/link/UtilityEmptyLink.vue";
import filterMixins from "@/mixins/filterMixins";
import listMixins from "@/mixins/listMixins";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import utilityUtil from "@/components/utility/UtilityUtil";
import util from "@/mixins/util";
import {mapState} from "vuex";

const {filterConfig} = utilityUtil;

export default {
    name: "UtilityListComponent",
    mixins: [alertMixins, filterMixins, listMixins, UtilityUtil],
    components: {
        UtilityEmptyLink,
        UtilityFilterLink,
        UtilityListLink,
        PaginationLink
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
        }
    },
    props: {
        utilityType: {
            default: () => '',
        },
        utilityKey: {default: 0},
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
            writeFunc: '',
            writeFailTypes: {},
            writeSuccessType: {},
            writeKeyName: '',
            utilityCategoryList: {
                type: Array,
                default: () => []
            },
            paginationLayout: '',
            paginationType: '',
            pageRow: 10,
            parserType: 'url',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig);
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {

        EventBus.$on('addManager', this.addManager)
        EventBus.$on('deleteManager', this.deleteManager)
    },
    beforeUnmount() {
        EventBus.$off('addManager')
        EventBus.$off('deleteManager')
    },
    unmounted() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let config = this.returnUtilityConfig(this.utilityType);
            this.listLayout = config.listLayout;
            this.filterLayout = config.filterLayout;
            this.emptyLayout = config.emptyLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;
            this.keyName = config.keyName;
            this.writeFunc = config.writeFunc;
            this.writeFailTypes = config.writeFailTypes;
            this.writeSuccessType = config.writeSuccessType;
            this.writeKeyName = config.writeKeyName;
            this.paginationLayout = config.paginationLayout;
            this.parserType = config.parserType;
            this.paginationType = config.paginationType;
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if (config.pageRow && config.pageRow !== this.filterSet.page.size) {
                this.pageRow = config.pageRow;
                this.filterSet.page.size = config.pageRow;
                this.pageClass.setterPage('size', this.pageRow);

            }
            if (!util.isEmpty(this.keyName)) {
                params[this.keyName] = this.utilityKey
            }
            let listOptions = {}
            if (this.utilityType === 'utilityManagerAddition') {
                listOptions = {
                    url: this.$api.$utility[this.getFunc],
                    defaultParams: params,
                }
            } else if (this.utilityType === 'utilityMember') {
                listOptions = {
                    url: this.$api.$utility[this.getFunc],
                    defaultParams: params,
                }
            } else {
                listOptions = {
                    url: this.$api.$utility[this.getFunc],
                    defaultParams: params,
                }
            }

            this.initList('api', listOptions);
            this.getList(true)
        },
        checkIsListEmpty() {
            if (this.List.length == 0) {
                EventBus.$emit('checkDataIsEmpty', this.utilityType);
            }
        },
        addManager(mbArr) {
            EventBus.$emit('apiLoading', true);
            let params = {
                mbArr: [],
                u_idx: this.utilityKey,
            };
            for (let i = 0; i < mbArr.length; i++) {
                params.mbArr[i] = {mb_no: mbArr[i]}
            }
            try {
                this.$api.$utility[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('apiLoading', false);
                    let type = res.Info.type;
                    if (type === 1) {
                        this.afterFunc();
                    } else {
                        EventBus.$emit('apiLoading', false);
                        this.errorAlert(this.returnType(type));
                        this.isRunning = false;
                    }
                })
            } catch (e) {
                EventBus.$emit('apiLoading', false);
                this.errorAlert(this.$t('fail_to_upload'));
                this.isRunning = false;
            }
        },
        deleteManager(mbno) {
            EventBus.$emit('apiLoading', true);
            let params = {
                mb_no: mbno,
                u_idx: this.utilityKey
            };

            try {
                this.$api.$utility[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('apiLoading', false);
                    let type = res.Info.type;
                    if (type === 1) {
                        this.deleteAfterFunc();
                    } else {
                        EventBus.$emit('apiLoading', false);
                        this.errorAlert(this.returnType(type));
                        this.isRunning = false;
                    }
                })
            } catch (e) {
                EventBus.$emit('apiLoading', false);
                this.errorAlert(this.$t('error_delete_manager'));
                this.isRunning = false;
            }
        },
        afterFunc() {
            EventBus.$emit('apiLoading', false);
            this.createAlert({
                title: this.$t('add_manager'),
                content: this.$t('add_manager_content'),
                btnText: this.$t('checked'),
                hide: () => {
                    this.$router.push(`/utility/${this.utilityKey}`)
                },
            })
        },
        deleteAfterFunc() {
            EventBus.$emit('apiLoading', false);
            this.createAlert({
                title: this.$t('delete_manager'),
                content: this.$t('delete_manager_content'),
                btnText: this.$t('checked'),
                hide: () => {
                    this.getList(true)
                },
            })
        },
        returnType(type) {
            if (type == this.writeSuccessType) {
                return true;
            } else {
                return util.isEmpty(this.writeFailTypes[type]) ? this.$t('fail_to_upload') : this.writeFailTypes[type]
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
