<template>
    <div v-if="itemBannerList.length > 0" style="position: relative">
        <swiper class="item_banner_swiper" ref="swiper" :options="swiperOption"
                :slidesPerView="'2'"
                v-if="itemBannerList.length>4" @click="onClick"
                @slideChange="slideChange">
            <swiper-slide class="banner_swiper_slide banner_swiper_slide_1" v-for="(data,index) in itemBannerList"
                          :key="`itemBanner${data.i_idx}`">
                <div class="banner_swiper_slide_pic"
                     :id="`topBanner_${data.i_sku}`"
                     :style="`background-image: url(${returnThumbnail(data)});`"
                >
                </div>
                <div class="banner_swiper_slide_pic_info">
                    <div class="info_thumb">
                        <img class="info_thumb_img" v-if="checkOriginal(data)"
                             :src="returnProfile(data,'originator')" :id="`profile_${data.i_sku}`">
                    </div>
                    <div class="info_text_box" :id="`text_${data.i_sku}`">
                        <div class="info_title" :id="`name_${data.i_sku}`">
                            {{ data.i_name }}
                        </div>
                        <div class="info_id" v-if="checkOriginal(data)" :id="`creater_${data.i_sku}`">
                            by @{{ data.originator_nick }}
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination contest_swiper_pagination" style="bottom:0 !important; text-align:center"
                 slot="pagination"></div>
        </swiper>
        <swiper class="item_banner_swiper" ref="swiper" :options="swiperOption2"
                :slidesPerView="'2'"
                v-else @click="onClick"
                @slideChange="slideChange">
            <swiper-slide class="banner_swiper_slide banner_swiper_slide_1" v-for="(data,index) in itemBannerList"
                          :key="`itemBanner${data.i_idx}`">
                <div class="banner_swiper_slide_pic"
                     :id="`topBanner_${data.i_sku}`"
                     :style="`background-image: url(${returnThumbnail(data)});`"
                ></div>
                <div class="banner_swiper_slide_pic_info">
                    <div class="info_thumb">
                        <img class="info_thumb_img" v-if="checkOriginal(data)" :id="`profile_${data.i_sku}`"
                             :src="returnProfile(data,'originator')">
                    </div>
                    <div class="info_text_box">
                        <div class="info_title" :id="`name_${data.i_sku}`">
                            {{ data.i_name }}
                        </div>
                        <div class="info_id" v-if="checkOriginal(data)" :id="`creater_${data.i_sku}`">
                            by @{{ data.originator_nick }}
                        </div>
                    </div>
                </div>
            </swiper-slide>
            <div class="swiper-pagination contest_swiper_pagination" style="bottom:0 !important; text-align:center"
                 slot="pagination"></div>
        </swiper>
        <div class="swiper_btn swiper-button-prev item_list_prev" slot="button-prev"></div>
        <div class="swiper_btn swiper-button-next item_list_next" slot="button-next"></div>
    </div>
</template>

<script>
import ItemListLink from "@/components/item/ItemListLink";
import ItemEmptyLink from "@/components/item/ItemEmptyLink";
import ItemFilterLink from "@/components/item/ItemFilterLink";
import PaginationLink from "@/components/item/PaginationLink";
import itemUtil from "@/components/item/itemUtil";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "itemListMarketTopComponent",
    mixins: [itemUtil, imageOption],
    components: {
        ItemListLink,
        ItemEmptyLink,
        ItemFilterLink,
        PaginationLink,
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemType: '',
        categoryKey: 0,
    },
    data() {
        return {
            getFunc: '',
            listApiParamSet: {},
            itemBannerList: {},

            swiperOption: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false
                },
                navigation: {
                    nextEl: '.item_list_next',
                    prevEl: '.item_list_prev',
                },

                grabCursor: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
            },
            swiperOption2: {
                slidesPerView: 2,
                spaceBetween: 10,
                slidesPerGroup: 2,
                navigation: {
                    nextEl: '.item_list_next',
                    prevEl: '.item_list_prev',
                },
                loop: true,
                // grabCursor: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
            },


        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let itemTemplateConfig = this.returnItemTemplateConfig(this.itemType);
            this.listApiParamSet = itemTemplateConfig.listApiParamSet;
            this.getFunc = itemTemplateConfig.getFunc;
            this.getList();
        },
        getList() {
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if (Number(this.categoryKey) !== 0) {
                params.icid = this.categoryKey;
            }
            this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    return false;
                }
                console.log(res.List)
                this.itemBannerList = res.List
            })
        },
        checkOriginal(data) {
            return !util.isEmpty(data) && !util.isEmpty(data.originator_nick);
        },
        returnProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(52, 52, 'png')}`;
            }
            return 'http://minglemint.com/profile/no_image.png?w=40&h=40&f=png'
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(383, 233, 'jpg')}`
            }
        },
        movePage(data) {
            this.$router.push(`${this.$route.path}/${data}`)

        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId)) {
                return false;
            }
            let elementIdx = elementId.split('_');
            this.movePage(elementIdx[1]);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
