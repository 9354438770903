<template>
    <div class="mypage_list_wrap mb30 border-top">
        <div class="mypage_list " v-for="(competition, index) in competitionData"
             :key="`my-competition${competition.c_idx}`"
             @click="movePage(`/competition/${competition.c_uuid}`)">
            <div class="item_information">
                <div class="list_time" v-html="returnDateTime(competition.created_at) "></div>

            </div>
            <div class="item_img_wrap mt11">
                <div class="item_img competition_img"
                     :style="`background-image: url(${returnImage(competition.Poster,140,194)});`">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold mb5">[{{ returnStatus(competition.c_status) }}]</div>
                    <div class="item_text mb2">{{ competition.c_title }}</div>
                    <div class="item_text mb2">
                        <span>{{ $t('e_period') }}</span> {{ returnApplyDate(competition) }}
                    </div>
                    <div class="item_text mb5">
                        <span>{{ $t('d_day') }}</span> {{ returnDateTime(competition.c_release_time) }}
                    </div>
                    <img src="@/assets/image/mg_icon.png">
                    <div class="item_text competition_text bold">{{ returnCommas(competition.c_prize) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "CompetitionListMyCompetitionLayout",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => {
                return []
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY-MM-DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY-MM-DD')
            return `${s_time} ~  ${e_time}`
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY.MM.DD')
            let r_time = this.$moment(time).format('HH:mm')
            return `${r_date}  ${r_time}`;
        },
        returnStatus(status) {
            switch (status) {
                case 0 : {
                    return this.$t('scheduled_to_proceed')
                }
                case 1 : {
                    return this.$t('in_process2')
                }
                case 2 : {
                    return this.$t('under_review')
                }
                case 3 : {
                    return this.$t('p_completed')
                }
                default : {
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
