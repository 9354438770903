<template>
<div>
    ItemListPremiumMarketCreate
</div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListPremiumMarketCreate",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                //return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_idx}`)
            } else {
                this.$router.push(`/item/${data.i_idx}`);
            }
        },
        openModal(data){
            this.$modal.show('premium-market-add-modal',{itemData:data})
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
