<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="padding_side dgm_wrap">
<!--            <div class="notice_tabs">-->
<!--                <div class="notice_item_3_tab active font_white">-->
<!--                    {{ $t('notice') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab" @click="movePage('/faq')">-->
<!--                    {{ $t('detail_faq') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab" @click="movePage('/qna')">-->
<!--                    {{ $t('qna') }}-->
<!--                </div>-->
<!--            </div>-->
            <board-list-component :boardType="'notice'"></board-list-component>
        </div>

    </div>


</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";

export default {
    name: "Notice",
    mixins: [],
    components: {
        BoardListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        // paginationData: {default: {page: 1, size: 10, total: 0,}},
        // listLength: {default: 0},
        // finishGetList: {default: true}
    },
    data() {
        return {
            // page: 1,
            // size: 10,
            // sizeList: [10, 30, 50],
            // maxPage: 0,
            // search_input: ""
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changePage(key, value) {
            this.setPage(key, value);
            window.scrollTo(0, 0);
        },
        movePage(url) {
            this.$router.push(url)
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
