import { render, staticRenderFns } from "./PortfolioListComponent.vue?vue&type=template&id=41a20b51&scoped=true"
import script from "./PortfolioListComponent.vue?vue&type=script&lang=js"
export * from "./PortfolioListComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41a20b51",
  null
  
)

export default component.exports