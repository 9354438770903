<template>
    <div class="mypage_update_wrap pt20 border-top ">
        <div class="update">
            <div class="update_title">{{ $t('id') }}</div>
            <div class="update_id">{{ UserInfo.mb_id }}</div>
        </div>
        <div class="update no_btn pt0">
            <div class="update_title">{{ $t('nick') }}</div>
            <div class="update_id_pass"  v-if="checkMemberData(UserInfo.mb_nick)">{{ UserInfo.mb_nick }}</div>
            <!--            <button class="update_btn"  v-if="checkMemberData(UserInfo.mb_nick)" @click="openModal('nickname-change-modal')">
                            <img src="@/assets/image/icon_update.png">
                        </button>-->
            <button class="enroll_btn_wrap" v-else @click="openModal('nickname-change-modal')">
                <div class="enroll_btn">{{ $t('enroll') }}</div>
            </button>
        </div>
        <div class="update" v-if="returnSocialType() === 'email'">
            <div class="update_title">{{ $t('password') }}</div>
            <div class="update_id_pass">**********</div>

            <button class="update_btn" @click="openModal('password-change-modal')">
                <img src="@/assets/image/icon_update.png">
            </button>
        </div>
        <div class="update">
            <div class="update_title">{{ $t('phoneNumber') }}</div>
            <div class="update_id_pass"  v-if="checkMemberData(UserInfo.mb_hp)">{{ UserInfo.mb_hp }}</div>
            <button class="update_btn"  v-if="checkMemberData(UserInfo.mb_hp)" @click="runAuthenticatio()">
                <img src="@/assets/image/icon_update.png">
            </button>
            <button class="enroll_btn_wrap" v-else @click="runAuthenticatio('tel-enroll-modal')">
                <div class="enroll_btn">{{ $t('enroll') }}</div>
            </button>
        </div>
        <div class="update" :class="{'no_btn': checkMemberData(UserInfo.mb_name)}">
            <div class="update_title">{{ $t('name_authentication') }}</div>
            <div class="update_id_pass "  v-if="checkMemberData(UserInfo.mb_name)">{{ UserInfo.mb_name }}</div>

            <button class="enroll_btn_wrap" v-else @click="runAuthenticatio('tel-enroll-modal')">
                <div class="enroll_btn">{{ $t('enroll') }}</div>
            </button>
            <!--            <button class="enroll_btn_wrap" @click="runAuthenticatio('certification-modal')">
                            <div class="enroll_btn">{{ $t('enroll') }}</div>
                        </button>-->
        </div>
        <div class="update">
            <div class="update_title">{{ $t('bank_add') }}</div>
            <div class="update_id_pass"  v-if="checkMemberData(UserInfo.Account)">
                {{ UserInfo.Account[0].ma_name }} /
                {{ UserInfo.Account[0].ma_bank }} /
                {{ UserInfo.Account[0].ma_account }}
            </div>
            <button class="update_btn"  v-if="checkMemberData(UserInfo.Account)" @click="certificationConfirm()">
                <img src="@/assets/image/icon_update.png">
            </button>
            <button class="enroll_btn_wrap" v-else @click="certificationConfirm()">
                <div class="enroll_btn">{{ $t('enroll') }}</div>
            </button>
            <div class="validation" v-if="UserInfo.g_idx != 2">{{$t('account_warning')}}</div>
        </div>
        <div class="update">
            <div class="update_title margin_top">{{ $t('r_nick') }}</div>
            <div class="recommend_id" v-if="checkMemberData(UserInfo.Recommend)">{{ UserInfo.Recommend[0].mb_nick }}</div>
            <button class="enroll_btn_wrap" @click="openModal('refferer-add-modal')" v-show="!checkMemberData(UserInfo.Recommend)">
                <div class="enroll_btn">{{ $t('enroll') }}</div>
            </button>
        </div>
        <nickname-change-modal></nickname-change-modal>
        <password-change-modal></password-change-modal>
        <tel-enroll-modal></tel-enroll-modal>
        <certification-modal></certification-modal>
        <account-registration-modal></account-registration-modal>
        <refferer-add-modal></refferer-add-modal>
        <member-authentication-component></member-authentication-component>
        <bank-add-modal></bank-add-modal>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import NicknameChangeModal from "@/components/modal/NicknameChangeModal";
import PasswordChangeModal from "@/components/modal/PasswordChangeModal";
import TelEnrollModal from "@/components/modal/TelEnrollModal";
import CertificationModal from "@/components/modal/CertificationModal";
import AccountRegistrationModal from "@/components/modal/AccountRegistrationModal";
import ReffererAddModal from "@/components/modal/ReffererAddModal";
import MemberAuthenticationComponent from "@/components/member/MemberAuthenticationComponent";
import BankAddModal from "@/components/modal/BankAddModal";

export default {
    name: "MemberWriteDefaultLayout",
    mixins: [alertMixins, AuthMixins],
    components: {
        AccountRegistrationModal,
        CertificationModal,
        TelEnrollModal,
        PasswordChangeModal,
        NicknameChangeModal,
        ReffererAddModal,
        MemberAuthenticationComponent,
        BankAddModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData:{}
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('MemberWriteDefaultLayoutForceUpdate', this.layoutForceUpdate);
    },
    beforeDestroy() {
        EventBus.$off('MemberWriteDefaultLayoutForceUpdate');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        certificationConfirm() {
            if(this.isAuthenticated()) {
                console.log('test')
                this.$modal.show('bank-add-modal');
            }else{
                this.$modal.show('tel-enroll-modal');
                /*this.createConfirm({
                    content: this.$t('popup_authentication'),
                    confirmText: this.$t('do_enroll'),
                    cancelText: this.$t('do_later'),
                    confirm: () => {

                    },
                    cancel: () => {

                    }
                })*/
            }
        },
        openModal(name) {
            this.$modal.show(name);
            // $("body").addClass("modal-open");
        },
        checkMemberData(data) {
            return !util.isEmpty(data);
        },
        layoutForceUpdate() {
            this.$forceUpdate()
        },
        runAuthenticatio() {
            EventBus.$emit('runAuthenticatio')
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
