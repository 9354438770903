<template>
    <div>
        <div class="login_signup_input nickname_change mb25">
            <input type="text" class="register_email" :placeholder="$t('change_nick')" maxlength="20"
                   v-model="nick"
                   @input="parentChange('nick',nick)"
                   @keypress.enter="parentChange('nick',nick)"/>
            <!--       닉네임 중복 체크-->
            <div class="validation" v-if="validation.hasError('nick')">
                {{ validation.firstError('nick') }}
            </div>
            <div class="validation" v-else-if="validation.hasError('nickCheckDuplicate')">
                {{ validation.firstError('nickCheckDuplicate') }}
            </div>
        </div>
    </div>
</template>


<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWriteNickLayout",
    mixins: [alertMixins,memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('checkData',this.checkNick);
    },
    beforeDestroy() {
        EventBus.$off('checkData');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        modalClose(){
            this.nick='';
            this.$modal.hide('mypage-nick-modal')
        },
        save(){
            EventBus.$emit('updateMember')
        },
        checkNick(type){
            this.checkNickAfter(type)
        },
        parentChange(type, value) {
            // this.checkNickDuplicate();
            this[type] = value;
            this.memberSetData(type, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
