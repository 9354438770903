<template>
    <div class="padding_side">
        <div class="border-top pt14">
            <div class="item_premium_list" v-for="(data,index) in itemData" :key="`item${index}`"
                 @click="movePage(data)">
                <div class="img_wrap">
                    <div class="img_bg " :style="`background-image: url(${returnThumbnail(data)});`"></div>
                    <div class="art_card">Art</div>
                </div>
                <div class="content_wrap">
                    <div class="subject_wrap">{{ data.i_name }}</div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListInterestLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
                return `${data.ItemFile.Item[0].org_url}`;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
