<template>
    <button class="sns_login_wrap mr22" @click="googleSignIn()">
        <!--        <button class="google_login_wrap" @click="movePage('/auth/sns')">-->
        <!--            <img src="@/assets/image/main/google.png">-->
    </button>
</template>

<script>
import EventBus from "@/utils/event-bus";
import {getTokenObj, loginTokenSet} from "@/mixins/Auth";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "GoogleLoginComponent",
    mixins: [AuthMixins, alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            //'/snsAuth'
            this.$router.push(url);
        },
        async googleSignIn() {
            const googleUser = await this.$gAuth.signIn()
            console.log(googleUser)
            // googleUser.getId() : Get the user's unique ID string.
            // googleUser.getBasicProfile() : Get the user's basic profile information.
            // googleUser.getAuthResponse() : Get the response object from the user's auth session. access_token and so on
            let isSignIn = this.$gAuth.isAuthorized
            console.log(isSignIn)

            if (isSignIn) {
                this.snsLogin(googleUser);
            }
        },
        snsLogin(data) {
            console.log('snsLogin', data)
            console.log(data.getId());
            let params = {
                userId: data.getId(),
            };
            if(data.hasOwnProperty('wc')) {
                params.code = data.wc.access_token;
            }
            if(data.hasOwnProperty('vc')) {
                params.code = data.vc.access_token;
            }
          if(data.hasOwnProperty('Sc')) {
            params.code = data.Sc.access_token;
          }
            if(data.hasOwnProperty('Oc')) {
                params.code = data.Oc.access_token;
            }
            if(data.hasOwnProperty('Fa')) {
                params.userId = data.Fa;
            }
            /*let params = {
                code: data.wc.access_token,
                userId: data.Ba
            };*/
            EventBus.$emit('apiLoading', true);
            this.$api.$auth.googleLogin(params).then(res => res.Data.Result).then((res) => {
                if (res.Info.type === 1) {
                    this.loginSns(res.List, 'google');
                } else {
                  EventBus.$emit('apiLoading', false);
                    this.createAlert({
                        title: "",
                        content: this.$t('error_msg2'),
                        btnText: this.$t('yes'),
                        confirmBtn: true,
                        closeBtn: true,
                        hide: () => {
                            window.close();
                        }
                    })
                }
            })
        },

        googleDisconnect() {
            console.log('test')
            this.$gAuth.GoogleAuth.disconnect();
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
