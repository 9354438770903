<template>
    <div class="radio_wrap">
        <li class="item_name">
            <h3 class="sub_title_m regular warning_icon">{{ $t('i_name') }}</h3>
            <div class="w_content_box">
                <h3 class="sub_title_s regular">{{ $t('sub_item') }}</h3>
                <span class="input_max_length"><span>{{ title.length }}</span>/50</span>

                <el-input class="input_txt" type="text" v-model="title"
                          @input="parentChange('title',title)" :placeholder="$t('write_sub_item')"
                          maxlength="50"/>
            </div>
            <div class="val_cap" v-if="validation.hasError('title')">{{
                    validation.firstError('title')
                }}
            </div>
        </li>
        <li class="category_select">
            <h3 class="sub_title_m regular warning_icon">{{ $t('category') }}</h3>
            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="category" @change="setCategory(Number(category))">
                    <el-radio class="el_radio_btn" :value="Number(data.node_id)" v-for="data in itemCategoryList"
                              :key="`${data.node_id}`" :label="data.node_id">{{ returnCategoryName(data) }}
                    </el-radio>
                </el-radio-group>
            </div>
        </li>
    </div>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemBasicLayout",
    mixins: [],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setCategoryData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            title: '',
            category: '',
            validatorArr: ['title', 'category'],
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
        this.setValidatorArr(this.validatorArr)
        this.initCategory();
        EventBus.$on('basicParentChange', this.setTitleData);
    },
    beforeDestroy() {
        EventBus.$off('basicParentChange');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.title = this.itemData.title;
            this.category = this.itemData.category;
            this.setCategory(this.category)
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        setTitleData(value) {
            this.title = value
            this.setComponentData('title', value);
        },
        initCategory() {
            if (!util.isEmpty(this.itemCategoryList) && this.category == '') {
                this.category = this.itemCategoryList[0].PKEY;
                this.setCategory(this.itemCategoryList[0].PKEY)
            }
        },
        setCategory(value) {
            this.parentChange('category', value);
            let index = this.itemCategoryList.findIndex(cate => cate.PKEY == value);
            if (index > -1) {
                let categoryData = this.itemCategoryList[index];
                this.setCategoryData(categoryData)
            }
        },
        returnCategoryName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.NAME
            }
            return data.NAME_ENG
        },
        checkValidator() {
            this.$validate(['title', 'category'])
        },
    },
    watch: {
        'itemCategoryList': {
            deep: true,
            handler: function (val, oldVal) {
                this.initCategory();
            }
        }
    },
    validators: {
        title: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg1'))
        },
        category: function (value) {
            return Validator.value(value)
                    .required(this.$t('itemErrorMsg3'))
        },
    },
}
</script>

<style scoped>

</style>
