<template>
    <div>
        CompetitionFilterArtLayout
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "CompetitionFilterArtLayout",
    mixins: [],
    components: {

    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            searchOption: 'ina',
            sortOption: 'i.updated_at',
            searchInput: '',
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['',''],
            popoverStatus: false,

            listChange: false,

            artCheck: false,
            collectionCheck: false,
            photoCheck: false,
            musicCheck: false,
            videoCheck: false,

            proceedCheck: false,
            proceedingCheck: false,
            underReviewCheck: false,
            presentationCheck: false,

        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        openModal(name){
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        listTypeChange(){
            this.listChange = !this.listChange;
            EventBus.$emit('changeListType');
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'asc');
            } else if (data === 'priceDesc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'desc');
            } else {
                this.setFilterFunc('obt', data);
                this.setFilterFunc('obs', 'desc');
            }
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if(this[dataName] ) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if(this[dataName]) {
                this.btnCheck = false;
            }
        },
    },
    watch: {
    },
}
</script>

<style scoped>

</style>
