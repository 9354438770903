<template>
    <div class="gallery_edit_wrap ">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
<!--        <page-header class="gallery_edit_header" :header_white_back="true"  :header_setting="true"></page-header>-->
        <gallery-detail-component galleryType="galleryBanner" :galleryKey="galleryKey"></gallery-detail-component>
        <div class="g_add_item" @click="movePage(`/gallery/${galleryKey}/itemadd`)">{{$t('add_item')}}</div>
        <div class="clear"></div>
        <gallery-list-component :gallery-type="'galleryEdit'" :defaultFilter="defaultFilter"></gallery-list-component>
    </div>
</template>

<script>
import GalleryDetailComponent from "@/components/gallery/GalleryDetailComponent";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";

export default {
    name: "GalleryEdit",
    mixins: [],
    components: {
        GalleryDetailComponent,
        GalleryListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            galleryKey: Number(this.$route.params.idx),
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {cid: this.galleryKey};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name, {galleryType: 'galleryAdd'});
        },
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
