<template>
    <div>
        <!--썸네일형 리스트-->
        <div class="competition_list mt10 pb30" v-for="(competition, index) in competitionData"
             :key="`competition-thumb-${competition.c_idx}`" v-show="listType === 'thumbnail'"
             @click="moveDetail(competition.c_uuid)">
            <div class="competition_thumb_content"
                 :id="`mainCompetition${competition.c_uuid}`"
                 :style="`background-image : url(${returnImage(competition.Poster,283,390)})`"
            >
                <div class="c_category">
                    {{ returnCategoryName(competition.cc_idx) }}
                </div>
                <div class="contest_info_box">
                    <div class="contest_content_area">{{ competition.c_title }}</div>
                    <div class="content_box">
                        <div class="contest_category_area">
                            <div class="c_day">
                                <competition-date-time
                                    :competition-data="competitionData[index]"></competition-date-time>
                            </div>
                        </div>
                        <div class="content_mingle_area">
                            <div class="c_mingle_img"></div>
                            <div class="c_mingle_price">{{ returnCommas(competition.c_prize) }} Mg</div>
                        </div>
                        <div class="c_won_price">({{ returnMingle(competition.c_prize) }})</div>
                    </div>
                </div>
            </div>
        </div>

        <!--목록형 리스트-->

        <div class="competition_art_list_wrap" v-show="listType === 'list'">
            <div class="item_img_wrap mt11" v-for="competition in competitionData"
                 :key="`competition-list-${competition.c_idx}`">
                <div class="item_img" @click="moveDetail(competition.c_uuid)"
                     :style="`background-image : url(${returnImage(competition.Poster,260 ,360)})`">
                </div>
                <div class="item_text_list">
                    <div class="art_box">
                        <div class="art_text" @click="moveDetail(competition.c_uuid)">
                            {{ returnCategoryName(competition.cc_idx) }}
                        </div>
                        <div class="heart_wrap" >
                            <div class="heart_box" @click="likeCompetition(competition.c_idx)">
                                <img src="@/assets/image/s_heart_color.png" v-if="isMyLike(competition)" alt="">
                                <img src="@/assets/image/s_heart.png" v-else alt="">
                                <span class="count">{{ returnCommas(competition.likeCnt) }}</span>
                            </div>

                            <div class="eye_box" @click="moveDetail(competition.c_uuid)">
                                <img src="@/assets/image/s_eye.png" alt="">
                                <span class="count">{{ returnCommas(competition.c_hit) }}</span>
                            </div>
                        </div>
                        <div class="clear"></div>

                    </div>
                    <div class="art_list_title_box mt10" @click="moveDetail(competition.c_uuid)">

                        <div class="item_text"> {{ competition.c_title }}</div>
                        <div class="hash_tag" v-for="(hash,index) in returnHashtag(competition.Hashtag)"
                             :key="`hash-${index}`"> #{{ hash }}
                        </div>
                    </div>
                    <div class="art_bottom mt5" @click="moveDetail(competition.c_uuid)">
                        <div class="art_proceed">
                            {{ returnStatus(competition.c_status) }}
                        </div>
                        <div class="art_terms_text">
                            <span>{{ $t('entry') }}</span>
                            {{ returnApplyDate(competition) }}
                        </div>
                        <div class="art_terms_text">
                            <span>{{ $t('presentation') }}</span>
                            {{ returnReleaseDate(competition.c_release_time) }}
                        </div>
                        <div class="art_mingle mt4">
                            <span>{{ $t('t_prize') }}</span>
                            <img src="@/assets/image/mg_icon.png">
                            {{ returnCommas(competition.c_prize) }}Mg
                            <div class="c_won_price">({{ returnMingle(competition.c_prize) }})</div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </div>
</template>


<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import CompetitionCategoryMixin from "@/mixins/CompetitionCategoryMixin";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import priceMixins from "@/mixins/priceMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";


export default {
    name: "CompetitionListDefaultLayout",
    mixins: [imageOption, CompetitionCategoryMixin, priceMixins, imageResizeMixins],
    components: {CompetitionDateTime},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {
            listType: 'list',
            lang: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.lang = this.$localStorage.get('lang');
    },
    mounted() {
        EventBus.$on('changeListType', this.changeListType);
    },
    beforeDestroy() {
        EventBus.$off('changeListType');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        moveDetail(data) {
            this.$router.push(`/competition/${data}`)
        },
        changeListType(type) {
            this.listType = type;
        },

        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY. MM. DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY. MM. DD')
            return `${s_time} ~ ${e_time}`
        },
        returnReleaseDate(time) {
            let r_time = this.$moment(time).format('YYYY. MM. DD')
            return r_time;
        },
        returnProfile(profile) {
            if (!util.isEmpty(profile)) {
                return `${profile[0].org_url}?${this.setImageOptions(64, 64, this.returnExtension(profile[0].org_url))}`
            }
            return ''

        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
        returnStatus(status) {
            switch (status) {
                case 0 : {
                    return this.$t('scheduled_to_proceed')
                }
                case 1 : {
                    return this.$t('in_process2')
                }
                case 2 : {
                    return this.$t('under_review')
                }
                case 3 : {
                    return this.$t('p_completed')
                }
                default : {
                    return ''
                }
            }
        },

        returnHashtag(hashtag) {
            let list = []
            util.each(hashtag, (hash, index, arr) => {
                if (!util.isEmpty(hash.ic_name) && hash.ch_type === 1) {
                    list.push(hash.ic_name)
                }
                if (!util.isEmpty(hash.ch_hashtag) && hash.ch_type === 2) {
                    list.push(hash.ch_hashtag)
                }
            })
            return list
        },
        isMyLike(data) {
            return data.LikeStatus
        },
        likeCompetition(key) {
            EventBus.$emit('likeCompetition', key);
        },
        checkLang() {
            this.lang = this.$localStorage.get('lang');
            if (this.lang === 'en') {
                return true
            }
            return false
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
