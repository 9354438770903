<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap padding_side">

            <award-list-component :award-type="'award'" :award-key="awardKey"></award-list-component>

        </div>
    </div>
</template>

<script>
import AwardListComponent from "@/components/award/AwardListComponent";
import JudgementResultModal from "@/components/modal/JudgementResultModal";

export default {
    name: "AwardList",
    mixins: [],
    components: {
        AwardListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            awardKey: this.$route.params.c_idx
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
