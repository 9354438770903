<template>
    <div class="">
        <div class="p_edit_btn_box">
            <div class="portfolio_edit_btn" @click="movePage('/portfolio/edit')">{{ $t('introduce_setting') }}</div>
        </div>
        <div class="clear"></div>
        <member-detail-component :memberType="'memberPortfolio'" :memberKey="UserInfo.mb_uuid"></member-detail-component>
    </div>
</template>

<script>
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import PortfolioListComponent from "@/components/portfolio/PortfolioListComponent";
import MemberDetailComponent from "@/components/member/MemberDetailComponent";

export default {
    name: "MyProfilePortfolio",
    mixins: [AuthMixins,],
    components: {
        PortfolioListComponent,
        MemberDetailComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'galleryMy',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        // -0--
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
