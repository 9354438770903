<template>
    <modal name="judgement-result-modal"
           class="judgement-result-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :width="'90%'"
           :height="'320px'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>

            <div class="update-change-modal_wrap ">
                <div class="nickname_title mb25">
                    {{ $t('awardResult') }}
                </div>
                <div class="rollback_btn" @click="modalClose()"></div>
                <div class="clear"></div>
                <div class="judgement_content">
                    <div class="j_c_write">
                        <textarea readonly v-model="reason"></textarea>
                    </div>
                </div>
                <button class="one_btn back_navy mt30" @click="modalClose()">
                    {{ $t('yes') }}
                </button>

                <div class="clear"></div>
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";

export default {
    name: "JudgementResultModal",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            reason: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.reason)) {
                event.stop()
            } else {
                this.reason = event.params.reason
            }
        },
        handlerOpened() {
            this.initResult()
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('judgement-result-modal');
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
