<template>
    <div class="competition_wrap">
        <div class="competition_item">
            <div class="competition_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
                </el-input>
                <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_sort_btn" @click="openFilter('competition-sort')"></button>
            <button class="c_filter_btn" @click="openFilter('competition-filter-default-filter')"></button>
            <!--목록형 아이콘-->
            <button class="c_filter_list_btn"
                    v-show=" listChange === 'list'"
                    @click="listTypeChange('thumbnail')"></button>
            <!--썸네일형 아이콘      -->
            <button class="c_filter_thumnail_btn"
                    v-show=" listChange === 'thumbnail'"
                    @click="listTypeChange('list')"></button>
        </div>
        <div class="clear"></div>
        <competition-filter-default-filter></competition-filter-default-filter>
        <competition-sort></competition-sort>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
import CompetitionFilterDefaultFilter from "@/components/filter/CompetitionFilterDefaultFilter";
import CompetitionSort from "@/components/sort/CompetitionSort";

const {filterConfig} = competitionUtil
export default {
    name: "CompetitionFilterAllLayout",
    mixins: [],
    components: {
        CompetitionFilterDefaultFilter,
        CompetitionSort
    },
    inject: ['setFilter', 'setSort'],

    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            listChange: 'list',
            searchInputOption: 'ti',
            searchInput: '',
            sortOption: 'created_at',
            filterStatus: [],
            filterCategory: [],
            filterPrice: [],

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
        if (!util.isEmpty(this.sortData)) {
            this.setterSortDataInData()
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData, sortData: this.sortData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }
                }
            })
        },
        setterSortDataInData() {
            if (this.sortData.order === 'asc') {
                this.sortOption = `${this.sortData.key}_asc`
            } else {
                this.sortOption = this.sortData.key
            }
        },
        openModal(name) {
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        listTypeChange(type) {
            this.listChange = type;
            EventBus.$emit('changeListType', type);
        },
        setSortFunc(key) {
            let order = 'desc'
            if (key.includes('_asc')) {
                let idx = key.indexOf('_asc')
                key = key.substring(0, idx)
                order = 'asc'
            }
            this.setSort(key, order)
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
