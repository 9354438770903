<template>
    <div class="radio_wrap c_hash_wrap">
        <li class="hashtag_wrap">
            <h3 class="sub_title_m regular warning_icon inline_block">{{ $t('collection') }}</h3>
            <button class="add_default_btn float_right mb5" @click="$router.push('/collect')">{{$t('collect_move')}}</button>
            <div class="clear"></div>
            <div class="w_content_box">
                <div class="sub_title_s">
                    {{ $t('holding_collection') }}
                </div>

                <div class="w_content">
                    <el-select v-model="collect" class="hashtag_select"
                               :placeholder="$t('select_collection')"
                               popper-class="category_select"
                               :popper-append-to-body="false"
                               :no-data-text="$t('collect_empty_msg')"
                               @change="selectCollect()">
                        <el-option :value="data.c_idx" v-for="data in collectionData"
                                   :key="`collection${data.c_idx}`" :label="data.c_title">
                        </el-option>
                    </el-select>
                </div>
            </div>
            <div class="val_cap" v-if="validation.hasError('collect')">{{
                    validation.firstError('collect')
                }}
            </div>
        </li>
    </div>
</template>

<script>
import util from "@/mixins/util";
import collectionMixin from "@/mixins/collectionMixin";
import SimpleVueValidation from "simple-vue-validator";
import Vue from 'vue';

const Validator = SimpleVueValidation.Validator;
export default {
    name: "ItemCollectionLayout",
    mixins: [collectionMixin],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        collectionData: {
            default: () => {
            }, type: Array
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            collect: '',
            collectOwner: '',
            validatorArr: ['collect', 'collectOwner'],

        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData()
        }
        this.setValidatorArr(this.validatorArr);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.hashtag = this.itemData.hashtag;
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        checkValidator() {
            this.$validate(this.validatorArr);
        },
        selectCollect() {
            let index = this.collectionData.findIndex(collect => collect.c_idx === this.collect)
            if (index > -1) {
                this.collectOwner = this.collectionData[index].c_owner
            }
            this.parentChange('collectOwner', this.collectOwner)
            this.parentChange('collect', this.collect)
        }
    },
    watch: {},
    validators: {
        collect: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
        },
        collectOwner: function (value) {
            return Validator.value(value)
                    .required(this.$t('collect_select_error'))
                    .custom(() => {
                        if (value === 0 && this.status !== 9) {
                            return this.$t('collect_select_error2')
                        }
                    })
        },
    }
}
</script>

<style scoped>

</style>
