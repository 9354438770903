<template>

    <div class="judge_modal_content">
        <div class="input_box">
            <h3>{{ $t('write_point') }}</h3>
            <el-input type="number" :min="0" :max="100" v-model="score"
                      @input="parentChange('score',parseInt(score))"></el-input>
            <span>/100{{ $t('jum') }}</span>
        </div>
        <div class="input_box textarea_box">
            <h3>{{ $t('reason_write_point') }}</h3>
            <el-input type="textarea" v-model="reason" @input="parentChange('reason',reason)"></el-input>
        </div>
    </div>
</template>

<script>
export default {
    name: "JudgeWriteDefaultLayout",
    mixins: [],
    components: {},
    inject: ['JudgeSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            score: 0,
            reason: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        parentChange(type, value) {
            this[type] = value;
            this.JudgeSetData(type, value);
        },
    },
    watch: {
        'score': {
            deep: true,
            handler: function (val, oldVal) {
                let x = this.score

                if (Number(x) > 100) {
                    this.parentChange('score', '100')
                }
            }
        }
    },
}
</script>

<style scoped>

</style>
