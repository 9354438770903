<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
<!--        <page-header :header_main_back="true" :header_side="true"></page-header>-->
        <div class="padding_side dgm_wrap">
<!--            <div class="notice_tabs">-->
<!--                <div class="notice_item_3_tab" @click="movePage('/notice')">-->
<!--                    {{ $t('notice') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab active  font_white">-->
<!--                    {{ $t('detail_faq') }}-->
<!--                </div>-->
<!--                <div class="notice_item_3_tab border_r_l" @click="movePage('/qna')">-->
<!--                    {{ $t('qna') }}-->
<!--                </div>-->
<!--            </div>-->
            <board-List-component :boardType="`faq`"></board-List-component>
<!--            <board-filter-faq-layout></board-filter-faq-layout>-->
<!--            <board-list-faq-layout></board-list-faq-layout>-->
        </div>
    </div>
</template>

<script>
import BoardListComponent from "@/components/board/BoardListComponent";
import BoardFilterFaqLayout from "@/template/board/filter/BoardFilterFaqLayout";
import BoardListFaqLayout from "@/template/board/list/BoardListFaqLayout";

export default {
    name: "Faq",
    mixins: [],
    components: {
        BoardListFaqLayout,
        BoardFilterFaqLayout,
        BoardListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'faq',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.backUrl = this.$route.query.backUrl;
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.backUrl = this.boardType;
            // this.movePage(`/${tab}?backUrl=${this.backUrl}`);
            this.movePage(`/${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
