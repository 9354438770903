<template>
<!--      <div class="empty_wrap" v-if="finishGetList">
        <div class="img_box"></div>
        <div class="text_box">{{$t('no_default_list')}}</div>
      </div>
        <div class="empty_wrap" v-else v-loading="!finishGetList" :element-loading-text="''" element-loading-custom-class="dgmong_loading"></div>-->
<!--        큐레이션 리스트가 엄서용..-->
      <div>
        <div class="empty_wrap">
          <div class="img_box"></div>
          <div class="text_box">{{$t('no_default_list')}}</div>
        </div>
        <!--    응모작 리스트가 없어요..-->
      </div>
</template>

<script>
export default {
    name: "CurationEmptyDefaultLayout",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        finishGetList: {default: true},
    },
    data() {
        return{

        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
