<template>
    <div class="search_title padding_side mb15">
        {{ searchData }} {{ $t('search_result') }}
        <span class="title_bold">{{ paginationData.total }}</span> {{ $t('amount') }}
        <div class="creator_list mt10">
            <div class="creator_top mb6" v-for="(data,index) in memberData" :key="`member${index}`">
                <div class="creator_img" @click="movePage(`/creator/${data.mb_uuid}`)">
                    <div class="profile_background" :style="` background-image: url(${returnBackground(data)});`"></div>
                    <div class="curator_icon" v-if="data.g_idx === 2">{{ $t('en_curator') }}</div>
                </div>
                <div class="profile_info">
                    <div class="profile_img" :style="` background-image: url(${returnThumbnail(data)});`"
                         @click="movePage(`/creator/${data.mb_uuid}`)"></div>
                    <div class="profile_s_mark" v-if="data.mb_premium === 1"
                         @click="movePage(`/creator/${data.mb_uuid}`)"></div>
                    <div class="profile_text" @click="movePage(`/creator/${data.mb_uuid}`)">{{ data.mb_nick }}</div>
                    <div class="creator_like">
                        <div class="like_content" @click="likeMember(data.mb_no)">
                            <img src="@/assets/image/s_heart_color.png" v-if="isMyLike(data)">
                            <img src="@/assets/image/heart_img.png" v-else>
                            <div class="like_cnt">{{ data.likeCnt }}</div>
                        </div>
                        <div class="sub_point" @click="movePage(`/creator/${data.mb_uuid}`)">·</div>
                        <div class="item_subject" @click="movePage(`/creator/${data.mb_uuid}`)">Item <span class="bold">{{
                                data.itemCnt
                            }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberListSearchDetailLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: [],
        paginationData: ''
    },
    data() {
        return {
            searchData: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.searchData = this.$route.query.ni
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        returnLike(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.Like)) {
                return data.im_real_like
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
                return `${data.Files.Profile[0].org_url}?${this.setImageOptions(66, 66, 'png')}`;
            }
        },
        returnBackground(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.ProfileBack)) {
                return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(220, 140, 'jpg')}`;
            }
        },
        isMyLike(data) {
            return data.likeStatus
        },
        likeMember(data) {
            EventBus.$emit('likeMember', data, 'member');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
