<template>
    <div>
<!--        <page-header :header_main_back="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="dgm_wrap padding_side">
            <alarm-list-component :alarm-type="'alarm'"></alarm-list-component>
<!--            <AlarmListDefaultLayout></AlarmListDefaultLayout>-->
            <!--      <div class="alarm">
                  <div class="alarm_lists">
                    <div class="list_img">
                      <div class="alarm_img">
                      </div>
                    </div>
                    <div class="alarm_texts ml7">
                      <div class="alarm_content mb7">
                        <span>닉네임</span>님이 <span> <아이템 제목명1> </span>을 큐레이션 신청했습니다.
                      </div>
                      <div class="alarm_content alarm_div">
                        2분전
                      </div>
                      <div class="alarm_time"></div>

                    </div>
                    <button class="alarm_rollback">
                      <div class="rollback_img"></div>
                    </button>
                  </div>
                  <div class="alarm_lists">
                    <div class="list_img">
                      <div class="alarm_img check_img">
                      </div>
                    </div>
                    <div class="alarm_texts ml7">
                      <div class="alarm_content mb7">
                        <span><아이템 제목명></span>에 대한 경매가 종료되었습니다.
                        <br>소유자 닉네임명님이 <span>NNNN Mg</span>로 낙찰되었습니다.
                        <br>실물거래 건으로 상대방의 연락처를 공유합니다.
                      </div>
                      <button class="alarm_confirm mb4">
                        <div>연락처 확인</div>
                      </button>
                      <div class="alarm_content alarm_div mb20">
                        2분전
                      </div>
                      <div class="alarm_time"></div>

                    </div>
                    <button class="alarm_rollback">
                      <div class="rollback_img"></div>
                    </button>
                  </div>
                  <div class="alarm_lists">
                    <div class="list_img">
                      <div class="alarm_img check_img">
                      </div>
                    </div>
                    <div class="alarm_texts ml7">
                      <div class="alarm_content mb7">
                        큐레이터승인 되었습니다. 큐레이션으로 수익을
                        <br>창출해보세요.
                      </div>

                      <div class="alarm_content alarm_div mb20">
                        2분전
                      </div>
                      <div class="alarm_time"></div>

                    </div>
                    <button class="alarm_rollback">
                      <div class="rollback_img"></div>
                    </button>
                  </div>
                  <div class="alarm_lists">
                    <div class="list_img">
                      <div class="alarm_img check_img">
                      </div>
                    </div>
                    <div class="alarm_texts ml7">
                      <div class="alarm_content mb7">
                        <span><아이템 제목명></span> 입찰에 실패했습니다.
                      </div>

                      <div class="alarm_content alarm_div mb20">
                        2분전
                      </div>
                      <div class="alarm_time"></div>

                    </div>
                    <button class="alarm_rollback">
                      <div class="rollback_img"></div>
                    </button>
                  </div>
                </div>-->
            <!--      <PaginationLayout></PaginationLayout>-->
        </div>
    </div>
</template>

<script>
import AlarmListComponent from "@/components/alarm/AlarmListComponent";
import AlarmListDefaultLayout from "@/template/alarm/list/AlarmListDefaultLayout";
export default {
    name: "Alarm",
    mixins: [],
    components: {
        AlarmListComponent,
        AlarmListDefaultLayout,
    },
    inject: [],
    provide() {
        return {
        }
    },
    props: {
    },
    data() {
        return {

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {

    },
    watch: {},
}
</script>

<style scoped>

</style>
