import EventBus from "@/utils/event-bus";

const historyConfig = {
    transaction: {
        listLayout: 'HistoryListTransactionLayout',
        emptyLayout: 'HistoryEmptyDefaultLayout',
        paginationLayout:'PaginationDefaultLayout'
    },
    list:{
        listLayout: 'HistoryListTransactionLayout',
        emptyLayout: 'HistoryEmptyDefaultLayout',
        paginationLayout:'PaginationDefaultLayout',
        detailLayout:'HistoryDetailHistoryLayout',
        getFunc: 'getItemNftHistory',
        listApiParamSet: {
            ip: 1,
        },
        detailApiParamSet: {
            ip: 1,
            sp: 10,
            // pa: 1,
            // si: 5
        },
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
        pageRow: 10,
        isRevers: true,
    },
    itemHistory:{
        detailLayout:'HistoryDetailDefaultLayout',
        getFunc: 'getOrder',
        detailApiParamSet: {},
        getSuccessType: 1,
        getErrorTypes: {},
    },
    itemDetail:{
        detailLayout:'HistoryDetailItemDetailLayout',
        getFunc: 'getItemNftHistory',
        detailApiParamSet: {
            ip: 1,
            sp: 5,
        },
        getSuccessType: 1,
        getErrorTypes: {},
        getKeyName: 'sku',
        isRevers: false,
    },

}
export default {
    methods: {
        returnHistoryConfig(type) {
            return historyConfig[type];
        },
        getItemNftStatus(status) {
            //minted:등록,burned:삭제,list:판매변경,cancel:판매취소,sale:판매완료,transfer:소유권변동,license:라이선스등록,rent:라이선스판매완료,apply:공모전 응모시도시,awarded:공모전 수상
            switch (status) {
                case 'minted': //등록
                    return this.$t('enroll')
                case 'burned': //삭제
                    return this.$t('confirm_del')
                case 'list': //판매변경
                    return this.$t('sale_change')
                case 'cancel': //판매취소
                    return this.$t('sale_cancel')
                case 'sale': //판매완료
                    return this.$t('sell_completed')
                case 'transfer': //소유권변동
                    return this.$t('ownership_transfer')
                case 'license': //라이선스등록
                    return this.$t('license_enroll')
                case 'rent': //라이선스판매완료
                    return this.$t('buy_license')
                case 'apply': //공모전 응모시도시
                    return this.$t('competition_apply')
                case 'awarded': //공모전 수상
                    return this.$t('competition_awards')
                case 'gift': //공모전 수상
                    return this.$t('gift')
                default:
                    return "-"
            }
        },
    },
    filterConfig: {

    },
}
