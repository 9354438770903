<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <div class="item_detail_wrap">
            <div class="item_detail_footer">
                <div class="footer_nft_wrap">
                    <div class="info_detail">
                        <div class="nft_header_wrap">
                            <h3 class="sub_title_m">{{ $t('nft_history') }}</h3>
                        </div>
                        <history-list-component :history-type="'list'" :historyKey="idx" :default-filter="defaultFilter"></history-list-component>

                    </div>
                </div>
            </div>
        </div>
        <!--                <history-detail-component historyType="list" :historyKey="idx"></history-detail-component>-->
    </div>
</template>

<script>
import HistoryListComponent from "../../components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
export default {
    name: "HistoryDetail",
    mixins: [],
    components: {
        HistoryListComponent,
        HistoryDetailComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            idx: this.$route.params.idx,
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {sku: this.idx};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
