<template>
    <div>
        <competition-detail-link :detailLayout="detailLayout"
                                 :competitionData="competitionData"></competition-detail-link>
    </div>
</template>

<script>
import CompetitionDetailLink from "@/components/competition/CompetitionDetailLink";
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
import DeleteClass from "@/mixins/deleteClass";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "CompetitionDetailComponent",
    mixins: [competitionUtil, alertMixins],
    components: {
        CompetitionDetailLink
    },
    inject: [],
    provide() {
        return {
            deleteCompetition: this.runDelete
        }
    },
    props: {
        competitionType: '',
        competitionKey: {
            type: String,
            default: () => {
                return ''
            }
        },
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamsSet: {},
            keyName: '',
            competitionData: {},
            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: 1,
            deleteErrorTypes: {},
            deleteClass: null,

            isGetFirst: true,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.getCompetition();
        this.initTemplateConfig()
    },
    mounted() {
        EventBus.$on('checkApply', this.checkApply)
        EventBus.$on('getCompetitionDetail', this.getCompetition)
    },
    beforeDestroy() {
        EventBus.$off('checkApply')
        EventBus.$off('getCompetitionDetail')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnCompetitionConfig(this.competitionType);
            this.getFunc = config.getFunc
            this.detailApiParamsSet = config.detailApiParamsSet
            this.keyName = config.keyName
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.deleteKayName = config.deleteKayName

            this.initDeleteClass();

        },
        initTemplateConfig() {
            let config = this.returnCompetitionConfig(this.competitionType);
            this.detailLayout = config.detailLayout;
        },
        initDeleteClass() {
            let options = {
                func: this.$api.$competition[this.deleteFunc],
                apiParamSet: this.deleteApiParamSet,
                keyName: this.deleteKayName,
                successType: this.deleteSuccessType,
                errorTypes: this.deleteErrorTypes,
                afterFunc: this.deleteAfterFunc,
            }
            this.deleteClass = new DeleteClass(options);
        },
        getCompetition() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamsSet))
            params[this.keyName] = this.competitionKey
            if(!this.isGetFirst && params.hasOwnProperty('hit')) {
                delete params.hit;
            }
            this.isGetFirst = false;
            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.createAlert({
                            title: '',
                            content: '데이터를 불러올 수 없습니다.',
                            btnText: '확인',
                            hide: () => {
                                this.$router.back();
                            }
                        });
                        return;
                    }
                    this.competitionData = res.List[0]
                })
            } catch (e) {
                console.log(e)
            }

        },
        deleteAfterFunc() {
            this.createAlert({
                content: this.$t('cancel_success_msg'),
                hide: () => {
                    this.$router.back()
                }
            })
        },
        runDelete(key) {
            this.deleteClass.runDelete(key);
        },
        checkApply() {
            //let params = {c_idx : this.competitionKey}
            let params = {c_idx : this.competitionData.c_idx}
            try {
                this.$api.$competition.checkApply(params).then(res => res.Data.Result).then(res =>{
                    if(res.Info.type === 99){
                        this.$router.push(`/entry/${this.competitionKey}/write`)
                    }
                    else {
                        this.errorAlert(this.$t('competition_err_over'))
                    }
                })
            }catch (e) {
                console.log(e)
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
