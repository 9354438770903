<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
<!--        <page-header :header_back="true"></page-header>-->
        <detection-detail-component :detection-type="'detectionDetail'" :detectionKey="detectionKey"></detection-detail-component>
    </div>
</template>

<script>


import DetectionDetailComponent from "@/components/detection/DetectionDetailComponent";

export default {
    name: "DetectionDetail",
    mixins: [],
    components: {DetectionDetailComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            detectionKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
