<template>
    <modal name="refferer-add-modal"
           class="nickname-change-modal refferer-add-modal"
           :pivotX="0.5"
           :pivotY="0.36"
           :adaptive="true"
           :scrollable="true"
           :clickToClose="false"
           :width="'90%'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="update-change-modal_wrap">
                <div class="nickname_title mb25">
                    {{ $t('r_nick') }} {{ $t('enroll') }}
                </div>
                <div class="rollback_btn" @click="closeModal()"></div>
                <div class="clear"></div>

                <member-write-component :member-type="'updateReferrer'"></member-write-component>

<!--                <button class="two_btn back_gray" @click="closeModal()">{{$t('cancel')}}</button>-->
<!--                <button class="two_btn back_navy" @click="save()">{{$t('save')}}</button>-->
<!--                <div class="clear"></div>-->
            </div>
        </div>
    </modal>

</template>

<script>
import EventBus from "@/utils/event-bus";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "ReffererAddModal",
    mixins: [],
    components: {
        MemberWriteComponent
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
      EventBus.$on('reffererAddModalClose', this.closeModal);
    },
    beforeDestroy() {
      EventBus.$off('reffererAddModalClose');
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('refferer-add-modal');
        },
        handlerBeforeOpen(event) {
        },

        handlerOpened() {
        },

        handlerBeforeClose() {

        },
        movePage(url) {
            this.$router.push(url);
        },
        save(){
          if(this.nick == this.referrerNick){
            this.errorAlert(this.$t('referrerErrorMsg1'));
            return;
          }
          // else{
          //   EventBus.$emit('updateMember')
          //   return;
          // }
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
