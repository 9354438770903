<template>
    <competition-list-component class="padding_side" :competition-type="'competitionUser'" :member-key="memberKey"></competition-list-component>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";

export default {
    name: "CreatorDetailCompetition",
    mixins: [],
    components: {
        CompetitionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
    },
    watch: {},
}
</script>

<style scoped>

</style>
