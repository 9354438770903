<template>
  <div class="banner_wrap" v-if="checkBanner()">
    <swiper class="banner_swiper" ref="swiper" :options="swiperOption" @click="onClick"
            @slideChange="slideChange">
      <swiper-slide class="banner_swiper_slide"
                    v-for="(data,index) in bannerData" :key="`banner${index}`" >
        <div class="banner_swiper_slide_pic" :id="`banner${data.b_idx}`" :style="` background-image: url(${returnThumbnail(data)});`"></div>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
<!--      <div class="swiper-button-prev" slot="button-prev"></div>-->
<!--      <div class="swiper-button-next" slot="button-next"></div>-->
    </swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "BannerComponent",
    mixins: [alertMixins, imageOption],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            swiperOption: {
                slidesPerView: 1,
                autoHeight: true,
                autoWidth: true,
                centeredSlides: true,
                grabCursor: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bullets',
                    clickable: true
                },
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                navigation: {
                    // nextEl: '.swiper-button-next',
                    // prevEl: '.swiper-button-prev'
                },

            },
            bannerData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getBanner();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getBanner() {
            let params = {
                ip: 1,
                di: 1,
                obt:'created_at',
                obs:'desc'
            }
            this.$api.$config.getBanner(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.bannerData = res.List

                } else if (res.Info.type === -100) {
                    this.bannerData = [];
                } else {
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                }

            })
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.MobileFiles)) {
                return `${data.MobileFiles[0].org_url}?${this.setImageOptions(710, 384, 'jpg')}`
            }
        },
        movePage(data) {
            console.log(data)
            if (data.b_type === 'link') {
                window.open(`${data.b_url}`, '_blank');
                return;
            }
            return
        },
        checkBanner() {
            return !util.isEmpty(this.bannerData)
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementId = element.id;
            if (util.isEmpty(elementId) || elementId.indexOf('banner')) {
                return false;
            }
            let elementIdx = elementId.replace('banner', "");
            let bannerIndex = this.bannerData.findIndex(banner => banner.b_idx === Number(elementIdx));
            if (bannerIndex > -1) {
                this.movePage(this.bannerData[bannerIndex]);
            }

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
