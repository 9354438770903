<template>
    <div>
        <member-detail-link v-if="showLayout" :detailLayout="detailLayout" :memberData="memberData"></member-detail-link>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import memberUtil from "@/components/member/memberUtil";
import MemberDetailLink from "@/components/member/MemberDetailLink";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";

export default {
    name: "MemberDetailComponent",
    mixins: [memberUtil, alertMixins],
    components: {
        MemberDetailLink
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberType: '',
        memberKey: {default: 0},
    },
    data() {
        return{
            detailLayout: '',
            getFunc:'',
            detailApiParamSet: {},
            getKeyName: 'no',

            memberData: {},
            showLayout: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.getMember();
    },
    mounted() {
        EventBus.$on('memberDetailGet', this.getMember);

    },
    beforeDestroy() {
        EventBus.$off('memberDetailGet');
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initConfig(){
            let memberConfig = this.returnMemberConfig(this.memberType);
            this.detailLayout = memberConfig.detailLayout;
            this.getFunc = memberConfig.getFunc;
            this.detailApiParamSet = memberConfig.detailApiParamSet;
            this.getKeyName = memberConfig.getKeyName;
        },
        getMember(isChange=false) {
            if(this.memberType != 'memberPortfolio') {
                EventBus.$emit('changeLoading', true);
            }
            let params = Object.assign({}, this.detailApiParamSet);
            if(this.memberKey != 0 && !util.isEmpty(this.getKeyName)) {
                params[this.getKeyName] = this.memberKey;
            }else if(this.memberKey != 0){
                params.no = this.memberKey;
            }
            try {
                this.$api.$member[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(isChange) {
                        setTimeout(() => {
                            EventBus.$emit('changeLoading', false);
                        }, 500)
                    }else{
                        EventBus.$emit('changeLoading', false);
                    }

                    if(res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    this.memberData = res.List[0];
                    this.showLayout = true;
                    EventBus.$emit('setTabsMemberData', this.memberData);
                })
            }catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false);
            }
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
