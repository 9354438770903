<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header" :header-title="$t('infoModify')"></page-header>
        <div class="dgm_wrap padding_side relative mb30">
            <member-write-component :member-type="'memberUpdate'"></member-write-component>
          <div class="member_write_btn">
            <div class="one_btn btn_background font_white">{{ $t('modify') }}</div>
            <div class="member_withdrawal mt15">
              <button @click="$router.push('/auth/unsubscribe')">{{ $t('unsubscribe') }}</button>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "MyPageUpdate",
    mixins: [],
    components: {
        MemberWriteComponent,

    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
