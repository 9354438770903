<template>
    <modal name="competition-popup-modal"
           class="popup-modal"
           :pivotY="0.3"
           :width="`calc(100% - 28px)`"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="modal_wrap">
            <div class="modal_header">
                <div class="modal_title">{{ $t('competition_member_info ') }}</div>
                <div class="modal_close" @click="closeModal()"></div>
            </div>
            <div class="modal_content">
                <div class="creator_popup border_none">
                    <div class="creator_text">{{ $t('organizer') }}</div>
                    <member-detail-component :member-key="competitionData.mb_no"
                                             :member-type="'itemMemberInfo'"></member-detail-component>
                </div>

            </div>

        </div>
    </modal>
</template>

<script>

import MemberDetailComponent from "@/components/member/MemberDetailComponent";
import util from "@/mixins/util";

export default {
    name: "CompetitionPopupModal",
    mixins: [],
    components: {MemberDetailComponent},
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            competitionData: {},
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.competitionData = event.params.competitionData
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        closeModal() {
            this.$modal.hide('competition-popup-modal');
        },
        movePage(url) {
            this.$router.push(url);
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        checkOriginal(data) {
            if (!util.isEmpty(data.im_originator)) {
                return true
            }
            return false;
        },


    },

    watch: {},
};
</script>

<style scoped>

</style>
