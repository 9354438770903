<template>
  <div>
    <alarm-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList" v-if="List.length === 0"></alarm-empty-link>
    <alarm-list-link :listLayout="listLayout" :alarmData="List" :paginationData="filterSet.page"
                    v-else></alarm-list-link>
    <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                     :finishGetList="finishGetList"  v-if="filterSet.page.total"></pagination-link>
  </div>
</template>

<script>
import AlarmListLink from "@/components/alarm/AlarmListLink";
import AlarmEmptyLink from "@/components/alarm/AlarmEmptyLink";
import PaginationLink from "@/components/alarm/PaginationLink";
import alarmUtil from "@/components/alarm/alarmUtil";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import DeleteClass from "@/mixins/deleteClass";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

export default {
  name: "AlarmListComponent",
  mixins: [alarmUtil, alertMixins, filterMixins, listMixins],
  components: {
    AlarmListLink,
    AlarmEmptyLink,
    PaginationLink,
  },
  inject: [],
  provide() {
    return{
      setPage: this.setPage,
      setAlarmListData: this.setData,
    }
  },
  props: {
    alarmType: '',
  },
  data() {
    return{
      listLayout: '',
      emptyLayout: '',
      paginationLayout: '',
      getFunc: '',
      listApiParamSet: {},
      deleteFunc: '',
      deleteApiParamsSet: {},
      deleteSuccessType: 1,
      deleteErrorTypes: {},
      deleteKeyName: '',
      deleteClass: null,
    }
  },
  beforeRouterEnter() {},
  created() {
    this.initConfig();
    this.setParserType('url', this.$route.fullPath);
  },
  mounted() {
    this.getList();
    this.setReadAlarm()
    EventBus.$on('deleteAlarm', this.runDelete);
  },
  beforeDestroy() {
    EventBus.$off('deleteAlarm')
  },
  destroyed() {},
  computed: {
    ...mapState({
      UserInfo: state => state.userInfo.UserInfo,
    })
  },
  methods:{
    initConfig(){
      let config = this.returnAlarmConfig(this.alarmType);
      this.listLayout = config.listLayout;
      this.emptyLayout = config.emptyLayout;
      this.paginationLayout = config.paginationLayout;
      this.getFunc = config.getFunc;
      this.listApiParamSet = config.listApiParamSet;
      this.deleteKeyName = config.deleteKeyName;
      this.deleteFunc = config.deleteFunc;
      this.deleteApiParamsSet = config.deleteApiParamsSet;
      this.deleteSuccessType = config.deleteSuccessType;
      this.deleteErrorTypes = config.deleteErrorTypes;
      let listOptions = {
        url: this.$api.$alarm[this.getFunc],
        defaultParams: this.listApiParamSet,
      }
      this.initList('api', listOptions);
      this.initDeleteClass();
    },
    setData(key, value) {
      this[key] = value;
    },
    initDeleteClass() {
      if(util.isEmpty(this.deleteFunc)) {
        return false;
      }
      let options = {
        func: this.$api.$alarm[this.deleteFunc],
        apiParamSet: this.deleteApiParamsSet,
        keyName: this.deleteKeyName,
        successType: this.deleteSuccessType,
        errorTypes: this.deleteErrorTypes,
        afterFunc: this.deleteAfterFunc,
      }
      this.deleteClass = new DeleteClass(options);
    },
    deleteAfterFunc() {
      this.createAlert({
        content: this.$t('delete_success_alarm'),
        hide:()=>{
          this.getList();
          // let token = this.$localStorage.get('token');
          // this.$store.dispatch('userInfo/setUserInfo', {token: token});
        }
      })

    },
    setReadAlarm() {
      let params = {type: 'multi'}
      try {
        this.$api.$alarm.readAlarm(params).then(res => res.Data.Result).then(res => {
          if (res.Info.type == 1) {
            let updateUserInfo = this.UserInfo;
            updateUserInfo.hasAlarm = false
            this.$store.dispatch('userInfo/changeUserInfo', {data: updateUserInfo});
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    runDelete(key) {
      this.deleteClass.runDelete(key);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
