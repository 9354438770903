<template>
    <div >

        <item-list-component :item-type="`premiumMarketUpload`" :member-key="UserInfo.mb_no"></item-list-component>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
export default {
    name: "PremiumMarketUpload",
    mixins: [],
    components: {
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    async created() {
      openTerms = this.openTerms;

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
      openTerms(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>