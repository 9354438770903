<template>
    <modal name="curation-terms-modal" class="curation-terms-modal"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="curation-terms-wrap">
            <div class="top_wrap">
                <div class="modal_title">
                    {{$t('terms_curation')}}
                </div>
                <div class="close_btn_wrap request_modal">
                    <button class="close_modal" @click="modalClose()"></button>
                </div>
            </div>
            <div class="middle_wrap" style="margin-top: 30px">
                <terms-component :termsType="termsType" :termsCategory="termsType"></terms-component>
            </div>
        </div>
    </modal>
</template>

<script>
import EventBus from "@/utils/event-bus";
import TermsComponent from "@/components/terms/TermsComponent";

export default {
    name: "CurationTermsModal",
    mixins: [],
    components: {
        TermsComponent
    },
    inject: [],
    provide() {
        return {
            'setTermsData': this.setTermsData,
        }
    },
    props: {},
    data() {
        return {
            nick: '',
            url: '',
            termsType: 'curation',
            termsKey: '',
            termsData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        //
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        setTermsData(value) {
            this.termsData = value;
        },
        handlerBeforeOpen(event) {
            this.termsType = event.params.termsType
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        openModal(name) {
            this.$modal.show(name);
        },
        modalClose() {
            this.$modal.hide('curation-terms-modal');
            $("body").removeClass("modal-open")
        },
        returnUrl() {
            return this.url
        },
        setData(key, value) {
            this[key] = value
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
