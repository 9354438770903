<template>
    <div class="competition_wrap pb17 padding_side">
        <div class="competition_item application_item">
            <div class="competition_input">
                <el-input :placeholder="$t('st')" v-model="searchInput" @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
            </div>
        </div>
        <div class="competition_btn_wrap">
            <button class="c_sort_btn ma0" @click="openFilter('open-market-sort-filter')"></button>
            <button class="c_filter_btn ma0 ml13"  @click="openFilter('item-filter-premium-my-filter')"></button>
        </div>
        <div class="clear"></div>
        <item-filter-premium-my-filter></item-filter-premium-my-filter>
        <open-market-sort-filter></open-market-sort-filter>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";
import ItemFilterPremiumMyFilter from "@/components/filter/ItemFilterPremiumMyFilter";
import OpenMarketSortFilter from "@/components/sort/OpenMarketSortFilter";


const {filterConfig} = itemUtil
export default {
    name: "ItemFilterPremiumMyLayout",
    mixins: [itemUtil],
    components: {
        ItemFilterPremiumMyFilter,
        OpenMarketSortFilter
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            type: Object,
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            priceInput: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
        if (!util.isEmpty(this.sortData)) {
            this.setterSortDataInData()
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setterSortDataInData() {
            this.sort = this.sortData.order;
            this.sortOption = this.sortData.key
            if (this.sortData.key === 'i_price') {
                if (this.sortData.order === 'desc') {
                    this.sortOption = `priceDesc`
                } else if (this.sortData.order === 'asc') {
                    this.sortOption = `priceAsc`
                }
            }
        },
        setData(key, value) {
            this[key] = value;
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('i_price', 'asc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('i_price', 'desc');
            } else {
                this.sort = 'desc'
                this.setSort(data, 'desc');
            }
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
        },
        movePage(url) {
            this.$router.push(`/${url}`)
        },
        checkMyProfile() {
            return this.$route.name === 'MyProfilePremium';
        },
        openFilter(name) {
            this.$modal.show(name, {sortData: this.sortData, filterData: this.filterData});
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
