<template>
    <div >
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
      <detection-write-component :detection-type="'requestWrite'"></detection-write-component>
    </div>
</template>

<script>


import DetectionWriteComponent from "@/components/detection/DetectionWriteComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionRequest",
    mixins: [],
    components: {DetectionWriteComponent},
    inject: [],
    provide() {
        return {
            setValidatorErr: this.setValidatorErr,
        }
    },
    props: {},
    data() {
        return {
            validatorErr: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setValidatorErr(value) {
            this.validatorErr = value;
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
