<template>
    <div>
        <div class="detail_middle_content">
            <ul v-if="itemData.i_status === 'auction'">
                <div class="box-cost-common mb12"  v-if="itemData.ia_is_minimum === 1">
                    <div class="info-cost-common">{{ $t('mini_bid') }}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{ returnCommas(itemData.ia_minimum) }} Mg</div>
                        </div>
                        <div class="won-cost-common">({{ returnMingle(itemData.ia_minimum) }})</div>
                    </div>
                </div>

                <div class="box-cost-common mb12"  :class="{'max_bid_wrap': itemData.ia_is_minimum === 1}">
                    <div class="info-cost-common">{{ $t('max_bid') }}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{ returnCommas(itemData.i_price) }} Mg</div>
                        </div>
                        <div class="won-cost-common">({{ returnMingle(itemData.i_price) }})</div>
                    </div>
                </div>

                <div class="box-cost-common mb12"  v-if="itemData.ia_is_immediately === 1">
                    <div class="info-cost-common">{{ $t('i_buy') }}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{ returnCommas(itemData.ia_immediately) }} Mg</div>
                        </div>
                        <div class="won-cost-common">({{ returnMingle(itemData.ia_immediately) }})</div>
                    </div>
                </div>
            </ul>

            <ul class="n_buy_box" v-if="itemData.i_status === 'pricetag'">
                <div class="box-cost-common mb12">
                    <div class="info-cost-common">{{ $t('n_buy') }}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{ returnCommas(itemData.i_price) }} Mg</div>
                        </div>
                        <div class="won-cost-common">({{ returnMingle(itemData.i_price) }})</div>
                    </div>
                </div>
            </ul>

            <ul class="nosale_box mt17" v-if="itemData.i_status === 'nosale'">
                <div class="box-cost-common mb12">
                    <div class="info-cost-common">{{ $t('final_price') }}</div>
                    <div>
                        <div>
                            <div class="icon-common"></div>
                            <div class="text-cost-common">{{ returnCommas(itemData.i_price) }} Mg</div>
                        </div>
                        <div class="won-cost-common">({{ returnMingle(itemData.i_price) }})</div>
                    </div>
                </div>
            </ul>

        </div>
    </div>
</template>

<script>
import priceMixins from "@/mixins/priceMixins";
import vClickOutside from 'v-click-outside'
import imageResizeMixins from "@/mixins/imageResizeMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import ItemEditionComponent from "@/components/item/ItemEditionComponent";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemDetailAmountLayout",
    mixins: [imageOption, priceMixins, imageResizeMixins, AuthMixins, awsS3FileDownload],
    components: {ItemEditionComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {}
    },
    data() {
        return {
            size: '',
            sizeType: '',
            btnClicked: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
        hasSizeError() {
            return util.isEmpty(this.sizeType)
        }
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Item)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            if (!util.isEmpty(this.itemData) && this.itemData.im_status === 3) {
                if (this.itemData.im_type === 'photo') {
                    let afterSize = this.itemData.imo_size.split('*');
                    this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
                } else {
                    this.sizeType = 'origin'
                }
            }
        },
        changeSelect(type) {
            this.size = '';
            this.sizeType = type;
        },
        onClickOutside() {
            this.size = '';
        },
        checkLicense(url) {
            this.btnClicked = true
            if (!this.hasSizeError) {
                this.checkLogin(url)
            }
        },
        checkMingle() {
            this.createConfirm({
                content: `${this.$t('n_e_mingle')}`,
                confirm: () => {
                    this.createConfirm({
                        content: `${this.$t('confirm_charge')}`,
                        confirm: () => {
                            this.createConfirm({
                                content: this.$t('r_license'),
                                confirmText: this.$t('check_now'),
                                cancelText: this.$t('check_later'),
                                confirm: () => {
                                },
                                cancel: () => {
                                }
                            })
                        },
                        cancel: () => {
                        }
                    })
                },
                cancel: () => {
                }
            })
        },
        checkLogin(url) {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                this.movePage(url);
            }
        },
        checkMemberType() {
            if ((!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no) || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        returnType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        returnCategoryDetailName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.ic_name
            }
            return data.ic_name_eng
        },
        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if (data.ic_idx === 178){
                return this.$t('prompt')
            }

        },
        checkItemData(data) {
            return !util.isEmpty(this.itemData[data]);
        },
        checkImgData(data) {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile[data]) && !util.isEmpty(this.itemData.ItemFile[data][0].a_idx)) {
                return true
            }
            return false
        },
        awsS3Download(type, name, key, nft) {
            if (util.isEmpty(this.UserInfo) || this.itemData.provider != this.UserInfo.mb_no) {
                return false;
            }
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        }
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>
