<template>
    <div class="mypage_list_wrap">
        <div class="mypage_list border-top" v-for="(data,index) in itemData"
             :key="`item${index}`">
            <div class="item_information">
                <div class="list_time" @click="movePage(data)">{{ data.created_at }}</div>

                <button class="list_download" v-if="checkLicense(data) === 'download'" @click="downloadLicense(data)">
                    <img src="@/assets/image/download_icon.png">
                    <div class="ml5"> {{ returnDateFormat(data.license_day, 'YY-MM-DD') }}/
                        {{ returnLicenseCount(data) }}{{ $t('times') }}
                    </div>
                </button>

                <div class="term_end" v-else-if="checkLicense(data) === 'end'">{{ $t('endDate') }}</div>

                <button class="list_record_img" v-if="checkHistory(data)" @click="moveHistory(data.m_idx)"></button>
            </div>
            <div class="item_img_wrap mt11" @click="movePage(data)">
                <div class="item_img">
                    <img :src="returnThumbnail(data)">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold">[{{ returnStatus(data) }}]</div>
                    <div class="item_text">{{ data.i_name }}</div>
                    <img src="@/assets/image/mg_icon.png" v-if="data.ih_price">
                    <div class="item_text bold">{{ data.ih_price }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import fileMixins from "@/mixins/fileMixins";
import alertMixins from "@/mixins/alertMixins";
import dateMixins from "@/mixins/dateMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
export default {
    name: "ItemListMyItemHistoryLayout",
    mixins: [imageOption, fileMixins, alertMixins, dateMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {
            historyLayout: 6
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    computed: {},
    methods: {
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        movePage(data) {
            console.log(data);
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else if (data.ih_status === 'cancel') {
                this.createAlert({
                    content: this.$t('delete_item_error_msg'),
                    btnText: this.$t('yes'),
                    hide: () => {
                        return
                    }
                })
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        },
        moveHistory(url) {
            this.$router.push(`/mypage/item/${url}/history`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(142, 90, 'png')}`;
            }
        },
        checkData(data) {
            return !util.isEmpty(data);
        },
        checkHistory(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.m_idx) && data.i_use !== 0) {
                return true
            }
            return false
        },
        returnStatus(data) {
            //라이센스 관련 차후 데이터 들어온후 진행 필요
            if (data.ih_status === 'create') {
                return this.$t('enroll')
            } else if (data.ih_status === 'provideBuy') {
                return this.$t('ownership_buy')
            } else if (data.ih_status === 'provideSell') {
                return this.$t('ownership_sell')
            } else if (data.ih_status === 'curationRequest') {
                return this.$t('c_request')
            } else if (data.ih_status === 'curationCancel') {
                return this.$t('c_request_cancel')
            } else if (data.ih_status === 'licenseBuy') {
                return `${this.$t('licenseBuy')}·${data.ih_option}`
            } else if (data.ih_status === 'licenseSell') {
                return this.$t('licenseSell')
            } else if (data.ih_status === 'cancel') {
                return this.$t('confirm_del')
            }
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },

        getDifferenceTime(endTime, currentTime) {
            let current = this.$moment(currentTime)
            let end = this.$moment(endTime)
            if (current > end) {
                return 0;
            }
            let diff = current.diff(end, 'second')

            let hour = Math.abs(parseInt(diff / 3600, 10))
            let min = Math.abs(parseInt((diff % 3600) / 60, 10))
            let sec = Math.abs(parseInt((diff % 3600) % 60, 10));

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min} : ${sec}`
        },
        checkLicense(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            let current = this.$moment(currentTime);
            let end = this.$moment(data.license_day);
            if (data.ih_status === 'licenseBuy' && current < end && data.i_ues !== 0) {
                return 'download'
            } else if (data.ig_status === 'licenseBuy' && current > end && data.i_ues !== 0) {
                return 'end'
            }
        },
        returnLicenseCount(data) {
            if (data.License === 0) {
                return 3
            } else if (data.License === 1) {
                return 2
            } else if (data.License === 2) {
                return 1
            } else {
                return 0
            }
        },
        downloadLicense(data) {
            if (data.License < 3) {
                this.getFileJwtToken('license', data.m_idx).then(res => {
                    EventBus.$emit('getItemList');
                });
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
