<template>
    <div class="e_search_layout">
        <div class="item_premium_list border-radius_ten mt8 mb10"  v-for="(entry,index) in competitionData"
             :key="`entrySearch${index}`"
             @click="selectEntryItem(entry.ci_idx,entry.Item.i_name)">
            <div class="img_wrap">
                <div class="img_bg"
                     :style="`background-image :    url(${setFitWidthImg(entry.Item.ItemFile.Item,276)})`"></div>
            </div>

            <div class="content_wrap pb10">
                <div class="subject_wrap competition_subject">{{ entry.Item.i_name }}
                </div>
                <div class="content_middle mb12">
                    <ul class="middle_thumb">
                        <li class="thumb_wrap competition_thumb">
                            <img :src="returnImage(entry.Profile.ProfileBack,29,29)" alt="">
                        </li>
                    </ul>
                    <div class="c_t_nickname">
                        {{ entry.Item.originator_nick }}
                    </div>
                </div>
                <div class="content_bottom pt9">
                    <img src="@/assets/image/icon_vote.png">
                    {{ returnCommas(entry.ci_vote_cnt) }}({{ returnPercentage(entry.ci_vote_cnt, entry.totalVote) }}%)
                </div>
                <div class="clear"></div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "CompetitionListEntryModalLayout",
    mixins: [imageOption, alertMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            type: Array,
            default: () => []
        },
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnCommas(x) {
            if (x > 9999) {
                return '9,999+';
            }
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnProfile(profile) {
            if (!util.isEmpty(profile)) {
                return `${profile[0].org_url}?${this.setImageOptions(64, 64, this.returnExtension(profile[0].org_url))}`
            }
            return ''

        },
        returnImage(image, width = 32, height = 32, f = 'png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, this.returnExtension(image[0].org_url))}`
            }
            return ''
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        selectEntryItem(idx, name) {
            this.createConfirm({
                content: `<span>${name}</span> <br/>
                                ${this.$t('entry_confirm_text')} `,
                confirm: () => {
                    EventBus.$emit('selectEntryModalItem', idx, name)
                },
            })
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
