<template>
  <div class="application_item_list border-top pt20">
    <div class="item_premium_list_wrap pa0">
      <div class="item_premium_list" v-for="data in 10">
        <div class="img_wrap">
          <div class="img_bg"></div>
          <div class="art_card">Art</div>
        </div>

        <div class="content_wrap">
          <div class="subject_wrap">병원로고 디자인 의뢰</div>
        </div>

      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: "EntryListDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>