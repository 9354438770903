<template>
    <div>
        <!-- <div class="detail_top_btn_wrap">
            <div class="heart_wrap">
                <div class="share_wrap" @click="openShareModal">
                    <div class="share_img"></div>
                </div>
                <div class="heart_count" @click="likeItem()">
                    <div class="heart" @click="likeItem()" :class="{'on': isMyLike()}"></div>
                    <span>{{ likeData.im_real_like }}</span></div>
                <div class="eye_count">
                    <div class="eye"></div>
                    <span>{{ likeData.im_real_hit }}</span></div>
            </div>
        </div> -->
        <div class="box-emotion-nticket">
            <!-- <div class="type-sellings-nticket">{{ $t(nticketItem.i_status) }}</div> -->
            <button class="icon-share-nticket" @click="openShareModal()"></button>
            <button class="icon-like-common ml8 mr2" :class="{'active': isNticketLikedByMe}" @click="likeItem()"></button>{{ likeData.im_real_like }}
            <button class="icon-view-common ml8 mr2"></button>{{ likeData.im_real_hit }}
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

export default {
    name: "LikeItemDetailLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeData: {}
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        likeItem() {
            if (!util.isEmpty(this.UserInfo)) {
                EventBus.$emit('like');
            }
        },
        isMyLike() {
            return this.likeData?.Like?.myLike
        },
        checkLike() {
            return !util.isEmpty(this.likeData) && !util.isEmpty(this.likeData.Like) && !util.isEmpty(this.likeData.im_real_like)
        },
        openShareModal() {
            this.$modal.show('share-modal', {itemData: this.likeData})
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
