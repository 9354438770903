<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header">
            <button slot="headerBtns" class="h_plus_btn" @click="openModal"></button>
        </page-header>
        <gallery-list-component :gallery-type="'gallery'" :defaultFilter="defaultFilter"></gallery-list-component>
        <gallery-setting-modal></gallery-setting-modal>
    </div>
</template>

<script>
import GalleryListComponent from "@/components/gallery/GalleryListComponent";
import GallerySettingModal from "@/components/modal/GallerySettingModal";
import {mapState} from "vuex";

export default {
    name: "Gallery",
    mixins: [],
    components: {
        GalleryListComponent,GallerySettingModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {no: this.UserInfo.mb_no}
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal() {
            this.$modal.show('gallery-setting-modal')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
