<template>
    <div>
        <!--                    댓글 등록 레이아웃 -->
        <div class="competition_mobile_reply_wrap padding_side">
            <div class="c_top_reply_wrap">
                <img class="reply_icon" src="@/assets/image/reply_icon.png" alt=""/>
                <div class="reply_title">{{ $t('reply') }}<span class="reply_total_count">&nbsp;{{ pageTotal }}</span></div>
            </div>
            <div class="c_content_reply_wrap">
                <div class="content_count">({{ reply_msg.length }}/200)</div>
                <textarea type="text" class="reply_content" maxlength="200" :placeholder="$t('reply_msg')" v-model="reply_msg"></textarea>
                <button class="reply_update_btn" @click="checkComment()">{{ $t('enroll') }}</button>
            </div>

            <!--            댓글 -->
            <div class="comment_list_wrap" v-for="(comment, cc_idx) in commentData" :key="`comment${cc_idx}`">
                <div class="comment_top">
                    <div class="comment_user_name">{{ comment.mb_nick }}</div>
                    <el-select class="my_comment_update"
                               v-if="userCommentCheck(UserInfo.mb_no, comment.mb_no) || userCommentCheck(UserInfo.mb_no, competitionData.mb_no)"
                               v-model="commentStatus"
                               @change="commentStatusSelect(commentStatus, comment.cc_idx, comment.cc_context)"
                               popper-class="comment_select" :popper-append-to-body="false">
                        <el-option v-if="userCommentCheck(UserInfo.mb_no, comment.mb_no)" :value="'modify'" :label="$t('modify')"></el-option>
                        <el-option :value="'delete'" :label="$t('confirm_del')"></el-option>
                    </el-select>
                </div>
                <div class="comment_content ql-editor" v-if="commentStatus != 'modify' || comment.cc_idx != updateCheck"
                     v-html="comment.cc_context"></div>
                <div class="comment_date"  v-if="commentStatus != 'modify' || comment.cc_idx != updateCheck">
                    {{ returnDateTime(comment.updated_at) }}</div>
                <div class="comment_modify_input" v-if="commentStatus === 'modify' && comment.cc_idx === updateCheck">
                    <div class="modify_count">({{ update_msg.length }}/200)</div>
                    <textarea type="text" class="modify_content" v-model="update_msg" />
                    <button class="reply_btn reply_delete_btn" @click="cancelComment">{{ $t('cancel') }}</button>
                    <button class="reply_btn reply_update_btn" @click="saveComment(comment.cc_idx)">{{ $t('save') }}</button>
                </div>
            </div>
            <div class="reply_more_btn_wrap"  @click="moreListFunc()" v-if="page != lastPage && hasData(commentData)">
                <button class="reply_more_btn">{{ $t('reply_more') }}</button>
            </div>

        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import competitionUtil from "@/components/competition/competitionUtil";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "CompetitionCommentComponent",
    mixins: [competitionUtil, alertMixins, AuthMixins],
    components: {
    },
    inject: [],
    provide() {
        return {
        }
    },
    props: {
        competitionData: {
            default: () => {
            }
        },
        competitionType: '',
        competitionKey: '',
    },
    data() {
        return {
            commentData: {},
            reply_msg: '',
            update_msg: '',
            commentStatus: '',
            updateCheck: '',

            writeFunc: '',
            writeApiParamSet: {},
            parsingData: {},
            directUrl: '',
            directType: '',
            keyName: '',
            successType: '',
            writeFailTypes: {},

            deleteFunc: '',
            deleteApiParamSet: {},
            deleteSuccessType: '',
            deleteErrorTypes: {},
            deleteKeyName: '',

            updateFunc: '',
            upParsingData: {},

            getKeyName: '',
            getFunc: '',
            listApiParamSet: {},
            pageRow: '',
            page: 1,
            lastPage: '',
            pageTotal: '',
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.initConfig();
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        async initConfig() {
            let config = this.returnCompetitionConfig(this.competitionType);
            this.writeFunc = config.writeFunc;
            this.writeApiParamSet = config.writeApiParamSet;
            this.parsingData = config.parsingData;
            this.directUrl = config.directUrl;
            this.directType = config.directType;
            this.keyName = config.keyName;
            this.successType = config.successType;
            this.writeFailTypes = config.writeFailTypes;
            this.deleteFunc = config.deleteFunc;
            this.deleteApiParamSet = config.deleteApiParamSet;
            this.deleteSuccessType = config.deleteSuccessType;
            this.deleteErrorTypes = config.deleteErrorTypes;
            this.deleteKeyName = config.deleteKeyName;
            this.getFunc = config.getFunc;
            this.updateFunc = config.updateFunc;
            this.upParsingData = config.upParsingData;
            this.getKeyName = config.getKeyName;
            this.listApiParamSet = config.listApiParamSet;
            this.pageRow = config.pageRow;
            this.initSetData();
            if (!util.isEmpty(this.competitionKey)) {
                await this.getCommentList();
            } else {
                await this.getCompetitionKey();
            }
        },
        initSetData() {
            this.commentData = {};
            this.commentStatus = '';
            this.page = 1;
            this.lastPage = '';
            this.pageTotal = '';
        },
        getCompetitionKey() {
            this.competitionKey = this.competitionData.cc_idx;
            this.getCommentList();
        },
        getCommentList() {
            EventBus.$emit('changeLoading', true);
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            params[this.getKeyName] = this.competitionKey;
            params.page = this.page;
            try {
                this.$api.$competition[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === this.successType) {
                        if (!util.isEmpty(this.commentData)) {
                            util.Object.each(res.List, (key, value, obj) => {
                                this.commentData.push(value);
                            });
                        } else {
                            this.commentData = res.List;
                        }
                        this.page = res.Page.current_page;
                        this.lastPage = res.Page.last_page;
                        this.pageTotal = res.Page.total;
                    } else {
                        this.errorAlert(this.$t('no_data_loading'));
                    }
                    EventBus.$emit('changeLoading', false);
                });
            } catch (e) {
                EventBus.$emit('changeLoading', false);
                console.log(e);
            }
        },
        hasData(data) {
            return !util.isEmpty(data);
        },
        moreListFunc() {
            if (this.page < this.lastPage) {
                this.page += 1;
                this.getCommentList();
            }
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YY-MM-DD HH:mm')
            return r_date;
        },
        checkComment() {
            if(!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    cancelText: this.$t('cancel'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
            } else {
                this.writeComment();
            }
        },
        writeComment() {
            if (this.reply_msg.length === 0) {
                this.errorAlert(this.$t('write_a_comment'));
                return false;
            }
            EventBus.$emit('changeLoading', true);
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.parsingData);
            dataArr.forEach(dataKey => {
                if(this.parsingData[dataKey] != '') {
                    params[this.parsingData[dataKey]] = this[dataKey];
                }
            });
            params.c_idx = this.competitionKey;
            try {
                this.$api.$competition[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === this.successType) {
                        this.commentAfterFunc();
                    } else {
                        EventBus.$emit('changeLoading', false);
                        this.errorAlert(this.$t('comment_create_error'));
                    }
                });
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        commentAfterFunc() {
            EventBus.$emit('changeLoading', false);
            this.createAlert({
                content: this.$t('comment_create'),
                hide: () => {
                    this.reply_msg = '';
                    this.initSetData();
                    this.getCommentList();
                },
            });
        },
        commentStatusSelect(type, key, msg) {
            if (type === 'delete') {
                this.commentDeleteConfirm(key);
            } else {
                this.updateCheck = key;
                this.update_msg = msg;
            }
        },
        commentDeleteConfirm(key) {
            this.createConfirm({
                content: this.$t('caution_del'),
                confirm: () => {
                    this.commentDeleteRun(key);
                },
            });
            this.cancelComment();
        },
        commentDeleteRun(commentKey) {
            EventBus.$emit('changeLoading', true);
            let params = JSON.parse(JSON.stringify(this.deleteApiParamSet));
            params.cc_idx = commentKey;
            try {
                this.$api.$competition[this.deleteFunc](params).then(res => res.Data.Result).then(res => {
                    let type = this.returnWriteTypes(res.Info.type);
                    if (type != true) {
                        EventBus.$emit('changeLoading', false);
                        this.initSetData();
                        this.getCommentList();
                        this.errorAlert(type);
                    }
                    else {
                        this.deleteAfterFunc();
                    }
                });
            } catch (e) {
                console.log(e);
            }
        },
        returnWriteTypes(type) {
            switch (type) {
                case 1: return true;
                case -202: return this.$t('delete_error_allow');
                default: return this.$t('deleteErrorMsg') ;
            }
        },
        deleteAfterFunc() {
            EventBus.$emit('changeLoading', false);
            this.createAlert({
                content: this.$t('deleteSuccessMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    this.initSetData();
                    this.getCommentList();
                }
            });
        },
        cancelComment() {
            this.commentStatus = '';
            this.updateCheck = '';
        },
        saveComment(key) {
            if (this.update_msg.length === 0) {
                this.errorAlert(this.$t('write_a_comment'));
                return false;
            }
            EventBus.$emit('changeLoading', true);
            let params = JSON.parse(JSON.stringify(this.writeApiParamSet));
            let dataArr = Object.keys(this.upParsingData);
            dataArr.forEach(dataKey => {
                if(this.upParsingData[dataKey] != '') {
                    params[this.upParsingData[dataKey]] = this[dataKey];
                }
            });
            params[this.keyName] = key;
            try {
                this.$api.$competition[this.updateFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    if (res.Info.type === this.successType) {
                        this.saveCommentAfterFunc();
                    } else {
                        this.errorAlert(this.$t('b_save_fail'));
                    }
                });
            } catch (e) {
                EventBus.$emit('changeLoading', false);
                console.log(e);
            }
        },
        saveCommentAfterFunc() {
            EventBus.$emit('changeLoading', false);
            this.createAlert({
                content: this.$t('saveMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    this.commentStatus = '';
                    this.initSetData();
                    this.getCommentList();
                },
            });
        },
        userCommentCheck(oldVal, newVal) {
            return oldVal === newVal;
        },

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
