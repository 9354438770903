<template>
    <div class="item_wrap mb40">
<!--        <div class="main_title">{{ $t('en_art') }}</div>-->

        <div class="item_category_wrap padding_side mt60">
            <button class="item_category" :class="{'active':categoryType === ''}" @click="changeCategory('')">All
            </button>
            <button class="item_category" :class="{'active':categoryType===data.node_id}"
                    @click="changeCategory(data)" v-for="data in itemCategoryList"
                    :key="`categort${data.node_id}`"> {{ data.NAME_ENG }}
            </button>
        </div>
        <swiper class="item_swiper" ref="swiper" :options="swiperOption">
            <swiper-slide class="item_swiper_slide" style="height: 45%;"
                          v-for="index in Math.ceil(parseFloat(itemList.length )/2)"
                          :key="`item${index}`">
                <div>
                    <div class="item_premium_list_wrap item_card_wrap">
                        <div class="list_item">
                            <div class="thumbnail" @click="movePage(itemList[(index -1)*2])"
                                 :style="` background-image: url(${returnThumbnail(itemList[(index -1)*2])});`">
                                <div class="card_category cc_category" @click="movePage(itemList[(index -1)*2])"> {{
                                        itemList[(index - 1) * 2].ic_name_eng
                                    }}
                                </div>
                                <div class="card_category cc_real" v-if="itemList[(index -1)*2].i_type ==='real'">{{ $t('real') }}</div>
                                <div class="card_category cc_real play_icon" v-if="itemList[(index -1)*2].im_type === 'video'|| itemList[(index -1)*2].im_type==='audio'" >
                                    <img class="icon play" src="@/assets/image/icon_play.png" alt="">
                                </div>
                            </div>
                            <div class="card_detail">
                                <div class="profile_group">
                                    <div @click="openModal('popup-modal',itemList[(index -1)*2])">
                                        <img class="card_profile" :src="returnCollectImage(itemList[(index -1)*2])" alt="">
                                        <img class="card_profile" :src="returnItemProfile(itemList[(index -1)*2],'provider')" alt="">
                                        <img class="card_profile" :src="returnItemProfile(itemList[(index -1)*2],'originator')" alt="">
                                        <img class="card_profile" v-if="checkCurator(itemList[(index -1)*2])"
                                             :src="returnItemProfile(itemList[(index -1)*2],'curator')" alt="">
                                    </div>

                                </div>
                                <div class="icon_group">
                                    <div class="icon_wrap pr-10">
                                        <div class="like_img" :class="{'active': isMyLike(itemList[(index -1)*2])}"
                                             @click="likeItem(itemList[(index -1)*2].i_idx)"></div>
                                        <div class="icon_count" @click="likeItem(itemList[(index -1)*2].i_idx)">{{ itemList[(index -1)*2].im_real_like }}</div>
                                    </div>
                                    <div class="icon_wrap" @click="movePage(itemList[(index -1)*2])">
                                        <img class="icon eye" src="@/assets/image/eye_empty.png" alt="">
                                        <div class="icon_count">{{ itemList[(index -1)*2].im_real_hit }}</div>
                                    </div>
                                </div>
                                <div class="card_title card_tit_home" @click="movePage(itemList[(index -1)*2])">
                                    {{ itemList[(index -1)*2].i_name }}
                                </div>
                                    <div class="premium" @click="movePage(itemList[(index -1)*2])"
                                         v-if="checkPremium(itemList[(index -1)*2])">premium
                                    </div>
                                    <div class="item_status time" @click="movePage(itemList[(index -1)*2])">
                                        {{ returnItemType(itemList[(index -1)*2]) }}
                                    </div>
                                    <div class="clear"></div>
                                <div class="item_price" @click="movePage(itemList[(index -1)*2])">
                                    <span class="price">{{ returnCommas(itemList[(index -1)*2].i_price) }} Mg </span><br/>
                                    <span class="item_small">({{ returnMingle(itemList[(index -1)*2].i_price) }})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="item_premium_list_wrap item_card_wrap">
                        <div class="list_item">
                            <div class="thumbnail" @click="movePage(itemList[(index * 2) -1])"
                                 :style="` background-image: url(${returnThumbnail(itemList[(index * 2) -1])});`">
                                <div class="card_category cc_category" @click="movePage(itemList[(index * 2) -1])"> {{
                                        itemList[(index - 1) * 2].ic_name_eng
                                    }}
                                </div>
                                <div class="card_category cc_real" v-if="itemList[(index * 2) -1].i_type ==='real'">{{ $t('real') }}</div>
                                <div class="card_category cc_real play_icon" v-if="itemList[(index * 2) -1].im_type === 'video'|| itemList[(index * 2) -1].im_type==='audio'" >
                                    <img class="icon play" src="@/assets/image/icon_play.png" alt="">
                                </div>
                            </div>
                            <div class="card_detail">
                                <div class="profile_group">
                                    <div @click="openModal('popup-modal',itemList[(index * 2) -1])">
                                        <img class="card_profile" :src="returnCollectImage(itemList[(index * 2) -1])" alt="">
                                        <img class="card_profile" :src="returnItemProfile(itemList[(index * 2) -1],'originator')" alt="">
                                        <img class="card_profile" :src="returnItemProfile(itemList[(index * 2) -1],'provider')" alt="">
                                        <img class="card_profile" v-if="checkCurator(itemList[(index * 2) -1])"
                                             :src="returnItemProfile(itemList[(index * 2) -1],'curator')" alt="">
                                    </div>

                                </div>
                                <div class="icon_group">
                                    <div class="icon_wrap pr-10">
                                        <div class="like_img" :class="{'active': isMyLike(itemList[(index * 2) -1])}"
                                             @click="likeItem(itemList[(index * 2) -1].i_idx)"></div>
                                        <div class="icon_count" @click="likeItem(itemList[(index * 2) -1].i_idx)">{{ itemList[(index * 2) -1].im_real_like }}</div>
                                    </div>
                                    <div class="icon_wrap" @click="movePage(itemList[(index * 2) -1])">
                                        <img class="icon eye" src="@/assets/image/eye_empty.png" alt="">
                                        <div class="icon_count">{{ itemList[(index * 2) -1].im_real_hit }}</div>
                                    </div>
                                </div>
                                <div class="card_title card_tit_home" @click="movePage(itemList[(index * 2) -1])">
                                    {{ itemList[(index * 2) -1].i_name }}
                                </div>
                                <div class="premium" @click="movePage(itemList[(index * 2) -1])"
                                     v-if="checkPremium(itemList[(index * 2) -1])">premium
                                </div>
                                <div class="item_status time" @click="movePage(itemList[(index * 2) -1])">
                                    {{ returnItemType(itemList[(index * 2) -1]) }}
                                </div>
                                <div class="clear"></div>
                                <div class="item_price" @click="movePage(itemList[(index * 2) -1])">
                                    <span class="price">{{ returnCommas(itemList[(index * 2) -1].i_price) }} Mg </span><br/>
                                    <span class="item_small">({{ returnMingle(itemList[(index * 2) -1].i_price) }})</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </swiper-slide>

            <div class="swiper-pagination contest_swiper_pagination " slot="pagination"></div>
        </swiper>
    </div>
</template>


<script>
import itemUtil from "@/components/item/itemUtil";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import priceMixins from "@/mixins/priceMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemHomeListComponent",
    mixins: [itemUtil, alertMixins, imageOption, dateMixins, imageResizeMixins, priceMixins],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemType: '',
    },
    data() {
        return {
            listLayout: '',
            listApiParamSet: '',
            getFunc: '',
            itemList: {},
            categoryName: 'All',
            categoryType: '',
            memberData: {},
            memberCheck: false,
            currentTime: '',

            swiperOption: {
                slidesPerView: 1,
                spaceBetween: 25,
                // slidesPerColumn: 2,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false
                },
                // centeredSlides: true,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    type: 'bubble',
                    clickable: true
                },
            },
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
        this.initConfig();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            this.getHomeData();
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let config = this.returnItemTemplateConfig(this.itemType);
            this.listLayout = config.listLayout;
            this.getFunc = config.getFunc;
            this.listApiParamSet = config.listApiParamSet;

            this.getHomeData();
        },
        setData(key, value) {
            this[key] = value;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        getHomeData() {
            let params = JSON.parse(JSON.stringify(this.listApiParamSet));
            if (this.categoryType !== '') {
                params.ic = [this.categoryType];
            }
            try {
                this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.itemList = res.List
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        changeCategory(data) {
            if (data === '') {
                this.categoryType = '';
                this.categoryName = 'All'
            } else {
                this.categoryType = data.node_id;
                this.categoryName = this.returnCategoryName(data);
            }
            this.getHomeData();
        },
        returnCategory(data) {
            let itemIndex = this.itemCategoryList.findIndex(category => category.node_id === data);
            if (itemIndex > -1) {
                return this.returnCategoryName(this.itemCategoryList[itemIndex]);
            }
        },
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, )}`;
                // return data.ItemFile.Item[0].org_url;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }

            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        returnCategoryName(data) {
            if (data.node_id === 2) {
                return 'Art'
            } else if (data.node_id === 3) {
                return 'Photo'
            } else if (data.node_id === 4) {
                return 'Video'
            } else if (data.node_id === 5) {
                return 'Music'
            } else if (data.node_id === 9) {
                return 'Collectibles'
            } else if (data.node_id === 178) {
                return 'Prompt'
            }
        },
        returnCommas(x = 0) {
            if (util.isEmpty(x)) {
                return 0;
            }
            return util.Number.numFormat(x);
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/openmarket/${data.i_sku}`);
            }

        },
        openModal(name, data) {
            this.$modal.show(name, {itemData: data});
        },
        isMyLike(data) {
            return data?.Like?.myLike
        },
        likeItem(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.i_idx = key
                this.$api.$item.itemLike(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.likeAfterFunc('add', key);
                    } else if (res.Info.type === 2) {
                        this.likeAfterFunc('sub', key);
                    }
                })
            }
        },
        likeAfterFunc(type, key) {
            let likeIndex = this.itemList.findIndex(item => item.i_idx === key);

            if (likeIndex > -1) {
                let likeCnt = Number(this.itemList[likeIndex].im_real_like)
                if (type === 'add') {
                    this.itemList[likeIndex].im_real_like = likeCnt + 1;
                } else if (type === 'sub') {
                    this.itemList[likeIndex].im_real_like = likeCnt - 1;
                }
                this.itemList[likeIndex].Like.myLike = !this.itemList[likeIndex].Like.myLike;
                this.$forceUpdate();
            }
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
