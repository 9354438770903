<template>
    <div>
        PointWriteDefaultLayout
    </div>
</template>


<script>
export default {
    name: "PointWriteDefaultLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            isActive: false,
            errorMsg: "",
            nowSelect: 1,
        }
    },
    beforeRouterEnter() {
    },
    async created() {
        openTerms = this.openTerms;

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        nowTab(index){
            this.nowSelect = index;
        },
        openTerms(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
