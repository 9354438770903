<template>
    <div class="">
        <item-filter-link :filterLayout="filterLayout" :filterData="filterSet.filter"
                          :sortData="filterSet.sort"></item-filter-link>
        <item-empty-link :emptyLayout="emptyLayout" :finishGetList="finishGetList"
                         v-if="List.length === 0"></item-empty-link>
        <item-list-link :listLayout="listLayout" :itemData="List" :paginationData="filterSet.page"
                        v-else></item-list-link>
        <pagination-link :paginationLayout="paginationLayout" :paginationData="filterSet.page" :listLength="List.length"
                         :finishGetList="finishGetList"></pagination-link>
    </div>
</template>

<script>
import ItemListLink from "@/components/item/ItemListLink";
import ItemEmptyLink from "@/components/item/ItemEmptyLink";
import ItemFilterLink from "@/components/item/ItemFilterLink";
import PaginationLink from "@/components/item/PaginationLink";
import itemUtil from "@/components/item/itemUtil";
import listMixins from "@/mixins/listMixins";
import filterMixins from "@/mixins/filterMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";

const {filterConfig} = itemUtil;

export default {
    name: "ItemListComponent",
    mixins: [itemUtil, alertMixins, filterMixins, listMixins],
    components: {
        ItemListLink,
        ItemEmptyLink,
        ItemFilterLink,
        PaginationLink,
    },
    inject: [],
    provide() {
        return {
            setPage: this.setPage,
            setFilter: this.setFilter,
            setSort: this.setSort,
            setItemListData: this.setData,
        }
    },
    props: {
        itemType: '',
        categoryKey: {default: 0},
        memberKey: '',
    },
    data() {
        return {
            listLayout: '',
            filterLayout: '',
            emptyLayout: '',
            paginationLayout: '',
            getFunc: '',
            listApiParamSet: {},
            keyName: '',
            pageRow: '',
            paginationType: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.setFilterConfig(filterConfig)
        this.setParserType('url', this.$route.fullPath);
    },
    mounted() {
        this.getList(true);
        EventBus.$on('getItemList', this.getList);
        EventBus.$on('acquisition', this.assumeAlert);
        EventBus.$on('likeItem', this.likeItem);
    },
    beforeDestroy() {
        EventBus.$off('getItemList');
        EventBus.$off('acquisition');
        EventBus.$off('likeItem');

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initConfig() {
            let itemConfig = this.returnItemTemplateConfig(this.itemType);
            this.listLayout = itemConfig.listLayout;
            this.filterLayout = itemConfig.filterLayout;
            this.emptyLayout = itemConfig.emptyLayout;
            this.paginationLayout = itemConfig.paginationLayout;
            this.getFunc = itemConfig.getFunc;
            this.listApiParamSet = itemConfig.listApiParamSet;
            this.keyName = itemConfig.keyName;
            this.pageRow = itemConfig.pageRow
            this.paginationType = itemConfig.paginationType;

            if (itemConfig.pageRow && itemConfig.pageRow != this.filterSet.page.size) {
                this.pageRow = itemConfig.pageRow;
                this.filterSet.page.size = itemConfig.pageRow;
                this.pageClass.setterPage('size', this.pageRow);
            }

            let params = JSON.parse(JSON.stringify(this.listApiParamSet));

            if (Number(this.categoryKey) !== 0) {
                params.ic = [this.categoryKey];
            }
            if ((this.memberKey !== '' || !util.isEmpty(this.memberKey)) && !util.isEmpty(this.keyName)) {
                params[this.keyName] = this.memberKey
            }

            let listOptions = {
                url: this.$api.$item[this.getFunc],
                defaultParams: params,
            }
            this.sortOptions = itemConfig.sortOptions
            this.setSortOptions(this.sortOptions)
            this.initList('api', listOptions);
        },
        assumeAlert(data, type, key, name) {
            this.createConfirm({
                content: this.$t('assumeMsg1', {name: name}),
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.acquisition(data, type, key)
                },
                cancel: () => {

                }
            })
        },
        acquisition(data, type, key) {
            let params = {
                i_idx: data,
                m_idx: key,
            }
            if (type === 1) {
                params.type = 'auction'
            } else if (type === 3) {
                params.type = 'pricetag'
            }
            this.$api.$item.setAssume(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.marketPriceUpdate(data);
                    return;
                }
                this.errorAlert(this.$t('assumeMsg'));
                return;

            })
        },
        marketPriceUpdate(key) {
            let params = {
                i_idx: key
            }
            this.$api.$item.marketPriceUpdate(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            EventBus.$emit('apiLoading', false);
                            this.$router.back();
                        }
                    })
                } else {
                    this.getList();
                }
            })
        },
        likeItem(key) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                let params = {}
                params.i_idx = key
                this.$api.$item.itemLike(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type === 1) {
                        this.likeAfterFunc('add', key);
                    } else if (res.Info.type === 2) {
                        if (this.itemType === 'interestItem') {
                            this.likeListAfterFunc(key)
                        } else {
                            this.likeAfterFunc('sub', key);
                        }
                    }
                })
            }
        },
        likeAfterFunc(type, key) {
            let likeIndex = this.List.findIndex(item => item.i_idx === key);
            if (likeIndex > -1) {
                let likeCnt = Number(this.List[likeIndex].im_real_like)
                if (type === 'add') {
                    this.List[likeIndex].im_real_like = likeCnt + 1;
                } else if (type === 'sub') {
                    this.List[likeIndex].im_real_like = likeCnt - 1;
                }
                this.List[likeIndex].Like.myLike = !this.List[likeIndex].Like.myLike;
                this.$forceUpdate();
            }
        },
        likeListAfterFunc(key) {
            let likeIndex = this.List.findIndex(item => item.i_idx === key);
            if (likeIndex > -1) {
                this.List.splice(likeIndex, 1);
                this.filterSet.page.total = this.filterSet.page.total - 1;
                if (this.List.length < this.filterSet.page.total) {
                    let params = JSON.parse(JSON.stringify(this.listApiParamSet));
                    params.page = this.filterSet.page.page * this.filterSet.page.size;
                    params.sp = 1;
                    this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                        if (res.List.length > 0) {
                            this.List.push(res.List[0]);
                        }
                    })
                }
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
