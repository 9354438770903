<template>
    <div class="mypage_list_wrap mb24 border-top">
        <div class="mypage_list " v-for="(curation, index) in curationData" :key="`curation${curation.ibc_idx}`">
            <div class="item_information">
                <div class="list_time" @click.self="moveItemDetail(curation)">
                    {{returnDateFormat(curation.created_at, 'YYYY-MM-DD HH:mm')}}
                </div>
                <div v-if="returnStatusData(curation.ibc_status).hasCancel">
                    <button class="mypage_list_two_btn" @click="openAllowModal(curation)" >
                        {{ $t('accept') }}
                    </button>
                    <button class="mypage_list_two_btn right"
                            @click="rejectRequest(curation)">{{ $t('refuse') }}
                    </button>
                </div>
                <button class="mypage_list_btn navy_back right" @click="cancelRequest(curation)" v-else-if="hasCancel(curation)">
                    {{ $t('curation_abandonment') }}
                </button>
                <button class="auction_history" @click="openAuctionHistory(curation.m_idx)" v-if="curation.m_type === 1">
                    <img src="@/assets/image/auction_history_icon.png" >
                </button>
                <button class="list_record_img" v-if="returnStatusData(curation.ibc_status).showMarketHistory" @click="$router.push(`/mypage/item/${curation.m_idx}/history`)"></button>
            </div>
            <div class="item_img_wrap mt11" @click.self="moveItemDetail(curation)">
                <div class="item_img pt3" @click="moveItemDetail(curation)">
                    <img :src="returnItemImage(curation)">
                </div>
                <div class="item_text_list" @click="moveItemDetail(curation)">
                    <div class="item_text bold">[{{ $t(returnStatusData(curation.ibc_status).showName) }}{{returnDetailStatus(curation)}}]</div>
                    <div class="item_text"><span>{{ $t('curator') }}</span>{{ curation.curator_nick }}</div>
                    <div class="item_text">{{ curation.i_name }}</div>
                    <img src="@/assets/image/mg_icon.png">
                    <div class="item_text bold">{{ returnPrice(curation) }}</div>
                </div>
            </div>
        </div>
        <mypage-request-modal></mypage-request-modal>
        <auction-history-modal></auction-history-modal>
    </div>
</template>


<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {curationStatus} from "@/service/curationService";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import MypageRequestModal from "@/components/modal/MypageRequestModal";
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";
// import CurationTermsModal from "@/components/modal/CurationTermsModal";
// import TermsModal from "@/components/modal/TermsModal";
export default {
    name: "CurationListDefaultLayout",
    mixins: [alertMixins, imageOption, dateMixins],
    components: {
        MypageRequestModal,
        AuctionHistoryModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        curationData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
            curationLayouts: 10,
            tempSubject: this.$t('temp_info')
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnItemImage(curation) {
            if(util.isEmpty(curation.ItemFile) || util.isEmpty(curation.ItemFile.Item)) {
                return "";
            }
            return `${curation.ItemFile.Item[0].org_url}?${this.setImageOptions(172, 90, 'jpg')}`;
        },
        returnStatusData(value) {
            let status = {value: null, name: '-', showName: '-', hasCancel: false, showMarketHistory: false,};
            let index = curationStatus.findIndex(st => st.value === value);
            if(index > -1) {
                status = curationStatus[index];
            }
            return status;
        },
        hasCancel(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.hasCancel) {
                return true;
            }
            return curation.ibc_status === 1 && curation.m_type == null;
        },
        returnPrice(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if((status.value == 4 || curation.i_status === 'pricetag' || curation.i_status === 'auction') && !isNaN(curation.i_price)) {
                return util.Number.numFormat(curation.i_price)
            }
            return '-'
        },
        openAuctionHistory(m_idx) {
            this.$modal.show('auction-history-modal', {m_idx : m_idx})
        },
        moveItemDetail(curation) {
            let url = `/item/${curation.i_sku}`
            if(curation.im_status === 2 || curation.im_status === 5) {
                url = `/premiummarket/${curation.i_sku}`
            }else if(curation.im_status === 1 || curation.im_status === 3 || curation.im_status === 4) {
                url = `/openmarket/${curation.i_sku}`
            }
            this.$router.push(url);
        },
        returnDetailStatus(curation) {
            let status = this.returnStatusData(curation.ibc_status);
            if(status.value === 1 && curation.m_type == 1) {
                return this.$t('auction')
            }else if(status.value === 1 && curation.m_type == 3) {
                return this.$t('fix_selling')
            }
            // return '';
            return status.detailName === '-' ? '' : '·' + this.$t(status.detailName)
        },
        acceptConfirm() {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ this.tempSubject + "</span>'<br>" +
                    this.$t('mypage_accept_confirm')
                    +'<br>'+
                    this.$t('mypage_accept_confirm3'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {

                },
                cancel: () => {

                }
            })
        },
        cancelRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_cancel_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    // this.errorAlert('추후에 기능이 추가됩니다.')
                    EventBus.$emit('curationListDelete', curation.ibc_idx)
                },
            })
        },
        openAllowModal(curation) {
            this.$modal.show('mypage-request-modal', {curationData: curation});
        },
        rejectRequest(curation) {
            this.createConfirm({
                title: '',
                content: " '<span class='subject'>"+ curation.i_name + "</span>'<br>" +
                    this.$t('mypage_refuse_confirm'),
                confirmText : this.$t('yes'),
                cancelText : this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('curationListReject', curation);
                },
            })
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
