<template>
    <modal name="judge-search-modal"
           class="judge-search-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :scrollable="true"
           :width="'100%'"
           :height="'100%'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="judge_search_wrap">
            <page-header :header-title="$t('search_judge')">
                <button slot="headerBtns" class="h_x_btn" @click="modalClose"></button>
            </page-header>
<!--            <page-header :header-title="$t('search_judge')" :header_j_modal_back="true"></page-header>-->
            <member-list-component :member-type="'judge'" :category-key="'1'"></member-list-component>
            <!--      <div>-->
            <!--        <item-filter-modal-filter-layout></item-filter-modal-filter-layout>-->
            <!--        <div class="list_wrap">-->
            <!--          <div class="creator_list">-->
            <!--            <div class="creator_top mb6" v-for="creator in 8">-->
            <!--              <div class="creator_img">-->
            <!--                <div class="profile_background"></div>-->
            <!--                <div class="curator_icon">{{ $t('en_curator') }}</div>-->
            <!--              </div>-->
            <!--              <div class="profile_info">-->
            <!--                <div class="profile_img"></div>-->
            <!--                <div class="profile_s_mark"></div>-->
            <!--                <div class="profile_text">nickname</div>-->
            <!--                <div class="creator_like">-->
            <!--                  <img src="@/assets/image/heart_img.png">-->
            <!--                  <div class="like_cnt">123</div>-->
            <!--                  <div class="sub_point">.</div>-->
            <!--                  <div class="item_subject">Item <span class="bold">123123</span></div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--        <div>-->
            <!--          <div class="creator_list">-->
            <!--            <div class="creator_top mb6" v-for="creator in memberData" :key="`member${creator.mb_no}`"-->
            <!--                 @click="moveDetail(creator)">-->
            <!--              <div class="creator_img">-->
            <!--                <div class="profile_background"-->
            <!--                     :style="`background-image: url(${returnBackground(creator)});`"></div>-->
            <!--                <div class="curator_icon" v-if="isCurator(creator)">{{ $t('en_curator') }}</div>-->
            <!--              </div>-->
            <!--              <div class="profile_info">-->
            <!--                <div class="profile_img" :style="`background-image: url(${returnThumbnail(creator)});`"></div>-->
            <!--                <div class="profile_s_mark" v-if="isSpecial(creator)"></div>-->
            <!--                <div class="profile_text">{{ creator.mb_nick }}</div>-->
            <!--                <div class="creator_like">-->
            <!--                  <img src="@/assets/image/heart_img.png">-->
            <!--                  <div class="like_cnt">{{ returnLike(creator) }}</div>-->
            <!--                  <div class="sub_point">.</div>-->
            <!--                  <div class="item_subject">Item <span class="bold">{{ returnItem(creator) }}</span></div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--            </div>-->
            <!--          </div>-->
            <!--        </div>-->
            <!--        </div>-->
        </div>
    </modal>

</template>
<script>
import MemberListComponent from "@/components/member/MemberListComponent";

export default {
    name: "JudgeSearchModal",
    mixins: [],
    components: {
        MemberListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            url: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            let path = this.$route.path;
            if (path != this.$route.fullPath) {
                this.$router.replace(path);
            }
        },
        modalClose() {
            this.$modal.hide('judge-search-modal');

        },
        returnUrl() {
            return this.url
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
