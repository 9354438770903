<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="detect_detail_wrap">
            <div>
                <div class="detect_detail_wrap_top detection_filter_top">
                    <p class="sub_title_m sub_title_m_detect">{{ $t('d_img_title') }}</p>
                    <h3>{{ itemData.i_name }}</h3>
                </div>
                <div class="detect_detail_wrap_bottom detection_filter_bottom">
                    <div class="detection_result_btn one_btn"
                         v-html="$t('d_result_text1', {count: `<strong class='detection_result_number'>${returnNumFormat()}</strong>`})">
                    </div>
                </div>
            </div>
            <detection-list-component :detection-type="'urlList'" :detectionKey="detectionKey"></detection-list-component>
        </div>
        </div>
</template>

<script>


import DetectionListComponent from "../../components/detection/DetectionListComponent";
import util from "@/mixins/util";

export default {
    name: "DetectionUrl",
    mixins: [],
    components: { DetectionListComponent},
    inject: [],
    provide() {
        return {
            setDetectItemData: this.setDetectItemData
        }
    },
    props: {},
    data() {
        return {
            detectionKey: Number(this.$route.params.d_idx),
            itemData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectItemData(item) {
            this.itemData = item;
        },
        returnNumFormat() {
            if(util.isEmpty(this.itemData.cnt) || isNaN(this.itemData.cnt)) {
                return 0;
            }
            return util.Number.numFormat(this.itemData.cnt);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
