<template>
    <div class="dgm_wrap padding_side mb197">
        <div class="search_bar padding_side">
            <div class="check_wrap mb15">
                <div class="checkbox inline_block">
                    <el-checkbox-group v-model="searchInputOption">
                        <el-checkbox :label="'member'">{{ $t('creator') }}</el-checkbox>
                        <el-checkbox :label="'item'">{{ $t('item') }}</el-checkbox>
                        <el-checkbox :label="'competition'">{{ $t('competition') }}</el-checkbox>
                        <el-checkbox :label="'collect'">{{ $t('collection') }}</el-checkbox>
                    </el-checkbox-group>
                </div>
            </div>
            <div class="search_item">
                <div class="search_input">
                    <el-input :placeholder="$t('st')" v-model="searchInput"
                              @keypress.enter.native="getList(searchInput)">
                    </el-input>
                    <button class="search_btn" @click="getList(searchInput)"></button>
                </div>
            </div>
        </div>
        <div>
            <div class="search_title mt30 mb15" v-if="checkSearchResult('member')">{{ $t('c_search') }}
                <span class="title_bold">{{ memberPageData }}</span> {{ $t('amount') }}
                <member-list-search-creator-layout :member-data="memberData" v-if="memberPageData>0"
                                                   :paginationData="memberPageData"></member-list-search-creator-layout>
                <div v-else>
                    <div class="empty_wrap">
                        <div class="img_box"></div>
                        <div class="text_box">{{ $t('no_search_input', {input: searchInputResult}) }}</div>
                    </div>
                </div>
                <div class="search_result_btn mb60">
                    <button class="more_btn font_white" v-if="memberPageData>8"
                            @click="movePage(`/search/member?ni=${searchInput}`)"><img
                            class="more_btn_img" src="@/assets/image/more_btn.png">
                        {{ $t('en_morebtn') }}
                    </button>
                </div>
            </div>

            <div class="search_title mt30 mb15" v-if="checkSearchResult('item')">
                {{ $t('i_search') }}
                <span class="title_bold">{{ itemPageData }}</span>
                {{ $t('amount') }}
                <item-list-searchlist-layout :item-data="itemData"
                                             :paginationData="itemPageData"
                                             v-if="itemPageData>0"></item-list-searchlist-layout>
                <div v-else>
                    <div class="empty_wrap">
                        <div class="img_box"></div>
                        <div class="text_box">{{ $t('no_search_input', {input: searchInputResult}) }}</div>
                    </div>
                </div>

                <div class="search_result_btn mt15 mb60">
                    <button class="more_btn font_white" v-if="itemPageData>8"
                            @click="movePage(`/search/item?ina=${searchInput}`)">+ More
                        View
                    </button>
                </div>
            </div>
            <div class="search_title mt30 mb15" v-if="checkSearchResult('competition')">{{ $t('competition_search') }}
                <span class="title_bold">{{ competitionPageData }}</span> {{ $t('amount') }}
                <competition-list-search-layout :competitionData="competitionData"
                                                :paginationData="competitionPageData"
                                                v-if="competitionPageData>0"></competition-list-search-layout>
                <div v-else>
                    <div class="empty_wrap">
                        <div class="img_box"></div>
                        <div class="text_box">{{ $t('no_search_input', {input: searchInputResult}) }}</div>
                    </div>
                </div>

                <div class="search_result_btn mb60">
                    <button class="more_btn font_white" v-if=" competitionPageData> 8"
                            @click="movePage(`/search/competition?ti=${searchInput}`)">+
                        More View
                    </button>
                </div>
            </div>

            <div class="search_title mt30 mb15" v-if="checkSearchResult('collect')">{{ $t('collect_search') }}
                <span class="title_bold ">{{ collectPageData }}</span> {{ $t('amount') }}
                <div class="text_left mt10" v-if="collectPageData>0">
                    <collect-list-search-layout :collectData="collectData"
                                                 :paginationData="collectPageData"
                    ></collect-list-search-layout>
                </div>
                <div v-else>
                    <div class="empty_wrap">
                        <div class="img_box"></div>
                        <div class="text_box">{{ $t('no_search_input', {input: searchInputResult}) }}</div>
                    </div>
                </div>

                <div class="search_result_btn mb60">
                    <button class="more_btn font_white" v-if=" collectPageData> 4"
                            @click="movePage(`/search/collect?ti=${searchInput}`)">+
                        More View
                    </button>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import MemberListComponent from "@/components/member/MemberListComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import alertMixins from "@/mixins/alertMixins";
import ItemListSearchlistLayout from "@/template/item/list/ItemListSearchlistLayout";
import CompetitionListSearchLayout from "@/template/competition/list/CompetitionListSearchLayout";
import MemberListSearchCreatorLayout from "@/template/member/list/MemberListSearchCreatorLayout";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import CollectListDefaultLayout from "@/template/collect/list/CollectListDefaultLayout";
import CollectListSearchLayout from "@/template/collect/list/CollectListSearchLayout";

export default {
    name: "SearchComponent",
    mixins: [alertMixins,],
    components: {
        CollectListSearchLayout,
        CollectListDefaultLayout,
        MemberListSearchCreatorLayout,
        CompetitionListSearchLayout,
        ItemListSearchlistLayout,
        MemberListComponent,
        ItemListComponent,
        CompetitionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            searchInputOption: ['member', 'item', 'competition', 'collect'],
            searchInput: '',
            memberData: [],
            memberPageData: 0,
            itemData: [],
            itemPageData: 0,
            competitionData: [],
            saveFilter: '',
            searchResult: [],
            competitionPageData: 0,
            searchInputResult: '',
            collectPageData: 0,
            collectData: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData()
        this.searchInput = this.$route.query.sd
    },
    mounted() {
        this.getList(this.searchInput)
        EventBus.$on('searchGetList', this.getList)
        EventBus.$on('like', this.like)
    },
    beforeDestroy() {
        EventBus.$off('searchGetList')
        EventBus.$off('like')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        setterFilterDataInData() {
            if (!util.isEmpty(this.$route.query.sd)) {
                this.searchInput = this.$route.query.sd
            }
            if (!util.isEmpty(this.$route.query.sty)) {
                let styArray = this.$route.query.sty.split(',');
                this.searchInputOption = styArray;
            }
        },
        getList(input = '') {
            this.searchInput = input
            if (this.searchInput === '') {
                return false;
            }
            this.searchResult = this.searchInputOption;
            this.getMemberList();
            this.getItemList();
            this.getCompetitionList();
            this.getCollect();
            this.searchInputResult = this.searchInput
            this.saveFilter = `?sd=${this.searchInput}&sty=${this.searchInputOption}`
            let url = `${this.$route.path}${this.saveFilter}`;
            this.$router.replace(url)
        },
        getMemberList() {
            if (this.searchInputOption.findIndex(search => search === 'member') < 0) {
                return;
            }
            let params = {
                ni: this.searchInput,
                obt: 'mb_nick',
                obs: 'desc',
                ip: 1,
                sp: 10,
                page: 1
            }
            this.$api.$member.getMember(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.errorAlert(this.$t('listErrorMsg1'))
                    return
                }
                this.memberData = res.List;
                this.memberPageData = res.Page.total;
            })
        },
        getItemList() {
            if (this.searchInputOption.findIndex(search => search === 'item') < 0) {
                return;
            }
            let params = {
                ina: this.searchInput,
                obt: 'i_name',
                obs: 'desc',
                en: 1,
                ip: 1,
                sp: 8,
                page: 1
            }
            this.$api.$item.getItem(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.errorAlert(this.$t('listErrorMsg1'))
                    return
                }
                this.itemData = res.List;
                this.itemPageData = res.Page.total;
            })
        },
        getCompetitionList() {
            if (this.searchInputOption.findIndex(search => search === 'competition') < 0) {
                return;
            }
            let params = {
                ti: this.searchInput,
                obt: 'c_title',
                obs: 'desc',
                ip: 1,
                sp: 8,
                page: 1
            }
            this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.errorAlert(this.$t('listErrorMsg1'))
                    return
                }
                this.competitionData = res.List;
                this.competitionPageData = res.Page.total;
            })
        },
        getCollect() {
            if (this.searchInputOption.findIndex(search => search === 'collect') < 0) {
                return;
            }
            let params = {
                ti: this.searchInput,
                ip: 1,
                sp: 8,
                page: 1,
                opf: 1
            }
            this.$api.$collect.getCollection(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type !== 1) {
                    this.errorAlert(this.$t('listErrorMsg1'))
                    return
                }
                this.collectData = res.List;
                this.collectPageData = res.Page.total;
            })
        },
        checkSearchResult(data) {
            let resultIndex = this.searchResult.findIndex(result => result === data);
            if (resultIndex > -1) {
                return true
            }
            return false
        },
        movePage(url) {
            this.$router.push(url);
        },
        like(key, searchType) {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no !== key) {
                if (searchType === 'item') {
                    this.likeItem(key);
                } else if (searchType === 'member') {
                    this.likeMember(key);
                }
            }
        },
        likeMember(key) {
            let params = {}
            params.follow_id = key
            this.$api.$member.toggleLike(params).then(res => res.Data.Result).then(res => {
                if (res.List.type === 'create') {
                    this.likeMemberAfterFunc('add', key);
                } else if (res.List.type === 'delete') {
                    this.likeMemberAfterFunc('sub', key);
                }
            })
        },
        likeItem(key) {
            let params = {}
            params.i_idx = key
            this.$api.$item.itemLike(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.likeAfterFunc('add', key);
                } else if (res.Info.type === 2) {
                    this.likeAfterFunc('sub', key);
                }
            })
        },
        likeAfterFunc(type, key) {
            let likeIndex = this.itemData.findIndex(item => item.i_idx === key);
            if (likeIndex > -1) {
                let likeCnt = Number(this.itemData[likeIndex].im_real_like)
                if (type === 'add') {
                    this.itemData[likeIndex].im_real_like = likeCnt + 1;
                } else if (type === 'sub') {
                    this.itemData[likeIndex].im_real_like = likeCnt - 1;
                }
                this.itemData[likeIndex].Like.myLike = !this.itemData[likeIndex].Like.myLike;
                this.$forceUpdate();
            }
        },
        likeMemberAfterFunc(type, key) {
            let likeIndex = this.memberData.findIndex(data => data.mb_no === Number(key));
            if (likeIndex > -1) {
                if (type === 'add') {
                    this.memberData[likeIndex].likeCnt = this.memberData[likeIndex].likeCnt + 1;
                } else if (type === 'sub') {
                    this.memberData[likeIndex].likeCnt = this.memberData[likeIndex].likeCnt - 1;
                }
                this.memberData[likeIndex].likeStatus = !this.memberData[likeIndex].likeStatus;
                this.$forceUpdate();
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
