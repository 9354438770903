<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <detection-detail-component :detection-type="'detectionHistoryDetail'" :detection-key="detectionKey" :detection-default-value="detectionDefaultValue"></detection-detail-component>
        <!--임시-->
<!--        <detection-detail-history-layout></detection-detail-history-layout>-->
    </div>
</template>

<script>
import DetectionDetailComponent from "@/components/detection/DetectionDetailComponent";

import DetectionDetailHistoryLayout from "@/template/detection/detail/DetectionDetailHistoryLayout";

export default {
    name: "DetectionHistoryDetail",
    mixins: [],
    components: {
        DetectionDetailComponent,
        DetectionDetailHistoryLayout
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            detectionKey: Number(this.$route.params.dr_idx),
            detectionDefaultValue: {
                d_idx: Number(this.$route.params.d_idx),
            },
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
