<template>
  <div class="">
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <div slot="headerBtns">
              <button class="h_search_btn" @click="$router.push('/search')"></button>
          </div>
      </page-header>
      <page-header class="sub_header"></page-header>
    <curation-application-component></curation-application-component>
  </div>
</template>

<script>
import CurationApplicationComponent from "@/components/curation/CurationApplicationComponent";

export default {
  name: "CurationApply",
  mixins: [],
  components: {
    CurationApplicationComponent,
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{

    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{

  },
  watch: {

  },
}
</script>

<style scoped>

</style>
