<template>
  <component :is="component" v-if="component" :boardData="boardData"/>
</template>

<script>
export default {
  name: "BoardDetailLink",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
    detailLayout: '',
    boardData: {},
  },
  data() {
    return{
      component: null,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {
    this.writeLoader()
  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
    loader() {
      if (!this.detailLayout) {
        return null
      }
      return () => import(`@/template/board/detail/${this.detailLayout}`)
    },
  },
  methods:{
    writeLoader() {
      this.loader()
          .then(() => {
            this.component = () => this.loader()
          })
          .catch(() => {
            this.component = () => import('@/template/board/detail/BoardDetailDefaultLayout')
          })
    }
  },
  watch: {

  },
}
</script>

<style scoped>

</style>