<template>
    <div>
        <!--        <page-header :header_back="true">
                    <button slot="headerBtns" class="h_setting_btn" v-if="checkUpdate()" @click="movePage(`update`)"
                            :disabled="checkDisabled(itemData)"></button>
                </page-header>-->

        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header has_btn">
            <button slot="headerBtns" class="h_setting_btn" v-if="checkUpdate()" @click="movePage(`update`)"
                    :disabled="checkDisabled(itemData)"></button>
            <button slot="headerBtns" class="h_gift_btn" @click="openGiftModal()"
                    v-if="checkGift()"></button>
        </page-header>
        <item-detail-component :item-type="'premiumMarketDetail'" :item-key="itemKey"></item-detail-component>
        <share-modal></share-modal>
        <gift-item-modal></gift-item-modal>
    </div>
</template>

<script>

import ItemDetailComponent from "@/components/item/ItemDetailComponent";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import {mapState} from "vuex";
import ShareModal from "@/components/modal/ShareModal";
import GiftItemModal from "@/components/modal/GiftItemModal";

export default {
    name: "PremiumMarketDetail",
    mixins: [],
    components: {
        GiftItemModal,
        ShareModal,
        ItemDetailComponent
    },
    inject: [],
    provide() {
        return {
            'vueSetData': this.setItemData,
        }
    },
    props: {},
    data() {
        return {
            itemKey: '',
            itemData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey = this.$route.params.idx
    },
    mounted() {
        EventBus.$on('marketSetData', this.setItemData);
    },
    beforeDestroy() {
        EventBus.$off('marketSetData');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        checkUpdate() {
            if (util.isEmpty(this.itemData)) {
                this.settingStatus = false
                return
            }
            if (util.isEmpty(this.UserInfo)) {
                this.settingStatus = false;
                return
            }
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
                this.settingStatus = true;
                return
            }
            if (this.itemData.provider === this.UserInfo.mb_no) {
                this.settingStatus = true;
                return
            }

        },
        checkDisabled(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.ibc_status) && data.ibc_status === 0) {
                return true;
            }
            return false;
        },
        setItemData(value) {
            this.itemData = value
        },
        openGiftModal() {
            this.$modal.show('gift-item-modal', {itemData: this.itemData});
        },
        checkGift() {
            if (this.itemData.provider === this.UserInfo.mb_no && this.itemData.i_status === 'nosale' && util.isEmpty(this.itemData.ibc_curator)) {
                return true;
            }
            return false
        }
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.checkUpdate()
            }
        },
    },
}
</script>

<style scoped>

</style>
