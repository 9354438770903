<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <award-write-component :award-type="'awardWrite'" :competitionKey="competitionKey"></award-write-component>
        <entry-search-modal></entry-search-modal>
    </div>
</template>

<script>
import AwardWriteComponent from "@/components/award/AwardWriteComponent";
import EntrySearchModal from "@/components/modal/EntrySearchModal";

export default {
    name: "AwardWrite",
    mixins: [],
    components: {
        EntrySearchModal,
        AwardWriteComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            competitionKey: this.$route.params.c_idx
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
