<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="movePage('/search')"></button>
        </page-header>
        <div class="dgm_wrap padding_side mt105">
            <div class="login">
                <div class="login_title">
                  <span v-html="$t('loginText1')"></span>
<!--                    {{ $t('loginText1') }}-->
                </div>
                <div class="login_top_img">
                    <img src="@/assets/image/main_logo.png" alt="">
                </div>
            </div>
            <login-component></login-component>

<!--            <div class="login_sns_start mt19">-->
<!--                <div class="login_sns_wrap mb15">-->
<!--                    {{ $t('start_sns') }}-->
<!--                </div>-->
<!--            </div>-->
            <div class="login_sns mb15">
<!--                <div class="sns_login_wrap mr22"></div>-->
                <google-login-component></google-login-component>
<!--                <div class="sns_login_wrap mr22 naver"></div>-->
                <naver-login-component></naver-login-component>
<!--                <div class="sns_login_wrap mr22 kakao"></div>-->
                <kakao-login-component></kakao-login-component>
<!--                <div class="sns_login_wrap facebook"></div>-->
                <facebook-login-component></facebook-login-component>
                <apple-login-component></apple-login-component>
            </div>
        </div>
    </div>
</template>
<script>
import LoginComponent from "@/components/auth/LoginComponent";
import GoogleLoginComponent from "@/components/auth/GoogleLoginComponent";
import NaverLoginComponent from "@/components/auth/NaverLoginComponent";
import KakaoLoginComponent from "@/components/auth/KakaoLoginComponent";
import FacebookLoginComponent from "@/components/auth/FacebookLoginComponent";
import AppleLoginComponent from "@/components/auth/AppleLoginComponent";

export default {
    name: "Login",
    mixins: [],
    components: {
        AppleLoginComponent,
        LoginComponent,
        GoogleLoginComponent,
        NaverLoginComponent,
        KakaoLoginComponent,
        FacebookLoginComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
