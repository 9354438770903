<template>
    <div>
        <div class="check_wrap application_check mb33 padding_side">
            <div class="application_title mb12">
                {{ $t('select_apply') }}
            </div>
            <div class="application_box">
                <el-checkbox class="mr0" @change="changeCheck('termCheck')" v-model="termCheck"></el-checkbox>
                <span v-html="$t('entry_terms')" @click="openModal('terms-modal', 'contestItem')"></span>
            </div>
        </div>
        <item-list-component :item-type="'entryList'" :member-key="UserInfo.mb_no"></item-list-component>
    </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "EntryWriteDefaultLayout",
    mixins: [],
    components: {
        ItemListComponent,
        CompetitionListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            termCheck: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        changeCheck(dataName) {
            console.log(dataName);
            this[dataName] = !this[dataName];
            EventBus.$emit('entryWriteSetData', dataName, this[dataName])
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
