<template>
    <div class="profile-setting-modal">
        <page-header :header_back="true" :header-title="$t('profile_setting')"></page-header>
        <member-write-component :member-type="'profileSetting'" :memberKey="UserInfo.mb_no"></member-write-component>
    </div>
</template>

<script>
import {mapState} from "vuex";
import MemberWriteComponent from "@/components/member/MemberWriteComponent";

export default {
    name: "MyProfileUpdate",
    mixins: [],
    components: {
        MemberWriteComponent,
    },
    inject: [],
    provide() {
        return {};
    },
    props: {},
    data() {
        return {
            intro_textarea: '',
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        handlerBeforeOpen(event) {
            this.scroll = event.params.scroll
            document.body.style.top = `-${this.scroll}px`
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            window.scrollTo(0, this.scroll);
        },

    },

    watch: {},
};
</script>

<style scoped>

</style>
