<template>
    <div class="mypage_list_wrap">
        <div class="mypage_list border-top" v-for="(data,index) in itemData"
             :key="`item${index}`">
            <div class="item_information">
                <div class="list_time" @click="movePage(data.i_sku)">{{ data.created_at }}</div>
            </div>
            <div class="item_img_wrap mt11" @click="movePage(data.i_sku)">
                <div class="item_img">
                    <img :src="returnThumbnail(data)">
                </div>
                <div class="item_text_list">

                    <div>{{ $t('unexposed') }}</div>  <!-- 상태 -->
                    <div class="item_text">{{ data.i_name }}</div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";

export default {
    name: "ItemListMyTempSaveLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(`/item/update/${url}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(142, 90, 'png')}`;
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
