<template>
    <div>
        <div class="empty_wrap" v-if="isEmpty(historyData)">
            <div class="img_box"></div>
            <div class="text_box">{{ $t('no_default_list') }}</div>
        </div>
        <div v-else>
            <table class="table_wrap" width="100%" border="0" cellspacing="0" cellpadding="0" >
                <colgroup>
                    <col style="max-width: 25%; width: 25%;">
                    <col style="max-width: 16.66%; width: 16.66%;">
                    <col style="max-width: 16.66%; width: 16.66%;">
                    <col style="max-width: 16.66%; width: 16.66%;">
                    <col style="max-width: 25%; width: 25%;">
                    <!--                <col style="max-width: 33.3%; width: 33.3%;">
                                    <col style="max-width: 33.3%; width: 33.3%;">
                                    <col style="max-width: 33.3%; width: 33.3%;">-->
                </colgroup>
                <tr height="52" class="t_top">
                    <!--                <th scope="col" class="h_t_th">{{ $t('ownership_user') }}</th>
                                    <th scope="col" class="h_t_th">Price</th>
                                    <th scope="col" class="h_t_th">Date</th>-->
                    <th scope="col" class="h_t_th">Event</th>
                    <th scope="col" class="h_t_th">Price</th>
                    <th scope="col" class="h_t_th">From</th>
                    <th scope="col" class="h_t_th">To</th>
                    <th scope="col" class="h_t_th">Date</th>
                </tr>
                <tr class="table_content" v-for="history in historyData.slice(0, 5)"
                    :key="`itemNtfHistory${history.inh_idx}`">
                    <!--
                                    <td class="t_price">{{ returnRecord(history).nftOwner }}</td>
                                    <td> {{ getPrice(returnRecord(history).price) }} </td>
                                    <td class="t_date">{{ returnDateTimezone(returnRecord(history).createdTimestamp, 'YYYY-MM-DD HH:mm') }}</td>
                    -->
                    <td> {{getItemNftStatus(history.inh_status)}} </td>
                    <td>{{ getPrice(history.inh_price) }}</td>
                    <td>{{ isEmpty(history.inh_from) ? '-' : history.inh_from }}</td>
                    <td>{{ isEmpty(history.inh_to) ? '-' : history.inh_to }}</td>
                    <td>{{ returnDateFormat(history.created_at, 'YYYY-MM-DD HH:mm') }}</td>
                </tr>
            </table>
            <div class="nft_header_wrap">
                <div class="more_view_btn2 text-right" @click="moveHistoryMore('history')">More+</div>
            </div>

        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import priceMixins from "@/mixins/priceMixins";
import dateMixins from "@/mixins/dateMixins";
import historyUtil from "@/components/history/historyUtil";

export default {
    name: "HistoryDetailItemDetailLayout",
    mixins: [priceMixins, dateMixins, historyUtil],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        historyData: {default: () => []},
    },
    data() {
        return {
            idx: 1,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(path) {
            this.$router.push(`${path}/history`);
        },
        isEmpty(data) {
            return util.isEmpty(data);
        },
        returnRecord(history) {
            return util.isEmpty(history) || util.isEmpty(history.Record) ? {} : history.Record;
        },
        getPrice(price) {
            if (util.isEmpty(price)) {
                return '-'
            }
            return this.returnCommas(price);
        },
        returnNFTTo(data) {
            let history = this.returnRecord(data)
            if (util.isEmpty(history)) {
                return
            }
            if (history.user !== 0) {
                return history.ownerNick
            } else {
                return history.nftOwner
            }

        },
        moveHistoryMore() {
            let itemKey = this.$route.params.idx;
            this.$router.push(`/item/${itemKey}/history`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
