<template>
    <div class="application_item_list border-top pt20">
        <div class="item_premium_list_wrap pa0">
            <div class="item_premium_list" v-for="(entryItem,index) in itemData" :key="`entry-item-${index}`"
                 @click="selectEntryItem(entryItem.i_idx,entryItem.i_name)">
                <div class="img_wrap">
                    <div class="img_bg"
                         :style="`background-image : url(${setFitWidthImg(entryItem.ItemFile.Item,278)})`"></div>
                    <div class="art_card">{{ returnCategoryName(entryItem.ic_idx) }}</div>
                </div>

                <div class="content_wrap">
                    <div class="subject_wrap">{{ entryItem.i_name }}</div>
                </div>

            </div>
        </div>
    </div>

</template>

<script>
import itemCategoryMixin from "@/mixins/itemCategoryMixin";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListEntryLayout",
    mixins: [itemCategoryMixin, imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {
            type: Array,
            default: () => {
                return []
            }
        },

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnImage(image, width = 32, height = 32, f = 'png') {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, f)}`
            }
            return ''
        },
        selectEntryItem(idx, name) {
            EventBus.$emit('selectEntryItem', idx, name)
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
