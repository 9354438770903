<template>
    <component :is="component" v-if="component" />
</template>

<script>
export default {
    name: "DetectionWriteLink",
    mixins: [],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        writeLayout: '',
    },
    data() {
        return{
            component: null,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {
        this.loader()
                .then(() => {
                    this.component = () => this.loader()
                })
                .catch(() => {
                    this.component = () => import('@/template/detection/write/DetectionWriteDefaultLayout')
                })

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        loader() {
            if (!this.writeLayout) {
                return null
            }
            return () => import(`@/template/detection/write/${this.writeLayout}`)
        },
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>