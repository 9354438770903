<template>
    <div class="detection_write_request">
        <ul class="item_create_wrap radio_wrap">
            <li class="detail_notice_detect_wrap">
                <h3 class="sub_title_m regular">{{ $t('notice_text') }}</h3>
<!--                <el-input type="textarea" class="itemcreate_textarea"/>-->
                <div class="itemcreate_textarea div">
                    <terms-component :terms-type="'lawsuit'" :terms-key="'lawsuit'"></terms-component>
                </div>
            </li>
            <li class="detail_notice_detect_wrap">
                <h3 class="sub_title_m regular">{{ $t('warrant') }}</h3>
<!--                <el-input type="textarea" class="itemcreate_textarea"/>-->
                <div class="itemcreate_textarea div">
                    <terms-component :terms-type="'lawsuitRequest'" :terms-key="'lawsuitRequest'"></terms-component>
                </div>
            </li>
            <li class="detail_attach_detect_wrap">
                <h3 class="sub_title_m sub_title_m_detect regular">{{ $t('request_form') }}</h3>
                <p class="sub_title_s sub_title_s_detect">{{ $t('download_notice_text') }}</p>
                <div class="real_item">
                    <div class="w_content_box">
                        <div class="item_file">
                            <div>
                                <div tabindex="0" class="el-download el-upload el-upload--picture-card">
                                    <button class="upload-name download-name" @click="downloadSample()">
                                        <span>{{ $t('request_form') }}.zip</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <!---->
                        <!---->
                    </div>
                </div>
            </li>
            <li class="detail_info_wrap">
                <h3 class="sub_title_m regular">{{ $t('input_info') }}</h3>
                <div class="w_content_box w_content_itemcreate_box">
                    <ul class="sub_content">
                        <li>
                            <div class="sub_title_s "> {{ $t('creation_date') }} </div>
                            <div class="el-date-editor date-picker el-input el-input--prefix el-input--suffix el-date-editor--datetime">
<!--                                <el-input type="text" autocomplete="off" name="" placeholder="2021-04-02" id="itemDate"></el-input>-->
                                <datetime class="date_time_btn fm_date_time" v-model="createDateView"
                                          placeholder="2021-04-02"
                                          :week-start="7" format="yyyy-MM-dd"
                                          @input="changeDatePicker(1, $event)"></datetime>
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"> {{ $t('creation_place') }} </div>
                            <div class="el-input">
                                <el-input type="text" autocomplete="off" placeholder="Republic of Korea" v-model="place"
                                          @input="setData('place',place)"></el-input>
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"> {{ $t('email') }} </div>
                            <div class="el-input">
                                <el-input type="email" autocomplete="off" placeholder="email@email.co.kr" v-model="email"
                                          @input="setData('email',email)"></el-input>
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s"> {{ $t('phone1') }} </div>
                            <div class="el-input">
                                <el-input type="tel" autocomplete="off" placeholder="010-0000-0000" v-model="phone"
                                          @input="setData('phone',phone)"></el-input>
                            </div>
                        </li>
                        <li>
                            <div class="sub_title_s "> {{ $t('proof_page') }} </div>
                            <div class="el-input el-input-itemcreate">
                                <el-input type="text" autocomplete="off" v-model="proofUrl1" @input="inputUrl(1, proofUrl1)"></el-input>
                            </div>
                            <div class="el-input el-input-itemcreate">
                                <el-input type="text" autocomplete="off" v-model="proofUrl2" :disabled="proofUrl1 === ''"
                                          @input="inputUrl(2, proofUrl2)"></el-input>
                            </div>
                            <div class="el-input el-input-itemcreate">
                                <el-input type="text" autocomplete="off" v-model="proofUrl3" :disabled="proofUrl2 === ''"
                                          @input="inputUrl(3, proofUrl3)"></el-input>
                            </div>
                        </li>
                    </ul>
                </div>
            </li>
            <li class="detail_attach_detect_wrap">
                <h3 class="sub_title_m regular">{{ $t('request_upload') }}</h3>
                <div class="real_item">
                    <div class="w_content_box">
                        <div class="item_file">
                            <div @click="changeType('requestForm')">
                                <el-upload
                                    v-show="requestForm.length === 0"
                                    class="item_file"
                                    action=""
                                    :limit="1"
                                    :file-list="requestForm"
                                    :auto-upload="false"
                                    :show-file-list="false"
                                    :on-change="handleEveryFileChange"
                                    :on-remove="fileRemove"
                                    :disabled="requestForm.length > 0"
                                >
<!--                                    <button class="request_btn" v-if="requestForm.length === 0">
                                        <div class="download_img upload"></div>
                                        <span>{{ $t('file_upload') }}</span>
                                    </button>-->
                                    <button class="upload-name" v-if="requestForm.length === 0">
                                        <span>{{ $t('file_upload') }}</span>
                                    </button>
                                    <!--                <img v-else src="@/assets/image/main/change_item.png" alt=""
                                                         style="width: 93px; height: 74px;">-->
                                </el-upload>
                                <div tabindex="0" class="el-upload el-upload--picture-card"  v-if="requestForm.length > 0">
<!--                                    <button class="upload-name" v-if="requestForm.length === 0">
                                        <span>{{ $t('file_upload') }}</span>
                                    </button>-->
                                    <div class="filebox filebox_curation mt0 mb0 ba0"
                                         v-for="(file, index) in requestForm" :key="`requestForm${index}`"
                                         @click="fileRemove(file, [], 'requestForm')">
                                        <div class="file_name file_name_curation" >
                                            <p>{{ file.name }}</p>
                                            <button class="drop_file drop_file_curation"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!---->
                        <!---->
                    </div>
                </div>
            </li>
        </ul>
        <div class="itemcreate_btn_border">
            <button class="itemcreate_btn one_btn" @click="runWrite">{{ $t('request1') }}</button>
            <div class="pt5"></div>
            <div class="val_cap" v-show="false">{{ $t('all_input') }}</div>
            <div class="val_cap" v-if="validation.hasError('createDate')">{{validation.firstError('createDate') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('place')">{{ validation.firstError('place') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('email')">{{ validation.firstError('email') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('phone')">{{ validation.firstError('phone') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('proofUrl1')">{{validation.firstError('proofUrl1') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('proofUrl2')">{{validation.firstError('proofUrl2') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('proofUrl3')">{{validation.firstError('proofUrl3') }}</div>
            <div class="val_cap" v-else-if="validation.hasError('requestForm')">{{validation.firstError('requestForm') }}</div>
        </div>
    </div>
</template>

<script>
import detectionValidator from "@/mixins/validators/detectionValidator";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import fileMixins from "@/mixins/fileMixins";
import TermsComponent from "@/components/terms/TermsComponent";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";

import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon'
import 'vue-datetime/dist/vue-datetime.css'

export default {
    name: "DetectionWriteRequestLayout",
    mixins: [detectionValidator, elFileMixins, fileMixins, awsS3FileDownload],
    components: {
        TermsComponent,
        datetime: Datetime,
    },
    inject: ['detectionWriteSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            sampleFile: {},
            createDateView: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        let lang = this.$localStorage.get('lang')
        //Settings.defaultLocale = lang
        if(lang === 'ko') {
            Settings.defaultLocale = 'ko'
        }else{
            Settings.defaultLocale = 'en'
        }
        this.setDetectionImageKey();
        this.getSampleFile();
    },
    mounted() {
        EventBus.$on('DetectionWriteRequestLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('DetectionWriteRequestLayoutValidator')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setDetectionImageKey() {
            this.detectionImageKey = Number(this.$route.params.idx)
            this.setData('detectionImageKey', this.detectionImageKey);
        },
        setData(type, value) {
            this.detectionWriteSetData(type, value);
        },
        parentChange(type, value) {
            this.detectionWriteSetData(type, value);
        },
        inputUrl(cnt, value) {
            if(cnt === 1 && value === '') {
                this.proofUrl2 = "";
                this.setData('proofUrl2', this.proofUrl2)
                this.proofUrl3 = "";
                this.setData('proofUrl3', this.proofUrl3)
            } else if(cnt === 2) {
                this.proofUrl3 = "";
                this.setData('proofUrl3', this.proofUrl3)
            }
            this.setData(`proofUrl${cnt}`, value)
        },
        changeDatePicker(index, value) {
            if(value === '') {
                return false;
            }
            this.createDate = this.$moment(value).format('YYYY-MM-DD');
            this.parentChange('createDate', this.createDate);
        },
        runValidator() {
            this.$validate(['createDate', 'place', 'email', 'phone', 'proofUrl1', 'proofUrl2', 'proofUrl3', 'requestForm'])
        },
        getSampleFile() {
            let params = {
                cid: 1,
                cty: 'lawsuitSample',
            }
            try {
                this.$api.$file.getFile(params).then(res => res.Data.Result).then(res => {
                    if(!util.isEmpty(res.List)) {
                        this.sampleFile = res.List[0];
                    }
                })
            }catch (e) {
                console.log(e)
            }
        },
        downloadSample() {
            if (util.isEmpty(this.sampleFile.a_idx)) {
                return false;
            }
            this.runDownloadFileInfo('lawsuitSample', this.sampleFile, '1', false).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
        runWrite() {
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>w
