import { render, staticRenderFns } from "./QnaDetail.vue?vue&type=template&id=2d96a09d&scoped=true"
import script from "./QnaDetail.vue?vue&type=script&lang=js"
export * from "./QnaDetail.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d96a09d",
  null
  
)

export default component.exports