<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="creator_wrap">
<!--            <member-filter-creator-layout></member-filter-creator-layout>-->
<!--            <member-list-creator-layout></member-list-creator-layout>-->

            <member-list-component :member-type="'creator'"></member-list-component>
        </div>
<!--        <pagination-layout></pagination-layout>-->
    </div>
</template>

<script>
import MemberListComponent from "@/components/member/MemberListComponent";
import MemberFilterCreatorLayout from "@/template/member/filter/MemberFilterCreatorLayout";
import MemberListCreatorLayout from "@/template/member/list/MemberListCreatorLayout";

export default {
    name: "Creator",
    mixins: [],
    components: {
        MemberListComponent,
        MemberListCreatorLayout,
        MemberFilterCreatorLayout
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
