<template>
    <div>
        CompetitionFilterModalFilterLayout
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";

export default {
    name: "CompetitionFilterModalFilterLayout",
    mixins: [],
    components: {

    },
    inject: [],
    provide() {
        return {

        }
    },
    props: {},
    data() {
        return {
            filterStatus:[],
            filterCategory:[],
            filterPrice:[],
            listChange: 'list',
            searchMsg: '',
            searchOption: 'ina',
            sortOption: 'i.updated_at',
            searchInput: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name);
        },

        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        listTypeChange() {
            EventBus.$emit('changeListType');
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'asc');
            } else if (data === 'priceDesc') {
                this.setFilterFunc('obt', 'i_price');
                this.setFilterFunc('obs', 'desc');
            } else {
                this.setFilterFunc('obt', data);
                this.setFilterFunc('obs', 'desc');
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if (this[dataName]) {
                this.btnCheck = false;
            }
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
