<template>
    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-input :placeholder="$t('st')" v-model="filterInput"
                      @keypress.enter.native="runFilter(filterInputOption, filterInput)"/>
            <button class="search_btn mypage_search_btn" @click="runFilter(filterInputOption, filterInput)">
            </button>
        </div>
        <button class="search_filter_btn" @click="openFilter('detection-request-filter')"></button>
        <detection-request-filter></detection-request-filter>
    </div>
</template>
<script>
import detectionUtil from "@/components/detection/detectionUtil";
import {detectionStatus} from "@/service/detectionService";
import util from "@/mixins/util";
import DetectionRequestFilter from "@/components/filter/DetectionRequestFilter";

const {filterConfig} = detectionUtil;
export default {
    name: "DetectionFilterRequestLayout",
    mixins: [],
    components: {DetectionRequestFilter},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            filterStatus: [],
            filterDate: [],
            filterInput: '',
            filterInputOption: 'na',
            filterInputOptions: [
                {
                    value: 'na',
                    name: 'subject',
                }, {
                    value: 'uid',
                    name: 'request_num',
                }, {
                    value: 'do',
                    name: 'detection_domain',
                }
            ],
            detectionStatus: detectionStatus,


            searchOption: 'ina',
            sortOption: 'i.updated_at',
            searchInput: '',
            filterEndCheck: false,

            closingCheck: false,
            processCheck: false,
            requestCheck: false,
            readyCheck: false,
            civilCheck: false,
            cancelRequestCheck: false,

            inputDate: '',

        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        runFilter(key, value) {
            this.setFilter(key, value);
        },
        changeDateFilter() {
            if (util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            } else {
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
