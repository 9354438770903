<template>
    <div v-if="showLayout">
        <item-detail-link :detailLayout="detailLayout" :itemData="itemData"></item-detail-link>
    </div>
</template>

<script>
import ItemDetailLink from "@/components/item/ItemDetailLink";
import itemUtil from "@/components/item/itemUtil";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import {getStorageToken} from "@/mixins/Auth";
import priceMixins from "@/mixins/priceMixins";
import itemValidator from "@/mixins/validators/itemValidator";

export default {
    name: "ItemDetailComponent",
    mixins: [itemUtil, alertMixins, priceMixins, itemValidator],
    components: {
        ItemDetailLink,
    },
    inject: [],
    provide() {
        return {
            itemDetailSetData: this.setData,
        }
    },
    props: {
        itemType: '',
        itemKey: '',
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            itemData: {},
            detailApiParamSet: {},
            getSuccessType: '',
            getErrorTypes: {},
            getKeyName: 'id',
            showLayout: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('voteItem', this.voteItemConfirm)
        EventBus.$on('buyItem', this.buyItem);
        EventBus.$on('buyLicense', this.buyLicense);
        EventBus.$on('checkValidator', this.checkValidator);
        EventBus.$on('getDetail', this.getDetail);
    },
    beforeDestroy() {
        EventBus.$off('voteItem')
        EventBus.$off('buyLicense')
        EventBus.$off('checkValidator')
        EventBus.$off('buyItem')
        EventBus.$off('getDetail')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let itemTemplateConfig = this.returnItemTemplateConfig(this.itemType);
            this.detailLayout = itemTemplateConfig.detailLayout;
            this.getFunc = itemTemplateConfig.getFunc;
            this.detailApiParamSet = itemTemplateConfig.detailApiParamSet;
            this.getSuccessType = itemTemplateConfig.getSuccessType;
            this.getErrorTypes = itemTemplateConfig.getErrorTypes;
            this.getKeyName = itemTemplateConfig.getKeyName;
            this.getDetail();
        },
        setData(key, value) {
            this[key] = value;
        },
        getDetail() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamSet));
            if (util.isEmpty(this.getKeyName)) {
                params.id = this.itemKey;
            } else {
                params[this.getKeyName] = this.itemKey;
            }

            EventBus.$emit('apiLoading', true)
            this.$api.$item[this.getFunc](params).then(res => res.Data.Result).then(res => {
                EventBus.$emit('apiLoading', false, '아이템')
                if (res.Info.type === this.getSuccessType && !util.isEmpty(res.List)) {
                    this.itemData = res.List[0];
                    this.showLayout = true;
                } else {
                    this.createAlert({
                        content: this.$t('listErrorMsg1'),
                        btnText: this.$t('yes'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                }
            })
        },
        //공모전 투표
        voteItemConfirm(ci_idx) {
            this.createConfirm({
                content: this.$t('confirm_vote'),
                confirm: () => {
                    this.voteItem(ci_idx)
                },
                cancel: () => {

                }
            })
        },
        voteItem(ci_idx) {
            let params = {
                ci_idx: ci_idx
            }
            try {
                this.$api.$competition.voteItem(params).then(res => res.Data.Result).then(res => {
                    let result = this.returnVoteType(res.Info.type)
                    if (result === true) {
                        this.getDetail();
                        this.createAlert({
                            content: this.$t('vote_done'),
                            hide: () => {
                                // this.$router.back();
                            }
                        })
                    } else {
                        this.createAlert({
                            content: result,
                            btnText: this.$t('yes'),
                            hide: () => {
                                // this.$router.back();
                            }
                        })
                    }
                })
            } catch (e) {
                console.log(e)
            }
        },
        returnVoteType(type) {
            switch (type) {
                case 1 : {
                    return true
                }
                case -841 : {
                    return this.$t('vote_err_date')
                }
                case -842 : {
                    return this.$t('vote_err_over')
                }
                case -843 : {
                    return this.$t('vote_err_already')
                }
                case -844 : {
                    return this.$t('vote_err_fail')
                }
                default : {
                    return this.$t('vote_err_fail2')
                }
            }
        },
        checkValidator(type, price) {
            this.bid_price = price;
            this.$validate('bid_price').then(success => {
                if (success) {
                    this.buyItem(type, price);
                } else {
                    EventBus.$emit(`${this.detailLayout}Validator`);
                }
            })

        },
        buyItem(type, price) {
            EventBus.$emit('apiLoading', true);
            let params = {
                price: price,
                i_idx: this.itemData.i_idx,
                m_idx: this.itemData.m_idx,
                type: type,
            }
            params.fees = this.setFees(this.itemData, price);
            this.$api.$item.buyItem(params).then(res => res.Data.Result).then(res => {
                let infoType = res.Info.type
                if (infoType === 2) {
                    this.marketPriceUpdate(this.itemData.i_idx, type, infoType);
                } else if (infoType !== 1) {
                    this.returnBuyErrorType(infoType)
                } else {
                    this.marketPriceUpdate(this.itemData.i_idx, type, infoType);
                }
            })
        },
        buyLicense(type, size, price) {
            EventBus.$emit('apiLoading', true);
            let params = {
                price: price,
                i_idx: this.itemData.i_idx,
                m_idx: this.itemData.m_idx,
                type: 'license',
                size: type,
            }
            if (this.itemData.im_type === 'photo') {
                params.m_license_horizontal = Number(size[0]);
                params.m_license_vertical = Number(size[1]);
            }
            params.fees = this.setFees(this.itemData, price);
            this.$api.$item.buyItem(params).then(res => res.Data.Result).then(res => {
                let infoType = res.Info.type
                if (infoType === 2) {
                    this.marketPriceUpdate(this.itemData.i_idx, 'license', infoType);
                } else if (infoType !== 1) {
                    this.returnBuyErrorType(infoType)
                } else {
                    this.marketPriceUpdate(this.itemData.i_idx, 'license', infoType);
                }
            })
        },
        marketPriceUpdate(key, type, infoType) {
            if (type === 'auction') {
                this.buyAfterFunc(type, infoType);
            } else {
                let params = {
                    i_idx: key
                }
                this.$api.$item.marketPriceUpdate(params).then(res => res.Data.Result).then(res => {
                    if (res.Info.type !== 1) {
                        this.createAlert({
                            content: this.$t('listErrorMsg1'),
                            btnText: this.$t('yes'),
                            hide: () => {
                                EventBus.$emit('apiLoading', false);
                                this.$router.back();
                            }
                        })
                    } else {
                        this.buyAfterFunc(type, infoType);
                    }
                })
            }
        },
        buyAfterFunc(type, key) {
            EventBus.$emit('apiLoading', false);
            let token = getStorageToken();
            this.$store.dispatch('userInfo/setUserInfo', {token});
            let alertContent = '';
            let url = '';
            console.log(type, key)
            if (type === 'auction') {
                if (key === 1) {
                    alertContent = 'buyItemMsg';
                    url = '/mypage/auction';
                } else {
                    alertContent = 'buyItemMsg1'
                    url = '/mypage/item';
                }
            } else if (type === 'license') {
                alertContent = 'buyItemMsg2'
                url = '/mypage/item'
            } else if (type === 'pricetag') {
                alertContent = 'buyItemMsg3'
                url = '/mypage/item'
            }
            this.createConfirm({
                content: this.$t(`${alertContent}`),
                confirmText: this.$t('check_now'),
                cancelText: this.$t('check_later'),
                confirm: () => {
                    this.$router.push(url);
                },
                cancel: () => {
                    this.$router.back();
                }
            })
        },
        returnBuyErrorType(type) {
            EventBus.$emit('apiLoading', false);
            if (type === -301) {
                this.errorAlert(this.$t('buyItemErrorMsg'))
            } else if (type === -302) {
                this.errorAlert(this.$t('buyItemErrorMsg9'))
            } else if (type === -303) {
                this.errorAlert(this.$t('buyItemErrorMsg1'))
            } else if (type === -304) {
                this.errorAlert(this.$t('buyItemErrorMsg2'))
            } else if (type === -801) {
                this.createConfirm({
                    content: this.$t(`buyItemErrorMsg4`),
                    confirmText: this.$t('check_now'),
                    cancelText: this.$t('check_later'),
                    confirm: () => {
                        this.$router.push(`/mypage/point/charge`);
                    },
                    cancel: () => {
                    }
                })
            } else if (type === -802) {
                this.errorAlert(this.$t('buyItemErrorMsg8'))
            } else if (type === -401) {
                this.errorAlert(this.$t('buyItemErrorMsg10'))
            } else {
                this.errorAlert(this.$t('buyItemErrorMsg3'))
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
