<template>
    <gallery-detail-link :detailLayout="detailLayout" :galleryData="galleryData"></gallery-detail-link>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import GalleryDetailLink from "@/components/gallery/GalleryDetailLink";
import galleryUtil from "@/components/gallery/galleryUtil";

export default {
    name: "GalleryDetailComponent",
    mixins: [galleryUtil, alertMixins],
    components: {
        GalleryDetailLink,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        galleryType: '',
        galleryKey: {default: 0},
    },
    data() {
        return{
            detailLayout: '',
            getFunc: 'getCollection',
            detailApiParamSet: {},
            getKeyName: 'id',
            isGetWriter: false,

            galleryData: {},
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initConfig();
        this.getGallery();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initConfig() {
            let config = this.returnGalleryConfig(this.galleryType);
            this.detailLayout = config.detailLayout;
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.getKeyName = config.getKeyName;
            this.isGetWriter = config.isGetWriter;
        },
        getGallery() {
            let params = Object.assign({}, this.detailApiParamSet);
            let getKeyName = this.getKeyName;
            if(util.isEmpty(getKeyName)) {
                getKeyName = 'id';
            }
            params[getKeyName] = this.galleryKey;
            try {
                this.$api.$gallery[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    if(this.isGetWriter) {

                        this.getWriter(res.List[0]);
                    }else{
                        this.setGalleryData(res.List[0]);
                    }
                })
            }catch (e) {
                console.log(e);
            }
        },
        getWriter(galleryData) {
            if(util.isEmpty(galleryData.mb_no)) {
                this.setGalleryData(galleryData);
                return false;
            }
            let params = {
                no: galleryData.mb_no
            };
            try {
                this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                    galleryData.Writer = {};
                    if(util.isEmpty(res.List)) {
                        this.setGalleryData(galleryData);
                    }else{
                        galleryData.Writer = res.List[0];
                        this.setGalleryData(galleryData)
                    }
                })
            }catch (e) {
                console.log(e);
            }
        },
        setGalleryData(galleryData) {
            this.galleryData = galleryData;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
