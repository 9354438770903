<template>
    <div class="mypage_list_wrap border-top">
        <div class="mypage_list " v-for="(competition, index) in competitionData"
             :key="`competition-my-item-${competition.c_idx}`">
            <div class="item_information">
                <div class="list_time" @click="movePage(`/item/${competition.Item.i_sku}`)">
                    {{ returnDateTime(competition.created_at) }}
                </div>
                <div class="list_download competition_download" v-if="competition.ci_status === 0 "
                     @click="cancelRequest(competition,$event)">
                    <div>{{ $t('entry') }}{{ $t('cancel') }}</div>
                </div>
            </div>
            <div class="item_img_wrap mt11">
                <div class="item_img">
                    <img :src="returnImage(competition.Item.ItemFile.Item,142,90)"
                         @click="movePage(`/item/${competition.Item.i_sku}`)">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold" @click="movePage(`/item/${competition.Item.i_sku}`)">[
                        {{ returnStatus(competition.ci_status) }} ·
                        {{ competition.c_title }}]
                    </div>
                    <div class="item_text" @click="movePage(`/item/${competition.Item.i_sku}`)">
                        {{ competition.Item.i_name }}
                    </div>
                    <div class="item_text" @click="movePage(`/item/${competition.Item.i_sku}`)">
                        {{ returnCommas(competition.ci_vote_cnt) }}({{
                            returnPercentage(competition.ci_vote_cnt, competition.totalVote)
                        }}%)
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "CompetitionListMyEntryLayout",
    mixins: [alertMixins, imageOption],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        competitionData: {
            default: () => {
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY-MM-DD HH:mm')
            return r_date;
        },
        returnStatus(status) {
            switch (status) {
                case 0 : {
                    return this.$t('in_process2')
                }
                case 1 : {
                    return this.$t('awards')
                }
                case 2 : {
                    return this.$t('award_fail')
                }
                case 3 : {
                    return this.$t('award_cancel')
                }
                default : {
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        cancelRequest(competition, e) {
            e.stopPropagation()
            let itemName = competition.Item.i_name
            let key = competition.ci_idx
            this.createConfirm({
                title: '',
                content: `<span class='subject'>${itemName}</span><br>${this.$t('my_cancel_confirm2')}<br> ${this.$t('my_cancel_confirm3')}`,
                confirmText: this.$t('yes'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('deleteCompetition', key)
                },
                cancel: () => {

                }
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
