<template>
    <div>
        <div class="">
            <item-list-component :item-type="'detection'"></item-list-component>
        </div>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import detectionValidator from "@/mixins/validators/detectionValidator";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "DetectionWriteDefaultLayout",
    mixins: [detectionValidator, alertMixins, AuthMixins],
    components: {ItemListComponent},
    inject: ['detectionWriteSetData'],
    provide() {
        return {
            setSelectItems: this.setSelectItems,
            checkSelectedItem: this.checkSelectedItem,
            checkAllItem: this.checkAllItem,
        }
    },
    props: {},
    data() {
        return {
            allItemCount: 0,
            definePoint: 1,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getPointDefine();
        this.getAllCount();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
    },
    methods: {
        getPointDefine() {
            let params = {
                co: 'DETECT'
            }
            try {
                this.$api.$point.getPointDefine(params).then(res => res.Data.Result).then(res => {
                    if(!util.isEmpty(res.List)) {
                        this.definePoint = res.List[0].pd_point;
                    }
                })
            }catch (e) {
                console.log(e)
            }
        },
        getAllCount() {
            let params = {ip: 1, page:1, sp: 1, obt: 'i.created_at'};
            this.$api.$item.getItemDetect(params).then(res => res.Data.Result).then(res => {
                if(util.isEmpty(res.List) || util.isEmpty(res.Page)) {
                    this.allItemCount = 0;
                }else{
                    this.allItemCount = res.Page.total;
                }
            })
        },
        setSelectItems(value) {
            this.selectedItems = value;
            this.detectionWriteSetData('selectedItems', this.selectedItems);
        },
        checkSelectedItem() {
            if(this.selectedItems.length === 0) {
                this.errorAlert(this.$t('detect_err1'))
            }else{
                this.openSelectedConfirm();
            }
        },
        openSelectedConfirm() {
            this.detectionWriteSetData('writeFunc', 'itemDetect');
            this.detectionWriteSetData('parsingData', {'selectedItems': 'i_idx'});
            let length = this.selectedItems.length;
            let count = util.Number.numFormat(length);
            let money = util.Number.numFormat(length * this.definePoint);
            let pointNum = length * this.definePoint
            this.detectionWriteSetData('detectPoint', pointNum);
            this.createConfirm({
                content: `${this.$t('detect_confirm1', {cnt: count})}<br>${this.$t('detect_confirm2', {money: money})}`,
                confirm: () => {
                    this.checkMyPoint(pointNum)
                }
            })
        },
        checkAllItem() {
            if(this.allItemCount > 0) {
                this.openAllConfirm();
            }else{
                this.errorAlert(this.$t('detect_err5'))
            }
        },
        openAllConfirm() {
            this.detectionWriteSetData('writeFunc', 'itemAllDetect');
            this.detectionWriteSetData('parsingData', {});
            let length = this.allItemCount;
            let count = util.Number.numFormat(length);
            let money = util.Number.numFormat(length * this.definePoint);
            let pointNum = length * this.definePoint
            this.detectionWriteSetData('detectPoint', pointNum);
            this.createConfirm({
                content: `${this.$t('detect_confirm1', {cnt: count})}<br>${this.$t('detect_confirm2', {money: money})}`,
                confirm: () => {
                    this.checkMyPoint(pointNum)
                }
            })
        },
        checkMyPoint(point) {
            let myPoint = this.getMyPoint();
            if(myPoint < point) {
                this.errorAlert(this.$t('detect_err6'))
                return false;
            }
            EventBus.$emit('writeDetection')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
