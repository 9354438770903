const likeConfig = {
    itemDetail: {
        likeLayout: 'LikeItemDetailLayout',
        writeFunc: 'itemLike',
        keyName: 'i_idx',
    },
    competitionDetail: {
        likeLayout: 'LikeCompetitionDetailLayout',
        writeFunc: 'competitionLike',
        keyName: 'c_idx',
    },
    memberDetail: {
        likeLayout: 'LikeMemberDetailLayout',
        writeFunc: 'toggleLike',
        keyName: 'mb_no',
    },

}
export default {
    methods: {
        returnLikeConfig(type) {
            return likeConfig[type];
        }
    },
}
