<template>
    <div >
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <curation-write-component :curationType="'curationWrite'"></curation-write-component>
    </div>
</template>

<script>
import AuthMixins from "@/mixins/AuthMixins";
import CurationWriteComponent from "@/components/curation/CurationWriteComponent";

export default {
    name: "CurationRequest",
    mixins: [AuthMixins],
    components: {
        CurationWriteComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{

    },
    watch: {

    },
}
</script>

<style scoped>

</style>
