<template>
    <modal name="collection-setting-modal" class="collection-setting-modal"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
           :pivotX="0.5"
           :pivotY="0.5"
           :adaptive="true"
           :scrollable="true"
           :width="'100%'"
           :height="'100%'"

    >
        <div>
            <page-header :header-title="$t('setting_collect')">
                <button slot="headerBtns" class="h_x_btn" @click="modalClose"></button>
            </page-header>
            <div>
                <collect-write-component :collect-type="collectType" :collectKey="collectKey"></collect-write-component>
            </div>
        </div>
    </modal>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import GalleryWriteComponent from "@/components/gallery/GalleryWriteComponent";
import util from "@/mixins/util";
import CollectWriteComponent from "@/components/collect/CollectWriteComponent";

export default {
    name: "CollectionSettingModal",
    mixins: [alertMixins],
    components: {
        CollectWriteComponent,
        GalleryWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            showComponent: false,
            collectKey: 0,
            collectType: 'collectAdd',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.collectKey)) {
                this.collectType = 'collectAdd';
            } else {
                this.collectKey = event.params.collectKey;
                this.collectType = 'collectUpdate';
            }
            this.showComponent = true;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.collectKey = 0;
        },
        modalClose() {
            this.$modal.hide('collection-setting-modal');
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
