import util from "./util";

let checkDays = [];
import momentTimezone from "moment-timezone";
import {mapState, mapGetters} from "vuex";
import 'moment/locale/ko'

export default {
    date() {
        return {}
    },
    computed: {},
    methods: {
        DayFastChatCheck(date) {
            /* 월과 일을 계산해서 해당 월 일중 최초의 날짜만 반환 */
            let day = this.$moment(date).format('YYYY.MM.DD');
            if (checkDays.indexOf(day) != -1) {
                return false;
            } else {
                checkDays.push(day);
                return true;
            }
        },
        ResetDayCheck() {
            checkDays = [];
        },
        returnTwoLine(value) {
            /* 한 자리수이면 앞에 0 붙여서 반환 */
            return ('' + value).length === 1 ? '0' + value : value;
        },
        getCheckDays() {
            return checkDays;
        },
        returnFullDate(date) {
            return this.$moment(date, 'YYYY/MM/DD/HH:mm:ss').format('YYYY.MM.DD');
        },
        returnTodayCheck(date) {
            const toDay = this.$moment();
            let vDate = this.$moment(date, 'YYYY/MM/DD/HH:mm:ss');
            return vDate.format('YYYY.MM.DD') === toDay.format('YYYY.MM.DD') ? vDate.format('HH:mm') : vDate.format('YY-MM-DD');
        },
        getDifferenceTime(endTime, currentTime) {
            let current = this.$moment(currentTime)
            let end = this.$moment(endTime)
            let diff = current.diff(end, 'day')
            if (diff > -1) {
                let s_diff = current.diff(end, 'second')
                diff =  s_diff > 0 ? 0 : Math.abs(s_diff)
            } else {
                return `D-${Math.abs(diff)}`
            }
            let hour = parseInt(diff / 3600, 10)
            let min = parseInt((diff % 3600) / 60, 10)
            let sec = parseInt((diff % 3600) % 60, 10)

            hour = hour < 10 ? `0${hour}` : hour
            min = min < 10 ? `0${min}` : min
            sec = sec < 10 ? `0${sec}` : sec
            return `${hour} : ${min} : ${sec}`
        },

        returnDateFormat(date, format = 'YYYY.MM.DD HH:mm') {
            return this.$moment(date).format(format)
        },
        returnDateUnix(date, format = 'YYYY-MM-DD HH:mm') {
            if (!date) {
                return '';
            }
            return this.$moment.unix(date).locale('ko').format(format)
        },
        returnDateTime(date = null) {
            if (!date) {
                return '';
            }
            return this.$moment(date).format('YYYY-MM-DD HH:mm')
        },
        returnDateTimezone(date, format = 'YYYY-MM-DD HH:mm') {
            let timezone = this.returnTimezone();
            if(util.isEmpty(timezone)) {
                timezone = 'Asia/Seoul'
            }
            return momentTimezone(date).tz(timezone).format(format);
        },
        returnUnixTimezone(date, format = 'YYYY-MM-DD HH:mm') {
            let timezone = this.returnTimezone();
            if(util.isEmpty(timezone)) {
                timezone = 'Asia/Seoul'
            }
            return momentTimezone.unix(date).tz(timezone).format(format);
        },
        returnDataStartRelativeTime(date, locale) {
            let timezone = this.returnTimezone();

            return momentTimezone.unix(date).tz(timezone).fromNow();
        },

        returnMinuteToHourFormat(date) {
            let time = parseInt(date / 60);
            let min = date % 60;
            if (time < 10 && min < 10) {
                return `0${time}:0${min}`
            } else if (time < 10) {
                return `0${time}:${min}`
            } else if (min < 10) {
                return `${time}:0${min}`
            } else {
                return `${time}:${min}`
            }
        },
        returnOneDayDate(data){
            let today = this.$moment();
            let diffDate = today.diff(data, 'seconds');
            if (diffDate < 60 && diffDate > 0) {
                return this.$t('t_second',{time : diffDate ? diffDate : 1 })
            }else if (diffDate < 3600){
                return this.$t('t_minute',{time : Math.floor(diffDate / 60) })
            } else if (diffDate < 86400){
                return this.$t('t_hour',{time : Math.floor(diffDate / 3600)})
            }else {
                return this.$moment(data).format('YYYY-MM-DD HH:mm')
            }
        },
        returnTimezone() {
            return null;
        },
        returnUTCTimezone(date,format='YYYY-MM-DD HH:mm:ss'){
            return this.$moment(date).utc().format(format)
        },
        returnDotDateTime(date = null) {
            if (!date) {
                return '';
            }
            return this.$moment(date).format('YYYY. MM. DD HH:mm')
        },
        getDifferenceDday(date) {
            if (util.isEmpty(date)) {
                return false
            }
            let current = this.$moment(date)
            let end = this.$moment()
            let diffDays = current.diff(end, 'days')
            let diffHours = parseInt(current.diff(end, 'hours') - diffDays * 24)
            let diffMinutes = parseInt((current.diff(end, 'minutes') - diffDays * 1440) % 60)
            let diffSeconds = parseInt((current.diff(end, 'seconds') - diffDays * 86400) % 60)

            if (diffDays == 0) {
                return `${diffHours}시간 ${diffMinutes}분 ${diffSeconds}초`;
            } else if (diffDays == 0 && diffHours == 0) {
                return `${diffMinutes}분 ${diffSeconds}초`;
            } else if (diffDays == 0 && diffHours == 0 && diffMinutes == 0) {
                reteurn `${diffSeconds}초`;
            } else {
                return `${diffDays}일 ${diffHours}시간 ${diffMinutes}분 ${diffSeconds}초`;
            }
        },
    }
}
