import {bucketOption} from "@/api/config";
import {fileuploadS3, s3ApiDelete} from "@/plugins/awsS3Plugin";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
export default {
    data() {
        return {
            successType: true,
            options: {},
            allFileUpload: false,
        }
    },
    mixins: [alertMixins],
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        runUploadFileInfo(fileInfo, index, key) {
            return new Promise(resolve => {
                this.runS3ApiUpload(fileInfo, index, key, resolve)
            })
        },
        runS3ApiUpload(fileInfo, index=0, key, resolve) {
            if (index < fileInfo.length) {
                let fileName = fileInfo[index].fileName
                let fileType = fileInfo[index].fileType
                let checkFile = !util.isEmpty(this[fileName]) && this[fileName].filter(file => file.hasOwnProperty('raw')).length > 0;

                if (checkFile) {
                    this.setS3FileUpload(this[fileName], fileType, key).then(res => {
                        if (!res) {
                            EventBus.$emit('apiLoading', false)
                            this.errorAlert('파일 등록에 실패했습니다.')
                            resolve(false)
                        } else {
                            this.runS3ApiUpload(fileInfo, index + 1, key, resolve)
                        }
                    });
                } else if (this.allFileUpload) {
                    this.setS3FileUpload(this[fileName], fileType, key).then(res => {
                        if (!res) {
                            EventBus.$emit('apiLoading', false)
                            this.errorAlert('파일 등록에 실패했습니다.')
                            resolve(false)
                        } else {
                            this.runS3ApiUpload(fileInfo, index + 1, key, resolve)
                        }
                    });
                } else {
                    this.runS3ApiUpload(fileInfo, index + 1, key, resolve)
                }
            } else {
                // EventBus.$emit('apiLoading', false)
                // this.afterSuccessFunc()
                resolve(true)
            }
        },
        afterSuccessFunc() {
            let _this = this
            this.createAlert({
                content: '저장 되었습니다.',
                hide() {
                    _this.$router.back()
                }
            })
        },

        setS3FileUpload(files, type, id) {
            let _this = this;
            this.options = {
                type: type,
                id: id,
                BucketName: bucketOption.BucketName,
                BucketRegion: bucketOption.BucketRegion,
                BucketIdentityPoolId: bucketOption.BucketIdentityPoolId,
            };

            return new Promise(function (resolve, reject) {
                if (files.length === 0) {
                    resolve(true);
                } else {
                    _this.runS3Upload(files, 0, resolve)
                }
            })
        },
        checkUploadFile(file) {

            return file != null && (file.hasOwnProperty('raw'))
            // return file != null && file.hasOwnProperty('org_name') && file.hasOwnProperty('fileSrc')
        },


        runS3Upload(files, index, resolve) {

            let _this = this;
            let file = files[index];

            if (this.checkUploadFile(file)) {
                let options = JSON.parse(JSON.stringify(this.options));
                //스트림이 존재하면 스트림을 삭제 한 뒤 비디오를 삭제합니다.
                if (files[index].hasOwnProperty('a_idx') && files[index].hasOwnProperty('stream_idx')) {
                    return s3ApiDelete(files[index].stream_idx).then(() => {
                        s3ApiDelete(files[index].a_idx).then(() => {
                            delete files[index].a_idx;
                            fileuploadS3(file, options).then(res => {
                                let type = res.Info.type
                                if (type != 1) {
                                    _this.successType = false;
                                }
                                // file.a_idx = res.List.a_idx
                                if (index + 1 === files.length) {
                                    resolve(_this.successType);
                                    _this.successType = true;
                                } else {
                                    _this.runS3Upload(files, index + 1, resolve);
                                }
                            })
                        })
                    })
                } else if (files[index].hasOwnProperty('a_idx')) {
                    return s3ApiDelete(files[index].a_idx).then(() => {
                        delete files[index].a_idx;
                        fileuploadS3(file, options).then(res => {
                            let type = res.Info.type
                            if (type != 1) {
                                _this.successType = false;
                            }
                            // file.a_idx = res.List.a_idx
                            if (index + 1 === files.length) {
                                resolve(_this.successType);
                                _this.successType = true;
                            } else {
                                _this.runS3Upload(files, index + 1, resolve);
                            }
                        })
                    })
                } else {
                    return fileuploadS3(file, options).then(res => {
                        let type = res.Info.type
                        if (type != 1) {
                            _this.successType = false;
                        }
                        // file.a_idx = res.List.a_idx
                        if (index + 1 === files.length) {
                            resolve(_this.successType);
                            _this.successType = true;
                        } else {
                            _this.runS3Upload(files, index + 1, resolve);
                        }
                    })
                }
            }  else {
                if (index + 1 === files.length) {
                    resolve(_this.successType);
                    this.successType = true;
                } else {
                    if (file.hasOwnProperty('a_idx') && this.allFileUpload) {
                        let params = {
                            a_idx: file.a_idx,
                            a_container_type: this.options.type,
                            a_container_id: this.options.id,
                        }
                        try {
                            this.$api.$file.copyFile(params).then(res => res.Data.Result).then(res => {
                                this.runS3Upload(files, index + 1, resolve);
                            });
                        } catch (e) {
                            console.log(e);
                        }
                    } else {
                        this.runS3Upload(files, index + 1, resolve);
                    }

                }
            }
        },
        runS3ApiDeleteFiles(deleteFiles, index = 0) {
            return new Promise(resolve => {

                /*util.Array.each(deleteFileIdx, (a_idx, index, arr) => {

                })*/

                //파일에서 a_idx만 추출하여 리스트화
                let deleteFileIdx = []
                deleteFiles.forEach(v => {
                    if (v.hasOwnProperty('a_idx') && !util.isEmpty(v.a_idx)) {
                        deleteFileIdx.push(v.a_idx)
                    }
                })
                this.runS3ApiDelete(deleteFileIdx, 0, resolve);
            })
        },
        runS3ApiDelete(deleteFileIdx, index = 0, resolve = null) {
            let _this = this
            //삭제하고자 하는 파일의 갯수만큼 반복
            let a_idx = deleteFileIdx[index]
            if (index < deleteFileIdx.length) {
                s3ApiDelete(a_idx).then(res => {
                    if (res.Info.type == 1) {
                        _this.runS3ApiDelete(deleteFileIdx, index + 1, resolve)
                    } else {
                        _this.errorAlert('파일 삭제에 실패하였습니다.')
                    }
                })
            } else if (resolve) {
                resolve(true);
                // _this.createAlert({content:'파일이 삭제되었습니다.'})
            }
        },

    },


}
