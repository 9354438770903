<template>
    <div class="qna_create">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="qna_create_wrap dgm_wrap">
            <board-write-component :boardType="`qnaUpdate`" :boardKey="boardKey"></board-write-component>
            <div class="save_btn one_btn" @click="update">{{ $t('save') }}</div>
        </div>
    </div>
</template>

<script>
import BoardWriteComponent from "@/components/board/BoardWriteComponent";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";

export default {
    name: "QnaUpdate",
    mixins: [alertMixins],
    components: {
        BoardWriteComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
    },
    data() {
        return{
            boardType: 'qnaUpdate',
            boardKey: this.$route.params.idx,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        backList() {
            this.createConfirm({
                content: this.$t('update_qna_back'),
                confirm: () => {
                    this.$router.back();
                }
            });
        },
        update() {
            this.createConfirm({
                content: this.$t('update_qna_confirm'),
                confirm: () => {
                    EventBus.$emit('writeBoard');
                }
            });
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
