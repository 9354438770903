<template>
    <div>
<!--        <page-header :header-title=" $t('c_search') " :header_side="true" :header_back="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header" :header-title=" $t('c_search') "></page-header>
        <member-list-component :memberType="'searchCreatorDetail'"></member-list-component>
    </div>
</template>

<script>
import MemberListComponent from "@/components/member/MemberListComponent";

export default {
    name: "SearchResultCreator",
    mixins: [],
    components: {
        MemberListComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
