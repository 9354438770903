import { render, staticRenderFns } from "./BoardDetailFaqLayout.vue?vue&type=template&id=f4fc0b6e&scoped=true"
import script from "./BoardDetailFaqLayout.vue?vue&type=script&lang=js"
export * from "./BoardDetailFaqLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f4fc0b6e",
  null
  
)

export default component.exports