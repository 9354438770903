<template>
    <div class="detect_detail_wrap">
        <div class="detect_detail_history_wrap">
            <div class="detect_detail_wrap_top">
                <figure class="detect_detail_img_box detect_history_img">
                    <img :src="returnItemFile()" alt="detect_img">
                    <figcaption>{{ $t('myImg') }}</figcaption>
                </figure>
                <figure class="detect_detail_img_box detect_history_img">
                    <img :src="returnDetectFile()" alt="detect_img">
                    <figcaption>{{ $t('detection_img') }}</figcaption>
                </figure>
            </div>
            <div>
                <div class="detection_filter_result_wrap">
                    <div class="detection_filter_result_list">
                        <ul class="detection_detail_list_wrap">
                            <li>
                                <p class="detection_detail_tit">{{ $t('detection_domain1') }}</p>
                                <p class="detection_detail_content">{{ returnDetectResult().dr_domain }}</p>
                            </li>
                            <li>
                                <p class="detection_detail_tit">{{ $t('detection_page_title') }}</p>
                                <p class="detection_detail_content detection-ellipsis">{{ returnDetectResult().dr_title }}</p>
                            </li>
                            <li>
                                <p class="detection_detail_tit ">{{ $t('d_page_url').toUpperCase() }}</p>
                                <p class="detection_detail_content detection-ellipsis">{{ returnDetectResult().dr_page }}</p>
                            </li>
                            <li>
                                <p class="detection_detail_tit">{{ $t('d_img_url').toUpperCase() }}</p>
                                <p class="detection_detail_content detection-ellipsis">{{ returnDetectResult().dr_image }}</p>
                            </li>
                            <li class="detection_select_wrap">
                                <p class="detection_detail_tit">상태</p>
                                <div class="detection_detail_content detection_detail_content_select">
                                    <div class="search_item mb19 detection_search_item">
                                        <div class="filter_category_box mr5 detection_select">
                                            <el-select id="category" class="my_filter select detection_select_select"
                                                       @change="changeStatus"
                                                       v-model="returnDetectResult().dr_status"
                                                       popper-class="select_style" value="상태" >
                                                <el-option class="detection_select_label" v-for="(status) in detectionDomainStatus" :key="`setStatus${status.value}`"
                                                           v-if="!status.noChange" :value="status.value" :label="`${$t(status.name)}`"></el-option>
                                                <el-option :value="'request'" :label="`${$t('request1')}`"></el-option>
                                            </el-select>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <p class="detection_detail_tit">{{ $t('detection_date') }}</p>
                                <p class="detection_detail_content">{{ returnDateFormat(returnDetectResult().created_at, 'YYYY.MM.DD') }}</p>
                            </li>
                        </ul>
                        <div class="btn-domain-wrap btn-domain-list-wrap">
                            <button class="btn-domain"
                                    v-if="returnDetectResult().dr_except != 1"
                                    :disabled="returnDetectResult().dr_except === 1"
                                    @click="ignoreDomain()">{{ $t('except_domain') }}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {detectionDomainStatus} from "@/service/detectionService";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionDetailHistoryLayout",
    mixins: [imageOption, dateMixins],
    components: {
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        detectionData: {default: () => {},}
    },
    data() {
        return{
            detectionDomainStatus: detectionDomainStatus,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        hasItemData() {
            return !util.isEmpty(this.detectionData) && !util.isEmpty(this.detectionData.Item)
        },
        returnItemData() {
            if(!this.hasItemData()) {
                return {}
            }
            return this.detectionData.Item[0];
        },
        returnDetectResult() {
            if(util.isEmpty(this.detectionData) || util.isEmpty(this.detectionData.Result)) {
                return {}
            }
            return this.detectionData.Result[0];
        },
        returnItemFile() {
            let item = this.returnItemData();
            if(util.isEmpty(item.ItemFile) || util.isEmpty(item.ItemFile.Item)) {
                return '';
            }
            return `${item.ItemFile.Item[0].org_url}?${this.setImageOptions(654, 422, 'png')}`;
        },
        returnDetectFile() {
            let resultData = this.returnDetectResult()
            if(util.isEmpty(resultData.dr_image)) {
                return '';
            }
            return `${resultData.dr_image}?${this.setImageOptions(654, 422, 'png')}`;
        },
        changeStatus() {
            let resultData = this.returnDetectResult();
            if (resultData.dr_status === 'request') {
                this.$router.push(`/detection/${resultData.dr_idx}/request`);
                return false;
            }
            EventBus.$emit('detectDetailChangeStatus', resultData);
        },
        ignoreDomain() {
            let resultData = this.returnDetectResult();
            EventBus.$emit('detectDetailIgnoreDomain', resultData)
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
