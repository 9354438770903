<template>
    <div class="creator_detail_tab_wrap">
        <div class="creator_detail_tab_scroll">
            <div class="creator_detail_tab" :class="{ active : nowTab === 'collection'}" @click="changeTab('collection')">
                <span class="tab_text">{{ $t('gallery') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'premium'}" @click="changeTab('premium')"  v-if="UserInfo.g_idx === 2">
                <span class="tab_text">{{ $t('premiumMarket') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'collect'}" @click="changeTab('collect')" >
                <span class="tab_text">{{ $t('collection') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'market'}" @click="changeTab('market')" v-if="UserInfo.g_idx != 2">
                <span class="tab_text">{{ $t('collection2') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'competition'}" @click="changeTab('competition')" v-if="UserInfo.g_idx != 2">
                <span class="tab_text">{{ $t('competition') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'creation'}" @click="changeTab('creation')" v-if="UserInfo.g_idx != 2">
                <span class="tab_text">{{ $t('creationItem') }}</span>
            </div>
            <div class="creator_detail_tab" :class="{ active : nowTab === 'portfolio'}" @click="changeTab('portfolio')">
                <span class="tab_text">{{ $t('introduction') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    name: "MyProfileTabs",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            nowTab: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.settingFirstTab();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        settingFirstTab() {
            let url = this.$route.path;
            if (url === `/myprofile`) {
                this.nowTab = 'collection';
            } else if (url === `/myprofile/premium`) {
                this.nowTab = 'premium';
            } else if (url === `/myprofile/market`) {
                this.nowTab = 'market';
            } else if (url === `/myprofile/competition`) {
                this.nowTab = 'competition';
            } else if (url === `/myprofile/creation`) {
                this.nowTab = 'creation';
            } else if (url === `/myprofile/portfolio`) {
                this.nowTab = 'portfolio';
            }else if(url === `/myprofile/collect`) {
                this.nowTab = 'collect';
            } else {
                this.nowTab = 'collection';
            }
        },
        changeTab(type) {
            if (this.nowTab === type) {
                return false;
            }
            this.nowTab = type;
            if (type === 'collection') {
                this.$router.replace('/myprofile');
                return false;
            }
            this.$router.replace('/myprofile/' + type);
        },
    },
    watch: {},
}
</script>
