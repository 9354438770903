import {mapState} from "vuex";
import util from "@/mixins/util";
import {getTokenObj, loginTokenSet} from "@/mixins/Auth";
import EventBus from "@/utils/event-bus";

export default {
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        isLogin() {
            return !util.isEmpty(this.UserInfo) && !util.isEmpty(this.UserInfo.mb_no);
        },
        loginSns(result, type='naver') {
            EventBus.$emit('apiLoading', false);
            if (result.token) {
                loginTokenSet(result.token);
            }
            this.$localStorage.set('snsResult', '');
                if (result.mb_is_verify != 1) {
                    let tokenObj = getTokenObj();
                    this.$store.dispatch('userInfo/setUserInfo', {token: result.token,});

                    /*if (type != 'google' || type != 'facebook') {
                        window.close();
                    }*/
                    if (window.hasOwnProperty('opener') && !util.isEmpty(window.opener)) {
                        window.opener.location.href = "/auth/register/sns";
                        setTimeout(() => {
                            window.close();
                        }, 500)
                       /* if(type != 'naver') {
                            window.close();
                        }*/

                    }else{
                        this.$router.push(`/auth/register/sns`);
                    }
                } else {
                    /*if(type != 'google' && type != 'facebook') {
                        window.close();
                    }*/
                    if(window.hasOwnProperty('opener') && !util.isEmpty(window.opener)) {
                        window.opener.location.href = "/";
                        window.close();
                    }
                    this.$router.push(`/`);
                }

        },
        getMyPoint() {
            if (util.isEmpty(this.UserInfo) || util.isEmpty(this.UserInfo.point) || isNaN(this.UserInfo.point)) {
                return 0
            }
            return Number(this.UserInfo.point);
        },
        getMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.g_idx === 2) {
                return 'curator';
            }
            return 'normal';
        },
        getIsPremium() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_premium === 1) {
                return 'premium';
            }
            return false;
        },
        returnSocialType() {
            if (!util.isEmpty(this.UserInfo)) {
                if (util.isEmpty(this.UserInfo.Social)) {
                    return 'email';
                } else {
                    // return this.UserInfo.Social.social_type;
                    return this.UserInfo.Social.social_type;
                }
            }


        },
        langCheckLine(value) {
            switch (value) {
                case 'ko':
                    return 1;
                case 'en':
                    return 30;
                default:
                    return '';
            }
        },
        isAuthenticated() {
            return !util.isEmpty(this.UserInfo.mb_hp) && !util.isEmpty(this.UserInfo.mb_name)
        },
    },
}
