<template>
    <div v-if="this.utilityData.hasOwnProperty('u_idx')">
        <div class="container-utility-detail">
            <div class="thumbnail-utility-detail" :style="`background-image : url('${returnImg(this.utilityData.UtilityImg[0])}')`"></div>
            <div class="header-utility">
                <div class="icon-collection-utiltity" :style="`background-image : url('${returnImg(this.utilityData.collectionImg[0])}')`"></div>
                <div class="title-utility-detail">{{ this.utilityData.c_title }}</div>
            </div>
            <div class="box-user-utility">
                <div class="icon-director-utility"
                     :style="`background-image : url('${returnImg(this.utilityData.directorProfile[0])}')`"></div>
                <div class="name-director-utility">{{ this.utilityData.director_nick }}</div>
            </div>
            <div class="title-product-nticket">{{ this.utilityData.child_uc_name }}</div>
            <div class="content-product-nticket">{{ this.utilityData.u_name }}</div>
            <div class="caution-utility-detail">
                {{ this.utilityData.u_detail }}
            </div>
            <div class="wrap-date-utility">
                <div class="box-date-utility" v-if="!showSellCountdown(2)">
                    <div class="title-date-utility">{{$t('sell_time')}}</div>
                    <div class="period-date-utility">
                        {{ returnDateFormat(this.utilityData.u_sell_start_date,"YYYY-MM-DD HH:MM") }}<br>
                        ~{{ returnDateFormat(this.utilityData.u_sell_end_date,"YYYY-MM-DD HH:MM") }}
                    </div>
                </div>
                <div class="box-date-utility">
                    <div class="title-date-utility">{{$t('using_time')}}</div>
                    <div class="icon-division-utuility" v-if="showSellCountdown(2)"
                         :class="returnState(this.utilityData.u_is_use, this.utilityData.u_use_start_date, this.utilityData.u_use_end_date)">
                        {{
                            $t(returnStateText(this.utilityData.u_is_use, this.utilityData.u_use_start_date, this.utilityData.u_use_end_date))
                        }}
                    </div>
                    <div class="period-date-utility">
                        {{ returnDateFormat(this.utilityData.u_use_start_date,"YYYY-MM-DD HH:MM") }}<br>
                        ~{{ returnDateFormat(this.utilityData.u_use_end_date,"YYYY-MM-DD HH:MM") }}
                    </div>
                </div>
                <div class="box-date-utility">
                    <div class="title-date-utility">{{$t('using_cnt')}}</div>
                    <div class="period-date-utility">
                    <span class="use">{{ this.utilityData.u_use_total }} / </span>
                        <span>{{ this.utilityData.u_use_total }}</span></div>
                </div>
                <div class="box-date-utility" v-if="returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === 2">
                    <div class="title-date-utility">{{$t('total_cnt')}}</div>
                    <div class="period-date-utility"><span>{{ returnTotal(this.utilityData.u_publish_total) }}</span></div>
                </div>
            </div>
            <div class="box-cost-common mb12" v-if="!showSellCountdown(2)">
                <div class="info-cost-common">{{$t('n_buy')}}</div>
                <div>
                    <div>
                        <div class="icon-common"></div>
                        <div class="text-cost-common">{{ this.utilityData.u_price }} Mg</div>
                    </div>
                    <div class="won-cost-common">{{returnMingle(this.utilityData.u_price)}}
                    </div>
                </div>
            </div>
            <div class="box-date-utility" v-if="showSellPercentage()">
                <div class="title-date-utility">{{$t('total_count')}}</div>
                <div class="period-date-utility"><span>{{ this.utilityData.selled_cnt }}</span> /
                    {{ this.utilityData.u_publish_total }}
                </div>
            </div>
            <div class="box-percent-utility" v-if="showSellPercentage()">
                <div class="filled-percent-utility" :style="`width: ${returnPercent(this.utilityData.selled_cnt?this.utilityData.selled_cnt:0  , this.utilityData.u_publish_total)}%`"></div>
            </div>
            <div class="box-countdown-utility" v-if="(showSellCountdown(0) || showSellCountdown(1))">
                <div class="title-countdown-utility" v-html="$t(returnTimeText())"></div>
                <div class="counts-countdown-utility"
                     id="countdown">{{ this.countdown }}
                </div>
                <div class="date-countdown-utility">
                    <div class="pr32">{{ $t(returnSellText()) }}</div>
                    <div>{{ returnDotDateTime(returnTime()) }}</div>
                </div>
            </div>
        </div>
        <div class="footer-utility">
            <!-- <div class="btn-common disabled">판매대기</div> -->
            <button class="btn-common" @click="moveApp()">{{$t('move_mingle_pass')}}</button>
        </div>
    </div>
</template>


<script>
import alertMixin from "@/mixins/alertMixins";
import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import priceMixins from "@/mixins/priceMixins";
export default {
    name: "UtilityDetailLayout",
    mixins: [alertMixin, dateMixins, priceMixins],
    components: { },
    props: {
        utilityData: {},
    },
    data() {
        return {
            countdown: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        if (!util.isEmpty(this.utilityData) && (this.showSellCountdown(0) || this.showSellCountdown(1))) {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) == 0) {
                this.intervalId = setInterval(() => {
                    this.countdown = this.getDifferenceDday(this.utilityData.u_sell_start_date)
                }, 1000);
            } else if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) == 1) {
                this.intervalId = setInterval(() => {
                    this.countdown = this.getDifferenceDday(this.utilityData.u_sell_end_date)
                }, 1000);
            }
        }
    },
    beforeDestroy() {
        clearInterval(this.intervalId);
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },

        returnState(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state == 1) {
                if (start > now) {
                    return 'waiting'; // 사용대기
                } else if (start < now && end > now) {
                    return 'possible'; // 사용가능
                } else if (end < now) {
                    return 'already'; //기한만료
                }
            } else {
                return 'used'; // 사용완료
            }
        },
        returnStateText(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state == 1) {
                if (start > now) {
                    return 'use_await'; // 사용대기
                } else if (start < now && end > now) {
                    return 'use_possible'; // 사용가능
                } else if (end < now) {
                    return 'use_end_already'; //기한만료
                }
            } else {
                return 'use_fin'; // 사용완료
            }
        },
        returnTotal(value) {
            if (value === 0) {
                return 0;
            } else {
                return value;
            }
        },
        returnSellState(start, end) {
            let now = this.returnDateTime(new Date());

            if (start > now) {
                return 0; //판매대기
            } else if (start < now && end > now) {
                return 1; //판매중
            } else if (end < now) {
                return 2; //판매종료
            }
        },
        returnPercent(value, max) {
            if (!util.isEmpty(value) && !util.isEmpty(max)) {
                return (value / max) * 100;
            }
        },
        returnTimeText() {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === 0) {
                return 'sell_left_times'
            } else if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === 1) {
                return 'sell_off_left_times'
            }
        },
        returnTime() {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === 0) {
                return this.utilityData.u_sell_start_date
            } else if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === 1) {
                return this.utilityData.u_sell_end_date
            }
        },
        returnSellText() {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) == 0) {
                return 'sell_start_time'
            } else if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) == 1) {
                return 'sell_off_start_time'
            }
        },
        showSellCountdown(value) {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) === value) {
                return true
            } else {
                return false;
            }
        },
        showSellPercentage() {
            if (this.returnSellState(this.utilityData.u_sell_start_date, this.utilityData.u_sell_end_date) == 2) {
                return false
            }
            return true;
        },
        moveApp() {
            location.href="https://minglepass.page.link/6BG2";
        }
    },
    watch: {
    },
}
</script>
