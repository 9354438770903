<template>
    <div class="om_list_wrap">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header" :header-title="$t('openMarket')" :header_side="true" :header_main_back="true" ></page-header>
        <div class="notice_tabs padding_side ">

            <button class="notice_item_3_tab border_r_l " :class="{ active : tab === '0'}" @click="changeTab('0')">
                {{ $t('all') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '2'}" @click="changeTab(`${2}`)">
                {{ $t('art') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '178'}" @click="changeTab(`${178}`)">
                {{ $t('prompt') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '9'}" @click="changeTab(`${9}`)">
                {{ $t('collections') }}
            </button>
            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '3'}" @click="changeTab(`${3}`)">
                {{ $t('picture') }}
            </button>
            <button class="notice_item_3_tab" :class="{ active : tab === '5'}" @click="changeTab(`${5}`)">
                {{ $t('music') }}
            </button>
<!--            <button class="notice_item_3_tab border_r_l" :class="{ active : tab === '4'}" @click="changeTab(`${4}`)">-->
<!--                {{ $t('video') }}-->
<!--            </button>-->
        </div>
        <div class="om_best_item_text">
            BEST NFT ITEMS
        </div>
        <div v-if="tab === '0'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '2'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '3'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '4'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '5'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '9'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <div v-if="tab === '178'">
            <div class="pl30 pr30">
                <item-list-market-top-component :item-type="'openMarketTopBanner'"
                                                :category-key="tab"></item-list-market-top-component>
            </div>
            <item-list-component :item-type="'openMarket'" :category-key="tab"></item-list-component>
        </div>
        <popup-modal></popup-modal>
        <transition :name="`detail-${$root.$children[0].transitionName}`">
            <router-view></router-view>
        </transition>
    </div>
</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import ItemListMarketTopComponent from "@/components/item/itemListMarketTopComponent";
import PopupModal from "@/components/modal/PopupModal";

export default {
    name: "OpenMarket",
    mixins: [],
    components: {
        PopupModal,
        ItemListMarketTopComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('0');
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        // ...mapState({
        //     itemCategoryList: state => state.itemCategory.itemCategoryList,
        // })
    },
    methods: {
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnCategoryName(data) {

            if (data.node_id === 2) {
                return this.$t('art');
            } else if (data.node_id === 3) {
                return this.$t('picture');
            } else if (data.node_id === 4) {
                return this.$t('video');
            } else if (data.node_id === 5) {
                return this.$t('music');
            } else if (data.node_id === 9) {
                return this.$t('collections');
            } else if (data.node_id === 178) {
                return this.$t('prompt')
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
