<template>
    <div class="item_detail_wrap">

        <div class="item_detail_top">
            <item-detail-main-slide-layout :item-data="itemData"></item-detail-main-slide-layout>
        </div>

        <div class="item_detail_middle">
            <div class="detail_middle_header">

                <div class="thumbnail_wrap">
                    <ul @click="openProfileModal('popup-modal',itemData)">
                        <li><img :src="returnItemProfile(itemData,'originator')" alt=""></li>
                        <li><img :src="returnItemProfile(itemData,'provider')" alt=""></li>
                        <li><img :src="returnItemProfile(itemData,'curator')" v-if="checkCurator(itemData)" alt=""></li>
                    </ul>
                    <div class="time_wrap" v-if="itemData.i_status === 'auction'">
                        <div class="time_remain">{{ returnItemType(itemData) }}</div>
                    </div>
                </div>

                <div class="subject_wrap">
                    <h3>{{ returnCategoryName(itemData) }}</h3>
                    <p>{{ itemData.i_name }}</p>
                    <span v-if="itemData.im_edition === 1">{{ $t('edition') }} {{
                            itemData.im_count
                        }} / {{ itemData.im_total }}</span>
                </div>

                <div class="hashtag_wrap">
                    <ul>
                        <li v-for="(hash,index) in itemData.Hash" :key="`hash${index}`">
                            <span v-if="hash.ih_type === 1">#{{ hash.ic_name }}</span>
                            <span v-else>#{{ hash.ih_hashtag }}</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="detail_middle_content">
                <div class="license_box">
                    <div class="content_wrap1">
                        <div class="regular license_text">{{ typeName }}</div>
                        <div class="amount_wrap">
                            <div class="mg_icon"></div>
                            <div class="amount"> {{ returnCommas(price) }}</div>
                            <div class="mg">Mg</div>
                            <div class="item_small">({{ returnMingle(price) }})</div>
                        </div>
                        <div class="detail_wrap" v-if="itemData.im_type==='photo'">
                            <div>{{ Math.floor(size[0]) }}*{{ Math.floor(size[1]) }} px
                            </div>
                        </div>
                    </div>
                    <div class="content_wrap2">
                        <div class="regular license_text">{{ $t('f_amount')  }}</div>
                        <div class="amount_wrap">
                            <div class="mg_icon"></div>
                            <div class="amount"> {{ returnCommas(price) }}</div>
                            <div class="mg">Mg</div>
                            <div class="item_small">({{ returnMingle(price) }})</div>
                        </div>
                    </div>
                </div>

            </div>

            <div class="check_wrap mb20 mt30">
                <el-checkbox v-model="termsCheck" @click="changeTerms">
                                            <span class="item_text_span bold"
                                                  @click="openModal('terms-modal', 'itembuy')">{{
                                                    $t('buy_item_terms')
                                                }}</span>
                    <span v-html="$t('agree_terms2')"></span>
                </el-checkbox>

            </div>

            <div class="detail_middle_footer"  v-if="checkMemberType()">
                <div class="one_btn bid_btn" @click="checkValidator">{{ $t('buy') }}</div>
            </div>
            <div class="detail_middle_footer"  v-else>
                <div class="one_btn history_btn" @click="backEvent">{{ $t('buy') }}</div>
            </div>
        </div>
    </div>
</template>


<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import imageResizeMixins from "@/mixins/imageResizeMixins";
import AuthMixins from "@/mixins/AuthMixins";
import priceMixins from "@/mixins/priceMixins";
import ItemDetailMainSlideLayout from "@/template/item/detail/ItemDetailMainSlideLayout";

export default {
    name: "ItemDetailLicenseLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins, AuthMixins, priceMixins],
    components: {
        ItemDetailMainSlideLayout,
        Swiper,
        SwiperSlide,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
            },
            memberData: {},
            price: '',
            fees: '',
            termsCheck: false,
            termsMsgType: false,
            licenseType: '',
            type: '',
            typeName: '',
            size: [],


        }
    },
    beforeRouterEnter() {

    },
    created() {
        this.licenseType = this.$route.params.type;
        this.initItemData();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            if (!util.isEmpty(this.itemData)) {
                if(!util.isEmpty(this.itemData.imo_size)){
                    let afterSize = this.itemData.imo_size.split('*');
                    this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
                }
                this.setLicenseData();
            }
        },
        setLicenseData() {
            if (this.itemData.im_type === 'photo') {
                if (this.licenseType === 'small') {
                    this.type = 'Small';

                    this.size[0] = this.smallSize[0];
                    this.size[1] = this.smallSize[1];
                    this.price = this.itemData.il_small_price;
                } else if (this.licenseType === 'medium') {
                    this.type = 'Medium';
                    this.size[0] = this.mediumSize[0];
                    this.size[1] = this.mediumSize[1];
                    this.price = this.itemData.il_medium_price
                } else if (this.licenseType === 'large') {
                    this.type = 'Large';
                    this.size[0] = this.largeSize[0];
                    this.size[1] = this.largeSize[1];
                    this.price = this.itemData.il_large_price
                } else if (this.licenseType === 'original') {
                    let afterSize = this.itemData.imo_size.split('*');
                    this.type = 'Original';
                    this.size[0] = afterSize[0];
                    this.size[1] = afterSize[1];
                    this.price = this.itemData.il_original_price
                }
                this.typeName = this.type;
            } else {
                this.typeName = this.$t('license_sell_amount');
                this.type = 'Original';
                this.price = this.itemData.il_original_price
            }
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },
        openProfileModal(name, data) {
            this.$modal.show(`${name}`, {itemData: data});
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, this.returnExtension(data.profile[type][0].org_url))}`;
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },

        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },

        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if(data.ic_idx === 178){
                return this.$t('prompt')
            }
        },

        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, this.returnExtension(this.memberData.Files.Profile[0].org_url)
                )}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        checkMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        checkValidator() {
            if (!this.termsCheck) {
                this.termsMsgType = true;
                return;
            } else {
                this.termsMsgType = false;
            }
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                this.buyLicense(this.type, this.size, this.price)
            }
        },
        buyLicense(type, size, price) {
            EventBus.$emit('buyLicense', type, size, price);
        },
        setData(key, value) {
            this[key] = value;
        },
        backEvent() {
            this.$router.back();
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },

    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>
