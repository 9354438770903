<template>
        <div class="sns_login_wrap apple" @click="loginApple()"></div>
</template>

<script>
import AuthMixins from "@/mixins/AuthMixins";
import {isDev} from "@/api/config";

export default {
    name: "AppleLoginComponent",
    mixins: [AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {
        async loginApple() {
            try {
                let correctURI = 'https://testmobile.minglemint.com/auth/register';
                if (isDev === false) {
                    correctURI = 'https://m.minglemint.com/auth/register';
                }
                let client_id = "com.bigstar.minglepass.login";
                let redirectURI = encodeURI(`${correctURI}`);
                let state = Math.random().toString(36);
                let scope = "email";

                let params = {
                    clientId: client_id,
                    scope: scope,
                    redirectURI: redirectURI,
                    state: state,
                    usePopup: true,
                };
                await window.AppleID.auth.init(params);
                let loginData = await window.AppleID.auth.signIn();
                if (!('error' in loginData)) {
                    await this.afterAppleLogin(loginData)
                }
            } catch (e) {
                console.log(e);
            }
        },

        async afterAppleLogin(data) {
            let params = {
                code: data.authorization.code,
                id_token: data.authorization.id_token,
                user:data.user
            }

            this.$api.$auth.appleLogin(params).then(res => res.Data.Result).then(res => {
                    let type = this.loginReturnType(res.Info.type);
                    if (type === true) {
                        this.loginSns(res.List, 'apple')
                    } else {
                        this.errorAlert(type)
                    }
                }
            )
        },


        loginReturnType(type) {
            switch (type) {
                case 1:
                    return true;
                case -3:
                    return this.$t('login_error_Msg');
                default:
                    return this.$t('error_msg2');
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
