<template>
    <div>
        <div class="creator_detail_top_wrap">
            <div class="member_background" :style="`background-image: url(${returnBackground()})`"></div>
            <div class="profile_wrap">

                <div class="profile_img_box" @click="openModal(`profile-setting-modal`)">
                    <img :src="returnProfile()">
                </div>
                <div class="profile_right_wrap">

                    <div class="p_r_middle_content">
                        <div class="heart_box">
                            <img src="@/assets/image/s_heart.png">
                            <span>{{ numFormat(memberData.likeCnt) }}</span>
                        </div>

                        <div class="item_box">
                            <div class="item_text">{{ $t('en_item') }}</div>
                            <span>{{ numFormat(memberData.itemCnt) }}</span>
                        </div>
                    </div>


                    <div class="share_box" @click="openShareModal">
                        <img src="@/assets/image/share.png">
                    </div>
                </div>
            </div>

            <div class="nickname_wrap">
                <div class="nickname_text">{{ memberData.mb_nick }}</div>
                <div class="card_wrap">
                    <div class="curator_card mt3" v-if="memberData.g_idx === 2">{{ $t('en_curator') }}</div>
                    <div class="special_card mt3" v-if="memberData.mb_premium === 1">{{ $t('en_special') }}</div>
                </div>
            </div>

            <div class="curation_btn_wrap">
                <!--                <div class="c_history" @click="openModal('curation-history-modal')">{{$t('curation_history')}}</div>-->
                <button class="c_history" v-if="returnCreatorType() === 'curator'"
                        @click="openModal('curation-history-modal', memberData.mb_no)">
                    {{ $t('curation_history') }}
                </button>
                <button class="c_request" v-else-if="returnCreatorType() === 'apply'"
                        @click="movePage('/curationapply')">
                    {{ $t('curator_ready') }}
                </button>
                <button class="c_request" v-else-if="curatorStatus" @click="movePage('/curationapply')">
                    {{ $t('curator_apply') }}
                </button>
            </div>

            <div class="url_wrap">
                <a :href="returnUrl(memberData.mb_sns_1)" target="_blank"
                   v-if="!isEmptyData(memberData.mb_sns_1)">{{ returnUrl(memberData.mb_sns_1) }}</a>
                <a :href="returnUrl(memberData.mb_sns_2)" target="_blank"
                   v-if="!isEmptyData(memberData.mb_sns_2)">{{ returnUrl(memberData.mb_sns_2) }}</a>
                <a :href="returnUrl(memberData.mb_sns_3)" target="_blank"
                   v-if="!isEmptyData(memberData.mb_sns_3)">{{ returnUrl(memberData.mb_sns_3) }}</a>
            </div>

            <div class="intro_wrap">
                <input type="checkbox" id="expanded" v-model="onceMore">
                <p v-html="replaceBr(memberData.mb_introduce)"></p>
            </div>

        </div>
        <!--        <member-detail-default-layout></member-detail-default-layout>-->
        <profile-setting-modal></profile-setting-modal>
        <curation-history-modal :memberKey="UserInfo.mb_no"></curation-history-modal>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import LikeComponent from "@/components/like/LikeComponent";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import GalleryDetailBannerLayout from "@/template/gallery/detail/GalleryDetailBannerLayout";
import ProfileSettingModal from "@/components/modal/ProfileSettingModal";
import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import MemberDetailDefaultLayout from "@/template/member/detail/MemberDetailDefaultLayout";

export default {
    name: "MemberDetailMyLayout",
    mixins: [imageOption, alertMixins],
    components: {
        MemberDetailDefaultLayout,
        CurationHistoryModal,
        LikeComponent,
        ProfileSettingModal
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            onceMore: false,
            curatorStatus: false,
        }
    },
    metaInfo() {
        let url = window.location.href;
        let imageUrl = this.memberData.Files.Profile[0].org_url;
        let shareMsg = this.$t('share_msg')
        return {
            meta: [
                // Twitter Card
                {name: 'twitter:card', content: shareMsg},
                {name: 'twitter:title', content: `${this.memberData.mb_nick}| 밍글민트(Minglemint NFT Marketplace)`},
                {name: 'twitter:description', content: shareMsg},
                // image must be an absolute path
                {name: 'twitter:image', content: imageUrl},
                // Facebook OpenGraph
                {
                    vmid: 'og:title',
                    name: 'og:title',
                    property: 'og:title',
                    content: `${this.memberData.mb_nick}| 밍글민트(Minglemint NFT Marketplace)`
                },
                {vmid: 'og:type', name: 'og:type', property: 'og:type', content: 'website'},
                {vmid: 'og:image', name: 'og:image', property: 'og:image', content: imageUrl},
                {vmid: 'og:description', name: 'og:description', property: 'og:description', content: shareMsg}
            ]
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.setMoreContent();
        this.checkRequestCurator();
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },

        openModal(name) {
            this.$modal.show(name);
        },
        numFormat(num) {
            if (util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnCreatorType() {
            if (this.memberData.g_idx === 2) {
                return 'curator'
            } else if (!this.isEmptyData(this.memberData.c_idx)) {
                return 'apply'
            }
            return 'normal'
        },
        setCuratorBtnText() {
            if (this.memberData.g_idx === 2) {
                return 'curator'
                return this.$t('curation_history')
            } else if (!this.isEmptyData(this.memberData.c_idx)) {
                return 'apply'
                return this.$t('curator_ready')
            }
            return 'none'
            return this.$t('curator_apply')
        },
        returnBackground() {
            if (util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.ProfileBack)) {
                return "";
            }
            // return `${this.memberData.Files.ProfileBack[0].org_url}?${this.setImageOptions(1200, 470, 'jpg')}`;
            return `${this.memberData.Files.ProfileBack[0].org_url}`;
        },
        returnProfile() {
            if (util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.Profile)) {
                return "";
            }
            return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(154, 154, 'png')}`;
        },
        returnUrl(url) {
            if (util.isEmpty(url)) {
                return "";
            }
            let expUrl = /^http[s]?\:\/\//i;
            if (!expUrl.test(url)) {
                return `http://${url}`;
            }
            return url;
        },
        setMoreContent() {
            if (util.isEmpty(this.memberData.mb_introduce)) {
                return false;
            }
            const ps = document.querySelectorAll('.creator_detail_intro');
            if (ps.length === 0) {
                setTimeout(() => {
                    this.setMoreContent();
                }, 200)
                return false;
            }
            let $this = this;
            ps.forEach(p => {
                p.style.lineHeight = '22.4px';
            })
            this.onceObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    let divHeight = entry.target.scrollHeight
                    let lineHeight = parseInt(entry.target.style.lineHeight);
                    let lines = Math.floor(divHeight / lineHeight);
                    if (!$this.onceMore && lines > 5) {
                        entry.target.classList['add']('off');
                    }
                }
            });

            ps.forEach(p => {
                this.onceObserver.observe(p);
            });
            return true;
        },
        checkRequestCurator() {
            EventBus.$emit('changeLoading', true);
            try {
                this.$api.$curation.checkApplyCurator().then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false)
                    if (res.Info.type === 99 || res.Info.type === 1) {
                        this.curatorStatus = true;
                        //this.errorAlert(this.$t('curator_application_err4'))
                    } else {
                        this.curatorStatus = false;
                        //this.$router.push('/curationapply')
                    }
                })
            } catch (e) {
                console.log(e);

            }
        },
        openShareModal() {
            this.$modal.show('share-modal', {memberData: this.memberData})
        }
    },
    watch: {
        'memberData': {
            deep: true,
            immediate: true,
            handler: function (val, oldVal) {
                this.setMoreContent();
            }
        }
    },
}
</script>

<style scoped>

</style>
