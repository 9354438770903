<template>
    <div class="padding_side">
        <div class="search_item mb19">
            <div class="filter_category_box mr5">
                <el-select id="category" v-model="searchInputOption" class="my_filter select"
                           popper-class="select_style"
                           :popper-append-to-body="false">
                    <el-option :value="'ti'" :label="$t('subject')"></el-option>
                    <el-option :value="'hash'" :label="$t('hashtag')"></el-option>
                    <el-option :value="'ni'" :label="$t('organizer')"></el-option>
                </el-select>
            </div>
            <div class="search_input mypage_search_input">
                <el-input :placeholder="$t('st')" v-model="searchInput"
                          @input="setData('searchInput',searchInput)"
                          @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                </button>
            </div>
            <button class="search_filter_btn" @click="openFilter('competition-filter-interest-filter')"></button>
        </div>
      <competition-filter-interest-filter></competition-filter-interest-filter>
    </div>
</template>

<script>
import util from "@/mixins/util";
import competitionUtil from "@/components/competition/competitionUtil";
import CompetitionFilterInterestFilter from "@/components/filter/CompetitionFilterInterestFilter";

const {filterConfig} = competitionUtil
export default {
    name: "CompetitionFilterInterestLayout",
    mixins: [competitionUtil],
    components: {CompetitionFilterInterestFilter},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            sortOption: 'i.updated_at',
            searchInputOption: 'ti',
            searchInput: '',

            filterCategory: [],
            filterStatus: [],
            filterPrice: ["", ""],
            filterDate: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
      openFilter(name) {
        this.$modal.show(name);
      },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        changeFilterPrize() {
            if (this.filterPrice[1] !== '' && this.filterPrice[0] > this.filterPrice[1]) {
                return
            }
            this.setFilterFunc('pr', this.filterPrice);
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        changeDateFilter() {
            if (util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            } else {
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
        openModal(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>