<template>
  <div class="cd_main_slide_wrap">
    <div class="slide_wrap"></div>
  </div>
</template>

<script>
export default {
  name: "CompetitionDetailMainSlideLayout"
}
</script>

<style scoped>

</style>