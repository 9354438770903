<template>
    <modal name="curation-history-modal"
           class="curation-history-modal"
           :pivotY="0.3"
           :scrollable="true"
           :width="`100%`"
           :height="`100%`"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="">
            <page-header :header_h_modal_back="true" _back="true" :header-title="$t('curation_history1')"></page-header>
            <div class="c_history_wrap">
                <curation-vuex-list-component v-if="showHistory" :curation-type="'curationHistory'" :defaultFilter="defaultFilter"></curation-vuex-list-component>
            </div>
        </div>
    </modal>
</template>

<script>
import CurationVuexListComponent from "@/components/curation/CurationVuexListComponent";
import util from "@/mixins/util";

export default {
    name: "CurationHistoryModal",
    mixins: [],
    components: {
        CurationVuexListComponent,
    },
    inject: [],
    provide() {
        return {};
    },
    props: {
        memberKey: {default: 0},
    },
    data() {
        return {
            url: '',
            showHistory: false,
            defaultFilter: {},
        };
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {

    },
    methods: {
        closeModal() {
            this.$modal.hide('curation-history-modal');
        },

        movePage(url) {
            this.$router.push(url);
        },

        handlerBeforeOpen(event) {
            if(!util.isEmpty(event.params) && !util.isEmpty(event.params.memberKey)) {
                this.defaultFilter = {no: event.params.memberKey}
            }else{
                this.defaultFilter = {no: this.memberKey}
            }
            this.showHistory = true;
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
            this.showHistory = false;
        },
        returnUrl(){
            return this.url
        },
    },

    watch: {},
};
</script>

<style scoped>

</style>
