<template>
    <modal name="judge-input-modal"
           class="filter-modal judge-input-modal"
           :pivotX="0.5"
           :pivotY="0.3"
           :adaptive="true"
           :width="'calc(100% - 28px)'"
           :height="'auto'"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div class="modal_wrap judge_input_modal_wrap">
            <div class="modal_header">
                <div class="modal_title">{{ $t('write_judge') }}</div>
                <div class="modal_close" @click="modalClose()"></div>
            </div>

            <judge-write-component :judge-type="'judgeWrite'" :judge-key="idx"></judge-write-component>


            <div class="judge_modal_footer">
                <div class="one_btn save_btn" @click="Confirm()">{{ $t('save') }}</div>
            </div>
        </div>
    </modal>

</template>


<script>
import alertMixins from "@/mixins/alertMixins";
import JudgeWriteComponent from "@/components/judge/JudgeWriteComponent";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "JudgeInputModal",
    mixins: [alertMixins],
    components: {
        JudgeWriteComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            idx: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.idx)) {
                event.stop();
            } else {
                this.idx = event.params.idx;
            }
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('judge-input-modal');
        },

        Confirm() {
            this.createConfirm({
                content: this.$t('judge_w_msg1') + '<br/>' + this.$t('judge_w_msg2'),
                confirmText: this.$t('save'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    EventBus.$emit('writeJudge')
                },
                cancel: () => {

                }
            });
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
