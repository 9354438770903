<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
<!--        <page-header class="sub_header" :header_white_back="true"></page-header>-->
        <gallery-detail-component gallery-type="galleryBanner" :galleryKey="galleryKey"></gallery-detail-component>
        <gallery-list-component :gallery-type="'galleryItemView'"
                                :defaultFilter="defaultFilter"></gallery-list-component>
    </div>
</template>

<script>
import GalleryDetailComponent from "@/components/gallery/GalleryDetailComponent";
import GalleryListComponent from "@/components/gallery/GalleryListComponent";

export default {
    name: "GalleryEdit",
    mixins: [],
    components: {
        GalleryDetailComponent,
        GalleryListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            galleryKey: Number(this.$route.params.idx),
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {cid: this.galleryKey};
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name, {galleryType: 'galleryAdd'});
        },
        movePage(url) {
            this.$router.push(url);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>

