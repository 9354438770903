<template>
    <div>

        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header">
            <button slot="headerBtns" class="h_plus_btn" @click="openModal()"></button>
        </page-header>
        <div class="collect_list_wrap">
            <collect-list-component :collect-type="'collect'"
                                    :defaultFilter="defaultFilter"></collect-list-component>
        </div>
        <collection-setting-modal></collection-setting-modal>
    </div>
</template>

<script>

import CollectListUpdateLayout from "@/template/collect/list/CollectListUpdateLayout";
import CollectionSettingModal from "@/components/modal/CollectionSettingModal";
import CollectListComponent from "@/components/collect/CollectListComponent";
import {mapState} from "vuex";

export default {
    name: "Collect",
    mixins: [],
    components: {CollectListComponent, CollectionSettingModal, CollectListUpdateLayout},
    inject: [],
    provide() {
        return {
            setViewData: this.setData
        }
    },
    props: {},
    data() {
        return {
            defaultFilter: {}
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.defaultFilter = {no: this.UserInfo.mb_no}
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        openModal() {
            this.$modal.show('collection-setting-modal', {collectType: 'collectAdd'});
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
