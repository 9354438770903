<template>
    <div class="radio_wrap">
        <!--        성인인증-->
        <li class="adult_certi_wrap">
            <h3 class="sub_title_m regular">{{ $t('adult_in') }}</h3>
            <div class="w_content_box">
                <el-radio-group class="el_radio_box" v-model="isCheckAdult"
                                @change="parentChange('isCheckAdult', isCheckAdult)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('yes2') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('no2') }}</el-radio>
                </el-radio-group>
            </div>
        </li>
        <!--        상세이미지-->
        <li class="detail_thumb_wrap">
            <h3 class="sub_title_m regular">{{ $t('d_thumb') }}</h3>
            <div class="w_content_box">
              <div class="margin_box">
                <div class="upload_box" v-for="(data, index) in detailFile"
                     :key="`detailFile${index}`">
                    <div class="img_box" v-if="hasFile('detailFile')"
                         :style="`background-image: url(${data.org_url})`"></div>
                    <button class="delete_btn" v-if="hasFile('detailFile')"
                            @click="removeList('detailFile',index)"></button>
                </div>
                <el-upload
                    class="n_el_up"
                    action=""
                    list-type="picture-card"
                    :show-file-list="false"
                    :file-list="detailFile"
                    :auto-upload="false"
                    :on-change="detailFileChange"
                >
                  <img class="default_img" src="@/assets/image/change_item.png">
                </el-upload>
              </div>
              <div class="clear"></div>
            </div>
        </li>
    </div>
</template>

<script>
import Vue from 'vue';
import SimpleVueValidation from 'simple-vue-validator';
import elFileMixins from "@/mixins/elFileMixins";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";

const Validator = SimpleVueValidation.Validator;

Vue.use(SimpleVueValidation);
export default {
    name: "ItemExtraInfoLayout",
    mixins: [elFileMixins, alertMixins],
    components: {},
    props: {
        setComponentData: {
            default: () => {
            }, type: Function
        },
        setValidatorArr: {
            default: () => {
            }, type: Function
        },
        setDeleteFiles: {
            default: () => {
            }, type: Function
        },
        itemData: {
            default: () => {
            }, type: Object
        },
        itemClass: {default: null,}
    },
    data() {
        return {
            detailFile: [],
            isCheckAdult: 0,
            validatorArr: [],

            detailMaxSize: 20 * 1024 * 1024,
        }
    },
    created() {
        if (this.isItemData()) {
            this.settingItemData();
        }
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        isItemData() {
            return !util.isEmpty(this.itemData);
        },
        settingItemData() {
            this.detailFile = this.$_.cloneDeep(this.itemData.detailFile);
            this.isCheckAdult = this.itemData.isCheckAdult;
        },
        parentChange(type, value) {
            this.setComponentData(type, value);
        },
        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        checkValidator() {

        },
        _checkFileSize(size, max) {
            if (size > max) {
                let sizeMB = max / 1024 / 1024
                this.errorAlert(this.$t('uploadFile_error1', {size: sizeMB + "MB"}));
                return false;
            }
            return true;
        },
        detailFileChange(file, fileList) {
            console.log(fileList)
            file = this.makeFileObj(file);
            let type = file.raw.type.split('/');
            if (this.detailFile.length > 9) {
                fileList.pop();
                this.errorAlert(this.$t('l_img1'))
                return false;
            }
            if (!this._checkFileSize(file.size, this.detailMaxSize)) {
                fileList.pop();
                return false;
            }
            if (!(type[1] === 'jpeg' || type[1] === 'png')) {
                this.errorAlert(this.$t('uploadFile_error2'))
                fileList.pop();
                return false;
            }
            this.detailFile = fileList;
            this.parentChange('detailFile', this.detailFile);
            this.$forceUpdate();
        },
        removeList(type, index) {
            let files = this[type].splice(index, 1);
            this.setDeleteFiles(files)
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
    },
    watch: {},
    validators: {
        detailFile: function (value) {
            return Validator.value(value)
        },
        isCheckAdult: function (value) {
            return Validator.value(value)
        },

    },
}
</script>

<style scoped>

</style>
