<template>
    <div class="gallery_detail_list_item_wrap">
        <div class="item_premium_list" v-for="(item, index) in itemData" :key="`item${item.i_idx}`" @click="selectItem(item)">
            <div class="img_wrap">
                <div class="img_bg" :style="`background-image: url(${returnThumbnail(item)})`"></div>
                <div class="art_card">{{ item.ic_name_eng }}</div>
                <div class="real_card" v-if="item.i_type == 'real'">{{ $t('real') }}</div>
            </div>

            <div class="content_wrap">
                <div class="subject_wrap">{{ item.i_name }}</div>
            </div>

        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListCurationRequestLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {

    },
    inject: ['setSelectItem'],
    provide() {
        return {}
    },
    props: {
        itemData: {default: () => []},
    },
    data() {
        return {
            entryItemLists: 20,

            searchMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name);
        },
        selectItem(item) {
            this.setSelectItem(item);
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return `${data.ItemFile.Item[0].org_url}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
