<template>
    <component :is="loader" v-if="loader" :utilityCategoryList="utilityCategoryList"></component>
  </template>
  
  <script>
  import {defineAsyncComponent} from "vue";
  
  export default {
    name: "UtilityFilterLink",
    mixins: [],
    components: {},
    inject: [],
    provide() {
      return {}
    },
    props: {
      filterLayout: '',
      utilityCategoryList : [],
    },
    data() {
      return {
        component: null,
      }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {
      loader() {
        if (!this.filterLayout) {
          return defineAsyncComponent(() => import('@/components/utility/layout/utilityList/filter/UtilityFilterMemberLayout.vue'));
        }
        return defineAsyncComponent(() => import(`@/components/utility/layout/utilityList/filter/${this.filterLayout}.vue`))
      },
    },
    methods: {},
    watch: {},
  }
  </script>
  
  <style scoped>
  
  </style>
  