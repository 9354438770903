<template>
    <div>
        <page-header :header_back="true">
            <button slot="end" class="btn-logo setting" v-if="showSetting" @click="$router.push(`/utility/update/${this.utilityKey}`)"></button>
        </page-header>
        <div>
            <utility-detail-component :UtilityType="'utilityDetail'" :utilityKey="utilityKey"></utility-detail-component>
        </div>
    </div>
</template>


<script>
import UtilityDetailComponent from '@/components/utility/UtilityDetailComponent.vue';
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "UtilityDetail",
    mixins: [],
    components: { UtilityDetailComponent, },
    props: {

    },
    data() {
        return {
            showSetting: false,
            utilityKey: Number(this.$route.params.idx),
            ticketData: [],
            utilityData: {},
            ownerData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        EventBus.$on('pushTicketData', this.pushTicketData);
        EventBus.$on('setUtilityData', this.setUtilityData);
        EventBus.$on('setOwnerData', this.setOwnerData);
        EventBus.$on('showSettingBtn', this.showSettingBtn)
    },
    beforeUnmount() {
        EventBus.$off('pushTicketData');
        EventBus.$off('setUtilityData');
        EventBus.$off('setOwnerData')
        EventBus.$off('showSettingBtn')
    },
    unmounted() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        pushTicketData(value) {
            this.ticketData = value;
        },
        setUtilityData(value) {
            this.utilityData = value
        },
        setOwnerData(value) {
            this.ownerData = value
        },
        openStateUpdate() {
            this.$vfm.show("utility-detail-filter-modal")
        },
        showSettingBtn(value) {
            this.showSetting = value;
        },
    },
    watch: {
    },
}
</script>
