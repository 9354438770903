<template>
    <div class="collect_detail_wrap">
        <div class="collect_back_img" :style="`background-image: url(${returnImage()})`"></div>
        <div class="collect_info_wrap">
            <div class="collect_profile_wrap">
                <div class="collect_main_img">
                    <img :src="returnMainImage()" width="180" height="180" alt="">
                </div>
                <div class="collect_util">
                    <div class="collect_like_wrap">
                        <div class="like_img"></div>
                        <span class="like_count">{{ returnCommas(collectData.Likes.cnt) }}</span>
                    </div>
                    <div class="collect_share_wrap">
                        <share-collect-component class="in_block" :collectData="collectData"></share-collect-component>
                    </div>
                </div>
                <div class="collect_detail_nick">
                    <span class="collect_title">{{ collectData.c_title }}</span>
                    <div class="collect_member_nick" v-if="collectData.mb_is_use === 1"  >
                        <div class="collect_member_img" @click="movePage(`/creator/${collectData.mb_uuid}`)">
                            <img :src="returnProfile()" width="25" height="25">
                        </div>
                        <div class="collect_nick" @click="movePage(`/creator/${collectData.mb_uuid}`)">
                            {{ collectData.mb_nick }}
                        </div>
                    </div>
                </div>
            </div>
            <div style="text-align: left;" class="mt10">
                <div class="collect_detail_info">
                    <div>
                        <div class="collect_info_text">
                            <div class="collect_info_title">Item</div>
                            <div class="collect_info_title">Owner</div>
                        </div>
                        <div class="collect_info_text info_right">
                            <div class="collect_info_content">{{ returnPriceString(collectData.item_cnt) }}</div>
                            <div class="collect_info_content"> {{ returnPriceString(collectData.owner_cnt) }}</div>
                        </div>
                    </div>
                    <div>
                        <div class="collect_info_text">
                            <div class="collect_info_title">Floor price</div>
                            <div class="collect_info_title">Volume traded</div>
                        </div>
                        <div class="collect_info_text info_right">
                            <div class="collect_info_content collect_price">
                                <div class="price">
                                    {{ returnPriceString(returnMinMingle()) }}Mg
                                </div>
                                <div class="item_small">
                                    ({{ returnPriceStringMingle(returnMinMingle()) }})
                                </div>
                            </div>
                            <div class="collect_info_content collect_price">
                                <div class="price">
                                    {{ returnPriceString(collectData.c_total) }}Mg
                                </div>
                                <div class="item_small">
                                    ({{ returnPriceStringMingle(collectData.c_total) }})
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="clear"></div>

            <div class="collect_intro">
                <div class="ql-editor pl0 pr0" v-html="replaceBr(collectData.c_intro)"></div>
            </div>
          <div>
            <button class="btn-collect-shortcut" v-if="checkLaunch()" @click="moveLaunch()">{{
                $t('shortcut_launch')
              }}
            </button>
          </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import LikeCollectionComponent from "@/components/like/LikeCollectionComponent";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import priceMixins from "@/mixins/priceMixins";
import ShareCollectComponent from "@/components/share/ShareCollectComponent";

export default {
    name: "CollectDetailDefaultLayout",
    mixins: [imageResizeMixins, priceMixins],
    components: {ShareCollectComponent, LikeCollectionComponent,},
    inject: [],
    provide() {
        return {}
    },
    props: {
        collectData: {
            default: () => {
            }
        },
      launchUrl: '',
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {

        returnImage() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.BgFile)) {
                return `${this.collectData.CollectionFiles.BgFile[0].org_url}?${this.setImageOptions(710, 456, 'webp')}`;

            } else {
                return '';
            }
        },
        returnMainImage() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.CollectionFiles) && !util.isEmpty(this.collectData.CollectionFiles.MainFile)) {
                return `${this.collectData.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(180, 180, 'webp')}`;
            }
        },
        returnProfile() {
            if (!util.isEmpty(this.collectData) && !util.isEmpty(this.collectData.OwnerFile) && !util.isEmpty(this.collectData.OwnerFile.Profile)) {
                return `${this.collectData.OwnerFile.Profile[0].org_url}?${this.setImageOptions(50, 50, 'webp')}`;
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        returnMinMingle() {
            if (!util.isEmpty(this.collectData.min_price)) {
                return this.collectData.min_price
            } else {
                return 0
            }
        },
        movePage(url) {
            this.$router.push(url);
        },
      checkLaunch() {
        return !util.isEmpty(this.launchUrl);
      },
      moveLaunch() {
        window.open(this.launchUrl, '')
      }

    },
    watch: {},
}
</script>

<style scoped>

</style>