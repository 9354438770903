<template>
    <modal name="detection-request-filter" class="filter-modal item-filter-premium-my-filter not_bottom"
           :width="`100%`" :height="'auto'" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{$t('status')}}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterStatus"
                                               @change="setFilterFunc('st',filterStatus)">
                                <el-checkbox v-for="status in detectionStatus" :key="`filterStatus${status.value}`" :label="status.value">{{ $t(status.name) }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_price filter_modal_date">
                    <div class="modal_title">{{ $t('reg_date') }}</div>
                    <div class="date-range-wrap">
                        <datetime class=" date-picker" v-model="filterDateView[0]"
                                  placeholder="YYYY-MM-DD"
                                  :min-datetime="returnLimitStartDate(0)"
                                  :max-datetime="returnLimitEndDate(0)" :week-start="7" format="yyyy-MM-dd"
                                  @input="changeDatePicker(0, $event)"></datetime>

                        <span class="first_input_step">~</span>

                        <datetime class=" date-picker" v-model="filterDateView[1]"
                                  placeholder="YYYY-MM-DD"
                                  :min-datetime="returnLimitStartDate(1)"
                                  :max-datetime="returnLimitEndDate(1)" :week-start="7" format="yyyy-MM-dd"
                                  @input="changeDatePicker(1, $event)"></datetime>

                        <button class="date-range-remove-btn" v-show="checkDate('filterDate')"
                                @click="resetDate('filterDate', 'cr')">
                            <i class="el-icon-circle-close"></i>
                        </button>
                    </div>
<!--                    <div class="price_wrap">
                        <div class="price_box">
                            <datetime class="date_time_btn fm_date_time" v-model="filterDateView[0]"
                                      placeholder="YYYY-MM-DD"
                                      :min-datetime="returnLimitStartDate(0)"
                                      :max-datetime="returnLimitEndDate(0)" :week-start="7" format="yyyy-MM-dd"
                                      @input="changeDatePicker(0, $event)"></datetime>
                            <div class="clear"></div>
                            <button v-show="checkDate('filterDate')"
                                    @click="resetDate('filterDate', 'cr')"></button>
                        </div>


                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <datetime class="date_time_btn fm_date_time" v-model="filterDateView[1]"
                                      placeholder="YYYY-MM-DD"
                                      :min-datetime="returnLimitStartDate(1)"
                                      :max-datetime="returnLimitEndDate(1)" :week-start="7" format="yyyy-MM-dd"
                                      @input="changeDatePicker(1, $event)"></datetime>
                            <div class="clear"></div>
                            <button v-show="checkDate('filterDate')"
                                    @click="resetDate('filterDate', 'cr')"></button>
                        </div>
                    </div>-->
                </div>

                <!--                <div class="mt20 filter_modal_edition">-->
                <!--                    <div class="modal_title">{{ $t('own2_datetime') }}</div>-->
                <!--                    <div class="price_wrap">-->
                <!--                        <div class="price_box">-->
                <!--                            <datetime class="date_time_btn fm_date_time" v-model="filterDateView[0]"-->
                <!--                                      placeholder="YYYY-MM-DD"-->
                <!--                                      :min-datetime="returnLimitStartDate(0)"-->
                <!--                                      :max-datetime="returnLimitEndDate(0)" :week-start="7" format="yyyy-MM-dd"-->
                <!--                                      @input="changeDatePicker(0, $event)"></datetime>-->
                <!--                        </div>-->
                <!--                        <span class="first_input_step">~</span>-->
                <!--                        <div class="price_box">-->
                <!--                            <datetime class="date_time_btn fm_date_time" v-model="filterDateView[1]"-->
                <!--                                      placeholder="YYYY-MM-DD"-->
                <!--                                      :min-datetime="returnLimitStartDate(1)"-->
                <!--                                      :max-datetime="returnLimitEndDate(1)" :week-start="7" format="yyyy-MM-dd"-->
                <!--                                      @input="changeDatePicker(1, $event)"></datetime>-->

                <!--                        </div>-->
                <!--                        <button v-show="checkDate('filterDate')"-->
                <!--                                @click="resetDate('filterDate', 'cr')">X</button>-->
                <!--                    </div>-->
                <!--                </div>-->



                <!--                <div class="wrapper_bottom">
                                    <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
                                </div>-->
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import { Datetime } from 'vue-datetime';
import { Settings } from 'luxon'
import 'vue-datetime/dist/vue-datetime.css'
import detectionUtil from "@/components/detection/detectionUtil";
import {detectionStatus} from "@/service/detectionService";

const {filterConfig} = detectionUtil

export default {
    name: "DetectionRequestFilter",
    mixins: [],
    components: {
        datetime: Datetime
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            filterStatus: [],
            filterDate: ['', ''],
            filterDateView: ['', ''],
            detectionStatus: detectionStatus,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        let lang = this.$localStorage.get('lang')
        //Settings.defaultLocale = lang
        if(lang === 'ko') {
            Settings.defaultLocale = 'ko'
        }else{
            Settings.defaultLocale = 'en'
        }

    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            this.sortData = event.params.sortData;
            if(!util.isEmpty(event.params.filterData)) {
                this.filterData = event.params.filterData;
            }
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('detection-request-filter');
        },
        returnLimitStartDate(index=0) {
            let startDate = null
            if (index === 1 && this.filterDate[0] != '') {
                startDate = this.$moment(this.filterDate[0]).toISOString();
            }
            return startDate;
        },
        returnLimitEndDate(index=0) {
            let endDate = null;
            if (index === 0 && this.filterDate[0] != '') {
                endDate = this.$moment(this.filterDate[1]).toISOString();
            }
            return endDate;
        },
        changeDatePicker(index, value) {
            if(value === '') {
                return false;
            }
            let reformDate = this.$moment(value).format('YYYY-MM-DD');
            this.filterDate[index] = reformDate;
            if(index === 0 && this.filterDate[1] != '' && this.filterDate[1] < reformDate) {
                this.filterDate[1] = reformDate;
                this.filterDateView[1] = reformDate + 'T00:00:00.000Z';
            }else if(index === 1 && this.filterDate[0] != '' && this.filterDate[0] > reformDate) {
                this.filterDate[0] = reformDate;
                this.filterDateView[0] = reformDate + 'T00:00:00.000Z';
            }
            this.setFilterFunc('cr', this.filterDate)
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                        if(classname === 'filterDate') {
                            if(obj.start != '') {
                                this[classname+'View'][0] = obj.start+'T00:00:00.000Z'
                            }
                            if(obj.end != '') {
                                this[classname+'View'][1] = obj.end+'T00:00:00.000Z'
                            }
                            console.log(obj)
                            console.log(this[classname+"View"])
                        }
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        checkDate(type) {
            if(this[type].findIndex(data => !util.isEmpty(data)) > -1) {
                return true;
            }
            return false;
        },
        resetDate(classname, type) {
            this[classname] = ['', ''];
            this[classname + 'View'] = ['', ''];
            this.setFilterFunc(type, this[classname])
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        changeFilter() {
            this.modalClose();
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
