<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
<!--        <page-header :header_back="true" :header-title="$t('curation')"></page-header>-->
        <div class="padding_side dgm_wrap">
            <div class="mypage_curation_wrap">
                <curation-list-component :curation-type="'request'" :defaultFilter="{cuap: UserInfo.mb_no}"
                                         v-if="tab === 'request'"></curation-list-component>
                <curation-list-component :curation-type="'curation'" :defaultFilter="{cu: UserInfo.mb_no}"
                                         v-else-if="tab === 'curation'"></curation-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import CurationListComponent from "@/components/curation/CurationListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";

export default {
    name: "MyPageCuration",
    mixins: [AuthMixins],
    components: {
        MyPageHeaderComponent,
        CurationListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'request',
            myType: '',
            defaultFilter: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.myType = this.getMemberType();
        if (!util.isEmpty(this.$route.query.tab)) {
            this.$router.replace(this.$route.path)
        }
        if (this.myType === 'curator') {
            this.tab = 'curation';
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
