<template>
    <div>
        <div class="creator_list" style="height: 400px; overflow: auto">
            <div class="creator_top mb6" v-for="creator in memberData" :key="`member${creator.mb_no}`"
             v-if="creator.mb_no !== UserInfo.mb_no">
                <div class="creator_img" @click="giftMember(creator.mb_no)">
                    <div class="profile_background"
                         :style="`background-image: url(${returnBackground(creator)});`"></div>
                    <div class="curator_icon" v-if="isCurator(creator)">{{ $t('en_curator') }}</div>
                </div>
                <div class="profile_info">
                    <div class="profile_img" :style="`background-image: url(${returnThumbnail(creator)});`"
                         @click="giftMember(creator.mb_no)"></div>
                    <div class="profile_s_mark" v-if="isSpecial(creator)" @click="giftMember(creator.mb_no)"></div>
                    <div class="profile_text" @click="giftMember(creator.mb_no)">{{ creator.mb_nick }}</div>
                    <div class="creator_like">
                        <div class="like_div" @click="giftMember(creator.mb_no)">
                            <img src="@/assets/image/s_heart_color.png" alt=""
                                 v-if=" isMyLike(creator)">
                            <img src="@/assets/image/s_heart.png" alt="" v-else>
                            <div class="like_cnt">{{ returnLike(creator) }}</div>
                        </div>
                        <div class="sub_point" >.</div>
                        <div class="item_subject" @click="giftMember(creator.mb_no)">Item <span
                                class="bold">{{ returnItem(creator) }}</span></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import AuthMixins from "@/mixins/AuthMixins";
import EventBus from "@/utils/event-bus";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "MemberListGiftLayout",
    mixins: [imageOption, AuthMixins, imageResizeMixins],
    components: {},
    inject: ['runGiftConfirm'],
    provide() {
        return {}
    },
    props: {
        memberData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        moveDetail(data) {
            if (this.UserInfo.mb_no == data.mb_no) {
                this.$router.push('/myprofile')
            } else {
                this.$router.push(`/creator/${data.mb_uuid}`)
            }
        },
        returnLike(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.likeCnt)) {
                //return util.Number.numFormat(data.likeCnt)
                return data.likeCnt
            }
            return 0
        },
        returnItem(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.itemCnt)) {
                // return util.Number.numFormat(data.itemCnt);
                return data.itemCnt
            }
            return 0
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
                return `${data.Files.Profile[0].org_url}?${this.setImageOptions(82, 82, this.returnExtension(data.Files.Profile[0].org_url))}`;
            }
        },
        returnBackground(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.ProfileBack)) {
                return `${data.Files.ProfileBack[0].org_url}?${this.setImageOptions(320, 218, this.returnExtension(data.Files.ProfileBack[0].org_url))}`;
            }
        },
        isCurator(data) {
            return !util.isEmpty(data) && data.g_idx == 2
        },
        isSpecial(data) {
            return !util.isEmpty(data) && data.mb_premium == 1
        },
        isMyLike(data) {
            if (!util.isEmpty(data.likeStatus)) {
                return data.likeStatus
            }
        },
        giftMember(key) {
            console.log(key);
            this.runGiftConfirm(key);
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
