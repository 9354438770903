<template>
    <div>
        <div class="search_item">
            <div class="search_input">
                <div class="el-input">
                    <!---->
                    <el-input type="text" autocomplete="off" :placeholder="$t('st')" v-model="filterInput"
                              @keypress.enter.native="setFilterFunc(filterInputOption,filterInput)"></el-input>
                    <!---->
                    <!---->
                    <!---->
                    <!---->
                </div>
                <button class="search_btn" @click="setFilterFunc(filterInputOption,filterInput)"></button>
            </div>
        </div>
        <div class="btn-domain-wrap">
            <button class="btn-domain btn-white-domain" @click="openIgnoreAddModal()">{{$t('ignore_url')}}</button>
        </div>
        <detection-list-url-modal></detection-list-url-modal>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import detectionUtil from "@/components/detection/detectionUtil";
import DetectionListUrlModal from "@/components/modal/DetectionListUrlModal";

const {filterConfig} = detectionUtil;
export default {
    name: "DetectionFilterUrlLayout",
    mixins: [],
    components: {
        DetectionListUrlModal
    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            filterInputOption: 'te',
            filterInput: '',
            inputDate: '',
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openModal(name){
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        movePage(url) {
            this.$router.push(url);
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        openIgnoreAddModal() {
            this.openModal('detection-list-url-modal')
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
