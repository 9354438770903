<template>
    <div class="point_status_wrap">
        <div class="point_status_list pt5">
            <div class="p_s_l_item">
                <div class="p_s_l_text active">{{ $t('usa_withdraw_mingle') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg active">{{returnCommas(pointRemain)}}</div>
                    <div class="mg  active">{{ $t('en_mg') }}</div>
                </div>
            </div>
            <div class="p_s_l_item">
                <div class="p_s_l_text">{{ $t('real_used_trade') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg">{{returnCommas(pointReal)}}</div>
                    <div class="mg">{{ $t('en_mg') }}</div>
                </div>
            </div>
            <div class="p_s_l_item">
                <div class="p_s_l_text">{{ $t('auction_bid_mingle') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg">{{returnCommas(pointAuction)}}</div>
                    <div class="mg">{{ $t('en_mg') }}</div>
                </div>
            </div>
            <div class="p_s_l_item">
                <div class="p_s_l_text">{{ $t('competition_open_mingle') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg">{{returnCommas(pointContest)}}</div>
                    <div class="mg">{{ $t('en_mg') }}</div>
                </div>
            </div>
            <div class="p_s_l_item">
                <div class="p_s_l_text">{{ $t('withdraw_mingle') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg">{{returnCommas(pointWithdraw)}}</div>
                    <div class="mg">{{ $t('en_mg') }}</div>
                </div>
            </div>
            <div class="p_s_l_item border-top">
                <div class="e_m_text">{{ $t('all_mingle') }}</div>
                <div class="p_s_mg_wrap">
                    <div class="p_s_l_mg entire">{{returnAllPoint()}}</div>
                    <div class="mg entire_mg">{{ $t('en_mg') }}</div>
                </div>
            </div>
        </div>
        <div class="point_status_btn" >
            <button class="two_btn btn_border"  @click="movePage('/mypage/point/charge')">{{ $t('charge_mingle') }}</button>
            <button class="two_btn back_navy" @click="movePage('/mypage/point/withdraw')">{{ $t('request_withdraw') }}</button>
        </div>
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    name: "PointStatusComponent",
    mixins: [alertMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            pointData: {},
            pointRemain: 0,
            pointReal: 0,
            pointAuction: 0,
            pointContest: 0,
            pointWithdraw: 0
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getList();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        }),
    },
    methods: {
        getList() {
            EventBus.$emit('changeLoading', true)
            try {
                this.$api.$point.getPointDetail().then(res => res.Data.Result).then(res => {
                    if (res.Info.type == 1) {
                        this.pointData = res.List;
                        this.pointRemain = this.pointData.remain[0].point;
                        this.pointReal = this.pointData.real[0].point;
                        this.pointAuction = this.pointData.auction[0].point;
                        this.pointContest = this.pointData.contest[0].point;
                        this.pointWithdraw = this.pointData.withdraw[0].point;
                    }
                    EventBus.$emit('changeLoading', false)
                })
            } catch (e) {
                console.log(e)
                EventBus.$emit('changeLoading', false)
            }

        },
        returnAllPoint() {
            let tatalPoint = Number(this.pointRemain) + Number(this.pointReal * -1) + Number(this.pointAuction * -1) + Number(this.pointContest * -1) + Number(this.pointWithdraw * -1);
            if (tatalPoint < 0) {
                tatalPoint = 0;
            }
            return this.returnCommas(tatalPoint);
        },

        returnCommas(x = 0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
