<template>
    <div>
        <div class="login_nickname">
            <div class="login_signup_input mb9">
                <input type="email" class="register_email" :placeholder="$t('email')" v-model="id"
                       @keypress.enter="checkValidator()"/>
            </div>
            <div class="login_signup_input mb17">
                <input type="password" class="register_password" v-model="password" @keypress.enter="checkValidator()"
                       :placeholder="$t('password')"/>
            </div>
        </div>
        <div class="login_signup mb15">
            <button class="one_btn font_white btn_background"
                    @click="checkValidator()"
            >
                {{ $t('login') }}
            </button>
        </div>
        <div class="search_login_middle">
            <button class="id_login" @click="movePage('/find/password')">
                {{ $t('find_pwd') }}
            </button>
            <button class="id_login mr0" @click="movePage('/auth/register')">
                {{ $t('register') }}
            </button>
        </div>
        <div class="validation validation_margin text_left login_vali">
            <div class="validation" v-if="errorMsg != ''">{{ errorMsg }}</div>
        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import memberValidator from "@/mixins/validators/memberValidator";
import {loginTokenSet, getTokenObj} from "@/mixins/Auth";
import util from "@/mixins/util";
import checkLevel from "@/mixins/checkLevelMixins";

export default {
    name: "LoginComponent",
    mixins: [memberValidator, alertMixins, checkLevel],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            redirect: '/',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (!util.isEmpty(this.$route.query.redirect)) {
            this.redirect = this.$route.query.redirect;
        }
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        checkValidator() {
            this.errorMsg = '';
            this.$validate('id').then(success => {
                if (success) {
                    this.$validate('password').then(success => {
                        if (success) {
                            this.login();
                        } else {
                            this.errorMsg = this.$t('write_pwd');
                        }
                    })
                } else {
                    this.errorMsg = this.$t('write_id_msg');
                }
            })
        },
        login() {
            let params = {
                mb_id: this.id,
                mb_password: this.password,
            }
            try {
                this.$api.$auth.login(params).then(res => res.Data.Result).then(res => {
                    let type = this.loginReturnType(res.Info.type);

                    if (type === true) {
                        this.errorMsg = '';
                        let token = res.List;
                        loginTokenSet(token);
                        this.checkLevel();
                    } else if (type !== false) {
                        this.errorMsg = this.$t('login_error_Msg');
                        this.errorAlert(type);
                    }
                })
            } catch (e) {
                console.log(e);
            }
        },
        loginReturnType(type) {
            switch (type) {
                case 1:
                    return true;
                case -3:
                    return this.$t('login_error_Msg');
                default:
                    return this.$t('error_msg2');
            }
        },
        movePage(url) {
            this.$router.push(url);
        },
        // autoTrim() {
        //   this.id = this.id.replace(' ', '');
        // },
        // setData(key, value) {
        //   this[key] = value
        //   if (this.errorMsg != '') {
        //     this.errorMsg = '';
        //   }
        // },

    },
    watch: {},

}
</script>

<style scoped>

</style>
