<template>
    <div>
        MemberListInterestCreatorLayout
    </div>
</template>

<script>
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import AuthMixins from "@/mixins/AuthMixins";

export default {
    name: "MemberListInterestCreatorLayout",
    mixins: [imageOption,AuthMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        memberData: {},
        paginationData: {default: {total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(data) {
            this.$router.push(`/creator/${data.mb_uuid}`)
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.Files) && !util.isEmpty(data.Files.Profile)) {
                return `${data.Files.Profile[0].org_url}?${this.setImageOptions(78, 49, 'png')}`;
            }
        },
        returnMemberType(){
            let type = '';
            type = this.$t(this.getMemberType());
            if(this.getIsPremium()){
                type += ','+this.$t(this.getIsPremium());
            }
            return type;

        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
