\<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header" :header-title="$t('mingle_charge')"></page-header>
        <point-charge-component :point-type="'pointCharge'"></point-charge-component>
    </div>
</template>

<script>

import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import PointChargeComponent from "@/components/point/PointChargeComponent";

export default {
    name: "PointCharge",
    mixins: [],
    components: {PointChargeComponent, MyPageHeaderComponent},
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'pointCharge',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.movePage(`/mypage/point/${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
