<template>
    <div>
        <item-list-component :item-type="'creationItem'" :memberKey="UserInfo.mb_no"></item-list-component>
    </div>
</template>

<script>
import {mapState} from "vuex";
import AuthMixins from "@/mixins/AuthMixins";
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "MyProfileCreationItem",
    mixins: [AuthMixins,],
    components: {
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
        // -0--
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
