import { render, staticRenderFns } from "./ItemListInterestLayout.vue?vue&type=template&id=7a6afca2&scoped=true"
import script from "./ItemListInterestLayout.vue?vue&type=script&lang=js"
export * from "./ItemListInterestLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a6afca2",
  null
  
)

export default component.exports