<template>
    <div>
<!--        <page-header :header_back="true" :header_side="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <search-component></search-component>
        <popup-modal></popup-modal>
    </div>

</template>

<script>
import SearchComponent from "@/components/search/SearchComponent";
import PopupModal from "@/components/modal/PopupModal";

export default {
    name: "Result",
    mixins: [],
    components: {
        PopupModal,
        SearchComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
