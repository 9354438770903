<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>

        <portfolio-write-component :portfolio-type="'portfolioWrite'"></portfolio-write-component>
    </div>
</template>

<script>

import PortfolioWriteComponent from "@/components/portfolio/PortfolioWriteComponent";

export default {
    name: "Portfolio",
    mixins: [],
    components: {
        PortfolioWriteComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
