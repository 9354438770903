<template>
    <div>
        <div class="item_wrap">
            <div class="item_category_wrap padding_side mt17">
                <button class="item_category" :class="{'active':filterCategory.length === 0}" @click="selectCategory('')">
                    All
                </button>
                <button class="item_category" :class="{'active':filterCategory[0] === data.node_id}"
                        @click="selectCategory(data.node_id)" v-for="data in itemCategoryList"
                        :key="`categort${data.node_id}`"> {{ data.NAME_ENG }}
                </button>
            </div>
        </div>
        <div class="">
            <div class="competition_wrap pb17 padding_side">
                <div class="competition_item application_item">
                    <div class="competition_input">
                        <el-input :placeholder="$t('st')" v-model="searchInput" @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)"></el-input>
                        <button class="competition_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
                    </div>
                </div>
                <div class="competition_btn_wrap">
                    <button class="c_sort_btn ma0" @click="openFilter('open-market-sort-filter')"></button>
                    <button class="c_filter_btn ma0 ml13"  @click="openFilter('item-filter-curation-request-filter')"></button>
                </div>
                <div class="clear"></div>
            </div>
<!--            <div class="search_item mb19">
                <div class="search_input detection_search_input">
                    <el-input :placeholder="$t('st')" v-model="searchInput"
                              @input="setData('searchInput',searchInput)"
                              @keyup.native.enter="setFilterFunc(searchInputOption,searchInput)">
                    </el-input>
                    <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
                    </button>
                </div>
                <button class="search_filter_btn" @click="openModal('item-filter-curation-request-filter')"></button>
            </div>-->
        </div>
        <!--        <div class="competition_wrap item_add_filter_wrap">
                    <div class="competition_btn_wrap">
                        <button class="c_sort_btn ma0" @click="openModal('item-filter-curation-request-filter')"></button>
                    </div>
                    <div class="clear"></div>
                </div>-->
        <open-market-sort-filter></open-market-sort-filter>
        <item-filter-curation-request-filter></item-filter-curation-request-filter>
    </div>
</template>

<script>
import itemUtil from "@/components/item/itemUtil";
import util from "@/mixins/util";
import {mapState} from "vuex";
import ItemFilterCurationRequestFilter from "@/components/filter/ItemFilterCurationRequestFilter";
import OpenMarketSortFilter from "@/components/sort/OpenMarketSortFilter";

const {filterConfig} = itemUtil;
export default {
    name: "ItemFilterItemAddLayout",
    mixins: [],
    components: {
        OpenMarketSortFilter,
        ItemFilterCurationRequestFilter
    },
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
        sortData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            searchInputOption: 'ina',
            sortOption: 'last_update',
            searchInput: '',
            filterCategory: [],
            filterEdition: [],
            filterType: [],
            showPopover: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
            if (!util.isEmpty(this.sortData.key)) {
                this.sortOption = this.sortData.key;
            }
        },
        sortChange(data) {
            if (data === 'priceAsc') {
                this.sort = 'asc'
                this.setSort('i_price', 'asc');
            } else if (data === 'priceDesc') {
                this.sort = 'desc'
                this.setSort('i_price', 'desc');
            } else {
                this.sort = 'desc'
                this.setSort(data, 'desc');
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData, sortData: this.sortData});
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
            if (this[dataName]) {
                this.btnCheck = false;
            }
        },
        changeFilter() {
            if (this.priceInput[1] !== '' && this.priceInput[0] > this.priceInput[1]) {
                return
            }
            this.setFilterFunc('pr', this.priceInput);
        },
        selectCategory(value) {
            if (value == '') {
                this.filterCategory = [];
            } else {
                this.filterCategory = [value];
            }
            this.setFilterFunc('ic', this.filterCategory);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
