<template>
    <div>
        <div class="detail_img_wrap">
            <div class="b_img_wrap" v-loading="mainImgLoading" @click="spinImg()">
                <img :class="{'active':spin}" :src="returnThumbnail(mainImg)" @load="isLoadMainImg">
                <div class="real_card" v-if="itemData.i_type === 'real'">{{ $t('real') }}</div>

                <i class="el-icon-caret-right play_icon" v-if="isPossiblePlay()" @click="openPlayModal($event)"></i>
            </div>
            <div>
                <swiper class="s_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                        :slidesPerView="'auto'" @click="onClick"
                        @slideChange="slideChange">
                    <swiper-slide class="img_wrap">
                        <div class="img_box">
                            <img :src="returnSmallThumbnail(orgItemImg)">
                        </div>
                    </swiper-slide>
                    <swiper-slide class="img_wrap"
                                  v-for="(data,index) in thumnailImgs"
                                  :key="`detail${index}`"
                                  v-if="data.org_name != 'no_image.png'">
                        <div class="img_box">
                            <img :src="returnSmallThumbnail(data.org_url)">
                        </div>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
        <play-video-modal></play-video-modal>
        <play-audio-modal></play-audio-modal>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from "vue-awesome-swiper";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import PlayVideoModal from "@/components/modal/PlayVideoModal";
import PlayAudioModal from "@/components/modal/PlayAudioModal";
import util from "@/mixins/util";

export default {
    name: "ItemDetailMainSlideLayout",
    mixins: [imageResizeMixins],
    components: {
        PlayVideoModal,
        PlayAudioModal,
        Swiper,
        SwiperSlide
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        showPlayer: {default: false,}
    },
    data() {
        return {
            swiperOption: {
                slidesPerView: 3,
                spaceBetween: 3,
                grabCursor: true,
            },
            swiperOption2: {
                slidesPerView: 1.77,
                spaceBetween: 3,
                grabCursor: true,
            },
            spin: false,
            mainImgLoading: true,
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initItemData();
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Item)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');


            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            if(this.mainImg === elementSrc[0]) {
                return false;
            }
            this.mainImgLoading = true;
            this.changeThumnail(elementSrc[0]);
        },
        spinImg() {
            this.spin = !this.spin;
        },
        isLoadMainImg() {
            this.mainImgLoading = false;
        },
        isPossiblePlay() {
            return this.showPlayer && this.orgItemImg === this.mainImg && (this.itemData.im_type === 'video' || this.itemData.im_type === 'audio')
        },
        openPlayModal(event) {
            if(!this.isPossiblePlay()) {
                return false;
            }
            if(this.itemData.im_type === 'video') {
                this.openVideoModal();
            }else if(this.itemData.im_type === 'audio'){
                this.openAudioModal();
            }
            event.stopPropagation();
        },
        openVideoModal() {
            if(util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.VideoStream)) {
                return false;
            }
            let url = this.itemData.ItemFile.VideoStream[0].org_url;
            let name = this.itemData.ItemFile.VideoStream[0].org_name;
            let thumbnail = this.returnThumbnail(this.orgItemImg);
            this.$modal.show('play-video-modal', {'src': url, 'name': name, thumbnail: thumbnail, is360: false});
        },
        openAudioModal() {
            if(util.isEmpty(this.itemData.ItemFile) || util.isEmpty(this.itemData.ItemFile.Original)) {
                return false;
            }
            let url = this.itemData.ItemFile.Original[0].org_url;
            let name = this.itemData.ItemFile.Original[0].org_name;
            let thumbnail = this.returnThumbnail(this.orgItemImg);
            this.$modal.show('play-audio-modal', {'src': url, 'name': name, 'thumbnail': thumbnail});

        },
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
            }
        },
    },
}
</script>

<style scoped>

</style>
