<template>
    <div class="collect_setting_wrap">
        <div class="collect_bg_wrap">
            <div class="collect_setting_title">{{ $t('back_img') }}
                <button class="reset_btn"
                        @click="[changeType('backgroundFile'), handleFileRemove(backgroundFile[0], [])]"
                        v-if="backgroundFile.length > 0">{{ $t('reset') }}
                </button>
            </div>

            <div class="collect_bg ba0" @click="[changeType('backgroundFile'), initErrorMsg()]">
                <el-upload
                        action=""
                        class="img_wrap w100p"
                        list-type="picture-card"
                        accept="image/png, image/jpeg"
                        :file-list="backgroundFile"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="onlyOnePhotoChange"
                >
                    <button class="collect_bg" v-if="backgroundFile.length === 0"></button>
                    <button v-else class="collect_bg">
                        <img :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                    </button>
                </el-upload>
                <div class="check_validator" v-if="validation.hasError('backgroundFile')">
                    {{ validation.firstError('backgroundFile') }}
                </div>
            </div>

            <div class="collect_main_box">
                <div class="collect_main ba0" @click="[changeType('mainFile'), initErrorMsg()]">
                    <el-upload
                            action=""
                            class="img_wrap w100p"
                            list-type="picture-card"
                            accept="image/png, image/jpeg"
                            :file-list="mainFile"
                            :auto-upload="false"
                            :show-file-list="false"
                            :on-change="onlyOnePhotoChange"
                    >
                        <button class="collect_main" v-if="mainFile.length === 0"></button>
                        <button v-else class="collect_main">
                            <img :src="mainFile[0].org_url" v-if="mainFile.length > 0" alt="">
                        </button>
                    </el-upload>
                    <div class="check_validator" v-if="validation.hasError('mainFile')">
                        {{ validation.firstError('mainFile') }}
                    </div>
                </div>
                <div class="collect_main_title">{{ $t('main_img') }}</div>
                <button class="reset_btn mt7"
                        @click="[changeType('mainFile'), handleFileRemove(mainFile[0], [])]"
                        v-if="mainFile.length > 0">{{ $t('reset') }}
                </button>
                <div class="clear"></div>
            </div>
        </div>

        <div :class="{'object_hidden': !checkPremium()}">
            <div class="collect_setting_title">{{ $t('owner_use_set') }}</div>
            <div class="">
                <el-radio-group class="el_radio_box" v-model="owner" :disabled="isCollectData()"
                                @change="parentChange('owner', owner)">
                    <el-radio class="el_radio_btn" :label="1">{{ $t('owner_use') }}</el-radio>
                    <el-radio class="el_radio_btn" :label="0">{{ $t('owner_use_not') }}</el-radio>
                </el-radio-group>

            </div>
            <div class="check_validator mt10 mb10">
                <span>{{ $t('owner_update_msg') }}</span>
            </div>
        </div>


        <div class="collect_setting_title">{{ $t('collect_name') }}</div>
        <el-input class="collect_input " :placeholder="$t('collect_name_error')" v-model="title"
                  @input="parentChange('title', title)" maxLength="20"></el-input>
        <div class="check_validator" v-if="validation.hasError('title')">
            {{ validation.firstError('title') }}
        </div>


        <div class="collect_setting_title mt14">{{ $t('collect_info') }}</div>
        <div class="intro_box mb20">
            <el-input class="intro_textarea" :placeholder="$t('collect_info_msg')" type="textarea" v-model="info"
                      @input="parentChange('info', info)"
                      maxLength="1000"></el-input>
        </div>

        <div class="save_btn one_btn" @click="runSave">{{ $t('save') }}</div>
        <div class="check_validator" v-if="errorMsg != ''">
            {{ errorMsg }}
        </div>

    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import collectValidator from "@/mixins/validators/collectValidator";
import {mapState} from "vuex";

export default {
    name: "CollectWriteModalLayout",
    mixins: [elFileMixins, collectValidator],
    components: {},
    inject: ['collectWriteSetData'],
    provide() {
        return {}
    },
    props: {
        collectData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            collectionName: '',
            errorMsg: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.settingBeforeData();
    },
    mounted() {
        EventBus.$on('GalleryWriteModalLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('GalleryWriteModalLayoutValidator')
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        settingBeforeData() {
            if (!this.isCollectData()) {
                return false;
            }
            this.title = this.collectData.c_title;
            this.collectWriteSetData('title', this.title);
            this.owner = this.collectData.c_owner;
            this.collectWriteSetData('owner', this.owner);
            this.info = this.collectData.c_intro;
            this.collectWriteSetData('info', this.info);

            if (!util.isEmpty(this.collectData.CollectionFiles)) {
                if (!util.isEmpty(this.collectData.CollectionFiles.BgFile) && !util.isEmpty(this.collectData.CollectionFiles.BgFile[0].a_idx)) {
                    this.backgroundFile = this.collectData.CollectionFiles.BgFile;
                    this.collectWriteSetData('backgroundFile', this.backgroundFile);
                }
                if (!util.isEmpty(this.collectData.CollectionFiles.MainFile) && !util.isEmpty(this.collectData.CollectionFiles.MainFile[0].a_idx)) {
                    this.mainFile = this.collectData.CollectionFiles.MainFile
                    this.collectWriteSetData('mainFile', this.mainFile);
                }

            }
        },
        runValidator(validatorArr = ['title', 'owner']) {
            this.$validate(validatorArr).then(success => {
                if (success) {
                    this.errorMsg = "";
                } else {
                    this.errorMsg = this.$t('all_input');
                }
            })
        },
        parentChange(type, value) {
            this.collectWriteSetData(type, value);
            this.initErrorMsg();
        },
        initErrorMsg() {
            this.errorMsg = "";
        },
        runSave() {
            EventBus.$emit('writeCollect');
        },
        isCollectData() {
            return !util.isEmpty(this.collectData);
        },
        checkPremium() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_premium === 1) {
                return true
            }

            return false
        }
    },
    watch: {
        'collectData': {
            deep: true,
            handler: function (val, oldVal) {
                this.settingBeforeData()
            }
        },
    },
}
</script>

<style scoped>

</style>
