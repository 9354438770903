import {bucketOption} from "@/api/config";
import AWS from "aws-sdk";
import EventBus from "@/utils/event-bus";
import {request} from "@/api/request";
import util from "@/mixins/util";
import moment from 'moment';

let progress = 0;
let uploading = false;

let AccessKeyId = 'QUtJQVdKU0hEV0RTTlJHU01YUjQ=';
let SecretAccessKey = "RitZT2xxQ0RpZVcreTNBeTVqNFErS2hsUWcySDl1R290czdWaUNPYQ==";

function setBucketPath(option, fileName) {
    let Bucket = '';
    let current = moment().format('x')
    if (option.hasOwnProperty('type')) {
        Bucket += `${option.type}/`;
    }
    if (option.hasOwnProperty('id')) {
        Bucket += `${option.id}/`;
    }
    Bucket += current;
    Bucket += fileName;
    return Bucket;
}

function updateAWSConfig(option) {
    let key = atob(AccessKeyId);
    let secret = atob(SecretAccessKey)
    AWS.config.update({
        region: option.BucketRegion,
        credentials: new AWS.Credentials(key, secret)
    })
}


export const fileuploadS3 = async (file, option) => {
    return new Promise((resolve) => {
        updateAWSConfig(option);
        AWS.config.loadFromPath = bucketOption.BucketName;
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
            params: {
                Bucket: bucketOption.BucketName,
            }
        });

        let s3FileName = getRandomFileName(file.name);
        let data = {
            Key: setBucketPath(option, s3FileName),
            Body: file.raw,
            ContentType: file.raw.type,
            ACL: 'public-read',
        }
        /*s3.upload(data, function (err, res) {
            if (err) {
                resolve(false);
            } else {
                s3ApiUpdate(option, file, res).then(res => {
                    resolve(res);
                })
            }
        })*/
        s3.upload(data).on(' httpUploadProgress', function (evt) {
            uploading = true;
            progress = parseInt((evt.loaded * 100) / evt.total);
            EventBus.$emit('changeLoading', true, progress);
        }).send(function (err, res) {
            if (err) {
                resolve(false);
            } else {

                s3ApiUpdate(option, file, res, s3FileName).then(res => {
                    resolve(res);
                })
            }
        })

        // s3.upload()
    })
};
// s3등록 완료 후 db에 파일 저장
const s3ApiUpdate = async (options, file, res, s3FileName) => {
    return new Promise((resolve) => {
            let params = {
                a_container_id: options.id,
                a_container_type: options.type,
                a_filesize: file.size,
                a_content_type: file.raw.type,
                a_file_extension: getFileExtension(file.name),
                a_disk_filename: getDiskFileName(res.Key, s3FileName),
                a_filename: returnFileName(file.name,getFileExtension(file.name))
            }
            if (options.hasOwnProperty('a_idx') && !util.isEmpty(options.a_idx)) {
                params.a_idx = options.a_idx
            }
            try {
                request.post('file/direct', params).then(res => res.data).then(res => res.Data.Result).then(res => {
                    resolve(res)
                })
            } catch (e) {
                console.log(e)
            }
        }
    )
}

// s3등록 전 db에 파일 삭제
export const s3ApiDelete = async (idx) => {
    return new Promise((resolve) => {
            try {
                request.post(`file/delete/${idx}`).then(res => res.data).then(res => res.Data).then(res => {
                    resolve(res)
                })
            } catch (e) {
                console.log(e)
            }
        }
    )
}


export const fileDownloadS3 = async (fileName, option) => {
    return new Promise((resolve) => {
        updateAWSConfig(option);
        AWS.config.loadFromPath = option.BucketName;
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
        });
        let data = {
            Bucket: setDownloadBucketPath(option),
            Key: fileName
        }

        s3.getObject(data).on('httpUploadProgress', function (evt) {
            uploading = true;
            progress = parseInt((evt.loaded * 100) / evt.total);
            EventBus.$emit('changeLoading', true, progress);
        }).send(function (err, res) {
            if (err) {
                resolve(false);

            } else {
                const blob = new Blob([res.Body], {
                    type: res.ContentType
                });
                console.log(blob)
                resolve(blob)
            }
        })
    })
};
export const nftFileDownloadS3 = async (file, option) => {
    return new Promise((resolve) => {
        updateAWSConfig(option);
        AWS.config.loadFromPath = option.BucketName;
        const s3 = new AWS.S3({
            apiVersion: '2006-03-01',
        });
        let fileName = file.a_disk_filename;

        let data = {
            Bucket: setDownloadBucketPath(option),
            Key: fileName
        }

        s3.getObject(data).on('httpUploadProgress', function (evt) {
            uploading = true;
            progress = parseInt((evt.loaded * 100) / evt.total);
            EventBus.$emit('changeLoading', true, progress);
        }).send(function (err, res) {
            if (err) {
                resolve(false);

            } else {
                const blob = new Blob([res.Body], {
                    type: file.a_content_type
                });
                console.log(blob)
                resolve(blob)
            }
        })
    })
};

function setDownloadBucketPath(option) {
    let Bucket = '';
    Bucket += `${option.BucketName}/`;
    if (option.hasOwnProperty('type')) {
        Bucket += `${option.type}`;
    }
    if (option.hasOwnProperty('id')) {
        Bucket += `/${option.id}`;
    }
    return Bucket;
}

function getFileExtension(name) {
    let idx = name.lastIndexOf('.')
    return name.substring(idx + 1)
}

function getRandomFileName(name) {
    let idx = name.lastIndexOf('.')
    let fileName = Math.random().toString(36).substr(2, 11) + name.substring(idx);
    return fileName
}

function getDiskFileName(key, name) {
    let idx = key.indexOf(name)
    return key.substring(idx - 13)
}

//파일명 특수문자 + 한글 제거
function regExp(str) {
    let reg = /[\{\}\[\]\/?,.;:|\)*~`!^\-+<>@\#$%&\\\=\(\'\"ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/gi
    if (reg.test(str)) {
        return str.replace(reg, "_");
    } else {
        return str
    }
}
function returnFileName(name,type) {
    let fileName = regExp(name)
    return fileName.replace(type, "")+'.'+type;
}
