<template>
    <modal name="qna-filter-modal" class="filter-modal qna-filter-modal not_bottom"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

                <div class="mt20 filter_modal_type">
                    <div class="modal_title">{{ $t('category') }}</div>
                    <div class="radio_wrap">
                        <div class=" inline_block">
                            <el-radio-group v-model="filterStatus"
                                               @change="setFilterFunc('isa', filterStatus)">
                                <el-radio :label="''">{{$t('all')}}</el-radio>

                                <el-radio :label="0">{{$t('answer_wait')}}</el-radio>
                                <el-radio :label="1">{{$t('answer_finish')}}</el-radio>
                            </el-radio-group>
                        </div>

                    </div>
                </div>
<!--                <div class="wrapper_bottom">
                    <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
                </div>-->
            </div>
        </div>
    </modal>
</template>

<script>
import util from "@/mixins/util";
import boardFilterUtil from "@/components/board/boardFilterUtil";

const {filterConfig} = boardFilterUtil;
export default {
    name: "QnaFilterModal",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            filterData: {},
            filterStatus: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            if (event.params.hasOwnProperty('filterData')) {
                this.filterData = event.params.filterData;
            }
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('qna-filter-modal');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
