<template>
    <div>
        <div class="dgm_wrap">
            <my-page-header-component></my-page-header-component>
        </div>
    </div>

</template>

<script>
import ItemListComponent from "@/components/item/ItemListComponent";
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";

export default {
    name: "MyPage",
    mixins: [],
    components: {
        MyPageHeaderComponent,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>