import {bucketOption, nftBucketOption} from "@/api/config";
import {fileDownloadS3,nftFileDownloadS3} from "@/plugins/awsS3Plugin";
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";


export default {
    data() {
        return {
            successType: true,
            options: {},

        }
    },
    mixins: [alertMixins],
    mounted() {
    },
    beforeDestroy() {
    },
    methods: {
        runDownloadFileInfo(fileType, file, key, type = false) {
            EventBus.$emit('apiLoading', true)
            return new Promise(resolve => {
                this.runS3ApiDownload(fileType, file, key, type, resolve);
            })
        },
        runS3ApiDownload(fileType, file, key, type, resolve) {
            this.setS3FileDownload(file, fileType, key, type).then(res => {
                EventBus.$emit('apiLoading', false)
                if (!res) {
                    resolve(false)
                }
                resolve(true);
            })
        },
        setS3FileDownload(file, fileType, id, type) {
            let _this = this;
            this.options = {
                type: fileType,
                id: id,
                BucketName: bucketOption.BucketName,
                BucketRegion: bucketOption.BucketRegion,
                BucketIdentityPoolId: bucketOption.BucketIdentityPoolId,
            };
            if (type) {
                this.options.BucketName = nftBucketOption.BucketName;
                this.options.BucketRegion = nftBucketOption.BucketRegion;
                this.options.BucketIdentityPoolId = nftBucketOption.BucketIdentityPoolId;
                this.options.type = file.disk_directory;
                delete this.options.id
            }
            return new Promise(function (resolve, reject) {
                if (file.length === 0) {
                    resolve(true);
                } else {
                    _this.runS3Download(file, resolve,type)
                }
            })
        },
        runS3Download(file, resolve,type) {
            let _this = this;
            let fileName = '';

            if (file.hasOwnProperty('a_disk_filename')) {
                fileName = file.a_disk_filename;
            } else {
                fileName = file.org_url.split('/');
                fileName = fileName[fileName.length - 1];
            }


            let options = JSON.parse(JSON.stringify(this.options));

            if (file.hasOwnProperty('a_idx')) {
                if(type){
                    return nftFileDownloadS3(file, options).then(res => {
                        if (!res) {
                            resolve(false);
                        }
                        this.downloadFile(res, fileName);
                        resolve(true);
                    })
                }else {
                    return fileDownloadS3(fileName, options).then(res => {
                        if (!res) {
                            resolve(false);
                        }
                        this.downloadFile(res, file.org_name);
                        resolve(true);
                    })
                }
            } else {
                resolve(false);
            }
        },
        downloadFile(data, fileName) {
            const a = document.createElement("a");
            a.style.display = "none";
            document.body.appendChild(a)
            a.href = window.URL.createObjectURL(data);
            a.setAttribute("download", fileName);
            a.click();
            window.URL.revokeObjectURL(a.href);
            document.body.removeChild(a);
        },
    }
}
