<template>
    <div >
<!--        <page-header :header_back="true"  :header_setting="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <item-register-component :item-key="itemKey"></item-register-component>

    </div>
</template>

<script>
import ItemRegisterComponent from "@/components/item/ItemRegisterComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemUpdate",
    mixins: [],
    components: {
        ItemRegisterComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            termsCheck: false,
            termsMsg: false,
            itemKey:''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey=this.$route.params.idx
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name, type) {
            this.$modal.show(name, {termsType: type});
        },
        changeCheck() {
            this.termsCheck = !this.termsCheck;
        },
        save(type,data) {
            if (!this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit(type,data);
        },
        deleteItem(){
            EventBus.$emit('deleteItem');
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
