<template>
    <div class="">
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <item-detail-component :item-type="'itemPrice'" :item-key="itemKey"></item-detail-component>
        <popup-modal></popup-modal>
    </div>
</template>

<script>
import PopupModal from "@/components/modal/PopupModal";
import ItemDetailComponent from "@/components/item/ItemDetailComponent";

export default {
    name: "Price",
    mixins: [],
    components: {
        ItemDetailComponent,
        PopupModal,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            itemKey: ''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.itemKey = this.$route.params.idx;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
