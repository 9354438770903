export const naverService = () => {
    const naverLogin = new window.naver.LoginWithNaverId({
        clientId: "mtNp5mLPqG7slXUOAFnV",
        callbackUrl: "https://m.minglemint.com/auth/naver/callback",
        isPopup: true, /* 팝업을 통한 연동처리 여부 */
        loginButton: {
            color: "green",
            type: 1,
            height: 64,
        }, /* 로그인 버튼의 타입을 지정 */
    });
    const setNaver = () => {
        naverLogin.init();
    };
    const getUserInfo = () => {
        setNaver();
        naverLogin.getLoginStatus((status) => {
            if (status) {
                const email = naverLogin.user.email;
                const name = naverLogin.user.name;
                console.log(naverLogin.user);
            } else {
                console.log("AccessToken이 올바르지 않습니다.");
            }
        });
    };
    const getOathParams = () => {
        setNaver();
        if(naverLogin.accessToken.hasOwnProperty('accessToken')) {
            return naverLogin.accessToken.accessToken
        }else{
            return false;
        }
    }
    return {
        setNaver,
        getUserInfo,
        getOathParams,
    };
};

export const naverDisconnect = (accessToken) => {
    return new Promise(resolve => {
        let client_id = 'mtNp5mLPqG7slXUOAFnV';
        let client_secret = 'cvIIBIH1es';
        let url = `http://nid.naver.com/oauth2.0/token?grant_type=delete&client_id=${client_id}&client_secret=${client_secret}&access_token=${accessToken}&service_provider=NAVER`
        let popup = window.open(url, "Naver Disconnect", "width=100, height=100,left=0,top=0,toolbar=no, location=no, directories=no, status=no, menubar=no, resizable=no, scrollbars=no, copyhistory=no");
        /*popup.onload = function () {
            console.log('popup onload 1')
            popup.close();
            resolve(true);
        }
        popup.addEventListener("load" ,function (event) {
            console.log('popup onload 2')
            popup.close();
            resolve(true);
        })*/
        setTimeout(() => {
            popup.close();
            resolve(true)
        }, 500)
    })
}

