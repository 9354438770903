<template>
    <div>
        <div class="alarm">
            <div class="alarm_lists" v-for="(data, index) in alarmData" :key="`alarm${index}`">
                <div class="list_img">
                    <div class="alarm_img" :class="classObject(data.ah_code)">
                    </div>
                </div>
                <div class="alarm_texts ml7">
                    <div class="alarm_content mb7" v-html="`${$t(data.ah_code, data.ah_data)}`">
<!--                        <span>닉네임</span>님이 <span> <아이템 제목명1> </span>을 큐레이션 신청했습니다.-->
                    </div>
                    <div class="alarm_content alarm_div" >
                        {{ returnOneDayDate(data.created_at) ? returnOneDayDate(data.created_at) : '-' }}
                    </div>
                    <div class="alarm_time mb20"></div>

                </div>
                <button class="alarm_rollback" @click="deleteAlaram(data.ah_idx)">
                    <div class="rollback_img"></div>
                </button>
            </div>
<!--            <div class="alarm_lists">
                <div class="list_img">
                    <div class="alarm_img check_img">
                    </div>
                </div>
                <div class="alarm_texts ml7">
                    <div class="alarm_content mb3" v-html="$t('alarm2', {item : '아이템 제목명1'})">
                    </div>
                  <div class="alarm_content mb3" v-html="$t('alarm3', {item : 'NNNN Mg'})">
                  </div>
                  <div class="alarm_content mb7">
                    {{$t('E_04')}}
                  </div>
                    <button class="alarm_confirm mb4">
                        <div>{{$t('check_p_num')}}</div>
                    </button>
                    <div class="alarm_content alarm_div mb20"  v-html="$t('t_minute', {time : '2'})">

                    </div>
                    <div class="alarm_time"></div>

                </div>
                <button class="alarm_rollback">
                    <div class="rollback_img"></div>
                </button>
            </div>
            <div class="alarm_lists">
                <div class="list_img">
                    <div class="alarm_img check_img">
                    </div>
                </div>
                <div class="alarm_texts ml7">
                    <div class="alarm_content mb7" v-html="$t('alarm4')">
                    </div>
                    <div class="alarm_content alarm_div mb20" v-html="$t('t_minute', {time : '2'})">

                    </div>
                    <div class="alarm_time"></div>
                </div>
                <button class="alarm_rollback">
                    <div class="rollback_img"></div>
                </button>
            </div>
            <div class="alarm_lists">
                <div class="list_img">
                    <div class="alarm_img cancel_img">
                    </div>
                </div>
                <div class="alarm_texts ml7">
                    <div class="alarm_content mb7" v-html="$t('alarm5', {item : '아이템 제목명'})">
                    </div>
                    <div class="alarm_content alarm_div mb20" v-html="$t('t_minute', {time : '2'})" >
                    </div>
                    <div class="alarm_time"></div>

                </div>
                <button class="alarm_rollback">
                    <div class="rollback_img"></div>
                </button>
            </div>-->
        </div>
    </div>
</template>

<script>
import dateMixins from "@/mixins/dateMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
export default {
    name: "AlarmListDefaultLayout",
    mixins: [dateMixins,alertMixins],
    components: {},
    inject: ['setAlarmListData'],
    provide() {
        return {}
    },
    props: {
        alarmData: [],
        paginationData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        classObject(type){
            switch (type) {
                case 'A_01': case 'A_02': case 'E_01': case 'E_02': case 'C_07': case 'F_03':
                case 'F_05': case 'F_07': case 'G_02': case 'G_04': case 'H_02': case 'I_01': case 'I_02':
                    return '';
                    break;
                case 'B_01': case 'B_02': case 'D_03': case 'C_05': case 'C_06': case 'C_08':
                case 'C_09': case 'C_10': case 'F_01': case 'F_09': case 'G_01': case 'G_07': case 'G_08':
                case 'H_01': case 'Q_01':
                    return 'check_img';
                    break;
                default:
                    return 'cancel_img';
            }
        },
        movePage(url) {
            if(!util.isEmpty(url)){
                this.$router.push(url)
            }
        },
        classAddClick(url){
            if(!util.isEmpty(url)){
                return 'pointer';
            }else{
                return false;
            }
        },
        deleteAlaram(key){
            if(!util.isEmpty(key)) {
              this.createConfirm({
                content: `${this.$t('delete_confirm')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                  EventBus.$emit('deleteAlarm',key)
                },
                cancel: () => {
                }
              })
            }
        }
    },
    watch: {},
}
</script>

<style scoped>

</style>
