<template>
    <div>
<!--        <creator-detail-tabs :nowTab="'premium'" :memberKey="memberKey"></creator-detail-tabs>-->
        <item-list-component :item-type="'premiumUser'" :member-key="memberKey"></item-list-component>
    </div>
</template>
<script>
// import CurationHistoryModal from "@/components/modal/CurationHistoryModal";
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "CreatorDetailPremium",
    mixins: [],
    components: {
        // CurationHistoryModal,
        ItemListComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            memberKey: this.$route.params.idx,
            tab: 'premiumUser',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        // this.memberKey = this.$route.params.idx;
        //this.memberKey = this.$route.params.idx;
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
