<template>
    <div class="profile_setting_wrap">

        <div class="profile_bg_wrap">
            <h3 class="p_setting_title">
                {{ $t('profile_background') }}
                <button class="reset_btn bg" @click="[changeType('backgroundFile'), handleFileRemove(backgroundFile[0], [])]"
                        v-if="backgroundFile.length > 0">{{$t('reset')}}</button>
            </h3>

            <div class="profile_bg ba0" style="background-image: none;" @click="[changeType('backgroundFile')]">
                <el-upload
                    action=""
                    class="img_wrap w100p"
                    list-type="picture-card"
                    accept="image/png, image/jpeg"
                    :file-list="backgroundFile"
                    :auto-upload="false"
                    :show-file-list="false"
                    :on-change="onlyOnePhotoChange"
                >
                    <button class="profile_bg" v-if="backgroundFile.length === 0"></button>
                    <button v-else class="profile_bg" style="background-image: none;">
                        <img :src="backgroundFile[0].org_url" v-if="backgroundFile.length > 0" alt="">
                    </button>
                </el-upload>
            </div>

            <div class="profile_img_box">
                <div class="profile_img ba0" style="background-image: none;" @click="[changeType('profileFile')]">
                    <el-upload
                        action=""
                        class="img_wrap w100p"
                        list-type="picture-card"
                        :file-list="backgroundFile"
                        :auto-upload="false"
                        :show-file-list="false"
                        :on-change="onlyOnePhotoChange"
                    >
                        <button class="profile_img" v-if="profileFile.length === 0"></button>
                        <button v-else class="profile_img" style="background-image: none;">
                            <img :src="profileFile[0].org_url" v-if="profileFile.length > 0" alt="">
                        </button>
                    </el-upload>
                </div>
                <div class="profile_img_text">{{ $t('profile_image') }}</div>
                <button class="reset_btn" @click="[changeType('profileFile'), handleFileRemove(profileFile[0], [])]"
                        v-if="profileFile.length > 0">{{$t('reset')}}</button>
                <div class="clear"></div>
            </div>
        </div>

        <div class="sns_wrap">
            <h3 class="p_setting_title">SNS</h3>
            <div class="sns_input_box" v-for="(urlValue, index) in urlList" :key="`snsUrl${index}`">
                <input class="sns_input first" placeholder="http://" type="text"
                       :class="{'other': urlSetOtherClass(index)}"
                       v-model="urlList[index]"
                       @input="setUrlData(index, urlList[index])"
                       />
<!--                <div class="delete_btn"></div>-->
                <button class="delete_btn" @click="minusUrl(index, urlList[index])"
                        v-if="urlList.length > 1"></button>
                <button class="plus_btn" @click="addUrl()"
                        v-if="index+1 === urlList.length && urlList.length < maxCnt"></button>
            </div>
<!--            <div class="sns_input_box">
                <input class="sns_input other" placeholder="http://" type="text"/>
                <div class="delete_btn"></div>
                <div class="plus_btn"></div>

            </div>-->
            <div class="validation" v-if="errorMsg != ''">{{ errorMsg }}</div>
            <div class="validation" v-else-if="validation.hasError('sns1')">{{
                    validation.firstError('sns1')
                }}
            </div>
            <div class="validation" v-else-if="validation.hasError('sns2')">{{
                    validation.firstError('sns2')
                }}
            </div>
            <div class="validation" v-else-if="validation.hasError('sns3')">{{
                    validation.firstError('sns3')
                }}
            </div>
        </div>

        <div class="intro_write_wrap">
            <h3 class="p_setting_title"> {{ $t('introduce_writer') }}</h3>
            <div class="intro_box">
                <el-input class="intro_textarea" maxLength="200" type="textarea" :placeholder="$t('intro_placeholder')"
                          v-model="introduce"
                          @input="parentChange('introduce', introduce)"></el-input>
            </div>
        </div>

        <div class="save_btn one_btn" @click="checkAllSetSns()">{{$t('save')}}</div>
    </div>
</template>

<script>
import elFileMixins from "@/mixins/elFileMixins";
import memberValidator from "@/mixins/validators/memberValidator";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import imageOption from "@/mixins/imageOption";

export default {
    name: "MemberWriteProfileSettingLayout",
    mixins: [elFileMixins, memberValidator, imageOption],
    components: {
    },
    inject: ['memberSetData'],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
            errorMsg: '',
            snsAddress1: '',
            snsAddress2: '',

            maxCnt: 3,
            urlList: [],
        }
    },
    beforeRouterEnter() {},
    created() {
        this.initSetData();
    },
    mounted() {
        EventBus.$on('MemberWriteProfileSettingLayoutValidator', this.runValidator)
    },
    beforeDestroy() {
        EventBus.$off('MemberWriteProfileSettingLayoutValidator')
    },
    destroyed() {},
    computed: {
    },
    methods:{
        initSetData() {
            console.log(this.memberData)
            if(util.isEmpty(this.memberData)) {
                return false;
            }
            this.introduce = this.memberData.mb_introduce;
            this.parentChange('introduce', this.introduce);
            if(!util.isEmpty(this.memberData.mb_sns_1)) {
                this.urlList.push(this.memberData.mb_sns_1);
                this.setUrlData(this.urlList.length-1, this.memberData.mb_sns_1)
            }
            if(!util.isEmpty(this.memberData.mb_sns_2)) {
                this.urlList.push(this.memberData.mb_sns_2);
                this.setUrlData(this.urlList.length-1, this.memberData.mb_sns_2)
            }
            if(!util.isEmpty(this.memberData.mb_sns_3)) {
                this.urlList.push(this.memberData.mb_sns_3);
                this.setUrlData(this.urlList.length-1, this.memberData.mb_sns_3)
            }
            if(this.urlList.length === 0) {
                this.urlList.push("");
            }
            if(util.isEmpty(this.memberData.Files)) {
                return false;
            }
            let memberFiles = this.memberData.Files;
            if(!util.isEmpty(memberFiles.Profile)) {
                let files = memberFiles.Profile.filter(file => !util.isEmpty(file.a_idx)).slice();
                if(files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(172, 172, 'jpg')}`;
                    this.profileFile = files;
                    //this.profileFile = memberFiles.Profile.filter(file => !util.isEmpty(file.a_idx));
                }

            }
            if(!util.isEmpty(memberFiles.ProfileBack)) {
                let files = memberFiles.ProfileBack.filter(file => !util.isEmpty(file.a_idx)).slice();
                if(files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(1162, 456, 'jpg')}`;
                    this.backgroundFile = files;
                    //this.backgroundFile = memberFiles.ProfileBack.filter(file => !util.isEmpty(file.a_idx));
                }
            }
            if(!util.isEmpty(memberFiles.ProfileThumb)) {
                let files = memberFiles.ProfileThumb.filter(file => !util.isEmpty(file.a_idx)).slice();
                if(files.length > 0) {
                    files[0].org_url = `${files[0].org_url}?${this.setImageOptions(218, 139, 'jpg')}`;
                    this.thumbnailFile = files;
                    //this.thumbnailFile = memberFiles.ProfileThumb.filter(file => !util.isEmpty(file.a_idx));
                }

            }
        },
        closeModal(name) {
            this.$modal.hide(name);
        },
        parentChange(type, value) {
            this.memberSetData(type, value)
        },
        urlSetOtherClass(index) {
            if(this.urlList.length === this.maxCnt) {
                return false;
            }
            return index > 0 && index+1 === this.urlList.length
        },
        addUrl() {
            this.urlList.push("");
            this.errorMsg = "";
        },
        minusUrl(index, value) {
            this.urlList.splice(index, 1);
            this.sns1 = "";
            this.sns2 = "";
            this.sns3 = "";
            util.Array.each(this.urlList, (url, index, arr) => {
                this[`sns${index+1}`] = url;
            })
            this.parentChange(`sns1`, this.sns1);
            this.parentChange(`sns2`, this.sns2);
            this.parentChange(`sns3`, this.sns3);
            this.errorMsg = "";
        },
        setUrlData(index, value) {
            let typeName = `sns${index+1}`;
            this[typeName] = value;
            this.parentChange(typeName, value);
            this.errorMsg = "";
        },
        runValidator(validatorArr) {
            this.$validate(['sns1', 'sns2', 'sns3']);
        },
        checkAllSetSns() {
            let success = true;
            if(this.urlList.length > 1) {
                util.Array.each(this.urlList, (url, index, arr) => {
                    if(util.isEmpty(url)) {
                        success = false;
                    }
                });
            }
            if(!success) {
                this.errorMsg = this.$t('sns_url_format_err1')
                return false;
            }
            this.runWrite()
        },
        runWrite() {
            EventBus.$emit('updateMember');
        }
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
