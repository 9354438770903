import util from "./util";

import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
    created() {
        this.categories = this.competitionCategoryList;
    },
    mounted() {

    },
    data() {
        return {
            categories: [],
        }
    },
    computed: {
        ...mapState({
            competitionCategoryList: state => state.competitionCategory.competitionCategoryList,
            Country: state => state.country.Country,
        })
    },
    methods: {
        setCategory(value) {
            console.log(value)
            this.parentChange('category', value);
            this.hashtag = [];
            this.hashData = '';
            this.hashInput = '';
            let categoryIndex = this.categories.findIndex(category => category.cc_idx === value);
        },
        returnCategoryName(value) {
            let category = this.categories.filter(category => category.cc_idx === value);
            if (this.Country === 'ko') {
                return !util.isEmpty(category) ? category[0].cc_name : ''
            } else if (this.Country === 'en') {
                return !util.isEmpty(category) ? category[0].cc_name_eng : ''

            }
        },

    }
}
