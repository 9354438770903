<template>
    <div class="gallery_detail_list_item_wrap">
      <div class="item_premium_list"  v-for="data in itemData" :key="`item${data.i_idx}`"  @click="movePage(data)">
        <div class="img_wrap">
          <div class="img_bg"  :style="` background-image: url(${returnThumbnail(data)});`">

          </div>
          <div class="art_card">{{ data.ic_name_eng }}</div>
        </div>
        <div class="content_wrap">
          <div class="subject_wrap">{{ data.i_name }}</div>
        </div>
      </div>

    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "ItemListCreationLayout",
    mixins: [imageOption, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, )}`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push(`/premiummarket/${data.i_sku}`)
            } else {
                this.$router.push(`/item/${data.i_sku}`);
            }
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
