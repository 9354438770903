<template>
  <div>
    <div class="search_title mt30 mb15">{{ $t('c_search') }} <span class="title_bold">882</span> 건</div>
    <div class="creator_list">
      <div class="creator_top mb6" v-for="i in 4" >
        <div class="creator_img">
          <div class="profile_background"></div>
          <div class="curator_icon">{{$t('en_curator')}}</div>
        </div>
        <div class="profile_info">
          <div class="profile_img"></div>
          <div class="profile_s_mark"></div>
          <div class="profile_text">jwo9500</div>
          <div class="creator_like">
            <img src="@/assets/image/heart_img.png">
            <div class="like_cnt">251</div>
            <div class="sub_point">.</div>
            <div class="item_subject">Item <span class="bold">12312</span></div>
          </div>
        </div>
      </div>
    </div>
    <div class="search_result_btn mb60">
      <button class="more_btn font_white"><img class="more_btn_img" src="@/assets/image/more_btn.png"> {{ $t('en_morebtn') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "MemberListSearchLayout",
  mixins: [],
  components: {},
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {}
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {},
  watch: {},
}
</script>

<style scoped>

</style>
