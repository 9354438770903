<template>
  <div class="competition_wrap pb24">
    <div class="competition_item application_item">
      <div class="competition_input">
        <el-input :placeholder="$t('st')" v-model="search_input">
        </el-input>
        <button class="competition_btn"></button>
      </div>
    </div>
    <div class="competition_btn_wrap">
      <button class="c_sort_btn ma0"></button>
      <button class="c_filter_btn ma0 ml13" @click="openFilter('item-filter-premium-my-filter')"></button>
    </div>
    <div class="clear"></div>
  </div>
</template>

<script>
export default {
  name: "EntryFilterDefaultLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      searchOption: 'ina',
      sortOption: 'i.updated_at',
      searchInput: '',
    }
  },
  beforeRouterEnter() {},
  created() {
    this.setterFilterDataInData();
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
    openFilter(name) {
      this.$modal.show(name);
    },
    setterFilterDataInData() {
      let classnames = Object.keys(this.filterData);
      classnames.forEach(classname => {
        let obj = this.filterData[classname];
        let type = filterConfig[obj.key].type;
        if (this.hasOwnProperty(classname)) {
          // this[classname] =
          if (type == 'query') {
            this[classname] = obj.query;
            if (this.hasOwnProperty(classname + 'Option')) {
              this[classname + 'Option'] = obj.key;
            }
          } else if (type === 'between') {
            this[classname] = [obj.start, obj.end];
          } else if (type === 'match') {
            if (isNaN(obj.value)) {
              this[classname] = obj.value;
            } else {
              this[classname] = Number(obj.value);
            }

          } else if (type === 'terms') {
            this[classname] = obj.terms;
          }

        }
      })
    },
    sortChange(data) {
      if (data === 'priceAsc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'asc');
      } else if (data === 'priceDesc') {
        this.setFilterFunc('obt', 'i_price');
        this.setFilterFunc('obs', 'desc');
      } else {
        this.setFilterFunc('obt', data);
        this.setFilterFunc('obs', 'desc');
      }
    },
    setFilterFunc(key, value) {
      this.setFilter(key, value);
    },
    setData(key, value) {
      this[key] = value;
    },
    openModal(name) {
      this.$modal.show(name);
    },
  },
  watch: {

  },
}
</script>

<style scoped>

</style>
