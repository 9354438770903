<template>
    <div>
        <div class="detail_middle_header">

            <div class="thumbnail_wrap">
                <ul>
                    <li @click="openModal('popup-modal',itemData)"><img :src="returnCollectImage(itemData)" alt=""></li>
                    <li @click="openModal('popup-modal',itemData)"><img :src="returnItemProfile(itemData,'originator')" alt=""></li>
                    <li @click="openModal('popup-modal',itemData)"><img :src="returnItemProfile(itemData,'provider')" alt=""></li>
                    <li @click="openModal('popup-modal',itemData)"><img :src="returnItemProfile(itemData,'curator')" v-if="checkCurator(itemData)" alt=""></li>
                </ul>
                <div class="time_wrap" v-if="itemData.i_status === 'auction'">
                    <div class="time_remain">{{ returnItemType(itemData) }}</div>
                </div>
            </div>

            <div class="subject_wrap">
                <h3 class="inline_block">{{ returnCategoryName(itemData) }}</h3>
                <p>{{ itemData.i_name }}</p>
                <span v-if="itemData.im_edition === 1">{{ $t('edition') }} {{
                        itemData.im_count
                    }} / {{ itemData.im_total }}</span>
            </div>

            <div class="hashtag_wrap">
                <ul>
                    <li v-for="(hash,index) in itemData.Hash" :key="`hash${index}`">
                        <span v-if="hash.ih_type === 1">#{{ returnCategoryDetailName(hash) }}</span>
                        <span v-else>#{{ hash.ih_hashtag }}</span>
                    </li>
                </ul>
            </div>
        </div>
        <item-detail-amount-layout v-if="!notShowAccount" :itemData="itemData"></item-detail-amount-layout>
        <popup-modal></popup-modal>
    </div>
</template>

<script>
import ItemDetailAmountLayout from "@/template/item/ItemDetailAmountLayout";
import PopupModal from "@/components/modal/PopupModal";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import AuthMixins from "@/mixins/AuthMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
export default {
    name: "ItemDetailSubjectLayout",
    mixins: [imageOption, dateMixins, AuthMixins],
    components: {
        PopupModal,
        ItemDetailAmountLayout
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
        notShowAccount: {default: false,}
    },
    data() {
        return {
            getDataStatus: false,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name, data) {
            this.$modal.show(name, {itemData: data});
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
            if (type != 'curator') {
                return 'http://testimage.minglemint.com/profile/no_image.png?w=45&h=45&f=png'
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    if(diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                        this.getDataStatus = true;
                        setTimeout(() => {
                            this.getDataStatus = false;
                            EventBus.$emit('getDetail');
                        }, 1000 * 30)
                    }
                    return diffTime;
                } else {
                    return data.ia_start_date;
                }
            }
            return '';
        },
        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            }else if(data.ic_idx === 178){
                return this.$t('prompt')
            }
        },
        checkUpdate() {
            if (util.isEmpty(this.itemData)) {
                return false
            }
            if (util.isEmpty(this.UserInfo)) {
                return false
            }
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
                return true
            }
            if (this.itemData.provider === this.UserInfo.mb_no) {
                return true
            }

        },
        checkDisabled(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.ibc_status) && data.ibc_status === 0) {
                return true;
            }
            return false;
        },
        movePage(url) {
            if(this.itemData.im_status === 6) {
                this.errorAlert(this.$t('item_status_err1')+'<br>'+this.$t('item_status_err2'));
                return;
            }
            this.$router.push(`${this.$route.path}/${url}`)
        },
        returnCategoryDetailName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.ic_name
            }
            return data.ic_name_eng
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
