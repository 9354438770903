<template>
    <modal name="competition-filter-default-filter" class="filter-modal competition-filter-default-filter"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

<!--                <div class="mt20 filter_modal_status">
                    <div class="modal_title">{{ $t('exposure') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterCategory"
                                               @change="setFilterFunc('ic', filterCategory)">
                                <el-checkbox :label="2">{{ $t('b_update') }}</el-checkbox>
                                <el-checkbox :label="9">{{ $t('b_enter_m') }}</el-checkbox>
                                <el-checkbox :label="3">{{ $t('b_like') }}</el-checkbox>
                                <el-checkbox :label="5">{{ $t('priceDesc') }}</el-checkbox>
                                <el-checkbox :label="4">{{ $t('priceAsc') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>-->

                <div class="mt20 filter_modal_status" v-if="tab == 'all'">
                    <div class="modal_title">{{ $t('category') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterCategory"
                                               @change="setFilterFunc('ccid', filterCategory)">
                                <el-checkbox v-for="data in competitionCategoryList"
                                             :key="`category${data.cc_idx}`" :label="data.cc_idx">
                                    {{ data.cc_name }}
                                </el-checkbox>
                                <!--                                            <el-checkbox :label="2" >{{ $t('art') }}</el-checkbox>-->
                                <!--                                            <el-checkbox :label="9" >{{ $t('collections') }}</el-checkbox>-->
                                <!--                                            <el-checkbox :label="3" >{{ $t('picture') }}</el-checkbox>-->
                                <!--                                            <el-checkbox :label="5" >{{ $t('music') }}</el-checkbox>-->
                                <!--                                            <el-checkbox :label="4">{{ $t('video') }}</el-checkbox>-->
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('status') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterStatus"
                                               @change="setFilterFunc('st', filterStatus)">
                                <el-checkbox :label="0">{{ $t('scheduled_to_proceed') }}</el-checkbox>
                                <el-checkbox :label="1">{{ $t('in_process2') }}</el-checkbox>
                                <el-checkbox :label="2">{{ $t('under_review') }}</el-checkbox>
                                <el-checkbox :label="3">{{ $t('p_completed') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('prize') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterPrice[0]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterPrice[1]"></el-input>
                            <span class="mg"> Mg</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="changeFilter">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>


    </modal>
</template>

<script>
import {mapState} from "vuex"
import util from "@/mixins/util";
import competitionUtil from "@/components/competition/competitionUtil";

const {filterConfig} = competitionUtil;
export default {
    name: "CompetitionFilterDefaultFilter",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort'],
    provide() {
        return {}
    },
    props: {
        modalLayout: '',
    },
    data() {
        return {
            component: null,
            filterData: {},
            tab: 'all',
            searchInputOption: 'ina',
            searchInput: '',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            filterPrice: ['', ''],
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            competitionCategoryList: state => state.competitionCategory.competitionCategoryList,
        })
    },
    methods: {
        handlerBeforeOpen(event) {
            if(!util.isEmpty(event.params.filterData)) {
                this.filterData = event.params.filterData;
            }else{
                this.filterData = {};
            }
            // this.sortData = event.params.sortData;
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('competition-filter-default-filter');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        changeFilter() {
            this.setFilterFunc('pr', this.filterPrice);
            this.modalClose();
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
