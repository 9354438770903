<template>
    <div class="container-utility-list">
        <div class="box-utility list" :class="{'disabled':returnDisalbe(data)}" v-for="(data,index) in utilityData"
             :key="`${index}boxUtility`" @click="$router.push(`/utility/${data.u_idx}`)">
            <div class="img-utility" :style="`background-image : url(${returnImg(data.UtilityImg[0])})`">
                <div class="category-utility">{{ data.uc_name }}</div>
                <div class="condition-utility" :class="returnState(data.u_is_use, data.u_use_start_date, data.u_use_end_date)">{{ $t(returnStateText(data.u_is_use, data.u_use_start_date, data.u_use_end_date)) }}</div>
            </div>
            <div class="wrap-content-utility">
                <div class="text-utility-top">Utility</div>
                <div class="title-utility">{{ data.u_name }}</div>
                <div class="wrap-profile-utility">
                    <div class="img-profile-utility" :style="`background-image : url(${returnImg(data.collectionImg[0])})`"></div>
                    <div class="text-profile-utility">{{ data.c_title }}</div>
                </div>
                <div class="wrap-info-utility">
                    <div class="text-cnt-utility"> {{ `${data.u_use_total}/${data.u_publish_total}` }}</div>
                    <div class="wrap-mingle-utility">
                        <div class="icon-mingle-utility"></div>
                        <div class="text-mingle-utility">{{ data. u_price }} Mg</div>
                    </div>
                    <div class="text-price-utility">({{ returnMingle(data.u_price) }})</div>
                </div>
            </div>
        </div>

    </div>

</template>


<script>
import util from "@/mixins/util";
import dateMixins from "@/mixins/dateMixins";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "UtilityListDefaultLayout.vue",
    mixins: [dateMixins, priceMixins],
    components: {},
    props: {
        utilityData: {
            default: () => []
        },

    },
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeUnmount() {
    },
    unmounted() {
    },
    computed: {},
    methods: {
        returnImg(data) {
            if (!util.isEmpty(data)) {
                return data.org_url;
            } else {
                return '';
            }
        },
        returnDisalbe(data) {
            let now = this.returnDateTime(new Date());

            if (!util.isEmpty(data) && (data.u_use_end_date < now || data.u_is_use == 0)) {
                return true;
            }
        },
        returnState(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state === 1) {
                if (start > now) {
                    return 'waiting'; // 사용대기
                } else if (start < now && end > now) {
                    return 'possible'; // 사용가능
                } else if (end < now) {
                    return 'already'; //기한만료
                }
            } else {
                return 'used'; // 사용완료
            }
        },
        returnStateText(state, start, end) {
            let now = this.returnDateTime(new Date());

            if (state === 1) {
                if (start > now) {
                    return 'use_await'; // 사용대기
                } else if (start < now && end > now) {
                    return 'use_possible'; // 사용가능
                } else if (end < now) {
                    return 'use_end_already'; //기한만료
                }
            } else {
                return 'use_fin'; // 사용완료
            }
        },
    },
    watch: {},
}
</script>
