<template>
    <div class="item_premium_list_wrap pa0">
        <div class="item_premium_list mt10" v-for="(data,index) in itemData" :key="`item${index}`" v-if="index<8">
            <div class="img_wrap">
                <div class="img_bg" :style="` background-image: url(${returnThumbnail(data)});`"
                     @click="movePage(data)"/>
                <div class="art_card">{{ data.ic_name_eng }}</div>
                <div class="real_card" v-if="data.i_type ==='real'">{{ $t('real') }}</div>
            </div>

            <div class="content_wrap">
                <div class="subject_wrap" @click="movePage(data)">{{ data.i_name }}</div>


                <div class="content_middle">
                    <ul class="middle_thumb" @click="openModal('popup-modal',data)">
                        <li class="thumb_wrap">
                            <img :src="returnCollectImage(data)" alt="">
                        </li>
                        <li class="thumb_wrap">
                            <img :src="returnItemProfile(data,'originator')" alt="">
                        </li>
                        <li class="thumb_wrap">
                            <img :src="returnItemProfile(data,'provider')" alt="">
                        </li>
                        <li class="thumb_wrap">
                            <img v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')" alt="">
                        </li>
                    </ul>


                    <div class="heart_wrap">
                        <div class="heart_box" @click="likeItem(data.i_idx)">
                            <img src="@/assets/image/s_heart_color.png"
                                 v-if=" isMyLike(data)" alt="">
                            <img src="@/assets/image/s_heart.png" v-else alt="">
                            <span class="count">{{
                                    data.hasOwnProperty('Like') ? data.im_real_like : data.im_real_like
                                }}</span>
                        </div>

                        <div class="eye_box" @click="moveDetail(data.i_sku)">
                            <img src="@/assets/image/s_eye.png" alt="">
                            <span class="count">{{ data.im_real_hit }}</span>
                        </div>
                    </div>
                </div>


                <div class="content_status" style="min-height: 20px">
                    <div class="premium_card" v-if="data.ibc_curator" @click="movePage(data)">Premium</div>
                    <div class="status_text" @click="movePage(data)">{{ returnItemType(data) }}</div>
                    <div class="clear"></div>
                </div>


                <div class="amount_wrap" @click="movePage(data)">
                    <div class="mg_icon search_mingle"></div>
                    <div class="amount_box search_mingle">{{ returnCommas(data.i_price) }}</div>
                    <div class="mg search_mingle">Mg</div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import EventBus from "@/utils/event-bus";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListSearchlistLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: [],
        paginationData: ''
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        getItemInterval = setInterval(() => {
            EventBus.$emit('searchGetList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        returnCommas(x = 0) {
            //return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return util.Number.numFormat(x);
        },
        movePage(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                this.$router.push('/premiummarket/' + data.i_sku)
            } else {
                this.$router.push('/item/' + data.i_sku);
            }
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return data.ItemFile.Item[0].org_url
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
            return 'http://testimage.minglemint.com/profile/no_image.png?w=40&h=40&f=png'
        },


        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            return diffTime;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        openModal(name, data) {
            this.$modal.show(name, {itemData: data});
        },
        likeItem(data) {
            EventBus.$emit('like', data, 'item');
        },
        isMyLike(data) {
            return data?.Like?.myLike
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
