<template>
  <div>
      <page-header :header-title="` `" :header_logo="true" :header_side="true">
          <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
      </page-header>
      <page-header class="sub_header"></page-header>
    <div class="dgm_wrap">
      <competition-list-component :competition-type="'entryList'"
                                  :competition-key="competitionKey"></competition-list-component>
    </div>
  </div>
</template>

<script>
import CompetitionListComponent from "@/components/competition/CompetitionListComponent";
import {mapState} from "vuex";
import EventBus from "@/utils/event-bus";

export default {
  name: "EntryList",
  mixins: [],
  components: {
    CompetitionListComponent
  },
  inject: [],
  provide() {
    return {}
  },
  props: {},
  data() {
    return {
      competitionKey: this.$route.params.cid,
      organizer: this.$route.params.mb_no
    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
    EventBus.$on('competitionVueSetData', this.setData)
  },
  beforeDestroy() {
    EventBus.$off('competitionVueSetData')
  },
  destroyed() {
  },
  computed: {
    ...mapState({
      UserInfo: state => state.userInfo.UserInfo,
    }),
    isOrganizer() {
      return this.UserInfo.mb_no === Number(this.organizer)
    },

  },
  methods: {
    movePage(url) {
      this.$router.push(url);
    },
    setData(key, value) {
      this[key] = value
    }

  },
  watch: {},
}
</script>

<style scoped>

</style>
