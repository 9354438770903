<template>
    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @input="setData('searchInput',searchInput)"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
            </el-input>
            <button class="search_btn mypage_search_btn"  @click="setFilterFunc(searchInputOption,searchInput)"></button>
        </div>
        <button class="search_filter_btn" @click="openFilter('competition-filter-my-filter')"></button>
        <competition-filter-my-filter></competition-filter-my-filter>
    </div>
</template>

<script>
import CompetitionFilterMyFilter from "@/components/filter/CompetitionFilterMyFilter";
import competitionUtil from "@/components/competition/competitionUtil";

const {filterConfig} = competitionUtil;
export default {
    name: "CompetitionFilterMyLayout",
    mixins: [],
    components: {CompetitionFilterMyFilter},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            searchInputOption: 'na',
            searchInput: '',
            filterCategory: [],
            filterDate: [],
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openModal(name) {
            this.$modal.show(name);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
