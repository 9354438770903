<template>
    <div class="mypage_list_wrap border-top">
        <div class="mypage_list" v-for="(competition, index) in competitionData"
             :key="`competition-my-${index}`">
            <div class="item_information">
                <div class="list_time" @click="movePage(`/item/${competition.Item.i_sku}`)">
                    {{ returnDateTime(competition.created_at) }}
                </div>
                <div class="list_download competition_download" v-if="!checkDownloadDate(competition)"
                     @click="awsS3Download('original',competition.Item.ItemFile.NFT[0],competition.Item.i_idx,true)">
                    <competition-release-time :competition-data="competition"></competition-release-time>
                </div>
            </div>
            <div class="item_img_wrap mt11">
                <div class="item_img">
                    <img :src="returnImage(competition.Item.ItemFile.Item,142,90)"
                         @click="movePage(`/item/${competition.Item.i_sku}`)">
                </div>
                <div class="item_text_list">
                    <div class="item_text bold" @click="movePage(`/item/${competition.Item.i_sku}`)">[
                        {{ returnStatus(competition.ci_status) }} ·
                        {{ competition.c_title }}]
                    </div>
                    <div class="item_text" @click="movePage(`/item/${competition.Item.i_sku}`)">
                        {{ competition.Item.i_name }}
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import CompetitionDateTime from "@/components/competition/CompetitionDateTime";
import CompetitionReleaseTime from "@/components/competition/CompetitionReleaseTime";
import fileMixins from "@/mixins/fileMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "CompetitionListMyLayout",
    mixins: [alertMixins, imageOption, fileMixins, awsS3FileDownload, priceMixins],
    components: {
        CompetitionReleaseTime,
        CompetitionDateTime
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        competitionData: {
            type: Object,
            default: () => {
                return {}
            }
        },
        paginationData: {default: {page: 1, size: 10, total: 0}},
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url)
        },
        returnImage(image, width = 32, height = 32) {
            if (!util.isEmpty(image)) {
                return `${image[0].org_url}?${this.setImageOptions(width, height, 'png')}`
            }
            return ''
        },
        returnApplyDate(data) {
            let s_time = this.$moment(data.c_start_time).format('YYYY-MM-DD')
            let e_time = this.$moment(data.c_end_time).format('YYYY-MM-DD')
            return `${s_time} ~ <br/> ${e_time}`
        },
        returnDateTime(time) {
            let r_date = this.$moment(time).format('YYYY-MM-DD HH:mm')
            return r_date;
        },
        returnStatus(status) {
            switch (status) {
                case 0 :{
                    return this.$t('scheduled_to_proceed')
                }
                case 1 :{
                    return this.$t('in_process2')
                }
                case 2 :{
                    return this.$t('under_review')
                }
                case 3 :{
                    return this.$t('p_completed')
                }
                default :{
                    return ''
                }
            }
        },
        tableIdx(index) {
            return this.paginationData.total - index - (this.paginationData.page - 1) * this.paginationData.size;
        },
        checkDownloadDate(competition){
            let current = this.$moment()
            let release = this.$moment(competition.c_release_time).add(10,'d')
            let diff = current.diff(release, 'day')
            return diff > 0
        },
        awsS3Download(type, name, key) {
            this.runDownloadFileInfo(type, name, key).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>


