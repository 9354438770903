<template>
    <div>
<!--        <page-header :header_back="true" :header_setting="true">
            <button slot="headerBtns" class="h_delete_btn" @click="openDeleteConfirm()"></button>
        </page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header has_btn">
            <button slot="headerBtns" v-if="itemData.isCanDelete" class="h_delete_btn" @click="openDeleteConfirm()"></button>
        </page-header>
        <!--      <item-write-component :item-type="'itemStatusUpdate'" :item-key="itemKey"></item-write-component>-->
        <item-status-component :itemKey="itemKey"></item-status-component>

    </div>
</template>

<script>
import alertMixins from "@/mixins/alertMixins";
import ItemWriteComponent from "@/components/item/ItemWriteComponent";
import ItemStatusComponent from "@/components/item/ItemStatusComponent";
import EventBus from "@/utils/event-bus";

export default {
    name: "ItemStatusUpdate",
    mixins: [alertMixins],
    components: {ItemWriteComponent, ItemStatusComponent},
    inject: [],
    provide() {
        return {
            setVueItemData: this.setData,
        }
    },
    props: {},
    data() {
        return {
            itemKey: this.$route.params.idx,
            itemClass: {},
            itemData:{},
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setData(key, value) {
            this[key] = value;
        },
        openDeleteConfirm() {
            this.createConfirm({
                content: `${this.$t('caution_del_item')}<br>${this.$t('caution_del')}`,
                confirmText: this.$t('confirm_del'),
                cancelText: this.$t('cancel'),
                confirm: () => {
                    this.deleteItem();
                },
                cancel: () => {
                }
            })
        },
        async deleteItem() {
            EventBus.$emit('apiLoading', true);
            let result = await this.itemClass.deleteItem(this.itemData.i_idx);
            if (result) {
                this.deleteItemFinish();
            } else {
                this.errorAlert(this.$t('deleteErrorMsg'));
            }
            EventBus.$emit('apiLoading', false);
        },
        deleteItemFinish() {
            this.createAlert({
                title: '',
                content: this.$t('deleteSuccessMsg'),
                btnText: this.$t('yes'),
                hide: () => {
                    this.$router.go(-2);
                },
            })
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
