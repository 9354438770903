<template>
    <modal name="member-filter-creator-filter" class="filter-modal member-filter-creator-filter"
           :width="`100%`" :height="`auto`" :pivotY="0.5"
           @before-close="handlerBeforeClose"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
    >
        <div>
            <div class="filter_modal_wrap">
                <div class="filter_modal_top">
                    <div class="filter_img"></div>
                    <div class="filter_name">{{ $t('filter') }}</div>
                    <div class="close_btn" @click="modalClose()"></div>
                </div>

<!--                <div class="mt20 filter_modal_status">
                    <div class="modal_title">{{ $t('exposure') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-radio-group v-model="sortOption"
                                               @change="sortChange(sortOption)">
                                <el-radio :label="'mb_item_update'">{{ $t('b_update') }}</el-radio>
                                <el-radio :label="'itemCnt'">{{ $t('b_item') }}</el-radio>
                                <el-radio :label="'likeCnt'">{{ $t('b_like') }}</el-radio>
                            </el-radio-group>
                        </div>
                    </div>
                </div>-->



                <div class="mt20 filter_modal_type">
                    <div class="modal_title">{{ $t('type') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterType"
                                               @change="setFilterFunc('pr', filterType)">
                                <el-checkbox :label="1">{{ $t('special') }}</el-checkbox>
                                <el-checkbox :label="0">{{ $t('normal') }}</el-checkbox>
                            </el-checkbox-group>
                        </div>

                    </div>
                </div>

                <div class="mt20 filter_modal_edition">
                    <div class="modal_title">{{ $t('curator') }}</div>
                    <div class="check_wrap">
                        <div class="checkbox inline_block">
                            <el-checkbox-group v-model="filterCurator"
                                               @change="setFilterFunc('gid', filterCurator)">
                                <el-checkbox :label="2" >{{$t('curator')}}</el-checkbox>
                                <el-checkbox :label="1" >{{$t('normal')}}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>

                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('item') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterItem[0]"></el-input>
                            <span class="mg"> {{$t('count')}}</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterItem[1]"></el-input>
                            <span class="mg"> {{$t('count')}}</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>

                <div class="mt20 filter_modal_price">
                    <div class="modal_title">{{ $t('like') }}</div>
                    <div class="price_wrap">

                        <div class="price_box">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterLike[0]"></el-input>
                            <span class="mg"> {{$t('count')}}</span>
                            <div class="clear"></div>
                        </div>

                        <span class="first_input_step">~</span>

                        <div class="price_box right">
                            <el-input class="el_price_input" type="number"
                                      v-model="filterLike[1]"></el-input>
                            <span class="mg"> {{$t('count')}}</span>
                            <div class="clear"></div>
                        </div>

                    </div>
                </div>

                <div class="wrapper_bottom">
                    <button class="" @click="runRangeFilter">{{ $t('apply2') }}</button>
                </div>

            </div>
        </div>
    </modal>
</template>

<script>
import memberUtil from "@/components/member/memberUtil";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import Filter from "@/mixins/filter/filter"

const {filterConfig} = memberUtil;
export default {
    name: "MemberFilterCreatorFilter",
    mixins: [],
    components: {},
    inject: ['setFilter', 'setSort', 'setMemberListData'],
    provide() {
        return {}
    },
    props: {

    },
    data() {
        return {
            sortData: {},
            filterData: {},
            filterSet: {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}},
            searchInputOption: 'ina',
            sort: '',
            sortOption: 'mb_item_update',
            filterCategory: [],
            filterType: [],
            filterCurator: [],
            filterItem: ['', ''],
            filterLike: ['', ''],
            filterClass: new Filter(),
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.filterClass.setterFilterConfigs(filterConfig);
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        handlerBeforeOpen(event) {
            console.log(event.params.hasOwnProperty('sortData'))
            if(event.params.hasOwnProperty('sortData')) {
                this.sortData = event.params.sortData;
            }
            if(event.params.hasOwnProperty('filterData')) {
                this.filterData = event.params.filterData;
            }
            if(event.params.hasOwnProperty('filterSet')) {
                this.filterSet = event.params.filterSet;
            }else{
                this.filterSet = {filter: {}, page: {page: 1, size: 1, total: 0}, sort: {}};
            }
            this.setterFilterDataInData();
        },
        handlerOpened() {
        },
        handlerBeforeClose() {
        },
        modalClose() {
            this.$modal.hide('member-filter-creator-filter');
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
            this.filterClass.filter = Object.assign({}, this.filterSet.filter);
            if(!util.isEmpty(this.sortData.key)) {
                this.sortOption = this.sortData.key;
            }
        },

        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },

        sortChange(data) {
            this.setSort(data, 'desc')
        },

        runRangeFilter() {
            let filterSet = this.filterSet;
            filterSet.filter = this.filterClass.setterFilter('lic', this.filterLike);
            filterSet.filter = this.filterClass.setterFilter('itc', this.filterItem);
            filterSet.filter = this.filterClass.setterFilter('pr', this.filterType);
            filterSet.filter = this.filterClass.setterFilter('gid', this.filterCurator);
            filterSet.filter = this.filterClass.setterFilter(this.searchInputOption, this.searchInput)
            this.setMemberListData('filterSet', filterSet);
            this.setMemberListData('filterClass', this.filterClass);
            EventBus.$emit('memberListChangeSaveFilter')
            this.modalClose();
            // this.showFilter = false;
            // this.setFilterFunc('lic', this.filterLike)

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
