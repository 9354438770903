<template>
    <div class="gallery_list_wrap">
        <div class="gallery_item" v-for="(gallery, index) in galleryData" :key="`gallery${gallery.c_idx}`">
            <div class="setting_btn" @click="openEditModal(gallery.c_idx)"></div>
            <div class="img_box" @click="movePage(`/gallery/${gallery.c_idx}/edit`)">
                <img :src="returnImage(gallery)" alt="">
            </div>
            <div class="item_name_box" @click="movePage(`/gallery/${gallery.c_idx}/edit`)">
                <div class="item_name">{{ gallery.c_title }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";

export default {
    name: "GalleryListDefaultLayout",
    mixins: [imageOption],
    components: {},
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        galleryData: {default: () => []},
        paginationData: {default: {page: 1, size: 10, total: 0}}
    },
    data() {
        return{
            collectionLists: 3,
        }
    },
    beforeRouterEnter() {},
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        movePage(url) {
            this.$router.push(url);
        },
        openEditModal(key) {
            this.$modal.show('gallery-setting-modal', {galleryKey: key});
        },
        returnImage(gallery) {
            if(util.isEmpty(gallery) || util.isEmpty(gallery.Files) || util.isEmpty(gallery.Files.Background)) {
                return "";
            }
            return `${gallery.Files.Background[0].org_url}?${this.setImageOptions(378, 240, 'jpg')}`;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
