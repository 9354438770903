<template>
    <div>
<!--        <page-header :header_back="true"></page-header>-->
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <find-component :member-type="`tempPassword`"></find-component>
    </div>
</template>

<script>

import FindComponent from "@/components/auth/FindComponent";

export default {
    name: "FindPassWord",
    mixins: [],
    components: {
        FindComponent
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
