<template>
    <div>
        <div class="login_signup_input nickname_change mb25">
            <input type="text" class="register_email" :placeholder="$t('r_write_nick')" maxlength="20"
                   v-model="referrerNick"
                   @input="parentChange('referrerNick',referrerNick)"
                   @keypress.enter="save()"/>
            <!--       추천인 닉네임 체크 -->
            <div class="validation referrer" v-if="validation.hasError('referrerNick')">
                {{ validation.firstError('referrerNick') }}
            </div>
            <div class="validation referrer" v-else-if="this.nick == this.referrerNick">
              {{ this.$t('referrerErrorMsg1') }}
            </div>
            <div class="validation referrer" v-else-if="referrerNickChk">
                {{ $t('referrerErrorMsg') }}
            </div>
        </div>
        <button class="two_btn back_gray" @click="closeModal()">{{$t('cancel')}}</button>
        <button class="two_btn back_navy" @click="save()">{{$t('save')}}</button>
        <div class="clear"></div>
    </div>
</template>

<script>
import {mapState, mapGetters} from "vuex";
import alertMixins from "@/mixins/alertMixins";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import memberValidator from "@/mixins/validators/memberValidator";

export default {
    name: "MemberWriteReferrerLayout",
    mixins: [alertMixins, memberValidator],
    components: {},
    inject: ['memberSetData'],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            checkReferrer: false
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
        this.nick = this.UserInfo.mb_nick
        EventBus.$on('checkData', this.checkData);
    },
    beforeDestroy() {
        EventBus.$off('checkData');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        modalClose() {
            this.referrerNick = '';
            this.referrerNickChk = false;
            this.$modal.hide('mypage-referrer-modal')
        },
        save() {
            if(this.nick == this.referrerNick){
              this.errorAlert(this.$t('referrerErrorMsg1'));
              return;
            } else{
              EventBus.$emit('updateMember')
              return;
            }
        },
        checkData(type) {
            this.referrerNickChk = type;
        },
        parentChange(type, value) {
            this[type] = value;
            this.memberSetData(type, value);
        },
        closeModal(){
          console.log(123)
          EventBus.$emit('reffererAddModalClose')
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
