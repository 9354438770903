<template>
    <div class="inform_table_wrap">
        <el-collapse v-click-outside="onClickOutside">
            <el-collapse-item class="ev_content_box">
                <template slot="title">
                    <div class="amount">{{ $t('edition') }}</div>
                </template>
                <ul class="license_content_wrap table_wrap">
                    <div class="table_top">
                        <div class="table_top_div cursor" @click="editionSort('number','numberSort')">
                            {{ $t('edition') }}
                            <span class="one_sort_img"
                                  :class="{'arrow_up': numberSort === 'desc'}" v-if="sortType === 'number'"></span>
                        </div>
                        <div class="table_top_div cursor" @click="editionSort('type','typeSort')">{{ $t('type') }}
                            <span class="one_sort_img"
                                  :class="{'arrow_up': typeSort === 'desc'}" v-if="sortType === 'type'"></span>
                        </div>
                        <div class="table_top_div cursor" @click="editionSort('price','priceSort')">{{ $t('price2') }}
                            <span class="one_sort_img"
                                  :class="{'arrow_up': priceSort === 'desc'}" v-if="sortType === 'price'"></span>
                        </div>
                        <div class="table_top_div">{{ $t('owner') }}</div>
                        <div class="table_top_div">{{ $t('select') }}</div>
                    </div>
                    <div class="table_content">
                        <table >
                            <colgroup>
                                <col width="20%" />
                                <col width="20%" />
                                <col width="20%" />
                                <col width="20%" />
                                <col width="20%" />
                            </colgroup>
                            <tr class="table_list" v-for="(data,index) in editionList" :key="`edition${index}`">
                                <td class="table_list_div">{{ data.im_count }} of {{ data.im_total }}</td>
                                <td class="table_list_div">{{ returnItemType(data) }}</td>
                                <td class="table_list_div list_position">
                                    <div class="list_position">
                                        <div class="list_position_top">
                                            <img src="@/assets/image/mypage_mingle.png">
                                            <span>{{ returnCommas(data.i_price) }} MG</span>
                                        </div>
                                        <br/>
                                        <span class="list_position_bottom">({{ returnMingle(data.i_price) }})</span>
                                    </div>
                                </td>
                                <td class="table_list_div">{{ data.provider_nick }}</td>
                                <td class="table_list_div" @click="moveEdition(data.i_sku)">
                                    <button class="div_select">{{ $t('select') }}</button>
                                </td>
                            </tr>
                        </table>
                    </div>

                </ul>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import priceMixins from "@/mixins/priceMixins";

export default {
    name: "ItemEditionComponent",
    mixins: [alertMixins, priceMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemKey: '',
    },
    data() {
        return {
            editionList: [],
            numberSort: 'asc',
            typeSort: 'desc',
            priceSort: 'desc',
            sortType: 'number'
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getList();
    },
    mounted() {
    },
    beforeDestroy() {

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {

        getList() {
            if (util.isEmpty(this.itemKey) || this.itemKey === '') {
                return
            }
            let params = {
                ip: 0,
                sku: this.itemKey
            }
            this.$api.$item.getEdition(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type === 1) {
                    this.editionList = this.$_.orderBy(res.List, ['im_count'], ['asc'])
                }
            })
        },
        editionSort(type, key) {

            if (this[key] === 'asc') {
                this[key] = 'desc';
            } else {
                this[key] = 'asc';
            }
            this.sortType = type;
            if (type === 'number') {
                this.editionList = this.$_.orderBy(this.editionList, ['im_count'], [`${this[key]}`]);
                this.typeSort = 'desc';
                this.priceSort = 'desc';
            } else if (type === 'type') {
                this.editionList = this.$_.orderBy(this.editionList, ['im_status'], [`${this[key]}`]);
                this.numberSort = 'desc';
                this.priceSort = 'desc';
            } else if (type === 'price') {
                this.priceSortFunc(key);
                this.typeSort = 'desc';
                this.numberSort = 'desc';
            }
        },
        priceSortFunc(key) {
            let noSaleArr = this.editionList.filter((data) => {
                return data.i_status === 'nosale'
            })
            noSaleArr = this.$_.orderBy(noSaleArr, ['i_price'], [`${this[key]}`]);

            let licenseArr = this.editionList.filter((data) => {
                return data.i_status === 'license'
            })
            licenseArr = this.$_.orderBy(licenseArr, ['i_price'], [`${this[key]}`]);

            let priceArr = this.editionList.filter((data) => {
                return data.i_status === 'aution' || data.i_status === 'pricetag'
            })
            priceArr = this.$_.orderBy(priceArr, ['i_price'], [`${this[key]}`]);

            this.editionList = priceArr.concat(licenseArr, noSaleArr);
        },

        returnItemType(data) {
            if (util.isEmpty(data)) {
                return
            }
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    return this.$t('auction_in_process')
                } else {
                    return this.$t('beforeAuction');
                }
            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return this.$t('no_sell')
            }
        },

        moveEdition(data) {
            let type = this.$route.name
            if (type === 'PremiumMarketDetail') {
                this.$router.replace(`/premiummarket/${data}?tab=item`);
                this.$router.go();
            } else if (type === 'OpenMarketDetail') {
                this.$router.replace(`/openmarket/${data}?tab=item`);
                this.$router.go();
            } else {
                this.$router.replace(`/item/${data}?tab=item`);
                this.$router.go();
            }
        },
        onClickOutside() {
            this.size = '';
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
