<template>
    <div>
        <div class="item_detail_wrap">

            <div class="item_detail_top">
                <item-detail-main-slide-layout :item-data="itemData"></item-detail-main-slide-layout>
            </div>

            <div class="item_detail_middle">
                <div class="detail_middle_header">

                    <div class="thumbnail_wrap">
                        <ul @click="openModal('popup-modal',itemData)">
                            <li><img :src="returnItemProfile(itemData,'originator')" alt=""></li>
                            <li><img :src="returnItemProfile(itemData,'provider')" alt=""></li>
                            <li><img :src="returnItemProfile(itemData,'curator')" v-if="checkCurator(itemData)" alt="">
                            </li>
                        </ul>
                        <div class="time_wrap" v-if="itemData.i_status === 'auction'">
                            <div class="time_remain">{{ returnItemType(itemData) }}</div>
                        </div>
                    </div>

                    <div class="subject_wrap">
                        <h3>{{ returnCategoryName(itemData) }}</h3>
                        <p>{{ itemData.i_name }}</p>
                        <span v-if="itemData.im_edition === 1">{{ $t('edition') }} {{
                                itemData.im_count
                            }} / {{ itemData.im_total }}</span>
                    </div>

                    <div class="hashtag_wrap">
                        <ul>
                            <li v-for="(hash,index) in itemData.Hash" :key="`hash${index}`">
                                <span v-if="hash.ih_type === 1">#{{ hash.ic_name }}</span>
                                <span v-else>#{{ hash.ih_hashtag }}</span>
                            </li>
                        </ul>
                    </div>
                </div>

                <div class="detail_middle_content">
                    <ul v-if="itemData.i_status === 'auction'">
                        <li class="min_bid_wrap" v-if="itemData.ia_is_minimum === 1">
                            <h3 class="regular">{{ $t('mini_bid') }}</h3>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.ia_minimum) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.ia_minimum) }})</div>
                            </div>
                        </li>

                        <li class="" :class="{'max_bid_wrap': itemData.ia_is_minimum === 1}">
                            <h3 class="regular">{{ $t('max_bid') }}</h3>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.i_price) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.i_price) }})</div>
                            </div>
                        </li>

                        <li class="buy_now_wrap" v-if="itemData.ia_is_immediately === 1">
                            <h3 class="regular">{{ $t('i_buy') }}</h3>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.ia_immediately) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.ia_immediately) }})</div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="detail_middle_license_content">
                    <ul>
                        <li class="bid_amount_wrap">
                            <div class="bid_amount">
                                <h3 class="bid_text">{{ $t('a_bid') }}</h3>
                                <input type="tel"
                                       placeholder=""
                                       v-model="bid_price"
                                       @input="checkMaxNumber('bid_price',bid_price)">
                                <div class="mg">Mg</div>
                            </div>
                            <div class="caption_check">
                                <div class="mt10 ml20"
                                     v-if="validation.hasError('bid_price')"><p>{{
                                        validation.firstError('bid_price')
                                    }}</p></div>
                            </div>

                            <div class="ba_bottom">
                                <h3 class="ba_text tax_text" @click="openModal('terms-modal', 'fees')">{{
                                        $t('f_tax')
                                    }}</h3>
                                <div class="amount_wrap">
                                    <div class="mg_icon"></div>
                                    <div class="amount">{{ setFeesData(bid_price) }}</div>
                                    <div class="mg">Mg</div>
                                    <div class="item_small">({{ returnMingle(fees) }})</div>
                                </div>
                            </div>
                            <div class="ba_bottom f_bid_wrap">
                                <h3 class="ba_text">{{ $t('f_bid') }}</h3>
                                <div class="amount_wrap">
                                    <div class="mg_icon"></div>
                                    <div class="amount">{{ returnTotalPrice() }}</div>
                                    <div class="mg">Mg</div>
                                    <div class="item_small">({{ returnMingle(returnTotalPrice()) }})</div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="check_wrap mb20 mt30" v-if="checkMemberType()">
                    <el-checkbox v-model="termsCheck" @click="changeTerms">
                                            <span class="item_text_span bold"
                                                  @click="openModal('terms-modal', 'auctionBuy')">{{
                                                    $t('terms_bid')
                                                }}</span>
                        <span v-html="$t('agree_terms2')"></span>
                    </el-checkbox>
                    <!--                    <span class="check_span"></span>-->
                </div>

                <div class="detail_middle_footer" v-if="checkMemberType()">
                    <div class="two_btn history_btn" @click="backEvent">{{ $t('cancel') }}</div>
                    <div class="two_btn bid_btn" @click="buyItem()">{{ $t('bid') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import {mapState} from "vuex";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import priceMixins from "@/mixins/priceMixins";
import itemValidator from "@/mixins/validators/itemValidator";
import AuthMixins from "@/mixins/AuthMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import ItemDetailMainSlideLayout from "@/template/item/detail/ItemDetailMainSlideLayout";

let timeInterval = null;
export default {
    name: "ItemDetailBidLayout",
    mixins: [imageOption, dateMixins, priceMixins, itemValidator, AuthMixins, imageResizeMixins],
    components: {
        ItemDetailMainSlideLayout,
        Swiper,
        SwiperSlide,
    },
    inject: ['itemDetailSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {

            visible: false,
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
            },
            memberData: {},
            fees: '',
            termsCheck: false,
            termsMsgType: false,
            errorMsgType: false,
            errorMsg: '',
            bid_priceCheck: false,
            currentTime: '',
            inputMsgType: false,

            getDataStatus: false,
        }
    },
    beforeRouterEnter() {

    },
    created() {
        this.initItemData();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        EventBus.$on('ItemDetailBidLayoutValidator', this.checkValidator);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        EventBus.$off('ItemDetailBidLayoutValidator');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
        },
        initSetData() {
            this.parentChange('immediately', this.itemData.ia_minimum);
            this.parentChange('i_price', this.itemData.i_price);
        },
        parentChange(type, value) {
            this[type] = value;
            this.itemDetailSetData(type, value);
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },

        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
            return 'http://testimage.minglemint.com/profile/no_image.png?w=45&h=45&f=png'
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        returnItemType(data) {
            if (util.isEmpty(data.ia_end_date)) {
                return '';
            }
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
            if(diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                this.getDataStatus = true;
                setTimeout(() => {
                    this.getDataStatus = false;
                    EventBus.$emit('getDetail');
                }, 1000 * 30)
            }
            return diffTime;
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },

        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if (data.ic_idx === 178) {
                return this.$t('prompt')
            }
        },

        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        checkMemberType() {
            if (!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        buyItem() {
            if (!this.termsCheck) {
                this.termsMsgType = true;
                return;
            } else {
                this.termsMsgType = false;
            }
            let bid_price = this.returnMaxPrice(this.bid_price);

            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('checkValidator', 'auction', bid_price);
            }
        },
        setFeesData(price) {
            price = this.returnMaxPrice(price);
            this.fees = this.setFees(this.itemData, price);

            return util.Number.numFormat(this.fees);
        },
        setData(key, value) {
            this[key] = value;
        },
        returnTotalPrice() {
            let total = 0;
            let price = this.returnMaxPrice(this.bid_price)
            total = price + Number(this.fees);
            return this.returnCommas(total);
        },

        checkValidator(dataArr) {
            this.$validate(dataArr)
        },
        backEvent() {
            this.$router.back();
        },
        checkMaxNumber(type, value) {
            if (!util.isEmpty(value) && !isNaN(value)) {
                let price = util.Number.returnNumber(value);
                if (this.itemData.ia_is_immediately === 1 && price > this.itemData.ia_immediately) {
                    price = util.Number.returnNumber(this.itemData.ia_immediately)
                }
                this[type] = price;
            } else {
                this[type] = 0;
            }

        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },
    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData()
            }
        }
    },
}
</script>

<style scoped>

</style>
