<template>
    <modal name="play-video-modal" class="play-video-modal"
           :width="'90%'"
           :height="'90%'"
           :scrollable="true"
           @before-open="handlerBeforeOpen"
           @opened="handlerOpened"
           @before-close="handlerBeforeClose"
    >
        <div class="pa10 pt5  bc_white ">
            <div class="modal_title mb0">
                <span>{{ $t('video') }}</span>

                <div class="close_btn_wrap">
                    <button class="close_modal" @click="backEvent()"></button>
                </div>
            </div>
            <div class="play-video-modal-wrap">
                <video-component v-if="showVideo" :url="src" :type="videoType" :width="width" :height="height" :thumbnail="thumbnail"
                                 style="display: block; width: 100%; height: 100%;"></video-component>
            </div>

        </div>

    </modal>
</template>

<script>
import util from "@/mixins/util";
import fetch from "node-fetch";
import VideoComponent from "@/components/player/VideoComponent";

export default {
    name: "PlayVideoModal",
    mixins: [],
    components: {VideoComponent},
    props: {},
    inject: ['changeLoading'],
    provide(){
        return {
            videoVueSetData : this.setData
        }
    },
    data() {
        return {
            src: '',
            name: '',
            thumbnail: '',
            videoType:'application/x-mpegURL',
            showVideo: false,
            width: 0,
            height: 0,
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        backEvent() {
            this.$modal.hide('play-video-modal');
        },
        handlerBeforeOpen(event) {
            if (util.isEmpty(event.params) || util.isEmpty(event.params.src)) {
                event.stop();
            }

            this.src = event.params.src;
            this.name = event.params.name;
            this.thumbnail = event.params.thumbnail;
            this.videoType = this.returnVideoType(this.src)

        },
        handlerOpened() {
            this.widith = $('.play-video-modal .v--modal').innerWidth() - 20;
            this.height = $('.play-video-modal .v--modal').innerHeight() - 54;
            this.showVideo = true;
        },
        handlerBeforeClose() {
            this.src = "";
            this.thumbnail = "";
            this.showVideo = false;
        },
        returnVideoType(src){
            let typeIndex = src.lastIndexOf('.')
            let type = src.substring(typeIndex+1)
            if(type === 'm3u8'){
                return 'application/x-mpegURL'
            }
            return 'video/mp4'
        },
        setData(key,value){
            this[key] = value
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
