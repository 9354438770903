<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <page-header class="sub_header"></page-header>
        <div class="padding_side dgm_wrap">
            <div class="mypage_real_wrap">
                <div class="notice_tabs">
                    <button class="notice_item_3_tab  two_tab" :class="{'active':tab==='buy'}"
                            @click="changeTab('buy')">
                        {{ $t('real') }} {{ $t('buy') }}
                    </button>
                    <button class="notice_item_3_tab two_tab border_r_l" :class="{'active':tab==='sale'}"
                            @click="changeTab('sale')">
                        {{ $t('real') }} {{ $t('sell') }}
                    </button>
                </div>
                <item-list-component :item-type="'realBuy'" v-if="tab === 'buy'"></item-list-component>
                <item-list-component :item-type="'realSale'" v-else-if="tab === 'sale'"></item-list-component>
            </div>
        </div>
    </div>
</template>

<script>
import MyPageHeaderComponent from "@/components/mypage/MyPageHeaderComponent";
import ItemListComponent from "@/components/item/ItemListComponent";

export default {
    name: "MyPageReal",
    mixins: [],
    components: {
        ItemListComponent,
        MyPageHeaderComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            tab: 'buy',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        movePage(url) {
            this.$router.push(url);
        },
        changeTab(tab) {
            this.tab = '';
            this.$nextTick(() => {
                this.tab = tab
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
