<template>
    <div class="heart_box" @click="likeMember">
        <img src="@/assets/image/s_heart_color.png" v-if="isMyLike()">
        <img src="@/assets/image/s_heart.png" v-else>
        <span>{{ returnCommas(likeData.likeCnt) }}</span>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import util from "@/mixins/util";
import {mapState} from "vuex";
export default {
    name: "LikeMemberDetailLayout",
    mixins: [],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeData: {}
    },
    data() {
        return {
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {        
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })},
    methods: {
        likeMember() {
            if (!util.isEmpty(this.UserInfo)) {
                EventBus.$emit('likeMember');
            }
        },
        returnCommas(x=0) {
            if(util.isEmpty(x) || isNaN(x)) {
                return 0;
            }

            return util.Number.numFormat(x);
        },
        isMyLike(){
            return !util.isEmpty(this.likeData.likeStatus) && this.likeData.likeStatus;
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
