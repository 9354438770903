<template>
    <div class="item_status_wrap">
        <div class="i_status_top">
            <div class="i_status_img_box">
                <img src="@/assets/image/bid_img1.jpg" alt="">
            </div>
            <div class="i_status_inform_box">
                <ul>
                    <li>
                        <h3>{{$t('type')}}</h3>
                        <div class="left_text">{{$t('digital')}}</div>
                    </li>
                    <li>
                        <h3>{{$t('category')}}</h3>
                        <div class="left_text">{{$t('picture')}}</div>
                    </li>
                    <li>
                        <h3>{{$t('i_name')}}</h3>
                        <div class="left_text">{{$t('waikiki')}}</div>
                    </li>
                    <li>
                        <h3>{{$t('certi')}}</h3>
                        <div class="left_text file_name">보증서1.jpg</div>
                    </li>
                    <li>
                        <h3>{{$t('f_real')}}</h3>
                        <div class="left_text file_name">원본.png</div>
                    </li>
                    <li class="detail_info_box">
                        <h3>{{$t('detail_info')}}</h3>
                        <ul>
                            <li>
                                <h4>{{$t('t_video2')}}</h4>
                                <div>04/02/2021/14:52:37 +0000</div>
                            </li>
                            <li>
                                <h4>{{$t('v_country2')}}</h4>
                                <div>South of Korea</div>
                            </li>
                            <li>
                                <h4>{{$t('v_si2')}}</h4>
                                <div>Gyeongsangbuk-do</div>
                            </li>
                            <li>
                                <h4>{{$t('v_city2')}}</h4>
                                <div>Gyeongju-si</div>
                            </li>
                            <li>
                                <h4>{{$t('size2')}}</h4>
                                <div>4131 * 6196 px</div>
                            </li>
                            <li>
                                <h4>{{$t('copyright_info2')}}</h4>
                                <div>Copyright 2017, Zava-queen</div>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>

        <div class="i_status_middle">
            <div class="i_inform_wrap">
                <h3>{{$t('info_item')}}</h3>
                <ul class="i_list_wrap">

                    <!--            상세페이지 썸네일-->
                    <li class="detail_thumb_wrap">
                        <h3 class="sub_title_m regular">{{$t('d_thumb')}}</h3>
                        <div class="w_content_box">
                            <el-upload class="upload_box">
                                <div class="img_box"></div>
                                <button class="delete_btn"></button>
                            </el-upload>
                            <el-upload class="upload_box">
                                <div class="img_box"></div>
                            </el-upload>
                        </div>
                    </li>

                    <!--              성인인증 필요-->
                    <li class="adult_certi_wrap">
                        <h3 class="sub_title_m regular">{{$t('adult_in')}}</h3>
                        <div class="w_content_box">
                            <el-radio-group class="el_radio_box" v-model="adult">
                                <el-radio class="el_radio_btn" :label="1">{{$t('yes2')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="0">{{$t('no2')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </li>

                    <!--              해시태그-->
                    <li class="hashtag_wrap">
                        <h3 class="sub_title_m regular">{{$t('hashtag')}}</h3>
                        <div class="w_content_box">
                            <div class="content_name">
                                {{$t('d_category')}}
                            </div>

                            <div class="w_content">
                                <el-select class="hashtag_select" v-model="hash_select">
                                    <el-options>
                                        <el-option>
                                            <div>{{$t('family')}}</div>
                                        </el-option>
                                    </el-options>
                                </el-select>

                                <ul class="hash_tag_wrap">
                                    <li class="" v-for="item in hash_data">
                                        <div class="hashtag_box">
                                            <span class="hash_name">{{$t('family')}}</span>
                                        </div>
                                        <button class="delete_btn"></button>
                                    </li>
                                </ul>
                            </div>


                            <div class="w_self">
                                <div class="input_wrap">
                                    <el-input :placeholder="$t('w_hash')"></el-input>
                                    <div class="plus_btn"></div>
                                </div>

                                <ul class="hash_tag_wrap">
                                    <li class="" v-for="item in hash_data">
                                        <div class="hashtag_box">
                                            <span class="hash_name">{{$t('family')}}</span>
                                        </div>
                                        <button class="delete_btn"></button>
                                    </li>
                                </ul>
                                <div class="validation mt10"></div>
                            </div>
                        </div>
                    </li>

                    <!--              아이템 설명-->
                    <li class="item_inform_wrap">
                        <h3 class="sub_title_m regular">{{$t('i_inform')}}</h3>
                        <div class="">
<!--                            <el-input type="textarea" v-model="item_info"></el-input>-->

                            <quill-editor
                                ref="contentEditor"
                                v-model="content"
                                :options="editorOption"
                            />
                            <div class="validation mt10">
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div class="i_status_bottom">
            <div class="i_sell_wrap">
                <h3>{{$t('sell_status')}}</h3>
                <ul class="i_list_wrap">

                    <li class="sell_status_wrap">
                        <h3 class="sub_title_m regular">{{$t('status')}}</h3>
                        <div class="w_content_box">
                            <el-radio-group class="el_radio_box" v-model="status">
                                <el-radio class="el_radio_btn" :label="1">{{$t('auction')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="2">{{$t('licenseSell')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="3">{{$t('fix_selling')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="4">{{$t('no_sell')}}</el-radio>
                            </el-radio-group>
                        </div>
                    </li>

                    <li class="min_bid_wrap">
                        <h3 class="sub_title_m regular">{{$t('mini_bid')}}</h3>
                        <div class="w_content_box">
                            <el-radio-group class="el_radio_box" v-model="min_bid">
                                <el-radio class="el_radio_btn" :label="1">{{$t('setting')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="2">{{$t('no_setting')}}</el-radio>
                            </el-radio-group>
                            <div class="input_wrap">
                                <h3>{{$t('mini_bid')}}</h3>
                                <div class="input_box">
                                    <input type="number" placeholder="0">
                                    <div class="mg">Mg</div>
                                </div>
                            </div>
                            <div class="validation mt10">
                                {{$t('itemErrorMsg9')}}
                            </div>
                        </div>
                    </li>

                    <li class="buy_now_wrap">
                        <h3 class="sub_title_m regular">{{$t('i_buy')}}</h3>
                        <div class="w_content_box">
                            <el-radio-group class="el_radio_box" v-model="buy_now">
                                <el-radio class="el_radio_btn" :label="1">{{$t('setting')}}</el-radio>
                                <el-radio class="el_radio_btn" :label="2">{{$t('no_setting')}}</el-radio>
                            </el-radio-group>
                            <div class="input_wrap">
                                <h3>{{$t('i_buy')}}</h3>
                                <div class="input_box">
                                    <input type="number" placeholder="0">
                                    <div class="mg">Mg</div>
                                </div>
                            </div>
                            <div class="validation mt10">
                                {{$t('itemErrorMsg10')}}
                            </div>
                        </div>
                    </li>

                    <li class="auction_date_wrap">
                        <h3 class="sub_title_m regular">{{$t('start_bid')}}</h3>
                        <div class="w_content_box">
                            <div class="input_wrap">
                                <h3>{{$t('start_bid')}}</h3>
                                <el-date-picker
                                        class="range_time"
                                        v-model="inputDate"
                                        type="datetime"
                                        format="yyyy-MM-DD HH:MM"
                                        placeholder="YYYY-MM-DD HH:MM">
                                </el-date-picker>
                            </div>

                            <div class="validation mt10">
                                {{$t('itemErrorMsg11')}}
                            </div>
                        </div>
                    </li>

                    <li class="auction_date_wrap">
                        <h3 class="sub_title_m regular">{{$t('end_bid')}}</h3>
                        <div class="w_content_box">
                            <div class="input_wrap">
                                <h3>{{$t('end_bid')}}</h3>
                                <el-date-picker
                                        class="range_time"
                                        v-model="inputDate"
                                        type="datetime"
                                        format="yyyy-MM-DD HH:MM"
                                        placeholder="YYYY-MM-DD HH:MM">
                                </el-date-picker>
                            </div>

                            <div class="validation mt10">
                                {{$t('itemErrorMsg13')}}
                            </div>
                        </div>
                    </li>

                    <!--              라이선스 판매 클릭 시-->
                    <li class="sell_price_wrap">
                        <h3 class="sub_title_m regular">{{$t('selling_price')}}</h3>
                        <div class="w_content_box">
                            <div class="input_wrap">
                                <h3>Original</h3>
                                <p class="size">0000 x 0000 px (00.00 x 00.00 in) 000dpi | 00.0MP</p>
                                <div class="input_box">
                                    <input type="number" placeholder="0">
                                    <div class="mg">Mg</div>
                                </div>
                            </div>
                            <div class="validation mt10">
                                {{$t('itemErrorMsg19')}}
                            </div>
                        </div>
                    </li>

                    <li class="license_caution_wrap">
                        <h3 class="sub_title_m regular">{{$t('caution_license')}}</h3>
                        <div class="w_content_box">
                            <div class="input_wrap">
                                <h3>{{$t('cautions')}}</h3>
                                <div class="input_box">
                                    <el-input type="textarea" :placeholder="$t('caution_license_input')"/>
                                </div>
                            </div>
                            <div class="validation mt10">
                                {{$t('error_license')}}
                            </div>
                        </div>
                    </li>

                </ul>
            </div>
        </div>

        <div class="i_status_footer">
            <div class="status_footer_check">
                <div class="check_wrap">
                    <el-checkbox v-model="termsCheck"></el-checkbox>
                  <span v-html="$t('status_terms')"></span>
<!--                    <span class="item_text_span bold">{{$t('bid_terms')}}</span>-->
<!--                    <span class="item_text_span ">{{$t('agree_terms2')}}</span>-->
                </div>
            </div>

            <div class="save_btn one_btn">{{$t('save')}}</div>
        </div>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import quillEditorMixins from "@/mixins/quillEditorMixins";
import {mapState} from "vuex";
import itemValidator from "@/mixins/validators/itemValidator";
import util from "@/mixins/util";
import elFileMixins from "@/mixins/elFileMixins";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import priceMixins from "@/mixins/priceMixins";
import imageOption from "@/mixins/imageOption";
import fetch from "node-fetch";
import axios from "axios";
import fileMixins from "@/mixins/fileMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";


export default {
    name: "ItemWriteStatusLayout",
    mixins: [quillEditorMixins, itemValidator, elFileMixins, alertMixins, imageOption, fileMixins, priceMixins, imageResizeMixins, awsS3FileDownload],
    components: {
        Swiper,
        SwiperSlide
    },
    inject: ['itemWriteSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemCategorys: {},
            detailCategorys: {},
            hashData: '',
            hashInput: '',
            tabChange: true,
            termsMsg: false,
            termsCheck: false,
            errorMsg: false,
            termsType: '',
            priceType: false,
            fileToken: '',
            startPickerOptions: {
                disabledDate: this.returnStartDate,
            },
            endPickerOptions: {
                disabledDate: this.returnEndDate,
            },
        }
    },
    beforeRouterEnter() {
    },
    created() {
        if (this.status === 1) {
            this.termsType = 'auctionSell';
        } else {
            this.termsType = 'licenseSell';
        }
        if (!util.isEmpty(this.itemData)) {
            this.initSetData();
        }
    },
    mounted() {
        this.itemCategorys = this.itemCategoryList;
        EventBus.$on('ItemWriteStatusLayoutValidator', this.checkValidator);
        window.scrollTo(0, 0)
    },
    beforeDestroy() {
        EventBus.$off('ItemWriteStatusLayoutValidator');

    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        initData() {
            this.minimumSetting = 0;
            this.parentChange('minimumSetting', this.minimumSetting);
            this.minimum = '';
            this.parentChange('minimum', this.minimum);
            this.immediatelySetting = 0;
            this.parentChange('immediatelySetting', this.immediatelySetting);
            this.immediately = '';
            this.parentChange('immediately', this.immediately);
            this.startDate = '';
            this.parentChange('startDate', this.startDate);
            this.endData = '';
            this.parentChange('endData', this.endData);
            this.smallPrice = '';
            this.parentChange('smallPrice', this.smallPrice);
            this.mediumPrice = '';
            this.parentChange('mediumPrice', this.mediumPrice);
            this.largePrice = '';
            this.parentChange('largePrice', this.largePrice);
            this.extraLargePrice = '';
            this.parentChange('extraLargePrice', this.extraLargePrice);
            this.originalPrice = '';
            this.parentChange('originalPrice', this.originalPrice);
            this.i_price = '';
            this.parentChange('i_price', this.i_price);
            this.caution = '';
            this.parentChange('caution', this.caution);
            this.termsCheck = false;
        },
        initSetData() {
            this.i_idx = this.itemData.i_idx;
            this.parentChange('i_idx', this.i_idx);
            this.title = this.itemData.i_name;
            this.parentChange('title', this.title);
            this.content = this.itemData.i_memo;
            this.content = util.htmlDecode(this.content);
            this.parentChange('content', this.content);
            this.type = this.itemData.i_type;
            this.parentChange('type', this.type);
            this.category = this.itemData.ic_idx;
            this.parentChange('category', this.category);
            let categoryIndex = this.itemCategoryList.findIndex(category => category.node_id === this.category);
            this.detailCategorys = this.itemCategoryList[categoryIndex];
            this.edition = this.itemData.im_edition;
            this.parentChange('edition', this.edition);
            this.count = this.itemData.im_count;
            this.parentChange('count', this.count);
            if (!util.isEmpty(this.itemData.im_total)) {
                this.total = this.itemData.im_total;
                this.parentChange('total', this.total);
            }
            this.i_status = this.itemData.i_status;
            this.parentChange('i_status', this.i_status);
            this.originator = this.itemData.im_originator;
            this.parentChange('originator', this.originator);
            this.im_type = this.itemData.im_type;
            this.parentChange('im_type', this.im_type);
            this.enroll = this.itemData.im_enroll;
            this.parentChange('enroll', this.enroll);
            this.adult = this.itemData.im_adult_chk;
            this.parentChange('adult', this.adult);
            this.size = this.itemData.imo_size;
            this.parentChange('size', this.size);
            if (this.itemData.im_type === 'photo') {
                let afterSize = this.size.split('*');
                this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
            }

            this.status = this.itemData.im_status;
            this.parentChange('status', this.status);
            //경매 데이터
            this.minimumSetting = this.itemData.ia_is_minimum;
            this.parentChange('minimumSetting', this.minimumSetting);
            this.minimum = this.itemData.ia_minimum;
            this.returnNumber('minimum', this.minimum);
            this.immediatelySetting = this.itemData.ia_is_immediately;
            this.parentChange('immediatelySetting', this.immediatelySetting);
            this.immediately = this.itemData.ia_immediately;
            this.returnNumber('immediately', this.immediately);
            this.startDate = this.itemData.ia_start_date;
            this.parentChange('startDate', this.startDate);
            this.endData = this.itemData.ia_end_date;
            this.parentChange('endData', this.endData);
            //라이센스 데이터
            this.caution = this.itemData.il_caution;
            this.parentChange('caution', this.caution);
            this.extraLargePrice = this.itemData.il_extra_large_price;
            this.returnNumber('extraLargePrice', this.extraLargePrice);
            this.largePrice = this.itemData.il_large_price;
            this.returnNumber('largePrice', this.largePrice);
            this.mediumPrice = this.itemData.il_medium_price;
            this.returnNumber('mediumPrice', this.mediumPrice);
            this.originalPrice = this.itemData.il_original_price;
            this.returnNumber('originalPrice', this.originalPrice);
            this.smallPrice = this.itemData.il_small_price;
            this.returnNumber('smallPrice', this.smallPrice);
            //지정가 데이터
            this.i_price = this.itemData.i_price;
            this.returnNumber('i_price', this.i_price);


            this.itemData.Hash.forEach(data => {
                let hash = {type: data.ih_type, tag: data.ih_hashtag}
                if (data.ih_type === 1) {
                    hash.tag = data.ic_idx;
                }
                this.hashtag.push(hash);
            })
            this.parentChange('hashtag', this.hashtag);

            if (!util.isEmpty(this.itemData.ItemFile.Detail)) {
                if (!util.isEmpty(this.itemData.ItemFile.Detail[0].a_idx)) {
                    this.detailThumnail = this.itemData.ItemFile.Detail;
                }
                this.parentChange('detailThumnail', this.detailThumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Guaranty)) {
                if (!util.isEmpty(this.itemData.ItemFile.Guaranty[0].a_idx)) {
                    this.guarantee = this.itemData.ItemFile.Guaranty;
                }
                this.parentChange('guarantee', this.guarantee);
            }
            if (!util.isEmpty(this.itemData.ItemFile.Item)) {
                if (!util.isEmpty(this.itemData.ItemFile.Item[0].a_idx)) {
                    this.thumnail = this.itemData.ItemFile.Item;
                }
                this.parentChange('thumnail', this.thumnail);
            }
            if (!util.isEmpty(this.itemData.ItemFile.NFT)) {
                if (!util.isEmpty(this.itemData.ItemFile.NFT[0].a_idx)) {
                    this.original = this.itemData.ItemFile.NFT;
                }
                this.parentChange('original', this.original);
            }
            if (this.itemData.ia_auction === 2) {
                this.termsCheck = true;
            }
        },

        movePage(url) {
            this.$router.push(url);
        },
        parentChange(type, value) {
            this[type] = value
            this.itemWriteSetData(type, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        returnStartDate(time) {
            if (!util.isEmpty(this.endData)) {
                return time.getTime() > this.$moment(this.endData) || time.getTime() < this.$moment();
            } else {
                return time.getTime() < this.$moment().add(-1,'day');
            }
        },
        returnEndDate(time) {
            if (!util.isEmpty(this.startDate)) {
                return time.getTime() <= this.$moment(this.startDate).add(-1,'day');
            } else {
                return time.getTime() < this.$moment().add(-1,'day');
            }

        },
        changeHash(key, value) {
            if (util.isEmpty(value)) {
                return false;
            }
            if (this.hashtag.length > 50) {
                this.errorAlert(this.$t('l_hash'));
                return;
            }
            let hash = {type: key, tag: value};
            if (key === 1) {
                this.hashData = value;
            }
            let hashIndex = this.hashtag.findIndex(category => category.tag === value);
            if (hashIndex > -1) {
                return
            }
            this.hashtag.push(hash);
            this.parentChange('hashtag', this.hashtag);
            this.hashInput = "";
        },
        removeHash(index) {
            this.hashtag.splice(index, 1);
        },
        setCategory(value) {
            this.parentChange('category', value);
            this.hashtag = [];
            this.hashData = '';
            this.hashInput = '';
            //this.detailCategorys = this.itemCategorys[value];
            let categoryIndex = this.itemCategorys.findIndex(category => category.node_id === value);
            if (!util.isEmpty(this.itemCategorys[categoryIndex].child)) {
                this.detailCategorys = this.itemCategorys[categoryIndex];
            } else {
                this.detailCategorys = [];
            }
        },
        returnHashTag(data) {
            let detailTag = Number(data.tag);
            let hashIndex = this.detailCategorys.child.findIndex(category => category.node_id === detailTag);
            if (hashIndex > -1) {
                return this.detailCategorys.child[hashIndex].NAME;
            }
        },

        hasFile(type) {
            return !util.isEmpty(this[type]);
        },
        changeFileType(type) {
            this.fileType = type;
        },
        checkValidator(dataArr) {
            this.$validate(dataArr).then(success => {
                if (!success) {
                    this.errorMsg = true;
                } else {
                    this.errorMsg = false;
                }
            })
        },

        removeList(type, index) {
            let files = this[type].splice(index, 1);
            if(!util.isEmpty(files[0].a_idx)) {
                this.deleteFiles.push(files[0])
                this.parentChange(`deleteFiles`, this.deleteFiles)
            }
            this.parentChange(`${type}`, this[type])
            this.$forceUpdate();
        },
        photoListChange(file, fileList) {
            if (this.detailThumnail.length > 9) {
                this.errorAlert(this.$t('l_img1'))
            } else {
                this.handleFileChange(file, fileList);
                this.$forceUpdate();
            }
        },
        returnCategoryName(data) {
            if (data === 2) {
                return `${this.$t('art')}`
            } else if (data === 3) {
                return `${this.$t('picture')}`
            } else if (data === 4) {
                return `${this.$t('video')}`
            } else if (data === 5) {
                return `${this.$t('music')}`
            } else if (data === 9) {
                return `${this.$t('collections')}`
            } else if (data === 178) {
                return this.$t('prompt')
            }
        },
        returnItemType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        returnProfile(data) {
            if (!util.isEmpty(data.profile.originator)) {
                return `${data.profile.originator[0].org_url}?${this.setImageOptions(25, 25, 'png')}`
            }
        },
        returnItemImg(data) {
            if (!util.isEmpty(data.ItemFile.Item)) {
                return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(730, null, 'png')}`
            }
        },
        openModal(data, type) {
            this.$modal.show(`${data}`, {termsType: type});
        },
        changeTerms() {
            this.termsCheck = !this.termsCheck;
        },
        save() {
            if (this.status !== 9 && !this.termsCheck) {
                this.termsMsg = true;
                return
            }
            this.termsMsg = false;
            EventBus.$emit('updateItem');
        },
        changeStatus() {
            this.initData();
            if (this.status === 1 || this.status === 2) {
                this.i_status = 'auction'
            } else if (this.status === 3) {
                this.i_status = 'license'
            } else if (this.status === 4 || this.status === 5) {
                this.i_status = 'pricetag'
            } else {
                this.i_status = 'nosale'
            }
            this.parentChange('i_status', this.i_status);
            this.parentChange('status', this.status);
        },

        checkFile(data) {
            return !util.isEmpty(data.a_idx);
        },
        deleteItem() {
            EventBus.$emit('deleteItem');
        },
        checkOriginal() {
            return !util.isEmpty(this.itemData.originator_nick)
        },

        checkCurator() {
            return util.isEmpty(this.itemData.ibc_curator)
        },
        dateChange(key, value) {
            let date = this.$moment(value).format('YYYY-MM-DD HH:mm')
            if (util.isEmpty(value)) {
                date = '';
            }

            this.parentChange(key, date)
        },
        returnNumber(type, price) {
            let number = this.returnMaxPrice(price);

            this.parentChange(type, number)
            this[type] = util.Number.numFormat(number);
        },
        awsS3Download(type, name, key, nft) {
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }

            })
        }
    },
    watch: {
        'content': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('content', this.content)
            },
        },
        'caution': {
            deep: true,
            handler: function (val, oldVal) {
                this.parentChange('caution', this.caution)
            },
        },
    },
}
</script>

<style scoped>

</style>
