<template>
  <div>
    <div class="detail_top_btn_wrap">
      <div class="share_wrap">
        <div class="share_img"></div>
      </div>
      <div class="heart_wrap">
        <div class="heart_count"><div class="heart" :class="{ 'on' : isClicked}" @click="isClicked = !isClicked"></div><span>251</span></div>
        <div class="eye_count"><div class="eye"></div><span>1,251</span></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ItemDetailHeartLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return {
    }
  },
  props: {
  },
  data() {
    return {

    }
  },
  beforeRouterEnter() {
  },
  created() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  destroyed() {
  },
  computed: {},
  methods: {

  },
  watch: {},
}
</script>

<style scoped>

</style>