<template>
    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @input="setData('searchInput',searchInput)"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
            </el-input>
            <button class="search_btn mypage_search_btn"  @click="setFilterFunc(searchInputOption,searchInput)"></button>
        </div>
        <button class="search_filter_btn" @click="openFilter('competition-filter-my-entry-item-filter')"></button>
        <competition-filter-my-entry-item-filter></competition-filter-my-entry-item-filter>
    </div>
<!--    <div class="search_item mb19">
        <div class="filter_category_box mr5">
            <el-select id="category" v-model="searchInputOption" class="my_filter select w150"
                       popper-class="select_style"
                       :popper-append-to-body="false"
            >
                <el-option :value="'na'" :label="$t('sub_item')"></el-option>
                <el-option :value="'hash'" :label="$t('item_hashtag')"></el-option>
                <el-option :value="'ti'" :label="$t('subject_comp')"></el-option>
                <el-option :value="'chash'" :label="$t('hashtag_comp')"></el-option>
                <el-option :value="'cni'" :label="$t('organizer')"></el-option>
            </el-select>
        </div>
        <div class="search_input mypage_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @input="setData('searchInputOption',searchInput)"
                      @keypress.enter.native="setFilterFunc(searchInputOption,searchInput)"/>
            <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)">
            </button>
        </div>
        <button class="search_filter_btn" @click="openFilter('competition-filter-my-entry-item-filter')"></button>
        <competition-filter-my-entry-item-filter></competition-filter-my-entry-item-filter>
    </div>-->
</template>


<script>
import util from "@/mixins/util";
import CompetitionFilterMyEntryItemFilter from "@/components/filter/CompetitionFilterMyEntryItemFilter";
import competitionUtil from "@/components/competition/competitionUtil";

const {filterConfig} = competitionUtil;
export default {
    name: "CompetitionFilterMyEntryItemLayout",
    mixins: [],
    components: {CompetitionFilterMyEntryItemFilter},
    inject: ['setFilter'],
    provide() {
        return {}
    },
    props: {
        filterData: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            searchInputOption: 'na',
            searchInput: '',
            filterCategory: [],
            filterDate: [],
            filterStatus: [],


        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        setData(key, value) {
            this[key] = value;
        },
        openModal(name) {
            this.$modal.show(name);
        },
        changeDateFilter() {
            if (util.isEmpty(this.filterDate)) {
                this.setFilter('cr', '');
            } else {
                let date = this.filterDate;
                date[0] = `${date[0]} 00:00:00`;
                date[1] = `${date[1]} 23:59:59`;
                this.setFilter('cr', date);
            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
