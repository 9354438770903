<template>
    <div>

        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <button slot="headerBtns" class="h_search_btn" @click="$router.push('/search')"></button>
        </page-header>
        <page-header class="sub_header"></page-header>
        <item-register-component :item-type="'itemCreate'"></item-register-component>
    </div>
</template>

<script>
import ItemRegisterComponent from "@/components/item/ItemRegisterComponent";
import ItemWriteComponent from "@/components/item/ItemWriteComponent";

export default {
    name: "ItemCreate",
    mixins: [],
    components: {
        ItemWriteComponent,
        ItemRegisterComponent,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {},
    data() {
        return {}
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {},
    watch: {},
}
</script>

<style scoped>

</style>
