<template>
    <div>
        <div class="item_premium_list_wrap">
            <!--            썸네일 한개씩 리스트 -->
            <div class="item_market_thumbnail_one_list" v-if="listType === 'thumbnail_one'"
                 v-for="(data,index) in itemData" :key="`item${index}`">
                <div class="img_wrap" :style="`background-image: url(${returnThumbnail(data)});`"
                     @click="moveDetail(data.i_sku)">
                    <div class="c_category_dup art_card">{{ data.ic_name_eng }}</div>
                    <div class="c_category_dup right_card">
                        <div class="play_card" v-if="data.im_type === 'video'|| data.im_type==='audio'">
                            <img class="icon play" src="@/assets/image/icon_play.png" alt="">
                        </div>
                        <div class="real_card" v-if="data.i_type ==='real'">
                            {{ $t('real') }}
                        </div>
                    </div>
                </div>

                <div class="content_wrap">
                    <div class="profile_group">
                        <div @click="openModal('popup-modal', data)">
                            <img class="card_profile" :src="returnCollectImage(data)" alt="">
                            <img class="card_profile" :src="returnItemProfile(data,'originator')" alt="">
                            <img class="card_profile" :src="returnItemProfile(data,'provider')" alt="">
                            <img class="card_profile" v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')" alt="">
                        </div>
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <div class="like_img" :class="{'active': isMyLike(data)}"
                                 @click="likeItem(data.i_idx)"></div>
                            <div class="icon_count" @click="likeItem(data.i_idx)">{{ data.im_real_like }}</div>
                        </div>
                        <div class="icon_wrap" @click="moveDetail(data.i_sku)">
                            <img class="icon eye" src="@/assets/image/eye_empty.png" alt="">
                            <div class="icon_count">{{ data.im_real_hit }}</div>
                        </div>
                    </div>
                    <div class="card_title card_tit_home" @click="moveDetail(data.i_sku)">{{ data.i_name }}</div>
                    <div class="premium" @click="moveDetail(data.i_sku)"
                         v-if="checkPremium(data)">premium</div>
                    <div class="item_status time" @click="moveDetail(data.i_sku)">{{ returnItemType(data) }}</div>
                    <div class="clear"></div>
                    <div class="item_price" @click="moveDetail(data.i_sku)">
                        <div class="price">{{ returnCommas(data.i_price) }} Mg</div>
                        <div class="item_small">{{ returnMingle(data.i_price) }}</div>
                    </div>

                </div>

            </div>
            <!--            썸네일 두개씩 리스트 -->
            <div class="item_openmarket_list" v-if="listType === 'thumbnail_two'"
                 v-for="(data,index) in itemData" :key="`item_two${index}`">
                <div class="img_wrap" :style="`background-image: url(${returnThumbnail(data)});`"
                     @click="moveDetail(data.i_sku)">
                    <div class="c_category_dup art_card">{{ data.ic_name_eng }}</div>
                    <div class="c_category_dup right_card">
                        <div class="play_card" v-if="data.im_type === 'video'|| data.im_type==='audio'">
                            <img class="icon play" src="@/assets/image/icon_play.png" alt="">
                        </div>
                        <div class="real_card" v-if="data.i_type ==='real'">
                            {{ $t('real') }}
                        </div>
                    </div>
                </div>

                <div class="content_wrap">
                    <div class="profile_group">
                        <div @click="openModal('popup-modal', data)">
                            <img class="card_profile" :src="returnCollectImage(data)" alt="">
                            <img class="card_profile" :src="returnItemProfile(data,'originator')" alt="">
                            <img class="card_profile" :src="returnItemProfile(data,'provider')" alt="">
                            <img class="card_profile" v-if="checkCurator(data)"
                                 :src="returnItemProfile(data,'curator')" alt="">
                        </div>
                    </div>
                    <div class="icon_group">
                        <div class="icon_wrap pr-10">
                            <div class="like_img" :class="{'active': isMyLike(data)}"
                                 @click="likeItem(data.i_idx)"></div>
                            <div class="icon_count" @click="likeItem(data.i_idx)">{{ data.im_real_like }}</div>
                        </div>
                        <div class="icon_wrap" @click="moveDetail(data.i_sku)">
                            <img class="icon eye" src="@/assets/image/eye_empty.png" alt="">
                            <div class="icon_count">{{ data.im_real_hit }}</div>
                        </div>
                    </div>
                    <div class="card_title card_tit_home" @click="moveDetail(data.i_sku)">{{ data.i_name }}</div>
                    <div class="premium" @click="moveDetail(data.i_sku)"
                        v-if="checkPremium(data)">premium</div>
                    <div class="item_status time" @click="moveDetail(data.i_sku)">{{ returnItemType(data) }}</div>
                    <div class="clear"></div>
                    <div class="item_price" @click="moveDetail(data.i_sku)">
                        <div class="price">{{ returnCommas(data.i_price) }} Mg</div><br/>
                        <div class="item_small">{{ returnMingle(data.i_price) }}</div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>


<script>
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import EventBus from "@/utils/event-bus";
import priceMixins from "@/mixins/priceMixins";

let timeInterval = null;
let getItemInterval = null;
export default {
    name: "ItemListOpenMarketLayout",
    mixins: [imageOption, dateMixins, imageResizeMixins, priceMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            memberCheck: false,
            currentTime: '',
            memberData: {},
            listType: 'thumbnail_one',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.getCurrentTime();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
        EventBus.$on('changeListType', this.changeListType);
        getItemInterval = setInterval(() => {
            EventBus.$emit('getItemList')
        }, 1000 * 60)
    },
    beforeDestroy() {
        clearInterval(timeInterval)
        clearInterval(getItemInterval)
        EventBus.$off('changeListType');
    },
    destroyed() {
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
        })
    },
    methods: {
        checkPremium(data) {
            if (data.im_status === 2 || data.im_status === 5 || data.im_status === 8) {
                return true
            }
            return false;
        },
        changeListType(type) {
            this.listType = type;
        },
        moveDetail(data) {
            this.$router.push(`/openmarket/${data}`)
        },
        returnThumbnail(data) {
            if (!util.isEmpty(data.ItemFile) && !util.isEmpty(data.ItemFile.Item)) {
                // return `${data.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return `${data.ItemFile.Item[0].org_url}?w=278?f=png`;
                return this.setFitWidthImg(data.ItemFile.Item, 278);
            }
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(40, 40, 'png')}`;
            }
            return 'http://testimage.minglemint.com/profile/no_image.png?w=40&h=40&f=png'
        },


        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(`/${url}`)
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    return diffTime;
                } else {
                    return this.$t('beforeAuction');
                }

            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                return ''
                // return this.$t('no_sell')
            }
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        openModal(name, data) {
            this.$modal.show(name, {itemData: data});
        },
        isMyLike(data) {
            return data?.Like?.myLike
        },
        likeItem(key) {
            EventBus.$emit('likeItem', key);
        },
        returnCollectImage(data) {
            if (!util.isEmpty(data.Collection) && !util.isEmpty(data.Collection.CollectionFiles) && !util.isEmpty(data.Collection.CollectionFiles.MainFile)) {
                return `${data.Collection.CollectionFiles.MainFile[0].org_url}?${this.setImageOptions(90, 90, 'webp')}`;
            }
        },

    },
    watch: {},
}
</script>

<style scoped>

</style>
