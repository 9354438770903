<template>
</template>

<script>
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import AuthMixins from "@/mixins/AuthMixins";
import util from "@/mixins/util";
export default {
    name: "AuthenticationResult",
    components: {},
    mixins: [alertMixins, AuthMixins],
    data() {
        return {

        }
    },
    created() {
        setTimeout(() => {
          let query = this.$route.query;
          this.getDecodeData(query);
        }, 500)
    },
    mounted() {
        EventBus.$emit('apiLoading', true);
    },
    beforeDestroy() {
        EventBus.$emit('apiLoading', false);
    },
    destroyed() {
    },
    computed: {},
    methods: {
        getDecodeData(data) {
            let params = {
                data: data.enc_data,
            }
            this.$localStorage.set('enc_data', data.enc_data);
            window.opener.parent.location= '/mypage/update';
            setTimeout(() => {
              window.close();
            }, 500)

        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
