<template>
    <div class="item_detail_wrap">

        <div class="item_detail_top pl20 pr20">
            <item-detail-main-slide-layout :item-data="itemData" :show-player="true"></item-detail-main-slide-layout>
            <like-component :likeType="'itemDetail'" :likeData="itemData"></like-component>
            <div class="left">
                <span class="type-sellings-nticket">{{ returnItemType(itemData) }}</span>
            </div>
        </div>

        <div class="item_detail_middle pl20 pr20">
            <item-detail-subject-layout :item-data="itemData"></item-detail-subject-layout>
            <div class="detail_middle_footer" v-if="itemData.i_status === 'auction' && itemData.ia_auction === 2 && checkMemberType()">
                <div class="two_btn history_btn" @click="openModal('auction-history-modal',itemData.m_idx)">
                    {{ $t('bid_history') }}
                </div>
                <div class="two_btn bid_btn" @click="checkLogin('bid')">{{ $t('bid') }}</div>
            </div>

            <div class="detail_middle_footer" v-if="itemData.i_status === 'auction' && itemData.ia_auction === 1">
                <div class="one_btn bid_btn">{{ $t('beforeAuction') }}</div>
            </div>

            <div class="detail_middle_footer" v-if="itemData.i_status === 'pricetag'">
                <div class="btn-common" v-if="checkMemberType()"
                     @click="checkLogin(`price`)">{{ $t('buy') }}
                </div>
            </div>

        </div>

        <div class="item_detail_footer">
            <div class="item_footer_coll  pl20 pr20">
                <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10 mb10 box-common">
                    <el-collapse-item class="ev_content_box">
                        <template slot="title">
                            <div class="amount">{{ $t('item') }}</div>
                        </template>
                        <div class="item_wrap">

                            <div class="item_description_wrap">
                                <div class="ql-editor" v-html="decodeHtml(replaceBr(itemData.i_memo))"></div>
                            </div>
                            <!--                            상세정보-->
                            <ul class="inform_detail_box">
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('type') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ returnType(itemData.i_type) }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="itemData.im_is_ai == 1">
                                    <div class="detail_tit">
                                        {{ $t('ai_create_check') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ $t('ai_create') }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('category') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ returnCategoryName(itemData) }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="checkItemData('im_originator')">
                                    <div class="detail_tit">
                                        {{ $t('k_creator') }}
                                    </div>
                                    <div class="detail_content">
                                        {{ itemData.originator_nick }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="itemData.isEdition === 1">
                                    <div class="detail_tit">
                                        {{ $t('edition') }}
                                    </div>
                                    <div class="detail_content" v-if="itemData.type === 'real'">
                                        {{ itemData.editionCount }} of {{ itemData.editionTotal }}
                                    </div>
                                    <div class="detail_content" v-else>
                                        {{ itemData.editionCount }}
                                    </div>
                                </li>
                                <li class="detail_list" v-if="itemData.hasOwnProperty('ItemFile')">
                                    <div class="detail_tit">
                                        {{ $t('certi') }}
                                    </div>
                                    <!--<div class="detail_content color_navy"
                                            v-for="(data,index) in itemData.ItemFile.Guaranty"
                                            :key="`guranty${index}`" v-if="checkImg(data)">
                                                {{ data.org_name }}
                                    </div>-->
                                    <div class="detail_content color_navy "
                                         :class="{'cursor': itemData.provider === UserInfo.mb_no, 'certi': index > 0}"
                                         v-if="checkImgData('Guaranty')"
                                         v-for="(data,index) in itemData.ItemFile.Guaranty"
                                         @click="awsS3Download('guaranty', data, itemData.i_idx)"
                                         :key="`guranty${index}`">
                                        {{ data.org_name }}
                                    </div>
                                    <div class="detail_content " v-else>
                                        {{ $t('no3') }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('f_real') }}
                                    </div>
                                    <div class="detail_content color_navy" v-if="checkImgData('NFT')">
                                        {{ itemData.ItemFile.NFT[0].a_filename }}
                                    </div>
                                </li>
                                <li class="detail_list">
                                    <div class="detail_tit">
                                        {{ $t('detail_inform') }}
                                    </div>

                                    <ul class="detail_content content_add" v-if="itemData.im_type==='photo'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('v_country2') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('v_si2') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('v_city2') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('size2') }}</h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li>
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else-if="itemData.im_type==='video'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('v_country2') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('v_si2') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('v_city2') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('v_long') }} : </h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else-if="itemData.im_type==='audio'">
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_record') }} : </h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('c_country') }} : </h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('c_si') }} : </h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('c_city') }} : </h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_size')">
                                            <h3>{{ $t('v_long') }} : </h3>
                                            <span>{{ itemData.imo_size }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi') }} : </h3>
                                                                                    <span>O</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                    <ul class="detail_content content_add" v-else>
                                        <li v-if="checkItemData('imo_date')">
                                            <h3>{{ $t('t_video2') }}</h3>
                                            <span>{{ itemData.imo_date }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_country')">
                                            <h3>{{ $t('c_country') }}</h3>
                                            <span>{{ itemData.imo_country }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_si')">
                                            <h3>{{ $t('c_si') }}</h3>
                                            <span>{{ itemData.imo_si }}</span>
                                        </li>
                                        <li v-if="checkItemData('imo_city')">
                                            <h3>{{ $t('c_city') }}</h3>
                                            <span>{{ itemData.imo_city }}</span>
                                        </li>
                                        <!--                                        <li v-if="checkCerti()">
                                                                                    <h3>{{ $t('certi2') }}</h3>
                                                                                    <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                    <span v-else>{{ $t('empty') }}</span>
                                                                                </li>-->
                                        <li v-if="checkItemData('imo_copyright')">
                                            <h3>{{ $t('copyright_info2') }}</h3>
                                            <span>{{ itemData.imo_copyright }}</span>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </el-collapse-item>
                </el-collapse>
                <div class="detail_middle_license_content mt10">
                    <!--            라이센스 에디션 선택 -->
                    <item-edition-component :item-key="itemData.i_sku"
                                            v-if="itemData.im_edition === 1"></item-edition-component>
                    <div v-if="itemData.i_status === 'license'" class="mt10">
                        <el-collapse class="edition_collapse"  v-click-outside="onClickOutside">
                            <el-collapse-item class="ev_content_box">
                                <template slot="title">
                                    <div class="amount">{{ $t('select_size') }}</div>
                                </template>
                                <ul class="license_content_wrap">
                                    <li v-if="smallType" @click="changeSelect('small')">
                                        <div class="license_box">
                                            <div class="license_top">
                                                <h3>Small</h3>
                                                <div class="amount_wrap">
                                                    <div class="mg_icon"></div>
                                                    <div class="amount">{{
                                                            returnCommas(itemData.il_small_price)
                                                        }}
                                                    </div>
                                                    <div class="mg">Mg</div>
                                                    <div class="item_small">({{ returnMingle(itemData.il_small_price) }})</div>
                                                </div>
                                            </div>
                                            <div class="license_content">
                                                <p>{{
                                                        Math.floor(smallSize[0])
                                                    }}*{{ Math.floor(smallSize[1]) }} px</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-if="mediumType" @click="changeSelect('medium')">
                                        <div class="license_box">
                                            <div class="license_top">
                                                <h3>Medium</h3>
                                                <div class="amount_wrap">
                                                    <div class="mg_icon"></div>
                                                    <div class="amount">{{
                                                            returnCommas(itemData.il_medium_price)
                                                        }}
                                                    </div>
                                                    <div class="mg">Mg</div>
                                                    <div class="item_small">({{ returnMingle(itemData.il_medium_price) }})</div>
                                                </div>
                                            </div>
                                            <div class="license_content">
                                                <p>{{
                                                        Math.floor(mediumSize[0])
                                                    }}*{{ Math.floor(mediumSize[1]) }} px</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li v-if="largeType" @click="changeSelect('large')">
                                        <div class="license_box">
                                            <div class="license_top">
                                                <h3>Large</h3>
                                                <div class="amount_wrap">
                                                    <div class="mg_icon"></div>
                                                    <div class="amount">{{
                                                            returnCommas(itemData.il_large_price)
                                                        }}
                                                    </div>
                                                    <div class="mg">Mg</div>
                                                    <div class="item_small">({{ returnMingle(itemData.il_large_price) }})</div>
                                                </div>
                                            </div>
                                            <div class="license_content">
                                                <p>{{
                                                        Math.floor(largeSize[0])
                                                    }}*{{ Math.floor(largeSize[1]) }} px</p>
                                            </div>
                                        </div>
                                    </li>
                                    <li @click="changeSelect('original')">
                                        <div class="license_box">
                                            <div class="license_top">
                                                <h3>Original</h3>
                                                <div class="amount_wrap">
                                                    <div class="mg_icon"></div>
                                                    <div class="amount">{{
                                                            returnCommas(itemData.il_original_price)
                                                        }}
                                                    </div>
                                                    <div class="mg">Mg</div>
                                                    <div class="item_small">({{ returnMingle(itemData.il_original_price) }})</div>
                                                </div>
                                            </div>
                                            <div class="license_content">
                                                <p>{{ itemData.imo_size }}px</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </el-collapse-item>
                        </el-collapse>
                        <li class="selected_size_wrap" v-if="sizeType==='small'">
                            <div class="size_wrap">
                                <h3 class="sub_title_s regular">Small</h3>
                                <div class="size">{{
                                        Math.floor(smallSize[0])
                                    }}*{{ Math.floor(smallSize[1]) }}px
                                </div>
                            </div>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.il_small_price) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.il_small_price) }})</div>
                            </div>
                        </li>
                        <li class="selected_size_wrap" v-if="sizeType==='medium'">
                            <div class="size_wrap">
                                <h3 class="sub_title_s regular">Medium</h3>
                                <div class="size">{{
                                        Math.floor(mediumSize[0])
                                    }}*{{ Math.floor(mediumSize[1]) }}px
                                </div>
                            </div>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.il_medium_price) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.il_medium_price) }})</div>
                            </div>
                        </li>
                        <li class="selected_size_wrap" v-if="sizeType==='large'">
                            <div class="size_wrap">
                                <h3 class="sub_title_s regular">Large</h3>
                                <div class="size">{{
                                        Math.floor(largeSize[0])
                                    }}*{{ Math.floor(largeSize[1]) }} px
                                </div>
                            </div>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.il_large_price) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.il_large_price) }})</div>
                            </div>
                        </li>
                        <li class="selected_size_wrap" v-if="sizeType==='original'">
                            <div class="size_wrap">
                                <h3 class="sub_title_s regular">Original</h3>
                                <div class="size">{{ itemData.imo_size }}px</div>
                            </div>
                            <div class="amount_wrap">
                                <div class="mg_icon"></div>
                                <div class="amount">{{ returnCommas(itemData.il_original_price) }}</div>
                                <div class="mg">Mg</div>
                                <div class="item_small">({{ returnMingle(itemData.il_original_price) }})</div>
                            </div>
                        </li>
                    </div>
                    <div class="detail_middle_footer" v-if="itemData.i_status === 'license' && checkMemberType()">
                        <div class="one_btn bid_btn" v-if="checkMemberType()"
                             @click="checkLicense(`license/${sizeType}`)">{{ $t('buy') }}
                        </div>
                        <div class="val_cap" v-show="hasSizeError && btnClicked"> {{ $t('need_size_select') }}
                        </div>
                    </div>
                </div>

                <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10 mb10 box-common">
                    <el-collapse-item class="ev_content_box">
                        <template slot="title">
                            <div class="amount">{{ $t('nft_history') }}</div>
                        </template>
                        <div class="info_detail nft_detail">
                            <history-detail-component v-if="itemData.hasOwnProperty('i_sku')"
                                                      :history-type="'itemDetail'"
                                                      :history-key="itemData.i_sku"></history-detail-component>

                        </div>
                    </el-collapse-item>
                </el-collapse>
                <!--            블록체인정보-->
                <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10 mb10 box-common">
                    <el-collapse-item class="ev_content_box">
                        <template slot="title">
                            <div class="amount">{{ $t('i_block') }}</div>
                        </template>
                        <block-chain-component :block-data="itemData.BlockChain"></block-chain-component>
                    </el-collapse-item>
                </el-collapse>
                <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10 mb10 box-common">
                    <el-collapse-item class="ev_content_box">
                        <template slot="title">
                            <div class="amount">연결된 유틸리티 : <span class="connect-utility">{{itemData.UtilityCnt}}개</span></div>
                        </template>
                        <utility-list-component :utilityKey="itemData.i_idx" :utility-type="'utilityDetailNticket'"></utility-list-component>
                    </el-collapse-item>
                </el-collapse>
                <div class="user_item_wrap padding_side">
                    <h3 v-html="$t('itemMsg', {nick: itemData.provider_nick})"></h3>
                    <item-list-component :item-type="'userItemList'"
                                         :memberKey="itemData.provider"
                                         v-if="itemData.provider"></item-list-component>
                </div>
            </div>
        </div>
        <auction-history-modal></auction-history-modal>
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ItemListComponent from "@/components/item/ItemListComponent";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import {mapState} from "vuex";
import LikeComponent from "@/components/like/LikeComponent";
import vClickOutside from 'v-click-outside'
import HistoryListComponent from "@/components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import ItemDetailMainSlideLayout from "@/template/item/detail/ItemDetailMainSlideLayout";
import ItemDetailHeartLayout from "@/template/item/ItemDetailHeartLayout";
import ItemDetailSubjectLayout from "@/template/item/ItemDetailSubjectLayout";
import ItemDetailInfoLayout from "@/template/item/ItemDetailInfoLayout";
import BlockChainComponent from "@/components/blockChain/BlockChainComponent";
import AuctionHistoryModal from "@/components/modal/AuctionHistoryModal";
import ItemEditionComponent from "@/components/item/ItemEditionComponent";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import priceMixins from "@/mixins/priceMixins";
import UtilityListComponent from "@/components/utility/UtilityListComponent";

let timeInterval = null;
export default {
    name: "ItemDetailOpenMarketLayout",
    mixins: [imageOption, dateMixins, AuthMixins, alertMixins, imageResizeMixins, awsS3FileDownload, priceMixins],
    components: {
        UtilityListComponent,
        ItemEditionComponent,
        AuctionHistoryModal,
        BlockChainComponent,
        ItemDetailInfoLayout,
        ItemDetailSubjectLayout,
        ItemDetailHeartLayout,
        ItemDetailMainSlideLayout,
        HistoryDetailComponent,
        HistoryListComponent,
        LikeComponent,
        ItemListComponent,
        Swiper,
        SwiperSlide,
    },
    inject: ['vueSetData'],
    provide() {
        return {}
    },
    props: {
        itemData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            itemKey: this.$route.params.idx,
            itemCategorys: {},
            currentTime: '',
            size: '',
            sizeType: '',
            tab: 'item',
            memberData: {},
            btnClicked: false,

            getDataStatus: false,
        }
    },
    metaInfo() {
        let url = window.location.href;
        let imageUrl = this.itemData.ItemFile.Item[0].org_url;
        let shareMsg = this.$t('share_msg')
        return {
            meta: [
                // Twitter Card
                {name: 'twitter:card', content: shareMsg},
                {name: 'twitter:title', content: `${this.itemData.i_name}| 밍글민트(Minglemint NFT Marketplace)`},
                {name: 'twitter:description', content: shareMsg},
                // image must be an absolute path
                {name: 'twitter:image', content: imageUrl},
                // Facebook OpenGraph
                {
                    vmid: 'og:title',
                    name: 'og:title',
                    property: 'og:title',
                    content: `${this.itemData.i_name}| 밍글민트(Minglemint NFT Marketplace)`
                },
                {vmid: 'og:type', name: 'og:type', property: 'og:type', content: 'website'},
                {vmid: 'og:image', name: 'og:image', property: 'og:image', content: imageUrl},
                {vmid: 'og:description', name: 'og:description', property: 'og:description', content: shareMsg}
            ]
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
        this.setVueData();
        this.initItemData();
        this.itemCategorys = this.itemCategoryList;
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);

    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        }),
        hasSizeError() {
            return util.isEmpty(this.sizeType)
        }
    },
    methods: {
        initItemData() {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Item)) {
                this.thumnailImgs = this.itemData.ItemFile.Detail;
                this.orgItemImg = this.itemData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
            console.log(!util.isEmpty(this.itemData) && this.itemData.im_status === 3)
            if (!util.isEmpty(this.itemData) && this.itemData.im_status === 3) {
                if (this.itemData.im_type === 'photo') {
                    let afterSize = this.itemData.imo_size.split('*');
                    console.log(afterSize)
                    this.imageResize(Number(afterSize[0]), Number(afterSize[1]))
                } else {
                    this.sizeType = 'origin'
                }
            }
            this.vueSetData(this.itemData);
            EventBus.$emit('marketSetData', this.itemData)
        },
        returnCommas(x = 0) {
            if (util.isEmpty(x)) {
                return 0;
            }
            return util.Number.numFormat(x);
        },
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveHistoryMore() {
            this.$router.push(`/item/${this.itemData.i_sku}/history`);
        },
        checkLicense(url) {
            this.btnClicked = true
            if (!this.hasSizeError) {
                this.checkLogin(url)
            }
        },
        checkLogin(url) {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                this.movePage(url);
            }
        },
        openModal(name, data) {
            this.$modal.show(name, {m_idx: data});
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
            if (type != 'curator') {
                return 'http://testimage.minglemint.com/profile/no_image.png?w=45&h=45&f=png'
            }
        },
        checkCurator(data) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator)) {
                return true
            }
            return false;
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            this.$forceUpdate();
        },
        returnItemType(data) {
            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    if(diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                        this.getDataStatus = true;
                        setTimeout(() => {
                            this.getDataStatus = false;
                            EventBus.$emit('getDetail');
                        }, 1000 * 30)
                    }
                    return diffTime;
                } else {
                    return data.ia_start_date;
                }
            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                // return ''
                return this.$t('no_sell')
            }
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },
        checkCerti() {
            return !util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Guaranty) && !util.isEmpty(this.itemData.ItemFile.Guaranty[0].a_idx)
        },
        checkItemData(data) {
            return !util.isEmpty(this.itemData[data]);
        },
        checkDetail() {
            return !util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile.Detail)
        },

        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if(data.ic_idx === 178){
                return this.$t('prompt')
            }
        },
        checkUpdate() {
            if (util.isEmpty(this.itemData)) {
                return false;
            }
            if (util.isEmpty(this.UserInfo)) {
                return false;
            }
            if (!util.isEmpty(this.itemData.ibc_curator) && this.itemData.ibc_curator === this.UserInfo.mb_no) {
                return true;
            }
            if (this.itemData.provider === this.UserInfo.mb_no) {
                return true;
            }
            return false;
        },

        getMemberData(data, type) {
            this.memberData = {}
            if (util.isEmpty(data[type])) {
                return
            }
            let params = {
                no: data[type]
            }
            this.$api.$member.getProfile(params).then(res => res.Data.Result).then(res => {
                if (res.Info.type != 1 || util.isEmpty(res.List)) {
                    this.createAlert({
                        content: this.$t('detect_detail_empty'),
                        hide: () => {
                            this.$router.back();
                        }
                    })
                    return false;
                }
                this.memberData = res.List[0];
                this.$forceUpdate()
            })

        },
        returnMemberImg() {
            if (!util.isEmpty(this.memberData.Files) && !util.isEmpty(this.memberData.Files.Profile)) {
                return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(62, 62, 'png')}`
            }
        },
        checkMemberData() {
            return !util.isEmpty(this.memberData)
        },
        moveMember(url) {
            this.$router.push(url)
        },
        checkMemberType() {
            if ((!util.isEmpty(this.UserInfo) && this.itemData.provider === this.UserInfo.mb_no) || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        onClickOutside() {
            this.size = '';
        },
        changeSelect(type) {
            this.size = '';
            this.sizeType = type;
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        checkDisabled(data) {
            if (!util.isEmpty(data) && !util.isEmpty(data.ibc_status) && data.ibc_status === 0) {
                return true;
            }
            return false;
        },
        openShareModal() {
            this.$modal.show('share-modal', {itemData: this.itemData})
        },
        setVueData() {
            this.vueSetData(this.itemData);
        },
        returnType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
        checkImgData(data) {
            if (!util.isEmpty(this.itemData) && !util.isEmpty(this.itemData.ItemFile) && !util.isEmpty(this.itemData.ItemFile[data]) && !util.isEmpty(this.itemData.ItemFile[data][0].a_idx)) {
                return true
            }
            return false
        },
        awsS3Download(type, name, key, nft) {
            if (util.isEmpty(this.UserInfo) || this.itemData.provider != this.UserInfo.mb_no) {
                return false;
            }
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },


    },
    watch: {
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData();
                this.setVueData();
            }
        },

    },
}
</script>

<style scoped>

</style>
