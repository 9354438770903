<template>
    <div class="gallery_detail_list_item_wrap edit_layout">
        <div class="gallery_list" v-for="gallery in galleryData" :key="`gallery${gallery.ci_idx}`">
            <div class="delete_btn" @click="openDeleteConfirm(gallery)"></div>
            <div class="img_wrap" @click="moveItemDetail(gallery)">
                <div class="c_category_dup img_bg" :style="`background-image: url(${returnThumbnail(gallery)})`"></div>
                <div class="c_category_dup art_card">{{ returnItem(gallery).ic_name_eng }}</div>
                <div class="c_category_dup right_card">
                    <div class="play_card"
                         v-if="returnItem(gallery).im_type === 'video'|| returnItem(gallery).im_type==='audio'">
                        <img class="icon play" src="@/assets/image/icon_play.png" alt="">
                    </div>
                    <div class="real_card" v-if="returnItem(gallery).i_type ==='real'">
                        {{ $t('real') }}
                    </div>
                </div>
            </div>

            <div class="content_wrap" @click="moveItemDetail(gallery)">
                <div class="subject_wrap">{{ returnItem(gallery).i_name }}</div>
            </div>

        </div>
    </div>
</template>


<script>
import imageOption from "@/mixins/imageOption";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";
import alertMixins from "@/mixins/alertMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";

export default {
    name: "GalleryListEditLayout",
    mixins: [imageOption, alertMixins, imageResizeMixins],
    components: {},
    inject: [],
    provide() {
        return {}
    },
    props: {
        galleryData: {default: () => []},
    },
    data() {
        return {
            idx: 1,
            galleryItemLists: 12,

        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        openModal(name) {
            this.$modal.show(name, {galleryType: 'galleryUpdate'});
        },
        moveDetail(data) {
            this.$router.push(`/gallery/edit/${data}`)
        },
        movePage(url) {
            this.$router.push(url);
        },
        returnItem(gallery) {
            return util.isEmpty(gallery) || util.isEmpty(gallery.Item) ? {} : gallery.Item;
        },
        returnThumbnail(data) {
            let item = this.returnItem(data);
            if (!util.isEmpty(item.ItemFile) && !util.isEmpty(item.ItemFile.Item)) {
                // return `${item.ItemFile.Item[0].org_url}?${this.setImageOptions(278, null, null)}`;
                // return `${item.ItemFile.Item[0].org_url}`;
                return this.setFitWidthImg(item.ItemFile.Item, 278);
            }
        },
        openDeleteConfirm(gallery) {
            /*this.createConfirm({
                content: `'<span class="subject">${item.i_name}</span>'<br>${this.$t('collection_item_add_confirm1')}`,
                confirm: () => {
                    console.log('test')
                    EventBus.$emit('writeGallery')
                },
                // content: `'<span class="subject">${this.itemname}</span>'<br>${this.$t('collection_item_add_confirm1')}`
            })*/
            let item = this.returnItem(gallery);
            let itemName = "";
            if (!util.isEmpty(item.i_name)) {
                itemName = item.i_name;
            }
            this.createConfirm({
                content: `'<span class="subject">${itemName}</span>'<br>${this.$t('collection_item_delete_confirm1')}`,
                confirm: () => {
                    EventBus.$emit('galleryListDelete', gallery.ci_idx);
                }
            })
        },
        moveItemDetail(gallery) {
            let itemData = this.returnItem(gallery);
            console.log(itemData)
            let url = `/item/${itemData.i_sku}`
            if (itemData.im_status === 2 || itemData.im_status === 5) {
                url = `/premiummarket/${itemData.i_sku}`
            } else if (itemData.im_status === 1 || itemData.im_status === 3 || itemData.im_status === 4) {
                url = `/openmarket/${itemData.i_sku}`
            }
            this.$router.push(url);
        },
        onClick(event) {
            console.log(event)
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
