<template>
    <div>
        CompetitionFilterUserFilter
        <button @click="openFilter">{{$t('filter')}}</button>
        <competition-filter-user-filter></competition-filter-user-filter>
    </div>
</template>

<script>
import EventBus from "@/utils/event-bus";
import competitionUtil from "@/components/competition/competitionUtil";
const {filterConfig} = competitionUtil
export default {
    name: "CompetitionFilterOtherLayout",
    mixins: [],
    components: {},
    inject: ['setFilter','setSort'],

    provide() {
        return {}
    },
    props: {
        filterData: {
            default: () => {
            }
        },
    },
    data() {
        return {
            listChange: 'list',
            searchInputOption: 'ti',
            searchInput: '',
            sortOption: 'created_at',
            filterStatus:[],
            filterCategory:[],
            filterPrice:[],
            minPrice:'',
            maxPrice:''
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        openModal(name) {
            this.$modal.show(name);
        },
        searchSubmit() {
            // 검색 입력 후 ,
            this.searchCheck = true;
            this.$router.push('/search');
        },
        listTypeChange(type) {
            this.listChange = type;
            EventBus.$emit('changeListType',type);
        },
        setSortFunc(key) {
            let order = 'desc'
            if(key.includes('_asc')){
                let idx = key.indexOf('_asc')
                key = key.substring(0,idx)
                order = 'asc'
            }
            this.setSort(key,order)
        },
        changeCheck(dataName) {
            this[dataName] = !this[dataName];
            if (this[dataName]) {
                this[dataName] = true;
            } else {
                this[dataName] = false;
            }
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        applyFilter(){
            /* this.filterPrice[0] = this.minPrice ? this.minPrice : ''
             this.filterPrice[1] = this.maxPrice  ? this.maxPrice : ''*/
            this.setFilter('pr', this.filterPrice);

        },
        openFilter() {
            this.$modal.show('competition-filter-user-filter')
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
