<template>
<div>
    ItemWriteCurationLayout
</div>
</template>

<script>

export default {
  name: "ItemWriteCurationLayout",
  mixins: [],
  components: {
  },
  inject: [],
  provide() {
    return{

    }
  },
  props: {
  },
  data() {
    return{
      content:'',
      isActive: false,
    }
  },
  beforeRouterEnter() {},
  created() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  destroyed() {},
  computed: {
  },
  methods:{
  },
  watch: {
  },
}
</script>

<style scoped>

</style>