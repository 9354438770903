<template>
    <div>

        <div class="item_detail_wrap">

            <div class="item_detail_top">
                <item-detail-main-slide-layout :item-data="entryData" :show-player="true"></item-detail-main-slide-layout>
                <like-component :likeType="'itemDetail'" :likeData="entryData"></like-component>
                <div class="left">
                    <span class="left_span ">{{ returnItemType(entryData) }}</span>
                </div>
            </div>

            <div class="item_detail_middle">
                <item-detail-subject-layout :item-data="entryData" :notShowAccount="true"></item-detail-subject-layout>


                <div class="vote_amount_wrap">
                    <h3>{{ $t('n_vote') }}</h3>
                    <div class="amount_wrap">
                        <div class="amount">{{ returnCommas(itemData.ci_vote_cnt) }}</div>
                        <div class="percentage">({{ returnPercentage(itemData.ci_vote_cnt, itemData.totalVote) }}%)
                        </div>
                    </div>
                </div>
                <!--            투표하기-->
                <div class="detail_middle_footer" v-if="itemData.UserType === 'normal'">
                    <button class="one_btn bid_btn" v-if="contestData.c_status === 1" @click="voteItem()">{{ $t('vote') }}
                    </button>
                </div>

                <!--            주최자-->
                <div class="detail_middle_footer" v-if="itemData.UserType === 'my'">
                    <el-collapse v-model="evaulation" class="ev_wrap">
                        <el-collapse-item v-for="(judge,index) in entryData.Judge" :key="`judge-master-${index}`"
                                          class="ev_content_box">
                            <template slot="title">
                                <div class="amount">{{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                </div>
                            </template>
                            <h3 class="transition-box">{{ $t('r_point') }}</h3>
                            <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                            <div class="thumb_wrap">
                                <img :src="returnProfile(judge)" alt="">
                                <span>{{ judge.mb_nick }}</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>


                <!--######################## 심사위원 ###############################-->
                <div class="simsa_wrap" >
                    <!--            배점-->
                    <div class="detail_score_wrap" v-if="itemData.UserType === 'judge' && itemData.Judge.length > 0">
                        <el-collapse v-model="evaulation">
                            <el-collapse-item class="ev_content_box" v-for="(judge,index) in itemData.Judge"
                                              :key="`judge-${index}`">
                                <template slot="title">
                                    <div class="amount">{{ $t('point') }}</div>
                                    <div>
                                        <span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                    </div>

                                </template>
                                <div class="ev_content">
                                    <h3 class="sub_title_m">{{ $t('r_point') }}</h3>
                                    <div class="reason_box">{{ judge.cij_reason }}</div>
                                </div>
                            </el-collapse-item>

                        </el-collapse>

                    </div>

                    <!--            심사 버튼-->
                    <div class="c_detail_middle_footer entry_btn_wrap" v-if="itemData.UserType === 'judge' && itemData.Judge.length === 0">
                        <div class="one_btn bid_btn" @click="openJudgeModal()">{{ $t('judge') }}</div>
                        <div class="clear"></div>
                    </div>

                </div>

                <div class="caution_license" v-if="entryData.i_status === 'license'&& entryData.il_caution">
                    <h3>{{ $t('caution_license') }}</h3>
                    <p>{{ entryData.il_caution }}</p>
                </div>
            </div>



            <div class="item_detail_footer">
                <div class="item_footer_coll padding_side">
                    <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10">
                        <el-collapse-item class="ev_content_box">
                            <template slot="title">
                                <div class="amount">{{ $t('item') }}</div>
                            </template>
                            <div class="item_wrap">

                                <div class="item_description_wrap pt10 pb10">
                                    <div class="ql-editor" v-html="decodeHtml(replaceBr(entryData.i_memo))"></div>
                                </div>
                                <!--                            상세정보-->
                                <ul class="inform_detail_box">
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('type') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ returnType(entryData.i_type) }}
                                        </div>
                                    </li>
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('category') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ returnCategoryName(entryData) }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="checkItemData('im_originator')">
                                        <div class="detail_tit">
                                            {{ $t('k_creator') }}
                                        </div>
                                        <div class="detail_content">
                                            {{ entryData.originator_nick }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="entryData.isEdition === 1">
                                        <div class="detail_tit">
                                            {{ $t('edition') }}
                                        </div>
                                        <div class="detail_content" v-if="entryData.type === 'real'">
                                            {{ entryData.editionCount }} of {{ entryData.editionTotal }}
                                        </div>
                                        <div class="detail_content" v-else>
                                            {{ entryData.editionCount }}
                                        </div>
                                    </li>
                                    <li class="detail_list" v-if="entryData.hasOwnProperty('ItemFile')">
                                        <div class="detail_tit">
                                            {{ $t('certi') }}
                                        </div>
                                        <!--<div class="detail_content color_navy"
                                                v-for="(data,index) in itemData.ItemFile.Guaranty"
                                                :key="`guranty${index}`" v-if="checkImg(data)">
                                                    {{ data.org_name }}
                                        </div>-->
                                        <div class="detail_content color_navy "
                                             :class="{'cursor': entryData.provider === UserInfo.mb_no, 'certi': index > 0}"
                                             v-if="checkImgData('Guaranty')"
                                             v-for="(data,index) in entryData.ItemFile.Guaranty"
                                             @click="awsS3Download('guaranty', data, entryData.i_idx)"
                                             :key="`guranty${index}`">
                                            {{ data.org_name }}
                                        </div>
                                        <div class="detail_content " v-else>
                                            {{ $t('no3') }}
                                        </div>
                                    </li>
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('f_real') }}
                                        </div>
                                        <div class="detail_content color_navy" v-if="checkImgData('NFT')">
                                            {{ entryData.ItemFile.NFT[0].a_filename }}
                                        </div>
                                    </li>
                                    <li class="detail_list">
                                        <div class="detail_tit">
                                            {{ $t('detail_inform') }}
                                        </div>

                                        <ul class="detail_content content_add" v-if="entryData.im_type==='photo'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('size2') }}</h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li>
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else-if="itemData.im_type==='video'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('v_country2') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('v_si2') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('v_city2') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else-if="entryData.im_type==='audio'">
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_record') }} : </h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }} : </h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }} : </h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }} : </h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_size')">
                                                <h3>{{ $t('v_long') }} : </h3>
                                                <span>{{ entryData.imo_size }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi') }} : </h3>
                                                                                        <span>O</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                        <ul class="detail_content content_add" v-else>
                                            <li v-if="checkItemData('imo_date')">
                                                <h3>{{ $t('t_video2') }}</h3>
                                                <span>{{ entryData.imo_date }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_country')">
                                                <h3>{{ $t('c_country') }}</h3>
                                                <span>{{ entryData.imo_country }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_si')">
                                                <h3>{{ $t('c_si') }}</h3>
                                                <span>{{ entryData.imo_si }}</span>
                                            </li>
                                            <li v-if="checkItemData('imo_city')">
                                                <h3>{{ $t('c_city') }}</h3>
                                                <span>{{ entryData.imo_city }}</span>
                                            </li>
                                            <!--                                        <li v-if="checkCerti()">
                                                                                        <h3>{{ $t('certi2') }}</h3>
                                                                                        <span v-if="checkCerti()">{{ $t('has') }}</span>
                                                                                        <span v-else>{{ $t('empty') }}</span>
                                                                                    </li>-->
                                            <li v-if="checkItemData('imo_copyright')">
                                                <h3>{{ $t('copyright_info2') }}</h3>
                                                <span>{{ entryData.imo_copyright }}</span>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
<!--                    <div class="detail_middle_license_content mt10">
                        &lt;!&ndash;            라이센스 에디션 선택 &ndash;&gt;
                        <item-edition-component :item-key="entryData.i_sku"
                                                v-if="entryData.im_edition === 1"></item-edition-component>
                    </div>-->

                    <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10">
                        <el-collapse-item class="ev_content_box">
                            <template slot="title">
                                <div class="amount">{{ $t('nft_history') }}</div>
                            </template>
                            <div class="info_detail nft_detail">
                                <history-detail-component v-if="entryData.hasOwnProperty('i_sku')"
                                                          :history-type="'itemDetail'"
                                                          :history-key="entryData.i_sku"></history-detail-component>
<!--                                <div class="nft_header_wrap">
                                    <div class="more_view_btn2 text-right" @click="moveHistoryMore('history')">More+</div>
                                </div>-->
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                    <!--            블록체인정보-->
                    <el-collapse v-click-outside="onClickOutside" class="non_borer_top mt10">
                        <el-collapse-item class="ev_content_box">
                            <template slot="title">
                                <div class="amount">{{ $t('i_block') }}</div>
                            </template>
                            <block-chain-component :block-data="entryData.BlockChain"></block-chain-component>
                        </el-collapse-item>
                    </el-collapse>
                    <div class="user_item_wrap padding_side">
                        <h3>{{ $t('itemMsg', {nick: entryData.provider_nick}) }}</h3>
                        <item-list-component :item-type="'userItemList'"
                                             :memberKey="entryData.provider"
                                             v-if="entryData.provider"></item-list-component>
                    </div>
                </div>
            </div>
            <auction-history-modal></auction-history-modal>
            <judge-input-modal></judge-input-modal>
            <share-modal></share-modal>
        </div>
<!--        <div class="item_detail_wrap">

            <div class="item_detail_top">
                <div class="detail_img_wrap">
                    <div class="b_img_wrap" :style="`background-image: url(${returnThumbnail(mainImg)})`">
                        <div class="real_card" v-if="entryData.i_type === 'real'">{{ $t('real') }}</div>
                    </div>
                    <swiper class="s_img_wrap" ref="swiper" :options="swiperOption" v-if="thumnailStatus"
                            :slidesPerView="'auto'" @click="onClick"
                            @slideChange="slideChange">
                        <swiper-slide class="img_wrap">
                            <div class="img_box">
                                <img :src="returnSmallThumbnail(orgItemImg)">
                            </div>
                        </swiper-slide>
                        <swiper-slide class="img_wrap"
                                      v-for="(data,index) in thumnailImgs"
                                      :key="`detail${index}`"
                                      v-if="data.org_name != 'no_image.png'">
                            <div class="img_box">
                                <img :src="returnSmallThumbnail(data.org_url)">
                            </div>
                        </swiper-slide>
                    </swiper>
                </div>

                <like-component v-if="entryData.hasOwnProperty('Item')" :likeType="'itemDetail'"
                                :likeData="returnEntryData()"></like-component>
            </div>

            <div class="item_detail_middle">
                <div class="detail_middle_header">

                    &lt;!&ndash;                    회원썸네일&ndash;&gt;
                    <div class="thumbnail_wrap">
                        <ul @click="openModal('popup-modal')">
                            <li><img :src="returnItemProfile(entryData,'originator')" alt=""></li>
                            <li><img :src="returnItemProfile(entryData,'provider')" alt=""></li>
                            <li><img :src="returnItemProfile(entryData,'curator')" v-if="checkCurator(entryData)" alt="">
                            </li>
                        </ul>
                        <div class="time_wrap" v-if="entryData.i_status === 'auction'">
                            <div class="time_remain">{{ returnItemType(itemData) }}</div>
                        </div>
                    </div>

                    &lt;!&ndash;                아이템 정보&ndash;&gt;
                    <div class="subject_wrap">
                        <h3>{{ returnCategoryName(entryData) }}</h3>
                        <p>{{ entryData.i_name }}</p>
                        <span v-if="entryData.im_edition === 1 && entryData.i_type === 'real'">{{ $t('edition') }} {{
                                entryData.im_count
                            }} / {{ entryData.im_total }}</span>
                        <span v-if="entryData.im_edition === 1 && entryData.i_type === 'digital' ">{{
                                $t('edition')
                            }} {{ entryData.im_count }}</span>
                    </div>

                    &lt;!&ndash;해시태그&ndash;&gt;
                    <div class="hashtag_wrap">
                        <ul>
                            <li v-for="(hash,index) in entryData.Hash" :key="`hash${index}`">
                                <span v-if="hash.ih_type === 1">#{{ returnCategoryDetailName(hash.ic_idx) }}</span>
                                <span v-else>#{{ hash.ih_hashtag }}</span>
                            </li>
                        </ul>
                    </div>

                    <div class="vote_amount_wrap">
                        <h3>{{ $t('n_vote') }}</h3>
                        <div class="amount_wrap">
                            <div class="amount">{{ returnCommas(itemData.ci_vote_cnt) }}</div>
                            <div class="percentage">({{ returnPercentage(itemData.ci_vote_cnt, itemData.totalVote) }}%)
                            </div>
                        </div>
                    </div>
                </div>


                &lt;!&ndash;            투표하기&ndash;&gt;
                <div class="detail_middle_footer" v-if="itemData.UserType === 'normal'">
                    <button class="one_btn bid_btn" v-if="contestData.c_status === 1" @click="voteItem()">{{ $t('vote') }}
                    </button>
                </div>

                &lt;!&ndash;            주최자&ndash;&gt;
                <div class="detail_middle_footer" v-if="itemData.UserType === 'my'">
                    <el-collapse v-model="evaulation" class="ev_wrap">
                        <el-collapse-item v-for="(judge,index) in entryData.Judge" :key="`judge-master-${index}`"
                                          class="ev_content_box">
                            <template slot="title">
                                <div class="amount">{{ $t('point') }}<span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                </div>
                            </template>
                            <h3 class="transition-box">{{ $t('r_point') }}</h3>
                            <div class="transition-box border_box">{{ judge.cij_reason }}</div>
                            <div class="thumb_wrap">
                                <img :src="returnProfile(judge)" alt="">
                                <span>{{ judge.mb_nick }}</span>
                            </div>
                        </el-collapse-item>
                    </el-collapse>
                </div>


                &lt;!&ndash;######################## 심사위원 ###############################&ndash;&gt;
                <div class="simsa_wrap" >
                    &lt;!&ndash;            배점&ndash;&gt;
                    <div class="detail_score_wrap" v-if="itemData.UserType === 'judge' && itemData.Judge.length > 0">
                        <el-collapse v-model="evaulation">
                            <el-collapse-item class="ev_content_box" v-for="(judge,index) in itemData.Judge"
                                              :key="`judge-${index}`">
                                <template slot="title">
                                    <div class="amount">{{ $t('point') }}</div>
                                    <div>
                                        <span>{{ judge.cij_score }}</span>{{ $t('jum') }}
                                    </div>

                                </template>
                                <div class="ev_content">
                                    <h3 class="sub_title_m">{{ $t('r_point') }}</h3>
                                    <div class="reason_box">{{ judge.cij_reason }}</div>
                                </div>
                            </el-collapse-item>

                        </el-collapse>

                    </div>

                    &lt;!&ndash;            심사 버튼&ndash;&gt;
                    <div class="c_detail_middle_footer entry_btn_wrap" v-if="itemData.UserType === 'judge' && itemData.Judge.length === 0">
                        <div class="one_btn bid_btn" @click="openJudgeModal()">{{ $t('judge') }}</div>
                        <div class="clear"></div>
                    </div>

                </div>

                <div class="caution_license" v-if="entryData.i_status === 'license'&& entryData.il_caution">
                    <h3>{{ $t('caution_license') }}</h3>
                    <p>{{ entryData.il_caution }}</p>
                </div>
            </div>
            <div class="item_detail_footer">
                <ul class="detail_footer_tab">
                    <li @click="changeTab('item')" class="col3 footer_tab_item" :class="{active : tab === 'item'}">
                        {{ $t('item') }}
                    </li>
                    <li @click="changeTab('block')" class="col3 footer_tab_block" :class="{active : tab === 'block'}">
                        {{ $t('i_block') }}
                    </li>
                    <li @click="changeTab('nft')" class="col3 footer_tab_nft" :class="{active : tab === 'nft'}">
                        {{ $t('nft_history') }}
                    </li>
                </ul>
                <div class="footer_item_wrap" v-if="tab === 'item'">
                    <div class="item_wrap">

                        <div class="detail_information_wrap">
                            <h3 class="sub_title_m">{{ $t('detail_inform') }}</h3>
                            <ul v-if="entryData.im_type==='photo'">
                                <li v-if="checkItemData('imo_date')">
                                    <h4 class="sub_title_s">{{ $t('t_video') }}</h4>
                                    <div>{{ entryData.imo_date }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h4 class="sub_title_s">{{ $t('v_country') }}</h4>
                                    <div>{{ entryData.imo_country }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h4 class="sub_title_s">{{ $t('v_si3') }}</h4>
                                    <div>{{ entryData.imo_si }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h4 class="sub_title_s">{{ $t('v_city') }}</h4>
                                    <div>{{ entryData.imo_city }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h4 class="sub_title_s">{{ $t('size') }}</h4>
                                    <div>{{ entryData.imo_size }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li>
                                    <h4 class="sub_title_s">{{ $t('certi') }}</h4>
                                    <div v-if="checkCerti()">{{ $t('has') }}</div>
                                    <div v-else>{{ $t('empty') }}</div>

                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h4 class="sub_title_s">{{ $t('copyright_info2') }}</h4>
                                    <div>{{ entryData.imo_copyright }}</div>
                                </li>
                            </ul>
                            <ul v-if="entryData.im_type==='video'">
                                <li v-if="checkItemData('imo_date')">
                                    <h4 class="sub_title_s">{{ $t('t_video') }}</h4>
                                    <div>{{ entryData.imo_date }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h4 class="sub_title_s">{{ $t('v_country') }}</h4>
                                    <div>{{ entryData.imo_country }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h4 class="sub_title_s">{{ $t('v_si3') }}</h4>
                                    <div>{{ entryData.imo_si }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h4 class="sub_title_s">{{ $t('v_city') }}</h4>
                                    <div>{{ entryData.imo_city }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h4 class="sub_title_s">{{ $t('v_long') }}</h4>
                                    <div>{{ entryData.imo_size }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li>
                                    <h4 class="sub_title_s">{{ $t('certi') }}</h4>
                                    <div v-if="checkCerti()">{{ $t('has') }}</div>
                                    <div v-else>{{ $t('empty') }}</div>

                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h4 class="sub_title_s">{{ $t('copyright_info2') }}</h4>
                                    <div>{{ entryData.imo_copyright }}</div>
                                </li>
                            </ul>
                            <ul v-if="entryData.im_type==='audio'">
                                <li v-if="checkItemData('imo_date')">
                                    <h4 class="sub_title_s">{{ $t('t_record') }}</h4>
                                    <div>{{ entryData.imo_date }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h4 class="sub_title_s">{{ $t('c_country') }}</h4>
                                    <div>{{ entryData.imo_country }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h4 class="sub_title_s">{{ $t('c_si') }}</h4>
                                    <div>{{ entryData.imo_si }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h4 class="sub_title_s">{{ $t('c_city') }}</h4>
                                    <div>{{ entryData.imo_city }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_size')">
                                    <h4 class="sub_title_s">{{ $t('v_long') }}</h4>
                                    <div>{{ entryData.imo_size }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li>
                                    <h4 class="sub_title_s">{{ $t('certi') }}</h4>
                                    <div v-if="checkCerti()">{{ $t('has') }}</div>
                                    <div v-else>{{ $t('empty') }}</div>

                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h4 class="sub_title_s">{{ $t('copyright_info2') }}</h4>
                                    <div>{{ entryData.imo_copyright }}</div>
                                </li>
                            </ul>
                            <ul v-else>
                                <li v-if="checkItemData('imo_date')">
                                    <h4 class="sub_title_s">{{ $t('t_video') }}</h4>
                                    <div>{{ entryData.imo_date }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_country')">
                                    <h4 class="sub_title_s">{{ $t('c_country') }}</h4>
                                    <div>{{ entryData.imo_country }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_si')">
                                    <h4 class="sub_title_s">{{ $t('c_si') }}</h4>
                                    <div>{{ entryData.imo_si }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_city')">
                                    <h4 class="sub_title_s">{{ $t('c_city') }}</h4>
                                    <div>{{ entryData.imo_city }}</div>
                                    <div class="clear"></div>
                                </li>
                                <li>
                                    <h4 class="sub_title_s">{{ $t('certi') }}</h4>
                                    <div v-if="checkCerti()">{{ $t('has') }}</div>
                                    <div v-else>{{ $t('empty') }}</div>

                                    <div class="clear"></div>
                                </li>
                                <li v-if="checkItemData('imo_copyright')">
                                    <h4 class="sub_title_s">{{ $t('copyright_info2') }}</h4>
                                    <div>{{ entryData.imo_copyright }}</div>
                                </li>
                            </ul>
                        </div>

                        <div class="item_description_wrap">
                            <h3 class="sub_title_m">{{ $t('i_inform') }}</h3>
                            <div v-html="decodeHtml(replaceBr(entryData.i_memo))"></div>
                        </div>
                        <div class="user_item_wrap">
                            <h3>{{ $t('itemMsg', {nick: entryData.provider_nick}) }}</h3>
                            <item-list-component :item-type="'userItemList'"
                                                 :memberKey="entryData.provider"
                                                 v-if="entryData.provider"></item-list-component>
                        </div>
                    </div>
                </div>
                &lt;!&ndash;            블록체인정보&ndash;&gt;
                <div class="footer_block_wrap" v-if="tab === 'block'">
                    <block-chain-component :block-data="entryData.BlockChain"></block-chain-component>
                </div>

                <div class="footer_nft_wrap" v-if="tab === 'nft'">
                    <div class="info_detail">
                        <div class="nft_header_wrap">
                            <h3 class="sub_title_m">{{ $t('nft_history') }}</h3>
                            <div class="more_view_btn2" @click="moveHistoryMore('history')">More+</div>
                        </div>
                        <history-detail-component :history-type="'itemDetail'"
                                                  :history-key="entryData.i_sku"></history-detail-component>
                    </div>
                </div>

            </div>

            <judge-input-modal></judge-input-modal>
        </div>-->
    </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
import ItemListComponent from "@/components/item/ItemListComponent";
import BlockChainComponent from "@/components/blockChain/BlockChainComponent";
import util from "@/mixins/util";
import imageOption from "@/mixins/imageOption";
import dateMixins from "@/mixins/dateMixins"
import {mapState} from "vuex";
import LikeComponent from "@/components/like/LikeComponent";
import vClickOutside from 'v-click-outside'
import HistoryListComponent from "@/components/history/HistoryListComponent";
import HistoryDetailComponent from "@/components/history/HistoryDetailComponent";
import EventBus from "@/utils/event-bus";
import AuthMixins from "@/mixins/AuthMixins";
import imageResizeMixins from "@/mixins/imageResizeMixins";
import alertMixins from "@/mixins/alertMixins";
import ItemDetailComponent from "@/components/item/ItemDetailComponent";
import ItemDetailHeartLayout from "@/template/item/ItemDetailHeartLayout";
import ItemDetailSubjectLayout from "@/template/item/ItemDetailSubjectLayout";
import ItemDetailEntrySlideLayout from "@/template/item/detail/ItemDetailEntrySlideLayout";
import itemCategoryMixin from "@/mixins/itemCategoryMixin";
import JudgeInputModal from "@/components/modal/JudgeInputModal";
import awsS3FileDownload from "@/mixins/awsS3FileDownload";
import ItemDetailMainSlideLayout from "@/template/item/detail/ItemDetailMainSlideLayout";
import ItemEditionComponent from "@/components/item/ItemEditionComponent";
import ShareModal from "@/components/modal/ShareModal";

let timeInterval = null;
export default {
    name: "ItemDetailEntryLayout",
    mixins: [imageOption, dateMixins, AuthMixins, itemCategoryMixin, imageResizeMixins, alertMixins, awsS3FileDownload],
    components: {
        JudgeInputModal,
        ItemDetailEntrySlideLayout,
        ItemDetailSubjectLayout,
        ItemDetailHeartLayout,
        ItemDetailComponent,
        HistoryDetailComponent,
        HistoryListComponent,
        LikeComponent,
        BlockChainComponent,
        ItemListComponent,
        ItemDetailMainSlideLayout,
        ItemEditionComponent,
        ShareModal,
        Swiper,
        SwiperSlide,
    },
    inject: [],
    provide() {
        return {}
    },
    props: {
        itemData: {},
    },
    data() {
        return {
            itemKey: this.$route.params.idx,
            itemCategorys: {},
            swiperOption: {
                slidesPerView: 4,
                spaceBetween: 8,
                grabCursor: true,
                autoWidth: true,
                // loop: true,
            },
            currentTime: '',
            size: {},
            tab: 'item',
            entryData: {},
            evaulation: ['1'],
            contestData: {},

            getDataStatus: false,
        }
    },

    created() {
        this.tab = this.$route.query.tab;
        if (util.isEmpty(this.tab)) {
            this.changeTab('item');
        }
        this.initItemData();
        this.itemCategorys = this.itemCategoryList;
        this.getContest();
    },
    mounted() {
        timeInterval = setInterval(this.getCurrentTime, 1000);
    },
    beforeDestroy() {
        clearInterval(timeInterval)
    },
    destroyed() {
    },
    directives: {
        clickOutside: vClickOutside.directive
    },
    computed: {
        ...mapState({
            itemCategoryList: state => state.itemCategory.itemCategoryList,
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods: {
        movePage(url) {
            this.$router.push(`${this.$route.path}/${url}`)
        },
        moveHistoryMore() {
            this.$router.push(`/item/${this.entryData.i_sku}/history`);
        },
        getContest() {
            if (util.isEmpty(this.$route.params.cid)) {
                return false;
            }
            let params = {
                cuid: this.$route.params.cid,
            }
            try {
                this.$api.$competition.getCompetition(params).then(res => res.Data.Result).then(res => {
                    if (util.isEmpty(res.List)) {
                        return false;
                    }
                    this.contestData = res.List[0];
                })
            } catch (e) {
                console.log(e);
            }
        },
        openJudgeModal() {
            this.$modal.show('judge-input-modal', {idx: this.itemData.ci_idx});
        },
        changeTab(tab) {
            this.tab = ''
            this.$nextTick(() => {
                this.tab = tab;
            })
            this.$router.replace(`${this.$route.path}?tab=${tab}`);
        },
        returnItemProfile(data, type) {
            if (!util.isEmpty(data.profile) && !util.isEmpty(data.profile[type])) {
                return `${data.profile[type][0].org_url}?${this.setImageOptions(45, 45, 'png')}`;
            }
        },
        checkCurator(data) {
            return !util.isEmpty(data.profile) && !util.isEmpty(data.profile.curator);
        },
        getCurrentTime() {
            this.currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
        },
        returnItemType(data) {
            /*if (util.isEmpty(data.ia_start_date)) {
                return '';
            }
            return data.ia_start_date;*/

            let currentTime = this.$moment().format('YYYY-MM-DD HH:mm:ss');
            if (data.i_status === 'auction') {
                if (data.ia_auction === 2) {
                    let diffTime = this.getDifferenceTime(data.ia_end_date, currentTime);
                    if(diffTime === '00 : 00 : 00' && !this.getDataStatus) {
                        this.getDataStatus = true;
                        setTimeout(() => {
                            this.getDataStatus = false;
                            EventBus.$emit('getDetail');
                        }, 1000 * 30)
                    }
                    return diffTime;
                } else {
                    return data.ia_start_date;
                }
            } else if (data.i_status === 'license') {
                return this.$t('license')
            } else if (data.i_status === 'pricetag') {
                return this.$t('fix_selling')
            } else {
                // return ''
                return this.$t('no_sell')
            }

        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        decodeHtml(html = '') {
            return util.htmlDecode(html);
        },
        checkCerti() {
            return !util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile.Guaranty) && !util.isEmpty(this.entryData.ItemFile.Guaranty[0].a_idx)
        },
        checkItemData(data) {
            return !util.isEmpty(this.entryData[data]);
        },
        onClickOutside() {
        },
        returnCategoryName(data) {
            if (data.ic_idx === 2) {
                return this.$t('art')
            } else if (data.ic_idx === 3) {
                return this.$t('picture')
            } else if (data.ic_idx === 4) {
                return this.$t('video')
            } else if (data.ic_idx === 5) {
                return this.$t('music')
            } else if (data.ic_idx === 9) {
                return this.$t('collections')
            } else if(data.ic_idx === 178){
                return  this.$t('prompt');
            }
        },
        checkUpdate() {
            if (!util.isEmpty(this.UserInfo) && this.UserInfo.mb_no === this.entryData.provider) {
                return true
            }
            return false
        },
        initItemData() {
            this.entryData = !util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('Item') && !util.isEmpty(this.itemData.Item) ? this.itemData.Item[0] : {}
            if (!util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile.Detail)) {
                this.thumnailImgs = this.entryData.ItemFile.Detail;
                this.orgItemImg = this.entryData.ItemFile.Item[0].org_url;
                this.mainImg = this.orgItemImg;
                this.thumnailStatus = true;
            } else {
                this.thumnailImgs = [];
                this.mainImg = '';
                this.thumnailStatus = false;
            }
        },
        returnEntryData() {
            return !util.isEmpty(this.itemData) && this.itemData.hasOwnProperty('Item') && !util.isEmpty(this.itemData.Item) ? this.itemData.Item[0] : {}
        },
        returnCommas(x = 0) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        },
        returnPercentage(v, t) {
            if (t !== 0) {
                return parseFloat((v / t * 100).toFixed(2))
            } else {
                return 0
            }
        },
        voteItem() {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                EventBus.$emit('voteItem', this.itemData.ci_idx)
            }
        },
        returnProfile(judge) {
            if (!util.isEmpty(judge) && !util.isEmpty(judge.Profile)) {
                return judge.Profile[0].org_url
            } else {
                return ''
            }
        },
        slideChange() {
            this.slideIndex = this.$refs.swiper.$swiper.realIndex;
        },
        onClick(event) {
            if (util.isEmpty(event.target)) {
                return false;
            }
            let element = event.target;
            let elementSrc = element.currentSrc.split('?');

            if (util.isEmpty(elementSrc[0])) {
                return false;
            }
            this.changeThumnail(elementSrc[0]);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        returnCategoryDetailName(data) {
            let locale = this.$i18n.locale;
            if (locale === 'ko') {
                return data.ic_name
            }
            return data.ic_name_eng
        },
        checkLogin(url) {
            if (!this.isLogin()) {
                this.createConfirm({
                    content: this.$t('not_login_confirm'),
                    confirmText: this.$t('go_login'),
                    confirm: () => {
                        this.$router.push('/auth');
                    }
                })
                return false;
            } else {
                this.movePage(url);
            }
        },
        checkMemberType() {
            if ((!util.isEmpty(this.UserInfo) && this.entryData.provider === this.UserInfo.mb_no) || this.UserInfo.g_idx === 2) {
                return false
            }
            return true
        },
        checkImgData(data) {
            if (!util.isEmpty(this.entryData) && !util.isEmpty(this.entryData.ItemFile) && !util.isEmpty(this.entryData.ItemFile[data]) && !util.isEmpty(this.entryData.ItemFile[data][0].a_idx)) {
                return true
            }
            return false
        },
        awsS3Download(type, name, key, nft) {
            if (util.isEmpty(this.UserInfo) || this.entryData.provider != this.UserInfo.mb_no) {
                return false;
            }
            this.runDownloadFileInfo(type, name, key, nft).then(res => {
                if (!res) {
                    this.errorAlert(this.$t('file_download_error'));
                }
            })
        },
        returnType(data) {
            if (data === 'real') {
                return `${this.$t('real')}`
            } else {
                return `${this.$t('digi')}`
            }
        },
    },
    watch: {
        "$route.fullPath": {
            deep: true,
            handler: function (val, oldVal) {
                if (!this.$route.query.tab) {
                    this.changeTab('item');
                } else if (this.tab != this.$route.query.tab) {
                    this.changeTab(this.$route.query.tab);
                }
            }
        },
        "itemData": {
            deep: true,
            handler: function (val, oldVal) {
                this.initItemData()
            }
        }
    },
}
</script>

<style scoped>

</style>
