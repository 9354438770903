const competitionConfig = {
    homeCompetition: {
        listLayout: 'CompetitionListHomeLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        getFunc: 'getMainCompetition',
        listApiParamSet: {
            // ip: 1,
            // page: 1,
            // sp: 3,
            // st: [0,1],
            // obt: 'c_end_time',
            // obs: 'asc',
        },
        pageRow: 10,
    },
    competition: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterDefaultLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    competitionDetail: {
        detailLayout: 'CompetitionDetailDefaultLayout',
        getFunc: 'getCompetitionDetail',
        detailApiParamsSet: {
            ip: 0,
            hit: 1,
        },
        //keyName:'id',
        keyName:'uid',
        deleteFunc: 'deleteCompetition',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-202': "delete_error_allow"
        },
        deleteKayName : 'c_idx',
    },
    competitionUser: {
        // listLayout: 'CompetitionListUserLayout',
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterAllLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'c_title',
            obs: 'desc',
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
        getMemberKeyName: 'muid',
    },
    competitionUserMy: {
        // listLayout: 'CompetitionListUserLayout',
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterAllLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'c_title',
            obs: 'desc',
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
        getMemberKeyName: 'no',
    },
    myCompetition: {
        listLayout: 'CompetitionListMyCompetitionLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterMyCompetitionLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
            obt: 'created_at',
            obs: 'desc',
        },
        pageRow: 10,
    },
    competitionMy: {
        listLayout: 'CompetitionListMyLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        // filterLayout: 'CompetitionFilterModalFilterLayout',
        filterLayout: 'CompetitionFilterMyLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getPrizeMyCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,

        },
        pageRow: 10,},
    judge: {
        listLayout: 'CompetitionListJudgeLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterJudgeLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetitionJudge',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,

        },
        pageRow: 10,
    },
    interestCompetition: {
        // filterLayout: 'CompetitionFilterInterestLayout',
        filterLayout: 'CompetitionFilterJudgeLayout',
        listLayout: 'CompetitionListInterestLayout',
        // listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',

        getFunc: 'getCompetitionLike',
        listApiParamSet: {
            ip:1,
            sp:1,
            page:1,
            obt: 'created_at',
            obs: 'desc',

            // prgt: [],
        },
        pageRow: 8,
    },
    latelyCompetition: {
        listLayout: 'CompetitionListLatelyLayout',
    },
    searchCompetition: {
        listLayout: 'CompetitionListSearchDetailLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterNoneLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'c_title',
            obs: 'desc',
        },
        pageRow: 8,
    },
    competitionAll: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterAllLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,

    },
    competitionArt: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterOtherLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
            ccid:[2]
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
    },
    competitionCollect: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterOtherLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
            ccid:[9]
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
    },
    competitionPhoto: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterOtherLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
            ccid:[3]
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
    },
    competitionMusic: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterOtherLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
            ccid:[5]
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
    },
    competitionVideo: {
        listLayout: 'CompetitionListDefaultLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterOtherLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetition',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
            ccid:[4]
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
    },
    entryCompetition: {
        listLayout: 'CompetitionListEntryCompetitionLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterModalFilterLayout',
        paginationLayout: 'LazyloadLoadingLayout',
    },
    entryList: {
        listLayout: 'CompetitionListEntryLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        filterLayout: 'CompetitionFilterEntryLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetitionItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
        getKeyName: 'cuid',
    },
    entryDetail: {
        detailLayout: 'CompetitionDetailEntryLayout',
    },
    competitionWrite: {
        writeLayouts: ['CompetitionWriteDefaultLayout'],
        writeFunc: 'createCompetition',
        writeApiParamSet: {

        },
        parsingData: {
            title: 'c_title',
            category: 'cc_idx',
            hashtag: 'hashtag',
            organize: 'c_organize',
            target: 'c_target',
            content: 'c_context',
            license: 'c_license',
            evaluate: 'evaluate',
            is_judge: 'c_is_judge',
            judge: 'judge',
            prize: 'c_prize',
            startApplyDate: 'c_start_time',
            endApplyDate: 'c_end_time',
            releaseDate: 'c_release_time',
            chance: 'c_apply_cnt',

        },
        fileInfo: [
            //fileName : front에서 정한 파일 이름 ,
            //fileType : api에 전송하는 a_container_type 이름
            {
                fileName: 'poster',
                fileType: 'contestPoster',
            },
            {
                fileName: 'intro',
                fileType: 'contestIntro',
            },
        ],
        directUrl: '',
        directType: 'back',
        keyName: '',
        writeSuccessType: 1,
        writeValidatorArr: [
            'title',
            'category',
            'hashtag',
            'organize',
            'target',
            'content',
            'license',
            'evaluate',
            'is_judge',
            'prize',
            'startApplyDate',
            'endApplyDate',
            'releaseDate',
            'chance',
            'poster',
            'intro',
        ],
        writeFailTypes: {
            '-100': 'no_value',
            '-103' : 'no_object',
            '-801' : 'no_match_category',
            '-831' : 'no_match_category2',
            '-811': 'wrong_judge',
            '-812' : 'over_judge',
            '-901' : 'mg_point_error'
        },
    },
    competitionUpdate: {
        writeLayouts: ['CompetitionWriteDefaultLayout'],
        getFunc: 'getCompetitionDetail',
        detailApiParamsSet: {
            ip: 0,
        },
        getKeyName: 'uid',
        writeFunc: 'updateCompetition',
        writeApiParamSet: {},
        parsingData: {
            title: 'c_title',
            category: 'cc_idx',
            hashtag: 'hashtag',
            organize: 'c_organize',
            target: 'c_target',
            content: 'c_context',
            license: 'c_license',
            evaluate: 'evaluate',
            is_judge: 'c_is_judge',
            u_judge: 'judge',
            prize: 'c_prize',
            startApplyDate: 'c_start_time',
            endApplyDate: 'c_end_time',
            releaseDate: 'c_release_time',
            chance: 'c_apply_cnt',

        },
        fileInfo: [
            //fileName : front에서 정한 파일 이름 ,
            //fileType : api에 전송하는 a_container_type 이름
            {
                fileName: 'poster',
                fileType: 'contestPoster',
            },
            {
                fileName: 'intro',
                fileType: 'contestIntro',
            },
        ],
        directUrl: '',
        directType: 'back',
        keyName: 'uid',
        writeSuccessType: 1,
        writeValidatorArr: [
            'title',
            'category',
            'hashtag',
            'organize',
            'target',
            'content',
            'license',
            'evaluate',
            'is_judge',
            'prize',
            'startApplyDate',
            'endApplyDate',
            'releaseDate',
            'chance',
            'poster',
            'intro',
        ],
        writeFailTypes: {
            '-100': 'no_value',
            '-103' : 'no_object',
            '-801' : 'no_match_category',
            '-831' : 'no_match_category2',
            '-811': 'wrong_judge',
            '-812' : 'over_judge',
        },
    },
    entrySearch:{
        filterLayout: 'CompetitionFilterEntryModalLayout',
        listLayout: 'CompetitionListEntryModalLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        paginationLayout: 'PaginationModalLayout',
        getFunc: 'getCompetitionItem',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 8,
            obt: 'created_at',
            obs: 'desc',
        },
        sortOptions: {
            keyName: 'obt',
            orderName: 'obs',
        },
        pageRow: 8,
        getKeyName: 'cuid',
        selectFilterKeyName: 'ci_idx',
    },
    entryItem: {
        filterLayout: 'CompetitionFilterMyEntryItemLayout',
        listLayout: 'CompetitionListMyEntryLayout',
        emptyLayout: 'CompetitionEmptyDefaultLayout',
        paginationLayout: 'PaginationDefaultLayout',
        getFunc: 'getCompetitionEntry',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 10,
        },
        pageRow: 10,
        deleteFunc: 'deleteCompetition',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {
            '-202': "delete_error_allow"
        },
        deleteKayName : 'ci_idx',
    },
// 댓글
    comment: {
        getFunc: 'getComment',
        listApiParamSet: {
            ip: 1,
            page: 1,
            sp: 5,
        },
        getKeyName: 'cid',
        pageRow: 5,

        deleteFunc: 'deleteComment',
        deleteApiParamSet: {},
        deleteSuccessType: 1,
        deleteErrorTypes: {},
        deleteKeyName: 'cc_idx',

        updateFunc: 'updateComment',
        writeFunc: 'createComment',
        writeApiParamSet: {},
        directUrl: '',
        directType: 'back',
        keyName: 'cc_idx',
        successType: 1,
        parsingData: {
            reply_msg: 'cc_context',
        },
        upParsingData: {
            update_msg: 'cc_context',
        },
        writeFailTypes: {
            '-202': "delete_error_allow"
        },
    },

}
export default {
    methods: {
        returnCompetitionConfig(type) {
            return competitionConfig[type];
        },
    },
    filterConfig: {
        //제목
        ti: {
            type: 'query',
            classname: 'searchInput',
        },
        //해시태그
        hash: {
            type: 'query',
            classname: 'searchInput',
        },
        chash: {
            type: 'query',
            classname: 'searchInput',
        },
        //공모전 주최자 or 응모작 창작자
        ni: {
            type: 'query',
            classname: 'searchInput',
        },
        //상태
        st :{
            type:'terms',
            classname : 'filterStatus'
        },
        //카테고리
        ccid:{
            type:'terms',
            classname : 'filterCategory'
        },
        icid:{
            type:'terms',
            classname : 'filterCategory'
        },
        //상금
        pr:{
            type: 'between',
            classname: 'filterPrice',
            option:{
                start: 'prgt',
                end: 'prle'
            }
        },
        //경매 아이템명
        na: {
            type: 'query',
            classname: 'searchInput',
        },
        //등록일자
        cr:{
            type: 'between',
            classname: 'filterDate'
        },
        r:{
            type: 'between',
            classname: 'filterDate'
        },
        cni:{
            type: 'match',
            classname: 'searchInput'
        },
        tab:{
            type: 'match',
            classname: 'tab',
        }


    }
}
