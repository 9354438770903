<template>
    <div>
        <page-header :header-title="` `" :header_logo="true" :header_side="true">
            <div slot="headerBtns">
                <button class="h_search_btn" @click="$router.push('/search')"></button>
            </div>
        </page-header>
        <div>
            <collect-detail-component :collect-type="'collectDetail'"
                                      :collect-key="collectKey"></collect-detail-component>
        </div>
        <div class="box-tab pb16">
            <div class="btn-box-tab" :class="{'active': tabStatus === 'nticket'}" @click="clickTabBtn('nticket')">NTicket</div>
            <div class="btn-box-tab" :class="{'active': tabStatus === 'utility'}" @click="clickTabBtn('utility')">Utility</div>
        </div>
        <div class="pr14 pl14">
            <item-list-component v-if="tabStatus === 'nticket'" :item-type="'collectItem'" :member-key="collectKey"></item-list-component>
            <utility-list-component v-if="tabStatus === 'utility'" :utilityKey="collectKey" :utility-type="'utilityCollect'"></utility-list-component>
        </div>
        <share-modal></share-modal>
    </div>
</template>

<script>


import CollectDetailComponent from "@/components/collect/CollectDetailComponent";
import ItemListComponent from "@/components/item/ItemListComponent";
import ShareModal from "@/components/modal/ShareModal";
import UtilityListComponent from '@/components/utility/UtilityListComponent';


export default {
    name: "CollectDetail",
    mixins: [],
    components: {ShareModal, ItemListComponent, CollectDetailComponent, UtilityListComponent},
    inject: [],

    provide() {
        return {}
    },
    props: {},
    data() {
        return {
            collectKey: this.$route.params.idx,
            tabStatus: 'nticket',
        }
    },
    beforeRouterEnter() {
    },
    created() {
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {
    },
    computed: {},
    methods: {
        clickTabBtn(value){
            this.tabStatus = value;
        },
        existUtility(){
            // return this.collectionData.utility_cnt > 0
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>

