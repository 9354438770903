<template>
    <div class="search_item mb19">
        <div class="search_input detection_search_input">
            <el-input :placeholder="$t('st')" v-model="searchInput"
                      @keypress.native.enter="setFilterFunc(searchInputOption,searchInput)">
            </el-input>
            <button class="search_btn mypage_search_btn" @click="setFilterFunc(searchInputOption,searchInput)"></button>
        </div>
        <button class="search_filter_btn" @click="openFilter('curation-filter-request-filter')"></button>
        <curation-filter-request-filter></curation-filter-request-filter>
    </div>

</template>

<script>
import {curationStatus} from "@/service/curationService";
import curationUtil from "@/components/curation/curationUtil";
import CurationFilterRequestFilter from "@/components/filter/CurationFilterRequestFilter";

const {filterConfig} = curationUtil;
export default {
    name: "CurationFilterDefaultLayout",
    mixins: [],
    components: {
        CurationFilterRequestFilter
    },
    inject: ['setFilter'],
    provide() {
        return{

        }
    },
    props: {
        filterData: {default: () => {}},
    },
    data() {
        return{
            sortOption: 'i.updated_at',
            searchInput: '',
            searchInputOption: 'ina',
            filterCategory: [],
            filterStatus: [],
            filterEdition: [],
            filterType: [],
            filterItemStatus: [],
            filterCurationStatus: [],
            priceInput: ["", ""],
            filterRequestDate: '',
            filterDate: '',

            curationStatus: curationStatus,
            showPopover: false,
        }
    },
    beforeRouterEnter() {},
    created() {
        this.setterFilterDataInData();
    },
    mounted() {

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods:{
        openFilter(name) {
            this.$modal.show(name, {filterData: this.filterData, sortData: this.sortData});
        },
        setterFilterDataInData() {
            let classnames = Object.keys(this.filterData);
            classnames.forEach(classname => {
                let obj = this.filterData[classname];
                let type = filterConfig[obj.key].type;
                if (this.hasOwnProperty(classname)) {
                    // this[classname] =
                    if (type == 'query') {
                        this[classname] = obj.query;
                        if (this.hasOwnProperty(classname + 'Option')) {
                            this[classname + 'Option'] = obj.key;
                        }
                    } else if (type === 'between') {
                        this[classname] = [obj.start, obj.end];
                    } else if (type === 'match') {
                        if (isNaN(obj.value)) {
                            this[classname] = obj.value;
                        } else {
                            this[classname] = Number(obj.value);
                        }

                    } else if (type === 'terms') {
                        this[classname] = obj.terms;
                    }

                }
            })
        },
        setFilterFunc(key, value) {
            this.setFilter(key, value);
        },
        runPriceFilter() {
            this.setFilterFunc('pr', this.priceInput);
            // this.showPopover = false;
        },
    },
    watch: {

    },
}
</script>

<style scoped>

</style>
