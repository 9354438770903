<template>
    <div>
        <detection-detail-link :detail-layout="detailLayout" :detectionData="detectionData"></detection-detail-link>
    </div>
</template>

<script>

import detectionUtil from "./detectionUtil";
import DetectionDetailLink from "./DetectionDetailLink";
import util from "@/mixins/util";
import alertMixins from "@/mixins/alertMixins";
import EventBus from "@/utils/event-bus";

export default {
    name: "DetectionDetailComponent",
    mixins: [detectionUtil, alertMixins],
    components: {DetectionDetailLink},
    inject: [],
    provide() {
        return {}
    },
    props: {
        detectionType: '',
        detectionKey:{default: 0},
        detectionDefaultValue: {default: () => {}},
    },
    data() {
        return {
            detailLayout: '',
            getFunc: '',
            detailApiParamSet: {},
            getKeyName: 'id',

            detectionData: {},
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
        this.getDetection();
    },
    mounted() {
        EventBus.$on('runItemDetection', this.runDetection);
        EventBus.$on('detectDetailChangeStatus', this.runChangeDetectStatus);
        EventBus.$on('detectDetailIgnoreDomain', this.runIgnoreDomain);
    },
    beforeDestroy() {
        EventBus.$off('runItemDetection')
        EventBus.$off('detectDetailChangeStatus')
        EventBus.$off('detectDetailIgnoreDomain')
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnDetectionConfig(this.detectionType);
            this.detailLayout = config.detailLayout;
            this.getFunc = config.getFunc;
            this.detailApiParamSet = config.detailApiParamSet;
            this.getKeyName = config.getKeyName;
        },
        getDetection() {
            let params = JSON.parse(JSON.stringify(this.detailApiParamSet));
            params = Object.assign({}, params, this.detectionDefaultValue);
            let getKeyName = this.getKeyName;
            if(util.isEmpty(getKeyName)) {
                getKeyName = 'id';
            }
            params[getKeyName] = this.detectionKey;
            try {
                this.$api.$detection[this.getFunc](params).then(res => res.Data.Result).then(res => {
                    if(res.Info.type != 1 || util.isEmpty(res.List)) {
                        this.createAlert({
                            content: this.$t('detect_detail_empty'),
                            hide: () => {
                                this.$router.back();
                            }
                        })
                        return false;
                    }
                    if(util.isArray(res.List)) {
                        this.detectionData = res.List[0]
                    }else{
                        this.detectionData = res.List;
                    }
                    if(!util.isEmpty(res.Log)) {
                        this.detectionData.Log = res.Log;
                    }

                })
            }catch (e) {
                console.log(e);

            }
        },
        runDetection() {
            if(util.isEmpty(this.detectionData.Item)) {

            }
            let params = {
                i_idx: [this.detectionData.Item[0].i_idx],
            }
            try {
                EventBus.$emit('changeLoading', true);
                this.$api.$detection.itemDetect(params).then(res => res.Data.Result).then(res => {
                    let type = this.returnDetectionType(res.Info.type);
                    EventBus.$emit('changeLoading', false);

                    if(type === true) {
                        this.createAlert({
                            content: this.$t('detect_success1'),
                        })
                        this.getDetection();
                    }else{
                        this.errorAlert(type);
                    }
                })
            }catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        returnDetectionType(type) {
            /*'-101': 'detect_err2',
            '-601': 'detect_err3',*/
            switch (type) {
                case 99:
                    return true;
                case -101:
                    return this.$t('detect_err2');
                case -601:
                    return this.$t('detect_err3');
                default:
                    return this.$t('detect_err4')
            }
        },
        runChangeDetectStatus(detect) {
            EventBus.$emit('changeLoading', true);
            let params = {
                dr_idx: detect.dr_idx,
                dr_status: detect.dr_status,
            }
            try {
                this.$api.$detection.changeStatus(params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    let type = this.returnDetectStatusType(res.Info.type);
                    if (type === true) {
                        detect.dr_status = detect.dr_status;
                        this.getDetection();
                    } else {
                        this.errorAlert(type);
                    }
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        returnDetectStatusType(type) {
            switch (type) {
                case 1:
                    return true;
                case -103:
                    return this.$t('d_change_status_err2');
                case -601:
                    return this.$t('d_change_status_err3');
                case -610:
                    return this.$t('d_change_status_err4');
                default:
                    return this.$t('d_change_status_err1')
            }
        },
        runIgnoreDomain(detect) {
            EventBus.$emit('changeLoading', true);
            let params = {
                dr_idx: detect.dr_idx,
            }
            try {
                this.$api.$detection.updateDetectDomainIgnore(params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('changeLoading', false);
                    let type = this.returnIgnoreDomain(res.Info.type);
                    if (type === true) {
                        detect.dr_except = 1;
                        this.getDetection();
                    } else {
                        this.errorAlert(type);
                    }
                })
            } catch (e) {
                console.log(e);
                EventBus.$emit('changeLoading', false);
            }
        },
        returnIgnoreDomain(type) {
            switch (type) {
                case 1:
                    return true;
                case -103:
                    return this.$t('d_change_status_err2');
                case -601:
                    return this.$t('d_change_status_err3');
                case -611:
                    return this.$t('d_ignore_domain_err1');
                default:
                    return this.$t('d_change_status_err1')

            }
        },
    },
    watch: {},
}
</script>

<style scoped>

</style>
